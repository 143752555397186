<template>
    <div
        id="textTaskOuter"
        ref="textTaskOuter"
        class="greyDivBorderColor"
        style="width: 100%; height: 100%"
        :class="currentFontSize"
    >
        <div
            v-if="showTeacherUI"
            style="display: flex; flex-direction: column; height: 100%"
        >
            <textarea
                v-model="answer"
                type="text"
                placeholder="Antwort eingeben (für Autokorrektur)"
                style="padding: 24px; margin: 12px; resize: none; height: 100%"
                class="greyDiv"
            />
        </div>
        <div
            v-else-if="showPupilUI"
            style="display: flex; flex-direction: column; height: 100%;"
        >
            <textarea
                v-model="solution"
                type="text"
                placeholder="Antwort eingeben"
                style="padding: 24px; margin: 12px; height: 100%; resize: none;"
                class="greyDiv"
            />
        </div>
        <!-- Correction View -->
        <div
            v-else-if="showCorrectionUI"
            style="display: flex; flex-direction: column; height: 100%;"
        >
            <p
                v-if="solution === answer"
                style="padding: 24px; margin: 12px; height: 100%"
                class="editorColorPrimary"
            >
                {{ solution }}
            </p>
            <v-tooltip
                v-else
                location="bottom"
                :disabled="!elementOnCurrentPage"
            >
                <template v-slot:activator="{ props }">
                    <div
                        style="padding: 24px; margin: 12px; height: 100%; border-radius: 12px; color: white"
                        :class="showSolution ? 'editorColorPrimaryBackground' : 'editorColorWrongBackground'"
                    >
                        <p
                            style="height: 100%"
                            v-bind="props"
                            @click="showSolution = !showSolution"
                        >
                            {{ showSolution ? answer : (solution === undefined || solution === '') ? '-' : solution }}
                        </p>
                    </div>
                </template>
                <span style="color: white; font-size: medium !important">{{ showSolution ? 'Lösung verstecken' : 'Lösung anzeigen' }}</span>
            </v-tooltip>
        </div>
        <!-- Controls rechts rausgeschoben editorOptionsFade -->
        <div
            v-if="showTeacherUI"
            class="editorOptionsFade"
        >
            <div style="display: flex; flex-direction: column; position: absolute; right: -56px; top: 60px; width: 48px; height: 48px; min-width: 48px">
                <v-btn
                    class="editorColorPrimaryBackgroundOnHover"
                    style="width: 48px; height: 48px; min-width: 48px; margin-bottom: 6px"
                    @click="changeSize()"
                >
                    <img
                        :src="currentSizeIcon"
                        style="height: 32px"
                        class="iconToWhiteHover"
                        alt="Größe des Elements verändern"
                    >
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import sizeSmallIcon from "@/assets/Icons/EditorRedesign/schrift_klein.svg";
import sizeMediumIcon from "@/assets/Icons/EditorRedesign/schrift_mittel.svg";
import sizeLargeIcon from "@/assets/Icons/EditorRedesign/schrift_groß.svg";

export default {
    name: 'EditorAnswerFieldPlus',
    props: {
        config: { type: Object, required: true},
        state: { type: String, required: true},
        tabIndex: { type: Number, required: false, default: 0 },
        canvasWidth: {type: Number, required: true},
        canvasHeight: {type: Number, required: true},
        elementOnCurrentPage: { type: Boolean, required: false },
        previewPupilUI: { type: Boolean, required: false, default: false },
    },
    data() {
        return {
            answer: '',
            solution: '',
            sizeState: 0,
            showSolution: false,

            // UI
            sizeSmallIcon,
            sizeMediumIcon,
            sizeLargeIcon,
        };
    },
    computed: {
        ...mapGetters("auth", ["accountRole"]),

        currentSizeIcon() {
            return this.sizeState ? (this.sizeState === 2 ? this.sizeLargeIcon : this.sizeMediumIcon) : this.sizeSmallIcon;
        },
        currentFontSize() {
            return this.sizeState ? (this.sizeState === 2 ? 'editorFontSize3' : 'editorFontSize2') : 'editorFontSize1';
        },
        showPupilUI() {
            return this.exportMode || this.state === 'pupil' || this.previewPupilUI;
        },
        showTeacherUI() {
            return !this.exportMode && !this.previewPupilUI && this.state === 'creator';
        },
        showCorrectionUI() {
            // New Modes viewerTeacher and viewerPupil to make sure pupils dont see corrections early
            return this.state === 'teacher' || this.state === 'viewerTeacher';
        },
    },
    watch: {
        sizeState: {
            handler(val, oldVal) {
                this.saveState();
            }
        },
        answer: {
            handler(val, oldVal) {
                this.saveState();
            }
        },
        solution: {
            handler(val, oldVal) {
                this.saveState();
            }
        },
        previewPupilUI: {
            handler(val, oldVal) {
                this.solution = '';
            }
        }
    },
    mounted() {
        if (!this.config.answerConfig) {
            this.config.answerConfig = {};
            this.config.identifier = this._uid;
            this.config.answerConfig.answer = this.answer;
            this.config.answerConfig.solution = this.solution;
            this.config.answerConfig.sizeState = this.sizeState;
        } else {
            if(this.config._id) {
                this.config.identifier = this.config._id;
            }
            this.answer = this.config.answerConfig.answer;
            this.solution = this.config.answerConfig.solution;
            this.sizeState = this.config.answerConfig.sizeState;
        }
    },
    methods: {
        changeSize() {
            switch (this.sizeState) {
                case 0:
                    this.sizeState = 1;
                    break;
                case 1:
                    this.sizeState = 2;
                    break;
                case 2:
                    this.sizeState = 0;
                    break;
                default:
                    break;
            }
        },

        saveState() {
            if(!this.previewPupilUI) {
                this.config.answerConfig.solution = this.solution;
                this.config.answerConfig.answer = this.answer;
                this.config.answerConfig.sizeState = this.sizeState;
            }
        },
    },
};
</script>

<style scoped lang="scss">
    input:focus {
      outline: none;
    }
    textarea:focus {
      outline: none;
    }
</style>
