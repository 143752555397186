<template>
    <div
        id="btnsContainer"
        class="d-flex justify-start"
        :style="accountRole === 'parent' ? 'flex-direction: column' : ''"
    >
        <!-- Parent -->
        <v-btn
            v-if="accountRole === 'parent'"
            id="joinRoomBtnPupil"
            style="justify-content: left"
            :color="atLeastOneRoomOpen ? 'gruen' : 'fgrau'"
            elevation="0"
            @click="requestRooms(true)"
        >
            <img
                :src="videoIcon"
                class="iconToWhite"
                alt="Videokamera"
            >
            <p>
                Besprechung
            </p>
        </v-btn>

        <!--#region Teacher -->
        <v-btn
            v-if="accountRole === 'teacher'"
            id="joinRoomBtnTeacher"
            :color="roomIsOpen ? 'gruen' : 'fgrau'"
            style="border-top-right-radius: 0; border-bottom-right-radius: 0; justify-content: left"
            class="px-2"
            elevation="0"
            @click="joinRoom(teacherRoomId)"
        >
            <img
                :src="videoIcon"
                alt=""
            >
            <p
                style="padding-right: 14px"
            >
                Eltern
            </p>
        </v-btn>

        <v-btn
            v-if="accountRole === 'teacher'"
            id="schlossBtn"
            :color="roomIsOpen ? 'gruen' : 'fgrau'"
            class="ml-0"
            elevation="0"
            style="border-top-left-radius: 0; border-bottom-left-radius: 0; border-left-style: solid; border-color: rgba(0, 0, 0, 0.33) !important"
            :size="getButtonSize(windowWidth)"
            @click="toggleRoom"
        >
            <img
                :src="lockIcon"
                :alt="roomIsOpen ? 'Raum schließen' : 'Raum öffnen'"
            >
        </v-btn>
        <!--#endregion -->

        <!-- Parent can chose from teachers -->
        <div
            v-if="menu && windowWidth >= 770"
            id="roomMenu"
            v-click-outside="onClickOutsideMenu"
            :style="windowWidth > 900 ? 'position: fixed' : ''"
        >
            <v-row
                v-for="(room, index) in rooms"
                :key="index"
                class="pa-0 ma-3"
            >
                <v-col
                    cols="10"
                    class="pa-0"
                    @click="joinRoom(room._id)"
                >
                    <v-btn
                        elevation="0"
                        style="padding: 0 6px !important"
                        color="hellgrau"
                        width="95%"
                        class="d-flex justify-start"
                    >
                        <Avatar
                            :profile="room.teacher.account._id"
                            :show-burger="false"
                            :size="30"
                            :tabindex-avatar="-1"
                        />
                        <p
                            class="text-capitalize ma-0 ml-2"
                            style="color: rgb(var(--v-theme-dunkelgrau))"
                        >
                            {{ getAppropriateTeacherDisplayName(room.teacher) }}
                        </p>
                    </v-btn>
                </v-col>

                <v-col
                    cols="2"
                    class="pa-0 pl-2 d-flex justify-end"
                    @click="joinRoom(room._id)"
                >
                    <v-btn
                        elevation="0"
                        :color="room.isOpen ? 'gruen' : 'hellgrau'"
                        class="videoBtn"
                        size="x-small"
                    >
                        <img
                            :src="videoIcon"
                            :class="room.isOpen ? 'iconToWhite' : 'iconToDunkelgrau'"
                            alt="Videokamera"
                        >
                    </v-btn>
                </v-col>
            </v-row>
        </div>

        <!-- Parent can chose from teachers in MOBILE -->
        <div
            v-if="menu && windowWidth < 770"
            id="roomMenuBtm"
            v-click-outside="onClickOutsideMenu"
        >
            <v-row
                v-for="(room, index) in rooms"
                :key="index"
                class="pa-0 ma-3"
                style="width: 176px"
            >
                <v-btn
                    color="hellgrau"
                    class="d-flex justify-start"
                    style="width: 140px; padding: 0 6px !important"
                    @click="joinRoom(room._id)"
                >
                    <Avatar
                        :profile="room.teacher.account._id"
                        :show-burger="false"
                        :size="30"
                        :tabindex-avatar="-1"
                    />
                    <p
                        class="text-truncate text-capitalize text-left ma-0 ml-2"
                        style="width: 80px; color: rgb(var(--v-theme-dunkelgrau))"
                    >
                        {{ getAppropriateTeacherDisplayName(room.teacher) }}
                    </p>
                </v-btn>

                <v-btn
                    :color="room.isOpen ? 'gruen' : 'hellgrau'"
                    class="videoBtn"
                    size="x-small"
                    @click="joinRoom(room._id)"
                >
                    <img
                        :src="videoIcon"
                        :class="room.isOpen ? '' : 'iconToDunkelgrau'"
                        alt="Kamera"
                    >
                </v-btn>
            </v-row>
        </div>
        <VideoTutorial
            v-if="videoTutorial && showVideoTutorial && !videoTutorialClosed"
            :video-tutorial="videoTutorial"
            @close="onVideoTutorialClose"
        />
    </div>
</template>

<script>
import {mapActions, mapState, mapMutations, mapGetters } from 'vuex';

import VideoTutorial from "../components/Tutorial/VideoTutorial.vue";
import Avatar from "@/components/SharedViews/Profile/Avatar";

import videoIcon from '@/assets/Icons/video.svg';
import lockIcon from '@/assets/Icons/lock.svg';

export default {
    name: "JoinSprechzimmerButton",
    components: {
        VideoTutorial,
        Avatar,
    },
    props: {
        currentChild: { required: false, type: Object },
    },
    data: () => ({
        videoIcon,
        lockIcon,

        menu: '',
        rooms: '',
        teacherRoomId: '',
        roomIsOpen: null,
        atLeastOneRoomOpen: null,      //to make Sprechzimmer-btn for parent green if >= 1 room isOpen

        person: null,
        currentChildId: null,

        // Show video Tutorial is set to true when button is clicked
        // videoTutorialClosed is set to false when VideoTutorial is closed
        videoTutorial: false,
        showVideoTutorial: false,
        videoTutorialClosed: false,
        videoTutorialOnCloseJoinRoomId: '',
    }),
    computed : {
        ...mapGetters("auth",["accountRole"]),
        ...mapState('translation', ['targetLang']),
        ...mapState('util', ['windowWidth']),
        ...mapGetters('videoTutorial', [ 'videoTutorialsByFilename' ]),
        ...mapGetters('parents', {
           getCurrentChild: 'currentChild',
        }),
    },
    watch: {
        async currentChild() {
            this.currentChildId = this.currentChild._id;
            this.atLeastOneRoomOpen = false;
            await this.requestRooms();
        }
    },
    async mounted() {
        await this.getTeacherRoom();
        if(this.accountRole === 'parent') {
            this.person = await this.getMeParent();
            this.currentChildId = this.person.pupils[0];
            await this.requestRooms();
        }
        this.videoTutorial = (this.accountRole === 'teacher' || this.accountRole === 'maintainer')
            ? this.videoTutorialsByFilename['Tutorial_bbb_Lehrer_Verwaltung.mp4']
            : this.videoTutorialsByFilename['Tutorial_bbb_Eltern_Schueler.mp4'];
    },
    methods: {
        ...mapActions('util', [ 'toggleLoading' ]),
        ...mapActions('rooms', [ 'getMyConferenceTeacher', 'getMyConferenceParent', 'changeConferenceRoomStatus', 'joinConferenceRoom' ]),
        ...mapActions('parents', [ 'getMeParent']),
        ...mapActions('translation', [ 'showTranslation', 'setTranslatedText', 'translateToTargetLang' ]),
        ...mapMutations('snackbar', [ 'showSnackbar' ]),

        getButtonSize(width) {
            if (width <= 770) return 'x-small';
            if (width <= 900) return 'small';
            return 'default'; // or 'medium' if defined in your theme
        },
        onVideoTutorialClose() {
            this.videoTutorial = false;
            this.videoTutorialClosed = true;
            this.joinRoom(this.videoTutorialOnCloseJoinRoomId);
        },
        async joinRoom(id) {
            this.toggleLoading(true);

            const response = await this.joinConferenceRoom({ roomId: id, childrenId: this.accountRole === 'parent' ? this.getCurrentChild._id : ''});
            if (this.accountRole === 'teacher') {
                if (!response.redirectUrl) {
                    this.showSnackbar({message: "Um das Sprechzimmer betreten zu können, müssen Sie es erst öffnen. Dazu müssen Sie das Schloss anklicken.", color: 'fgrau'});
                    this.toggleLoading(false);
                    return;
                }
            } else {
                if (!response.redirectUrl) {
                    this.showSnackbar({message: "Das Sprechzimmer ist geschlossen!", color: 'fgrau'});
                    this.toggleLoading(false);
                    return;
                }
            }

            if (!response || response === 422 || response === 404) {
                this.showSnackbar({message: "Das Sprechzimmer konnte nicht geladen werden - Bitte aktualisieren Sie die Seite!", color: 'error'});
                this.toggleLoading(false);
                return;
            }

            if(this.videoTutorial) {
                // Open video tutorial and after try to exec joinClassroom again.
                this.showVideoTutorial = true;
                this.videoTutorialOnCloseJoinRoomId = id;
                return;
            }

            window.location.href = response.redirectUrl;
            this.toggleLoading(false);
        },

        async toggleRoom() {
            let newStatus = !this.roomIsOpen;
            let data = {
                classroomId: this.teacherRoomId,
                newStatus: newStatus,
            }
            let res = await this.changeConferenceRoomStatus(data);

            if(res) {   // if successful also change the local one
                this.roomIsOpen = res.isOpen;

                // and show snackbar
                if (this.roomIsOpen) {
                    this.showSnackbar({ message: "Das Sprechzimmer ist nun geöffnet.", color: "success" });
                } else {
                    this.showSnackbar({ message: "Das Sprechzimmer wurde geschlossen.", color: "success" });
                }
            }
        },

        async requestRooms(menu) {
            // make sure route is only called initial and if parent opens menu, cause big route
            if (this.accountRole === 'parent' && !this.menu && this.currentChildId) {
                this.rooms = await this.getMyConferenceParent(this.currentChildId);
                for (let i = 0; i < this.rooms.length; i++) {
                    if (this.rooms[i] && this.rooms[i].isOpen) {
                        this.atLeastOneRoomOpen = true;
                        break;
                    }
                }
            }
            // if called on click of Sprechzimmer btn, also toggle the menu
            if(menu) {
                this.menu = !this.menu;
            }
        },

        getAppropriateTeacherDisplayName(teacher) {
          const teacherBugfix = teacher || {};
          const fullName = teacherBugfix.name.slice(0,1) + '. ' + teacherBugfix.lastName;
          return fullName;
        },

        async getTeacherRoom() {
            if(this.accountRole === 'teacher') {
                let room = await this.getMyConferenceTeacher();
                this.teacherRoomId = room._id;
                this.roomIsOpen = room.isOpen;
            }
        },

        onClickOutsideMenu() {
            this.menu = false;
        },
    }
}
</script>

<style scoped lang="scss">
.v-btn {
    border-radius: 8px;
}

.ellipsis {
    text-overflow: ellipsis;

    /* Required for text-overflow to do anything */
    white-space: nowrap;
    overflow: hidden;
}

.roundIcon {
    border-radius: 50%;
}

#joinRoomBtnTeacher {
    color: white;
    text-transform: inherit;
    height: 50px !important;

    img {
        height: 25px;
        filter: brightness(1000%);
        margin-right: 4px;
    }

    p {
        font-size: large;
        font-weight: 400;
        line-height: 0;
        margin-bottom: 0;
    }
}

#joinRoomBtnPupil {
    color: white;
    text-transform: inherit;
    height: 50px !important;
    margin-left: 12px;

    img {
        height: 30px;
        filter: brightness(1000%);
        margin-right: 5px;
    }

    p {
        font-size: large;
        font-weight: 400;
        line-height: 0;
        margin-bottom: 0;
    }
}

#schlossBtn {
    height: 50px;
    min-width: 50px;
    width: 50px;

    img {
        height: 30px;
        filter: brightness(1000%);
    }
}

#roomMenu {
    top: 74px;
    width: 250px;
    background-color: rgb(var(--v-theme-headerblue));
    border-radius: 0 0 8px 8px;
    z-index: 10; //to be one higher than fach
    color: rgb(var(--v-theme-grau));

    img {
        height: 25px;
    }
}

.videoBtn {
    height: 36px !important;
    width: 36px !important;
}

@media only screen and (max-width: 900px) {
    #joinRoomBtnTeacher {
        height: 40px !important;
        padding: 8px;
        margin-left: 8px;

        p {
            font-size: small;
        }

        img {
            height: 20px !important;
        }
    }

    #joinRoomBtnPupil {
        height: 40px !important;
        width: 160px;
        padding: 8px;
        margin-left: 0;
        margin-bottom: 8px;

        p {
            font-size: small;
        }

        img {
            height: 21px !important;
            margin-right: 8px !important;
        }
    }

    #schlossBtn {
        height: 40px;
        min-width: 40px;
        width: 40px;

        img {
            height: 21px !important;
            margin: 0 !important;
        }
    }
}

//when change to mobile nav on bottom
@media only screen and (max-width: 500px), (max-width: 900px) and (orientation: portrait) {
    #joinRoomBtnTeacher {
        padding: 8px !important;
        margin-left: 0 !important;
        margin-bottom: 8px;
        width: 120px;
    }

    #joinRoomBtnPupil {
        height: 41px !important;
    }

    #roomMenuBtm {
        width: 200px;
        background-color: white;
        border-radius: 8px 0 0 8px;
        box-shadow: 0 2px 4px silver;
        z-index: 2;
        color: rgb(var(--v-theme-grau));

        img {
            height: 25px;
        }
    }

    #schlossBtn {
        margin-left: 0 !important;
        padding: 0 !important;
        width: 40px !important;

        img {
            margin: 0 !important;
        }
    }
}
</style>
