<template>
    <v-dialog
        v-if="enableFullscreenPreview"
        :model-value="enableFullscreenPreview"
        opacity="1.0"
        :max-width="dialogWidth"
        :max-height="dialogHeight"
        :style="{ width: dialogWidth + 'px', height: dialogHeight + 'px' }"
    >
        <v-card
            v-click-outside="clickOutsideTheDialog"
            flat
            class="filter-dialog__card"
            style="border-radius: 8px;"
        >
            <!-- Image automatically sizes to fit the viewport without scrolling -->
            <div v-if="previewSrc === fileIcon">
                <div class="d-flex flex-column align-center">
                    <div class="d-flex flex-row align-center justify-space-between">
                        <!-- <div
                            class="filter-dialog__overlay"
                            :style="`background-color:${getHeaderColor}`"
                        > -->
                        <span
                            class="filter-dialog__title"
                            style="color: black !important;"
                        >{{ fileTitle }}</span>

                        <!-- <v-btn
                        icon
                        class="filter-dialog__menu-btn"
                    >
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn> -->
                        <FilePreviewTileMenu
                            v-if="!fachInfo"
                            ref="filePreviewMenu"
                            :enable-image-rotation="enableImageRotation"
                            :emit-button-press="emitButtonPress"
                            :show-submissions="showSubmissions"
                            :audio-compatible="audioCompatible"
                            :vfs-usage="vfsUsage"
                            :downloadable="downloadable"
                            :save-to-folder="saveToFolder"
                            :dialog-width="dialogWidth"
                            :dialog-height="dialogHeight"
                            :img-error="true"
                            :usage="usage"
                            @open-menu="(value) => { openMenu = value }"
                            @close-dialog="closeDialog"
                            @open-submissions="emitButtonPress"
                            @play-audio="emitButtonPress"
                            @notify-copy-or-move-file="emitButtonPress"
                            @open-save-to-folder-dialog="emitButtonPress"
                        />

                        <div
                            v-if="fachInfo"
                            class="fachInfoButtonsContainer"
                        >
                            <FileListEditButton
                                v-if="!previewSelection.isMovedPupilUpload && buttonConditions.editButtonCondition(previewSelection, previewSelection.usage)"
                                :file="previewSelection"
                                :primary-color="primaryColor"
                                :person="person"
                                :account-role="accountRole"
                                :button-size="20"
                                @edit-in-editor="$emit(previewSelection.usage === 'toCorrectTeacher' ? 'correctInEditor' : 'editInEditor', previewSelection)"
                                @edit-in-libre="$emit(previewSelection.usage === 'toCorrectTeacher' ? 'correctInLibre' : 'editInLibre', previewSelection)"
                                @edit-draft="$emit(previewSelection.usage === 'toCorrectTeacher' ? 'editCorrectionDraft' : 'editDraft', previewSelection)"
                            />
                            <FileListDoneButton
                                v-if="buttonConditions.doneButtonCondition(previewSelection, accountRole, previewSelection.usage)"
                                :file="previewSelection"
                                :primary-color="primaryColor"
                                :button-size="20"
                                @done-file="$emit('doneFile', previewSelection)"
                            />
                            <!-- This info button displays user notes when given by the teacher -->
                            <FileListInfoButton
                                v-if="buttonConditions.infoButtonCondition('userNote', previewSelection.usage, previewSelection, accountRole, person)"
                                :usage="'userNote'"
                                :file="previewSelection"
                                :primary-color="primaryColor"
                                :person="person"
                                :button-size="20"
                            />
                            <!-- This info button displays that the file can't be edited within eklara -->
                            <FileListInfoButton
                                v-if="buttonConditions.infoButtonCondition('fileFormat', previewSelection.usage, previewSelection, accountRole, person)"
                                :usage="'fileFormat'"
                                :file="previewSelection"
                                :primary-color="primaryColor"
                                :person="person"
                                :button-size="54"
                            />
                            <!-- This info button displays that the file was given back to the teacher without an answer -->
                            <FileListInfoButton
                                v-if="buttonConditions.infoButtonCondition('noAnswer', previewSelection.usage, previewSelection, accountRole, person)"
                                :usage="'noAnswer'"
                                :file="previewSelection"
                                :primary-color="primaryColor"
                                :person="person"
                                :button-size="20"
                            />
                            <FileListEyeButton
                                v-if="previewSelection.isMovedPupilUpload || buttonConditions.fileListEyeButtonCondition(previewSelection, previewSelection.usage, accountRole)"
                                :file="previewSelection"
                                :primary-color="primaryColor"
                                :button-size="20"
                                @open-in-editor="$emit('openInEditor', previewSelection)"
                            />
                            <FileListOptionsMenuButton
                                v-if="!previewSelection.isMovedPupilUpload && buttonConditions.fileListOptionsMenuCondition(previewSelection, previewSelection.usage)"
                                :default-options="buttonConditions.fileListOptionsMenuCondition(previewSelection)"
                                :file="previewSelection"
                                :account-role="accountRole"
                                :person="person"
                                :icon-white="true"
                                :button-size="20"
                                @download-file="$emit(previewSelection.usage === 'toCorrectTeacher' ? 'downloadCorrectionFile' : 'downloadFile', previewSelection)"
                                @delete-file="$emit('deleteFile', previewSelection)"
                                @delete-draft="$emit('deleteDraft', previewSelection)"
                                @upload-answer="$emit('uploadAnswer', previewSelection)"
                                @download-main-video="$emit('downloadMainVideo', previewSelection)"
                                @download-d-g-s-video="$emit('downloadDGSVideo', previewSelection)"
                                @play-video="$emit('playVideo', previewSelection)"
                                @play-audio="$emit('playAudio', previewSelection)"
                                @edit-in-editor="$emit('editInEditor', previewSelection)"
                                @edit-in-libre="$emit('editInLibre', previewSelection)"
                                @open-in-editor="$emit('openInEditor', previewSelection)"
                                @video-in-video-clicked="$emit('videoInVideoClicked', previewSelection)"
                                @show-information="()=>{showDetails = true}"
                            />
                        </div>
                    </div>
                    <div>
                        <img
                            :src="previewSrc"
                            class="iconToDunkelgrau icon80"
                        >
                    </div>
                    <p>Fehler beim Laden der Vorschau</p>
                </div>
            </div>
            <div
                v-else
                class="imageContainer"
            >
                <v-img
                    id="dialogMainCard"
                    ref="previewImage"
                    :src="previewSrc"
                    :style="{ transform: `rotate(${rotationAngle}deg)` }"
                    class="filter-dialog__image"
                    cover
                    :lazy-src="fileIcon"
                >
                    <!-- Overlay content for buttons and title -->
                    <!-- <div
                    class="filter-dialog__overlay"
                    :style="`background-color:${getHeaderColor}`"
                > -->
                </v-img>
            </div>
            <div
                class="previewOverlay"
            >
                <p
                    class="filter-dialog__title"
                >
                    {{ fileTitle }}
                </p>

                <!-- <v-btn
                        icon
                        class="filter-dialog__menu-btn"
                    >
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn> -->
                <FilePreviewTileMenu
                    v-if="!fachInfo"
                    ref="filePreviewMenu"
                    :enable-image-rotation="enableImageRotation"
                    :emit-button-press="emitButtonPress"
                    :show-submissions="showSubmissions"
                    :audio-compatible="audioCompatible"
                    :vfs-usage="vfsUsage"
                    :downloadable="downloadable"
                    :save-to-folder="saveToFolder"
                    :dialog-width="dialogWidth"
                    :dialog-height="dialogHeight"
                    :enable-editing="enableEditing"
                    :usage="usage"
                    @open-menu="(value) => { openMenu = value }"
                    @close-dialog="closeDialog"
                    @open-submissions="emitButtonPress"
                    @play-audio="emitButtonPress"
                    @notify-copy-or-move-file="emitButtonPress"
                    @open-save-to-folder-dialog="emitButtonPress"
                />

                <div
                    v-if="fachInfo"
                    class="fachInfoButtonsContainer"
                >
                    <FileListEditButton
                        v-if="!previewSelection.isMovedPupilUpload && buttonConditions.editButtonCondition(previewSelection, previewSelection.usage)"
                        :file="previewSelection"
                        :primary-color="primaryColor"
                        :person="person"
                        :account-role="accountRole"
                        :button-size="20"
                        @edit-in-editor="$emit(previewSelection.usage === 'toCorrectTeacher' ? 'correctInEditor' : 'editInEditor', previewSelection)"
                        @edit-in-libre="$emit(previewSelection.usage === 'toCorrectTeacher' ? 'correctInLibre' : 'editInLibre', previewSelection)"
                        @edit-draft="$emit(previewSelection.usage === 'toCorrectTeacher' ? 'editCorrectionDraft' : 'editDraft', previewSelection)"
                    />
                    <FileListDoneButton
                        v-if="buttonConditions.doneButtonCondition(previewSelection, accountRole, previewSelection.usage)"
                        :file="previewSelection"
                        :primary-color="primaryColor"
                        :button-size="25"
                        @done-file="$emit('doneFile', previewSelection)"
                    />
                    <!-- This info button displays user notes when given by the teacher -->
                    <FileListInfoButton
                        v-if="buttonConditions.infoButtonCondition('userNote', previewSelection.usage, previewSelection, accountRole, person)"
                        :usage="'userNote'"
                        :file="previewSelection"
                        :primary-color="primaryColor"
                        :person="person"
                        :button-size="25"
                    />
                    <!-- This info button displays that the file can't be edited within eklara -->
                    <FileListInfoButton
                        v-if="buttonConditions.infoButtonCondition('fileFormat', previewSelection.usage, previewSelection, accountRole, person)"
                        :usage="'fileFormat'"
                        :file="previewSelection"
                        :primary-color="primaryColor"
                        :person="person"
                        :button-size="25"
                    />
                    <!-- This info button displays that the file was given back to the teacher without an answer -->
                    <FileListInfoButton
                        v-if="buttonConditions.infoButtonCondition('noAnswer', previewSelection.usage, previewSelection, accountRole, person)"
                        :usage="'noAnswer'"
                        :file="previewSelection"
                        :primary-color="primaryColor"
                        :person="person"
                        :button-size="25"
                    />
                    <FileListEyeButton
                        v-if="previewSelection.isMovedPupilUpload || buttonConditions.fileListEyeButtonCondition(previewSelection, previewSelection.usage, accountRole)"
                        :file="previewSelection"
                        :primary-color="primaryColor"
                        :button-size="25"
                        @open-in-editor="$emit('openInEditor', previewSelection)"
                    />
                    <FileListOptionsMenuButton
                        v-if="!previewSelection.isMovedPupilUpload && buttonConditions.fileListOptionsMenuCondition(previewSelection, previewSelection.usage)"
                        :default-options="buttonConditions.fileListOptionsMenuCondition(previewSelection)"
                        :file="previewSelection"
                        :account-role="accountRole"
                        :person="person"
                        :icon-white="true"
                        :button-size="25"
                        @download-file="$emit(previewSelection.usage === 'toCorrectTeacher' ? 'downloadCorrectionFile' : 'downloadFile', previewSelection)"
                        @delete-file="$emit('deleteFile', previewSelection)"
                        @delete-draft="$emit('deleteDraft', previewSelection)"
                        @upload-answer="$emit('uploadAnswer', previewSelection)"
                        @download-main-video="$emit('downloadMainVideo', previewSelection)"
                        @download-d-g-s-video="$emit('downloadDGSVideo', previewSelection)"
                        @play-video="$emit('playVideo', previewSelection)"
                        @play-audio="$emit('playAudio', previewSelection)"
                        @edit-in-editor="$emit('editInEditor', previewSelection)"
                        @edit-in-libre="$emit('editInLibre', previewSelection)"
                        @open-in-editor="$emit('openInEditor', previewSelection)"
                        @video-in-video-clicked="$emit('videoInVideoClicked', previewSelection)"
                        @show-information="()=>{showDetails = true}"
                    />
                </div>
            </div>
        </v-card>
   
        <!-- Loading overlay -->
        <v-overlay
            absolute
            :model-value="showLoadingOverlay"
            style="width: 100% !important; z-index: 500 !important;"
        >
            <v-progress-circular
                v-if="showLoadingOverlay"
                indeterminate
                size="100"
            />
        </v-overlay>

        <FileInformationDialog
            v-if="file"
            v-model:show-details="showDetails"
            :file="file"
            @close="()=>{showDetails = false}"
        />
    </v-dialog>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {
    allowedMimeTypesForLibre,
    allowedMimeTypesForEditor,
}from "@/constants/allowedMimeTypes"
import * as backend from "../../api/backend";
import closeIcon from '../../assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg';
import FilePreviewTileMenu from "./FilePreviewTileMenu";
import FileInformationDialog from "./FileInformationDialog";
import rightIcon from '@/assets/Icons/FaceliftIcons/vor_pfeil.svg';
import leftIcon from '@/assets/Icons/FaceliftIcons/zurueck_pfeil.svg';
import fileIcon from '@/assets/Icons/file-white.svg';
import FileListEditButton from "@/components/SharedViews/Stundenplan/Schueler_und_Lehrer/FileList/FileListEditButton";
import FileListInfoButton from "@/components/SharedViews/Stundenplan/Schueler_und_Lehrer/FileList/FileListInfoButton";
import FileListOptionsMenuButton
    from "@/components/SharedViews/Stundenplan/Schueler_und_Lehrer/FileList/FileListOptionsMenuButton";
import FileListDoneButton from "@/components/SharedViews/Stundenplan/Schueler_und_Lehrer/FileList/FileListDoneButton";
import FileListEyeButton from "@/components/SharedViews/Stundenplan/Schueler_und_Lehrer/FileList/FileListEyeButton";
import * as buttonConditions from "@/components/SharedViews/Stundenplan/Schueler_und_Lehrer/FileList/FileListConditions"

export default {
    name: "FilePreviewDialog",
    components: {
        FilePreviewTileMenu,
        FileListOptionsMenuButton,
        FileListInfoButton,
        FileListEditButton,
        FileListDoneButton,
        FileListEyeButton,
        FileInformationDialog,
    },
    props: {
        files: { required: true },
        enableFullscreenPreview: { required: true, type: Boolean, default: false },
        previewSelection: { required: true, default: { name: 'Vorschau', uploadedName: 'Vorschau' }},
        previewInformation: { required: true },
        previewSelectionIndex: { required: false, default: 0},
        enableEditing: { required: false, type: Boolean, default: false },
        disableEditor: { required: false, type: Boolean, default: false },
        chatMode: { required: false, type: Boolean, default: false },
        fileName: { required: false, type: String, default: '' },
        isUserAuthor: { required: false, type: Boolean, default: false },
        showSubmissions: { required: false, type: Boolean, default: false },
        audioCompatible: { required: false, type: Boolean, default: false },
        vfsUsage: { required: false, type: Boolean, default: false },
        downloadable: { required: false, type: Boolean, default: true },
        saveToFolder: { required: false, type: Boolean, default: false },
        showSaveToFolder: { required: false, type: Boolean, default: false }, // "Im Ordner speichern"-Dialog
        usage: {required: false, type: String, default:'' },
        // Stundenplan FachInfo Stuff
        fachInfo: { required: false, type: Boolean, default: false },
        person: { required: false, type: Object },
        accountRole: { required: false, type: String },
    },
    data: () => ({
        validMimetype: true,
        previewPDF: false,
        showLoadingOverlay: false,

        file: null,

        closeIcon,
        leftIcon,
        rightIcon,
        fileIcon,

        allowedMimeTypesForLibre,
        allowedMimeTypesForEditor,

        fileExtension: '',
        isLibreFile: false,
        isEditorFile: false,
        openMenu: false,
        previewURL: null,

        screenX: 1920,
        screenY: 1080,

        // Stundenplan FachInfo Stuff
        buttonConditions,
        primaryColor: '#706f6f',
        dialogWidth: "auto", // Width of the dialog (adjusted later)
        dialogHeight: "auto", // Height of the dialog (adjusted later)
        initialWidth: '',
        initialHeight: '',
        fileTitle: '',
        rotationAngle: 0,

        showDetails: false, 
    }),
    computed: {
        ...mapState('util', ['windowWidth', 'windowHeight']),
        dialogModel() {
            return this.enableFullscreenPreview;
        },
        enableImageRotation() {
            // also check for author
            return this.previewURL && !this.previewPDF && this.isUserAuthor;
        },
        previewSrc(){
            return this.previewURL ? this.previewURL : this.previewPDF ? this.previewPDF : this.fileIcon;
        },
        calculateAspectRatio() {
            if (this.windowWidth < 600) {
                return '1';
            } else if (this.windowWidth < 900) {
                return '1.4';
            } else if (this.windowWidth < 1500) {
                return '2.0';
            } else {
                return '2.6';
            }
        },
    },
    watch: {
        async previewSelection(newVal){
            if(newVal && newVal.mimetype){
              await this.initFile();
              this.initThumbnail();
              this.initFileType();
            }
        },
        // enableFullscreenPreview: {
        //     handler() {
        //         if (this.enableFullscreenPreview === true) {
        //             this.initThumbnail();
        //         }
        //     },
        // },
        // previewSelectionIndex: {
        //     handler() {
        //         if (this.enableFullscreenPreview === true) {
        //             this.initThumbnail();
        //         }
        //     },immediate: true,
        // },
        previewURL(){
            this.setImageDimensions();
        }
    },
    async mounted() {
        this.evalScreenSize();
        await this.initFile();
        await this.initThumbnail();
        await this.initFileType();
    },
    methods: {
        ...mapActions('subjects', ['getThumbnail']),
        ...mapActions('files', ['rotateGivenImageByGivenDeg']),
        ...mapActions('mimeTypes', ['getFileExtension']),
        clickOutsideTheDialog(event){
            event.stopPropagation();
            console.log('dingdong', event.target, (event.target.classList[0] === 'v-list-item__title'));
            if(!((event.target.classList[0] === 'v-list-item__content') || (event.target.classList[0] === 'v-list-item__title'))){
                this.closeFullscreenDialog()
            }
        },
        setImageDimensions() {
            const img = new Image();
            img.src = this.previewURL;
            // When the image is loaded, calculate the aspect ratio and set the dialog size accordingly
            img.onload = () => {
                const imgRatio = img.width / img.height;
                if (imgRatio < 1) {
                    // Portrait image: set height to 90vh and adjust width automatically
                    this.dialogHeight = Math.min(img.height, (this.windowHeight * 0.9));
                    // somehow on larger screens there is still small dialog background visible, this is a dirty fix for that
                    this.dialogWidth = img.width * imgRatio *0.9; 
                } else if(imgRatio > 1){
                    // Landscape image: set width to 90vw and adjust height automatically
                    this.dialogWidth = Math.min(img.width, (this.windowWidth * 0.9)); // Max 90% of viewport width
                    this.dialogHeight = img.height *imgRatio;
                }else{

                    this.dialogWidth = img.width * 0.9; // Max 90% of viewport width
                    this.dialogHeight = img.height * 0.9;
                }
                this.initialHeight = this.dialogHeight;
                this.initialWidth = this.dialogWidth;
            };
        },
        async initFile() {
            const { file, fileAccessToken, accessToken, _id } = this.previewInformation;

            const fileId = (file && file._id) ? file._id : (file || _id);
            const token = fileAccessToken || accessToken;

            const res = await backend.getFileInformation(fileId, token);
            this.file = await res.json();
            this.fileTitle = this.file.uploadedName;
            this.isLibreFile = false;
            this.isEditorFile = false;
            if (this.file && this.allowedMimeTypesForEditor.includes(this.file.mimetype)) {
                this.isEditorFile = true;
            } else if (this.file && this.allowedMimeTypesForLibre.includes(this.file.mimetype)) {
                this.isLibreFile = true;
            }
        },
        async initFileType() {
          if (!this.isLibreFile) {
            return;
          }
          this.fileExtension = await this.getFileExtension(this.file.mimetype);
        },
        async initThumbnail() {
            // The associated attribute isn't used at the moment, so I commented this method out temporarily
            // this.validateMimetype();
            this.previewURL = this.previewSelection.thumbnail ? this.previewSelection.thumbnail : this.previewSelection;
        },

        async rotateImage() {
            if (this.enableImageRotation) {
                this.showLoadingOverlay = true;
              
                 this.rotationAngle = (this.rotationAngle + 90) % 360;

                if(this.rotationAngle === 90 || this.rotationAngle === 270){
                    this.dialogHeight = this.initialWidth;
                    this.dialogWidth = this.initialHeight;
                }else{
                    this.dialogHeight = this.initialHeight;
                    this.dialogWidth = this.initialWidth;
                }
                this.showLoadingOverlay = false;
            }
        },

        validateMimetype() {
            if (this.chatMode) {
                if (allowedMimeTypesForEditor.includes(this.fileName.split('.').pop().toLowerCase())) {
                    this.validMimetype = true;
                } else if (allowedMimeTypesForLibre.includes(this.fileName.split('.').pop().toLowerCase())) {
                    this.validMimetype = true;
                } else {
                    this.validMimeType = false;
                }
            } else {
                const fileName = this.previewSelection.uploadedName ? this.previewSelection.uploadedName : this.previewSelection.title;
                if (allowedMimeTypesForEditor.includes(fileName.split('.').pop().toLowerCase())) {
                    this.validMimetype = true;
                } else if (allowedMimeTypesForLibre.includes(fileName.split('.').pop().toLowerCase())) {
                    this.validMimetype = true;
                } else {
                    this.validMimetype = false;
                }
            }
        },
        getMimeType(mimetype) {
        const mimeTypes = {
            'image/jpeg': 'JPEG - Bild',
            'image/jpg': 'JPG - Bild',
            'image/png': 'PNG - Bild',
            'image/gif': 'GIF - Bild',
            'image/bmp': 'BMP - Bild',
            'image/tiff': 'TIFF - Bild',
            'image/heic': 'HEIC - Bild',
            'image/jp2': 'JPEG 2000 - Bild',
            'image/svg+xml': 'SVG - Bild',
            
            'application/pdf': 'PDF - Datei',
            'application/rtf': 'RTF - Dokument',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'Excel - XLSX Datei',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'Word - DOCX Datei',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'PowerPoint - Präsentation',
            'application/vnd.sealed.xls': 'Versiegelte Excel Datei',
            'application/vnd.sealed.ppt': 'Versiegelte PowerPoint Datei',
            'application/vnd.sealed.tiff': 'Versiegelte TIFF Datei',
            'application/vnd.ms-xpsdocument': 'XPS - Dokument',
            'application/xls': 'Excel - XLS Datei',
            'application/x-zip-compressed': 'ZIP - Komprimierte Datei',
            'application/zip': 'ZIP - Komprimierte Datei',
            'application/msword': 'Word - DOC Datei',
            'application/vnd.oasis.opendocument.text': 'OpenDocument - Text Datei',
            'application/vnd.oasis.opendocument.spreadsheet': 'OpenDocument - Tabelle',
            'application/vnd.oasis.opendocument.presentation': 'OpenDocument - Präsentation',
            'application/vnd.oasis.opendocument.graphics': 'OpenDocument - Grafik',
            'application/octet-stream': 'Binäre Datei',

            'audio/mp3': 'MP3 - Audio',
            'audio/mpeg': 'MPEG - Audio',
            'audio/mp4': 'MP4 - Audio',
            'audio/MP4A-LATM': 'MP4A - Audio',
            'audio/asc': 'ASC - Audio',
            'audio/aac': 'AAC - Audio',
            'audio/ogg': 'OGG - Audio',
            'audio/wav': 'WAV - Audio',
            'audio/x-wav': 'WAV - Audio',

            'video/mp4': 'MP4 - Video',
            'video/MP4V-ES': 'MP4V - Video',
            'video/vnd.dece.mp4': 'DECE - MP4 Video',
            'video/vnd.nokia.mp4vr': 'Nokia - MP4 Video',
            'video/vnd.uvvu.mp4': 'UVVU - MP4 Video',
            'video/vnd.sealedmedia.softseal.mov': 'Sealed - MOV Video',
            'video/BMPEG': 'MPEG - Video',
            'video/mpeg4-generic': 'MPEG4 - Video',
            'video/quicktime': 'QuickTime - Video',
            'video/webm': 'WebM - Video',

            'text/csv': 'CSV - Datei',
            'text/rtf': 'RTF - Text',
            'text/plain': 'Einfacher Text',
            'text/vnd.ascii-art': 'ASCII-Art - Text',
            'text/csv-schema': 'CSV - Schema',
        };

        // Return the mapped value, or if not found, the original mimetype
            return mimeTypes[mimetype] || mimetype;
        },
        evalScreenSize() {
            let win = window;
            let doc = document;
            let docElem = doc.documentElement;
            let body = doc.getElementsByTagName('body')[0];
            this.screenX = win.innerWidth || docElem.clientWidth || body.clientWidth;
            this.screenY = win.innerHeight || docElem.clientHeight || body.clientHeight;
            // if(this.screenX < 800 || this.screenY < 800) {
            //     this.smallUI = true;
            // }
            // alert(this.screenX + ' × ' + this.screenY);
        },

        handleKeydown(event) {
                        switch (event.code) {
                case 'ArrowLeft':
                    this.cyclePrev();
                    break;
                case 'KeyA':
                    this.cyclePrev();
                    break;
                case 'ArrowRight':
                    this.cycleNext();
                    break;
                case 'KeyD':
                    this.cycleNext();
                    break;
                case 'Enter':
                    if (this.validMimetype) {
                        //this.openEditor();
                    }
                    break;
                case 'Space':
                    if (this.validMimetype) {
                        //this.openEditor();
                    }
                    break;
                case 'Escape':
                    this.closeFullscreenDialog()
                    break;
                default:
                    break;
            }
        },
        closeFullscreenDialog() {
            if(this.openMenu){
                this.openMenu = false;
                return
            }
            if (!this.showSaveToFolder && !this.openMenu) {
                this.$emit('close');
            }
        },
        closeDialog(){
            this.$emit('close');
        },
        cyclePrev() {
            this.$emit('cyclePrev');
        },
        cycleNext() {
            this.$emit('cycleNext');
        },
        openEditor() {
          if (this.isEditorFile) {
            this.$emit('openEditor');
          } else if (this.isLibreFile) {
            this.$emit('openLibre');
          }
        },
        emitButtonPress(emit) {
            switch (emit) {
                case "rotate":
                    this.rotateImage();
                    break;
                case "edit":
                    this.$emit('openEditor');
                    break;
                case "download":
                    this.$emit('download');
                    break;
                case "openSubmissions":
                    this.$emit('openSubmissions');
                    break;
                case "playAudio":
                    this.$emit('playAudio');
                    break;
                case "notifyCopyOrMoveFile":
                    this.$emit('notifyCopyOrMoveFile');
                    break;
                case "openSaveToFolderDialog":
                    this.$emit('openSaveToFolderDialog');
                    break;
                case "details":
                    this.showDetails = true;
                    break;
                default:
                    console.error('No action found.')
            }
        }
    }
}
</script>

<style scoped lang="scss">
.fachInfoButtonsContainer {
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    top: 1%;
    right: 1%;
}
.stiftButton {
    color: white;
    border-radius: 6px;
    font-size: 40px !important;
    background: rgb(var(--v-theme-chatColor));
    position: fixed;
    z-index: 1;
    height: 50px;
    width: 50px;
    margin-top: 24px;
    right: 10vw;
}

.textTransformNone {
    text-transform: none;
}

.sidebarButton {
    z-index: 3;
    width: 49px !important;
    height: 49px !important;
    border-radius: 8px;
    pointer-events: all;
    margin-left: 2vw;
    margin-top: 3vh;
    position: fixed;

    p {
        line-height: 0;
        font-size: 55px;
        margin-bottom: 0;
        font-weight: 300;
        color: #333;
    }
}

@media only screen and (max-width: 900px) {
    .stiftButton {
        right: 13vw !important;
    }
}

@media only screen and (max-width: 600px) {
    .stiftButton {
        right: 18vw !important;
    }
}
.filter-dialog__card {
  position: relative;
  margin: 0;
  padding: 0;
  overflow: hidden; /* Ensure no overflow or scrolling */
}

/* The image container */
.filter-dialog__image {
  object-fit: contain; /* Ensure the image fits without distortion */
  width: auto;
  height: auto;
  max-width: 90vw;
  max-height: 90vh;
}

/* Overlay for buttons and title */
.filter-dialog__overlay {
  position: absolute;
  left: 0;
  right: 0;
  padding: 0.25rem;
  min-height:45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 205 !important;
}

.filter-dialog__header {
  display: flex;
  align-items: center;
}

/* Title styling */
.filter-dialog__title {
  margin-left: .5rem;
  font-weight: bold;
  font-size: 18px;
  color: white;
   white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 50px);
}

/* Menu button at the bottom-right */
.filter-dialog__menu-btn {
  color: white;
}

 .v-dialog__content--active{
    background-color: rgba(145, 150, 158, 0.6);
    backdrop-filter: blur(8px);
    
}
.icon80{
    margin-top: 50px;
    max-width: 180px;
    width: 180px;
}
.previewOverlay{
    box-shadow:0px 10px 60px rgba(0, 0, 0, 0.6) inset;
    width: 100%;
    min-width: 100%;
    min-height: 100%;
    height: 100%;
    max-height: 100%;
    border-radius: 8px;
    position: absolute;
    top: 0; left: 0;
    aspect-ratio: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    padding: 10px 0px 0px 0px;
}
.imageContainer{
    position: relative;
    width: 100%;
    height: 100%;
}
</style>
