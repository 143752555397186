<template>
    <div
        style="width: 100%"
        @click="$emit('focusMe')"
    >
        <div
            id="choiceObject"
            ref="choiceObject"
            style="width: 100%"
        >
            <div
                class="greyDivBorderColor"
                style="width: 100%"
                :class="currentFontSize"
            >
                <span class="visually-hidden">Multiple Choice Frage: </span>
                <div
                    :class="showTeacherUI ? 'greyDiv' : ''"
                    style="padding: 12px; margin: 8px"
                >
                    <!-- Teacher Frage input -->
                    <textarea
                        v-if="showTeacherUI"
                        ref="textAreaQuestionTitle"
                        v-model="multipleChoice.mQuestionTitle"
                        type="text"
                        rows="1"
                        style="resize: none; overflow: hidden; min-width: 360px; width: 100%"
                        placeholder="Geben Sie hier die Frage ein..."
                        @keydown.enter="focusFirstOption()"
                        @keydown="delayedResize"
                    />
                    <!-- Pupil/Correction Frage Heading -->
                    <span
                        v-else
                        :aria-label="'Multiple Choice: ' + multipleChoice.mQuestionTitle"
                    >{{ multipleChoice.mQuestionTitle }}</span>
                </div>
                <!-- Teacher Lösung Input -->
                <div v-if="showTeacherUI">
                    <div
                        v-for="(txt, i) in multipleChoice.mQuestions"
                        :key="'question'+i"
                        class="greyDiv"
                        style="padding: 12px; margin: 8px"
                    >
                        <div style="display: flex; flex-direction: row; align-items: center">
                            <!-- Also change size of Checkbox with sizeState: :class="`${'checkboxSize' + sizeState} multipleChoiceCheckbox`" -->
                            <input
                                v-model="txt.correct"
                                type="checkbox"
                                style="margin-left: 8px; margin-right: 12px; -webkit-appearance: none;"
                                :class="`${'checkboxSize' + sizeState} multipleChoiceCheckbox ${!exportMode ? 'webkitClass' : ''}`"
                            >
                            <input
                                :ref="i === multipleChoice.mQuestions.length - 1 ? 'lastoption' : i === 0 ? 'firstoption' : ''"
                                v-model="txt.text"
                                placeholder="Geben Sie hier den Optionstext ein..."
                                style="width: 100%; border-radius: 6px"
                                @keydown.enter="focusNextTextField(i)"
                                @keydown.delete="deleteTextFieldIfEmpty(i)"
                            >
                        </div>
                    </div>
                </div>
                <!-- Pupil Lösung Input -->
                <div v-else-if="showPupilUI">
                    <div
                        v-for="(txt, i) in multipleChoice.mQuestions"
                        :key="'question'+i"
                        :class="txt.checked ? 'editorColorPrimaryBackground greyDiv' : 'greyDiv'"
                        style="padding: 12px; margin: 8px"
                    >
                        <div style="display: flex; flex-direction: row; align-items: center">
                            <input
                                v-model="txt.checked"
                                type="checkbox"
                                style="margin-left: 8px; margin-right: 12px; -webkit-appearance: none;"
                                tabindex="0"
                                :class="`${'checkboxSize'+ sizeState} ${txt.checked ? 'multipleChoiceCheckboxPupil' : 'multipleChoiceCheckbox'} webkitClass`"
                            >
                            <!--                                   :class="`${'checkboxSize'+ sizeState}`"-->
                            <span>{{ txt.text }}</span>
                        </div>
                    </div>
                </div>
                <!-- Correction Answers -->
                <v-tooltip
                    v-else-if="showCorrectionUI || showViewerUI"
                    location="right"
                    :disabled="!elementOnCurrentPage"
                >
                    <template v-slot:activator="{ props }">
                        <div v-bind="props">
                            <div
                                v-for="(txt, i) in multipleChoice.mQuestions"
                                :key="'question'+i"
                                :class="correctionShowSolution
                                    ? (txt.correct ? 'editorColorPrimaryBackground' : 'editorColorWrongBackground')
                                    : !txt.checked
                                        ? '' : txt.checked === txt.correct
                                            ? '' : 'editorColorWrongBackground'"
                                class="greyDiv"
                                style="padding: 12px; margin: 8px"
                                @click="correctionShowSolution = !correctionShowSolution"
                            >
                                <div style="display: flex; flex-direction: row; align-items: center">
                                    <input
                                        v-if="correctionShowSolution"
                                        v-model="txt.correct"
                                        disabled
                                        type="checkbox"
                                        style="margin-left: 8px; margin-right: 12px; -webkit-appearance: none !important; "
                                        :class="`${'checkboxSize' + sizeState} ${!exportMode ? 'webkitClass' : ''}
                                            ${txt.correct ? 'multipleChoiceCheckboxCorrect' : 'multipleChoiceCheckboxWrong'}`"
                                    >
                                    <input
                                        v-else
                                        v-model="txt.checked"
                                        disabled
                                        type="checkbox"
                                        style="margin-left: 8px; margin-right: 12px; -webkit-appearance: none !important;"
                                        :class="`${'checkboxSize' + sizeState} ${!exportMode ? 'webkitClass' : ''}
                                            ${!txt.checked ? 'multipleChoiceCheckboxNeutral' : txt.checked === txt.correct ? 'multipleChoiceCheckboxCorrect' : 'multipleChoiceCheckboxWrong'}`"
                                    >
                                    <span :class="correctionShowSolution ? 'colorWhite' : txt.checked === txt.correct && txt.checked && txt.correct ? 'editorColorPrimary' : txt.checked && txt.checked !== txt.correct ? 'colorWhite' : ''">{{ txt.text }}</span>
                                </div>
                            </div>
                        </div>
                    </template>
                    <span>{{ correctionShowSolution ? 'Lösung verstecken' : 'Lösung anzeigen' }}</span>
                </v-tooltip>
            </div>
            <!-- Unterer Bereich -->
            <div
                v-if="showTeacherUI"
                class="editorOptionsFade"
                style="display: flex; flex-direction: row; margin-top: 8px"
            >
                <v-btn
                    class="editorColorPrimaryBackgroundOnHover greyDiv"
                    variant="flat"
                    color="#ededed"
                    style="flex-grow: 1; height: 48px; min-height: 48px; cursor: pointer"
                    @click="multipleChoice.mQuestions.push({ text: '' , correct: false, checked: false })"
                >
                    <span
                        class="editorColorPrimaryOnHoverChildColorToWhite editorColorPrimary"
                        style="font-size: xx-large"
                    >+</span>
                </v-btn>
                <v-btn
                    v-ripple="multipleChoice.mQuestions.length > 1"
                    class="greyDiv"
                    variant="flat"
                    color="#ededed"
                    :class="multipleChoice.mQuestions.length > 1 ? 'editorColorWrongBackgroundOnHover' : ''"
                    style="height: 48px; min-height: 48px; cursor: pointer; width: 48px; min-width: 48px; margin-left: 4px"
                    @click="multipleChoice.mQuestions.length > 1 ? multipleChoice.mQuestions.pop() : {}"
                >
                    <span
                        style="font-size: xx-large"
                        :class="multipleChoice.mQuestions.length > 1 ? 'editorColorWrongOnHoverChildColorToWhite editorColorWrong' : 'controlsDisabled'"
                    >-</span>
                </v-btn>
            </div>
            <!-- Controls rechts rausgeschoben -->
            <div
                v-if="showTeacherUI"
                class="editorOptionsFade"
            >
                <div style="display: flex; flex-direction: column; position: absolute; right: -56px; top: 60px; width: 48px; height: 48px; min-width: 48px">
                    <v-btn
                        class="editorColorPrimaryBackgroundOnHover"
                        style="width: 48px; height: 48px; min-width: 48px; margin-bottom: 6px"
                        @click="changeSize"
                    >
                        <img
                            style="height: 32px"
                            :src="currentSizeIcon"
                            class="iconToWhiteHover"
                            alt="Größe des Elements verändern"
                        >
                    </v-btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import sizeSmallIcon from "@/assets/Icons/EditorRedesign/schrift_klein.svg";
import sizeMediumIcon from "@/assets/Icons/EditorRedesign/schrift_mittel.svg";
import sizeLargeIcon from "@/assets/Icons/EditorRedesign/schrift_groß.svg";
import {v4 as uuidv4} from "uuid";

export default {
    name: "EditorMultipleChoice",
    props: {
        config: {type: Object, required: true},
        state: { type: String, required: true },
        showResults: {type: Boolean, required: false, default: false},
        propsDisabled: {type: Boolean, required: false, default: false},
        canvasWidth: {type: Number, required: true},
        canvasHeight: {type: Number, required: true},
        targetLang: {type: String, required: false, default: 'de'},
        translate: {type: Boolean, required: false, default: false},
        exportMode: {type: Boolean, required: false, default: false},
        tabIndex: {type: Number, required: false, default: 0},
        elementOnCurrentPage: { type: Boolean, required: false },
        previewPupilUI: { type: Boolean, required: false, default: false },
    },
    data() {
        return {
            multipleChoice: {
                mQuestionTitle: '',
                mQuestions: [{text: '', correct: false, checked: false}]
            },
            sizeState: 0,
            correctionShowSolution: false,

            alreadyTranslated: false, //whether the texts were already translated once
            //store original texts
            untranslatedTask: null, untranslatedAnswers: [], //store translated texts (so translating is only done once)
            translatedTask: null, translatedAnswers: [],

            sizeSmallIcon,
            sizeMediumIcon,
            sizeLargeIcon,
        }
    },
    computed: {
        // ...mapGetters("auth",["accountRole"]),
        currentFontSize() {
            return this.sizeState ? (this.sizeState === 2 ? 'editorFontSize3' : 'editorFontSize2') : 'editorFontSize1';
        },

        currentSizeIcon() {
            return this.sizeState ? (this.sizeState === 2 ? this.sizeLargeIcon : this.sizeMediumIcon) : this.sizeSmallIcon;
        },

        showPupilUI() {
            return this.exportMode || this.state === 'pupil' || this.previewPupilUI;
        },
        showTeacherUI() {
            return !this.exportMode && !this.previewPupilUI && this.state === 'creator';
        },
        showCorrectionUI() {
            return this.state === 'teacher' || this.state === 'viewerTeacher';
        },
        showViewerUI() {
            return this.state === 'viewer' || this.state === 'viewerPupil';
        },
    },
    watch: {
        translate() {
            this.toggleTextLanguage();
        },
        multipleChoice: {
            handler(val, oldVal) {
                this.saveState();
            }, deep: true
        },
        sizeState: {
            handler(val, oldVal) {
                this.saveState();
            }
        },
        previewPupilUI: {
            handler(val, oldVal) {

                for(let i=0; i<this.multipleChoice.mQuestions.length; i++) {
                    delete this.multipleChoice.mQuestions[i].checked;
                }
                // console.log(this.config.mulitpleChoiceConfig);
            }
        }
    },
    mounted() {
        if (!this.config.mulitpleChoiceConfig) {
            this.config.mulitpleChoiceConfig = {};
            this.config.identifier = uuidv4();
            this.config.mulitpleChoiceConfig = this.multipleChoice;
            this.config.mulitpleChoiceConfig.sizeState = this.sizeState;
        } else {
            if(this.config._id) {
                this.config.identifier = this.config._id;
            }
            this.multipleChoice = this.config.mulitpleChoiceConfig;
            this.sizeState = this.config.mulitpleChoiceConfig.sizeState;
        }
    },
    methods: {
        ...mapActions('translation', ['translateToTargetLang']),

        delayedResize() {
            window.setTimeout(this.resize, 0);
        },

        resize() {
            const textArea =  this.$refs.textAreaQuestionTitle
            textArea.style.height = 'auto';
            textArea.style.height = textArea.scrollHeight + 'px';
        },

        focusFirstOption() {
            if(this.multipleChoice.mQuestions.length === 1) {
                this.$refs.lastoption[0].focus();
            } else {
                this.$refs.firstoption[0].focus();
            }
        },

        async focusNextTextField() {
            this.multipleChoice.mQuestions.push({ text: '' , correct: false, checked: false });
            await this.$nextTick();
            this.$refs.lastoption[0].focus();
        },

        async deleteTextFieldIfEmpty(i) {
            if(this.multipleChoice.mQuestions[i].text.length === 0 && this.multipleChoice.mQuestions.length > 1) {
                this.multipleChoice.mQuestions.splice(i, 1);
                await this.$nextTick();
                this.$refs.lastoption[i].focus();
            }
        },

        changeSize(event) {
            event.stopPropagation();
            switch (this.sizeState) {
                case 0:
                    this.sizeState = 1;
                    break;
                case 1:
                    this.sizeState = 2;
                    break;
                case 2:
                    this.sizeState = 0;
                    break;
                default:
                    break;
            }
        },

        saveState() {
            // save values by overwriting the multipleChoiceConfig properties
            // which get saved in the db schoolSubjectAppointmentUploadMessage
            if(!this.previewPupilUI) {
                this.config.mulitpleChoiceConfig = this.multipleChoice;
                this.config.mulitpleChoiceConfig.sizeState = this.sizeState;
            }
        },

        async toggleTextLanguage() {
            //translate and replace displayed texts
            if (this.translate && !this.alreadyTranslated) {
                this.translatedTask = await this.translateToTargetLang({
                    targetLang: this.targetLang,
                    textToTranslate: this.config.startTextTask
                });
                this.config.startTextTask = this.translatedTask;

                for (let i = 0; i < this.config.multipleChoices.length; i++) {
                    this.translatedAnswers[i] = await this.translateToTargetLang({
                        targetLang: this.targetLang,
                        textToTranslate: this.config.multipleChoices[i].text
                    });
                    this.config.multipleChoices[i].text = this.translatedAnswers[i];
                }

                this.alreadyTranslated = true;
            }
            //just replace displayed texts with stored translated texts
            else if (this.translate && this.alreadyTranslated) {
                this.config.startTextTask = this.translatedTask;
                for (let i = 0; i < this.config.multipleChoices.length; i++) {
                    this.config.multipleChoices[i].text = this.translatedAnswers[i];
                }
            }
            //set texts to original language
            else {
                this.config.startTextTask = this.untranslatedTask;
                for (let j = 0; j < this.config.multipleChoices.length; j++) {
                    this.config.multipleChoices[j].text = this.untranslatedAnswers[j];
                }
            }
        },
    }
}
</script>

<style scoped>
    input:focus {
        outline: none;
    }
    textarea:focus {
        outline: none;
    }

    .colorWhite {
        color: white;
    }

    .checkboxSize0::before {
        width: 35px;
        height: 35px;
        background-size: 25px 25px;
    }
    .checkboxSize1::before {
        width: 40px;
        height: 40px;
        background-size: 30px 30px;
    }
    .checkboxSize2::before {
        width: 45px;
        height: 45px;
        background-size: 35px 35px;
    }

    .v-input {
        font-size: inherit;
    }
</style>

<style lang="scss">
    .webkitClass {
        -webkit-appearance: none !important;
        width: 35px;
        height: 35px;
        position: relative !important;
    }

    // Multiple Choice Styling after this guide: https://boolie.org/css-checkbox-styling/
    .multipleChoiceCheckbox {
        position: absolute;
    }
    .multipleChoiceCheckbox {
        z-index: 0;
      //-webkit-appearance: none;
    }
    .multipleChoiceCheckbox::before {
        background-color: white;
        border: 2px solid rgb(var(--v-theme-editorprimary));
        border-radius: 6px;
        display: block;
        content: "";
        float: left;
        margin-right: 5px;
        z-index: 5;
        position: relative;
    }
    input.multipleChoiceCheckbox:checked::before {
        background-color: rgb(var(--v-theme-editorprimary));
        background-image: url('../../assets/Icons/fertig-weiss-74.svg');
        background-position: center;
    }

    // Multiple Choice for pupils (difference in checked)
    .multipleChoiceCheckboxPupil {
      position: absolute;
    }
    .multipleChoiceCheckboxPupil {
        width: 35px;
        height: 35px;
        position: relative;
        z-index: 0;
      //-webkit-appearance: none;
    }
    .multipleChoiceCheckboxPupil::before {
      background-color: white;
      border: 2px solid rgb(var(--v-theme-editorprimary));
      border-radius: 6px;
      display: block;
      content: "";
      float: left;
      margin-right: 5px;
      z-index: 5;
      position: relative;
    }
    input.multipleChoiceCheckboxPupil:checked::before {
      background-color: white;
      background-image: url('../../assets/Icons/fertig-gruen-74.svg');
      background-position: center;
    }

    // Multiple Choice for Correct Answers
    .multipleChoiceCheckboxCorrect {
      position: absolute;
    }
    .multipleChoiceCheckboxCorrect {
      position: relative;
      z-index: 0;
      -webkit-appearance: none;
    }
    .multipleChoiceCheckboxCorrect::before {
      background-color: white;
      border-radius: 6px;
      display: block;
      content: "";
      float: left;
      margin-right: 5px;
      z-index: 5;
      position: relative;
      background-image: url('../../assets/Icons/fertig-gruen-74.svg');
      background-position: center;
    }

    // Multiple Choice for Wrong Answers
    .multipleChoiceCheckboxWrong {
      position: absolute;
    }
    .multipleChoiceCheckboxWrong {
      position: relative;
      z-index: 0;
      -webkit-appearance: none;
    }
    .multipleChoiceCheckboxWrong::before {
      background-color: white;
      border-radius: 6px;
      display: block;
      content: "";
      float: left;
      margin-right: 5px;
      z-index: 5;
      position: relative;
      background-image: url('../../assets/Icons/abbrechen-rot-08.svg');
      background-position: center;
    }

    // Multiple Choice for Wrong Answers
    .multipleChoiceCheckboxNeutral {
      position: absolute;
    }
    .multipleChoiceCheckboxNeutral {
      position: relative;
      z-index: 0;
      -webkit-appearance: none;
    }
    .multipleChoiceCheckboxNeutral::before {
      background-color: white;
      border-radius: 6px;
      display: block;
      content: "";
      float: left;
      margin-right: 5px;
      z-index: 5;
      position: relative;
    }
</style>
