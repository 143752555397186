<template>
    <v-menu
        v-model="menuOpen"
        :offset="[0,10]"
        :close-on-content-click="false"
    >
        <template v-slot:activator="{ props }">
            <div
                class="menuActivator"
                :class="{'errorDetected': errorStatus}"
                v-bind="props"
            >
                <input
                    ref="autocompleteTextfield"
                    class="autocompleteTextfield"
                    type="text"
                    :placeholder="labelText"
                    :value="autocompleteValue"
                    @input="handleInputChange"
                    @keydown.enter="closeMenu"
                >
                <v-icon
                    end
                    style="font-size: small; color: rgb(var(--v-theme-dunkelgrau))"
                    :style="`transform: rotate(${menuOpen ? '180' : '0'}deg)`"
                >
                    fas fa-chevron-down
                </v-icon>
                <p v-if="errorStatus">
                    {{ errorMessage }}
                </p>
            </div>
        </template>
        <div class="menuContent">
            <div
                v-if="customEntry"
                class="menuItem"
            >
                <v-btn
                    style="text-transform: inherit"
                    block
                    variant="text"
                    @click="callCustomFunction"
                >
                    <v-icon
                        start
                        style="font-size: small; color: rgb(var(--v-theme-rot))"
                    >
                        fas fa-times
                    </v-icon>
                    Nicht fortsetzen
                </v-btn>
            </div>
            <div
                v-for="(item, index) in displayedItems"
                :key="`${item + index}`"
                class="d-flex justify-center align-center menuItem"
            >
                <v-btn
                    block
                    variant="text"
                    style="text-transform: inherit"
                    @click="handleItemSelected(item)"
                >
                    {{ item }}
                </v-btn>
            </div>
        </div>
    </v-menu>
</template>

<script>
export default {
    name: 'CustomDropDownAutocomplete',
    props: {
        items: { required: true, type: Array },
        index: { required: true, type: Number },
        defaultText: { required: false, type: String, default: 'Gruppenname' },
        rules: { required: false, type: Array, default: () => [] },
        customEntry: { required: false, type: Boolean, default: false },
        customFunction: { required: false, type: Function, default: () => {} },
        givenEntry: { required: false, type: String, default: '' },
    },
    data: () => ({
        menuOpen: false,

        labelText: '',
        autocompleteValue: '',
        searchString: '',

        // Error Stuff
        errorMessage: null,
    }),
    computed: {
        displayedItems() {
            return this.items.filter(item => item.includes(this.searchString));
        },
        errorStatus() {
            return !!this.errorMessage;
        }
    },
    mounted() {
        this.labelText = this.defaultText;
        this.autocompleteValue = this.givenEntry;
    },
    methods: {
        handleInputChange(event) {
            const input = this.$refs.autocompleteTextfield.value;
            this.autocompleteValue = input;
            this.searchString = input;
            this.errorMessage = null;

            this.checkForErrors(input);

            this.$emit('updateGroupName', input);
        },
        handleItemSelected(item) {
            this.autocompleteValue = item;
            this.errorMessage = null;

            this.checkForErrors(item);

            this.menuOpen = false;
            this.$emit('updateGroupName', item);
        },
        checkForErrors(item) {
            //Check if text meets the rules, mark error if it doesnt
            for (let i = 0; i < this.rules.length; i++) {
                const rule = this.rules[i];
                const ruleCheck = rule(item, this.index);
                if (typeof ruleCheck === 'string') {
                    this.errorMessage = ruleCheck;
                    return true;
                }
            }
            this.errorMessage = null;
            return false;
        },
        callCustomFunction() {
            this.errorMessage = null;
            this.menuOpen = false;
            this.customFunction();
        },
        closeMenu() {
            this.menuOpen = false;
        }
    }
}
</script>

<style scoped lang="scss">
.menuActivator {
    padding: 0.25em;
    width: 80%;
    background-color: rgb(var(--v-theme-hellgrau));
    border-radius: 4px;
}
.errorDetected {
    border: 1px solid rgb(var(--v-theme-rot));

    p {
        color: rgb(var(--v-theme-rot));
    }
}

.menuContent {
    height: 250px;
    overflow-y: scroll;
    background-color: rgb(var(--v-theme-grau));
}

.menuItem {
    padding: 0.25em;
}
</style>
