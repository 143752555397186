<template>
    <v-dialog
        v-model="showDialogGDPR"
        persistent
        style="border-radius:8px;"
        class="container"
        :width="windowWidth > 900 ? '800px':'80vw'"
    >
        <div style="background-color: white;">
            <v-overlay
                :model-value="showLoadingOverlay"
                style="top: -50%;"
            >
                <p>
                    {{ `${confirmQuitAdv ? 'Kündigung' : 'Vertrag'}` + ' wird versendet, dies kann einen Moment in Anpruch nehmen. Bitte haben Sie Geduld.' }}
                </p>

                <v-progress-circular
                    v-if="showLoadingOverlay"
                    indeterminate
                    size="100"
                    style="position: absolute; left: 40%; right: 40%;"
                />
            </v-overlay>
           
            <div
                style="display: flex; justify-content: space-between; align-items: center; background-color:#343e55;"
                class="px-2 py-2"
            >
                <span
                    class="text"
                    style="font-weight: bold; font-size: large; color: white"
                >
                    Auftragsdatenverarbeitung
                </span>
                <v-btn
                    size="small"
                    elevation="0"
                    class="pa-0"
                    icon="$vuetify"
                    variant="plain"
                    @click="() => { setShowDialogGDPR(false) }"
                >
                    <img
                        :src="cancelIcon"
                        class="icon20 iconToWhite"
                        alt="schließen"
                    >
                </v-btn>
            </div>
            
            
            
            <div
                v-if="!contractSigned"
                class="d-flex flex-column align-center"
            >
                <simple-bar
                    class="scroll-area"
                    :auto-hide="false"
                    :settings="settings"
                    style="box-shadow: 1px 3px 3px silver"
                >
                    <div class="d-flex flex-column align-center">
                        <div
                            v-if="windowWidth > 600"
                            class="d-flex flex-row justify-center align-center pdfContainer"
                            style="align-self: center;"
                        >
                            <object
                                :data="avvPdf"
                                align="center"
                                type="application/pdf"
                                border="0"
                                loading="lazy"
                                class="iOS_ResponsiveHack"
                            />
                        </div>
                        <div
                            class="d-flex flex-column align-baseline px-3"
                        >
                            <div
                                class="d-flex flex-row align-center my-1"
                            >
                                ADV_TOM_eklara_v1.0.pdf
                                <v-btn
                                    class="pa-0 ml-2"
                                    size="x-small"
                                    @click="() => { downloadAVV(); }"
                                >
                                    <img
                                        :src="herunterladenIcon"
                                        class="icon20"
                                        style="cursor: pointer;"
                                        alt="Datei herunterladen"
                                    >
                                </v-btn>
                            </div>
                            <!-- <div
                            class="d-flex flex-row align-center my-1"
                        >
                            Fragebogen Checkliste_für_IT_Anbieter DSGVO
                            <v-btn
                                class="pa-0 ml-2"
                                x-small
                                @click="() => { downloadCheckliste(); }"
                            >
                                <img
                                    :src="herunterladenIcon"
                                    class="icon20"
                                    style="cursor: pointer;"
                                    alt="Datei herunterladen"
                                >
                            </v-btn>
                        </div><div
                            class="d-flex flex-row align-center my-1"
                        >
                            Nutzungsordnungen (Videokonferenz, Messenger)
                            <v-btn
                                class="pa-0 ml-2"
                                x-small
                                @click="downloadNutzungsordnungen()"
                            >
                                <img
                                    :src="herunterladenIcon"
                                    class="icon20"
                                    style="cursor: pointer;"
                                    alt="Datei herunterladen"
                                >
                            </v-btn>
                        </div>
                        <div
                            class="d-flex flex-row align-center my-1"
                        >
                            Rollen_und_Berechtigungskonzept_eklara
                            <v-btn
                                class="pa-0 ml-2"
                                x-small
                                @click="downloadRollenBerechtigungskonzept()"
                            >
                                <img
                                    :src="herunterladenIcon"
                                    class="icon20"
                                    style="cursor: pointer;"
                                    alt="Datei herunterladen"
                                >
                            </v-btn>
                        </div> -->
                            <div
                                class="d-flex flex-row align-center my-1"
                            >
                                <!-- <v-checkbox
                                v-model="fillVTT"
                                class="pa-0 mt-0 mb-1"
                                label="Verzeichnis der Verarbeitungstätigkeiten automatisch vervollständigen."
                                dense
                                :hide-details="true"
                            /> -->
                                <p>Verzeichnis der Verarbeitungstätigkeiten v1.0.docx</p>
                                <v-tooltip location="top">
                                    <template v-slot:activator="{ props }">
                                        <img
                                            :src="infoIcon"
                                            class="icon20 ml-2 mr-1"
                                            alt="Erklärung"
                                           
                                            v-bind="props"
                                        >
                                    </template>
                                    <p style="max-width: 250px">
                                        Verzeichnis der Verarbeitungstätigkeiten als Word-Datei zum selbst Bearbeiten herunterladen.
                                    </p>
                                </v-tooltip>
                                <v-btn
                                    class="pa-0"
                                    size="x-small"
                                    @click="() => { downloadVTT(); }"
                                >
                                    <img
                                        :src="herunterladenIcon"
                                        class="icon20"
                                        style="cursor: pointer;"
                                        alt="Datei herunterladen"
                                    >
                                </v-btn>
                            </div>
                            <div
                                class="d-flex flex-row align-center my-1"
                            >
                                <!-- <v-checkbox
                                v-model="fillFragebogen"
                                class="pa-0 mt-0 mb-1"
                                label="'Fragebogen IT-Anwendung Schule' automatisch vervollständigen."
                                dense
                                :hide-details="true"
                            /> -->
                                <p>Fragebogen IT-Anwendung Schule v1.0.docx</p>
                                <v-tooltip location="top">
                                    <template v-slot:activator="{ props }">
                                        <img
                                            :src="infoIcon"
                                            class="icon20 ml-2 mr-1"
                                            alt="Erklärung"
                                           
                                            v-bind="props"
                                        >
                                    </template>
                                    <p style="max-width: 250px">
                                        'Fragebogen IT-Anwendung Schule' als Word-Datei zum selbst Bearbeiten herunterladen.
                                    </p>
                                </v-tooltip>
                                <v-btn
                                    class="pa-0 "
                                    size="x-small"
                                    @click="() => { downloadFragebogen(); }"
                                >
                                    <img
                                        :src="herunterladenIcon"
                                        class="icon20"
                                        style="cursor: pointer;"
                                        alt="Datei herunterladen"
                                    >
                                </v-btn>
                            </div>
                        </div>
                        <p
                            class="px-2 pt-2"
                            style="font-size: 14px; color: red;"
                        >
                            Sie erhalten nach dem Unterschreiben alle erforderlichen Datenschutzunterlagen per Email zugesandt. Leiten Sie die Email mit Anhang an ihre Datenschutzbehörde weiter.
                        </p>
                        <div class="d-flex flex-row justify-space-around px-2 py-2">
                            <p style="font-size: 12px;">
                                Diese und weitere nützliche Unterlagen zum Datenschutz stehen Ihnen nochmals zum Herunterladen bereit unter „Profileinstellungen > Dokumente > Datenschutzpaket“. Kontaktieren Sie gerne bei Fragen oder Anregungen den Support unter <a>info@eklara.de.</a>
                            </p>
                        </div>
                    </div>
                </simple-bar>
                <div class="d-flex flex-row justify-space-around px-2 py-2">
                    <v-checkbox
                        v-model="confirmSignAdv"
                        class="pa-0 mt-0 mb-1"
                        label="Ich habe den obenstehenden Vertrag über eine Auftragdatenverarbeitung vollständig gelesen
                                und stimme zu, meine Unterschrift an den dafür vorgesehenen Stellen zu setzen."
                        density="compact"
                        :hide-details="true"
                    />
                </div>
                <div
                    class="d-flex flex-row justify-end px-2 py-2 align-center"
                    style="align-self: end"
                >
                    <v-tooltip location="top">
                        <template v-slot:activator="{ props }">
                            <img
                                :src="infoIcon"
                                class="icon20 pr-2"
                                alt="Erklärung"
                               
                                v-bind="props"
                            >
                        </template>
                        <p style="max-width: 250px">
                            Mit Bestätigen ihrer Unterschrift wird eine Kopie des Vertrages sowohl an eklara als auch an die genannte Email der Schule versendet.
                        </p>
                    </v-tooltip>
                    <SignatureDialog
                        :disable-button="!confirmSignAdv || showError"
                        :type="'contract'"
                        @signature-done="signatureDone"
                    />
                </div>
            </div>
            <div
                v-if="contractSigned"
                class="d-flex flex-column"
            >
                <div
                    v-if="windowWidth > 600"
                    class="d-flex flex-row justify-center align-center pdfContainer"
                    style="align-self: center;"
                >
                    <object
                        :data="avvPdf"
                        align="center"
                        type="application/pdf"
                        border="0"
                        loading="lazy"
                        class="iOS_ResponsiveHack"
                    />
                </div>
                <div class="d-flex flex-row justify-space-between align-center pb-2">
                    <p class="px-2">
                        Sie haben den Vertrag bereits unterschrieben.
                    </p>
                    <div class="d-flex flex-row justify-space-around align-center ">
                        Vertrag herunterladen
                            
                        <v-btn
                            color="green"
                            class="mx-2"
                            size="small"
                            @click="() => { downloadExisting(); }"
                        >
                            <img
                                :src="herunterladenIcon"
                                class="icon20 iconToWhite"
                                alt="Herunterladen"
                            >
                        </v-btn>
                    </div>
                </div>
                <div class="d-flex flex-row justify-space-between align-center pb-2">
                    <div class="d-flex flex-row justify-space-around align-center ">
                        <v-checkbox
                            v-model="confirmQuitAdv"
                            class="pa-0 mt-0 mb-1 ml-2"
                            style="min-height: 0px !important;"
                            density="compact"
                            :hide-details="true"
                        /> 
                        <p class="px-2">
                            Hier können Sie den bestehenden Vertrag kündigen. Dabei wird automatisch eine Email mit Kündigungswunsch an das eklara - Team versendet. 
                        </p>
                    </div>

                    <v-btn
                        color="red"
                        class="mx-2"
                        size="small"
                        :disabled="!confirmQuitAdv"
                        @click="() => { quitAdv(); }"
                    >
                        <img
                            :src="deleteIcon"
                            class="icon20 iconToWhite"
                            alt="Löschen"
                        >
                    </v-btn>
                </div>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import * as backend from "../../api/backend";
import herunterladenIcon from "@/assets/Icons/FaceliftIcons/herunterladen.svg";
import cancelIcon from '@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg';
import SignatureDialog from "@/components/SignatureDialog";
import infoIcon from '@/assets/Icons/FaceliftIcons/info_hinweis.svg';
import deleteIcon from '@/assets/Icons/FaceliftIcons/loeschen_muelleimer.svg';
import Checkliste from "@/assets/PDF/templates/eklara2_0_2301_Fragebogen_Checkliste_für_IT_Anbieter_DSGVO_07_2020.pdf";
import {PDFDocument} from "pdf-lib";

export default {
    name: "ContractVerwaltung",
    components: {
        SignatureDialog,
    },
    data() {
        return {
            // state
            downloaded: false,
            uploaded: false,
            contractSigned: false,

            //input model
            schoolName: '',
            schoolEmail: '',
            principal: '',
            schoolCity: '',
            schoolPhone: '',
            schoolStreet: '',
            schoolPostal: '',
            schoolDataSecurityOfficer: '',
            file: null,
            fillFragebogen: true,
            fillVTT: true,
            today: null,

            // Icons
            herunterladenIcon,
            infoIcon, 
            cancelIcon,
            deleteIcon,

            avvPdf: null,
            confirmSignAdv: false,
            confirmQuitAdv: false,
            signatureFile: null,
            avvForm: null,
            schoolLoaded: false,

            showLoadingOverlay: false,

            Checkliste,

            settings: {
            suppressScrollY: false,
            suppressScrollX: true,
            wheelPropagation: false,
        },
        }
    },
    computed: {
        ...mapGetters("auth", ["accountId"]),
        ...mapGetters("maintainer", ["showDialogGDPR", "getADVSigned"]),
        ...mapState('util', ['windowWidth', 'windowHeight']),

        // contact information is now optional, only check for uploaded contract
        formValid() {
            // if (this.schulname.length>0 && this.kontaktperson.length>0 &&
            //     this.schulleitung.length>0 && this.mailadresse.length>0 &&
            //     this.telefonnummer.length>0 && this.uploaded) {
            //         return true;
            //     }
            // return false;
            return this.uploaded;
        },
        showError(){
            const values = [this.schoolName, this.schoolEmail, this.principal, this.schoolCity,this.schoolPhone, this.schoolPostal, this.schoolStreet]
            return values.some(value => value === '' || value === undefined || value === null);
        }
    },
    watch:{
        confirmSignAdv(newVal){
            if(newVal === true){
                 if(this.showError){
                    this.showSnackbar({
                        message: `Achtung, Sie haben folgende Felder in der Ersteinrichtung nicht ausgefüllt: ${
                            [
                            this.schoolName === '' || this.schoolName === undefined ? 'Name der Schule' : '',
                            this.schoolEmail === '' || this.schoolEmail === undefined ? 'Email der Schule' : '',
                            this.principal === '' || this.principal === undefined ? 'Schulleiter' : '',
                            this.schoolCity === '' || this.schoolCity === undefined ? 'Ort' : '',
                            this.schoolPhone === '' || this.schoolPhone === undefined ? 'Telefonnummer' : '',
                            this.schoolPostal === '' || this.schoolPostal === undefined ? 'PLZ' : '',
                            this.schoolStreet === '' || this.schoolStreet === undefined ? 'Anschrift' : ''
                            ].filter(Boolean).join(', ')
                        }. Bitte holen Sie dies im Profilmenü unter dem Punkt 'Schulangaben' nach.`,
                        color: 'error',
                        timeout: 10000,
                        });
                }
            }
        }
    },
    async beforeMount(){
        this.contractSigned = this.getADVSigned;
    },
    async mounted() {
        await this.initializeSchool();
        await this.initializeBlankAVV();
    },
    beforeUnmount() {
        window.URL.revokeObjectURL(this.avvForm);
        window.URL.revokeObjectURL(this.avvPdf);
    },
    methods: {
        ...mapMutations("snackbar", ["showSnackbar"]),
        ...mapMutations("maintainer", ["setShowDialogGDPR", 'setADVSigned']),
        ...mapActions('school', [ 'getSchool', 'postSchool' ]),

       saveByteArray(reportName, byte) {
            let blob = new Blob([byte], {type: "application/pdf"});
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = reportName;
            link.click();
            setTimeout(function () {
                    link.remove();
                    window.URL.revokeObjectURL(blob);
                }, 1000);
        },
        async downloadCheckliste(){
            const pdfBytes = await fetch(this.Checkliste).then(res => res.arrayBuffer());
            this.saveByteArray('Checkliste_für_IT_Anbieter DSGVO_v1.0', pdfBytes);

        },
        async downloadFragebogen(){
            const fragebogen_res = await backend.getWordTemplate();
            const fragebogen = await fragebogen_res.blob();
             let link = document.createElement("a");
            link.href = window.URL.createObjectURL(fragebogen);
            link.download = 'Fragebogen IT-Anwendung Schule_v1.0';
            link.click();
            setTimeout(function () {
                    link.remove();
                    window.URL.revokeObjectURL(fragebogen);
                }, 1000);
        },
        async downloadRollenBerechtigungskonzept(){
            const rollenkonzept_res =  await backend.getRollenkonzeptDocx();
            const blob = await rollenkonzept_res.blob();
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = 'Rollen_und_Berechtigungskonzept_eklara_v1.0';
            link.click();
            setTimeout(function () {
                    link.remove();
                    window.URL.revokeObjectURL(blob);
                }, 1000);

        },
        async downloadNutzungsordnungen(){
            const nutzungsordnung_res =  await backend.getNutzungsbedingungenDocx();
            const blob = await nutzungsordnung_res.blob();
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = 'Nutzungsordnungen_eklara_v1.0';
            link.click();
            setTimeout(function () {
                    link.remove();
                    window.URL.revokeObjectURL(blob);
                }, 1000);
        },
        async initializeBlankAVV(){
            const avvRes = await backend.getAVVTemplate();
            const blob = await avvRes.blob();
            this.avvPdf = URL.createObjectURL(blob);
            const formRes= await backend.getAVVForm();
            const avvBlob = await formRes.blob();
            const avvCopy = URL.createObjectURL(avvBlob);
            this.avvForm = avvCopy;
            await this.fillForm(avvCopy);
        },
        async initializeSchool(){
            const school = await this.getSchool();
            this.schoolName = school.name !== null ? school.name : '';
            this.schoolEmail = school.email !== null ? school.email : '';
            this.principal = school.principal !== null ? school.principal : '';
            this.schoolCity = school.city !== null ? school.city : '';
            this.schoolPhone = school.phone !== null ? school.phone : '';
            this.schoolPostal = school.postal !== null ? school.postal : '';
            this.schoolStreet = school.street !== null ? school.street : '';
            this.schoolDataSecurityOfficer = school.schoolDataSecurityOfficer !== null ? school.schoolDataSecurityOfficer : '';
            this.schoolLoaded = true;
        },

        async fillForm(avvForm){
            const existingPdfBytes = await fetch(avvForm).then((res) =>
                res.arrayBuffer()
            );
            const today = new Date().toLocaleDateString('de-DE', {
                                                                day: '2-digit',
                                                                month: '2-digit',
                                                                year: 'numeric'
                                                            })
            const pdfDoc = await PDFDocument.load(existingPdfBytes);
            const form = pdfDoc.getForm();
            const fields = form.getFields();
            console.log('fields',fields);

            form.getTextField('NameSchule').setText(this.schoolName);
            form.getTextField('Street').setText(this.schoolStreet);
            form.getTextField('PLZundOrt').setText(`${this.schoolPostal}  ${this.schoolCity}`);
            form.getTextField('Telephone').setText(this.schoolPhone);
            form.getTextField('NameSchulleitung').setText(this.principal);
            form.getTextField('Weisungsberechtigte Person').setText(`${this.principal} - ${this.schoolEmail}`);
            form.getTextField('Ort und Datum_2').setText(`Heilbronn, den ${today}`);
            form.getTextField('Ort und Datum').setText(`${this.schoolCity}, den ${today}`);
           
            form.getTextField('NameSchule').enableReadOnly();
            form.getTextField('Street').enableReadOnly();
            form.getTextField('PLZundOrt').enableReadOnly();
            form.getTextField('Telephone').enableReadOnly();
            form.getTextField('NameSchulleitung').enableReadOnly();
            form.getTextField('Weisungsberechtigte Person').enableReadOnly();
            form.getTextField('Ort und Datum_2').enableReadOnly();
            form.getTextField('Ort und Datum').enableReadOnly();
            const bytes = await pdfDoc.save();
            this.file = new Blob([bytes], {type: "application/pdf"});

            this.avvPdf =  URL.createObjectURL(this.file);
        },
        async signatureDone(imgData, sendLetter, letter) {
            this.signaturePhoto = imgData;
            const signatureFile = await fetch(imgData);
            // const pdfFile = URL.createObjectURL(this.file);
            const existingPdfBytes = await fetch(this.avvPdf).then((res) =>
                res.arrayBuffer()
            );
            // window.URL.revokeObjectURL(pdfFile);
            const pdfDoc = await PDFDocument.load(existingPdfBytes)
            const form = pdfDoc.getForm();
            const png = await pdfDoc.embedPng(signatureFile.url);
            const page = pdfDoc.getPages()[3];
            page.drawImage(png,{
                    x: 85,
                    y: 290,
                    width: 180,
                    height: 60,
                    })
            
            const bytes = await pdfDoc.save();
            let fragebogenBytes = null;
            if(this.fillFragebogen){
                const fragebogenRes = await backend.getFragebogenForm();
                const blob = await fragebogenRes.blob();
                const zwischenURL = URL.createObjectURL(blob);
                const fragebogen = await fetch(zwischenURL).then((res) =>
                res.arrayBuffer()
                ); 
                URL.revokeObjectURL(zwischenURL);
                const today = new Date().toLocaleDateString('de-DE', {
                                                                day: '2-digit',
                                                                month: '2-digit',
                                                                year: 'numeric'
                                                            })
                const fragebogenPDF = await PDFDocument.load(fragebogen);
                const fragebogenForm = fragebogenPDF.getForm();
                fragebogenForm.getTextField('Datum').setText(today);
                fragebogenForm.getTextField('PLZundOrt').setText(`${this.schoolPostal} ${this.schoolCity}`);
                fragebogenForm.getTextField('Name').setText(this.schoolName);
                fragebogenForm.getTextField('Email').setText(this.schoolEmail);
                fragebogenForm.getTextField('Ort und Datum').setText(`${this.schoolCity}, den ${today}`);
                fragebogenForm.getTextField('Datum').enableReadOnly();
                fragebogenForm.getTextField('Name').enableReadOnly();
                fragebogenForm.getTextField('Ort und Datum').enableReadOnly();
                fragebogenForm.getTextField('PLZundOrt').enableReadOnly();
                fragebogenForm.getTextField('Email').enableReadOnly();

                const signatureImage = await fragebogenPDF.embedPng(signatureFile.url);
                const fragebogenPage = fragebogenPDF.getPages()[8];
                fragebogenPage.drawImage(signatureImage,{
                    x: 330,
                    y: 440,
                    width: 180,
                    height: 60,
                    })
            
             fragebogenBytes = await fragebogenPDF.save();
            }
            let vttBytes = null;
            if(this.fillVTT){
                const vttRes = await backend.getVTTForm();
                const blob = await vttRes.blob();
                const zwischenURL = URL.createObjectURL(blob);
                const vtt = await fetch(zwischenURL).then((res) =>
                res.arrayBuffer()
                ); 
                URL.revokeObjectURL(zwischenURL);
                const vttPDF = await PDFDocument.load(vtt);
                const vttForm = vttPDF.getForm();
                vttForm.getTextField('ContactDataSecurityOfficer').setText(this.schoolDataSecurityOfficer);
                vttForm.getTextField('Principal').setText(this.principal);
                vttForm.getTextField('Telephone').setText(this.schoolPhone);
                vttForm.getTextField('SchoolMail').setText(this.schoolEmail);
                vttForm.getTextField('PLZundOrt').setText(`${this.schoolPostal} ${this.schoolCity}`);
                vttForm.getTextField('Name').setText(this.schoolName);
                vttForm.getTextField('ContactDataSecurityOfficer').enableReadOnly();
                vttForm.getTextField('Principal').enableReadOnly();
                vttForm.getTextField('Telephone').enableReadOnly();
                vttForm.getTextField('SchoolMail').enableReadOnly();
                vttForm.getTextField('PLZundOrt').enableReadOnly();
                vttForm.getTextField('Name').enableReadOnly();
                 
                vttBytes = await vttPDF.save();
            }
            this.uploadAvvNew(bytes, fragebogenBytes, vttBytes);

        },
        uploadAvvNew(bytes, fragebogenBytes, vttBytes){
            const signed = new Blob([bytes], {type: "application/pdf"});
            const signedFile = new File([signed],"ADV_TOM_eklara_unterschrieben_v1.0.pdf",{type: "application/pdf"})
            const formData = new FormData();
            const fileArray = [];
                       
            fileArray.push(signedFile);

            if(fragebogenBytes){
                const signedFragebogen = new Blob([fragebogenBytes], {type: "application/pdf"});
                const signedFileFragebogen = new File([signedFragebogen],"ADV_TOM_eklara_unterschrieben_v1.0.pdf",{type: "application/pdf"})
                formData.append(
                    "file",
                    signedFragebogen,
                    "Fragebogen_IT-Anwendung_Schule_v1.0.pdf"
                );
                fileArray.push(signedFileFragebogen);
            }
            if(vttBytes){
                const signedVTT = new Blob([vttBytes], {type: "application/pdf"});
                const signedFileVTT = new File([signedVTT],"Verzeichnis_der_Verarbeitungstätigkeiten_v1.0.pdf",{type: "application/pdf"}) 
                formData.append(
                    "file",
                    signedFileVTT,
                    "Verzeichnis_der_Verarbeitungstätigkeiten_v1.0.pdf"
                );
                fileArray.push(signedFileVTT);
            }
            formData.append(
                "file",
                signed,
                "ADV_TOM_eklara_unterschrieben_v1.0.pdf"
            );
            let uploadXmlHttpRequest = backend.postMaintainerAVV(formData, this.accountId);
            uploadXmlHttpRequest.send(formData);
            this.showLoadingOverlay = true;
            uploadXmlHttpRequest.addEventListener('load', async (e) => {
                if (uploadXmlHttpRequest.status !== 201) {
                    this.showLoadingOverlay = false;
                    this.showSnackbar({
                        message: 'Beim Hochladen ist ein Fehler aufgetreten.',
                        color: 'error',
                        duration: '5'
                    });
                } else {
                    this.showSnackbar({
                        message: 'Die Auftragsdatenverarbeitung wurde erfolgreich hochgeladen.',
                        color: 'success',
                        duration: '5'
                    });
                    this.showLoadingOverlay = false;
                    this.setADVSigned(true);
                    this.setShowDialogGDPR(false);
                }
                });
            },
        async quitAdv(){
            this.showLoadingOverlay = true;
            const res = await backend.deleteAVV(this.accountId);
            if(res.status === 200){
                this.showSnackbar({message: 'Die Anfrage zur Kündigung wurde erfolgreich versendet.', color: 'success'})
                this.setADVSigned(false);
                this.showLoadingOverlay = false;

                this.setShowDialogGDPR(false);
            }else{
                this.showLoadingOverlay = false;
                this.showSnackbar({message: 'Beim Versenden der Kündigung ist ein Fehler aufgetreten. Laden Sie die Seite neu und veruschen sie es erneut oder wenden Sise sich direkt an den Support.', color: 'error'})
                this.setShowDialogGDPR(false);
            }
        },
        async downloadAVV() {
            // const res = await backend.getAVVTemplate();
            // let blob = await res.blob();
            // var url = window.URL.createObjectURL(blob);
            var a = document.createElement("a");
            a.href = this.avvPdf;
            a.download = 'ADV_TOM_schule_eklara_v1.0';
            document.body.appendChild(a);
            a.click();
            // setTimeout(function () {
            //     a.remove();
            //     // window.URL.revokeObjectURL(url);
            // }, 1000);
        },
        async downloadVTT() {
            const res = await backend.getVTTTemplate();
            let blob = await res.blob();
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement("a");
            a.href = url;
            a.download = 'Verzeichnis_Verarbeitungstätigkeiten_VTT_eklara_v1.0.docx';
            document.body.appendChild(a);
            a.click();
            setTimeout(function () {
                a.remove();
                window.URL.revokeObjectURL(url);
            }, 1000);
        },
        async downloadExisting() {
            const res = await backend.getAVVSigned();
            let blob = await res.blob();
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement("a");
            a.href = url;
            a.download = 'ADV_TOM_eklara_ausgefüllt_v1.0';
            document.body.appendChild(a);
            a.click();
            setTimeout(function () {
                a.remove();
                window.URL.revokeObjectURL(url);
            }, 1000);
        },
        uploadInputChange() {
            this.file = this.$refs.uploadInput.files[0];
            this.uploaded = true;
        }
    },
}
</script>

<style scoped>
.container {
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 90vh;
    height:90vh;
    background-color: white;
    border-radius: 16px;
    max-width: 80vw;
    /* hide scrollbar 
    -ms-overflow-style: none; /* Internet Explorer 10+ 
    scrollbar-width: none;  Firefox */
}

.container::-webkit-scrollbar {
    /* display: none; Safari and Chrome */
}

.text {
    color: black;
}

.textField {
    color: black;
}
.iOS_ResponsiveHack {
    width: 1px;
    min-width: 100%;
    *width: 100%;
    padding-left: 10%;
    padding-right: 10%;
    min-height: 100%;
}
.pdfContainer{
    width: 100%;
    height: 35vh;
    max-width: 580px;
    aspect-ratio: 1/1;
    cursor: pointer;
    display: flex;
    justify-content: center;
    border-radius: 8px;
}
.scroll-area {
  position: relative;
  margin: auto;
  max-height: calc(80vh - 140px);
  overflow-x: hidden;
}
@media screen and (max-width: 600px) {
  .scroll-area {
    position: relative;
    margin: auto;
    max-height: 42vh;
    overflow-x: hidden;
  }
}
</style>
