<template>
    <div>
        <progress-bar
            title="Hochladen"
            :show-progress="showPupilUploadProgress"
            :progress="pupilUploadProgress"
            :abort-progress="pupilXmlHttpRequest ? () => { pupilXmlHttpRequest.abort() }: () => {}"
            :header-color="'rgb(var(--v-theme-fileArchiveOrange))'"
            @update:show-progress="showPupilUploadProgress = $event"
            @update:progress="pupilUploadProgress = $event"
        />
        <label
            style="display: none"
            for="hausiPupilUploadInput"
            hidden
        >Aufgaben hochladen</label>
        <input
            id="hausiPupilUploadInput"
            ref="hausiPupilUploadInput"
            type="file"
            hidden
            @change="() => pupilUploadInputChange(item.appointmentId, item._id)"
        >

        <v-row class="mx-0">
            <v-col class="pa-0">
                <div id="hausiWidgetBabyContainer">
                    <!--Header of Aufgaben widget-->
                    <div id="hausiWidgetHeader">
                        <v-row
                            style="max-width: 100%; display: flex; align-items: center;"
                            class="mx-4"
                        >
                            <v-col
                                cols="9"
                                md="8"
                                lg="9"
                                class="pa-0 text-left d-flex align-center"
                                style="font-size: xx-large"
                            >
                                <img
                                    :src="hausaufgabenIconLeer"
                                    style="height: 30px; margin-bottom: -3px;"
                                    alt=""
                                >
                                <h1
                                    class="ma-0 ml-2"
                                    tabindex="0"
                                    aria-label="Aufgaben"
                                >
                                    <span style="text-decoration: underline white">H</span>ausaufgaben
                                </h1>
                            </v-col>
                        </v-row>
                    </div>

                    <div
                        v-if="showLoading"
                        class="d-flex justify-center mt-4"
                    >
                        <v-progress-circular
                            color="#6995CD"
                            indeterminate
                            size="100"
                        />
                    </div>

                    <!-- Hausiwidget mit magnifier -->
                    <div v-else-if="magnifier">
                        <div
                            @keydown.right="handleArrowKeyRight()"
                            @keydown.left="handleArrowKeyLeft()"
                        >
                            <v-row class="px-3">
                                <v-col
                                    class="pa-0"
                                    cols="6"
                                >
                                    <v-tooltip
                                        :model-value="shortcutTooltips"
                                        :disabled="!showTooltips"
                                        location="bottom"
                                    >
                                        <template v-slot:activator="{ props }">
                                            <v-badge
                                                color="#ff0000"
                                                :content="todoUnseenCount"
                                                :model-value="todoUnseenCount"
                                                location="left"
                                                offset-x="10"
                                                style="z-index: 8; width: 100%; height: 48px !important;"
                                            >
                                                <v-btn
                                                    :id="'toDoTab'"
                                                    :color="tab === 'toDo' ? '#E94E1B' : '#ED7251'"
                                                    :theme="tab === 'toDo' ? 'dark':''"
                                                    style="width: 100%; overflow: hidden; text-overflow: ellipsis; border: 1px solid white !important; font-size: 25px; height: 40px"
                                                    elevation="0"
                                                    role="tab"
                                                    class="text-capitalize"
                                                    v-bind="props"
                                                    @click="tab = 'toDo'; checkImagePreviews(); setFocusOn('toDoTab')"
                                                    @keydown.tab="setFocusOnFirstItemTab('toDoIconMagnifier-0', 'toDo')"
                                                >
                                                    <img
                                                        :src="tick1Icon"
                                                        class="iconToWhite icon mr-1"
                                                        alt=""
                                                    >
                                                    Offen
                                                </v-btn>
                                            </v-badge>
                                        </template>
                                        <div style="display: flex; flex-direction: column; text-align: center">
                                            <span><span style="text-decoration: underline white">H</span>ausaufgaben (Alt + H)</span>
                                            <div>
                                                <img
                                                    v-if="hoeren"
                                                    :src="hausaufgabenMetacom"
                                                    width="100"
                                                    style="margin: auto"
                                                    alt=""
                                                    class="mr-1"
                                                >
                                                <img
                                                    v-if="hoeren"
                                                    :src="toDoMetacom"
                                                    width="100"
                                                    style="margin: auto"
                                                    alt=""
                                                >
                                            </div>
                                        </div>
                                    </v-tooltip>
                                </v-col>
                                <!-- "Kuckt sich eh niemand mehr an" ¯\_(ツ)_/¯-->
                                <!--                                <v-col
                                    class="px-1"
                                    cols="4"
                                >
                                    <v-tooltip
                                        :disabled="!showTooltips || !hoeren"
                                        bottom
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                :id="'doneTab'"
                                                :color="tab === 'done' ? '#F49F31' : 'white'"
                                                :dark="tab === 'done'"
                                                style="width: 100%; border: solid 1px #F49F31 !important; overflow: hidden; text-overflow: ellipsis"
                                                elevation="0"
                                                role="tab"
                                                class="text-capitalize"
                                                v-on="on"
                                                @click="tab = 'done'; checkImagePreviews(); setFocusOn('doneTab')"
                                                @keydown.tab="setFocusOnFirstItemTab('doneIconMagnifier-0', 'toDo')"
                                            >
                                                <img
                                                    :src="tick2Icon"
                                                    :class="(tab === 'done' ? 'iconToWhite ' : '') + 'icon mr-1'"
                                                    alt=""
                                                >
                                                Erledigt
                                            </v-btn>
                                        </template>
                                        <img
                                            v-if="hoeren"
                                            :src="hausaufgabenMetacom"
                                            width="100"
                                            style="margin: auto"
                                            alt=""
                                            class="mr-1"
                                        >
                                        <img
                                            v-if="hoeren"
                                            :src="doneMetacom"
                                            width="100"
                                            style="margin: auto"
                                            alt=""
                                        >
                                    </v-tooltip>
                                </v-col>-->
                                <v-col
                                    class="pa-0"
                                    cols="6"
                                >
                                    <v-tooltip
                                        :disabled="!showTooltips || !hoeren"
                                        location="bottom"
                                    >
                                        <template v-slot:activator="{ props }">
                                            <v-badge
                                                color="#ff0000"
                                                :content="correctedUnseenCount"
                                                :model-value="correctedUnseenCount"
                                                location="left"
                                                offset-x="10"
                                                style="z-index: 8; width: 100%; height: 48px !important;"
                                            >
                                                <v-btn
                                                    :id="'correctedTab'"
                                                    :color="tab === 'corrected' ? '#E94E1B' : '#ED7251'"
                                                    :theme="tab === 'corrected'?'dark':''"
                                                    style="width: 100%; overflow: hidden; text-overflow: ellipsis; border: 1px solid white !important; font-size: 25px; height: 40px"
                                                    elevation="0"
                                                    role="tab"
                                                    class="text-capitalize"
                                                    v-bind="props"
                                                    @click="tab = 'corrected'; checkImagePreviews(); setFocusOn('correctedTab')"
                                                    @keydown.tab="setFocusOnFirstItemTab('correctedIconMagnifier-0', 'toDo')"
                                                >
                                                    <img
                                                        :src="tick3Icon"
                                                        class="iconToWhite icon mr-1"
                                                        alt=""
                                                    >
                                                    Korrigiert
                                                </v-btn>
                                            </v-badge>
                                        </template>
                                        <img
                                            v-if="hoeren"
                                            :src="hausaufgabenMetacom"
                                            width="100"
                                            style="margin: auto"
                                            alt=""
                                            class="mr-1"
                                        >
                                        <img
                                            v-if="hoeren"
                                            :src="correctedMetacom"
                                            width="100"
                                            style="margin: auto"
                                            alt=""
                                        >
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                            <anti-scroll class="scroll-area">
                                <div v-if="tab ==='toDo' && babyFilesToDoDisplay">
                                    <!-- Needed for screenreader -->
                                    <v-row
                                        v-show="false"
                                        class="cols"
                                        style="width: 99%; margin: 0.5em auto 0.5em auto;"
                                    >
                                        <v-col
                                            style="display: flex; align-items: center"
                                            class="sectionHeaderCol"
                                        >
                                            <img
                                                :src="doneIcon"
                                                style="height: 30px"
                                                class="sectionHeaderImg"
                                                alt=""
                                            >
                                            <h2
                                                class="pl-2 sectionHeaderText"
                                                tabindex="0"
                                            >
                                                Offen:
                                            </h2>
                                        </v-col>
                                    </v-row>
                                    <div
                                        v-for="({ day, items }, index) in babyFilesToDoDisplay"
                                        :key="`${index}${day}`"
                                    >
                                        <div class="d-flex justify-center align-center">
                                            <hr style="min-width: 25%">
                                            <p
                                                :ref="`hausiItemstoDo${day}`"
                                                style="font-size: x-large; padding: 0.5em; color: grey"
                                                class="text-center"
                                            >
                                                {{ new Date(day).toLocaleDateString(targetLang, { weekday: 'short', year: '2-digit', month: '2-digit', day: '2-digit' }) }}
                                            </p>
                                            <hr style="min-width: 25%">
                                        </div>
                                        <div
                                            v-for="(item, index) in items"
                                            :key="item.file"
                                        >
                                            <HausiWidgetListItem
                                                :item="item"
                                                :index="index"
                                                :category="'toDo'"
                                                :show-tooltips="showTooltips"
                                                :hoeren="hoeren"
                                                :sehen="sehen"
                                                :account="account"
                                                :class="{ brighterFileRow: index % 2 === 1, disabledFileRow: false }"
                                                @open-viewer="handleOpenAnsichtClick(item)"
                                                @upload-pupil-answer="pupilUploadFile(item)"
                                                @download-pupil-upload="clickDownloadPupilUpload(item)"
                                                @move-without-answer="() => { moveDialog = true; elementToMove = item }"
                                                @play-video="play(item)"
                                                @play-audio="playAudio(item)"
                                            />
                                        </div>
                                    </div>

                                    <v-row
                                        v-if="babyFilesToDoDisplay && babyFilesToDoDisplay.length === 0"
                                        class="emptyRow ma-2"
                                        align="center"
                                        justify="center"
                                    >
                                        <v-col class="pa-0">
                                            <p
                                                id="toDoEmpty-magnifier"
                                                tabindex="0"
                                            >
                                                Aktuell gibt es nichts zu erledigen
                                            </p>
                                        </v-col>
                                    </v-row>
                                </div>

                                <div v-if="tab === 'done'">
                                    <!-- Needed for screenreader -->
                                    <v-row
                                        v-show="false"
                                        class="cols"
                                        style="width: 99%; margin: 0.5em auto 0.5em auto;"
                                    >
                                        <v-col
                                            style="display: flex; align-items: center"
                                            class="sectionHeaderCol"
                                        >
                                            <img
                                                :src="doneIcon"
                                                style="height: 30px"
                                                class="sectionHeaderImg"
                                                alt=""
                                            >
                                            <h2
                                                class="pl-2 sectionHeaderText"
                                                tabindex="0"
                                            >
                                                Erledigt:
                                            </h2>
                                        </v-col>
                                    </v-row>

                                    <div
                                        v-for="({ day, items }, index) in babyFilesDoneDisplay"
                                        :key="`${index}${day}`"
                                    >
                                        <div class="d-flex justify-center align-center">
                                            <hr style="min-width: 25%">
                                            <p
                                                :ref="`hausiItemsdone${day}`"
                                                style="font-size: x-large; padding: 0.5em; color: grey"
                                                class="text-center"
                                            >
                                                {{ new Date(day).toLocaleDateString(targetLang, { weekday: 'short', year: '2-digit', month: '2-digit', day: '2-digit' }) }}
                                            </p>
                                            <hr style="min-width: 25%">
                                        </div>
                                        <div
                                            v-for="(item, index) in items"
                                            :key="item.file"
                                        >
                                            <HausiWidgetListItem
                                                :item="item"
                                                :index="index"
                                                :category="'done'"
                                                :show-tooltips="showTooltips"
                                                :hoeren="hoeren"
                                                :sehen="sehen"
                                                :account="account"
                                                :class="{ brighterFileRow: index % 2 === 1, disabledFileRow: false }"
                                                @open-viewer="handleOpenAnsichtClick(item)"
                                                @upload-pupil-answer="pupilUploadFile(item)"
                                                @download-pupil-upload="clickDownloadPupilUpload(item)"
                                                @move-without-answer="() => { moveDialog = true; elementToMove = item }"
                                                @play-video="play(item)"
                                                @play-audio="playAudio(item)"
                                            />
                                        </div>
                                    </div>

                                    <v-row
                                        v-if="babyFilesDone && babyFilesDone.length === 0"
                                        class="emptyRow ma-2"
                                        align="center"
                                        justify="center"
                                    >
                                        <v-col class="pa-0">
                                            <p
                                                id="doneEmpty-magnifier"
                                                tabindex="0"
                                            >
                                                Aktuell gibt es noch nichts Erledigtes
                                            </p>
                                        </v-col>
                                    </v-row>
                                </div>

                                <div v-if="tab === 'corrected'">
                                    <v-row
                                        v-show="false"
                                        class="cols"
                                        style="width: 99%; margin: 0.5em auto 0.5em auto;"
                                    >
                                        <v-col
                                            style="display: flex; align-items: center"
                                            class="sectionHeaderCol"
                                        >
                                            <img
                                                :src="correctedIcon"
                                                style="height: 30px"
                                                class="sectionHeaderImg"
                                                alt=""
                                            >
                                            <h2
                                                class="pl-2 sectionHeaderText"
                                                tabindex="0"
                                            >
                                                Korrigiert:
                                            </h2>
                                        </v-col>
                                    </v-row>

                                    <div
                                        v-for="({ day, items }, index) in babyFilesCorrected"
                                        :key="`${index}${day}`"
                                    >
                                        <div class="d-flex justify-center align-center">
                                            <hr style="min-width: 25%">
                                            <p
                                                :ref="`hausiItemscorrected${day}`"
                                                style="font-size: x-large; padding: 0.5em; color: grey"
                                                class="text-center"
                                            >
                                                {{ new Date(day).toLocaleDateString(targetLang, { weekday: 'short', year: '2-digit', month: '2-digit', day: '2-digit' }) }}
                                            </p>
                                            <hr style="min-width: 25%">
                                        </div>
                                        <div
                                            v-for="(item, index) in items"
                                            :key="item.file"
                                        >
                                            <HausiWidgetListItem
                                                :item="item"
                                                :index="index"
                                                :category="'corrected'"
                                                :show-tooltips="showTooltips"
                                                :hoeren="hoeren"
                                                :sehen="sehen"
                                                :account="account"
                                                :class="{ brighterFileRow: index % 2 === 1, disabledFileRow: false }"
                                                @open-viewer="handleOpenAnsichtClick(item)"
                                                @upload-pupil-answer="pupilUploadFile(item)"
                                                @download-pupil-upload="clickDownloadPupilUpload(item)"
                                                @move-without-answer="() => { moveDialog = true; elementToMove = item }"
                                                @play-video="play(item)"
                                                @play-audio="playAudio(item)"
                                            />
                                        </div>
                                    </div>

                                    <v-row
                                        v-if="babyFilesCorrected && babyFilesCorrected.length === 0"
                                        class="emptyRow ma-2"
                                        align="center"
                                        justify="center"
                                    >
                                        <v-col class="pa-0">
                                            <p
                                                id="correctedEmpty-magnifier"
                                                tabindex="0"
                                            >
                                                Aktuell gibt es nichts Korrigiertes
                                            </p>
                                        </v-col>
                                    </v-row>
                                </div>
                            </anti-scroll>
                        </div>
                    </div>
                    <!-- Hausiwidget ohne magnifier -->
                    <div v-else>
                        <v-row class="px-3">
                            <v-col
                                class="pa-0"
                                cols="6"
                            >
                                <v-tooltip
                                    :model-value="shortcutTooltips"
                                    :disabled="!showTooltips"
                                    location="bottom"
                                >
                                    <template v-slot:activator="{ props }">
                                        <v-badge
                                            color="#ff0000"
                                            :content="todoUnseenCount"
                                            :model-value="todoUnseenCount"
                                            location="left"
                                            offset-x="10"
                                            style="z-index: 8; width: 100%; height: 48px !important;"
                                        >
                                            <v-btn
                                                :id="'toDoTab'"
                                                :color="tab === 'toDo' ? '#E94E1B' : '#ED7251'"
                                                theme="dark"
                                                tile
                                                style="width: 100%; overflow: hidden; text-overflow: ellipsis; border: 1px solid white !important; font-size: 25px; height: 40px"
                                                elevation="0"
                                                role="tab"
                                                class="text-capitalize px-2"
                                                v-bind="props"
                                                @click="tab = 'toDo'; checkImagePreviews(); setFocusOn('toDoTab')"
                                                @keydown.tab="setFocusOnFirstItemTab('toDoIcon-0', 'toDo')"
                                            >
                                                <img
                                                    :src="tick1Icon"
                                                    class="iconToWhite icon mr-1"
                                                    alt=""
                                                >
                                                Offen
                                            </v-btn>
                                        </v-badge>
                                    </template>
                                    <div style="display: flex; flex-direction: column; text-align: center">
                                        <span><span style="text-decoration: underline white">H</span>ausaufgaben (Alt + H)</span>
                                        <div>
                                            <img
                                                v-if="hoeren"
                                                :src="hausaufgabenMetacom"
                                                width="100"
                                                style="margin: auto"
                                                alt=""
                                                class="mr-1"
                                            >
                                            <img
                                                v-if="hoeren"
                                                :src="toDoMetacom"
                                                width="100"
                                                style="margin: auto"
                                                alt=""
                                            >
                                        </div>
                                    </div>
                                </v-tooltip>
                            </v-col>
                            <!-- "Kuckt sich eh niemand mehr an" ¯\_(ツ)_/¯-->
                            <!--                            <v-col
                                class="px-1"
                                cols="4"
                            >
                                <v-tooltip
                                    :disabled="!showTooltips || !hoeren"
                                    bottom
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            :id="'doneTab'"
                                            :color="tab === 'done' ? '#F49F31' : 'white'"
                                            :dark="tab === 'done'"
                                            style="width: 100%; border: solid 1px #F49F31 !important; overflow: hidden; text-overflow: ellipsis"
                                            elevation="0"
                                            role="tab"
                                            class="text-capitalize"
                                            v-on="on"
                                            @click="tab = 'done'; checkImagePreviews(); setFocusOn('doneTab')"
                                            @keydown.tab="setFocusOnFirstItemTab('doneIcon-0', 'done')"
                                        >
                                            <img
                                                :src="tick2Icon"
                                                :class="(tab === 'done' ? 'iconToWhite ' : '') + 'icon mr-1'"
                                                alt=""
                                            >
                                            Erledigt
                                        </v-btn>
                                    </template>
                                    <img
                                        v-if="hoeren"
                                        :src="hausaufgabenMetacom"
                                        width="100"
                                        style="margin: auto"
                                        alt=""
                                        class="mr-1"
                                    >
                                    <img
                                        v-if="hoeren"
                                        :src="doneMetacom"
                                        width="100"
                                        style="margin: auto"
                                        alt=""
                                    >
                                </v-tooltip>
                            </v-col>-->
                            <v-col
                                class="pa-0"
                                cols="6"
                            >
                                <v-tooltip
                                    :disabled="!showTooltips || !hoeren"
                                    location="bottom"
                                >
                                    <template v-slot:activator="{ props }">
                                        <v-badge
                                            color="#ff0000"
                                            :content="correctedUnseenCount"
                                            :model-value="correctedUnseenCount"
                                            location="left"
                                            offset-x="10"
                                            style="z-index: 8; width: 100%; height: 48px !important;"
                                        >
                                            <v-btn
                                                :id="'correctedTab'"
                                                :color="tab === 'corrected' ? '#E94E1B' : '#ED7251'"
                                                theme="dark"
                                                tile
                                                style="width: 100%; overflow: hidden; text-overflow: ellipsis; border: 1px solid white !important; font-size: 25px; height: 40px"
                                                elevation="0"
                                                role="tab"
                                                class="text-capitalize px-2"
                                                v-bind="props"
                                                @click="tab = 'corrected'; checkImagePreviews(); setFocusOn('correctedTab')"
                                                @keydown.tab="setFocusOnFirstItemTab('correctedIcon-0', 'corrected')"
                                            >
                                                <img
                                                    :src="tick3Icon"
                                                    class="iconToWhite icon mr-1"
                                                    alt=""
                                                >
                                                Korrigiert
                                            </v-btn>
                                        </v-badge>
                                    </template>
                                    <img
                                        v-if="hoeren"
                                        :src="hausaufgabenMetacom"
                                        width="100"
                                        style="margin: auto"
                                        alt=""
                                        class="mr-1"
                                    >
                                    <img
                                        v-if="hoeren"
                                        :src="correctedMetacom"
                                        width="100"
                                        style="margin: auto"
                                        alt=""
                                    >
                                </v-tooltip>
                            </v-col>
                        </v-row>

                        <simple-bar
                            :auto-hide="false"
                            class="scroll-area"
                            :settings="settings"
                        >
                            <div
                                @keydown.right="handleArrowKeyRight()"
                                @keydown.left="handleArrowKeyLeft()"
                            >
                                <div v-if="tab === 'toDo' && babyFilesToDoDisplay">
                                    <v-row
                                        v-show="false"
                                        class="cols"
                                        style="width: 99%; margin: 0.5em auto 0.5em auto;"
                                    >
                                        <v-col
                                            style="display: flex; align-items: center"
                                            class="sectionHeaderCol"
                                        >
                                            <img
                                                :src="correctedIcon"
                                                style="height: 30px"
                                                class="sectionHeaderImg"
                                                alt=""
                                            >
                                            <h2
                                                class="pl-2 sectionHeaderText"
                                                tabindex="0"
                                            >
                                                Offen:
                                            </h2>
                                        </v-col>
                                    </v-row>
                                    <div
                                        v-for="({ day, items }, index) in babyFilesToDoDisplay"
                                        :key="`${index}${day}`"
                                    >
                                        <div class="d-flex justify-center align-center">
                                            <hr style="min-width: 25%">
                                            <p
                                                :ref="`hausiItemstoDo${day}`"
                                                style="font-size: x-large; padding: 0.5em; color: grey"
                                                class="text-center"
                                            >
                                                {{ new Date(day).toLocaleDateString(targetLang, { weekday: 'short', year: '2-digit', month: '2-digit', day: '2-digit' }) }}
                                            </p>
                                            <hr style="min-width: 25%">
                                        </div>
                                        <div
                                            v-if="items && items.length"
                                            class="d-flex justify-space-around"
                                            style="flex-wrap: wrap"
                                        >
                                            <HausiWidgetListItem
                                                v-for="(item, index) in items"
                                                :key="item.file"
                                                :item="item"
                                                :index="index"
                                                :category="'toDo'"
                                                :show-tooltips="showTooltips"
                                                :hoeren="hoeren"
                                                :sehen="sehen"
                                                :account="account"
                                                :class="{ brighterFileRow: index % 2 === 1, disabledFileRow: false }"
                                                style="min-width: 45%; width: 45%;"
                                                @open-viewer="handleOpenAnsichtClick(item)"
                                                @upload-pupil-answer="pupilUploadFile(item)"
                                                @download-pupil-upload="clickDownloadPupilUpload(item)"
                                                @move-without-answer="() => { moveDialog = true; elementToMove = item }"
                                                @play-video="play(item)"
                                                @play-audio="playAudio(item)"
                                            />
                                        </div>
                                        <div
                                            v-else
                                            class="emptyRow ma-2"
                                            align="center"
                                            justify="center"
                                        >
                                            <p tabindex="0">
                                                Keine Aktivitäten heute
                                            </p>
                                        </div>

                                        <v-row
                                            v-if="babyFilesToDoDisplay && babyFilesToDoDisplay.length === 0"
                                            class="emptyRow ma-2"
                                            align="center"
                                            justify="center"
                                        >
                                            <v-col class="pa-0">
                                                <p
                                                    id="toDoEmpty"
                                                    tabindex="0"
                                                >
                                                    Aktuell gibt es nichts zu erledigen
                                                </p>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </div>

                                <div v-if="tab === 'done'">
                                    <v-row
                                        v-show="false"
                                        class="cols"
                                        style="width: 99%; margin: 0.5em auto 0.5em auto;"
                                    >
                                        <v-col
                                            style="display: flex; align-items: center"
                                            class="sectionHeaderCol"
                                        >
                                            <img
                                                :src="correctedIcon"
                                                style="height: 30px"
                                                class="sectionHeaderImg"
                                                alt=""
                                            >
                                            <h2
                                                class="pl-2 sectionHeaderText"
                                                tabindex="0"
                                            >
                                                Erledigt:
                                            </h2>
                                        </v-col>
                                    </v-row>

                                    <div
                                        v-for="({ day, items }, index) in babyFilesDoneDisplay"
                                        :key="`${index}${day}`"
                                    >
                                        <div class="d-flex justify-center align-center">
                                            <hr style="min-width: 25%">
                                            <p
                                                :ref="`hausiItemsdone${day}`"
                                                style="font-size: x-large; padding: 0.5em; color: grey"
                                                class="text-center"
                                            >
                                                {{ new Date(day).toLocaleDateString(targetLang, { weekday: 'short', year: '2-digit', month: '2-digit', day: '2-digit' }) }}
                                            </p>
                                            <hr style="min-width: 25%">
                                        </div>
                                        <div
                                            v-if="items && items.length"
                                        >
                                            <div
                                                v-for="(item, index) in items"
                                                :key="item.file"
                                            >
                                                <HausiWidgetListItem
                                                    :item="item"
                                                    :index="index"
                                                    :category="'done'"
                                                    :show-tooltips="showTooltips"
                                                    :hoeren="hoeren"
                                                    :sehen="sehen"
                                                    :account="account"
                                                    :class="{ brighterFileRow: index % 2 === 1, disabledFileRow: false }"
                                                    @open-viewer="handleOpenAnsichtClick(item)"
                                                    @upload-pupil-answer="pupilUploadFile(item)"
                                                    @download-pupil-upload="clickDownloadPupilUpload(item)"
                                                    @play-video="play(item)"
                                                    @play-audio="playAudio(item)"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <v-row
                                        v-if="babyFilesDone && babyFilesDone.length === 0"
                                        class="emptyRow ma-2"
                                        align="center"
                                        justify="center"
                                    >
                                        <v-col class="pa-0">
                                            <p
                                                id="doneEmpty"
                                                tabindex="0"
                                            >
                                                Aktuell gibt es noch nichts Erledigtes
                                            </p>
                                        </v-col>
                                    </v-row>
                                </div>

                                <div v-if="tab === 'corrected'">
                                    <v-row
                                        v-show="false"
                                        class="cols"
                                        style="width: 99%; margin: 0.5em auto 0.5em auto;"
                                    >
                                        <v-col
                                            style="display: flex; align-items: center"
                                            class="sectionHeaderCol"
                                        >
                                            <img
                                                :src="correctedIcon"
                                                style="height: 30px"
                                                class="sectionHeaderImg"
                                                alt=""
                                            >
                                            <h2
                                                class="pl-2 sectionHeaderText"
                                                tabindex="0"
                                            >
                                                Korrigiert:
                                            </h2>
                                        </v-col>
                                    </v-row>

                                    <div
                                        v-for="({ day, items }, index) in babyFilesCorrectedDisplay"
                                        :key="`${index}${day}`"
                                    >
                                        <div class="d-flex justify-center align-center">
                                            <hr style="min-width: 25%">
                                            <p
                                                :ref="`hausiItemscorrected${day}`"
                                                style="font-size: x-large; padding: 0.5em; color: grey"
                                                class="text-center"
                                            >
                                                {{ new Date(day).toLocaleDateString(targetLang, { weekday: 'short', year: '2-digit', month: '2-digit', day: '2-digit' }) }}
                                            </p>
                                            <hr style="min-width: 25%">
                                        </div>
                                        <div
                                            v-if="items && items.length"
                                        >
                                            <div
                                                v-for="(item, index) in items"
                                                :key="item.file"
                                            >
                                                <HausiWidgetListItem
                                                    :item="item"
                                                    :index="index"
                                                    :category="'corrected'"
                                                    :show-tooltips="showTooltips"
                                                    :hoeren="hoeren"
                                                    :sehen="sehen"
                                                    :account="account"
                                                    :class="{ brighterFileRow: index % 2 === 1, disabledFileRow: false }"
                                                    @open-viewer="handleOpenAnsichtClick(item)"
                                                    @upload-pupil-answer="pupilUploadFile(item)"
                                                    @download-pupil-upload="clickDownloadPupilUpload(item)"
                                                    @play-video="play(item)"
                                                    @play-audio="playAudio(item)"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <v-row
                                        v-if="babyFilesCorrected && babyFilesCorrected.length === 0"
                                        class="emptyRow ma-2"
                                        align="center"
                                        justify="center"
                                    >
                                        <v-col class="pa-0">
                                            <p
                                                id="correctedEmpty"
                                                tabindex="0"
                                            >
                                                Aktuell gibt es nichts Korrigiertes
                                            </p>
                                        </v-col>
                                    </v-row>
                                </div>
                            </div>
                        </simple-bar>
                    </div>
                </div>
            </v-col>
        </v-row>

        <!-- popups, overlays and snackbars -->

        <!-- Normal Video Popup -->
        <v-dialog
            v-if="videoUrl"
            :model-value="videoUrl"
            scrim="rgba(0,0,0,0.9)"
            max-width="90%"
            width="unset"
            @click:outside="videoUrl = null; $refs.normalVideo.stop();"
        >
            <v-row
                justify="end"
                align="end"
            >
                <v-col
                    cols="1"
                    align-self="end"
                >
                    <v-icon
                        size="large"
                        theme="dark"
                        @click="videoUrl = null; primaryVideoUrl = null; secondaryVideoUrl = null; $refs.normalVideo.stop();"
                    >
                        fas fa-times
                    </v-icon>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="auto">
                    <video-in-video
                        ref="normalVideo"
                        :primary-video-src="videoUrl"
                        :subtitle-src="subtitleURL"
                    />
                </v-col>
            </v-row>
        </v-dialog>

        <!-- Video in Video Popup -->
        <v-dialog
            v-if="primaryVideoUrl && secondaryVideoUrl"
            :model-value="primaryVideoUrl && secondaryVideoUrl"
            scrim="rgba(0,0,0,0.9)"
            max-width="90%"
            width="unset"
            @click:outside="primaryVideoUrl = null; secondaryVideoUrl = null; $refs.videoInVideo.stop();"
        >
            <v-row
                justify="end"
                align="end"
            >
                <v-col
                    cols="1"
                    align-self="end"
                >
                    <v-icon
                        size="large"
                        theme="dark"
                        @click="primaryVideoUrl = null; secondaryVideoUrl = null; $refs.videoInVideo.stop();"
                    >
                        fas fa-times
                    </v-icon>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="auto">
                    <video-in-video
                        ref="videoInVideo"
                        size-controls
                        :primary-video-src="primaryVideoUrl"
                        :secondary-video-src="secondaryVideoUrl"
                        :subtitle-src="subtitleURL"
                    />
                </v-col>
            </v-row>
        </v-dialog>

        <v-dialog
            v-if="audioUrl"
            :model-value="audioUrl"
            z-index="95"
            scrim="rgba(0,0,0,0.9)"
            max-width="90%"
            width="unset"
            @click:outside="audioUrl = null;"
        >
            <v-row
                justify="end"
                align="end"
            >
                <v-col
                    cols="1"
                    align-self="end"
                >
                    <v-icon
                        size="large"
                        theme="dark"
                        @click="audioUrl = null;"
                    >
                        fas fa-times
                    </v-icon>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="auto">
                    <audio
                        id="audioPlayer"
                        :src="audioUrl"
                        controls
                        :width="windowWidth > 900 ? '80%' : '40%%'"
                        :style="{ marginLeft: windowWidth > 900 ? '10%' : '10px' }"
                    >
                        Your browser does not support the video tag.
                    </audio>
                </v-col>
            </v-row>
            <!--            <v-icon alt="Audio schließen" tabindex="2" large style="position: fixed; top: 50px; right: 50px; z-index: 99" @click="audioUrl = false" dark>-->
            <!--                fas fa-times-->
            <!--            </v-icon>-->
            <!--            <audio id="audioPlayer" alt="" tabindex="1" :src="audioUrl" controls :width="windowWidth > 900 ? '80%' : '40%%'"-->
            <!--                   :style="{ marginLeft: windowWidth > 900 ? '10%' : '10px' }">-->
            <!--                Your browser does not support the video tag.-->
            <!--            </audio>-->
        </v-dialog>

        <v-dialog
            v-model="deleteDialog"
            max-width="350px"
        >
            <v-card
                class="cardStyle"
                style="overflow: hidden; border-radius: 16px"
            >
                <v-card-title class="d-flex align-center justify-space-between">
                    <p>Löschen bestätigen</p>
                    <v-btn
                        size="x-small"
                        variant="text"
                        elevation="0"
                        class="pa-0"
                        @click="deleteDialog = false"
                    >
                        <img
                            :src="schliesenIcon"
                            class="icon20"
                            alt="schließen"
                        >
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    Willst du die Datei wirklich <strong>unwiderruflich</strong> löschen?
                </v-card-text>

                <v-card-actions class="d-flex justify-end">
                    <v-btn
                        class="mr-2 text-capitalize"
                        color="rot"
                        theme="dark"
                        @click="clickDeleteUpload"
                    >
                        <img
                            :src="papierkorbIcon"
                            class="iconToWhite mr-2"
                            alt=""
                        >
                        Löschen
                    </v-btn>

                    <v-btn
                        class="mr-2 text-capitalize"
                        color="gruen"
                        theme="dark"
                        @click="deleteDialog = false"
                    >
                        <img
                            :src="schliesenIcon"
                            class="iconToWhite mr-2"
                            alt=""
                        >
                        Abbrechen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="moveDialog"
            max-width="350px"
        >
            <v-card
                class="cardStyle"
                style="overflow: hidden; border-radius: 16px"
            >
                <v-card-title class="d-flex align-center justify-space-between">
                    <p>Abgeben bestätigen</p>
                    <v-btn
                        size="x-small"
                        variant="text"
                        elevation="0"
                        class="pa-0"
                        @click="moveDialog = false"
                    >
                        <img
                            :src="schliesenIcon"
                            class="icon20"
                            alt="schließen"
                        >
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    Willst du die Datei wirklich <strong>ohne</strong> eine Lösung/Abgabe abgeben?
                </v-card-text>

                <v-card-actions class="d-flex justify-end">
                    <v-btn
                        class="mr-2 text-capitalize"
                        color="rot"
                        theme="dark"
                        @click="moveDialog = false"
                    >
                        <img
                            :src="schliesenIcon"
                            class="iconToWhite mr-2"
                            alt=""
                        >
                        Abbrechen
                    </v-btn>
                    <v-btn
                        class="mr-2 text-capitalize"
                        color="gruen"
                        theme="dark"
                        @click="moveUploadToDone(elementToMove)"
                    >
                        <img
                            :src="fertigIcon"
                            class="iconToWhite mr-2"
                            alt=""
                        >
                        Abgeben
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import hausaufgabenIconLeer from '../../../assets/Icons/dokumente-56.svg'
import doneIcon from '../../../assets/Icons/erledigt-orange-90.svg'
import correctedIcon from '../../../assets/Icons/korrigiert-orange-91.svg'
import papierkorbIcon from "../../../assets/Icons/FaceliftIcons/loeschen_muelleimer.svg";
import schliesenIcon from '../../../assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg'
import fertigIcon from '../../../assets/Icons/fertig-74.svg'
import tick1Icon from '../../../assets/Icons/tick-1.svg'
import tick2Icon from '../../../assets/Icons/tick-2.svg'
import tick3Icon from '../../../assets/Icons/tick-3.svg'

import hausaufgabenMetacom from '../../../assets/METACOM/Hausis/hausaufgaben.png'
import toDoMetacom from '../../../assets/METACOM/Hausis/machen.png'
import doneMetacom from '../../../assets/METACOM/Hausis/beenden.png'
import correctedMetacom from '../../../assets/METACOM/Hausis/Korrektur.png'

import {mapActions, mapGetters, mapState, mapMutations} from "vuex";
import * as backend from "../../../api/backend";
import ProgressBar from "../../ProgressBar";
import AntiScroll from "../../AntiScroll";
import VideoInVideo from "@/components/Utils/VideoInVideo";
import HausiWidgetListItem from "@/components/Schueler/HausiWidget/HausiWidgetListItem";

export default {
    name: "HausiWidgetBaby",
    components: {
        HausiWidgetListItem,
        VideoInVideo,
        AntiScroll,
        ProgressBar,
    },
    props: {
        small: {required: false, default: false},
    },
    data: () => ({
        subtitleURL: null,
        noSubtitle: false,
        tab: 'toDo',
        settings: {
            suppressScrollY: false,
            suppressScrollX: true,
            wheelPropagation: false,
        },
        showPupilUploadProgress: false,
        pupilUploadProgress: 0.0,
        pupilXmlHttpRequest: null,
        appointments: [],

        babyFilesToDo: [],
        babyFilesCorrected: [],
        babyFilesDone: [],

        /*to toggle btwn showing 3 items and whole list*/
        babyFilesToDoDisplay: [],
        babyFilesCorrectedDisplay: [],
        babyFilesDoneDisplay: [],
        showLoading: false,

        person: {
            accessibility: {
                screenreader: false,
            }
        },
        account: '',
        videoUrl: null,
        audioUrl: null,
        videoLoading: false,
        primaryVideoUrl: null,
        secondaryVideoUrl: null,

        sehen: false,
        hoeren: false,
        babyView: false,

        deleteDialog: false,
        elementToDelete: null,
        moveDialog: false,
        elementToMove: null,

        hausaufgabenIconLeer,
        doneIcon,
        correctedIcon,
        papierkorbIcon,
        schliesenIcon,
        fertigIcon,
        tick1Icon,
        tick2Icon,
        tick3Icon,

        hausaufgabenMetacom,
        toDoMetacom,
        doneMetacom,
        correctedMetacom,
    }),
    computed: {
        ...mapState("auth", ["token"]),
        ...mapState('translation', ['targetLang']),
        ...mapGetters('pupils', ['getTutorialProgress']),
        ...mapGetters('util', ['currentlyOpenTeacherUploadId']),
        ...mapState('magnifier', ["magnifier"]),
        ...mapState('util', ['keyboard', 'windowWidth']),
        ...mapState("speechControl", ["word"]),
        ...mapState("tooltips", ["shortcutTooltips", "showTooltips"]),
        ...mapGetters("gamepad", {gamepadBButtonPressed: "bButtonPressed"}),
        ...mapMutations("snackbar", ["showSnackbar"]),

        todoUnseenCount() {
            let result = -1;
            result = this.babyFilesToDo.filter(file => !file.seenByMe).length;
            this.setBabyFilesToDoBadge(result);
            return result;
        },
        correctedUnseenCount() {
            let result = -1;
            result = this.babyFilesCorrected.filter(file => !file.seenByMe).length;
            this.setBabyFilesCorrectedBadge(result);
            return result;
        }
    },
    watch: {
        word(newVal) {
            if (newVal === 'aufgaben') {
                this.focusOnHausaufgaben();
            }
        },
        async currentlyOpenTeacherUploadId(newVal, oldVal) {
            if (oldVal && !newVal) {
                await this.getAllTeacherFiles();
                this.sliceLists();
            }
        },
        gamepadBButtonPressed(newVal, oldVal) {
            // on keyup of gamepadYButton
            if (oldVal === true && newVal === false) {
                this.focusOnHausaufgaben();
            }
        },
        tab(newVal) {
            setTimeout(() => {
                let today = new Date();
                today.setHours(0,0,0,0);
                if (this.$refs[`hausiItems${newVal}${today.toISOString()}`] && this.windowWidth > 900) {
                    this.$refs[`hausiItems${newVal}${today.toISOString()}`][0]
                        .scrollIntoView(true, { behavior: 'smooth', block: 'center', inline: 'center' });
                }
            }, 1000);
        }
    },
    async created() {
        let temp = await this.getMePupil();
        this.person = temp;
        this.account = temp.account;
        this.babyView = temp.babyView;
        if (temp.accessibility) {
            this.sehen = temp.accessibility.sehen;
            this.hoeren = temp.accessibility.hoeren;
        } else {
            this.sehen = false;
            this.hoeren = false;
        }
        //to check if they are babys
        if (this.babyView) {
            this.babyViewTut = true;
        }
        await this.getAllTeacherFiles(true);
        await this.sliceLists();
    },
    async beforeMount() {
        this.account = (await this.getMePupil()).account;
    },
    async mounted() {
        window.addEventListener("keydown", this.handleKeyUp);
        this.account = (await this.getMePupil()).account;
        this.requestAppointments();
    },
    beforeUnmount() {
        window.removeEventListener("keydown", this.handleKeyUp);
    },
    methods: {
        ...mapActions('appointments', ['getAppointments']),
        ...mapActions('pupils', ['setTutorialProgress', 'createPupil', 'getMePupil',]),
        ...mapActions('translation', ['setTranslatedText', 'showTranslation', 'translateToTargetLang']),
        ...mapActions('util', ['toggleLoading', 'toggleOpenAppointment', 'toggleCurrentUploadGroup', 'toggleOpenTeacherUploadId', 'insertLastOpenedInEditor']),
        ...mapActions('tooltips', ['triggerShortcutTooltips']),
        ...mapMutations('badges', ['setBabyFilesToDoBadge', 'setBabyFilesCorrectedBadge']),

        handleKeyUp(event) {
            if (event.altKey && event.key === 'h') {
                this.focusOnHausaufgaben();
                this.triggerShortcutTooltips();
            }
        },
        focusOnHausaufgaben() {
            this.tab = 'toDo';
            if (!this.magnifier) {
                if (this.babyFilesToDoDisplay.length === 0) {
                    document.getElementById("toDoTab").focus();
                } else {
                    document.getElementById("toDoItem-0").focus();
                }
            } else {
                if (this.babyFilesToDoDisplay.length === 0) {
                    document.getElementById("toDoTabMagnifier").focus();
                } else {
                    document.getElementById("toDoItemMagnifier-0").focus();
                }
            }
        },

        async clickDeleteUpload() {
            let file = this.elementToDelete;

            // find pupil upload
            const neededAppointment = this.appointments.filter(app => app._id === this.elementToDelete.appointmentId)[0];
            const fileToDelete = neededAppointment.pupilUpload.filter(
                upload => upload.uploader === this.account && file.editLeafs.includes(upload._id)
            )[0];

            const res = await backend.deleteUpload((fileToDelete._id).toString());

            if (res.status === 204) {
                this.showSnackbar({message: `Datei ${file.title} erfolgreich gelöscht`});
            } else {
                this.showSnackbar({message: 'Beim Löschen ist ein Fehler aufgetreten', color: 'error'});
            }

            this.deleteDialog = false;

            this.getAllTeacherFiles(); //reload files and sort in tabs/arrays
            this.elementToDelete = null; //clear temp reference to file
        },

        async requestAppointments() {
            this.appointments = await this.getAppointments();
        },

        getAllTeacherFiles(fetch) {
            this.showLoading = true;

            this.babyFilesToDo = [];
            this.babyFilesCorrected = [];
            this.babyFilesDone = [];
            this.getAppointments(fetch).then(async (appointments) => {
                for (let i = 0; i < appointments.length; i++) {
                    const appointment = appointments[i];
                    if (appointment.teacherUploads.length) {
                        for (let j = 0; j < appointment.teacherUploads.length; j++) {
                            // Check if this is a picture in picture record -> don't show this
                            const element = { ...appointment.teacherUploads[j] };
                            // Add upload if there is no canPip-Property or if its true
                            element.fachIcon = appointment.schoolSubject.icon;
                            element.fachColor = appointment.schoolSubject.color;
                            element.groupName = appointment.schoolSubject.name;
                            element.appointmentId = appointment._id;
                            element.isDone = appointment.teacherUploads[j].editLeafs.length > 0;
                            element.previewData = null;
                            element.day = appointment.day;
                            // The default preview data (only added if some files are really there)
                            let previewData = {
                                fileId: element.file,
                                loading: false,
                                fetched: false,
                                preview: null
                            }

                            if (this.isOpen(appointment, element)) {
                                element.previewData = previewData;
                                this.babyFilesToDo.push(element);
                            } else if (element.editParent && !element.seenByMe) {
                                let correctedFile = appointment.pupilUpload.filter(
                                    upload => upload.editLeafs && upload.editLeafs.length > 0 && upload.editParent === element._id);
                                if (correctedFile && correctedFile.length > 0 && correctedFile.editLeafs && correctedFile.editLeafs.leading > 0) {
                                    // Only set the preview data if there is a corrected file
                                    // Set the correct id of the file
                                    previewData.fileId = correctedFile[0].editLeafs[correctedFile[0].editLeafs.length - 1];
                                    element.previewData = previewData;
                                }
                                this.babyFilesCorrected.push(element);
                            } else {
                                let pupilFile = appointment.pupilUpload.filter(upload => upload.uploader === this.account && upload.editParent === element._id);
                                if (pupilFile && pupilFile.length > 0) {
                                    previewData.fileId = pupilFile[0].file;
                                    element.previewData = previewData;
                                } else
                                    previewData = null;
                                this.babyFilesDone.push(element);
                            }
                        }
                    }
                }
                await this.sliceLists();
                this.showLoading = false;
            });
        },
        async fetchPreview(element, isTeacherUpload) {
            // Check if the element even has a preview and if it hasn't been fetched yet
            if (!element.previewData || element.previewData.fetched || element.previewData.loading)
                return;
            element.previewData.loading = true;
            try {

                let res;
                if (isTeacherUpload) {
                    // Fetch teacher upload
                    res = await backend.getTeacherUploadThumbnail(element.appointmentId, element.previewData.fileId);
                    if (res.ok && res.status === 200) {
                        let blob = await res.blob();
                        element.previewData.preview = window.URL.createObjectURL(blob);
                    }
                } else {
                    // Fetch pupil upload
                    if (element.title.endsWith('.pdf')) {
                        res = await backend.getPupilUpload(element.appointmentId, element.previewData.fileId);
                    } else {
                        res = await backend.getPupilUploadThumbnail(element.appointmentId, element.previewData.fileId);
                    }
                    if (res.ok && res.status === 200) {
                        let blob = await res.blob();
                        element.previewData.preview = window.URL.createObjectURL(blob);
                    }
                }
            } catch (e) {
                console.error(e);
            }
            // Set fetched to true, to not fetch it again
            element.previewData.loading = false;
            element.previewData.fetched = true;

            // Sorry: I tried it with keys and a subcomponents, but nothing worked, this is the only thing that forces the list to update...
            this.$forceUpdate();
        },
        isOpen(appointment, element) {
            if (this.wasMovedByMe(element)) {
                return false;
            }
            /** Needs explicit check for false (for now) because ! could also mean not defined */
            if (element.isAssignment === false && element.seenByMe) {
                return false;
            }

            const checkingLeafs = appointment.pupilUpload.filter(
                upload => upload.uploader === this.account && element.editLeafs.includes(upload._id)
            );

            return checkingLeafs.length === 0 && !element.editParent;

        },

        async clickDownloadPupilUpload(file) {
            // find pupil upload
            const neededAppointment = this.appointments.filter(app => app._id === file.appointmentId)[0];

            const neededPupilUpload = neededAppointment.pupilUpload.filter(
                upload => upload.uploader === this.account && file.editLeafs.includes(upload._id)
            )[0];

            const res = await backend.getPupilUpload(file.appointmentId, neededPupilUpload.file);
            fetch(res.url, {
                method: 'GET',
                headers: new Headers({
                    "Authorization": "Bearer " + this.token
                })
            })
                .then(response => response.blob())
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = neededPupilUpload.title;
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    setTimeout(function () {
                        a.remove();
                        window.URL.revokeObjectURL(url);
                    }, 1000);
                });
        },

        handleOpenAnsichtClick(element) {
            const neededAppointment = this.appointments.filter(app => app._id === element.appointmentId)[0];

            if (element.editParent && !element.editLeafs.length || !element.isAssignment) {
                this.openAnsicht(neededAppointment, element.title, element.file, element._id, 'pupil');
            } else {
                const pupilFile = neededAppointment.pupilUpload.filter(
                    item =>
                        (element.editLeafs
                            && element.editLeafs.includes(item._id))
                        && item.uploader === this.account
                )[0];
                if (pupilFile) {
                    this.openAnsicht(neededAppointment, pupilFile.title, pupilFile.file, pupilFile._id, 'teacher')
                } else {
                    this.openAnsicht(neededAppointment, element.title, element.file, element._id, 'pupil');
                }
            }
        },

        openAnsicht(appointment, name, id, uploadId, userGroup) {
            const group = appointment.schoolSubject.name;
            const color = appointment.schoolSubject.color;
            const appointmentId = appointment._id;

            this.$router.push({
                name: 'editor',
                params: { mode: 'viewer' },
                query:
                    {
                        group: group,
                        color: color,
                        aId: appointmentId,
                        title: name,
                        fId: id,
                        uploadid: uploadId,
                        ug: userGroup
                    }
            });
        },

        isLangPackageAvailable() {
            for (let i = 0; i < window.speechSynthesis.getVoices().length; i++) {
                if (window.speechSynthesis.getVoices()[i].lang.includes(this.targetLang)) {
                    return true;
                }
            }

            return false;
        },
        pupilUploadFile(item) {

            let neededAppointment = this.appointments.filter(app => app._id === item.appointmentId)[0];

            if (!neededAppointment) {
                neededAppointment = {};
                neededAppointment._id = item.appointmentId;
            }

            this.toggleOpenAppointment(neededAppointment);
            this.toggleCurrentUploadGroup('baby');
            this.toggleOpenTeacherUploadId(item.file);
            document.getElementById("uploadInput").click();
        },

        async pupilUploadInputChange(appointmentId, teacherFileId) {
            const input = this.$refs.hausiPupilUploadInput;
            const file = input.files[0];
            if (file) {
                const name = file.name;
                const lastDot = name.lastIndexOf('.');
                const fileName = name.substring(0, lastDot);
                const fileExtension = name.substring(lastDot + 1);
                // this.uploadFileName = fileName;
                // this.fileNameCallback = (newFileName) => {
                //     this.showNameFile = false;
                // };
                // this.showNameFile = true;
                await this.uploadFile(appointmentId, teacherFileId, fileName, fileExtension);
            }
        },

        async uploadFile(appointmentId, teacherFileId, fileName, fileExtension) {
            const input = this.$refs.hausiPupilUploadInput;
            const file = input.files[0];
            if (file) {
                const formData = new FormData();
                formData.append('file', file, `${fileName}.${fileExtension}`);
                this.pupilXmlHttpRequest = backend.postTeacherUploadEdit(appointmentId, teacherFileId, file);

                this.pupilXmlHttpRequest.onerror = (e) => {
                    console.error('Pupil upload error:', e)
                    this.showPupilUploadProgress = false;
                    this.pupilXmlHttpRequest = null;
                    this.$refs.hausiPupilUploadInput.value = '';
                };

                this.pupilXmlHttpRequest.onabort = (e) => {
                    console.warn('Pupil upload aborted');
                    this.showPupilUploadProgress = false;
                    this.pupilXmlHttpRequest = null;
                    this.$refs.hausiPupilUploadInput.value = '';
                }

                this.pupilXmlHttpRequest.upload.addEventListener('progress', (e) => {
                    this.pupilUploadProgress = (e.loaded / e.total) * 100;
                });

                this.pupilXmlHttpRequest.addEventListener('load', (e) => {
                    if (this.pupilXmlHttpRequest.status !== 201)
                        console.error('Pupil upload failed:', this.pupilXmlHttpRequest.response);
                    this.showPupilUploadProgress = false;
                    this.showSnackbar({message: 'Gespeichert!'});
                    this.requestAppointments();
                    this.pupilXmlHttpRequest = null;
                    this.$refs.hausiPupilUploadInput.value = '';
                });
                this.pupilUploadProgress = 0.0;
                this.showPupilUploadProgress = true;
                this.pupilXmlHttpRequest.send(formData);
            }
        },

        moveUploadToDone(file) {
            backend.postTeacherUploadMove(file.appointmentId, file._id);
            this.getAllTeacherFiles(true);
            this.elementToMove = null;
            this.moveDialog = false;
        },

        wasMovedByMe(teacherupload) {
            if (teacherupload.isMoved) {
                for (let i = 0; i < teacherupload.isMoved.length; i++) {
                    if (teacherupload.isMoved[i] === this.account) {
                        return true;
                    }
                }
            }
            return false;
        },

        /**
         * method sorts babyFiles by appointment day and fills display arrays with that
         */
        async sliceLists() {

            // get todays date into needed format
            let todayResponse = await backend.getServerTime(null);
            let today = new Date(await todayResponse.json());
            today.setHours(0,0,0,0);
            today = today.toISOString();

            const compareFunction = (a, b) => {
                let result = 0;
                if (a.day > b.day) {
                    result = -1;
                } else if (a.day < b.day) {
                    result = 1;
                }
                return result;
            }

            this.babyFilesToDoDisplay = await this.babyFilesToDo
                .filter(item => (!('canPip' in item) || item.canPip))
                .reduce((previous, currentItem) => {
                    const dateSet = previous.find(set => set.day === currentItem.day);
                    if (dateSet) {
                        dateSet.items.push(currentItem);
                    } else {
                        previous.push({
                            day: currentItem.day,
                            items: [currentItem]
                        });
                    }
                    return [...previous];
                }, []);
            const isTodayInToDoArray = this.babyFilesToDoDisplay.find(dateSet => dateSet.day === today);
            if (!isTodayInToDoArray) {
                this.babyFilesToDoDisplay.push({
                    day: today,
                    items: []
                });
            }
            this.babyFilesToDoDisplay.sort(compareFunction);

            this.babyFilesDoneDisplay = this.babyFilesDone
                .reduce((previous, currentItem) => {
                    const dateSet = previous.find(set => set.day === currentItem.day);
                    if (dateSet) {
                        dateSet.items.push(currentItem);
                    } else {
                        previous.push({
                            day: currentItem.day,
                            items: [currentItem]
                        });
                    }
                    return [...previous];
                }, [])
                .sort(compareFunction);

            this.babyFilesCorrectedDisplay = this.babyFilesCorrected
                .reduce((previous, currentItem) => {
                    const dateSet = previous.find(set => set.day === currentItem.day);
                    if (dateSet) {
                        dateSet.items.push(currentItem);
                    } else {
                        previous.push({
                            day: currentItem.day,
                            items: [currentItem]
                        });
                    }
                    return [...previous];
                }, [])
                .sort(compareFunction);

            await this.checkImagePreviews();
            if (this.windowWidth > 900) {
                setTimeout(() => {
                    this.$refs[`hausiItems${this.tab}${today}`][0]
                        .scrollIntoView(true, { behavior: 'smooth', block: 'center', inline: 'center' });
                }, 2000);
            }
        },
        async checkImagePreviews() {
            if (this.tab === 'toDo') {
                this.babyFilesToDoDisplay.forEach(data =>
                    data.items
                        .filter(value =>
                            value.previewData && !value.previewData.fetched && !value.previewData.loading && !value.previewData.preview)
                        .forEach(value => this.fetchPreview(value, true)));
            } else if (this.tab === 'done') {
                this.babyFilesDoneDisplay.forEach(data =>
                    data.items
                        .filter(value =>
                            value.previewData && !value.previewData.fetched && !value.previewData.loading && !value.previewData.preview)
                        .forEach(value => this.fetchPreview(value, false)));
            } else if (this.tab === 'corrected') {
                this.babyFilesCorrectedDisplay.forEach(data =>
                    data.items
                        .filter(value =>
                            value.previewData && !value.previewData.fetched && !value.previewData.loading && !value.previewData.preview)
                        .forEach(value => this.fetchPreview(value, true)));
            }
        },
        async play(file) {
            // this.setFocusOn("videoPlayer");
            file.seenByMe = true;
            this.videoLoading = true;
            if (file.subtitle) {
                const subtitle = await backend.getSubtitle(file.subtitle);
                this.subtitleURL = window.URL.createObjectURL(await subtitle.blob());
            } else {
                this.noSubtitle = true;
            }
            let pipFile = null;
            if (file.pipReference)
                pipFile = this.babyFilesToDo.find(value => value._id === file.pipReference);

            if (pipFile) {
                // PiP Video
                const primary = await backend.getTeacherUpload(file.appointmentId, file.file);
                const secondary = await backend.getTeacherUpload(file.appointmentId, pipFile.file);
                this.primaryVideoUrl = window.URL.createObjectURL(await primary.blob());
                this.secondaryVideoUrl = window.URL.createObjectURL(await secondary.blob());
            } else {
                const res = await backend.getTeacherUpload(file.appointmentId, file.file);
                this.videoUrl = window.URL.createObjectURL(await res.blob());
            }
            this.videoLoading = false;
        },
        async playAudio(file) {
            // this.setFocusOn("audioPlayer");
            file.seenByMe = true;
            this.videoLoading = true;
            const res = await backend.getTeacherUpload(file.appointmentId, file.file);
            fetch(res.url, {
                method: 'GET',
                headers: new Headers({
                    "Authorization": "Bearer " + this.token
                })
            })
                .then(response => response.blob())
                .then(blob => {
                    this.audioUrl = window.URL.createObjectURL(blob);
                    this.videoLoading = false;
                });
        },

        setFocusOn(element) {
            document.getElementById(element).focus();
        },

        /**
         * Function setzt Fokus wenn Tab Taste gedrückt wird.
         * Bitte beachten: Fokus muss immer auf das Element zuvor gesetzt werden, weil mit Tab noch direkt ein Element
         * nach vorne gesprungen wird.
         * Function sets focus on tabpress
         * ! Focus always has to be set on the previous element since a tabpress will be executed at the same time this
         * function ist called
         * @param element elementId to set focus on if array isn't empty
         * @param tab HausiWidgetTab
         */
        setFocusOnFirstItemTab(element, tab) {
            this.tab = tab;
            switch (tab) {
                case "toDo":
                    if (this.babyFilesToDoDisplay.length > 0) {
                        const el = document.getElementById(element);
                        if (el !== null) {
                            el.focus();
                        }
                    } else {
                        document.getElementById("correctedTab").focus();
                    }
                    break;
                case  "done":
                    if (this.babyFilesDoneDisplay.length > 0) {
                        const el = document.getElementById(element);
                        if (el !== null) {
                            el.focus();
                        }
                    } else {
                        document.getElementById("correctedTab").focus();
                    }
                    break;
                case "corrected":
                    if (this.babyFilesCorrectedDisplay.length > 0) {
                        const el = document.getElementById(element);
                        if (el !== null) {
                            el.focus();
                        }
                    } else {
                        document.getElementById("correctedTab").focus();
                    }
                    break;
                default:
                    break;
            }
        },
        handleArrowKeyLeft() {
            if (!this.keyboard) {
                switch (this.tab) {
                    case  "done":
                        this.setFocusOn("toDoTab");
                        this.tab = "toDo";
                        break;
                    case "corrected":
                        this.setFocusOn("doneTab");
                        this.tab = "done";
                        break;
                    default:
                        break;
                }
            }
        },
        handleArrowKeyRight() {
            if (!this.keyboard) {
                switch (this.tab) {
                    case  "toDo":
                        this.setFocusOn("doneTab");
                        this.tab = "done";
                        break;
                    case "done":
                        this.setFocusOn("correctedTab");
                        this.tab = "corrected";
                        break;
                    default:
                        break;
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
table, th, td {
    border: none;
    border-collapse: collapse;
}

tr {
    height: 40px;
}

th {
    vertical-align: top;
}

table tr th:first-child,
table tr td:first-child {
    padding-top: 10px;
    width: 10px !important;
}

table tr th:nth-child(2),
table tr td:nth-child(2) {
    width: 5px !important;
}

table tr th:last-child,
table tr td:last-child {
    width: 5px !important;
}

table tr th,
table tr td {
    width: 9em;
    border: none;
}

table, tr, td {
    border: none;
}

#hausiWidgetBabyContainer {
    height: auto;
    //width: 90%;
    min-height: 80vh;
    border-radius: 15px;
    box-shadow: 1px 5px 5px silver;
    //margin: auto auto 5em;
    background-color: #F6F6F6;
}

#hausiWidgetHeader {
    background-color: #E94E1B;
    border-radius: 15px 15px 0 0;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    color: white;
}

.fach {
    cursor: pointer;
}

.scroll-area {
    position: relative;
    margin: auto;
    max-height: calc(80vh - 150px);
    height: calc(80vh - 150px);
    overflow: hidden;
}

.fileRow {
    /*margin: 0.5em auto 0.5em auto;*/
    background-color: rgb(112 112 112 / 15%);
    //border-radius: 5px;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    height: 76px !important;
}

.emptyRow {
    background-color: transparent;
    border-radius: 5px;
    height: 35px !important;

    font-size: 25px;

    & p {
        text-align: center;
        width: 100%;
        margin-bottom: 0;
    }
}

.brighterFileRow {
    background-color: rgb(214 214 214 / 15%);
}

.disabledFileRow {
    opacity: 0.5;
}

.fileTitle {
    margin-bottom: 0 !important;
    font-size: 25px;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.babyViewActionBtn {
    min-width: 50px !important;
    width: 50px !important;
    height: 50px !important;
}

.fachLine {
    display: none;
}

.sectionHeaderText {
    color: #F49F31;
    font-weight: 500;
    font-size: 28px;
    margin-bottom: 0;
    display: inline-block;
}

.sectionHeaderCol {
    padding-top: 0;
    padding-bottom: 0;
}

.small-drop-down-menu {
    display: block;
    background-color: #F8F8F8;
    padding-top: 5px;
    padding-bottom: 5px;

    & button {
        width: 100%;
        padding: 25px 10px !important;
    }
}

@media screen and (max-width: 600px) {
    .sectionHeaderText {
        font-size: 24px;
    }

    .sectionHeaderImg {
        height: 24px !important;
    }

    .babyViewActionBtn {
        margin-left: 4px !important;
        min-width: 30px !important;
        width: 30px !important;
        height: 30px !important;
    }

    .babyViewActionBtnImg {
        max-width: 24px !important;
        max-height: 24px !important;
        width: 24px !important;
        height: 24px !important;
    }

    .fachLogo {
        display: none;
    }

    .fachLine {
        display: block;
        min-width: 10px;
        max-width: 10px;
        height: 100%;
        border-radius: 5px 0 0 5px;
    }

    .fileRow {
        height: 40px !important;
    }

    .fileTitle {
        font-size: 16px;
    }
}

.subjectBabyViewActionBtn {
    height: 40px !important;
    min-width: 40px !important;
    width: 40px !important;
    border-radius: 5px;
    box-shadow: 1px 2px 3px silver;
}

.babyViewActionBtnImg {
    width: 35px !important;
    height: 35px !important;
}

.smallBabyViewActionBtnImg {
    width: 28px !important;
    height: 28px !important;
    filter: brightness(1000%);
}

.editImg {
    filter: brightness(1000%);
}

.edit {
    background-color: rgb(var(--v-theme-chatColor)) !important;
}

.move {
    background-color: #F49F31 !important;
}

.icon {
    height: 20px;
}

.iconToWhite {
    height: 20px;
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

</style>
