<template>
    <div style="height: auto">
        <v-tooltip
            v-if="notificationsActivated"
            style="z-index: 9"
            :disabled="!showTooltips"
            location="bottom"
        >
            <template v-slot:activator="{ props }">
                <v-btn
                    v-if="big"
                    class="pa-0 pr-1"
                    style="text-transform: inherit; height: 100%"
                    icon="$vuetify"
                    variant="plain"

                    elevation="0"
                    v-on="on"
                    @click="deactivateNotifications"
                >
                    <div
                        class="d-flex align-center"
                        style="width: 100%"
                    >
                        <img
                            :src="bellIcon"
                            class="iconToWhite mx-2"
                            style="opacity: 0.4"
                            height="20"
                            alt="Benachrichtigungen an"
                        >
                    </div>
                </v-btn>
                <v-btn
                    v-else
                    id="bell"
                    class="pr-1"
                    icon="$vuetify"
                    variant="plain"

                    size="x-small"
                    v-bind="props"
                    @click="deactivateNotifications"
                >
                    <img
                        :src="bellIcon"
                        class="iconToWhite"
                        style="opacity: 0.4"
                        height="20"
                        alt="Benachrichtigungen an"
                    >
                </v-btn>
            </template>
            <span>Browserbenachrichtigungen deaktivieren</span>
        </v-tooltip>
        <v-tooltip
            v-else
            style="z-index: 9"
            :disabled="!showTooltips"
            location="bottom"
        >
            <template v-slot:activator="{ props }">
                <v-btn
                    v-if="big"
                    class="pa-0 pr-1"
                    style="text-transform: inherit; height: 100%; background-color: transparent"
                    icon="$vuetify"
                    variant="plain"

                    elevation="0"
                    v-on="on"
                    @click="activateNotifications"
                >
                    <div
                        class="d-flex align-center"
                        style="width: 100%"
                    >
                        <img
                            :src="bellOffIcon"
                            class="iconToWhite mx-2"
                            style="opacity: 0.4"
                            height="20"
                            alt="Benachrichtigungen an"
                        >
                    </div>
                </v-btn>
                <v-btn
                    v-else
                    id="bell"
                    class="pr-1"
                    icon="$vuetify"
                    variant="plain"
                    size="x-small"
                    v-bind="props"
                    @click="activateNotifications"
                >
                    <img
                        :src="bellOffIcon"
                        class="iconToWhite"
                        style="opacity: 0.4"
                        height="20"
                        alt="Benachrichtigungen an"
                    >
                </v-btn>
            </template>
            <span>Browserbenachrichtigungen aktivieren</span>
        </v-tooltip>

        <ActivateBrowserNotificationsDialog
            v-if="showActivateBrowserNotificationsDialog"
            @close="showActivateBrowserNotificationsDialog = false"
        />
    </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import ActivateBrowserNotificationsDialog from "./ActivateBrowserNotificationsDialog";
import * as BackendApi from "../../../../api/backend";
import bellIcon from'@/assets/Icons/FaceliftIcons/benachrichtigung_an.svg';
import bellOffIcon from'@/assets/Icons/FaceliftIcons/benachrichtigung_aus.svg';

export default {
    name: "ToggleChatNotificationButton",
    components: { ActivateBrowserNotificationsDialog },
    props: {
        big: { required: false, default: false }
    },
    data: () => ({
        showActivateBrowserNotificationsDialog: false,
        devices: [],
        bellIcon,
        bellOffIcon,
    }),
    computed: {
        ...mapState("auth", ["deviceToken", "account"]),
        ...mapState("tooltips", ["shortcutTooltips", "showTooltips"]),
        mappedDevices() {
            return this.devices.map((device) => ({
                ...device,
                isThisDevice: device.token === this.deviceToken,
                lastLoginPretty: new Date(device.lastLoginTime).toLocaleTimeString(),
            }));
        },
        thisDevice() {
            return this.mappedDevices.find((device) => device.isThisDevice) || {};
        },
        notificationsActivated() {
            return this.thisDevice.subscription && Notification.permission === "granted";
        },
    },
    async mounted() {
        await this.loadDevices();
    },
    methods: {
        ...mapActions("matrix", ["deleteMatrixRoom"]),
        ...mapActions("accounts", ["getDevices"]),
        ...mapMutations("snackbar", ["showSnackbar"]),
        async loadDevices(update) {
            this.devices = await this.getDevices({
                accountId: this.account._id,
                update
            });
        },
        activateNotifications() {
            this.subscribeUser();
        },
        deactivateNotifications() {
            this.unsubscribeUser();
        },
        async unsubscribeUser() {
            await BackendApi.unsubscribe({
                deviceToken: this.deviceToken,
            });
            await this.loadDevices(true);
            this.showSnackbar({
                message: `Browserbenachrichtigungen deaktiviert`,
            });
        },
        async subscribeUser() {
            // urlB64ToUint8Array is a magic function that will encode the base64 public key
            // to Array buffer which is needed by the subscription option
            const urlB64ToUint8Array = (base64String) => {
                const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
                const base64 = (base64String + padding)
                    // eslint-disable-next-line no-useless-escape
                    .replace(/\-/g, "+")
                    .replace(/_/g, "/");
                const rawData = atob(base64);
                const outputArray = new Uint8Array(rawData.length);
                for (let i = 0; i < rawData.length; ++i) {
                    outputArray[i] = rawData.charCodeAt(i);
                }
                return outputArray;
            };

            const publicVapidKeyResponse = await BackendApi.getPublicVapidKey();
            const publicVapidKey = await publicVapidKeyResponse.json();

            if (!("serviceWorker" in navigator)) {
                this.showSnackbar({
                    message:
                        "Browserbenachrichtigungen werden auf diesem Gerät nicht unterstützt.",
                    color: "Error",
                });
                return;
            }

            const serviceWorkerRegistrationExists = await navigator.serviceWorker.getRegistration();
            if (!serviceWorkerRegistrationExists) {
                this.showSnackbar({
                    message:
                        "Browserbenachrichtigungen können noch nicht aktiviert werden. Vielleicht hilft es die Seite neuzuladen.",
                    color: "Error",
                });
                return;
            }

            const serviceWorkerRegistration = await navigator.serviceWorker.ready;
            if (!serviceWorkerRegistration.pushManager) {
                this.showSnackbar({
                    message:
                        "Benachrichtigungen werden auf diesem Browser nicht unterstützt. I.d.R. sind davon iOS Geräte und Safari von macOS betroffen.",
                    color: "Error",
                });
                return;
            }

            try {
                const subscription =
                    await serviceWorkerRegistration.pushManager.subscribe({
                        userVisibleOnly: true,
                        applicationServerKey: urlB64ToUint8Array(publicVapidKey),
                    });

                await BackendApi.subscribe({
                    subscription,
                    deviceToken: this.deviceToken,
                });
                await this.loadDevices(true);
                this.showSnackbar({
                    message: `Browserbenachrichtigungen aktiviert`,
                });
            } catch (err) {
                if (Notification.permission === "denied") {
                    this.showSnackbar({
                        message: `Dein Browser blockiert das Aktivieren von Benachrichtigungen. Erlaube in deinen Browsereinstellungen das Empfangen von Benachrichtigungen`,
                        color: "Error",
                    });
                    console.warn("Permission for notifications was denied", err);
                } else {
                    this.showSnackbar({
                        message: `Beim Aktivieren der Browserbenachrichtigungen ist ein unerwarteter Fehler aufgetreten. `,
                        color: "Error",
                    });
                    console.error("Unable to subscribe to push", err);
                }
            }
        },
    },
};
</script>

<style scoped lang="scss">
</style>
