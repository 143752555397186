<template>
    <div
        class="main"
        style="margin-bottom: 2%"
        @click="$emit('closeTopMenu')"
    >
        <v-col
            style="margin-left: 5%"
            cols="10"
        >
            <h1
                aria-label="Kurzüberblick - alles über eKlara in wenigen Minuten"
                tabindex="0"
                style="width: fit-content; margin: 24px 0 0 0; font-size: x-large"
            >
                Kurzüberblick - alles über eKlara in wenigen Minuten
            </h1>
            <div
                v-for="(videoTutorial, index) in videoTutorialsRole"
                :key="index"
            >
                <v-btn
                    elevation="0"
                    style="margin: 4px 0; color: white"
                    color="green"
                    class="text-none"
                    @click="videotutorialSelected = videoTutorial"
                >
                    {{ videoTutorial.title }}
                </v-btn>
            </div>
            <h1
                aria-label="F A Q - Häufig gestellte Fragen"
                class="header"
                tabindex="0"
                style="width: fit-content; margin: 24px 0 0 0; font-size: x-large"
            >
                FAQ - Häufig gestellte Fragen
            </h1>
            <v-expansion-panels
                flat
                dark
                @update:model-value="allPanels = !allPanels"
            >
                <!-- Allgemeines -->
                <v-expansion-panel class="panel">
                    <v-expansion-panel-title
                        color="#4F5FA8"
                        class="panelHeader"
                    >
                        1. Allgemeines
                    </v-expansion-panel-title>
                    <v-expansion-panel-text class="panelContent">
                        <v-expansion-panels
                            flat
                            dark
                            @update:model-value="allPanels = !allPanels"
                        >
                            <v-expansion-panel class="panel">
                                <v-expansion-panel-title
                                    color="#6996cd"
                                    class="panelHeader"
                                >
                                    1.1. Allgemeine Frage
                                </v-expansion-panel-title>
                                <v-expansion-panel-text class="panelContent">
                                    <v-expansion-panels
                                        flat
                                        @update:model-value="allPanels = !allPanels"
                                    >
                                        <v-expansion-panel class="panelQuestion">
                                            <v-expansion-panel-title
                                                color="#707070"
                                                class="panelQuestionHeader"
                                            >
                                                Die Seite lädt nicht oder eine Funktion wird nicht ausgeführt. Was nun?
                                            </v-expansion-panel-title>
                                            <v-expansion-panel-text class="panelQuestionContent">
                                                <p
                                                    tabindex="0"
                                                    class="mb-0"
                                                    style="width: fit-content"
                                                >
                                                    Bitte laden Sie die Seite neu bzw. versuchen Sie die Funktion erneut durchzuführen. Beim zweiten Mal sollte Ihr Vorhaben wieder funktionieren.
                                                </p>
                                            </v-expansion-panel-text>
                                        </v-expansion-panel>
                                        <v-expansion-panel class="panelQuestion">
                                            <v-expansion-panel-title
                                                color="#707070"
                                                class="panelQuestionHeader"
                                            >
                                                Wie lange ist ein virtueller Klassenraum aktiv?
                                            </v-expansion-panel-title>
                                            <v-expansion-panel-text class="panelQuestionContent">
                                                <p
                                                    tabindex="0"
                                                    class="mb-0"
                                                    style="width: fit-content"
                                                >
                                                    Damit sich der Lehrer auf die Stunde vorbereiten kann, wird der Raum im Vorfeld für einen zugänglich gemacht. Während der Unterrichtsstunde ist der Raum für den Lehrer und die Schüler geöffnet. Nach der regulären Unterrichtszeit bleibt der Raum noch eine Weile geöffnet - etwa 1,5 Stunden. Auf diese Weise versichern wir, dass die Klasse im Falle einer Überziehung keine neue Sitzung beginnen muss.
                                                </p>
                                            </v-expansion-panel-text>
                                        </v-expansion-panel>
                                        <v-expansion-panel class="panelQuestion">
                                            <v-expansion-panel-title
                                                color="#707070"
                                                class="panelQuestionHeader"
                                            >
                                                Beim Eintreten in den virtuellen Klassenraum hat man das Mikrofon oder die Kamera nicht zugelassen. Wie kann man diese freischalten?
                                            </v-expansion-panel-title>
                                            <v-expansion-panel-text class="panelQuestionContent">
                                                <p
                                                    tabindex="0"
                                                    class="mb-0"
                                                    style="width: fit-content"
                                                >
                                                    Sie haben Ihre Auswahl für diese Sitzung bereits getroffen. Sie können jetzt keine Änderungen im virtuellen Klassenzimmer vornehmen. Verlassen Sie die Sitzung. Bitte betreten Sie die Klasse erneut über die grüne Videotaste. Nun können Sie Ihre Auswahl von Anfang an treffen.
                                                </p>
                                            </v-expansion-panel-text>
                                        </v-expansion-panel>
                                        <v-expansion-panel class="panelQuestion">
                                            <v-expansion-panel-title
                                                color="#707070"
                                                class="panelQuestionHeader"
                                            >
                                                Warum ist die Videoübertragung langsam und nicht flüssig?
                                            </v-expansion-panel-title>
                                            <v-expansion-panel-text class="panelQuestionContent">
                                                <p
                                                    tabindex="0"
                                                    class="mb-0"
                                                    style="width: fit-content"
                                                >
                                                    Die Hintergründe können vielfältig sein. Eine Ursache könnte sein, dass die Internetverbindung zu schwach ist. Ein anderer Grund ist möglicherweise, dass zu viele Schüler ihr Video eingeschaltet haben. Bitten Sie Ihre Schüler, ihre Videos auszuschalten. Auf diese Weise können Sie ihre Verbindung entlasten.
                                                </p>
                                            </v-expansion-panel-text>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-expansion-panel-text>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-expansion-panel-text>
                </v-expansion-panel>

                <!-- Schüler -->
                <v-expansion-panel
                    v-if="accountRole === 'pupil'"
                    class="panel"
                >
                    <v-expansion-panel-title
                        color="#fabb00"
                        class="panelHeader"
                    >
                        2. Schüler
                    </v-expansion-panel-title>
                    <v-expansion-panel-text class="panelContent">
                        <v-expansion-panels
                            flat
                            dark
                            @update:model-value="allPanels = !allPanels"
                        >
                            <v-expansion-panel class="panel">
                                <v-expansion-panel-title
                                    color="#fac837"
                                    class="panelHeader"
                                >
                                    2.1. Schüler Modus einfach erklärt
                                </v-expansion-panel-title>
                                <v-expansion-panel-text class="panelContent">
                                    <v-expansion-panels
                                        flat
                                        @update:model-value="allPanels = !allPanels"
                                    >
                                        <v-expansion-panel
                                            v-for="entry in schuelerEinfachFAQ"
                                            :key="entry.index"
                                            class="panelQuestion"
                                        >
                                            <v-expansion-panel-title
                                                color="#707070"
                                                class="panelQuestionHeader"
                                            >
                                                {{ entry.title }}
                                            </v-expansion-panel-title>
                                            <!-- Disable v-html xss checking entry.content is provided only by developers -->
                                            <!-- eslint-disable vue/no-v-html -->
                                            <v-expansion-panel-text
                                                class="panelQuestionContent"
                                            >
                                                <div v-html="entry.content" />
                                            </v-expansion-panel-text>
                                            <!-- eslint-enable vue/no-v-html -->
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-expansion-panel-text>
                            </v-expansion-panel>

                            <v-expansion-panel class="panel">
                                <v-expansion-panel-title
                                    color="#fac837"
                                    class="panelHeader"
                                >
                                    2.2. Schüler Modus normal erklärt
                                </v-expansion-panel-title>
                                <v-expansion-panel-text class="panelContent">
                                    <v-expansion-panels
                                        flat
                                        @update:model-value="allPanels = !allPanels"
                                    >
                                        <v-expansion-panel
                                            v-for="entry in schuelerFAQ"
                                            :key="entry.index"
                                            class="panelQuestion"
                                        >
                                            <v-expansion-panel-title
                                                color="#707070"
                                                class="panelQuestionHeader"
                                            >
                                                {{ entry.title }}
                                            </v-expansion-panel-title>
                                            <!-- Disable v-html xss checking entry.content is provided only by developers -->
                                            <!-- eslint-disable vue/no-v-html -->
                                            <v-expansion-panel-text
                                                class="panelQuestionContent"
                                            >
                                                <div v-html="entry.content" />
                                            </v-expansion-panel-text>
                                            <!-- eslint-enable vue/no-v-html -->
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-expansion-panel-text>
                            </v-expansion-panel>

                            <v-expansion-panel class="panel">
                                <v-expansion-panel-title
                                    color="#fac837"
                                    class="panelHeader"
                                >
                                    2.3. Tipps für Schüler
                                </v-expansion-panel-title>
                                <v-expansion-panel-text class="panelContent">
                                    <v-expansion-panels
                                        flat
                                        @update:model-value="allPanels = !allPanels"
                                    >
                                        <v-expansion-panel
                                            v-for="entry in schuelerTippsFAQ"
                                            :key="entry.index"
                                            class="panelQuestion"
                                        >
                                            <v-expansion-panel-title
                                                color="#707070"
                                                class="panelQuestionHeader"
                                            >
                                                {{ entry.title }}
                                            </v-expansion-panel-title>
                                            <!-- Disable v-html xss checking entry.content is provided only by developers -->
                                            <!-- eslint-disable vue/no-v-html -->
                                            <v-expansion-panel-text
                                                class="panelQuestionContent"
                                            >
                                                <div v-html="entry.content" />
                                            </v-expansion-panel-text>
                                            <!-- eslint-enable vue/no-v-html -->
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-expansion-panel-text>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-expansion-panel-text>
                </v-expansion-panel>

                <!-- Lehrer -->
                <v-expansion-panel
                    v-if="accountRole === 'teacher'"
                    class="panel"
                >
                    <v-expansion-panel-title
                        color="#f07d32"
                        class="panelHeader"
                    >
                        2. Lehrer
                    </v-expansion-panel-title>
                    <v-expansion-panel-text class="panelContent">
                        <v-expansion-panels
                            flat
                            dark
                            @update:model-value="allPanels = !allPanels"
                        >
                            <v-expansion-panel class="panel">
                                <v-expansion-panel-title
                                    color="#f5a032"
                                    class="panelHeader"
                                >
                                    2.1. Lehrer vorgestellt
                                </v-expansion-panel-title>
                                <v-expansion-panel-text class="panelContent">
                                    <v-expansion-panels
                                        flat
                                        @update:model-value="allPanels = !allPanels"
                                    >
                                        <v-expansion-panel
                                            v-for="entry in lehrerFAQ"
                                            :key="entry.index"
                                            class="panelQuestion"
                                        >
                                            <v-expansion-panel-title
                                                color="#707070"
                                                class="panelQuestionHeader"
                                            >
                                                {{ entry.title }}
                                            </v-expansion-panel-title>
                                            <!-- Disable v-html xss checking entry.content is provided only by developers -->
                                            <!-- eslint-disable vue/no-v-html -->
                                            <v-expansion-panel-text
                                                class="panelQuestionContent"
                                            >
                                                <div v-html="entry.content" />
                                            </v-expansion-panel-text>
                                            <!-- eslint-enable vue/no-v-html -->
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-expansion-panel-text>
                            </v-expansion-panel>

                            <v-expansion-panel class="panel">
                                <v-expansion-panel-title
                                    color="#f5a032"
                                    class="panelHeader"
                                >
                                    2.2. Lehrer Fragen
                                </v-expansion-panel-title>
                                <v-expansion-panel-text class="panelContent">
                                    <v-expansion-panels
                                        flat
                                        @update:model-value="allPanels = !allPanels"
                                    >
                                        <v-expansion-panel class="panelQuestion">
                                            <v-expansion-panel-title
                                                color="#707070"
                                                class="panelQuestionHeader"
                                            >
                                                Ich habe eine Doppelstunde. In der zweiten Einheit sind nicht alle Kinder präsent. Woran liegt das?
                                            </v-expansion-panel-title>
                                            <v-expansion-panel-text class="panelQuestionContent">
                                                <p
                                                    tabindex="0"
                                                    class="mb-0"
                                                    style="width: fit-content"
                                                >
                                                    Es kann sein, dass manche Kinder sich nicht aus der letzten Einheit ausgecheckt haben. Sie können die aktuelle Klasse verlassen und in die vorherige eintreten. Dort weisen Sie die Kinder darauf hin, dass sie den Raum bitte verlassen und in die neue Stunde eintreten sollen. Alternativ können Sie die „Konferenz beenden“ für alle übrig gebliebenen Schüler beenden. Diese Funktion finden Sie oben rechts bei den „Optionen“ (drei vertikale Punkte) wieder.
                                                </p>
                                            </v-expansion-panel-text>
                                        </v-expansion-panel>
                                        <v-expansion-panel class="panelQuestion">
                                            <v-expansion-panel-title
                                                color="#707070"
                                                class="panelQuestionHeader"
                                            >
                                                Können die Lehrkräfte selbst entscheiden ob Kinder mit Video und Mikrofon sich anmelden können oder nicht?
                                            </v-expansion-panel-title>
                                            <v-expansion-panel-text class="panelQuestionContent">
                                                <p
                                                    tabindex="0"
                                                    class="mb-0"
                                                    style="width: fit-content"
                                                >
                                                    Nein diese Wahl dürfen Lehrkräfte aus rechtlichen Gründen nicht für die Kinder vortreffen. Jedes Kind und jeder Teilnehmer muss für sich selbst entscheiden weder sie mit angemachtem Mikrofon und / oder Video teilnehmen möchten oder nicht.
                                                </p>
                                            </v-expansion-panel-text>
                                        </v-expansion-panel>
                                        <v-expansion-panel class="panelQuestion">
                                            <v-expansion-panel-title
                                                color="#707070"
                                                class="panelQuestionHeader"
                                            >
                                                Kann man die Klassenliste während des digitalen Unterrichts kontrollieren?
                                            </v-expansion-panel-title>
                                            <v-expansion-panel-text class="panelQuestionContent">
                                                <p
                                                    tabindex="0"
                                                    class="mb-0"
                                                    style="width: fit-content"
                                                >
                                                    Ja, im virtuellen Klassenzimmer können Sie im Chatbereich (beim " Männchen ") sehen, wer derzeit an dem Unterricht teilnimmt. Sie können diese "Klassenliste" anschließend herunterladen und abhaken.
                                                </p>
                                            </v-expansion-panel-text>
                                        </v-expansion-panel>
                                        <v-expansion-panel class="panelQuestion">
                                            <v-expansion-panel-title
                                                color="#707070"
                                                class="panelQuestionHeader"
                                            >
                                                Wie viel Kontrolle hat eine Lehrkraft im virtuellen Klassenraum?
                                            </v-expansion-panel-title>
                                            <v-expansion-panel-text class="panelQuestionContent">
                                                <p
                                                    tabindex="0"
                                                    class="mb-0"
                                                    style="width: fit-content"
                                                >
                                                    Die Lehrkraft ist die einzige Person im virtuellen Klassenzimmer, die Unterrichtsteilnehmer individuell freischalten, stummschalten und aufrufen kann. So kann z. B. im Unterricht zunächst die gesamte Klasse freigeschaltet werden, wodurch alle mit der Lehrkraft und untereinander interagieren können. Sollte die Klasse "aus dem Ruder laufen", können alle Schüler durch die Lehrkraft stummgeschaltet werden. Anschließend können die Schüler einzeln aufgerufen werden, indem sie nacheinander zum "Präsentator" gemacht werden. Außerdem kann eine Lehrkraft, wenn sie ihren Unterricht beendet, die Sitzung komplett schließen. Durch diese Aktion werden alle Teilnehmer automatisch das virtuelle Klassenzimmer verlassen und sich im Menü wiederfinden.
                                                </p>
                                            </v-expansion-panel-text>
                                        </v-expansion-panel>
                                        <v-expansion-panel class="panelQuestion">
                                            <v-expansion-panel-title
                                                color="#707070"
                                                class="panelQuestionHeader"
                                            >
                                                Kann ich während des virtuellen Unterrichts die Schüler in Gruppen einteilen?
                                            </v-expansion-panel-title>
                                            <v-expansion-panel-text class="panelQuestionContent">
                                                <p
                                                    tabindex="0"
                                                    class="mb-0"
                                                    style="width: fit-content"
                                                >
                                                    Ja, der Lehrer kann in der Unterrichtsstunde Gruppen bilden.
                                                </p>
                                            </v-expansion-panel-text>
                                        </v-expansion-panel>
                                        <v-expansion-panel class="panelQuestion">
                                            <v-expansion-panel-title
                                                color="#707070"
                                                class="panelQuestionHeader"
                                            >
                                                Können diese Gruppen für eine längere Zeit festgelegt werden – z.B. für einen gesamten Monat?
                                            </v-expansion-panel-title>
                                            <v-expansion-panel-text class="panelQuestionContent">
                                                <p
                                                    tabindex="0"
                                                    class="mb-0"
                                                    style="width: fit-content"
                                                >
                                                    Es besteht keine Funktion zur permanenten Einteilung der Unterrichtsteilnehmer.
                                                </p>
                                            </v-expansion-panel-text>
                                        </v-expansion-panel>
                                        <v-expansion-panel class="panelQuestion">
                                            <v-expansion-panel-title
                                                color="#707070"
                                                class="panelQuestionHeader"
                                            >
                                                Ist es möglich, den Bildschirm zu teilen, ohne dass die Schüler den gesamten Bildschirm des Präsentators sehen?
                                            </v-expansion-panel-title>
                                            <v-expansion-panel-text class="panelQuestionContent">
                                                <p
                                                    tabindex="0"
                                                    class="mb-0"
                                                    style="width: fit-content"
                                                >
                                                    Teilen Sie Ihren Bildschirm über den Button „Bildschirm teilen“, wird Ihnen eine Auswahl angeboten. Sie können wählen ob der gesamte Bildschirm oder nur ein bestimmtes Anwendungsfenster, oder ein Tab aus dem Internet geteilt werden soll.
                                                </p>
                                            </v-expansion-panel-text>
                                        </v-expansion-panel>
                                        <v-expansion-panel class="panelQuestion">
                                            <v-expansion-panel-title
                                                color="#707070"
                                                class="panelQuestionHeader"
                                            >
                                                Warum können die Schüler nicht eigenständig in den öffentlichen Chat einschreiben?
                                            </v-expansion-panel-title>
                                            <v-expansion-panel-text class="panelQuestionContent">
                                                <p
                                                    tabindex="0"
                                                    class="mb-0"
                                                    style="width: fit-content"
                                                >
                                                    Der öffentliche Chat ist am Anfang jeder Unterrichtseinheit gesperrt. Solange die Lehrkraft den öffentlichen Chat nicht freigibt, können die Schüler diesen nicht benutzen. Macht sie diesen zugänglich, kann sie mit den Kindern darin kommunizieren. Der Zugriff auf den öffentlichen Chat kann jederzeit wieder gesperrt werden, z.B. wenn dieser nicht mehr gebraucht wird. Auf diese Weise kann man verhindern, dass Schüler in die Versuchung kommen, den öffentlichen Chat zweckwidrig zu nutzen.
                                                </p>
                                            </v-expansion-panel-text>
                                        </v-expansion-panel>
                                        <v-expansion-panel class="panelQuestion">
                                            <v-expansion-panel-title
                                                color="#707070"
                                                class="panelQuestionHeader"
                                            >
                                                Können Schüler während des Unterrichts im digitalen Klassenzimmer privat miteinander kommunizieren?
                                            </v-expansion-panel-title>
                                            <v-expansion-panel-text class="panelQuestionContent">
                                                <p
                                                    tabindex="0"
                                                    class="mb-0"
                                                    style="width: fit-content"
                                                >
                                                    Die Schüler können keine privaten Gespräche miteinander führen. Diese Funktion gibt es für die Schüler nicht. Ein Dialog ist nur mit dem Lehrer möglich, wenn er die Möglichkeit gibt, mit ihm zu interagieren.
                                                </p>
                                            </v-expansion-panel-text>
                                        </v-expansion-panel>
                                        <v-expansion-panel class="panelQuestion">
                                            <v-expansion-panel-title
                                                color="#707070"
                                                class="panelQuestionHeader"
                                            >
                                                Wie kann ein Arbeitsblatt im Unterricht live geteilt werden?
                                            </v-expansion-panel-title>
                                            <v-expansion-panel-text class="panelQuestionContent">
                                                <p
                                                    tabindex="0"
                                                    class="mb-0"
                                                    style="width: fit-content"
                                                >
                                                    Dateien können über "Präsentation hochladen" mit der Klasse geteilt werden. Wenn Sie die Dateien zuvor auf dem Gerät hochgeladen und vorbereitet haben, können Sie diese über "Dateien hochladen" auswählen und der Klasse zur Verfügung stellen. Außerdem können Sie an dieser Stelle das Arbeitsblatt abfotografieren, so dass es direkt im Programm konvertiert und hochgeladen wird, damit der Lehrer es teilen kann. Auf diese Weise können Arbeitsblätter freigegeben und von der Klasse gemeinsam bearbeitet werden.
                                                </p>
                                            </v-expansion-panel-text>
                                        </v-expansion-panel>
                                        <v-expansion-panel class="panelQuestion">
                                            <v-expansion-panel-title
                                                color="#707070"
                                                class="panelQuestionHeader"
                                            >
                                                Zusätzlicher Hinweis!
                                            </v-expansion-panel-title>
                                            <v-expansion-panel-text class="panelQuestionContent">
                                                <p
                                                    tabindex="0"
                                                    class="mb-0"
                                                    style="width: fit-content"
                                                >
                                                    Beachten Sie, dass bei Freigabe der Bearbeitung des Arbeitsblattes die gesamte Klasse freigeschaltet wird. Um "Chaos" zu vermeiden, rufen Sie die Schüler wie gewohnt einzeln auf! Während ein Kind das Arbeitsblatt an der virtuellen Tafel bearbeitet, sehen Sie dessen Namen und können so die Leistung mitverfolgen.
                                                </p>
                                            </v-expansion-panel-text>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-expansion-panel-text>
                            </v-expansion-panel>

                            <v-expansion-panel class="panel">
                                <v-expansion-panel-title
                                    color="#f5a032"
                                    class="panelHeader"
                                >
                                    2.3. Lehrer Tipps
                                </v-expansion-panel-title>
                                <v-expansion-panel-text class="panelContent">
                                    <v-expansion-panels
                                        flat
                                        @update:model-value="allPanels = !allPanels"
                                    >
                                        <v-expansion-panel
                                            v-for="entry in lehrerTippsFAQ"
                                            :key="entry.index"
                                            class="panelQuestion"
                                        >
                                            <v-expansion-panel-title
                                                color="#707070"
                                                class="panelQuestionHeader"
                                            >
                                                {{ entry.title }}
                                            </v-expansion-panel-title>
                                            <!-- diable no-html rule, because content is developer provided -->
                                            <!-- eslint-disable vue/no-v-html -->
                                            <v-expansion-panel-text
                                                class="panelQuestionContent"
                                            >
                                                <div v-html="entry.content" />
                                            </v-expansion-panel-text>
                                            <!-- eslint-enable vue/no-v-html -->
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-expansion-panel-text>
                            </v-expansion-panel>

                            <v-expansion-panel class="panel">
                                <v-expansion-panel-title
                                    color="#f5a032"
                                    class="panelHeader"
                                >
                                    2.4. Lernmaterial
                                </v-expansion-panel-title>
                                <v-expansion-panel-text class="panelContent">
                                    <v-expansion-panels
                                        flat
                                        @update:model-value="allPanels = !allPanels"
                                    >
                                        <v-expansion-panel class="panelQuestion">
                                            <v-expansion-panel-title
                                                color="#707070"
                                                class="panelQuestionHeader"
                                            >
                                                Schatzsuche
                                            </v-expansion-panel-title>
                                            <v-expansion-panel-text
                                                class="panelQuestionContent pr-6"
                                            >
                                                <p
                                                    tabindex="0"
                                                    class="mb-0"
                                                    style="width: fit-content"
                                                >
                                                    Mit der Schatzsuche können Sie die Schüler auf eine selbständige Nutzung von eKlara vorbereiten.
                                                    <br>Drucken Sie dafür die PDF aus und platzieren Sie nur die durchnummerierten Elemente (1. - 4.), wie auf Seite 1 zu sehen, auf der Tafel. Jetzt können Sie den Schülern mithilfe der restlichen Elemente in der Mitte der Tafel Abläufe, Zusammenhänge und den täglichen Gebrauch von eKlara spielerisch beibringen.
                                                </p>
                                                <object
                                                    width="100%"
                                                    height="400px"
                                                    type="application/pdf"
                                                    :data="schatzsuchePdf"
                                                    class="pt-3"
                                                />
                                                <v-btn
                                                    color="#323639"
                                                    style="width: 100%; margin-top: -8px"
                                                    size="large"
                                                    :href="schatzsuchePdf"
                                                    download
                                                >
                                                    Herunterladen
                                                </v-btn>
                                            </v-expansion-panel-text>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-expansion-panel-text>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-expansion-panel-text>
                </v-expansion-panel>

                <!-- Verwaltung -->
                <v-expansion-panel
                    v-if="accountRole === 'maintainer'"
                    class="panel"
                >
                    <v-expansion-panel-title
                        color="#3caa69"
                        class="panelHeader"
                    >
                        2. Verwaltung
                    </v-expansion-panel-title>
                    <v-expansion-panel-text class="panelContent">
                        <v-expansion-panels
                            flat
                            dark
                            @update:model-value="allPanels = !allPanels"
                        >
                            <v-expansion-panel class="panel">
                                <v-expansion-panel-title
                                    color="rgb(var(--v-theme-chatColor))"
                                    class="panelHeader"
                                >
                                    2.1. Verwaltung vorgestellt
                                </v-expansion-panel-title>
                                <v-expansion-panel-text class="panelContent">
                                    <v-expansion-panels
                                        flat
                                        @update:model-value="allPanels = !allPanels"
                                    >
                                        <v-expansion-panel
                                            v-for="entry in verwaltungFAQ"
                                            :key="entry.index"
                                            class="panelQuestion"
                                        >
                                            <v-expansion-panel-title
                                                color="#707070"
                                                class="panelQuestionHeader"
                                            >
                                                {{ entry.title }}
                                            </v-expansion-panel-title>
                                            <!-- diable no-html rule, because content is developer provided -->
                                            <!-- eslint-disable vue/no-v-html -->
                                            <v-expansion-panel-text
                                                class="panelQuestionContent"
                                            >
                                                <div v-html="entry.content" />
                                            </v-expansion-panel-text>
                                            <!-- eslint-enable vue/no-v-html -->
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-expansion-panel-text>
                            </v-expansion-panel>

                            <v-expansion-panel class="panel">
                                <v-expansion-panel-title
                                    color="rgb(var(--v-theme-chatColor))"
                                    class="panelHeader"
                                >
                                    2.2. Verwaltungsfragen
                                </v-expansion-panel-title>
                                <v-expansion-panel-text class="panelContent">
                                    <v-expansion-panels
                                        flat
                                        @update:model-value="allPanels = !allPanels"
                                    >
                                        <v-expansion-panel class="panelQuestion">
                                            <v-expansion-panel-title
                                                color="#707070"
                                                class="panelQuestionHeader"
                                            >
                                                Können die Fächer in der Stundenplanübersicht verschoben werden?
                                            </v-expansion-panel-title>
                                            <v-expansion-panel-text class="panelQuestionContent">
                                                <p
                                                    tabindex="0"
                                                    class="mb-0"
                                                    style="width: fit-content"
                                                >
                                                    Die vorhandenen Lerneinheiten können entweder ersetzt oder gelöscht werden. Aus diesem Grund braucht man eine Lerneinheit nicht zu verschieben. Man muss auf das Fach und weiter auf das Fachfenster klicken. Dort ersetzt man einfach ein bestehendes Unterrichtsfach durch ein neues. Fertig!
                                                    <br>
                                                    <br>Alternativ könnte man jede eingetragene Unterrichtseinheit einzeln anklicken und über das Feld "Löschen" entfernen. Es wird gefragt, ob nur dieser Termin (von dieser Woche) oder alle Termine (ganzes Schuljahr) gelöscht werden sollen. Sobald alle Unterrichtszeiten frei wurden, können die Fächer wieder neu angeordnet werden.
                                                </p>
                                            </v-expansion-panel-text>
                                        </v-expansion-panel>
                                        <v-expansion-panel class="panelQuestion">
                                            <v-expansion-panel-title
                                                color="#707070"
                                                class="panelQuestionHeader"
                                            >
                                                Wie kann die Verwaltung klassenübergreifende Unterrichtseinheiten erstellen?
                                            </v-expansion-panel-title>
                                            <v-expansion-panel-text class="panelQuestionContent">
                                                <p
                                                    tabindex="0"
                                                    class="mb-0"
                                                    style="width: fit-content"
                                                >
                                                    Wenn eine Unterrichtseinheit für Schüler aus verschiedenen Klassen erstellen werden soll, muss eine neue "Gruppe" aus betroffenen Schülern erstellt werden. Zu dieser Schülergruppe fügen Sie einen Gruppenleiter (Lehrer) hinzu. Der Leiter dieser Gruppe kann ein Lehrer sein, der bereits registriert wurde. Bitte überprüfen Sie, dass dieser Lehrer im Bereich "Fächer" als "Fachlehrer" für die klassenübergreifende Unterrichtseinheit hinzugefügt wurde.
                                                    <br>
                                                    <br>Auf die gleiche Weise können einmalige Unterrichtsstunden oder außerschulische Veranstaltungen eingeplant werden, die mehrere Klassen oder die gesamte Schulgemeinschaft betreffen sollen. Beispielsweise soll einen Gastredner oder Gastlehrer, eine Veranstaltung durchführen. Die Verwaltung legt vorübergehend ein Lehrerkonto für den Gast an. Danach ordnet sie ihm die Schülergruppe und das Fach ("die Veranstaltung") zu. Höchstwahrscheinlich muss dafür ein neues Fach angelegt und dem Namen der Veranstaltung entsprechend benannt werden.
                                                    <br>
                                                    <br>In beiden Fällen kann die neue klassenübergreifende Gruppe in der Stundenplanübersicht über das Suchfeld (anklickbar neben der Lupe) ausgewählt werden. Über das Feld „Hinzufügen“ können Fächer (Veranstaltungen) hinzugefügt werden. Dafür klickt man auf den Bereich "Fach" und wählt die neu angelegte außerordentliche Veranstaltung aus. Im Bereich "Lehrer" sollte man den gewünschten Lehrer (oder Gastlehrer) auswählen. Am Ende ist es wichtig auf "Speichern" zu klicken, damit die Veranstaltung veröffentlicht wird. Der betroffene Lehrer / Gastlehrer und die Schüler können nun die neue Unterrichtsstunde / Veranstaltung in ihrem Stundenplan sehen und besuchen.
                                                </p>
                                            </v-expansion-panel-text>
                                        </v-expansion-panel>
                                        <v-expansion-panel class="panelQuestion">
                                            <v-expansion-panel-title
                                                color="#707070"
                                                class="panelQuestionHeader"
                                            >
                                                Kann ich während des virtuellen Unterrichts die Schüler in Gruppen einteilen?
                                            </v-expansion-panel-title>
                                            <v-expansion-panel-text class="panelQuestionContent">
                                                <p
                                                    tabindex="0"
                                                    class="mb-0"
                                                    style="width: fit-content"
                                                >
                                                    Es bietet die Möglichkeit, die Passworteingabe zu visualisieren und zu kontrollieren.
                                                </p>
                                            </v-expansion-panel-text>
                                        </v-expansion-panel>
                                        <v-expansion-panel class="panelQuestion">
                                            <v-expansion-panel-title
                                                color="#707070"
                                                class="panelQuestionHeader"
                                            >
                                                Wo kann ich sehen, welche verschiedenen Profile erstellt werden können?
                                            </v-expansion-panel-title>
                                            <v-expansion-panel-text class="panelQuestionContent">
                                                <p
                                                    tabindex="0"
                                                    class="mb-0"
                                                    style="width: fit-content"
                                                >
                                                    In der Symbolleiste auf der linken Seite können die einzelnen Profile an den unterschiedlichen Symbolen erkannt werden.
                                                </p>
                                            </v-expansion-panel-text>
                                        </v-expansion-panel>
                                        <v-expansion-panel class="panelQuestion">
                                            <v-expansion-panel-title
                                                color="#707070"
                                                class="panelQuestionHeader"
                                            >
                                                Warum druckt man drei Anmeldekarten für einen Schüler aus? Was ist der Zweck davon? An wen sollen die Ausweise ausgegeben werden?
                                            </v-expansion-panel-title>
                                            <v-expansion-panel-text class="panelQuestionContent">
                                                <p
                                                    tabindex="0"
                                                    class="mb-0"
                                                    style="width: fit-content"
                                                >
                                                    Alle Anmeldekarten sollten an den jeweiligen Schüler oder seine Eltern ausgehändigt werden. Während eine Karte stationär zu Hause am Lerntisch verbleiben kann und die andere von den Eltern aufbewahrt wird, kann ein Schüler die dritte Karte stets mit in die Schule nehmen, um an einem Hybridunterricht teilnehmen zu können.
                                                </p>
                                            </v-expansion-panel-text>
                                        </v-expansion-panel>
                                        <v-expansion-panel class="panelQuestion">
                                            <v-expansion-panel-title
                                                color="#707070"
                                                class="panelQuestionHeader"
                                            >
                                                Wie geht man vor, wenn ein Schüler meldet, dass er sein Ausweis und die Anmeldedaten verloren hat?
                                            </v-expansion-panel-title>
                                            <v-expansion-panel-text class="panelQuestionContent">
                                                <p
                                                    tabindex="0"
                                                    class="mb-0"
                                                    style="width: fit-content"
                                                >
                                                    Schülerprofil sperren bzw. Profil ändern → Neues PW und Kuh-R generieren
                                                    → Anschließend PDF speichern → Ausdrucken → Versenden / Neu aushändigen.
                                                </p>
                                            </v-expansion-panel-text>
                                        </v-expansion-panel>
                                        <v-expansion-panel class="panelQuestion">
                                            <v-expansion-panel-title
                                                color="#707070"
                                                class="panelQuestionHeader"
                                            >
                                                Was ist zu tun, wenn man einen Namen falsch eingegeben hat und das Profil trotzdem angelegt wurde?
                                            </v-expansion-panel-title>
                                            <v-expansion-panel-text class="panelQuestionContent">
                                                <p
                                                    tabindex="0"
                                                    class="mb-0"
                                                    style="width: fit-content"
                                                >
                                                    Klicken Sie auf Profil bearbeiten. → Ändern Sie den Namen. → Legen Sie ein neues Passwort an. → Das neue PDF-Dokument des Profils muss nun gespeichert werden. → Drucken → Dem Schüler das Profil und sein KUH-R verschicken bzw. geben.
                                                </p>
                                            </v-expansion-panel-text>
                                        </v-expansion-panel>
                                        <v-expansion-panel class="panelQuestion">
                                            <v-expansion-panel-title
                                                color="#707070"
                                                class="panelQuestionHeader"
                                            >
                                                Warum kann man im Stundenplan weder manuell noch über eine Liste einen Klassenlehrer zuordnen?
                                            </v-expansion-panel-title>
                                            <v-expansion-panel-text class="panelQuestionContent">
                                                <p
                                                    tabindex="0"
                                                    class="mb-0"
                                                    style="width: fit-content"
                                                >
                                                    Der Klassenlehrer wird einer Gruppe bzw. einer Klasse zugeteilt, wenn man eine solche anlegt. Das bedeutet aber nicht, dass das System diesen Lehrer auch als Fachlehrer für alle Unterrichtseinheiten der Klasse übernimmt. Da ein Klassenlehrer auch ein Fachlehrer ist, sollte er als solcher registriert werden. Zu diesem Zweck ordnen man die Lehrkraft im Bereich "Fächer" den für ihn oder sie relevanten Fächern zu. Anschließend können im Stundenplan die Unterrichtseinheiten für die Klasse eingetragen werden. In allen Fächern, in denen der Klassenlehrer als Fachlehrer ausgewählt wird, betreut dieser seine Klasse.
                                                </p>
                                            </v-expansion-panel-text>
                                        </v-expansion-panel>
                                        <v-expansion-panel class="panelQuestion">
                                            <v-expansion-panel-title
                                                color="#707070"
                                                class="panelQuestionHeader"
                                            >
                                                Ist es möglich, für jede einzelne Klasse einen eigenen Wochenplan zu erstellen?
                                            </v-expansion-panel-title>
                                            <v-expansion-panel-text class="panelQuestionContent">
                                                <p
                                                    tabindex="0"
                                                    class="mb-0"
                                                    style="width: fit-content"
                                                >
                                                    Jede bereits angelegte Klasse (Gruppe) kann in der der Stundenplanübersicht über das Suchfeld (anklickbar neben der Lupe) ausgewählt werden. Dann können die Unterrichtseinheiten in den Stundenplan der jeweiligen Klasse hinzugefügt werden. Neben dem Fach wählt man auch den betreuenden Lehrer.
                                                    <br>
                                                    <br>
                                                    <b>Tipp:</b> Es ist sinnvoll die Wiederholung der Unterrichtsstunden auf „Wöchentlich bis" zum Ende des Schuljahres einzustellen und damit zu begrenzen. So können ungeplante Überschneidungen im folgenden Schuljahr vermieden werden.
                                                </p>
                                            </v-expansion-panel-text>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-expansion-panel-text>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-expansion-panel-text>
                </v-expansion-panel>

                <!-- Big Blue Button -->
                <v-expansion-panel class="panel">
                    <v-expansion-panel-title
                        color="#113256"
                        class="panelHeader"
                    >
                        3. Hilfe für BigBlueButton
                    </v-expansion-panel-title>
                    <v-expansion-panel-text class="panelContent">
                        <v-expansion-panels
                            flat
                            dark
                            @update:model-value="allPanels = !allPanels"
                        >
                            <v-expansion-panel class="panel">
                                <v-expansion-panel-title
                                    color="#255c98"
                                    class="panelHeader"
                                >
                                    3.1. Anforderungen an den Nutzer
                                </v-expansion-panel-title>
                                <v-expansion-panel-text class="panelContent">
                                    <v-expansion-panels
                                        flat
                                        @update:model-value="allPanels = !allPanels"
                                    >
                                        <v-expansion-panel
                                            v-for="entry in bbbAnforderungenFAQ"
                                            :key="entry.index"
                                            class="panelQuestion"
                                        >
                                            <v-expansion-panel-title
                                                color="#707070"
                                                class="panelQuestionHeader"
                                            >
                                                {{ entry.title }}
                                            </v-expansion-panel-title>
                                            <v-expansion-panel-text class="panelQuestionContent">
                                                <!-- Disable v-html xss checking entry.content is provided only by developers -->
                                                <!-- eslint-disable vue/no-v-html -->
                                                <p v-html="entry.content" />
                                                <!-- eslint-enable vue/no-v-html -->
                                            </v-expansion-panel-text>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-expansion-panel-text>
                            </v-expansion-panel>

                            <v-expansion-panel class="panel">
                                <v-expansion-panel-title
                                    color="#255c98"
                                    class="panelHeader"
                                >
                                    3.2. Probleme mit BBB beheben
                                </v-expansion-panel-title>
                                <v-expansion-panel-text class="panelContent">
                                    <v-expansion-panels
                                        flat
                                        @update:model-value="allPanels = !allPanels"
                                    >
                                        <v-expansion-panel
                                            v-for="entry in bbbProblemeFAQ"
                                            :key="entry.index"
                                            class="panelQuestion"
                                        >
                                            <v-expansion-panel-title
                                                color="#707070"
                                                class="panelQuestionHeader"
                                            >
                                                {{ entry.title }}
                                            </v-expansion-panel-title>
                                            <v-expansion-panel-text class="panelQuestionContent">
                                                <!-- Disable v-html xss checking entry.content is provided only by developers -->
                                                <!-- eslint-disable vue/no-v-html -->
                                                <p v-html="entry.content" />
                                                <!-- eslint-enable vue/no-v-html -->
                                            </v-expansion-panel-text>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-expansion-panel-text>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-expansion-panel-text>
                </v-expansion-panel>

                <!-- Neues -->
                <v-expansion-panel class="panel">
                    <!-- Vorübergehende Badge -->
                    <!--                        <v-badge overlap content="Neues" left offset-x="20" offset-y="10" style="width: 100%">-->
                    <v-expansion-panel-title
                        color="#FF5757"
                        class="panelHeader"
                    >
                        4. Was gibt es Neues?
                    </v-expansion-panel-title>
                    <!--                        </v-badge>-->
                    <v-expansion-panel-text class="panelContent">
                        <v-expansion-panels
                            flat
                            dark
                            @update:model-value="allPanels = !allPanels"
                        >
                            <v-expansion-panels
                                flat
                                @update:model-value="allPanels = !allPanels"
                            >
                                <v-expansion-panel
                                    v-for="entry in neuesFAQ"
                                    :key="entry.index"
                                    class="panelQuestion"
                                >
                                    <v-expansion-panel-title
                                        color="#707070"
                                        class="panelQuestionHeader"
                                    >
                                        {{ entry.title }}
                                    </v-expansion-panel-title>
                                    <v-expansion-panel-text class="panelQuestionContent">
                                        <!-- Disable v-html xss checking entry.content is provided only by developers -->
                                        <!-- eslint-disable vue/no-v-html -->
                                        <p v-html="entry.content" />
                                        <!-- eslint-enable vue/no-v-html -->
                                    </v-expansion-panel-text>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-expansion-panels>
                    </v-expansion-panel-text>
                </v-expansion-panel>
            </v-expansion-panels>

            <VideoTutorial
                v-if="videotutorialSelected"
                :video-tutorial="videotutorialSelected"
                :faq="true"
                @close="videotutorialSelected = false"
            />

            <!-- Zurück Button -->
            <v-btn
                v-if="accountRole && accountRole !== 'maintainer'"
                class="backButton text-none"
                color="#eaeaea"
                elevation="0"
                @click="toggleFaq"
            >
                &lt; Zurück zu eKlara
            </v-btn>
            <v-btn
                v-else
                :to="{ path: '/verwaltung/plan' }"
                exact
                class="backButton text-none"
                color="#eaeaea"
                elevation="0"
            >
                &lt; Zurück zu eKlara
            </v-btn>
        </v-col>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
import faqCollection from "../constants/faqCollection";
import VideoTutorial from "@/components/Tutorial/VideoTutorial.vue";
import schatzsuchePdf from "../assets/PDF/Schatzsuche.pdf"

export default {
    name: 'FaqComponent',
    components: {
        VideoTutorial,
    },
    data: () => ({
        schuelerFAQ: [],
        schuelerEinfachFAQ: [],
        schuelerTippsFAQ: [],
        lehrerFAQ: [],
        lehrerTippsFAQ: [],
        verwaltungFAQ: [],
        bbbAnforderungenFAQ: [],
        bbbProblemeFAQ: [],
        neuesFAQ: [],

        videotutorialSelected: false,
        account: null,
        userrole: '',

        allPanels: false,
        schatzsuchePdf,
    }),
    computed: {
        ...mapGetters('videoTutorial', [ 'videoTutorials' ]),
        ...mapGetters('auth', ["accountRole"]),

        videoTutorialsRole() {
            const videoTutorialofMyRole = this.videoTutorials.filter(tutorial => {
                if (this.userrole.babyView) {
                    return tutorial.role === 'baby'
                } else {
                    return tutorial.role === this.accountRole;
                }
            })

            return videoTutorialofMyRole;
        }
    },
    watch: {
        // This is for stopping the FAQ YT Videos when any panel changes
        allPanels() {
            var elements = document.getElementsByClassName("panelQuestionContent");
            elements.forEach((element) => {
                var iframe = element.querySelector('iframe');
                var video = element.querySelector('video');
                if (iframe) {
                    var iframeSrc = iframe.src;
                    iframe.src = iframeSrc;
                }
                if (video) {
                    video.pause();
                }
            })
        }
    },
    created() {
        this.schuelerFAQ = faqCollection.schuelerFAQ;
        this.schuelerEinfachFAQ = faqCollection.schuelerEinfachFAQ;
        this.schuelerTippsFAQ = faqCollection.schuelerTippsFAQ;
        this.lehrerFAQ = faqCollection.lehrerFAQ;
        this.lehrerTippsFAQ = faqCollection.lehrerTippsFAQ;
        this.verwaltungFAQ = faqCollection.verwaltungFAQ;
        this.bbbAnforderungenFAQ = faqCollection.hilfefuerBBBAnforderungenFAQ;
        this.bbbProblemeFAQ = faqCollection.hilfefuerBBBProblemeFAQ;
        this.neuesFAQ = faqCollection.neuesFAQ;
    },
    async mounted() {
        this.account = await this.getCurrentAccount();
        switch (this.account.role) {
            case 'pupil':
                this.userrole = await this.getMePupil();
                break;
            case 'teacher':
                this.userrole = await this.getMeTeacher();
                break;
            case 'parent':
                this.userrole = await this.getMeParent();
                break;
            default:
                break;
        }
    },
    methods: {
        ...mapActions('faq', ['toggleFaq']),
        ...mapActions('pupils', ['getMePupil']),
        ...mapActions('teachers', ['getMeTeacher']),
        ...mapActions('parents', ['getMeParent']),
        ...mapActions("auth", ["getCurrentAccount"]),
    },
}
</script>

<style lang="scss">
.v-expansion-panel-content__wrap {
    padding-right: 0px !important;
}
</style>

<style lang="scss" scoped>
.main {
    width: 100vw;
}
.main[maintainer] {
    background-color: green;
}

.header {
    margin-bottom: 0.5em;
}

.panelContent {
    background-color: transparent;
    color: #737373;
    padding-top: 5px;
    padding-left: 0px;
}

.panelHeader {
    background-color: transparent;
    border-radius: 10px !important;
    font-size: large;
}

.panel {
    margin-top: 10px;
    background-color: transparent !important;
}

.panelQuestionContent {
    padding: 5px 24px 8px 24px;
}

.panelQuestionHeader {
    font-size: large;
    border-radius: 10px !important;
}

.panelQuestion {
    margin-top: 10px;
    background-color: rgb(var(--v-theme-dunkelgrau)) !important;
    border-radius: 10px !important;
}

.backButton {
    margin-top: 10px;
    color: rgb(var(--v-theme-dunkelgrau));
    border-radius: 10px !important;
}
</style>
