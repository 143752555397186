<template>
    <!-- eslint-enable vue/no-v-html -->
    <v-tooltip
        :disabled="!showTooltips"
        location="top"
        style="z-index: 9;"
    >
        <template v-slot:activator="{ props }">
            <v-btn
                class="pa-0 menuBtn chatMessageBtn"
                elevation="2"
                v-bind="props"
            >
                <img
                    :src="papierkorbIcon"
                    class="iconToRed"
                    height="20px"
                    alt="Nachricht löschen"
                    @click="showDeleteDialog = true"
                    @keypress.enter="showDeleteDialog = true"
                >
            </v-btn>
        </template>
        <span>Löschen</span>
        <ChatRoomEventListItemDeleteDialog
            v-if="showDeleteDialog"
            :chat-room-event="chatRoomEvent"
            @close-dialog="showDeleteDialog = false"
        />
    </v-tooltip>
</template>

<script>
import { mapState } from "vuex";
import papierkorbIcon from "../../../../assets/Icons/FaceliftIcons/loeschen_muelleimer.svg";
import ChatRoomEventListItemDeleteDialog from "./ChatRoomEventListItemDeleteDialog";

export default {
  name: "ChatRoomEventListItemDeleteButton",
  components: {
    ChatRoomEventListItemDeleteDialog,
  },
  props: {
    chatRoomEvent: { required: true, type: Object },
    // Is the current account a 'moderator' or a 'user' in this chatroom
  },
  data: () => ({
    showDeleteDialog: false,
    papierkorbIcon,
  }),
  computed: {
    ...mapState("tooltips", ["showTooltips"]),
  },
};
</script>
<style scoped>
.chatMessageBtn{
  min-width: 30px !important;
  max-width: 30px !important; 
  min-height: 30px !important; 
  max-height: 30px !important;
  background-color:white !important;
  z-index: 50 !important;

}
</style>