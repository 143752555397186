<!-- eslint-disable vuetify/no-deprecated-props -->
<template>
    <div>
        <progress-bar
            title="Hochladen"
            :show-progress="showAttachmentUploadProgress"
            :progress="attachmentUploadProgress"
            :abort-progress="fileXmlHttpRequest ? () => { fileXmlHttpRequest.abort() }: () => {}"
            :header-color="'rgb(var(--v-theme-parentLetterPurple))'"
            @update:show-progress="showAttachmentUploadProgress = $event"
            @update:progress="attachmentUploadProgress = $event"
        />

        <NameFile
            v-if="showNameFile"
            :show-dialog="showNameFile"
            :original-name="originalFileName"
            :callback="fileNameCallback"
            @update:show-dialog="showNameFile = $event"
        />

        <!-- Widget -->
        <div class="widgetAlign mx-0">
            <v-card
                id="messageWidgetContainer"
                class="widgetContainer"
                @dragenter="onDragEnter"
                @dragover="onDragOver"
                @dragleave="onDragLeave"
                @drop="dropFile"
                @dragover.prevent
                @drop.prevent
            >
                <div
                    v-if="dropUiActive"
                    class="dropUiContainer"
                >
                    <div class="dropUi">
                        <p style="color: rgb(var(--v-theme-parentLetterPurple)); font-size: 25px">
                            Dateien hier loslassen!
                        </p>
                    </div>
                </div>
                <!-- <ClickableBadge
                    :event-list="badgeEventList"
                    @badgeClicked="widgetBadgeClicked"
                /> -->
                <!--#region Widget header -->
                <!-- Header for create/edit Letter -->
                <div
                    v-if="createLetterDialog || replyLetterDialog"
                    class="header"
                >
                    <div class="headerRow mx-2 d-flex align-center justify-space-between">
                        <div class="d-flex align-center">
                            <v-btn
                                class="optionBtn"
                                theme="dark"
                                icon="$vuetify"
                                variant="plain"

                                @click="checkForChanges"
                            >
                                <img
                                    :src="backIcon"
                                    class="icon20 iconToWhite"
                                    alt="zurück"
                                >
                            </v-btn>

                            <h1
                                class="text-truncate"
                                style="font-size: 16px"
                                :style="teacherCreateSick && (windowWidth < 1400 && windowWidth > 900) ? 'font-size:13px': ''"
                            >
                                {{ selectReceiver ? 'Empfänger auswählen' : editLetter ? 'Elternbrief bearbeiten' : teacherCreateSick ? 'Schüler/in abwesend melden' : replyLetterDialog ? 'Elternbrief antworten' :'Elternbrief verfassen' }}
                            </h1>
                        </div>
                        <v-tooltip
                            location="top"
                            :disabled="replyLetterDialog || (receiverAccounts.length > 0 && (containsPoll ? pollValid : true))"
                        >
                            <template v-slot:activator="{ props }">
                                <div v-bind="props">
                                    <v-btn
                                        id="sendLetterButton"
                                        color="gruen"
                                        style="color: white; height: 35px"
                                        size="small"
                                        theme="dark"
                                        elevation="0"
                                        class="mr-1"
                                        aria-label="Absenden"
                                        :disabled="teacherCreateSick ? receiverAccounts === [] : replyLetterDialog ? false : !(receiverAccounts.length && (containsPoll ? pollValid : true) && title)"
                                        @click="handleClickLetterDialog"
                                    >
                                        <img
                                            :src="sendenIcon"
                                            class="icon20 iconToWhite"
                                            style="height: 25px"
                                            alt="Absenden"
                                        >
                                        <p
                                            v-if="!teacherCreateSick"
                                            class="text-capitalize sendingButton ml-2"
                                            aria-hidden="true"
                                        >
                                            Absenden
                                        </p>
                                    </v-btn>
                                </div>
                            </template>
                            <span>{{ !receiverAccounts.length ? 'Sie müssen einen oder mehrere Empfänger auswählen'
                                : 'Sie müssen die Umfrage ausfüllen' }}</span>
                        </v-tooltip>
                    </div>
                </div>

                <!-- Header for creating sick note -->
                <div
                    v-else-if="createSickNoteDialog"
                    class="header"
                >
                    <div
                        class="headerRow mx-2 d-flex align-center "
                        style="flex:auto;font-size:larger"
                    >
                        <v-btn
                            icon="$vuetify"
                            variant="plain"

                            theme="dark"
                            @click="checkForSickNoteChanges"
                        >
                            <img
                                :src="backIcon"
                                class="icon20 iconToWhite"
                                alt="zurück"
                            >
                        </v-btn>
                        <div
                            class="mx-2 d-flex justify-space-between align-center"
                            style="flex:auto;font-size:larger; "
                        >
                            <p style="line-height: 95%; font-size: smaller">
                                Mitteilung erstellen
                            </p>
                            <div>
                                <v-btn
                                    id="eK-button-disabled"
                                    :disabled="validateCreateSickNote()"
                                    theme="dark"
                                    size="small"
                                    elevation="0"
                                    color="gruen"
                                    style="height: 35px"
                                    @click="handleSendSickNote"
                                >
                                    <img
                                        :src="sendenIcon"
                                        class="icon20 iconToWhite"
                                        style="height: 25px"
                                        alt="senden"
                                    >
                                    <p class="text-capitalize sendingButton ml-2">
                                        Absenden
                                    </p>
                                </v-btn>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Header for Gesendet/Empfangen -->
                <div
                    v-if="showWidgetHeader"
                    class="header"
                >
                    <div class="headerRow mx-4 d-flex align-center justify-space-between">
                        <div
                            class="d-flex align-center justify-center"
                        >
                            <img
                                :src="mailIcon"
                                class=" icon20 iconToWhite"
                                alt=""
                                @click="messageCategory === 1 ? handleClickCategory(0) : handleClickCategory(1)"
                            >
                            <h1
                                class="mb-0 ml-2"
                                style="font-size: larger"
                            >
                                <v-text-field
                                    v-model="searchMessagesWidget"
                                    :append-icon="searchMessagesWidget.trim().length > 0 ? 'mdi-close' : ''"
                                    hide-details
                                    theme="dark"
                                    density="compact"
                                    tabindex="0" 
                                    class="mb-1"
                                    :style="windowWidth > 900 && windowWidth < 1100 ? 'width: 200px;':'width:240px' "
                                    :placeholder="widgetTitle"
                                    @click:append="() => { searchMessagesWidget = '' }"
                                /> 
                            </h1>
                        </div>
                        <div class="pa-0 d-flex align-center justify-end">
                            <div v-if="usePin && accountPinValid">
                                <v-tooltip
                                    location="top"
                                >
                                    <template v-slot:activator="{ props }">
                                        <v-btn 
                                            size="x-small"
                                            variant="text"
                                            min-width="20"
                                            class="pa-0 mr-2"
                                            style="height: 20px; width: 20px"
                                            color="white"
                                           
                                            v-bind="props"
                                            @click="closeWidgetDialog = true"
                                        >
                                            <img
                                                class="icon25 iconToWhite"
                                                style="position: relative; margin: auto; opacity: 0.8"
                                                alt="schließen"
                                                :src="lockIcon"
                                            >
                                        </v-btn>
                                    </template>
                                    <span>Elternbrief schließen</span>
                                </v-tooltip>
                            </div>
                            <!-- Create button and create menu -->
                            <div v-if="accountRole === 'teacher'|| accountRole === 'maintainer'">
                                <v-menu
                                    :offset="[0,10]"
                                    location="left"
                                >
                                    <template v-slot:activator="{ props }">
                                        <v-btn
                                            variant="text"
                                            min-width="20"
                                            class="pa-0"
                                            style="height: 20px; width: 20px"
                                           
                                            v-bind="props"
                                        >
                                            <img
                                                :src="plusIcon"
                                                class="icon20 iconToWhite"
                                                alt="Erstellen"
                                            >
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <v-list-item @click="setCreateLetter(false)">
                                            Elternbrief erstellen
                                        </v-list-item>
                                        <v-list-item @click="()=>{ setCreateLetter(true);}">
                                            Schüler abwesend
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                                <!-- <v-tooltip
                                    top
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            :disabled="(usePin && (!accountPinValid))"
                                            x-small
                                            text
                                            min-width="20"
                                            class="pa-0 ml-2"
                                            style="height: 20px; width: 20px"
                                            color="white"
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="setCreateLetter"
                                        >
                                            <img
                                                :src="plusIcon"
                                                class="icon20 iconToWhite"
                                                alt="Elternbrief erstellen"
                                            >
                                        </v-btn>
                                    </template>
                                    <span>Elternbrief erstellen</span>
                                </v-tooltip> -->
                            </div>
                            <div v-else>
                                <v-tooltip
                                    location="top"
                                >
                                    <template v-slot:activator="{ props }">
                                        <v-btn
                                            class="optionBtn ml-2"
                                            elevation="0"
                                            variant="text"
                                            size="x-small"
                                           
                                            v-bind="props"
                                            @click="clickPlusButton();"
                                        >
                                            <img
                                                :src="plusIcon"
                                                class="icon20"
                                                alt="Erstellen"
                                            >
                                        </v-btn>
                                    </template>
                                    <span>Entschuldigung erstellen</span>
                                </v-tooltip>
                            </div>
                        </div>
                    </div>
                </div>
                <!--#endregion -->

                <!-- TEACHER | create/edit letter dialog -->
                <div
                    v-if="createLetterDialog || replyLetterDialog"
                    @click:outside="checkForChanges"
                >
                    <simple-bar
                        class="scroll-area"
                        :auto-hide="false"
                        :settings="settings"
                    >
                        <!-- create page -->
                        <div class="dialogRow">
                            <div
                                class=" d-flex mt-2 align-center justify-space-evenly"
                                style="flex:auto;"
                            />
                        </div>

                        <v-card class="mx-4 mb-2">
                            <div
                                v-if="!replyLetterDialog"
                                class="px-2 mt-1"
                                :class="teacherCreateSick ? 'teacherSickHeader':'messageCardHeader'"
                            >
                                <div
                                    v-if="!teacherCreateSick"
                                    class="align-center mb-0 pb-0"
                                    style="flex-flow: column; width: 100%;"
                                >
                                    <div
                                        class="d-flex mt-2"
                                        style="align-items:center; margin-left: -5px"
                                    >
                                        <p
                                            class="pl-4"
                                            :style="letterColor==='#fcd20a' ? '' : `color:${letterColor}`"
                                            style="font-weight:bold; font-size: 17px"
                                        >
                                            An:
                                        </p>
                                        <div
                                            style="align-items: center; width: 100%"
                                        >
                                            <v-menu
                                                :offset="[0,10]"
                                                location="left"
                                                class="whiteBg"
                                                :close-on-content-click="false"
                                                max-height="60%"
                                                style="width: 100%;"
                                                :attach="'#empfaengerAttach'"
                                            >
                                                <template v-slot:activator="{ props }">
                                                    <div
                                                        class="d-flex justify-space-between"
                                                        style="cursor: pointer; width: 98%"
                                                        @click="checkForUpdates"
                                                    >
                                                        <div
                                                           
                                                            style="width:100%"
                                                            v-bind="props"
                                                        >
                                                            <p
                                                                v-if="receiverAccounts.length < 1"
                                                                class="px-2"
                                                                :style="letterColor==='#fcd20a' ? '' : `color:${letterColor}`"
                                                                style="font-size: 16px"
                                                            >
                                                                Empfänger auswählen*
                                                            </p>
                                                            <p
                                                                v-else
                                                                :style="letterColor==='#fcd20a' ? '' : `color:${letterColor}`"
                                                                class="text-truncate px-2"
                                                            >
                                                                {{ receiverAccounts.length > 1 ? displayReceivers(receiverAccounts).split(', ')[0]+'...' : displayReceivers(receiverAccounts) }}
                                                            </p>
                                                        </div>
                                                        <div id="empfaengerAttach" />
                                                        <img
                                                            :src="searchIcon"
                                                            class="icon20"
                                                            :style="letterColor==='#fcd20a' ? '' : `color:${letterColor}`"
                                                            alt=""
                                                        >
                                                    </div>
                                                </template>

                                                <v-text-field
                                                    v-model="searchTreeView"
                                                    style="background-color: white; padding: 6px; border-radius: 0"
                                                    variant="solo"
                                                    density="compact"
                                                    hide-details
                                                >
                                                    <template v-slot:prepend-inner>
                                                        <img
                                                            :src="searchIcon"
                                                            class="icon20"
                                                            alt="suchen"
                                                        >
                                                    </template>
                                                </v-text-field>
                                                <!-- <v-treeview
                                                    v-model="receiverAccounts"
                                                    open-all
                                                    :items="possibleReceivers"
                                                    item-children="participants"
                                                    item-key="_id"
                                                    item-text="displayName"
                                                    :search="searchTreeView"
                                                    selected-color="rgb(var(--v-theme-chatColor))"
                                                    selectable
                                                    disable-per-node
                                                    dense
                                                    light
                                                    class="whiteBg"
                                                    return-object
                                                >
                                                    <template v-slot:prepend="{ item }">
                                                        <v-tooltip 
                                                            location="top"
                                                        >
                                                            <template v-slot:activator="{ props }">
                                                                <div
                                                                    class="d-flex align-center"
                                                                    v-bind="props"
                                                                >
                                                                    <img
                                                                        :src="item.participants ? groupIcon : pupilIcon"
                                                                        class="iconToDunkelgrau"
                                                                        height="20"
                                                                        alt="Schüler"
                                                                    >
                                                                </div>
                                                            </template>
                                                            <p>{{ item.displayName }}</p>
                                                        </v-tooltip>
                                                    </template>
                                                </v-treeview> -->
                                            </v-menu>
                                        </div>
                                    </div>
                                    <hr
                                        class="solid"
                                        :style="letterColor==='#fcd20a' ? '' : `border-top: 2px solid ${letterColor}`"
                                        aria-hidden="true"
                                    >
                                    <v-text-field
                                        v-model="title"
                                        single-line
                                        label="Betreff eingeben...*"
                                        density="compact"
                                        prepend-inner-icon="mdi-pound"
                                        class="pt-0 px-2"
                                    />
                                </div>
                                <div
                                    v-if="teacherCreateSick"
                                    class="d-flex flex-column"
                                    :style="windowWidth > 899 ? 'min-width: 26vw' : 'min-width: 74vw;'"
                                >
                                    <div class="datePickerInputRow">
                                        <v-select
                                            v-model="receiverAccounts"
                                            :items="pupilsFromTeacher"
                                            item-title="displayName"
                                            :menu-props="vSelectMenuProps"
                                            no-data-text="Keine Schüler vorhanden"
                                            density="compact"
                                            hide-details
                                            placeholder="Betroffene/-r Schüler/-in"
                                            class="mb-1 mr-1 "
                                            style="max-width:100%"
                                            return-object
                                        />
                                    </div>
                                    <div class="datePickerInputRow">
                                        <VDatePicker
                                            v-model="sickFrom"
                                            popover
                                            :masks="{ input: 'DD.MM.YYYY' }"
                                            expanded
                                            color="blue"
                                            locale="de-DE"
                                            first-day-of-week="1"
                                            :min-date="minDate"
                                            :attributes="[{ highlight: { color: 'blue' }, dates: sickFrom }]"
                                        >
                                            <template #default="{ inputValue, inputEvents }">
                                                <v-text-field
                                                    v-model="sickFrom"
                                                    :value="inputValue"
                                                    readonly
                                                    density="compact"
                                                    hide-details
                                                    label="Von:"
                                                    class="equal-width"
                                                    placeholder="Abwesend von"
                                                    v-on="inputEvents"
                                                >
                                                    <template #prepend>
                                                        <img
                                                            :src="kalenderIcon"
                                                            style="width: 20px; margin-top: 4px"
                                                            alt="Datum bearbeiten"
                                                        >
                                                    </template>
                                                </v-text-field>
                                            </template>
                                        </VDatePicker>

                                        <VDatePicker
                                            v-model="sickTill"
                                            popover
                                            :masks="{ input: 'DD.MM.YYYY' }"
                                            expanded
                                            color="blue"
                                            locale="de-DE"
                                            first-day-of-week="1"
                                            :min-date="sickFrom"
                                            :attributes="[{ highlight: { color: 'blue' }, dates: sickTill }]"
                                        >
                                            <template #default="{ inputValue, inputEvents }">
                                                <v-text-field
                                                    v-model="sickTill"
                                                    :value="inputValue"
                                                    readonly
                                                    density="compact"
                                                    hide-details
                                                    label="Bis:"
                                                    class="equal-width"
                                                    placeholder="Abwesend bis"
                                                    v-on="inputEvents"
                                                >
                                                    <template #prepend>
                                                        <img
                                                            :src="kalenderIcon"
                                                            style="width: 20px; margin-top: 4px"
                                                            alt="Datum bearbeiten"
                                                        >
                                                    </template>
                                                </v-text-field>
                                            </template>
                                        </VDatePicker>
                                    </div>

                                    <!-- Row for Time Pickers -->
                                    <div class="datePickerInputRow align-center">
                                        <v-checkbox
                                            v-model="addTime"
                                            rounded
                                            small
                                            density="compact"
                                            class="custom-checkbox"
                                            light
                                        />
                                        <v-menu
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            :offset="[10,0]"
                                            max-width="290px"
                                            min-width="290px"
                                        >
                                            <template v-slot:activator="{ props }">
                                                <v-text-field
                                                    v-model="sickFromTime"
                                                    :disabled="!addTime"
                                                    readonly
                                                    density="compact"
                                                    hide-details
                                                    label="Von:"
                                                    class="equal-width"
                                                    v-bind="props"
                                                    @click="startTimeMenu = true"
                                                >
                                                    <template #prepend>
                                                        <img
                                                            :src="uhrIcon"
                                                            class="icon20"
                                                            alt="Uhrzeit bearbeiten"
                                                        >
                                                    </template>
                                                </v-text-field>
                                            </template>

                                            <v-time-picker
                                                v-if="startTimeMenu"
                                                v-model="sickFromTime"
                                                format="24hr"
                                                color="rgb(var(--v-theme-parentLetterPurple))"
                                                @click:minute="startTimeMenu = false; areYouSureChangeSickText()"
                                            />
                                        </v-menu>

                                        <v-menu
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            :offset="[10,0]"
                                            max-width="290px"
                                            min-width="290px"
                                        >
                                            <template v-slot:activator="{ props }">
                                                <v-text-field
                                                    v-model="sickTillTime"
                                                    :disabled="!addTime"
                                                    readonly
                                                    density="compact"
                                                    hide-details
                                                    label="Bis:"
                                                    class="equal-width"
                                                    v-bind="props"
                                                    @click="endTimeMenu = true"
                                                >
                                                    <template #prepend>
                                                        <img
                                                            :src="uhrIcon"
                                                            class="icon20"
                                                            alt="Uhrzeit bearbeiten"
                                                        >
                                                    </template>
                                                </v-text-field>
                                            </template>

                                            <v-time-picker
                                                v-if="endTimeMenu"
                                                v-model="sickTillTime"
                                                format="24hr"
                                                color="rgb(var(--v-theme-parentLetterPurple))"
                                                @click:minute="endTimeMenu = false; areYouSureChangeSickText()"
                                            />
                                        </v-menu>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-else
                                class="messageCardHeader px-2 mt-1"
                                style="flex-direction: column !important;"
                            >
                                <p>{{ `${getAccountName(letterToReply.account)} schrieb am ${new Date(letterToReply.createdAt).toLocaleDateString('de-DE', {day: '2-digit', month: '2-digit'} )} :` }}</p>
                                <p><i>{{ `Betreff: ${letterToReply.title}` }}</i></p>
                                <p
                                    :class="replyCollapsed ? 'collapsedReply':''"
                                >
                                    <i v-html="sanitizeHtml(urlify(letterToReply.content))" />
                                </p>

                                <div
                                    v-if="letterToReply && (letterToReply.content.length > 40 || letterToReply.content.split('</p>').length > 3)"
                                    class="d-flex flex-column justify-space-around align-center"
                                    style="font-size: 12px;"
                                >
                                    <img
                                        :src="downIcon"
                                        class="icon20 iconToWhite ml-1"
                                        :style="!replyCollapsed ? 'transform:rotate(180deg);':''"
                                        @click="replyCollapsed = !replyCollapsed"
                                    >
                                    <div>
                                        {{ replyCollapsed ? 'Gesamte Nachricht anzeigen' :'Weniger anzeigen' }}
                                    </div>
                                </div>
                            </div>
                            <v-card-text class="d-flex flex-column">
                                <!-- <div 
                                    v-if="!teacherCreateSick" 
                                > -->
                                <AdvancedQuillEditor
                                    v-model="body"
                                    style="min-height: 200px"
                                    :placeholder="teacherCreateSick ? 'Schüler auswählen um Vorlage zu laden' :'Text eingeben...'"
                                    :text-down="true"
                                    @link-clicked="showLinkExplanation"
                                />
                                <div
                                    v-if="!teacherCreateSick" 
                                    class="d-flex justify-start"
                                >
                                    <p class="mr-4 mt-2">
                                        <b>Optionen</b>
                                    </p>
                                    <div
                                        class="d-flex align-center mt-2 mb-2"
                                        style="flex-flow: row wrap; gap: 8px"
                                    >
                                        <v-tooltip location="top">
                                            <template v-slot:activator="{ props }">
                                                <div
                                                    class="d-flex"
                                                    v-bind="props"
                                                >
                                                    <label
                                                        v-if="replyLetterDialog"
                                                        class="checkmarkContainer"
                                                    >
                                                        <input
                                                            v-model="visibleForAll"
                                                            type="checkbox"
                                                            aria-label="An Alle"
                                                            class="ma-1 pa-0 checkbox"
                                                        >
                                                        <span class="checkmark" />
                                                        <p
                                                            class="d-flex"
                                                            style="margin-left: -10px; align-items:center;"
                                                        >
                                                            <img 
                                                                :src="groupIcon"
                                                                height="20px"
                                                                style="margin-top: -3px; margin-right: 3px"
                                                                class="iconToDunkelgrau"
                                                                alt="Alle"
                                                            >
                                                            <span aria-hidden="true">Alle</span>
                                                        </p>
                                                    </label>
                                                </div>
                                            </template>
                                            <span>Wenn ausgewählt sehen diese Antwort auch alle ursprünglichen Empfänger des Elternbriefs</span>
                                        </v-tooltip>
                                        <v-tooltip location="top">
                                            <template v-slot:activator="{ props }">
                                                <div
                                                    class="d-flex align-center"
                                                    v-bind="props"
                                                >
                                                    <label
                                                        v-if="!replyLetterDialog"
                                                        class="checkmarkContainer"
                                                    >
                                                        <input
                                                            v-model="requiresSignature"
                                                            type="checkbox"
                                                            :disabled="onlyMaintainerSelected"
                                                            aria-label="Unterschreibungspflichtig"
                                                            class="ma-1 pa-0 checkbox"
                                                        >
                                                        <span
                                                            class="checkmark"
                                                            :style="onlyMaintainerSelected ? 'border: 1px solid red;' : ''"
                                                        />
                                                        <p
                                                            class="d-flex"
                                                            style="margin-left: -10px; align-items:center;"
                                                        >
                                                            <img
                                                                :src="unterschriftIcon"
                                                                height="20px"
                                                                style="margin-top: -3px; margin-right: 3px"
                                                                alt
                                                            >
                                                            <span aria-hidden="true">Signatur</span>
                                                        </p>
                                                    </label>
                                                </div>
                                            </template>
                                            <span v-if="onlyMaintainerSelected">Signatur kann von Verwaltung nicht gefordert werden</span>
                                            <span v-else> Eltern müssen diesen Brief unterschreiben</span>
                                        </v-tooltip>
                                        <v-tooltip location="top">
                                            <template v-slot:activator="{ props }">
                                                <div
                                                    class="d-flex"
                                                    v-bind="props"
                                                >
                                                    <label
                                                        v-if="!replyLetterDialog"
                                                        class="checkmarkContainer"
                                                    >
                                                        <input
                                                            v-model="containsPoll"
                                                            type="checkbox"
                                                            aria-label="Umfrage"
                                                            class="ma-1 pa-0 checkbox"
                                                        >
                                                        <span class="checkmark" />
                                                        <p
                                                            class="d-flex"
                                                            style="margin-left: -10px; align-items:center;"
                                                        >
                                                            <v-icon
                                                                height="20"
                                                                color="#6f6e6e"
                                                                style="transform: rotate(90deg); margin-top: -3px; margin-right: 3px"
                                                                alt="Umfrage"
                                                            >
                                                                mdi-poll
                                                            </v-icon>
                                                            <span aria-hidden="true">Umfrage</span>
                                                        </p>
                                                    </label>
                                                </div>
                                            </template>
                                            <span> Umfrage hinzufügen</span>
                                        </v-tooltip>
                                        <v-tooltip location="top">
                                            <template v-slot:activator="{ props }">
                                                <div
                                                    class="d-flex"
                                                    style="align-items:center;"
                                                    v-bind="props"
                                                >
                                                    <label
                                                        class="checkmarkContainer"
                                                    >
                                                        <input
                                                            v-model="anhang"
                                                            type="checkbox"
                                                            aria-label="Anhang"
                                                            class="ma-1 pa-0 checkbox"
                                                        >
                                                        <span class="checkmark" />
                                                        <p
                                                            class="d-flex"
                                                            style="margin-left: -10px; align-items:center;"
                                                        >
                                                            <img
                                                                :src="paperclipIcon"
                                                                height="20px"
                                                                style="margin-top: -3px; margin-right: 3px"
                                                                alt=""
                                                            >
                                                            <span aria-hidden="true">Anhang</span>
                                                        </p>
                                                    </label>
                                                </div>
                                            </template>
                                            <span> Anhang hinzufügen</span>
                                        </v-tooltip>
                                    </div>
                                </div>
                                <!-- Poll -->
                                <div
                                    v-if="containsPoll"
                                    class="dialogRow"
                                >
                                    <div
                                        class="mb-1"
                                        style="display:flex; justify-content: flex-start"
                                    >
                                        <v-icon
                                            class="mr-2"
                                            height="20"
                                            color="#6f6e6e"
                                            style="transform: rotate(90deg);"
                                        >
                                            mdi-poll
                                        </v-icon>
                                        <h1 style="margin-top: 10px; font-weight: bold; font-size: 17px">
                                            Umfrage
                                        </h1>
                                    </div>
                                    <Poll
                                        :letter-mode="true"
                                        @poll-valid="pollValid = true"
                                        @poll-invalid="pollValid = false"
                                    />
                                </div>
                                <div v-if="anhang">
                                    <div
                                        class="ml-1"
                                        style="display:flex; justify-content: flex-start"
                                    >
                                        <img
                                            :src="paperclipIcon"
                                            class="icon20"
                                            style="min-height: 25px"
                                            alt="Anhang"
                                        >
                                        <h1 style="margin-top: 8px; font-weight: bold; font-size: 17px">
                                            Anhang
                                        </h1>
                                    </div>
                                    <v-row class="d-flex justify-space-between mx-4 mt-5">
                                        <!-- Upload Previews -->
                                        <div
                                            v-for="(item, index) in filesToUpload"
                                            :key="index"
                                            class="uploadPreview"
                                            style="min-height: 180px; min-width: 180px;"
                                        >
                                            <div class="uploadPreviewHeader text-truncate">
                                                <p
                                                    class="text-truncate"
                                                    style="color: white"
                                                >
                                                    {{ item.name }}
                                                </p>
                                                <v-btn
                                                    height="25"
                                                    width="25"
                                                    icon="$vuetify"
                                                    variant="plain"

                                                    theme="dark"
                                                    @click="removeFileToUpload(item.lastModified)"
                                                >
                                                    <v-icon>
                                                        mdi-close
                                                    </v-icon>
                                                </v-btn>
                                            </div>

                                            <v-img
                                                v-if="item.thumbnail"
                                                :src="getThumb(item.thumbnail)"
                                                align="center"
                                                class="pa-1 thumbnailDisplayNormal mx-auto"
                                            >
                                                <div class="fill-height bottom-gradient" />
                                                <template v-slot:placeholder>
                                                    <v-row
                                                        class="fill-height fill-width ma-0"
                                                        align="center"
                                                        justify="center"
                                                    >
                                                        <!-- <v-progress-circular
                                                            indeterminate
                                                            color="grey"
                                                        /> -->
                                                    </v-row>
                                                </template>
                                            </v-img>
                                            <div
                                                v-else-if="(item['type'].split('/')[0] === 'image')"
                                                class="displayWholeThumbnail"
                                                style="overflow:hidden"
                                            >
                                                <v-img
                                                    :src="loadPicture(item)"
                                                    align="center"
                                                >
                                                    <div class="fill-height bottom-gradient" />
                                                    <template v-slot:placeholder>
                                                        <v-row
                                                            class="fill-height fill-width ma-0"
                                                            align="center"
                                                            justify="center"
                                                        >
                                                            <!-- <v-progress-circular
                                                                indeterminate
                                                                color="grey"
                                                            /> -->
                                                        </v-row>
                                                    </template>
                                                </v-img>
                                            </div>
                                            <div
                                                v-else-if="(item['type'].split('/')[0] === 'video')"
                                                class="pa-1 thumbnailDisplayNormal fill-height bottom-gradient mx-auto"
                                                style="margin-bottom: 5px; overflow: hidden"
                                            >
                                                <v-img
                                                    :src="videoIcon"
                                                    class="mb-2 iconToDunkelgrau"
                                                    alt="Video"
                                                    cover
                                                />
                                            </div>
                                        </div>

                                        <!-- Drop Area indicator -->
                                        <div
                                            class="uploadPreview d-flex flex-column align-center justify-center"
                                            style="min-height: 180px; min-width: 180px; justify-content: center; cursor: pointer; background-color: rgba(80, 80, 80, 0.2);"
                                            :style="dropzone"
                                            @click="clickUpload"
                                        >
                                            <div
                                                class="mx-auto d-flex flex-column align-center justify-center"
                                                style="margin-bottom: 5px; overflow: hidden;"
                                            >
                                                <v-tooltip location="top">
                                                    <template v-slot:activator="{ props }">
                                                        <img
                                                            :src="plusIcon"
                                                            class="mb-2 iconToDunkelgrau"
                                                            style="height: 50px"
                                                            alt="Plus"
                                                           
                                                            v-bind="props"
                                                        >
                                                    </template>
                                                    <span>Klicken um Datei auszuwählen oder direkt hereinziehen</span>
                                                </v-tooltip>
                                                <p
                                                    v-if="isDesktopSize"
                                                    style="white-space: normal; max-width: 75%; text-align: center; color: rgb(var(--v-theme-dunkelgrau))"
                                                >
                                                    Dateien hier ablegen
                                                </p>
                                                <p
                                                    v-else
                                                    style="white-space: normal; max-width: 75%; text-align: center; color: rgb(var(--v-theme-dunkelgrau))"
                                                >
                                                    Foto, Datei hochladen
                                                </p>
                                                <input
                                                    id="fileUpload"
                                                    ref="fileUpload"
                                                    type="file"
                                                    hidden
                                                    multiple
                                                    @change="uploadInputChange"
                                                >
                                            </div>
                                        </div>

                                        <!-- Camera upload -->
                                        <!-- <div
                                            v-if="isDesktopSize"
                                            class="uploadPreview d-flex flex-column align-center justify-center"
                                            style="min-height: 180px; min-width: 180px; justify-content: center; cursor: pointer; background-color: rgba(80, 80, 80, 0.2);"
                                            @click="() => { this.toggleCamera = !this.toggleCamera }"
                                        >
                                            <div
                                                class="mx-auto d-flex flex-column align-center justify-center"
                                                style="margin-bottom: 5px; overflow: hidden"
                                            >
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <img
                                                            :src="cameraIcon"
                                                            class="mb-2 iconToDunkelgrau"
                                                            style="height: 50px"
                                                            alt="Plus"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                    </template>
                                                    <span>Klicken um die Kamera zu öffnen und ein Bild aufnehmen zu können</span>
                                                </v-tooltip>
                                                <p style="white-space: normal; max-width: 75%; text-align: center">
                                                    Bild mit Kamera aufnehmen
                                                </p>
                                            </div>
                                            <ImageCapture
                                                :toggle-dialog="toggleCamera"
                                                @handleImageCaptured="handleImageReceived"
                                            />
                                        </div> -->
                                    </v-row>
                                </div>
                            </v-card-text>

                            <!-- if editing letter show the original files first & handle deleting differently -->
                            <div
                                v-if="editLetter"
                                class="mx-2"
                            >
                                <p
                                    v-if="filesFromOriginalLetter != 0"
                                    class="headlineText"
                                >
                                    Ursprüngliche Dateien
                                </p>

                                <v-row class="d-flex align-center">
                                    <div
                                        v-for="file in filesFromOriginalLetter"
                                        :ref="`uploadPreview${file._id}`"
                                        :key="file._id"
                                        class="uploadPreview"
                                    >
                                        <div
                                            class="uploadPreviewHeader text-truncate"
                                        >
                                            <p
                                                class="text-truncate"
                                                style="color:white"
                                            >
                                                {{ file.uploadedName }}
                                            </p>
                                            <v-btn
                                                class="optionBtn"
                                                @click="openDeleteFileDialog(file)"
                                            >
                                                <img
                                                    :src="deleteIcon"
                                                    class="icon20"
                                                    alt="Datei entfernen"
                                                >
                                            </v-btn>
                                        </div>
                                        <div
                                            v-if="file.mimetype==='application/pdf'"
                                            class="thumbnailDisplayNormal"
                                        >
                                            <object
                                                :data="file.thumbnail + '#toolbar=0&navpanes=0&scrollbar=0&view=fitH'"
                                                align="center"
                                                type="application/pdf"
                                                border="0"
                                                loading="lazy"
                                                class="iOS_ResponsiveHack "
                                                style="cursor: pointer"
                                            />
                                        </div>
                                        <div
                                            v-else-if="file['mimetype'].split('/')[0] === 'image'"
                                            class="displayWholeThumbnail"
                                            style="overflow:hidden"
                                        >
                                            <v-img
                                                :src="file.thumbnail"
                                                align="center"
                                            >
                                                <div class="fill-height bottom-gradient" />
                                                <template v-slot:placeholder>
                                                    <v-row
                                                        class="fill-height fill-width ma-0"
                                                        align="center"
                                                        justify="center"
                                                    >
                                                        <!-- <v-progress-circular
                                                            indeterminate
                                                            color="grey"
                                                        /> -->
                                                    </v-row>
                                                </template>
                                            </v-img>
                                        </div>
                                        <div
                                            v-else-if="(file['mimetype'].split('/')[0] === 'video')"
                                            class="displayWholeThumbnail"
                                            style="margin-bottom: 5px; overflow: hidden"
                                        >
                                            <v-img
                                                :src="videoIcon"
                                                class="mb-2 iconToDunkelgrau"
                                                alt="Video"
                                                cover
                                            />
                                        </div>
                                    </div>
                                </v-row>
                            </div>
                        </v-card>
                        <!-- eslint-enable vue/no-v-html -->
                    </simple-bar>
                </div>

                <!-- PARENT | create sick note dialog -->
                <div
                    v-if="createSickNoteDialog"
                    max-width="600px"
                    @click:outside="checkForSickNoteChanges"
                >
                    <simple-bar
                        ref="scrollbarSicknote"
                        :auto-hide="false"
                        class="scroll-area-sickNote"
                        :settings="settings"
                    >
                        <div class="d-flex flex-column px-4 mt-2">
                            <v-card class="mb-2">
                                <div class="messageCardHeader px-2 pt-2">
                                    <div
                                        class="align-center mb-0 pb-0"
                                        style="flex-flow: column; width: 100%;"
                                    >
                                        <div
                                            class="d-flex"
                                            style="align-items:center;"
                                        >
                                            <p
                                                class="ml-1 mr-2"
                                                style="font-weight:bold"
                                            >
                                                An:
                                            </p>
                                            <v-select
                                                v-model="sickNoteReceivers"
                                                :items="receiverAccountsParents"
                                                item-title="displayName"
                                                item-value="account"
                                                :menu-props="vSelectMenuProps"
                                                no-data-text="Kein Klassenlehrer vorhanden"
                                                density="compact"
                                                hide-details
                                                multiple
                                                placeholder="Empfänger"
                                                class="mb-1 mr-1"
                                                style="max-width:100%"
                                                @update:model-value="areYouSureChangeSickText()"
                                            />
                                        </div>
                                        <v-select
                                            v-model="sickReasonIndex"
                                            :items="sickReasons"
                                            item-title="text"
                                            item-value="id"
                                            :menu-props="vSelectMenuProps"
                                            no-data-text="Gründe konnten nicht geladen werden"
                                            density="compact"
                                            prepend-inner-icon="mdi-pound"
                                            hide-details
                                            placeholder="Entschuldigungsgrund"
                                            class="padTop pb-2"
                                            @update:model-value="areYouSureChangeSickText();"
                                        />
                                    </div>
                                </div>
                                <v-card-text>
                                    <div
                                        class="d-flex"
                                        style="align-items:center;"
                                    >
                                        <p class="mr-2 mt-2">
                                            Schüler/-in:
                                        </p>
                                        <v-select
                                            v-model="sickNotePupil"
                                            :items="children"
                                            :item-title="el => el.name + ' ' + el.lastName"
                                            item-value="_id"
                                            :menu-props="vSelectMenuProps"
                                            no-data-text="Personen konnten nicht geladen werden"
                                            density="compact"
                                            hide-details
                                            placeholder="Entschuldigte Person"
                                            style="max-width: 50%; font-size:small"
                                            @update:model-value="areYouSureChangeSickText()"
                                        />
                                    </div>
                                    <div class="datePickerInputRow">
                                        <VDatePicker
                                            v-model="sickFrom"
                                            popover
                                            :masks="{ input: 'DD.MM.YYYY' }"
                                            expanded
                                            color="blue"
                                            locale="de-DE"
                                            first-day-of-week="1"
                                            :min-date="minDate"
                                            :attributes="[{ highlight: { color: 'blue' }, dates: sickFrom }]"
                                        >
                                            <template #default="{ inputValue, inputEvents }">
                                                <v-text-field
                                                    v-model="sickFrom"
                                                    :value="inputValue"
                                                    readonly
                                                    density="compact"
                                                    hide-details
                                                    label="Von:"
                                                    class="equal-width"
                                                    placeholder="Abwesend von"
                                                    v-on="inputEvents"
                                                >
                                                    <template #prepend>
                                                        <img
                                                            :src="kalenderIcon"
                                                            style="width: 20px; padding-left: 4px; margin-top: 4px"
                                                            alt="Datum bearbeiten"
                                                        >
                                                    </template>
                                                </v-text-field>
                                            </template>
                                        </VDatePicker>

                                        <VDatePicker
                                            v-model="sickTill"
                                            popover
                                            :masks="{ input: 'DD.MM.YYYY' }"
                                            expanded
                                            color="blue"
                                            locale="de-DE"
                                            first-day-of-week="1"
                                            :min-date="sickFrom"
                                            :attributes="[{ highlight: { color: 'blue' }, dates: sickTill }]"
                                        >
                                            <template #default="{ inputValue, inputEvents }">
                                                <v-text-field
                                                    v-model="sickTill"
                                                    :value="inputValue"
                                                    readonly
                                                    density="compact"
                                                    hide-details
                                                    label="Bis:"
                                                    class="equal-width"
                                                    placeholder="Abwesend bis"
                                                    v-on="inputEvents"
                                                >
                                                    <template #prepend>
                                                        <img
                                                            :src="kalenderIcon"
                                                            style="width: 20px; padding-left: 4px; margin-top: 4px"
                                                            alt="Datum bearbeiten"
                                                        >
                                                    </template>
                                                </v-text-field>
                                            </template>
                                        </VDatePicker>
                                    </div>

                                    <div
                                        v-if="sickReasons[sickReasonIndex].id !== '1'"
                                        class="datePickerInputRow"
                                    >
                                        <v-checkbox
                                            v-model="addTime"
                                            rounded
                                            small
                                            light
                                            density="compact"
                                            class="mr-2 mt-2"
                                            @click="areYouSureChangeSickText()"
                                        />
                                        <v-menu
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            :offset="[10,0]"
                                            max-width="290px"
                                            min-width="290px"
                                        >
                                            <template v-slot:activator="{ props }">
                                                <v-text-field
                                                    v-model="sickFromTime"
                                                    :disabled="!addTime"
                                                    readonly
                                                    density="compact"
                                                    hide-details
                                                    label="Von:"
                                                    class="equal-width "
                                                    v-bind="props"
                                                    @click="startTimeMenu = true"
                                                >
                                                    <template
                                                        #prepend
                                                    >
                                                        <img
                                                            :src="uhrIcon"
                                                            style="width: 20px; padding-left: 4px; margin-top: 4px"
                                                            alt="Uhrzeit bearbeiten"
                                                        >
                                                    </template>
                                                </v-text-field>
                                            </template>

                                            <v-time-picker
                                                v-if="startTimeMenu"
                                                v-model="sickFromTime"
                                                format="24hr"
                                                full-width
                                                color="rgb(var(--v-theme-parentLetterPurple))"
                                                @click:minute="startTimeMenu = false; areYouSureChangeSickText()"
                                            />
                                        </v-menu>

                                        <v-menu
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            :offset="[10,0]"
                                            max-width="290px"
                                            min-width="290px"
                                        >
                                            <template v-slot:activator="{ props }">
                                                <v-text-field
                                                    v-model="sickTillTime"
                                                    :disabled="!addTime"
                                                    readonly
                                                    density="compact"
                                                    hide-details
                                                    label="Bis:"
                                                    class="equal-width"
                                                    v-bind="props"
                                                    @click="endTimeMenu = true"
                                                >
                                                    <template
                                                        #prepend
                                                    >
                                                        <img
                                                            :src="uhrIcon"
                                                            style="width: 20px; padding-left: 4px; margin-top: 4px"
                                                            alt="Uhrzeit bearbeiten"
                                                        >
                                                    </template>
                                                </v-text-field>
                                            </template>

                                            <v-time-picker
                                                v-if="endTimeMenu"
                                                v-model="sickTillTime"
                                                format="24hr"
                                                full-width
                                                color="rgb(var(--v-theme-parentLetterPurple))"
                                                @click:minute="endTimeMenu = false; areYouSureChangeSickText()"
                                            />
                                        </v-menu>
                                    </div>

                                    <v-textarea
                                        v-model="sickNote"
                                        class="padTop textfieldFlat"
                                        density="compact"
                                        hide-details
                                        auto-grow
                                        placeholder="Beschreibung der Abwesenheit"
                                    />
                                    <div class="d-flex flex-column mt-2">
                                        <input
                                            id="signatureSickNoteInput"
                                            ref="signatureSickNoteInput"
                                            type="file"
                                            hidden
                                            @change="changeSignatureFileInput"
                                        >
                                        <div
                                            v-if="signatureDisplay"
                                            class=" d-flex px-2 mb-2"
                                            style="width:75px; height: fit-content !important; cursor: unset"
                                        >
                                            <img
                                                :src="signatureDisplay"
                                                alt="Ihre Unterschrift"
                                                style="height: 75px;"
                                            >
                                        </div>
                                        <!-- Signature Component which brings its own button -->
                                        <SignatureDialog
                                            :sick-note="true"
                                            :signature-display="signatureDisplay"
                                            @signature-done="signatureSickNoteDone"
                                        />
                                    </div>
                                    <v-file-input
                                        v-model="sickNoteFiles"
                                        multiple
                                        chips
                                        autofocus
                                        hide-details
                                        flat
                                        label="Anhang"
                                        placeholder="Datei hinzufügen"
                                        class="mt-10"
                                        @change="() => sickNoteInputChange()"
                                    />

                                    <!-- <v-card
                                        elevation="0"
                                        color="grey"
                                        class="mx-5 my-5 d-flex"
                                        height="100px"
                                    >
                                        <v-card-text
                                            style="display:flex; flex-direction: column; align-items: center;justify-content: center;"
                                        >
                                            <v-icon left>
                                                mdi-paperclip
                                            </v-icon>
                                            Oder Datei hier reinziehen
                                        </v-card-text>
                                    </v-card> -->
                                </v-card-text>
                                <v-card-actions class="d-flex justify-end pa-0" />
                            </v-card>
                        </div>
                    </simple-bar>
                </div>
                <!--#endregion -->

                <div v-else>
                    <v-overlay
                        v-if="usePin && (!accountPinValid)"
                        :scrim="'white'"
                        opacity="1.0"
                        style="border-radius: 0 0 16px 16px;position: absolute; top: 56px;cursor: pointer; z-index:4 !important"
                        @click="pinDialog = true"
                    >
                        <div class="d-flex flex-column justify-space-around align-center">
                            <img
                                class="icon30 iconToRed"
                                style="position: relative; margin: auto;"
                                alt="Pfeil nach rechts"
                                :src="lockIcon"
                            >
                            <p style="color:black">
                                Klicken um Elternbriefe anzuzeigen
                            </p>
                        </div>
                    </v-overlay>
                    <!--#region category switcher & messages -->
                    <div v-if="!createLetterDialog && !createSickNoteDialog && !replyLetterDialog">
                        <div class="mx-4 mt-2 pb-1 d-flex justify-center ">
                            <v-btn-toggle
                                density="compact"
                                mandatory
                                style="width: 100% !important; background-color: #F5F5F5; border-radius: 8px;"
                            >
                                <!-- <v-badge
                                    v-if="incomingMessageEvents.length > 0"
                                    :content="incomingMessageEvents.length"
                                    overlap
                                    left
                                    color="#ff0000"
                                    style="z-index: 4; width:100%"
                                /> -->
                                <ClickableBadge
                                    v-if="incomingMessageEvents.length > 0"
                                    :event-list="incomingMessageEvents"
                                    :small-badge="true"
                                    @badge-clicked="incomingMessagesBadgeClicked"
                                />
                                <v-btn
                                    :theme="messageCategory === 0 ? 'dark':''"
                                    class="text-none"
                                    style="width: 50% !important; border-top-right-radius: 0px; border-bottom-right-radius: 0px;"
                                    @click="handleClickCategory(0)"
                                >
                                    <img
                                        :src="eingangIcon"
                                        class="icon20 mr-2"
                                        :class="messageCategory === 0 ? 'iconToWhite' : ''"
                                        alt="Eingang"
                                    >
                                    Eingang
                                </v-btn>
                                <v-btn
                                    :theme="messageCategory === 1 ? 'dark':''"
                                    class="text-none"
                                    style="width: 50% !important; border-top-left-radius: 0px; border-bottom-left-radius: 0px;"
                                    @click="handleClickCategory(1)"
                                >
                                    <img
                                        :src="ausgangIcon"
                                        class="icon20 mr-2"
                                        :class="messageCategory === 1 ? 'iconToWhite' : ''"
                                        alt="Ausgang"
                                    >
                                    Gesendet
                                </v-btn>
                                <!-- <v-badge 
                                    v-if="accountRole !== 'parent' && sentMessageEvents > 0"
                                    :content="sentMessageEvents"
                                    overlap
                                    left
                                    color="#ff0000"
                                    style="z-index: 4; width:100%; display: unset !important;"
                                /> -->
                                <ClickableBadge
                                    v-if="accountRole !== 'parent' && sentMessageEvents.length > 0"
                                    :event-list="sentMessageEvents"
                                    :small-badge="true"
                                    @badge-clicked="sentMessagesBadgeClicked"
                                />
                            </v-btn-toggle>
                        </div>

                        <!-- messages -->
                        <div class="d-flex justify-center pb-2">
                            <simple-bar
                                class="scroll-area-messages"
                                :settings="settings"
                                style="width: 100%"
                            >
                                <!-- message sent -->
                                <div v-if="messageCategory === 1">
                                    <div
                                        v-if="sentMessages && sentMessages.length < 1 && !showOutgoingOverlay"
                                        class="ma-4 text-center"
                                        style="z-index: 0; padding-top: 11vh; cursor: pointer"
                                        @click="accountRole === 'teacher'|| accountRole === 'maintainer' ? setCreateLetter() : clickPlusButton()"
                                    >
                                        <img
                                            :src="keineElternbriefeIcon"
                                            alt="Keine Elternbriefe"
                                            style="height: 100px"
                                        >
                                        <p
                                            class="mt-2 text-center"
                                            style="font-size: 20px"
                                        >
                                            Keine Nachrichten in Gesendet
                                        </p>
                                        <p
                                            class="mt-2 text-center"
                                            style="font-size: 20px"
                                        >
                                            Auf "+ Plus" oder hier klicken um neuen Brief zu erstellen
                                        </p>
                                    </div>
                                    <div
                                        v-if="showOutgoingOverlay"
                                        class="d-flex flex-column justify-space-around align-center"
                                        style="padding-top: 30vh"
                                    >
                                        <v-progress-circular
                                            indeterminate
                                            color="rgb(var(--v-theme-parentLetterPurple))"
                                        />
                                        <p class="text-center">
                                            Gesendete werden geladen
                                        </p>
                                    </div>
                                    <div
                                        v-for="msg in searchSentMessages"
                                        :key="msg._id"
                                        class="mx-2"
                                        :class="accountRole===('teacher'||'maintainer') ? 'mt-1' : 'mt-0' "
                                    >
                                        <v-lazy
                                            transition="v-expand-x-transition"
                                            :options="{
                                                threshold: 0.25
                                            }"
                                        >
                                            <!-- sent Messages Teacher/Verwaltung -->
                                            <v-card
                                                v-if="accountRole === 'teacher' || accountRole === 'maintainer'"
                                                :ref="`message${msg._id}`"
                                                class="mx-2 my-2"

                                                style="border-radius: 8px;"
                                            >
                                                <v-badge 
                                                    v-if="msg.parentalLetterReplies && calcUnseenReplies(msg) > 0"
                                                    :content="calcUnseenReplies(msg)"
                                                    location="left"
                                                    color="#ff0000"
                                                    style="z-index: 4; width:100%; display: unset !important;"
                                                />
                                                <div class="d-flex flex-column justify-space-between">
                                                    <div
                                                        class="messageCardHeader d-flex align-center"
                                                        style="height: 48px"
                                                        @click="handleClickSentMessage(msg)"
                                                    >
                                                        <div>
                                                            <Avatar
                                                                class="pa-0 ma-1"
                                                                :size="35"
                                                                :profile="msg.account"
                                                                :show-burger="false"
                                                            />
                                                        </div>
                                                        <v-tooltip location="top">
                                                            <template v-slot:activator="{ props }">
                                                                <div
                                                                    class="d-flex flex-column text-truncate"
                                                                    style="color: black; overflow: hidden;"
                                                                   
                                                                    v-bind="props"
                                                                >
                                                                    <span
                                                                        :style="msg.uploadedIcon || msg.color==='#fcd20a' ? 'color: black' : `color:${msg.color}`"
                                                                        style="font-weight: bold"
                                                                    >
                                                                        {{ loggedInName + ' → ' + computedReceiverNames(msg) }}
                                                                    </span>

                                                                    <p>{{ msg.title }}</p>
                                                                </div>
                                                            </template>
                                                            <div :style="windowWidth > 900 ? 'max-width 28vw;':''">
                                                                <p>  {{ loggedInName + ' → ' + computedReceiverNames(msg) }}</p>
                                                                <p>{{ msg.title }}</p>
                                                            </div>
                                                        </v-tooltip>
                                                        <v-spacer />
                                                        <div
                                                            class="d-flex justify-flex-start align-center"
                                                        >
                                                            <v-tooltip location="top">
                                                                <template v-slot:activator="{ props }">
                                                                    <img
                                                                        v-if="msg.files.length>0 || msg.poll"
                                                                        :src="bueroklammerIcon"
                                                                        class="icon20"
                                                                        alt="Anhang"
                                                                       
                                                                        v-bind="props"
                                                                    >
                                                                </template>
                                                                <span>{{ msg.poll ? 'Umfrage enthalten ' :'' }} {{ msg.files.length > 0 ? 'Anhang enthalten':'' }}</span>
                                                            </v-tooltip>
                                                            <!-- <v-tooltip top>
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-icon
                                                                        v-if="msg.poll"
                                                                        height="20"
                                                                        color="#6f6e6e"
                                                                        style="transform: rotate(90deg);"
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                    >
                                                                        mdi-poll
                                                                    </v-icon>
                                                                </template>
                                                                <span>Umfrage enthalten</span>
                                                            </v-tooltip> -->

                                                            <img
                                                                :src="downIcon"
                                                                class="icon20 ml-1"
                                                                :style="clicked.active && (clicked.id === msg._id) ? 'transform: rotate(180deg)' : ''"
                                                                :alt="clicked.active && (clicked.id === msg._id) ? 'Nachricht einklappen' : 'Nachricht ausklappen'"
                                                            >

                                                            <v-menu
                                                                :offset="[0,10]"
                                                                location="left"
                                                                class="ml-0"
                                                            >
                                                                <template v-slot:activator="{ props }">
                                                                    <v-btn
                                                                        theme="dark"
                                                                        icon="$vuetify"
                                                                        variant="plain"

                                                                        @click="setCurrentLetter(msg)"
                                                                    >
                                                                        <img
                                                                            class="icon20"
                                                                            :src="optionsIcon"
                                                                            alt="Einstellungen"
                                                                           
                                                                            v-bind="props"
                                                                        >
                                                                    </v-btn>
                                                                </template>
                                                                <div>
                                                                    <v-list density="compact">
                                                                        <v-list-item @click="openConfirmDelDialog(msg, false)">
                                                                            <template #prepend>
                                                                                <v-icon
                                                                                    class="icon20 iconToRed"
                                                                                    aria-label="Löschen"
                                                                                >
                                                                                    mdi-delete
                                                                                </v-icon>
                                                                            </template>
                                                                            <v-list-item-title class="text-red">
                                                                                Löschen
                                                                            </v-list-item-title>
                                                                        </v-list-item>

                                                                        <v-divider />

                                                                        <v-list-item @click="() => { letterToExport = msg; exportType = 'parentalLetter'; exportLetterDialog = true; }">
                                                                            <template #prepend>
                                                                                <v-icon
                                                                                    class="icon20 iconToDunkelgrau"
                                                                                    aria-label="Exportieren"
                                                                                >
                                                                                    mdi-file-pdf
                                                                                </v-icon>
                                                                            </template>
                                                                            <v-list-item-title>
                                                                                Exportieren
                                                                            </v-list-item-title>
                                                                        </v-list-item>

                                                                        <v-divider />

                                                                        <v-list-item @click="setEditLetter(msg)">
                                                                            <template #prepend>
                                                                                <v-icon
                                                                                    class="icon20 iconToDunkelgrau"
                                                                                    aria-label="Bearbeiten"
                                                                                >
                                                                                    mdi-pencil
                                                                                </v-icon>
                                                                            </template>
                                                                            <v-list-item-title>
                                                                                Bearbeiten
                                                                            </v-list-item-title>
                                                                        </v-list-item>
                                                                    </v-list>
                                                                </div>
                                                            </v-menu>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    v-if=" accountRole === 'teacher' || 'maintainer' "
                                                    class="mx-2"
                                                    style="border-radius: 0 0 8px 8px;"
                                                >
                                                    <MessagesLetter
                                                        v-if="currentLetter && (clicked.active && (clicked.id === msg._id))"
                                                        class="mb-2"
                                                        :current-letter="currentLetter"
                                                        :signed-current-letter="signedCurrentLetter"
                                                        :not-signed-current-letter="notSignedCurrentLetter"
                                                        :seen-current-letter="seenCurrentLetter"
                                                        :settings="settings"
                                                        @edit-letter="setEditLetter(currentLetter)"
                                                        @export-letter="exportLetterDialog = true"
                                                        @delete-letter="openConfirmDelDialog(currentLetter, false)"
                                                        @play-audio="(thumbnail) => { audioUrl = thumbnail }"
                                                        @open-image="(thumbnail) => { imageUrl = thumbnail }"
                                                        @open-pdf="(thumbnail) => { pdfUrl = thumbnail }"
                                                        @play-video="(thumbnail) => { videoUrl = thumbnail }"
                                                    />
                                                    <v-menu
                                                        location="top"
                                                        :offset="[0,10]"
                                                        transition="scale-transition"
                                                        origin="center"
                                                    >
                                                        <template v-slot:activator="{ props }">
                                                            <div class="d-flex flex-row justify-space-between">
                                                                <v-spacer />
                                                                <div
                                                                    class="d-flex text-truncate flex-row justify-flex-end messageFooter"
                                                                    style="align-items:center"
                                                                   
                                                                    v-bind="props"
                                                                >
                                                                    <!-- seenByRow -->
                                                                    <span>
                                                                        {{ dayname[new Date(msg.createdAt).getDay()] }}.
                                                                        <span class="mr-2">
                                                                            {{ new Date(msg.createdAt).toLocaleDateString('de-DE', {day: '2-digit', month: '2-digit'}) }}
                                                                            <span class="hideText"> {{ new Date(msg.createdAt).toLocaleTimeString('de-DE', {hour: '2-digit', minute: '2-digit'}) }}</span>
                                                                        </span>
                                                                    </span>

                                                                    <span
                                                                        v-if="accountRole === 'teacher' || accountRole === 'maintainer'"
                                                                        :style="msg.checkmarkStyle==='all' ? 'color: rgb(var(--v-theme-chatColor));' : 'color: rgb(var(--v-theme-timetableColor));'"
                                                                    >
                                                                        {{ msg.requiresSignature ? msg.parentalLetterResponses.length : msg.seenBy.length }}/{{ msg.receivingAccounts.length }}
                                                                    </span>
                                                                    <span
                                                                        v-if="accountRole === 'teacher' && msg.checkmarkStyle === 'none'"
                                                                    >
                                                                        <img
                                                                            :src="checkIcon"
                                                                            class="iconToBlue"
                                                                            style="width: 16px; height: 16px; margin-bottom: -2px"
                                                                        >
                                                                    </span>
                                                                    <span
                                                                        v-if="accountRole === 'teacher' && !msg.checkmarkStyle"
                                                                    >
                                                                        <img
                                                                            :src="checkAllIcon"
                                                                            class="iconToBlue"
                                                                            style="width: 16px; height: 16px; margin-bottom: -2px"
                                                                        >
                                                                    </span>
                                                                    <span
                                                                        v-if="accountRole === 'teacher' && msg.checkmarkStyle==='all'"
                                                                    >
                                                                        <img
                                                                            :src="checkAllIcon"
                                                                            class="iconToGreen"
                                                                            style="width: 16px; height: 16px; margin-bottom: -2px"
                                                                        >
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </template>
                                                        <v-list
                                                            density="compact"
                                                            color="rgba(97, 97, 97, 0.9)"
                                                        >
                                                            <v-list-item class="d-flex flex-row justify-space-between">
                                                                <div style="color: white">
                                                                    Gelesen von:
                                                                </div>
                                                                <v-spacer />
                                                                <div
                                                                    style="font-size: 12px; align-items: flex-end; color: white"
                                                                >
                                                                    {{ "('" }}
                                                                    <img
                                                                        :src="checkIcon"
                                                                        class="iconToWhite"
                                                                        style="width: 14px; height: 14px; margin-left: -5px; margin-right: -4px; margin-bottom: -2px"
                                                                    >
                                                                    {{ "' = erhalten, '" }}
                                                                    <img
                                                                        :src="checkAllIcon"
                                                                        class="iconToWhite"
                                                                        style="width: 14px; height: 14px; margin-left: -3px; margin-right: -2px; margin-bottom: -2px"
                                                                    >
                                                                    {{ "' = gesehen)" }}
                                                                </div>
                                                            </v-list-item>
                                                            <template
                                                                v-for="element in msg.receivingAccounts"
                                                                :key="element._id"
                                                            >
                                                                <div
                                                                    style="background-color: transparent"
                                                                >
                                                                    <v-divider />
                                                                    <div
                                                                        class="d-flex flex-row justify-space-between px-2"
                                                                        style="max-width: 28vw;"
                                                                    >
                                                                        <div
                                                                            class="justify-flex-start"
                                                                            style="font-size: 14px; color: white"
                                                                        >
                                                                            {{ getFullName(element) }}
                                                                        </div>
                                                                        <img
                                                                            v-if=" msg.seenBy.includes(element)"
                                                                            :src="checkAllIcon"
                                                                            class="iconToWhite justify-flex-end"
                                                                            style="width: 14px; height: 14px; margin-bottom: -2px"
                                                                        >
                                                                        <img
                                                                            v-else
                                                                            :src="checkIcon"
                                                                            class="iconToWhite justify-flex-end"
                                                                            style="width: 14px; height: 14px; margin-bottom: -2px"
                                                                        >
                                                                    </div>
                                                                </div>
                                                            </template>
                                                        </v-list>
                                                    </v-menu>
                                                </div>
                                            </v-card>
                                        </v-lazy>
                                        <!-- sent MessageHeader PARENT -->
                                        <v-lazy
                                            transition="v-expand-x-transition"
                                            :options="{
                                                threshold: 0.25
                                            }"
                                        >
                                            <v-card
                                                v-if="accountRole === 'parent'"
                                                class="d-flex justify-center mx-2 my-2"
                                                style="flex-direction: column; flex: auto; border-radius: 8px"
                                            >
                                                <!-- sent Message PARENT -->
                                                <div
                                                    v-if="accountRole === 'parent'"
                                                    :ref="`message${msg._id}`"
                                                    class="d-flex messageCardHeader"
                                                    style="border-radius: 8px 8px 0 0; align-items:center; max-width: 100%;"
                                                    @click="handleClickSentMessage(msg)"
                                                >
                                                    <div
                                                        class="d-flex flex-row justify-space-around align-center text-truncate titleContainer"
                                                    >
                                                        <Avatar
                                                            :profile="msg.author.account"
                                                            :size="35"
                                                            :alt="'Profilbild'"
                                                            class="ma-2"
                                                        />
                                                        <v-tooltip
                                                            location="top"
                                                            z-index="99"
                                                        >
                                                            <template v-slot:activator="{ props }">
                                                                <div
                                                                    class="d-flex flex-column text-truncate "
                                                                    style="color: black; overflow: hidden;"
                                                                   
                                                                    v-bind="props"
                                                                >
                                                                    <p
                                                                        class="text-truncate"
                                                                        :style="getProfileColor(msg.author.account ? msg.author.account : msg.author)==='#fcd20a' ? 'color: black' : `color:${getProfileColor(msg.author.account ? msg.author.account : msg.author)}`"
                                                                        style="font-weight: bold"
                                                                    >
                                                                        {{ 'An ' + getReceivers(msg.receiver) }}
                                                                    </p>

                                                                    <p style="color: black">
                                                                        {{ msg.title }}
                                                                    </p>
                                                                </div>
                                                            </template>
                                                            <div :style="windowWidth > 900 ? 'max-width:28vw':'max-width:85vw'">
                                                                <p>
                                                                    {{ 'An ' + getReceivers(msg.receiver) }}
                                                                </p>

                                                                <p style="color: black">
                                                                    {{ msg.title }}
                                                                </p>
                                                            </div>
                                                        </v-tooltip>
                                                    </div>
                                                    <v-spacer />
                                                    <div
                                                        class="d-flex flex-row mx-1 mt-6"
                                                    >
                                                        <div class="mr-2">
                                                            <v-tooltip location="top">
                                                                <template v-slot:activator="{ props }">
                                                                    <img
                                                                        :src="sickNoteHeaderIcon(msg).icon" 
                                                                        class="icon20"
                                                                        :class="sickNoteHeaderIcon(msg).class"
                                                                       
                                                                        v-bind="props"
                                                                    >
                                                                </template>
                                                                <span
                                                                    v-if="msg.accepted === true"
                                                                >
                                                                    angenommen
                                                                </span>
                                                                <span
                                                                    v-else-if="msg.accepted === false"
                                                                >
                                                                    abgelehnt
                                                                </span>
                                                                <span
                                                                    v-else
                                                                >
                                                                    offen
                                                                </span>
                                                            </v-tooltip>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="d-flex"
                                                        style="align-items: center;"
                                                    >
                                                        <v-tooltip location="top">
                                                            <template v-slot:activator="{ props }">
                                                                <img
                                                                    v-if="msg.files.length>0"
                                                                    :src="bueroklammerIcon"
                                                                    class="icon20"
                                                                    alt="Anhang"
                                                                   
                                                                    v-bind="props"
                                                                >
                                                            </template>
                                                            <span>Anhang enthalten</span>
                                                        </v-tooltip>
                                                        <img
                                                            :src="downIcon"
                                                            class="icon20 ml-1 mr-2"
                                                            :style="clicked.active && (clicked.id === msg._id) ? 'transform: rotate(180deg)' : ''"
                                                            :alt="clicked.active && (clicked.id === msg._id) ? 'Nachricht einklappen' : 'Nachricht ausklappen'"
                                                        >
                                                    </div>
                                                </div>

                                                <div
                                                    v-if="clicked.id === msg._id && accountRole==='parent'"
                                                >
                                                    <MessagesSickNote
                                                        class="pb-2 mb-2"
                                                        :current-sick-note="currentSickNote"
                                                        :role="accountRole"
                                                        :settings="settings"
                                                        :signature="sickNoteSignature"
                                                        @handle-accept-sick-note="handleAcceptSickNote"
                                                        @handle-decline-sick-note="handleDeclineSickNote"
                                                        @play-audio="(thumbnail) => { audioUrl = thumbnail }"
                                                        @open-image="(thumbnail) => { imageUrl = thumbnail }"
                                                        @open-pdf="(thumbnail) => { pdfUrl = thumbnail }"
                                                        @play-video="(thumbnail) => { videoUrl = thumbnail }"
                                                    />
                                                    <div
                                                        v-if="msg.accepted || msg.accepted === false"
                                                        class="d-flex justify-end ma-2"
                                                    >
                                                        <v-btn
                                                            :color="msg.accepted ? 'success' : 'error'"
                                                            variant="flat"
                                                            style="cursor: default; pointer-events: none;"
                                                        >
                                                            <v-icon
                                                                class="mr-1"
                                                            >
                                                                {{ msg.accepted ? 'mdi-check': 'mdi-close' }}
                                                            </v-icon>
                                                            {{ msg.accepted ? 'Entschuldigt': 'Abgelehnt' }}
                                                        </v-btn>
                                                    </div>
                                                </div>
                                                <div
                                                    v-if="accountRole==='parent'"
                                                    class="d-flex justify-space-between"
                                                >
                                                    <v-spacer />
                                                    <div class="messageFooter">
                                                        {{ dayname[new Date(msg.createdAt).getDay()] }}.
                                                        <span class="mr-2 ml-1">
                                                            {{ new Date(msg.createdAt).toLocaleDateString('de-DE', {day: '2-digit', month: '2-digit'}) }}
                                                            <span class="hideText"> {{ new Date(msg.createdAt).toLocaleTimeString('de-DE', {hour: '2-digit', minute: '2-digit'}) }}</span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </v-card>
                                        </v-lazy>
                                    </div>
                                </div>

                                <!-- message received -->
                                <div
                                    v-else
                                    class="mt-1"
                                >
                                    <div
                                        v-if="(!incomingMessages || incomingMessages.length < 1) && !showIncomingOverlay"
                                        class="d-flex"
                                        style="justify-content: center; flex-direction: column; padding-top: 11vh"
                                    >
                                        <img
                                            :src="keineElternbriefeIcon"
                                            alt="Keine Elternbriefe"
                                            style="height: 100px"
                                        >
                                        <p
                                            class="mt-2 text-center"
                                            style="font-size: 20px"
                                        >
                                            Keine Nachrichten im Eingang
                                        </p>
                                    </div>
                                    <div
                                        v-if="showIncomingOverlay"
                                        class="d-flex flex-column justify-space-around align-center"
                                        style="padding-top: 30vh"
                                    >
                                        <v-progress-circular
                                            indeterminate
                                            color="rgb(var(--v-theme-parentLetterPurple))"
                                        />
                                        <p class="text-center">
                                            Eingang wird geladen
                                        </p>
                                    </div>
                                    <div
                                        v-for="(msg, index) in searchIncomingMessages"
                                        :key="msg._id + index"
                                        :ref="`index_${index}`"
                                        class="d-flex flex-column pt-1 pb-1 mx-4"
                                    >
                                        <!-- THIS BADGE IS BAD AND NO GOOD
                                        THE ENTIRE BADGE CALCULATION IS BAD AND NO GOOD
                                        AND IT INFINITE LOOPS AND ITS NO GOOD AND ITS BAD -->
                                        <!-- <v-badge
                                            v-if="calcUnseenBadges(msg._id)"
                                            content="1"
                                            style="width: 100%; position: absolute; z-index: 9"
                                            color="#ff0000"
                                            offset-y="16"
                                            left
                                            overlap
                                        /> -->
                                        <v-lazy
                                            transition="v-expand-x-transition"
                                            :options="{
                                                threshold: 0.25
                                            }"
                                        >
                                            <v-card
                                                :ref="`letter${msg._id}`"
                                                class="d-flex mx-4;"
                                                style="flex-direction: column; border-radius: 8px;"
                                            >
                                                <v-badge
                                                    v-if="calcUnseenRepliesIncoming(msg) > 0"
                                                    :content="calcUnseenRepliesIncoming(msg)"
                                                    location="left"
                                                    color="#ff0000"
                                                    style="z-index: 4; width:100%"
                                                />
                                                <!-- if msg.pupil -> message is sickNote, therefore incoming for teacher -->
                                                <div
                                                    v-if="msg.pupil"
                                                >
                                                    <div
                                                        class="messageCardHeader align-center"
                                                        style="height: 48px"
                                                        @click="handleClickIncomingMessage(msg)"
                                                    >
                                                        <div
                                                            class="d-flex flex-row justify-space-around text-truncate titleContainer"
                                                            style="max-width: 90%;"
                                                        >
                                                            <Avatar
                                                                v-if="msg.author"
                                                                :profile="msg.author?.account"
                                                                :size="35"
                                                                :alt="'Profilbild'"
                                                                class="ma-2 "
                                                            />
                                                            <div
                                                                v-else
                                                                class="ma-2 d-flex align-center justify-center"
                                                                style="width:35px; height:35px; background-color: grey; border-radius: 6px;"
                                                            >
                                                                <img
                                                                    :src="deletedAvatar"
                                                                    class="icon30 iconToWhite"
                                                                >
                                                            </div>
                                                            <v-tooltip 
                                                                v-if="msg.author"
                                                                location="top"
                                                                z-index="99"
                                                            >
                                                                <template v-slot:activator="{ props }">
                                                                    <div
                                                                       
                                                                        class="d-flex flex-column text-truncate"
                                                                        style="color: black; overflow: hidden;"
                                                                        v-bind="props"
                                                                    >
                                                                        <p
                                                                            v-if="accountRole === 'teacher' || accountRole === 'maintainer'"
                                                                            class="text-truncate"
                                                                            :style="getProfileColor(msg.author.account ? msg.author.account : msg.author)==='#fcd20a' ? 'color: black' : `color:${getProfileColor(msg.author.account ? msg.author.account : msg.author)}`"
                                                                            style="font-weight: bold"
                                                                        >
                                                                            {{
                                                                                msg.pupil.name[0] + '. ' + msg.pupil.lastName + ' → ' + getReceivers(msg.receiver)
                                                                            }}
                                                                        </p>
                                                                        <p>{{ msg.title }}</p>
                                                                    </div>
                                                                </template>
                                                                <div :style="windowWidth > 900 ? 'max-width:28vw':'max-width:85vw'">
                                                                    <p
                                                                        v-if="accountRole === 'teacher' || accountRole === 'maintainer'"
                                                                    >
                                                                        {{
                                                                            msg.pupil.name[0] + '. ' + msg.pupil.lastName + ' → ' + getReceivers(msg.receiver)
                                                                        }}
                                                                    </p>
                                                                    <p>{{ msg.title }}</p>
                                                                </div>
                                                            </v-tooltip>
                                                            <div v-else>
                                                                <v-tooltip 
                                                                    location="top"
                                                                    z-index="99"
                                                                >
                                                                    <template v-slot:activator="{ props }">
                                                                        <div
                                                                           
                                                                            v-bind="props"
                                                                        >
                                                                            <p
                                                                                style="font-weight: bold"
                                                                            >
                                                                                gelöschter Nutzer
                                                                            </p>
                                                                            <p>{{ msg.title }}</p>
                                                                        </div>
                                                                    </template>
                                                                    <div :style="windowWidth > 900 ? 'max-width:28vw':'max-width:85vw'">
                                                                        <p>gelöschter Nutzer</p>
                                                                        <p>{{ msg.title }}</p>
                                                                    </div>
                                                                </v-tooltip>
                                                            </div>
                                                        </div>
                                                        <v-spacer />
                                                        <div
                                                            class="d-flex flex-row mx-1 mt-6 "
                                                        >
                                                            <div class="mr-2">
                                                                <v-tooltip location="top">
                                                                    <template v-slot:activator="{ props }">
                                                                        <img
                                                                            :src="sickNoteHeaderIcon(msg).icon" 
                                                                            class="icon20"
                                                                            :class="sickNoteHeaderIcon(msg).class"
                                                                           
                                                                            v-bind="props"
                                                                        >
                                                                    </template>
                                                                    <span
                                                                        v-if="msg.accepted === true"
                                                                    >
                                                                        angenommen
                                                                    </span>
                                                                    <span
                                                                        v-else-if="msg.accepted === false"
                                                                    >
                                                                        abgelehnt
                                                                    </span>
                                                                    <span
                                                                        v-else
                                                                    >
                                                                        offen
                                                                    </span>
                                                                </v-tooltip>
                                                            </div>
                                                        </div>

                                                        <div
                                                            class="d-flex"
                                                            style="align-items: center;"
                                                        >
                                                            <v-tooltip
                                                                v-if="msg.files.length > 0"
                                                                location="top"
                                                            >
                                                                <template v-slot:activator="{ props }">
                                                                    <img
                                                                        :src="bueroklammerIcon"
                                                                        class="icon20 mx-1"
                                                                        alt="Anhang"
                                                                       
                                                                        v-bind="props"
                                                                    >
                                                                </template>
                                                                <span>Anhang enthalten</span>
                                                            </v-tooltip>
                                                            <img
                                                                :src="downIcon"
                                                                class="icon20 mx-1"
                                                                :style="clicked.active && (clicked.id === msg._id) ? 'transform: rotate(180deg)' : ''"
                                                                :alt="clicked.active && (clicked.id === msg._id) ? 'Nachricht einklappen' : 'Nachricht ausklappen'"
                                                            >

                                                            <v-menu
                                                                :offset="[0,10]"
                                                                location="left"
                                                            >
                                                                <template v-slot:activator="{ props }">
                                                                    <v-btn
                                                                        theme="dark"
                                                                        icon="$vuetify"
                                                                        variant="plain"
                                                                    >
                                                                        <img
                                                                            class="icon20"
                                                                            :src="optionsIcon"
                                                                            alt="Einstellungen"
                                                                           
                                                                            v-bind="props"
                                                                        >
                                                                    </v-btn>
                                                                </template>
                                                                <div>
                                                                    <v-list density="compact">
                                                                        <v-list-item @click="areYouSureArchive(msg._id, msg.hasOwnProperty('accepted'))">
                                                                            <template #prepend>
                                                                                <v-icon
                                                                                    class="icon20 iconToRed"
                                                                                    aria-label="Archivieren"
                                                                                >
                                                                                    mdi-archive-outline
                                                                                </v-icon>
                                                                            </template>
                                                                            <v-list-item-title class="text-red">
                                                                                Archivieren
                                                                            </v-list-item-title>
                                                                        </v-list-item>

                                                                        <v-divider />

                                                                        <v-list-item
                                                                            v-if="accountRole === 'maintainer' || accountRole === 'teacher'"
                                                                            @click="() => { letterToExport = msg; exportType = 'sickNote'; exportLetterDialog = true; }"
                                                                        >
                                                                            <template #prepend>
                                                                                <v-icon
                                                                                    class="icon20 iconToDunkelgrau"
                                                                                    aria-label="Exportieren"
                                                                                >
                                                                                    mdi-file-pdf
                                                                                </v-icon>
                                                                            </template>
                                                                            <v-list-item-title>
                                                                                Exportieren
                                                                            </v-list-item-title>
                                                                        </v-list-item>
                                                                    </v-list>
                                                                </div>
                                                            </v-menu>
                                                        </div>
                                                    </div>
                                                    <div
                                                        v-if="clicked.id !== msg._id"
                                                        class="messageFooter flex-row"
                                                    >
                                                        <v-spacer />
                                                        <div>
                                                            <span>
                                                                {{ dayname[new Date(msg.createdAt).getDay()] }}.
                                                                <span class="mr-2">
                                                                    {{
                                                                        new Date(msg.createdAt).toLocaleDateString('de-DE', {
                                                                            day: '2-digit',
                                                                            month: '2-digit'
                                                                        })
                                                                    }}
                                                                    <span class="hideText"> {{
                                                                        new Date(msg.createdAt).toLocaleTimeString('de-DE', {
                                                                            hour: '2-digit',
                                                                            minute: '2-digit'
                                                                        })
                                                                    }}</span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- incoming on parent -->
                                                <div
                                                    v-else
                                                >
                                                    <v-badge
                                                        v-if="hasMessageUnseenBadge(msg._id)"
                                                        content="1"
                                                        style="width: 100%; position: absolute"
                                                        color="#ff0000"
                                                        offset-y="16"
                                                        location="left"
                                                    />
                                                    <div
                                                        :ref="`speaker${msg._id}`"
                                                        class="showSpeaker"
                                                    >
                                                        <v-btn
                                                            elevation="6"
                                                            absolute
                                                            size="x-small"
                                                            style="height: 30px; width:30px; background-color:white; border-radius: 4px !important; top:-5px; left: 30%;"
                                                            @click="readText(msg, $event)"
                                                        >
                                                            <img
                                                                alt="Vorlesen"
                                                                :src="lautsprecherIcon"
                                                                style="height: 20px;"
                                                            >
                                                        </v-btn>
                                                    </div>
                                                    <div
                                                        class="d-flex messageCardHeader justify-space-between align-center pr-2"
                                                        style="max-width: 100%;"
                                                        @click="handleClickIncomingMessage(msg)"
                                                    >
                                                        <div
                                                            class="d-flex flex-row justify-space-around titleContainer align-center ellipsis;"
                                                            :style="((msg.poll || msg.files.length > 0)&& windowWidth > 900 )? 'max-width: calc(28vw - 148px) !important;':''"
                                                        >
                                                            <Avatar
                                                                class="pa-0 ma-1"
                                                                :size="35"
                                                                :profile="msg.account"
                                                                :show-burger="false"
                                                            />
                                                            <v-tooltip
                                                                location="top"
                                                                z-index="99"
                                                            >
                                                                <template v-slot:activator="{ props }">
                                                                    <div
                                                                        class="d-flex flex-column text-truncate"
                                                                        style="color: black; overflow: hidden;"
                                                                       
                                                                        v-bind="props"
                                                                    >
                                                                        <p
                                                                            class="text-truncate"
                                                                            :style="msg.uploadedIcon || msg.color==='#fcd20a' ? 'color: black' : `color:${msg.color}`"
                                                                            style="font-weight: bold;"
                                                                        >
                                                                            {{ getAccountName(msg.account) }}
                                                                        </p>

                                                                        <p style="color: black; ">
                                                                            {{ msg.title }}
                                                                        </p>
                                                                    </div>
                                                                </template>
                                                                <div :style="windowWidth > 900 ? 'max-width:28vw':'max-width:85vw'">
                                                                    <p>{{ getAccountName(msg.account) }}</p>
                                                                    <p>{{ msg.title }}</p>
                                                                </div>
                                                            </v-tooltip>
                                                        </div>

                                                        <div
                                                            class="d-flex flex-row mt-6"
                                                        >
                                                            <div class="mr-2">
                                                                <v-tooltip location="top">
                                                                    <template v-slot:activator="{ props }">
                                                                        <img
                                                                            :src="msgHeaderIcon(msg).icon" 
                                                                            class="icon20"
                                                                            :class="msgHeaderIcon(msg).class"
                                                                           
                                                                            v-bind="props"
                                                                        >
                                                                    </template>
                                                                    <span
                                                                        v-if="msg.requiresSignature && letterSignedByMe(msg) && accountRole !== 'maintainer' && accountRole !== 'teacher'"
                                                                    >
                                                                        unterschrieben
                                                                    </span>
                                                                    <span
                                                                        v-else-if="msg.requiresSignature && !letterSignedByMe(msg) && accountRole !== 'maintainer' && accountRole !== 'teacher'"
                                                                    >
                                                                        benötigt Unterschrift
                                                                    </span>
                                                                    <span
                                                                        v-else-if="letterSeenByMe(msg)"
                                                                    >
                                                                        gesehen
                                                                    </span>
                                                                    <span
                                                                        v-else
                                                                    >
                                                                        nicht gesehen
                                                                    </span>
                                                                </v-tooltip>
                                                            </div>

                                                            
                                                            <v-tooltip
                                                                v-if="msg.files.length > 0 || msg.poll"
                                                                location="top"
                                                            >
                                                                <template v-slot:activator="{ props }">
                                                                    <img
                                                                        :src="bueroklammerIcon"
                                                                        class="icon20"
                                                                        alt="Anhang"
                                                                       
                                                                        v-bind="props"
                                                                    >
                                                                </template>
                                                                <span>{{ msg.poll ? 'Umfrage enthalten ' :'' }} {{ msg.files.length > 0 ? 'Anhang enthalten':'' }}</span>
                                                            </v-tooltip>
                                                            <!-- <v-tooltip top>
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-icon
                                                                        v-if="msg.poll"
                                                                        height="20"
                                                                        color="#6f6e6e"
                                                                        style="transform: rotate(90deg);"
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                    >
                                                                        mdi-poll
                                                                    </v-icon>
                                                                </template>
                                                                <span>Umfrage enthalten</span>
                                                            </v-tooltip> -->
                                                            <v-tooltip location="top">
                                                                <template v-slot:activator="{ props }">
                                                                    <img
                                                                        v-if="accountRole==='parent'"
                                                                        :src="replyIcon"
                                                                        class="icon20 iconToDunkelgrau mr-1"
                                                                        :alt="'Antworten'"
                                                                       
                                                                        v-bind="props"
                                                                        @click="replyToLetter(msg)"
                                                                    >
                                                                </template>
                                                                <span>Antworten</span>
                                                            </v-tooltip>
                                                            <img
                                                                :src="downIcon"
                                                                class="icon20 ml-1"
                                                                :style="clicked.active && (clicked.id === msg._id) ? 'transform: rotate(180deg)' : ''"
                                                                :alt="clicked.active && (clicked.id === msg._id) ? 'Nachricht einklappen' : 'Nachricht ausklappen'"
                                                            >
                                                        
                                                            
                                                            <v-menu
                                                                v-if="!msg.pupil && accountRole === 'maintainer' || accountRole === 'teacher'"
                                                                :offset="[0,10]"
                                                                location="left"
                                                            >
                                                                <template v-slot:activator="{ props }">
                                                                    <v-btn
                                                                        v-if="!msg.pupil"
                                                                        theme="dark"
                                                                        icon="$vuetify"
                                                                        variant="plain"

                                                                        class="align-baseline"
                                                                        style="margin-bottom: -8px; margin-right: -10px;"
                                                                    >
                                                                        <img
                                                                            class="icon20 "
                                                                            :src="optionsIcon"
                                                                            alt="Einstellungen"
                                                                           
                                                                            v-bind="props"
                                                                        >
                                                                    </v-btn>
                                                                </template>
                                                                <div>
                                                                    <v-list
                                                                        density="compact"
                                                                    >
                                                                        <v-list-item
                                                                            @click="replyToLetter(msg)"
                                                                        >
                                                                            <template #prepend>
                                                                                <img
                                                                                    :src="replyIcon"
                                                                                    class="icon20 iconToDunkelgrau ml-1"
                                                                                    :alt="'Antworten'"
                                                                                >
                                                                            </template>
                                                                            <v-list-item-title>
                                                                                Antworten
                                                                            </v-list-item-title>
                                                                        </v-list-item>
                                                                        <v-divider />
                                                                        <v-list-item
                                                                           
                                                                            @click="() => { letterToExport = msg; exportType='sickNote'; exportLetterDialog = true }"
                                                                        >
                                                                            <template #prepend>
                                                                                <img
                                                                                    :src="pdfIcon"
                                                                                    class="icon20 iconToDunkelgrau"
                                                                                    alt="Exportieren"
                                                                                >
                                                                            </template>
                                                                            <v-list-item-title>Exportieren</v-list-item-title>
                                                                        </v-list-item>
                                                                    </v-list>
                                                                </div>
                                                            </v-menu>
                                                        </div>
                                                    </div>
                                                    <div
                                                        v-if="clicked.id !== msg._id"
                                                        class="messageFooter flex-row "
                                                    >
                                                        <v-spacer />
                                                        <div>
                                                            <span>
                                                                {{ dayname[new Date(msg.createdAt).getDay()] }}.
                                                                <span class="mr-2">
                                                                    {{
                                                                        new Date(msg.createdAt).toLocaleDateString('de-DE', {
                                                                            day: '2-digit',
                                                                            month: '2-digit'
                                                                        })
                                                                    }}
                                                                    <span class="hideText"> {{
                                                                        new Date(msg.createdAt).toLocaleTimeString('de-DE', {
                                                                            hour: '2-digit',
                                                                            minute: '2-digit'
                                                                        })
                                                                    }}</span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="showLetterDialog">
                                                    <!-- PARENT | view/sign letter dialog -->
                                                    <div v-if="clicked.id === msg._id">
                                                        <div
                                                            class="whiteBg mb-2"
                                                            style="border-radius: 0 0 15px 15px;"
                                                        >
                                                            <div
                                                                v-if="accountRole === 'parent'"
                                                            >
                                                                <MessagesLetter
                                                                    class="mb-2"
                                                                    :current-letter="letter"
                                                                    :settings="settings"
                                                                    :is-incoming="true"
                                                                    @export-letter="exportLetterDialog = true"
                                                                    @play-audio="(thumbnail) => { audioUrl = thumbnail }"
                                                                    @open-image="(thumbnail) => { imageUrl = thumbnail }"
                                                                    @open-pdf="(thumbnail) => { pdfUrl = thumbnail }"
                                                                    @play-video="(thumbnail) => { videoUrl = thumbnail }"
                                                                />
                                                                <div class="d-flex flex-column">
                                                                    <!-- signature -->
                                                                    <div
                                                                        v-if="accountRole === 'parent' && letter.requiresSignature"
                                                                        class="d-flex flex-column justify-space-between  pl-1"
                                                                    >
                                                                        <div class="pa-1 d-flex flex-row justify-flex-start align-center">
                                                                            <p class="headlineText">
                                                                                {{ letterSignedByMe(letter)? 'Sie haben diesen Brief bereits unterschrieben.' : 'Bitte unterschreiben' }}
                                                                            </p>
                                                                        </div>
                                                                        <div
                                                                            v-if="!letterSignedByMe(letter)"
                                                                            class="d-flex flex-wrap justify-flex-around"
                                                                        >
                                                                            <div
                                                                                class="d-flex justify-flex-start align-center px-0 mx-2"
                                                                            >
                                                                                <input
                                                                                    id="signatureFileInput"
                                                                                    ref="signatureFileInput"
                                                                                    type="file"
                                                                                    hidden
                                                                                    @change="changeSignatureFileInput"
                                                                                >

                                                                                <img
                                                                                    v-if="signaturePhoto"
                                                                                    id="signaturePicture"
                                                                                    :src="signaturePhoto"
                                                                                    alt="Bild ihrer Unterschrift"
                                                                                >

                                                                                <!-- Signature Component which brings its own button -->

                                                                                <SignatureDialog
                                                                                    :type="'parentalletter'"
                                                                                    @signature-done="signatureDone"
                                                                                />

                                                                                <hr
                                                                                    v-if="!signaturePhoto"
                                                                                    aria-hidden="true"
                                                                                    class="greyLine"
                                                                                >

                                                                                <v-tooltip
                                                                                    location="top"
                                                                                >
                                                                                    <template v-slot:activator="{ props }">
                                                                                        <v-btn
                                                                                            v-if="!signaturePhoto"
                                                                                            class="actionBtn ml-1"
                                                                                            style="width: 87px !important"
                                                                                            size="x-small"
                                                                                            v-bind="props"
                                                                                            @click="clickElement('signatureFileInput')"
                                                                                        >
                                                                                            <img
                                                                                                :src="fileUploadIcon"
                                                                                                class="icon20"
                                                                                                alt="Datei als Antwort hochladen"
                                                                                            >
                                                                                            <span
                                                                                                style="color: black"
                                                                                            >
                                                                                                Hochladen
                                                                                            </span>
                                                                                        </v-btn>
                                                                                    </template>
                                                                                    <span> Unterschrift hochladen </span>
                                                                                </v-tooltip>
                                                                            </div>
                                                                        <!-- <v-spacer /> -->
                                                                        <!-- <v-btn
                                                                            v-if="accountRole === 'parent' & letter.requiresSignature && !letterSignedByMe(letter)"
                                                                            color="fhellgruen"
                                                                            class="justify-flex-end mr-2"
                                                                            :disabled="!signaturePhoto"
                                                                            aria-label="Fertig"
                                                                            style="color: white"
                                                                            @click="sendParentResponse"
                                                                        >
                                                                            Absenden
                                                                        </v-btn> -->
                                                                        </div>

                                                                    <!-- <div
                                                                        v-else
                                                                        class="px-2"
                                                                    >
                                                                        Sie haben diesen Brief bereits unterschrieben.
                                                                    </div> -->
                                                                    </div>

                                                                    <!-- received Date -->
                                                                    <div class="messageFooter flex-row ">
                                                                        <v-spacer />
                                                                        <div>
                                                                            <span>
                                                                                {{
                                                                                    dayname[new Date(msg.createdAt).getDay()]
                                                                                }}.
                                                                                <span class="mr-2">
                                                                                    {{
                                                                                        new Date(msg.createdAt).toLocaleDateString('de-DE', {
                                                                                            day: '2-digit',
                                                                                            month: '2-digit'
                                                                                        })
                                                                                    }}
                                                                                    <span class="hideText"> {{
                                                                                        new Date(msg.createdAt).toLocaleTimeString('de-DE', {
                                                                                            hour: '2-digit',
                                                                                            minute: '2-digit'
                                                                                        })
                                                                                    }}</span>
                                                                                </span>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-else>
                                                                <MessagesLetter
                                                                    v-if="letter && (clicked.active && (clicked.id === msg._id))&& accountRole !== 'parent'"
                                                                    class="mb-2"
                                                                    :current-letter="letter"
                                                                    :settings="settings"
                                                                    :is-incoming="true"
                                                                    @export-letter="exportLetterDialog = true"
                                                                    @play-audio="(thumbnail) => { audioUrl = thumbnail }"
                                                                    @open-image="(thumbnail) => { imageUrl = thumbnail }"
                                                                    @open-pdf="(thumbnail) => { pdfUrl = thumbnail }"
                                                                    @play-video="(thumbnail) => { videoUrl = thumbnail }"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div v-else>
                                                    <div v-if="clicked.id === msg._id">
                                                        <MessagesSickNote
                                                            class="pb-2 mb-2"
                                                            :current-sick-note="currentSickNote"
                                                            :role="accountRole"
                                                            :settings="settings"
                                                            @handle-accept-sick-note="handleAcceptSickNote"
                                                            @handle-decline-sick-note="handleDeclineSickNote"
                                                            @play-audio="(thumbnail) => { audioUrl = thumbnail }"
                                                            @open-image="(thumbnail) => { imageUrl = thumbnail }"
                                                            @open-pdf="(thumbnail) => { pdfUrl = thumbnail }"
                                                            @play-video="(thumbnail) => { videoUrl = thumbnail }"
                                                        />
                                                        <div
                                                            class="messageFooter flex-row"
                                                        >
                                                            <v-spacer />
                                                            <div>
                                                                <span>
                                                                    {{ dayname[new Date(msg.createdAt).getDay()] }}.
                                                                    <span class="mr-2">
                                                                        {{ new Date(msg.createdAt).toLocaleDateString('de-DE', {day: '2-digit', month: '2-digit'}) }}
                                                                        <span class="hideText"> {{ new Date(msg.createdAt).toLocaleTimeString('de-DE', {hour: '2-digit', minute: '2-digit'}) }}</span>
                                                                    </span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </v-card>
                                        </v-lazy>
                                    </div>
                                    <div
                                        v-if="archivedCount > 0"
                                        class="d-flex justify-space-between mx-4 mt-4 pointer"
                                        @click="fetchArchivedMessages"
                                    >
                                        <p
                                            style="font-weight:bold"
                                        >
                                            Archiviert ({{ archivedCount }})
                                        </p>
                                        <div>
                                            <img
                                                :src="dropdownIcon"
                                                style="height: 20px; width:20px;"
                                                :style="showArchivedLetters ? 'transform: rotate(180deg)':''"
                                            >
                                        </div>
                                    </div>
                                    <div
                                        v-if="showArchivedLetters"
                                    >
                                        <div
                                            v-if="showArchivedLoading"
                                            class="d-flex justify-space-around"
                                        >
                                            <div class="d-flex flex-column align-center">
                                                <v-progress-circular
                                                    indeterminate
                                                    color="purple"
                                                />
                                                <p>Archivierte Nachrichten werden geladen</p>
                                            </div>
                                        </div>
                                        <div
                                            v-for="msg in archivedLetters"
                                            :key="msg._id"
                                            class="d-flex flex-column pt-1 pb-1 mx-4"
                                        >
                                            <v-lazy
                                                transition="v-expand-x-transition"
                                                :options="{
                                                    threshold: 0.25
                                                }"
                                            >
                                                <v-card style="border-radius: 8px;">
                                                    <div
                                                        class="messageCardHeader align-center"
                                                        style="height: 48px"
                                                        @click="handleClickIncomingMessage(msg)"
                                                    >
                                                        <div
                                                            class="d-flex flex-row justify-space-around text-truncate"
                                                            style="max-width: 90%;"
                                                        >
                                                            <Avatar
                                                                :profile="msg.author.account"
                                                                :size="35"
                                                                :alt="'Profilbild'"
                                                                class="ma-2 "
                                                            />
                                                            <v-tooltip
                                                                location="top"
                                                                z-index="99"
                                                            >
                                                                <template v-slot:activator="{ props }">
                                                                    <div
                                                                        class="d-flex flex-column text-truncate titleContainer"
                                                                        style="color: black; overflow: hidden;"
                                                                       
                                                                        v-bind="props"
                                                                    >
                                                                        <p
                                                                            v-if="accountRole === 'teacher' || accountRole === 'maintainer'"
                                                                            class="text-truncate"
                                                                            :style="getProfileColor(msg.author.account ? msg.author.account : msg.author)==='#fcd20a' ? 'color: black' : `color:${getProfileColor(msg.author.account ? msg.author.account : msg.author)}`"
                                                                            style="font-weight: bold"
                                                                        >
                                                                            {{
                                                                                msg.pupil.name[0] + '. ' + msg.pupil.lastName + ' → ' + getReceivers(msg.receiver)
                                                                            }}
                                                                        </p>
                                                                        <p>{{ msg.title }}</p>
                                                                    </div>
                                                                </template>
                                                                <div :style="windowWidth > 900 ? 'max-width:28vw':'max-width:85vw'">
                                                                    <p
                                                                        v-if="accountRole === 'teacher' || accountRole === 'maintainer'"
                                                                    >
                                                                        {{
                                                                            msg.pupil.name[0] + '. ' + msg.pupil.lastName + ' → ' + getReceivers(msg.receiver)
                                                                        }}
                                                                    </p>
                                                                    <p>{{ msg.title }}</p>
                                                                </div>
                                                            </v-tooltip>
                                                        </div>
                                                        <v-spacer />
                                                        <div
                                                            class="d-flex flex-row mx-1 mt-6"
                                                        >
                                                            <div class="mr-2">
                                                                <v-tooltip location="top">
                                                                    <template v-slot:activator="{ props }">
                                                                        <img
                                                                            :src="sickNoteHeaderIcon(msg).icon" 
                                                                            class="icon20"
                                                                            :class="sickNoteHeaderIcon(msg).class"
                                                                           
                                                                            v-bind="props"
                                                                        >
                                                                    </template>
                                                                    <span
                                                                        v-if="msg.accepted === true"
                                                                    >
                                                                        angenommen
                                                                    </span>
                                                                    <span
                                                                        v-else-if="msg.accepted === false"
                                                                    >
                                                                        abgelehnt
                                                                    </span>
                                                                    <span
                                                                        v-else
                                                                    >
                                                                        offen
                                                                    </span>
                                                                </v-tooltip>
                                                            </div>
                                                        </div>

                                                        <div
                                                            class="d-flex"
                                                            style="align-items: center;"
                                                        >
                                                            <v-tooltip
                                                                v-if="msg.files.length > 0"
                                                                location="top"
                                                            >
                                                                <template v-slot:activator="{ props }">
                                                                    <img
                                                                        :src="bueroklammerIcon"
                                                                        class="icon20 mx-1"
                                                                        alt="Anhang"
                                                                       
                                                                        v-bind="props"
                                                                    >
                                                                </template>
                                                                <span>Anhang enthalten</span>
                                                            </v-tooltip>
                                                            <img
                                                                :src="downIcon"
                                                                class="icon20 mx-1"
                                                                :style="clicked.active && (clicked.id === msg._id) ? 'transform: rotate(180deg)' : ''"
                                                                :alt="clicked.active && (clicked.id === msg._id) ? 'Nachricht einklappen' : 'Nachricht ausklappen'"
                                                            >

                                                            <v-menu
                                                                :offset="[0,10]"
                                                                location="left"
                                                            >
                                                                <template v-slot:activator="{ props }">
                                                                    <v-btn
                                                                        theme="dark"
                                                                        icon="$vuetify"
                                                                        variant="plain"
                                                                    >
                                                                        <img
                                                                            class="icon20"
                                                                            :src="optionsIcon"
                                                                            alt="Einstellungen"
                                                                           
                                                                            v-bind="props"
                                                                        >
                                                                    </v-btn>
                                                                </template>
                                                                <div>
                                                                    <v-list
                                                                        density="compact"
                                                                    >
                                                                        <v-list-item
                                                                            @click="areYouSureRestore(msg._id)"
                                                                        >
                                                                            <template #prepend>
                                                                                <v-icon>
                                                                                    mdi-archive-outline
                                                                                </v-icon>
                                                                            </template>
                                                                            <v-list-item-title style="color: red">
                                                                                Wiederherstellen
                                                                            </v-list-item-title>
                                                                        </v-list-item>
                                                                        <v-divider />
                                                                        <v-list-item
                                                                            v-if="accountRole === 'maintainer' || accountRole === 'teacher'"
                                                                            @click="() => { letterToExport = msg; exportType='sickNote'; exportLetterDialog = true }"
                                                                        >
                                                                            <template #prepend>
                                                                                <img
                                                                                    :src="pdfIcon"
                                                                                    class="icon20 iconToDunkelgrau"
                                                                                    alt="Exportieren"
                                                                                >
                                                                            </template>
                                                                            <v-list-item-title>Exportieren</v-list-item-title>
                                                                        </v-list-item>
                                                                    </v-list>
                                                                </div>
                                                            </v-menu>
                                                        </div>
                                                    </div>
                                                       
                                           
                                                    <div
                                                        v-if="clicked.id !== msg._id"
                                                        class="messageFooter flex-row"
                                                    >
                                                        <v-spacer />
                                                        <div>
                                                            <span>
                                                                {{ dayname[new Date(msg.createdAt).getDay()] }}.
                                                                <span class="mr-2">
                                                                    {{
                                                                        new Date(msg.createdAt).toLocaleDateString('de-DE', {
                                                                            day: '2-digit',
                                                                            month: '2-digit'
                                                                        })
                                                                    }}
                                                                    <span class="hideText"> {{
                                                                        new Date(msg.createdAt).toLocaleTimeString('de-DE', {
                                                                            hour: '2-digit',
                                                                            minute: '2-digit'
                                                                        })
                                                                    }}</span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div v-if="clicked.id === msg._id">
                                                        <MessagesSickNote
                                                            class="pb-2 mb-2"
                                                            :current-sick-note="currentSickNote"
                                                            :role="accountRole"
                                                            :settings="settings"
                                                            @handle-accept-sick-note="handleAcceptSickNote"
                                                            @handle-decline-sick-note="handleDeclineSickNote"
                                                            @play-audio="(thumbnail) => { audioUrl = thumbnail }"
                                                            @open-image="(thumbnail) => { imageUrl = thumbnail }"
                                                            @open-pdf="(thumbnail) => { pdfUrl = thumbnail }"
                                                            @play-video="(thumbnail) => { videoUrl = thumbnail }"
                                                        />
                                                        <div
                                                            class="messageFooter flex-row"
                                                        >
                                                            <v-spacer />
                                                            <div>
                                                                <span>
                                                                    {{ dayname[new Date(msg.createdAt).getDay()] }}.
                                                                    <span class="mr-2">
                                                                        {{ new Date(msg.createdAt).toLocaleDateString('de-DE', {day: '2-digit', month: '2-digit'}) }}
                                                                        <span class="hideText"> {{ new Date(msg.createdAt).toLocaleTimeString('de-DE', {hour: '2-digit', minute: '2-digit'}) }}</span>
                                                                    </span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </v-card>
                                            </v-lazy>
                                        </div>
                                    </div>
                                </div>
                            </simple-bar>
                        </div>
                    </div>
                    <!--#endregion -->
                </div>
            </v-card>
        </div>

        <!--#region dialogs -->
        <!-- universal confirm dialog -->
        <v-dialog
            v-model="confirmDialog"
            width="400"
        >
            <v-card
                style="overflow: hidden; border-radius: 16px"
            >
                <v-card-title
                    class="my-0 py-1 d-flex align-center justify-space-between"
                    style="height: 56px; color: white; background-color: rgb(var(--v-theme-parentLetterPurple)) "
                >
                    <p
                        tabindex="0"
                        style="width: fit-content"
                    >
                        {{ confirmType[confirmDialogType].title }}
                    </p>
                    <v-btn
                        size="x-small"
                        variant="text"
                        elevation="0"
                        class="pa-0"
                        @click="confirmDialog = false"
                    >
                        <img
                            :src="cancelIcon"
                            class="icon20 iconToWhite"
                            alt="schließen"
                        >
                    </v-btn>
                </v-card-title>

                <v-card-text class="pt-2">
                    <p
                        tabindex="0"
                        style="width: fit-content"
                    >
                        {{ confirmType[confirmDialogType].text }}
                    </p>
                </v-card-text>

                <v-card-actions class="d-flex justify-space-between mb-2">
                    <v-btn
                        class="option"
                        color="#F5F5F5"
                        @click="handleConfirmDialogDecline"
                    >
                        <img
                            :src="cancelIcon"
                            class="icon20 mr-2"
                        >
                        Abbrechen
                    </v-btn>

                    <v-btn
                        v-if="confirmDialogType === 2 || confirmDialogType === 5"
                        class="option"
                        theme="dark"
                        color="red"
                        @click="handleConfirmDialogAccept"
                    >
                        <img
                            :src="deleteIcon"
                            class="icon20 iconToWhite mr-2"
                        >
                        Löschen
                    </v-btn>

                    <v-btn
                        v-else
                        class="option"
                        theme="dark"
                        color="gruen"
                        @click="handleConfirmDialogAccept"
                    >
                        Bestätigen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="closeWidgetDialog"
            width="400"
        >
            <v-card
                style="overflow: hidden; border-radius: 16px"
            >
                <v-card-title
                    class="my-0 py-1 d-flex align-center justify-space-between"
                    style="height: 56px; color: white; background-color: rgb(var(--v-theme-parentLetterPurple)) "
                >
                    <p
                        tabindex="0"
                        style="width: fit-content"
                    >
                        Sind sie Sicher?
                    </p>
                    <v-btn
                        size="x-small"
                        variant="text"
                        elevation="0"
                        class="pa-0"
                        @click="closeWidgetDialog = false"
                    >
                        <img
                            :src="cancelIcon"
                            class="icon20 iconToWhite"
                            alt="schließen"
                        >
                    </v-btn>
                </v-card-title>

                <v-card-text class="pt-2">
                    <p
                        tabindex="0"
                        style="width: fit-content"
                    >
                        Mit Klick auf Bestätigen wird das Widget geschlossen und die PIN muss zum Öffnen erneut eingegeben werden.
                    </p>
                </v-card-text>

                <v-card-actions class="d-flex justify-space-between mb-2">
                    <v-btn
                        class="option"
                        color="#F5F5F5"
                        @click="closeWidgetDialog = false"
                    >
                        <img
                            :src="cancelIcon"
                            class="icon20 mr-2"
                        >
                        Abbrechen
                    </v-btn>



                    <v-btn
                        
                        class="option"
                        theme="dark"
                        color="gruen"
                        @click="closeWidget"
                    >
                        Bestätigen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--#region Preview dialogs -->
        <v-dialog
            v-if="audioUrl"
            :model-value="audioUrl"
            scrim="rgba(0,0,0,0.9)"
            max-height="80vh"
            width="400px"
            max-width="90%"
        >
            <v-card style="max-height: 90vh; overflow: hidden">
                <div class="d-flex justify-space-between align-center ma-2">
                    <span>Audiovorschau</span>
                    <v-icon
                        size="large"
                        @click="audioUrl = false"
                    >
                        fas fa-times
                    </v-icon>
                </div>
                <div class="d-flex justify-center align-center">
                    <audio
                        :src="audioUrl"
                        controls
                        :width="windowWidth > 900 ? '80%' : '40%%'"
                        :style="{ marginLeft: windowWidth > 900 ? '10%' : '10px' }"
                    >Ihr Browser unterstützt das audio-Tag nicht.
                    </audio>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog
            v-if="imageUrl"
            :model-value="imageUrl"
            scrim="rgba(0,0,0,0.9)"
            max-height="80vh"
            width="400px"
            max-width="90%"
        >
            <v-card style="max-height: 90vh; overflow: hidden">
                <div class="d-flex justify-space-between align-center ma-2">
                    <span>Bildvorschau</span>
                    <v-icon
                        size="large"
                        @click="imageUrl = false"
                    >
                        fas fa-times
                    </v-icon>
                </div>
                <div class="d-flex justify-center align-center">
                    <img
                        :src="imageUrl"
                        style="max-width: 100%; max-height: 100%"
                        alt="Bildvorschau"
                    >
                </div>
            </v-card>
        </v-dialog>

        <v-dialog
            v-if="pdfUrl"
            :model-value="pdfUrl"
            scrim="rgba(0,0,0,0.9)"
            height="90vh"
        >
            <v-card style="height: 90vh; overflow: hidden">
                <div class="d-flex align-center justify-space-between ma-2">
                    <span>PDF-Vorschau</span>
                    <v-icon
                        size="large"
                        @click="pdfUrl = false"
                    >
                        fas fa-times
                    </v-icon>
                </div>
                <div style="height: 100%; width: 100%; min-width: 250px; min-height: 400px;">
                    <p
                        v-if="windowWidth < 900"
                        class="ma-2"
                    >
                        PDFs werden auf Mobilgeräten eventuell nicht unterstützt, laden Sie die Datei stattdessen herunter.
                    </p>
                    <object
                        :data="pdfUrl"
                        type="application/pdf"
                        style="height: 100%; width: 100%; min-width: 250px; min-height: 400px;"
                    />
                </div>
            </v-card>
        </v-dialog>

        <!-- Normal video popup -->
        <v-dialog
            v-if="videoUrl"
            :model-value="videoUrl"
            scrim="rgba(0,0,0,0.9)"
            max-width="90%"
            width="unset"
            @click:outside="videoUrl = null; $refs.normalVideo.stop();"
        >
            <v-row
                justify="end"
                align="end"
            >
                <v-col
                    cols="1"
                    align-self="end"
                >
                    <v-icon
                        size="large"
                        theme="dark"
                        @click="videoUrl = null; $refs.normalVideo.stop();"
                    >
                        fas fa-times
                    </v-icon>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="auto">
                    <video-in-video
                        ref="normalVideo"
                        :primary-video-src="videoUrl"
                        :fullscreen-mode="true"
                    />
                </v-col>
            </v-row>
        </v-dialog>
        <!--#endregion -->

        <v-dialog
            v-model="exportLetterDialog"
            width="400"
        >
            <div style="max-height: 90vh">
                <v-card
                    style="border-radius: 16px"
                >
                    <v-card-title
                        class="d-flex align-center justify-space-between"
                        style="height: 56px; background-color: rgb(var(--v-theme-parentLetterPurple))"
                    >
                        <p
                            tabindex="0"
                            style="width: fit-content; color: white"
                        >
                            Elternbrief exportieren
                        </p>
                        <v-btn
                            size="x-small"
                            variant="text"
                            elevation="0"
                            class="pa-0"
                            @click="() => { resetExport(); }"
                        >
                            <img
                                :src="cancelIcon"
                                class="icon20 iconToWhite"
                                alt="schließen"
                            >
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <!--#region receiver -->
                        <div>
                            <p
                                v-if="showSchoolNotice"
                                class="mb-4 text-red"
                            >
                                Die Verwaltung muss die Daten der Schule einmal initial hinterlegen damit der Export funktioinert.
                                Gehen Sie hierzu in das Profil der Verwaltung und füllen den Bereich "Briefkopf" aus.
                            </p>
                            <p
                                v-if="logoError"
                                class="mb-4 text-red"
                            >
                                Die Verwaltung muss das Logo der Schule hochladen.
                            </p>
                            <p
                                v-if="exportType ==='parentalLetter'"
                                class="mb-4"
                            >
                                Füllen Sie die folgenden Felder aus um die Empfänger-Adresse auf den Export drucken zu lassen.
                                Wenn Sie die Felder nicht ausfüllen wird ein Bereich auf dem Export angezeigt, um die Daten per Hand einzutragen.
                            </p>
                            <p
                                v-else-if="exportType ==='sickNote'"
                                class="mb-4"
                            >
                                Füllen Sie die folgenden Felder aus um die Sender-Adresse auf den Export drucken zu lassen.
                                Wenn Sie die Felder nicht ausfüllen wird ein Bereich auf dem Export angezeigt, um die Daten per Hand einzutragen.
                            </p>

                            <p class="headlineText">
                                Adresse
                            </p>
                            <p>{{ this.exportType==='parentalLetter'?'Empfänger':'Absender' }}</p>
                            <v-text-field
                                v-model="receiverName"
                                variant="outlined"
                                density="compact"
                                hide-details
                                class="mb-2"
                                style="max-width: 500px;"
                            />
                            <p>Straße und Nummer</p>
                            <v-text-field
                                v-model="receiverStreet"
                                variant="outlined"
                                density="compact"
                                hide-details
                                class="mb-2"
                                style="max-width: 500px;"
                            />
                            <div
                                class="d-flex"
                                style="min-width: 100%"
                            >
                                <div style="width: 25%">
                                    <p v-if="windowWidth < 500">
                                        PLZ
                                    </p>
                                    <p v-else>
                                        Postleitzahl
                                    </p>
                                    <v-text-field
                                        v-model="receiverPostal"
                                        variant="outlined"
                                        density="compact"
                                        hide-details
                                        class="mb-2"
                                        style="max-width: 500px;"
                                    />
                                </div>

                                <div
                                    class="ml-2"
                                    style="width: 75%"
                                >
                                    <p>Ort</p>
                                    <v-text-field
                                        v-model="receiverCity"
                                        variant="outlined"
                                        density="compact"
                                        hide-details
                                        class="mb-2"
                                        style="max-width: 500px;"
                                    />
                                </div>
                            </div>
                        </div>
                        <!--#endregion-->
                        <hr class="mb-2">
                        <div>
                            <p class="mb-4">
                                Wählen Sie eine Sprache aus um den Brief übersetzen zu lassen.
                                Die Übersetzung ist die letzte Seite des Exports.
                            </p>

                            <p class="headlineText">
                                Übersetzung
                            </p>

                            <v-select
                                v-model="translationLanguage"
                                :items="languages"
                                item-title="text"
                                item-value="lang"
                                label="Sprache"
                                :menu-props="{ bottom: true, offsetY: true }"
                                class="mt-2"
                                hide-details
                                density="compact"
                                variant="outlined"
                            />
                        </div>
                    </v-card-text>
                    <v-card-actions class="d-flex justify-end px-4">
                        <v-btn
                            class="ml-2"
                            theme="dark"
                            @click="() => { resetExport(); }"
                        >
                            Abbrechen
                        </v-btn>

                        <v-btn
                            id="custom-disabled"
                            color="fhellgruen"
                            theme="dark"
                            :disabled="showSchoolNotice"
                            @click="exportLetter(exportType)"
                        >
                            Exportieren
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </div>
        </v-dialog>
        <!--#endregion -->

        <!-- Loading overlay -->
        <v-overlay
            absolute
            :model-value="showLoadingOverlay"
            style="width: 100% !important; z-index: 500 !important;"
        >
            <v-progress-circular
                v-if="showLoadingOverlay"
                indeterminate
                size="100"
            />
        </v-overlay>

        <AreYouSurePopup
            v-if="showAreYouSure"
            v-model:show-dialog="showAreYouSure"
            :header-color="'#95509c'"
            :usage="'letter'"
            :ok-btn-color="okBtnColor"
            :ok-button-text="areYouSureBtn"
            :cancel-btn-color="cancelBtnColor"
            :cancel-button-text="areYouSureCancelBtn"
            :cancel-button-icon="cancelButtonIcon"
            ok-button-icon=""
            :content-text-prop="areYouSureMsg"
            @close="showAreYouSure = false"
            @ok="areYouSureFinish"
            @cancel="showAreYouSure = false"
        />
        <FilePreviewDialog
            v-if="enableFilePreview"
            ref="previewDialog"
            :enable-fullscreen-preview="enableFilePreview"
            :enable-editing="true"
            :files="tempFiles"
            :is-user-author="true"
            :preview-selection="previewSelection"
            :preview-information="previewSelection"
            :preview-selection-index="0"
            :chat-mode="true"
            :thumbnail-url="previewSelection.thumbnail"
            :file-name="previewSelection.uploadedName"
            :disable-editor="true"
            :usage="'messages'"
            @close="resetFilePreview"
            @open-editor="openFileInEditor"
            @open-libre="openFileInLibre"
            @cycle-next="cycleNext"
            @cycle-prev="cyclePrevious"
            @download="downloadAttachment(letter._id, previewSelection)"
        />
        <!-- <BadgeExplanations
            v-if="windowWidth < 900"
            :small="true"
        /> -->
        <PinDialog
            v-model:show-pin-dialog="pinDialog"
            :show-pin-dialog="pinDialog"
            :account="myAcc"
            :usage="'messages'"
            @close="pinDialog = false"
            @account-pin-valid="()=>{pinDialog = false}"
        />
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";

import {fill, PageSizes, PDFDocument, rgb, StandardFonts} from "pdf-lib";
import {textToSpeech} from "@/util/textToSpeech";
import sanitizeHtml from '../util/sanitizeHtml';
import EventBus from "../util/eventBus";
import pdfjs from "@/util/pdfjs";
import fontkit from "@pdf-lib/fontkit";
import roboto from '@/assets/Fonts/Roboto-Regular.ttf'
import robotoBold from '@/assets/Fonts/Roboto-Bold.ttf'
import noto from '@/assets/Fonts/NotoSansArabic-Regular.ttf'
import notoBold from '@/assets/Fonts/NotoSansArabic-Bold.ttf'

import AdvancedQuillEditor from "./Utils/AdvancedQuillEditor";
import ProgressBar from "@/components/ProgressBar";
import NameFile from "@/components/NameFile";
import VideoInVideo from "@/components/Utils/VideoInVideo";
import Poll from "@/components/Poll";
import SignatureDialog from "./SignatureDialog";
import MessagesLetter from "@/components/MessagesLetter";
import MessagesSickNote from "@/components/MessagesSickNote";
import ImageCapture from "@/components/Utils/ImageCapture";
import IconPicker from"@/components/Utils/IconPicker";
import ClickableBadge from "@/components/Utils/ClickableBadge";
import FilePreviewDialog from "@/components/Utils/FilePreviewDialog"
import AreYouSurePopup from './Utils/AreYouSurePopup';
import Avatar from "@/components/SharedViews/Profile/Avatar";

import lautsprecherIcon from "../assets/Icons/lautsprecher-13.svg";
import lautsprecherAusIcon from "../assets/Icons/lautsprecher-aus-89.svg";
import mailIcon from '@/assets/Icons/letter.svg';
import infoCircleIcon from '@/assets/Icons/FaceliftIcons/info_hinweis.svg';
import downloadIcon from "@/assets/Icons/runterladen-14.svg";
import cancelIcon from '@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg';
import offenIcon from '@/assets/Icons/FaceliftIcons/offen.svg';
import searchIcon from '@/assets/Icons/suche-26.svg';
import fileUploadIcon from '@/assets/Icons/hochladen-15.svg';
import pupilIcon from '@/assets/Icons/pupil.svg';
import pdfIcon from '@/assets/Icons/PDF-65.svg';
import deleteIcon from '@/assets/Icons/FaceliftIcons/loeschen_muelleimer.svg';
import playIcon from "@/assets/Icons/Play-114.svg";
import unterschriftIcon from "@/assets/Icons/unterschrift-113.svg";
import plusIcon from "@/assets/Icons/plus.svg";
import backIcon from "@/assets/Icons/FaceliftIcons/zurueck_pfeil.svg";
import cameraIcon from "@/assets/Icons/FaceliftIcons/kamera.svg";
import downIcon from "@/assets/Icons/FaceliftIcons/dropdown_mehr_unten_pfeil.svg";
import bueroklammerIcon from "@/assets/Icons/FaceliftIcons/anhang_bueroklammer.svg"
import optionsIcon from "@/assets/Icons/FaceliftIcons/menu_mehr_weiteres_setup.svg";
import bearbeitenIcon from "@/assets/Icons/FaceliftIcons/stift_bearbeiten_.svg";
import dropdownIcon from "@/assets/Icons/FaceliftIcons/dropdown_mehr_unten_pfeil.svg";
import sonneIcon from "../assets/Icons/FaceliftIcons/sonne_weiss.svg";
import erdkundeIcon from '../assets/Icons/FaceliftIcons/f-erdkunde-weiss.svg';
import religionIcon from '../assets/Icons/FaceliftIcons/f-religion-weiss.svg';
import politikIcon from '../assets/Icons/FaceliftIcons/f-politik-weiss.svg';
import bioIcon from '../assets/Icons/FaceliftIcons/f-biologie-weiss.svg';
import englischIcon from "../assets/Icons/FaceliftIcons/f-englisch.svg";
import englischIconWeiss from "../assets/Icons/FaceliftIcons/f-englisch-weiss.svg";
import sendenIcon from "../assets/Icons/FaceliftIcons/absenden_versenden_flugzeug.svg";
import paperclipIcon from "@/assets/Icons/FaceliftIcons/anhang_bueroklammer.svg";
import eingangIcon from "../assets/Icons/FaceliftIcons/elternbriefe_eingang.svg";
import ausgangIcon from "../assets/Icons/FaceliftIcons/elternbriefe_ausgang.svg";
import checkIcon from "@/assets/Icons/FaceliftIcons/haeckehn_versendet.svg";
import checkAllIcon from "@/assets/Icons/FaceliftIcons/haeckehn_gesehen.svg";
import keineElternbriefeIcon from "@/assets/Icons/FaceliftIcons/keine_elternbriefe.svg";
import uhrIcon from "@/assets/Icons/timetable.svg";
import videoIcon from '@/assets/Icons/video.svg';
import kalenderIcon from "../assets/Icons/FaceliftIcons/kalender_datum_zeit.svg";
import deletedAvatar from "@/assets/Icons/FaceliftIcons/nutzer_schueler_default.svg";
import lockIcon from '@/assets/Icons/lock-closed.svg';
import replyIcon from '@/assets/Icons/reply-google-font.svg';
import groupIcon from '@/assets/Icons/group.svg';

import * as backend from "../api/backend";
import {isFileThumbnailCompatibleExtension} from "../util/MimetypeHelper";
import img_eklaraLogo from '@/assets/Icons/FaceliftIcons/datei_blatt.svg';

import BadgeExplanations from './Utils/BadgeExplanations.vue';
import PinDialog from '@/components/PinDialog.vue';
import urlify from '../util/urlify';

export default {
    name: "ElternbriefWidget",
    components: {
        // ImageCapture,
        ClickableBadge,
        AdvancedQuillEditor,
        ProgressBar,
        NameFile,
        VideoInVideo,
        Poll,
        SignatureDialog,
        MessagesLetter,
        MessagesSickNote,
        FilePreviewDialog,
        AreYouSurePopup,
        Avatar,
        //BadgeExplanations,
        PinDialog,
    },
    props: {
        childId: { required: false },
        reset: { required:false, type: Boolean},
    },
emits: ['messagesMounted', 'clearAllBadges', 'messagesRemounted'],
    data: () => ({
        //#region icons
        isFileThumbnailCompatibleExtension,
        
        groupIcon,
        replyIcon,
        lockIcon,
        img_eklaraLogo,
        lautsprecherIcon,
        lautsprecherAusIcon,
        mailIcon,
        infoCircleIcon,
        downloadIcon,
        cancelIcon,
        offenIcon,
        searchIcon,
        fileUploadIcon,
        pupilIcon,
        pdfIcon,
        deleteIcon,
        playIcon,
        unterschriftIcon,
        plusIcon,
        backIcon,
        cameraIcon,
        downIcon,
        bueroklammerIcon,
        optionsIcon,
        bearbeitenIcon,
        sendenIcon,
        paperclipIcon,
        dropdownIcon,
        eingangIcon,
        ausgangIcon,
        checkIcon,
        checkAllIcon,
        keineElternbriefeIcon,
        videoIcon,
        uhrIcon,
        kalenderIcon,
        deletedAvatar,
        //#endregion

        roboto,
        robotoBold,
        noto,
        notoBold,

        sanitizeHtml,

        pinDialog:false,
        // accountPinIsValid: false,

        previewSelection: null,
        previewSelectionIndex: 0,
        enableFilePreview: false,
        tempFiles: [],

        receiversFetched: false,
        showLoadingOverlay: false,
        settings: {
            suppressScrollY: false,
            suppressScrollX: true,
            wheelPropagation: false,
        },
        vSelectMenuProps: { bottom: true, offsetY: true },  // use this to have the v-select dropdown underneath
        showAttachmentUploadProgress: false,
        attachmentUploadProgress: 0.0,
        showNameFile: false,
        fileNameCallback: () => {},
        originalFileName: '',

        showLetterDialog: false,            // opens letter for parent to sign
        letter: '',
        letterIcon:'sonneIcon',
        letterUploadedIcon: false,
        letterColor: '',

        // universal confirm dialog
        confirmDialog: false,
        confirmDialogType: 0, // 0: discard sickNote changes, 1: discard letter changes, 2: delete letter, 3: send letter, 4: edit letter, 5: export letter
        confirmType: [
            { type: 0, title: 'Änderungen verwerfen?', text: 'Möchten Sie die Änderungen an der Entschuldigung verwerfen?' },
            { type: 1, title: 'Änderungen verwerfen?', text: 'Möchten Sie die Änderungen am Elternbrief verwerfen?' },
            { type: 2, title: 'Brief löschen?', text: 'Möchten Sie den Elternbrief wirklich löschen? Dadurch wird er auch beim Empfänger gelöscht und kann nicht mehr aufgerufen werden.' },
            { type: 3, title: 'Abschicken bestätigen', text: 'Möchten Sie den Elternbrief wirklich abschicken?' },
            { type: 4, title: 'Bearbeitung bestätigen', text: 'Wenn Sie den Elternbrief bearbeiten werden die vorhandenen Unterschriften gelöscht und der Brief erneut an die Empfänger versendet. Sind Sie sicher, dass Sie den aktualisierten Brief neu versenden wollen?' },
            { type: 5, title: 'Sind Sie sicher?', text: 'Möchten sie die Datei endgültig löschen?' },
            { type: 6, title: 'Abschicken bestätigen', text: 'Möchten Sie die Antwort abschicken?' },
        ],

        exportType: '',
        exportLetterDialog: false,
        showSchoolNotice: false,
        logoError: false,
        translationLanguage: '',
        languages: [
            // keep this as first element because of the sorting
            { text: 'keine Übersetzung', lang: 'none' },
            { text: 'Englisch', lang: 'en' }, { text: 'Russisch', lang: 'ru' }, { text: 'Französisch', lang: 'fr' },
            { text: 'Türkisch', lang: 'tr' }, { text: 'Rumänisch', lang: 'ro' }, { text: 'Italienisch', lang: 'it' },
            { text: 'Arabisch', lang: 'ar' }, /*{ text: 'Kurdisch', lang: 'ku' }, */
        ],
        receiverName: '',
        receiverStreet: '',
        receiverPostal: '',
        receiverCity: '',

        //#region sickNote dialog
        createSickNoteDialog: false,
        childTeachers: [],
        receiverAccountsParents: [],
        children: [],
        sickNoteReceivers: [],
        sickNotePupil: '',
        sickPupilName: '(Name)',
        sickReasons: [
            { id: '0', text: 'Abwesenheit', description: 'Sehr (geehrte/r Frau/Herr Nachname), \n' +
                    'mein Kind (Name) ist für den ' +
                    'Zeitraum vom (Startdatum) bis (Enddatum) von (Startuhrzeit) bis (Enduhrzeit) als abwesend gemeldet. ' +
                    'Hiermit bitte ich das Fehlen zu entschuldigen. \n \n' +
                    'Mit freundlichen Grüßen, \n' +
                    '(Ihr Name), (Datum)' },
            { id: '1', text: 'Befreiung Schulsport', description: 'Sehr (geehrte/r Frau/Herr Nachname), \n' +
                    'mein Kind (Name) kann für den ' +
                    'Zeitraum vom (Startdatum) bis (Enddatum) nicht am Schulsport teilnehmen.\n \n' +
                    'Mit freundlichen Grüßen, \n' +
                    '(Ihr Name), (Datum)' },
            { id: '2', text: 'Beurlaubung', description: 'Sehr (geehrte/r Frau/Herr Nachname), \n' +
                    'hiermit möchte ich mein Kind (Name) für den ' +
                    'Zeitraum vom (Startdatum) bis (Enddatum) von (Startuhrzeit) bis (Enduhrzeit) ' +
                    'beurlauben. ' +
                    'Den verpassten Unterrichtsstoff werden wir mit (ihm/ihr) nachholen. \n' +
                    'Ich bitte hiermit vielmals um Verständnis. \n \n' +
                    'Mit freundlichen Grüßen, \n' +
                    '(Ihr Name), (Ort) den (Datum)' },
            { id: '3', text: 'Krankheit', description: 'Sehr (geehrte/r Frau/Herr Nachname), \n' +
                    'mein Kind (Name) kann im' +
                    'Zeitraum vom (Startdatum) bis (Enddatum) von (Startuhrzeit) bis (Enduhrzeit) \n' + 
                    'krankheitsbedingt nicht am Unterricht teilnehmen. ' +
                    'Hiermit bitte ich das Fehlen zu entschuldigen. \n \n' +
                    'Mit freundlichen Grüßen, \n' +
                    '(Ihr Name), (Datum)' },
             { id: '4', text: 'Feiertag', description: 'Sehr (geehrte/r Frau/Herr Nachname), \n' +
                    'mein Kind (Name) kann im' +
                    'Zeitraum vom (Startdatum) bis (Enddatum) von (Startuhrzeit) bis (Enduhrzeit) \n' + 
                    'aufgrund eines Feiertages nicht am Unterricht teilnehmen. ' +
                    'Hiermit bitte ich das Fehlen zu entschuldigen. \n \n' +
                    'Mit freundlichen Grüßen, \n' +
                    '(Ihr Name), (Datum)' },
            { id: '5', text: 'Kur', description: 'Sehr (geehrte/r Frau/Herr Nachname), \n' +
                    'mein Kind (Name) kann im' +
                    'Zeitraum vom (Startdatum) bis (Enddatum) von (Startuhrzeit) bis (Enduhrzeit) \n' + 
                    'aufgrund einer Kur nicht am Unterricht teilnehmen. ' +
                    'Hiermit bitte ich das Fehlen zu entschuldigen. \n \n' +
                    'Mit freundlichen Grüßen, \n' +
                    '(Ihr Name), (Datum)' },
            { id: '6', text: 'Trauerfall', description: 'Sehr (geehrte/r Frau/Herr Nachname), \n' +
                    'mein Kind (Name) kann im' +
                    'Zeitraum vom (Startdatum) bis (Enddatum) von (Startuhrzeit) bis (Enduhrzeit) \n' + 
                    'aufgrund eines Trauerfalls in der Familie nicht am Unterricht teilnehmen. ' +
                    'Hiermit bitte ich das Fehlen zu entschuldigen. \n \n' +
                    'Mit freundlichen Grüßen, \n' +
                    '(Ihr Name), (Datum)' },
            { id: '7', text: 'Sonstiges', description: 'Sehr (geehrte/r Frau/Herr Nachname), \n' +
                    '(Entschuldigung) \n \n' +
                    'Mit freundlichen Grüßen, \n' +
                    '(Ihr Name), (Datum)' },
        ],
        teacherCreateSickText: 'Liebe Eltern,  <br>' +
                    'ihr Kind (Name) hat im Unterricht  <br>' +
                    'vom (Startdatum) bis (Enddatum) von (Startuhrzeit) bis (Enduhrzeit)  <br>' + 
                    'unentschuldigt gefehlt.  <br>' +
                    'Dafür benötige ich von Ihnen noch eine unterschriebene Entschuldigung.  <br>' +
                    'Mit freundlichen Grüßen,  <br><br>' +
                    '(Lehrername), (Datum)',
        icons:[
            {icon: englischIcon, text: "englischIcon"},
            {icon: sonneIcon, text: "sonneIcon"},
            {icon: englischIconWeiss, text: "englischIconWeiss"},
            {icon: politikIcon, text: "politikIcon"},
            {icon: erdkundeIcon, text: "erdkundeIcon"},
            {icon: religionIcon, text: "religionIcon"},
            {icon: bioIcon, text: "bioIcon"},
        ],
        clicked:'',
        loggedInName:'',
        parentAccount: null,
        sickReasonIndex: '0',
        sickReason: '',
        sickFrom: '',
        sickTill: '',
        sickFromTime: '08:00',
        sickTillTime: '08:45',
        addTime: false,
        startTimeMenu: false,
        endTimeMenu: false,
        dateFrom: '',
        dateTill: '',
        sickNote: '',
        sickNoteFiles: [],
        signatureXmlHttpRequest: null,
        currentSickNote: null,              // which sickNote you're currently looking at, if looking at one
        allSentSickNotes: {},
        allIncomingSickNotes: {},
        sickNoteSignature: null,
        //#endregion

        messageCategory: 0,                  // 1 = sent, 0 = incoming, 2 = archive
        sentMessages: [],
        incomingMessages: [],

        // message categories for TEACHER, TODO: use categories above when redesigning TEACHER widget
        archivedLetters: [],                // letters signed by all receivers
        showArchivedLetters: false,
        showArchivedLoading: false,

        possibleReceivers: [],              // list of all groups the teacher leads
        searchTreeView: '',                 // used for searching the possible receivers in the treeview

        createLetterDialog: false,
        receiverAccounts: [],               // list with IDs of all the selected receivers
        title: '',
        body: '',
        attachedFiles: [],
        requiresSignature: false,

        editLetter: null,                   // true if you edit an existing letter
        filesFromOriginalLetter: [],        // for handling deleting when editing letter
        fileToBeDeleted: null,

        editTitle: null,
        editBody: null,
        containsPoll: null,
        currentLetter: null,                // which letter you're currently looking at, if looking at one
        // letterSignedByMe: null,
        signedCurrentLetter: [],            // who signed the letter you're currently looking at
        notSignedCurrentLetter: [],         // who didn't sign current letter
        // seenCurrentLetter: [],
        archivedLetter: null,               // true if looking at groups, otherwise references which archived letter to show
        selectReceiver: null,
        signaturePhoto: null,               // holds the photo for a signature response of parent
        letterToDelete: {                   // which letter to delete, because I need two params and a confirm dialog
            letterToDel: null,
            list: null                      // true means the letter is in archived- (currently not implemented), false it's in sentMessages list
        },
        letterToExport: null,               // letter to be exported

        pollValid: false,                   // to disable send letter btn until poll is valid

        signatureFile: [],                  // holds file to send as letter response or sick Note signature
        fileXmlHttpRequest: null,
        signatureDisplay: null,

        filesToUpload: [],
        anhang: false,

        audioUrl: null,
        imageUrl: null,
        pdfUrl: null,
        videoUrl: null,

        dayname: [ 'So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa' ],

        // Drag and drop functionality stuff
        dropUiActive: false,
        dropzone: false,
        toggleCamera: false,

        myAcc: null,
        school: null,
        meParent: null,

        timeslots: [],

        // AreYouSurePopup
        showAreYouSure: false,
        areYouSureMsg: '',
        areYouSureBtn: '',
        areYouSureCancelBtn: '',
        cancelButtonIcon: '',
        okBtnColor: 'rgb(var(--v-theme-parentLetterPurple))',
        cancelBtnColor: 'error',
        popupSickNote: null,
        popupArchive: true, // Archive or restore
        changeSickText: false,

        archivedCount: null,
        archivedNeedsUpdate: true,
        t0: null,
        showIncomingOverlay: false,
        showOutgoingOverlay: false,
        closeWidgetDialog: false,
        teacherCreateSick: false,

        searchMessagesWidget: '',

        urlify,
        replyLetterDialog:false,
        letterToReply: null,
        replyCollapsed: true,
        visibleForAll: false,
    }),
    computed: {
        ...mapState('translation', [ 'targetLang' ]),
        ...mapState('util', ['windowWidth', 'windowHeight']),
        ...mapGetters('auth', [ 'accountId', 'accountRole','accountPinValid','messagesPinActivated','diaryPinActivated' ]),
        ...mapGetters("accounts", ["accountsById"]),
        ...mapState('tooltips', ['showTooltips']),
        ...mapGetters('parentalLetter', [ 'isUpdatePossible' ]),
        ...mapGetters("teachers", {'vuexTeachers': 'teachers'}),
        ...mapGetters("pupils", {'vuexPupils': 'pupils', 'pupilsById': 'pupilsById', 'pupilsByAccountId': 'pupilsByAccountId'}),
        ...mapGetters("maintainers", {'vuexMaintainers': 'maintainers'}),
        ...mapGetters("sickNote", ["signaturesById"]),
        ...mapGetters('parents', ['parentsById', 'parentsByAccountId']),
        ...mapGetters('subjects', ['subjects']),
        ...mapGetters("appointments",["appointments"]),
        ...mapGetters("groups", ['groupsById']),
        showWidgetHeader(){
            return !this.createLetterDialog && !this.createSickNoteDialog && !this.replyLetterDialog;
        },
        usePin(){
            if (this.accountRole ==='teacher'|| this.accountRole === 'parent') {
                return this.messagesPinActivated;
                
            } else if (this.accountRole === 'maintainer'){
                return this.diaryPinActivated;
            }

            return false;
        },
        usageAgreementsInSelectedGroups(){
            if(this.letter.receivingAccounts.length > 0 || (this.receiverAccounts.length > 0 && !this.receiverAccounts.includes(undefined))){
                const allParticipants = this.letter.receivingAccounts.filter(account => account.displayName !== 'Verwaltung')
                const parentAccounts = allParticipants.map(participant => {
                    if(participant.parent){
                        const parent = this.parentsById[participant.parent]
                        if(parent){
                            const parentAccount = this.accountsById[parent.account];
                            return parentAccount
                        }
                    }
                
                });
                
                return parentAccounts;
            }
            return [];
        },
        widgetTitle(){
            //currently disabled, may be reverted later on
            // return this.accountRole === 'parent' && this.messageCategory === 0 || 
            //         this.accountRole === 'teacher' && this.messageCategory === 1 || 
            //         this.accountRole === 'maintainer'? 'Elternbriefe' : 'Krankmeldungen';
            return 'Elternbriefe suchen ...';
        },
        incomingMessageEvents() {
            let msg;
            let eventList = [];
            if (this.incomingMessages) {
                for (let i = 0; i < this.incomingMessages.length; i++) {
                        msg = this.incomingMessages[i];
                         const event = {
                        category: 'message',
                        item: msg._id
                    };
                    let isUnseen = false;
                    if (this.accountRole === 'maintainer' && msg.author && msg.accepted == null) {
                        isUnseen = true;
                    }
                    if (this.accountRole === 'maintainer' && msg.account) {
                        let seenByMe = true;
                        seenByMe = msg.seenBy.some((account) => account === this.myAcc._id);
                        if (!seenByMe) {
                             isUnseen = true;
                        }
                    }
                    if (this.accountRole === 'teacher') {
                        if (msg.account) {
                            // parental letter
                            let seenByMe = true;
                            seenByMe = msg.seenBy.some((account) => account === this.myAcc._id);
                            if (!seenByMe) {
                                 isUnseen = true;
                            }
                        } else {
                            // sick note
                            if (msg.accepted == null) {
                                 isUnseen = true;
                            }
                        }
                    }
                    if (this.accountRole === 'parent'
                        && ((msg.requiresSignature
                                && !msg.parentalLetterResponses.some((msg) => msg.account === this.childId))
                            || (!msg.requiresSignature && !msg.seenBy?.includes(this.childId)))
                    ) {
                        isUnseen = true;
                    }
                    if(msg.account && msg.parentalLetterReplies){
                        msg.parentalLetterReplies.forEach((reply) => {
                        if(reply.account !== this.myAcc._id && !reply.seenBy.includes(this.myAcc._id)){
                            isUnseen = true;
                        }
                        });
                    }

                    if (msg.requiresSignature){
                        if (msg.parentalLetterResponses && !msg.parentalLetterResponses.some((msg) => msg.account === this.childId)){

                            isUnseen = true;
                        }

                    } 
                    if(isUnseen){
                        eventList.push(event)
                    }
                }
            }
            return eventList;
        },
        sentMessageEvents(){
            let eventList = [];
            if(this.sentMessages.length){
                this.sentMessages.map((msg) => {
                    const event = {
                        category: 'message',
                        item: msg._id
                    };
                
                    if ((this.accountRole === 'maintainer' || this.accountRole === 'teacher') && msg.account && msg.parentalLetterReplies) {
                        let seenByMe = true;
                        if(msg.parentalLetterReplies){
                          msg.parentalLetterReplies.forEach((reply) => {
                            if(reply.account !== this.myAcc._id && !reply.seenBy.includes(this.myAcc._id)){
                                seenByMe = false;
                            }
                            });
                        }
                        if (!seenByMe) {
                            eventList.push(event);
                        }
                    }
                });
            }
            return eventList;
        },
        badgeEventList() {
            const eventList = [];
            if (this.incomingMessages.length) {
                this.incomingMessages.map((msg) => {
                    const event = {
                        category: 'message',
                        item: msg._id
                    };
                    if (this.accountRole === 'maintainer' && msg.author && msg.accepted == null) {
                        eventList.push(event);
                    }
                    if (this.accountRole === 'maintainer' && msg.account) {
                        let seenByMe = true;
                        seenByMe = msg.seenBy.some((account) => account === this.myAcc._id);
                        if (!seenByMe) {
                            eventList.push(event);
                        }
                    }
                    if (this.accountRole === 'teacher') {
                        if (msg.account) {
                            // parental letter
                            let seenByMe = true;
                            seenByMe = msg.seenBy.some((account) => account === this.myAcc._id);
                            if (!seenByMe) {
                                eventList.push(event);
                            }
                        } else {
                            // sick note
                            if (msg.accepted == null) {
                                eventList.push(event);
                            }
                        }
                    }
                    if (this.accountRole === 'parent'
                        && ((msg.requiresSignature
                                && !msg.parentalLetterResponses.some((msg) => msg.account === this.childId))
                            || (!msg.requiresSignature && !msg.seenBy?.includes(this.childId)))
                    ) {
                        eventList.push(event);
                    }
                });
            }
            // for(let i = 0; i < eventList.length; i++) {
            //     this.$emit('badge');
            // }
            return eventList;
        },
        dragDropUploadAllowed() {
            return this.createLetterDialog && this.anhang;
        },
        onlyMaintainerSelected() {
            if (this.receiverAccounts.length === 1) {
                const acc = this.accountsById[this.receiverAccounts[0].account];
                if (!acc) {
                    return false;
                }
                if (acc.role === 'maintainer'){
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
        teachers() {
            return this.vuexTeachers;
        },
        pupils() {
            return this.vuexPupils;
        },
        maintainer() {
            return this.vuexMaintainers[0];
        },
        isDesktopSize() {
            //this is stupid, since there are also tablets with those measures
            // height on my 1080p screen is about 762px - Jan
            return this.windowWidth > 900 && this.windowHeight > 700
        },
        widgetInfo() {
            if (this.accountRole === 'teacher') {
                return 'Hier können Sie Ihre gesendeten und erhaltenen Mitteilungen ansehen. Sie können neue Elternbriefe erstellen.';
            } else if (this.accountRole === 'parent') {
                return 'Hier können Sie Ihre gesendeten und erhaltenen Mitteilungen ansehen. Sie können neue Entschuldigungen erstellen.';
            } else {
                return 'Hier können Sie Ihre gesendeten und erhaltenen Mitteilungen ansehen. Sie können neue Elternbriefe erstellen.';
            }
        },
        pupilsFromTeacher(){
            if(this.accountRole === 'teacher' || this.accountRole === 'maintainer'){
                //filter out maintainer as reciever for this
                const groups = this.possibleReceivers.filter(group => !(group.participants === undefined));
                const pupils = groups.map(group => group.participants).flat();
                return pupils;
            }
            return [];
        },
        searchIncomingMessages(){
            if(this.searchMessagesWidget !== ''){
                return this.incomingMessages.filter((message) => (message.title && message.title.toLowerCase().includes(this.searchMessagesWidget.toLowerCase())) ||
                 (message.content.toLowerCase().includes(this.searchMessagesWidget.toLowerCase())));
            }
            return this.incomingMessages;
        },
        searchSentMessages(){
            if(this.searchMessagesWidget !== ''){
                return this.sentMessages.filter((message) => (message.title && message.title.toLowerCase().includes(this.searchMessagesWidget.toLowerCase())) ||
                 (message.content.toLowerCase().includes(this.searchMessagesWidget.toLowerCase())));
            }
            return this.sentMessages;
        },

    },
    watch: {
          reset(newVal, oldVal){
            if(newVal && newVal === true ){
                this.resetExport();
                this.confirmDialog = false;
                this.letterToDelete.letterToDel = null;
                this.letterToDelete.list = null;
                this.clearInputs();
                this.resetCreateLetter();
                this.showArchivedLetters = false;
                this.messageCategory= 0;
                 const element = this.$refs[`index_${0}`][0];
                    if(element){
                         element.scrollIntoView();
                    }
                this.$emit('messagesRemounted');
            }
        },
        incomingMessageEvents() {
                this.setSickNoteBadge(this.incomingMessageEvents.length + this.sentMessageEvents.length);
                this.setParentalLetterBadge(this.incomingMessageEvents.length + this.sentMessageEvents.length);
        },
        sentMessageEvents() {
                this.setSickNoteBadge(this.incomingMessageEvents.length + this.sentMessageEvents.length);
                this.setParentalLetterBadge(this.incomingMessageEvents.length + this.sentMessageEvents.length);
        },
        async exportLetterDialog() {
            if (this.exportLetterDialog) {
                this.school = await this.getSchool();
                this.school === 404 ? this.showSchoolNotice = true : this.showSchoolNotice = false;
                if(this.school){
                    this.school.logo ? this.logoError = false : this.logoError = true;
                }
                // remove the no translation option before sorting, then add it at start of array again
                let noTranslation = this.languages.shift();
                this.languages.sort((a, b) => a.text.localeCompare(b.text));
                this.languages.unshift(noTranslation);
                this.translationLanguage = this.languages[0].lang;
            }
        },
        exportType(newVal){
            if(newVal === 'sickNote'){
                const pupilAcc = this.pupilsById[this.parentsById[this.letterToExport.author._id].pupils[0]];
                if(pupilAcc){
                    this.receiverName = `Eltern von ${pupilAcc.name[0]} ${pupilAcc.lastName}`
                }
            }
        },
        async receiverAccounts(newVal, oldVal){
            if(!this.teacherCreateSick){
                this.displayReceiverInfo();
            }else if(this.teacherCreateSick && newVal){
                    this.title = `Abwesenheit ${newVal.displayName}`;
                    this.fillTeacherSickNoteFields();
            }
        },
        archivedLetters(newVal){
            this.archivedCount = newVal.length;
        }, 
        async childId(newVal, oldVal){
            if(newVal && newVal !== oldVal){
                this.$emit('clearAllBadges');
                await this.getMessages();
                this.initializeEventBus();
                this.timeslots = await this.getSlots();
            }
        },
        sickFrom(newVal, oldVal){
            if((newVal !== oldVal) && typeof newVal === 'object'){
                this.areYouSureChangeSickText();
            }
        },
         sickTill(newVal, oldVal){
            if((newVal !== oldVal) && typeof newVal === 'object'){
                this.areYouSureChangeSickText();
            }
        },
        sickTillTime(newVal, oldVal){
            if(newVal !== oldVal) {
                            this.areYouSureChangeSickText();
                        }
        },
        sickFromTime(newVal,oldVal){
            if(newVal !== oldVal){
                            this.areYouSureChangeSickText();
                        }
        },
        replyLetterDialog(newVal){
            if(newVal && newVal === false){
                this.title = '';
                this.body  = '';
            }
        },
        showWidgetHeader(newVal,oldVal){
            console.log('showWidgetHeader',newVal,oldVal)
        }
    },
    async beforeMount () {
       this.t0 = performance.now();

        this.myAcc = await this.getCurrentAccount();
        // if(this.usePin){
        //     const hasPinRes = await backend.accountHasPin(this.myAcc._id);
        //                 if(hasPinRes.status === 200){
        //                     this.accountHasPin = true;
        //                 }
        // }
        //this is done to help remove unnecessary loading of old messages
        //   if (this.accountRole === 'teacher' || this.accountRole === 'maintainer') {
        //     const res = await backend.autoArchiveSickNotes();
        //   }
        // this.getTeachers();
        // this.getPupils();
        // this.getMaintainers();

    },
    async mounted() {
        this.$emit('clearAllBadges');
        EventBus.off('pollCreated');
        EventBus.on('pollCreated', (pollId) => {this.confirmSendLetter(true, pollId)});


        if (this.accountRole === 'teacher' || this.accountRole === 'maintainer') {
            await this.getArchivedCount();
        }
        const t5 = performance.now();

        await this.getMessages();
        const t6 = performance.now();
        console.debug(`this.getMessagesPerformance ${t6-t5}`)

        this.getLoggedInName();

        this.initializeEventBus();
        this.timeslots = await this.getSlots();
        const t1 = performance.now();
        console.debug(`MessagesMounted in: ${t1 - this.t0} ms.`);
        this.$emit('messagesMounted');
        
    },
    beforeUnmount() {
        URL.revokeObjectURL(this.audioUrl);
        URL.revokeObjectURL(this.imageUrl);
        URL.revokeObjectURL(this.pdfUrl);
        URL.revokeObjectURL(this.videoUrl);
    },
    methods: {
        ...mapMutations('snackbar', [ 'showSnackbar' ]),
        ...mapActions('translation', [ 'setTranslatedText', 'showTranslation', 'translateToTargetLang' ]),
        ...mapActions('parentalLetter', [
            'getAllParentalLetters',
            'createLetter',
            'setSeenBy',
            'getParentalLetterList',
            'getSignature',
            'updateParentalLetter',
            'deleteParentalLetter',
            'deleteParentalLetterFile',
            'toggleUpdatePossible',
            'getParentalLetterFile',
            'postParentalLetterFile',
            'postReplyLetterFile',
            'replyToParentalLetter',
            'createResponse'
        ]),
        ...mapActions('sickNote', [
            'createSickNote',
            'getSickNotes',
            'getArchivedSickNotes',
            'getSickNote',
            'acceptSickNote',
            'declineSickNote',
            'archiveSickNote',
            'restoreSickNote',
            'postSickNoteFile',
            'uploadSickNoteSignature',
            'getSickNotesSignatures'
        ]),
        ...mapActions('poll', [ 'createPoll', 'getPoll' ]),
        ...mapActions('parents', [ 'getChildTeacher', 'getMeParent' ]),
        ...mapActions('pupils', [ 'getPupil', 'getPupils' ]),
        ...mapActions('teachers', [ 'getMeTeacher', 'getTeachers' ]),
        ...mapActions('school', [ 'getSchool', 'getSchoolLogo' ]),
        ...mapActions('auth', [ 'getCurrentAccount' ]),
        ...mapActions('mimeTypes', [ 'validateMimeType', 'getFileExtension' ]),
        ...mapMutations('badges', ['setSickNoteBadge', 'setParentalLetterBadge', 'setMaintainerParentalLetterBadge']),
        ...mapMutations('auth', ['setPinValid']),
        ...mapActions("maintainers", ["getMaintainers"]),
        ...mapActions("timeslots", ["getSlots"]),
        ...mapActions('lateTimes', ['getPupilLateTimes', 'postLateTime', 'updateLateTime', 'toggleExcuseLateTime', 'deleteLateTime']),
        ...mapActions('diary', ['getDiary', 'postDiary']),
        

        calcUnseenRepliesIncoming(message){
            let unseenReplies = 0;
            if (this.accountRole === 'maintainer' && message.author && message.accepted == null) {
                        unseenReplies ++;
                    }
            if (this.accountRole === 'maintainer' && message.account) {
                let seenByMe = true;
                seenByMe = message.seenBy.some((account) => account === this.myAcc._id);
                if (!seenByMe) {
                        unseenReplies ++;
                }
            }
            if (this.accountRole === 'teacher') {
                if (message.account) {
                    // parental letter
                    let seenByMe = true;
                    seenByMe = message.seenBy.some((account) => account === this.myAcc._id);
                    if (!seenByMe) {
                            unseenReplies ++;
                    }
                } else {
                    // sick note
                    if (message.accepted == null) {
                            unseenReplies ++;
                    }
                }
            }
             if (this.accountRole === 'parent'
                        && ((message.requiresSignature
                                && !message.parentalLetterResponses.some((msg) => msg.account === this.childId))
                            || (!message.requiresSignature && !message.seenBy?.includes(this.childId)))
                    ) {
                        unseenReplies ++;
                    }
                
            if(message.parentalLetterReplies){
                message.parentalLetterReplies.forEach(reply =>{
                    if(reply.account !== this.myAcc._id && !reply.seenBy.includes(this.myAcc._id)){
                        unseenReplies ++;
                    }
                })
                return unseenReplies;
            }

            return unseenReplies;
        },
        calcUnseenReplies(message){
            if(message.parentalLetterReplies){
                let unseenReplies = 0;
                message.parentalLetterReplies.forEach(reply =>{
                    if(reply.account !== this.myAcc._id && !reply.seenBy.includes(this.myAcc._id)){
                        unseenReplies ++;
                    }
                })
                return unseenReplies;
            }

            return 0;
        },
        replyToLetter(letter){
            this.replyLetterDialog = true;
            this.letterToReply = letter;
            this.title = `Antwort: ${letter.title}`
        },
        async closeWidget(){
            await this.setPinValid(false);
            this.closeWidgetDialog = false;
        },
        async getArchivedCount(){
            const res = await backend.getArchivedSickNoteCount();
            this.archivedCount = await res.json();
        },
        async fetchArchivedMessages(){
            this.showArchivedLetters = !this.showArchivedLetters;
            if(this.showArchivedLetters){
                this.showArchivedLoading =true;
                await this.loadArchivedMessages({update:this.archivedNeedsUpdate});
                this.archivedNeedsUpdate = false;
                this.showArchivedLoading = false;

            }
        },
        msgHeaderIcon(msg){
            if(msg.requiresSignature && this.letterSignedByMe(msg) && this.accountRole !== 'maintainer' && this.accountRole !== 'teacher'){
                return {icon:this.checkIcon, class:'iconToGreen'}
            }
            else if(msg.requiresSignature && !this.letterSignedByMe(msg) && this.accountRole !== 'maintainer' && this.accountRole !== 'teacher'){
                return {icon: this.cancelIcon, class:'iconToRed'}
            }else if(this.letterSeenByMe(msg)){
                    return {icon: this.checkIcon, class:'iconToGreen'}
            }else{
                return {icon: this.cancelIcon, class:'iconToRed'}
            }
        },
        sickNoteHeaderIcon(msg){
            if(msg.accepted === true){
                return {icon:this.checkIcon, class:'iconToGreen'}
            }
            else if(msg.accepted === false){
                    return {icon: this.cancelIcon, class:'iconToRed'}
            }else{
                return {icon: this.offenIcon, class:'iconToDunkelGrau'}
                    }
        },
        displayReceiverInfo(){
            let usernames = '';
            if(this.usageAgreementsInSelectedGroups.length > 0 && !this.usageAgreementsInSelectedGroups.includes(undefined)){
             const accountsWithoutSigned = this.usageAgreementsInSelectedGroups.filter((user) => user.signedUsageAgreement && !user.signedUsageAgreement.isSigned);
                usernames = accountsWithoutSigned.map(account =>{
                    return account.displayName ? account.displayName : 'Unbekannt';
                }).join(", ");
            }
            if(usernames !== ''){
            this.showSnackbar({message: `Folgende ausgewählte Nutzer haben die Nutzungserklärung noch nicht unterschrieben: ${usernames}.`, color: 'warning'
                });
            }

        },
        cycleNext() {
            this.previewSelectionIndex++;
            if (this.tempFiles.length === this.previewSelectionIndex) {
                this.previewSelectionIndex = 0;
            }
            this.previewSelection = this.tempFiles[this.previewSelectionIndex];
        },
        cyclePrevious() {
            this.previewSelectionIndex--;
            if (this.previewSelectionIndex < 0) {
                this.previewSelectionIndex = this.tempFiles.length - 1;
            }
            this.previewSelection = this.tempFiles[this.previewSelectionIndex];
        },

        loadFullScreenPreview(file) {
            this.previewSelection = file;
            this.enableFilePreview = true;
            setTimeout(() => {
                // go to html element of vue component to get correct focus
                this.$refs.previewDialog.$el.focus();
            }, 150);
        },
        resetFilePreview() {
            this.tempFiles = [];
            this.previewSelectionIndex = 0;
            this.enableFilePreview = false;
        },
        openFileInEditor() {

        },
        async openFileInLibre() {
            let file;
            let res;
            res = await backend.getCopyOfFile(this.previewSelection._id, this.previewSelection.accessToken);
            file = await res.json();
            res = await backend.getLibreDocFromFile(file._id ? file._id : file);
            const doc = await res.json();

            const libreAccessToken = doc.accessToken;
            const libreDocId = doc.libreDoc._id;
            const docFile = doc.file;
            const fileType = await this.getFileExtension(docFile.mimetype);

            const query = {
                ... this.$route.query,

                fileId: docFile._id,
                libreDocId: libreDocId,
                accessToken: libreAccessToken,
                fileAccessToken: file.accessToken,
                fileType: fileType,
                mode: 'default',
            };

            await this.$router.push({
                name: 'libreoffice',
                query,
            });
        },
        handleThumbnailClicked(message, file) {
            if (!file.hasThumbnail) {
                return;
            }
            this.tempFiles = message.files;
            this.previewSelectionIndex = this.tempFiles.indexOf(file);
            this.loadFullScreenPreview(file);
        },

        /**
         * Method needed for live update of messages via sockets
         */
        initializeEventBus() {
            EventBus.off('sicknote-signature-new');
            EventBus.on('sicknote-signature-new', async (body) => {
                const { signatureId, sickNoteId } = body;
                this.allIncomingSickNotes[sickNoteId].signature.push(signatureId);
                await this.getSickNotesSignatures({ sickNotes: Object.values(this.allIncomingSickNotes) });
            });
            EventBus.off('new-thumbnail-parental');
            EventBus.on('new-thumbnail-parental', async (fileId) => {
                let foundFile;
                let foundMessage;
                foundMessage = this.incomingMessages.find((message) => {
                    const foundFile1 = message.files.find((file) => file._id === fileId);
                    foundFile = foundFile1;
                    return foundFile1;
                })
                if (!foundMessage) {
                    foundMessage = this.sentMessages.find((message) => {
                        const foundFile1 = message.files.find((file) => file._id === fileId);
                        foundFile = foundFile1;
                        return foundFile1;
                    })
                }
                if (!foundFile || !foundMessage) {
                    return;
                }
                const res = await backend.getThumbnailForParentalFile(foundMessage._id, foundFile._id, foundFile.accessToken);
                const blob = await res.blob();
                const hasThumbnail = blob.type !== 'text/plain; charset=utf-8';
                foundFile.thumbnail = window.URL.createObjectURL(blob);
                foundFile.hasThumbnail = hasThumbnail;
                foundMessage.files.map((file) => file._id === foundFile._id ? foundFile : file);
            });
            EventBus.off('parental-new');
            EventBus.on('parental-new', (newParentalLetter) => {
                this.incomingMessages.unshift(newParentalLetter);
            });
            EventBus.on('parental-delete', (letterId) => {
                const updatedIncomingMessages = this.incomingMessages.filter((message) => message._id !== letterId);
                this.incomingMessages = updatedIncomingMessages;
            });
            EventBus.on('parental-patch', async (updatedParentalLetter) => {
                const updatedIncomingMessages = await Promise.all(this.incomingMessages.map((message) => message._id === updatedParentalLetter._id ? updatedParentalLetter : message));
                this.incomingMessages = updatedIncomingMessages;
            });
            EventBus.on('parental-new-file', async (body) => {
                const { parentalLetterId, createdFiles } = body;
                const updatedIncomingMessages = await Promise.all(this.incomingMessages.map((letter) => {
                    if (letter._id !== parentalLetterId) {
                        return letter;
                    }
                    createdFiles.forEach((file) => letter.files.push(file));
                    return letter;
                }));
                this.incomingMessages = updatedIncomingMessages;
                // this.$forceUpdate();
            });
            EventBus.on('parental-seen', async (body) => {
                const { letterId, accId } = body;
                let updatedSentMessages = await Promise.all(this.sentMessages.map((letter) => {
                    if (letter._id !== letterId) {
                        return letter;
                    }
                    letter.seenBy.push(accId);
                    letter.checkmarkStyle = null;
                    return letter;
                }));
                if (this.accountRole === 'teacher' || this.accountRole === 'maintainer') {
                    updatedSentMessages = await this.addStyleProps(updatedSentMessages);
                }
                this.sentMessages = updatedSentMessages;
            });
            EventBus.off('parentalReply-seen')
            EventBus.on('parentalReply-seen', async (body) => {
                const { letterId, replyId, accountId } = body;
                if(this.accountRole === 'parent'){
                    const messageIndex = this.incomingMessages.findIndex(message => message._id.toString() === letterId.toString());
                    if (messageIndex !== -1) {
                        const replies = this.incomingMessages[messageIndex].parentalLetterReplies; 
                        const replyIndex = replies.findIndex(reply => reply._id.toString() === replyId.toString());
                        this.incomingMessages[messageIndex].parentalLetterReplies[replyIndex].seenBy.push(accountId);
                    } else {
                        console.log('Object not found');
                    }

                }
                else if(this.accountRole === 'teacher' || this.accountRole === 'maintainer'){
                    //on teacher and maintainer the message can either be in sent or incoming, but more likely to be in sent, so first check sen then incoming
                    const messageIndex = this.sentMessages.findIndex(message => message._id.toString() === letterId.toString());
                    if (messageIndex !== -1) {
                        const replies = this.sentMessages[messageIndex].parentalLetterReplies; 
                        const replyIndex = replies.findIndex(reply => reply._id.toString() === replyId.toString());
                        this.sentMessages[messageIndex].parentalLetterReplies[replyIndex].seenBy.push(accountId);
                        // The object is automatically updated in place, no need to reinsert it
                    } else {
                        const messageIndexIncoming = this.incomingMessages.findIndex(message => message._id.toString() === letterId.toString());
                        if (messageIndexIncoming !== -1) {
                            const replies = this.incomingMessages[messageIndexIncoming].parentalLetterReplies; 
                            const replyIndexIncoming = replies.findIndex(reply => reply._id.toString() === replyId.toString());
                            this.incomingMessages[messageIndexIncoming].parentalLetterReplies[replyIndexIncoming].seenBy.push(accountId);
                        }

                    }
                }
            });
            EventBus.on('parental-signed', async (body) => {
                const { parentalLetterId, parentalLetterResponseId } = body;
                let updatedSentMessages = await Promise.all(this.sentMessages.map(async (letter) => {
                    if (letter._id !== parentalLetterId) {
                        return letter;
                    }
                    const fullLetterRes = await backend.getParentalLetterById(parentalLetterId);
                    const fullLetter = await fullLetterRes.json();
                    if(fullLetter.length){
                        letter.parentalLetterResponses = fullLetter[0].parentalLetterResponses;
                    }else{
                        letter.parentalLetterResponses.push(parentalLetterResponseId);

                    }
                    letter.checkmarkStyle = null;
                    return letter;
                }));
                if (this.accountRole === 'teacher' || this.accountRole === 'maintainer') {
                    updatedSentMessages = await this.addStyleProps(updatedSentMessages);
                }
                this.sentMessages = updatedSentMessages;
            });

            EventBus.on('sicknote-new', (sickNote) => {
                let newSickNote = sickNote;
                if (typeof sickNote.author === 'string') {
                    const sender = this.parentsByAccountId[sickNote.author];
                    newSickNote = {...sickNote, author: sender};
                }
                this.incomingMessages.unshift(newSickNote);
                this.allIncomingSickNotes[newSickNote._id] = newSickNote;
            });
            EventBus.on('sicknote-new-file', async (body) => {
                const { createdFiles, sickNoteId } = body;
                const updatedIncomingMessages = await Promise.all(this.incomingMessages.map((sickNote) => {
                    if (sickNote._id !== sickNoteId) {
                        return sickNote;
                    }
                    createdFiles.forEach((file) => sickNote.files.push(file));
                    return sickNote;
                }));
                this.incomingMessages = updatedIncomingMessages;
            });
            EventBus.on('sicknote-response', async (body) => {
                const { status, sickNoteId } = body;
                let updatedSentMessages = await Promise.all(this.sentMessages.map((sickNote) => {
                    if (sickNote._id !== sickNoteId) {
                        return sickNote;
                    }
                    sickNote.accepted = status;
                    return sickNote;
                }));
                if (this.accountRole === 'teacher' || this.accountRole === 'maintainer') {
                    updatedSentMessages = await this.addStyleProps(updatedSentMessages);
                }
                this.sentMessages = updatedSentMessages;
            });
        },

        widgetBadgeClicked(neededEvent) {
            // find referenced messages element
            const element = this.$refs[`letter${neededEvent.item}`][0];
            // scroll element into view
            element.$el.scrollIntoView();
            this.$nextTick(() => {
                if (element) {
                    element.$el.classList.remove('badgeEventVisualize');
                    element.$el.classList.add('badgeEventVisualize');

                    setTimeout(() => {
                        element.$el.classList.remove('badgeEventVisualize');
                    }, 1050);
                }
            });
        },
    sentMessagesBadgeClicked(neededEvent) {
            // find referenced messages element
            const element = this.$refs[`message${neededEvent.item}`][0];
            // scroll element into view
            element.$el.scrollIntoView();
            this.$nextTick(() => {
                if (element) {
                    element.$el.classList.remove('badgeEventVisualize');
                    element.$el.classList.add('badgeEventVisualize');

                    setTimeout(() => {
                        element.$el.classList.remove('badgeEventVisualize');
                    }, 1050);
                }
            });
        },
        incomingMessagesBadgeClicked(neededEvent) {
            // find referenced messages element
            const element = this.$refs[`letter${neededEvent.item}`][0];
            // scroll element into view
            element.$el.scrollIntoView();
            this.$nextTick(() => {
                if (element) {
                    element.$el.classList.remove('badgeEventVisualize');
                    element.$el.classList.add('badgeEventVisualize');

                    setTimeout(() => {
                        element.$el.classList.remove('badgeEventVisualize');
                    }, 1050);
                }
            });
        },
        computedReceiverNames(msg) {
            let result = 'Unbekannt';
            if (msg.receivingAccounts.length) {
                let names = [];
                msg.receivingAccounts.forEach((acc, i) => {
                    if (i === 0) {
                        names.push(this.getAccountNameById(acc));
                    } else {
                        const string = " " + this.getAccountNameById(acc);
                        names.push(string);
                    }
                });
                return names;
            }
            return result;
        },

        async setCreateLetter(sickPupilNotification) {
            if (!this.receiversFetched) {
                await this.getPossibleReceivers();
                this.receiversFetched = true;
            }
            if(sickPupilNotification){
                this.teacherCreateSick = true;
            }
            this.createLetterDialog = true;
            this.letterColor = '#fcd20a'
            this.letterUploadedIcon = false;
        },

        chooseIcon(iconName) {
            for(let i of this.icons){
                if(i.text === iconName){
                    return i.icon;
                }
            }
        },

        //#region badging
        hasMessageUnseenBadge(messageId) {
            const msg = this.incomingMessages.find((msg) => msg._id === messageId);
            let cond1;
            let cond2;
            if (this.accountRole === 'parent') {
                cond1 = msg.requiresSignature && !msg.parentalLetterResponses.some((msg) => msg.account === this.childId);
                cond2 = !msg.requiresSignature && !msg.seenBy.includes(this.childId);
            } else {
                cond2 = !msg.seenBy.includes(this.accountId);
            }
            return cond1 || cond2;
        },
        //#endregion

        async getLoggedInName(){
            let name='';
            if (this.accountRole==='teacher') {
                let res= await this.getMeTeacher();
                name= res.name[0] + '.'+ res.lastName;
            } else {
                name= 'Verwaltung';
            }
            this.loggedInName = name;
        },

        // async addStyleProps(body) {
        //     // merge of the previous methods setSeenByNames and cacluclateCheckmarkstyle
        //     return Promise.all(body.map(async (letter) => {
        //         letter.seenByNames = await Promise.all(letter.seenBy.map(async (userId) => {
        //             const pupil = this.pupils.find((pupil)=> pupil.account === userId);
        //             if (pupil) {
        //                 return `${pupil.name} ${pupil.lastName}`;
        //             }
        //         }));
        //         if (letter.parentalLetterResponses.length >= letter.receivingAccounts.length || letter.seenBy.length >= letter.receivingAccounts.length) {
        //             letter.checkmarkStyle = 'all';
        //         }
        //         if (letter.parentalLetterResponses.length === 0 && letter.seenBy.length === 0) {
        //             letter.checkmarkStyle = 'none';
        //         }
        //         // if (letter.parentalLetterResponses.length > 0 && letter.parentalLetterResponses.length < letter.receivingAccounts.length) {
        //         //     letter.checkmarkStyle = 'partial';
        //         // }
        //         return letter;
        //     }));
        // },
        async addStyleProps(body) {
            return body.map((letter) => {
                // Map seenBy to pupil names, filtering out any undefined values
                letter.seenByNames = letter.seenBy.map((userId) => {
                    const pupil = this.pupils.find((pupil) => pupil.account === userId);
                    return pupil ? `${pupil.name} ${pupil.lastName}` : undefined;
                }).filter(name => name !== undefined);

                // Determine checkmarkStyle based on the lengths of responses and seenBy
                const totalRecipients = letter.receivingAccounts.length;
                const responsesCount = letter.parentalLetterResponses.length;
                const seenCount = letter.seenBy.length;

                if (responsesCount >= totalRecipients || seenCount >= totalRecipients) {
                    letter.checkmarkStyle = 'all';
                } else if (responsesCount === 0 && seenCount === 0) {
                    letter.checkmarkStyle = 'none';
                } else {
                    // letter.checkmarkStyle = 'partial';
                }

                return letter;
            });
        },

        getAccountName(accountId){
            const teacher = this.teachers.filter((teacher)=>{ return teacher.account === accountId; });
            if(teacher.length === 1){
                return teacher[0].name[0] + ' ' + teacher[0].lastName;
            } else {
                return 'Verwaltung'
            }
        },

        handleImageReceived(image) {
            this.filesToUpload.push(image);
        },

        async clickPlusButton() {
            await this.handleClickCreateSickNote();
            this.fillSickNoteFields();
        },

        fillSickNoteFields() {
            if(this.teacherCreateSick){
                this.fillTeacherSickNoteFields();
                return
            }
            this.dateFrom = '(Startdatum)';
            this.dateTill = '(Enddatum)';
            const date = new Date();
            const currentMonth = date.getMonth() + 1;
            const currentDay = date.getDate();
            const nextDay = date.getDate() + 1;
            if(this.sickFrom === '' && this.sickTill === ''){
                switch(true) {
                case currentMonth >= 10 && currentDay >= 10:
                    this.sickFrom = date.getFullYear() + '-' + currentMonth + '-' + currentDay;
                    break;
                case currentMonth < 10 && currentDay >= 10:
                    this.sickFrom = date.getFullYear() + '-0' + currentMonth + '-' + currentDay;
                    break;
                case currentMonth >= 10 && currentDay < 10:
                    this.sickFrom = date.getFullYear() + '-' + currentMonth + '-0' + currentDay;
                    break;
                default:
                    this.sickFrom = date.getFullYear() + '-0' + currentMonth + '-0' + currentDay;
                    break;
                }
                switch(true) {
                    case currentMonth >= 10 && nextDay >= 10:
                        this.sickTill = date.getFullYear() + '-' + currentMonth + '-' +currentDay;
                        break;
                    case currentMonth < 10 && nextDay >= 10:
                        this.sickTill = date.getFullYear() + '-0' + currentMonth + '-' + currentDay;
                        break;
                    case currentMonth >= 10 && nextDay < 10:
                        this.sickTill = date.getFullYear() + '-' + currentMonth + '-0' + currentDay;
                        break;
                    default:
                        this.sickTill = date.getFullYear() + '-0' + currentMonth + '-0' + currentDay;
                        break;
                }
            }
            
            this.sickNote = this.sickReasons[this.sickReasonIndex].description;
            this.sickReason = this.sickReasons[this.sickReasonIndex].text;

            this.fillSickNote();
        },
         fillTeacherSickNoteFields(){
             this.dateFrom = '(Startdatum)';
            this.dateTill = '(Enddatum)';
            const date = new Date();
            const currentMonth = date.getMonth() + 1;
            const currentDay = date.getDate();
            const nextDay = date.getDate() + 1;
            if(this.sickFrom === '' && this.sickTill === ''){
                switch(true) {
                case currentMonth >= 10 && currentDay >= 10:
                    this.sickFrom = date.getFullYear() + '-' + currentMonth + '-' + currentDay;
                    break;
                case currentMonth < 10 && currentDay >= 10:
                    this.sickFrom = date.getFullYear() + '-0' + currentMonth + '-' + currentDay;
                    break;
                case currentMonth >= 10 && currentDay < 10:
                    this.sickFrom = date.getFullYear() + '-' + currentMonth + '-0' + currentDay;
                    break;
                default:
                    this.sickFrom = date.getFullYear() + '-0' + currentMonth + '-0' + currentDay;
                    break;
                }
                switch(true) {
                    case currentMonth >= 10 && nextDay >= 10:
                        this.sickTill = date.getFullYear() + '-' + currentMonth + '-' +currentDay;
                        break;
                    case currentMonth < 10 && nextDay >= 10:
                        this.sickTill = date.getFullYear() + '-0' + currentMonth + '-' + currentDay;
                        break;
                    case currentMonth >= 10 && nextDay < 10:
                        this.sickTill = date.getFullYear() + '-' + currentMonth + '-0' + currentDay;
                        break;
                    default:
                        this.sickTill = date.getFullYear() + '-0' + currentMonth + '-0' + currentDay;
                        break;
                }
            }
            this.fillTeacherSickNote();
         },
         async fillTeacherSickNote(){
            this.body = this.teacherCreateSickText;
            const name = this.receiverAccounts.displayName;
            const teacherName = await this.getMeTeacher();
            // const teacherName = await teacherNameRes.json();
            this.body = this.body.replace('(Name)',name);
            this.body = this.body.replace('(Lehrername)',`${teacherName.name} ${teacherName.lastName}`);
            
            const date = new Date();
            this.body = this.body.replaceAll('(Datum)', date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear());
            // Fill in Date
            if(this.sickFrom) {
                const replaceFrom = this.sickFrom.toLocaleDateString("de-DE");
                this.body = this.body.replaceAll(this.dateFrom, replaceFrom);
                this.dateFrom = replaceFrom;

            }
            if(this.sickTill) {
                const replaceTill = this.sickTill.toLocaleDateString("de-DE");
                if(this.dateTill === this.dateFrom){
                    let count = 0;
                    this.body = this.body.replace(new RegExp(this.dateTill, 'g'), function(match) {
                        count++; 
                        if (count === 2) {
                            return replaceTill; 
                        } else {
                            return match; 
                        }
                        });
                }else{
                    this.body = this.body.replaceAll(this.dateTill, replaceTill);
                }
                this.dateTill = replaceTill;
            }

             if (this.addTime) {
                this.body = this.body.replaceAll('(Startuhrzeit)', this.sickFromTime + ' Uhr');
                this.body = this.body.replaceAll('(Enduhrzeit)', this.sickTillTime + ' Uhr');
            } else {
                this.body = this.body.replaceAll(' von (Startuhrzeit) bis (Enduhrzeit)', '');
            }
         },
        //#region helpers
        async fillSickNote() {
            // Fill in Gender and Lastname of Teacher
            if(this.sickNoteReceivers.length > 0) {
                let receivers = this.receiverAccountsParents.filter((el) => {
                    return this.sickNoteReceivers.includes(el.account);
                })
                // This filters all duplicates in the List, because it's possible that the teacher and the pupil are in
                // multiple classes (which are the same classes). This will lead to the a duplicate name in the
                // genderText
                receivers = receivers.filter((v,i,a)=>a.findIndex(v2=>(v2.account===v.account))===i)
                let receiverHeadline = '';
                let firstReceiver = true;
                receivers.forEach((el) => {
                    let genderText;
                    switch (el.gender) {
                        case 'w':
                            genderText = 'geehrte Frau';
                            break;
                        case 'm':
                            genderText = 'geehrter Herr';
                            break;
                        case 'd':
                        default:
                            genderText = 'geehrte/r Frau/Herr';
                            break;
                    }
                    if(firstReceiver) {
                        firstReceiver = false;
                        receiverHeadline = 'Sehr ' + genderText + ' ' + el.lastName;
                    } else {
                        receiverHeadline = receiverHeadline + ', sehr ' + genderText + ' ' + el.lastName;
                    }
                })
                this.sickNote = this.sickNote.replace('Sehr (geehrte/r Frau/Herr Nachname)', receiverHeadline);
            }
            // Get pupil
            const pupil = this.children.find(el => {
                return el._id === this.sickNotePupil;
            })
            // Fill in name of pupil
            if(this.sickNotePupil !== '') {
                this.sickNote = this.sickNote.replace(this.sickPupilName, pupil.name + ' ' + pupil.lastName);
            }
            // Fill in gender of pupil
            if(pupil && pupil.gender) {
                let genderText = '(ihm/ihr)';
                switch (pupil.gender) {
                    case 'w':
                        genderText = 'ihr';
                        break;
                    case 'm':
                        genderText = 'ihm';
                        break;
                    case 'd':
                    default:
                        genderText = '(ihm/ihr)';
                        break;
                }
                this.sickNote = this.sickNote.replace('(ihm/ihr)', genderText);
            }
            // Fill in parent name
            const parent = this.meParent;
            if(parent) {
                if(!this.parentAccount) {
                    this.parentAccount = this.myAcc;
                }
                const parentName = this.parentAccount.accountName;
                this.sickNote = this.sickNote.replace('(Ihr Name)', parentName.charAt(0).toUpperCase() + parentName.slice(1));
            }
            // Fill in signed Date
            const date = new Date();
            this.sickNote = this.sickNote.replaceAll('(Datum)', date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear());
            // Fill in Date
            if(this.sickFrom && typeof this.sickFrom === 'object') {
                const replaceFrom = this.sickFrom.toLocaleDateString("de-DE");
                this.sickNote = this.sickNote.replaceAll(this.dateFrom, replaceFrom);
                this.dateFrom = replaceFrom;

            }
            if(this.sickTill && typeof this.sickTill === 'object') {
                const replaceTill = this.sickTill.toLocaleDateString("de-DE");
                if(this.dateTill === this.dateFrom){
                    let count = 0;
                    this.sickNote = this.sickNote.replace(new RegExp(this.dateTill, 'g'), function(match) {
                        count++; 
                        if (count === 2) {
                            return replaceTill; 
                        } else {
                            return match; 
                        }
                        });
                }else{
                    this.sickNote = this.sickNote.replaceAll(this.dateTill, replaceTill);
                }
                this.dateTill = replaceTill;
            }

            if (this.addTime && (this.sickReasons[this.sickReasonIndex].id === '0' ||  this.sickReasons[this.sickReasonIndex].id === '2')) {
                this.sickNote = this.sickNote.replaceAll('(Startuhrzeit)', this.sickFromTime + ' Uhr');
                this.sickNote = this.sickNote.replaceAll('(Enduhrzeit)', this.sickTillTime + ' Uhr');
            } else {
                this.sickNote = this.sickNote.replaceAll(' von (Startuhrzeit) bis (Enduhrzeit)', '');
            }
        },

        clickElement(element) {
            document.getElementById(element).click();
        },

        // only allow weekdays to be selected in date picker
        weekdaysOnly(val) {
            let date = new Date(val);
            //saturday is weekday 0, sunday is weekday 6, so monday-friday is 1-5
            return date.getDay() > 0 && date.getDay() < 6;
        },

        sortDateNewToOld(a, b) {
            return a.createdAt < b.createdAt ? 1 : (a.createdAt > b.createdAt ? -1 : 0);
        },

        handleClickLetterDialog() {
            this.editLetter ? this.confirmDialogType = 4 : this.replyLetterDialog ? this.confirmDialogType = 6 : this.confirmDialogType = 3;
            this.confirmDialog = true;
        },

        handleConfirmDialogDecline() {
            switch (this.confirmDialogType) {
                case 2:
                    this.confirmDialog = false;
                    this.letterToDelete.letterToDel = null;
                    this.letterToDelete.list = null;
                    break;
                case 5:
                    this.confirmDialog = false;
                    this.letterToDelete.letterToDel = null;
                    this.letterToDelete.list = null;
                    break;
                case 6:
                    this.confirmDialog = false;
                    break;
                default:
                    this.confirmDialog = false;
            }
        },

        handleConfirmDialogAccept() {
            switch (this.confirmDialogType) {
                case 0:
                    this.clearInputs();
                    break;
                case 1:
                    this.resetCreateLetter();
                    break;
                case 2:
                    this.deleteLetter();
                    break;
                case 3:
                    this.confirmSendLetter();
                    break;
                case 4:
                    this.confirmEditLetter();
                    break;
                case 5:
                    this.confirmDeleteFile();
                    break;
                case 6:
                    this.confirmSendAnswer();
                break;
            }
        },
        async confirmSendAnswer(){
                let letter;
        
                    letter = {
                        title: this.title,
                        content: this.body,
                        visibleForAll: this.visibleForAll,
                        originalLetter: this.letterToReply._id,
                    }

                const files = this.filesToUpload;
                let resReply = await this.replyToParentalLetter(letter);
                console.log('filesToUpload',files);
                await files.reduce(async (filesPromise, file) => {
                    const filesArray = await filesPromise;
                    const fileUploadRes = await this.postReplyLetterFile({ parentalLetterReplyId: resReply._id, file: file });
                    filesArray.push(fileUploadRes);
                    return filesArray;
                }, Promise.resolve([]));
                this.confirmDialog = false;
                this.createLetterDialog = false;
                this.resetCreateLetter();
                await this.getMessages();
        },
        async handleClickSentMessage(message) {
            // sent message as teacher is parental letter
            if (this.accountRole === 'teacher' || this.accountRole === 'maintainer') {
                await this.setCurrentLetter(message);
                this.expandLetter(message._id);
            }
            // sent message as parent is sick note
            else if (this.accountRole === 'parent') {
                this.currentSickNote = this.allSentSickNotes[message._id];
                // const signature = this.signaturesById.find((signature) => signature.sickNoteId === message._id);
                // let url;
                // if (signature) {
                //     url = signature.url;
                // } else {
                //     // return empty blob url
                //     const blob = new Blob([], { type: 'image/png' });
                //     url = URL.createObjectURL(blob);
                // }
                // this.sickNoteSignature = url;
                this.expandLetter(message._id);
            } else {
                console.warn('role not supported');
            }
        },

        showSpeakerBtn(id){
            const element = this.$refs[`speaker${id}`][0];
            if (element) {
                element.classList.remove('showSpeaker');


                setTimeout(() => {
                    element.classList.add('showSpeaker');
                }, 3000);
            }
        },

        async handleClickIncomingMessage(message) {
            // incoming message as teacher is sick note
            if (this.accountRole === 'teacher' || this.accountRole === 'maintainer') {
                if (message.parentalLetterResponses) {
                    await this.openLetter(message);
                } else {
                    this.showLetterDialog = false;
                    this.currentSickNote = this.allIncomingSickNotes[message._id];
                    // const signature = this.signaturesById.find((signature) => signature.sickNoteId === message._id);
                    // let url;
                    // if (signature) {
                    //     url = signature.url;
                    // } else {
                    //     // return empty blob url
                    //     const blob = new Blob([], { type: 'image/png' });
                    //     url = URL.createObjectURL(blob);
                    // }
                    // this.sickNoteSignature = url;
                }
                this.expandLetter(message._id);
            }
            // incoming message as parent is parental letter
            else if (this.accountRole === 'parent') {
                    this.expandLetter(message._id);
                if(this.clicked.active){
                    //show button only if message is opened
                    this.showSpeakerBtn(message._id);
                }
                // Todo inifite loop part 2
                await this.openLetter(message);

            } else {
                console.warn('role not supported');
            }
        },

        // closeShowLetterDialog(id) {
        //     this.showLetterDialog = false;
        //     this.letter = '';
        // },

        handleClickCategory(category) {
            this.messageCategory = category;
            this.clicked='';
            this.searchMessagesWidget ='';
        },

        async handlePreview(letterId, fileId, type) {
            const res = await this.getParentalLetterFile({letterId, fileId});
            switch (type) {
                case 'audio':
                    this.audioUrl = res;
                    break;
                case 'image':
                    this.imageUrl = res;
                    break;
                case 'pdf':
                    this.pdfUrl = res;
                    break;
                default:
                    console.warn('Type not supported for preview')
                    break;
            }
        },

        async playVideo(letterId, fileId) {
            this.videoUrl = await this.getParentalLetterFile({letterId, fileId});
        },

        fileNotSupported() {
            this.showSnackbar({message: "Für dieses Dateiformat ist keine Vorschau verfügbar. Bitte laden Sie die Datei herunter."});
        },
        //#endregion

        //#region fileInput
        async uploadInputChange() {
            this.showLoadingOverlay = true;
            const fileArray = Array.from(this.$refs.fileUpload.files);
            if (await this.validateMimeType(fileArray)) {
                if((fileArray[fileArray.length - 1]['type'] === 'application/pdf')) {
                    await this.createFileThumbnail(fileArray[fileArray.length-1]).then(r => fileArray[fileArray.length-1].thumbnail = r);
                }
                this.filesToUpload = this.filesToUpload.concat(fileArray);
            }
            this.$refs.fileUpload.value = "";
            this.showLoadingOverlay = false;
        },

        /**
         * #Region drag and drop stuff
         */
        limitEvent(event) {
            event.stopPropagation(); event.preventDefault();
        },

        onDragEnter(e) {
            this.limitEvent(e);
            if (this.dragDropUploadAllowed) {
                e.currentTarget.classList.add('dragFeedback');
                this.dropUiActive = true;
            }
        },

        onDragLeave(e){
            this.limitEvent(e);
            e.currentTarget.classList.remove('dragFeedback');
            this.$nextTick(() => {
                this.dropUiActive = false;
            });
        },

        onDragOver(e) {
            this.limitEvent(e);
            if (this.dragDropUploadAllowed) {
                const path = e.path || (e.composedPath && e.composedPath());
                this.dropUiActive = !!path.find(el => el.id === 'messageWidgetContainer');
            }
        },

        async dropFile(e) {
            this.limitEvent(e);
            if (this.dragDropUploadAllowed) {
                const fileArray = Array.from(e.dataTransfer.files);
                if (await this.validateMimeType(fileArray)) {
                    if ((fileArray[fileArray.length - 1]['type'] === 'application/pdf')) {
                        await this.createFileThumbnail(fileArray[fileArray.length - 1]).then(r => fileArray[fileArray.length - 1].thumbnail = r);
                    }
                    this.filesToUpload = this.filesToUpload.concat(fileArray);
                }
                this.dropUiActive = false;
            }
        },

        removeFileToUpload(fileId){
            this.filesToUpload = this.filesToUpload.filter(obj => obj.lastModified !== fileId);
        },

        getThumb(blob){
            return window.URL.createObjectURL(blob);
        },

        loadPicture(file){
            return URL.createObjectURL(file);
        },

        async loadPreviews(files){
            const tempfiles = [];
            await files.forEach(async(file) => {
                file.thumbnail = await this.getParentalLetterFile({ letterId: this.letter._id, fileId: file._id });
                tempfiles.push(file);
            });
            return tempfiles;
        },

        async createFileThumbnail(file){
            const pdfDocumentLoadingTask = pdfjs.getDocument({ data: await file.arrayBuffer() });
            const pdfDocument = await pdfDocumentLoadingTask.promise;

            const pagePromises = Array.from(Array(pdfDocument.numPages).keys()) // Create array from [0 ... countPages]
                .map((element) => element + 1) // Add 1 to each element to get all page numbers
                .map((page) => pdfDocument.getPage(page));
            const allPages = await Promise.all(pagePromises);
            const firstPage = allPages[0];
            const viewport = firstPage.getViewport({scale: 1.5});

            let createdCanvas = document.createElement('canvas');
            createdCanvas.width = viewport.width;
            createdCanvas.height = viewport.height;

            const canvasContext = createdCanvas.getContext('2d');
            const renderContext = {canvasContext, viewport, renderInteractiveForms: true};
            const renderTask = firstPage.render(renderContext);
            await renderTask.promise;

            const zwischenUrl = createdCanvas.toDataURL('image/png');

            return await fetch(zwischenUrl).then(r => r.blob());
        },

        clickUpload(){
            document.getElementById("fileUpload").click();
        },

        async getMessages() {
            let allLetters;
            let allSickNotes;
            this.showIncomingOverlay = true;
            this.showOutgoingOverlay = true; 

            if (this.accountRole === 'teacher' || this.accountRole === 'maintainer') {
                allSickNotes = await this.getSickNotes();
                allLetters = await this.getAllParentalLetters();
                
                await this.processSentMessagesForTeachersOrMaintainers(allLetters, this.accountId);
                this.showOutgoingOverlay = false; // Sent messages processed

                await this.processIncomingMessagesForTeachersOrMaintainers(allSickNotes, allLetters, this.accountId);
                this.showIncomingOverlay = false; // Incoming messages processed
            } else if (this.accountRole === 'parent') {
                allSickNotes = await this.getSickNotes();
                
                await this.processSentMessagesForParents(allSickNotes);
                this.showOutgoingOverlay = false; // Sent messages processed

                await this.processIncomingMessagesForParents(this.childId);
                this.showIncomingOverlay = false; // Incoming messages processed
            }
        },
        async processIncomingMessagesForTeachersOrMaintainers(allSickNotes, allLetters, accountId) {
            const incomingSickNotes = allSickNotes;
            await Promise.all(incomingSickNotes.map(async (el) => {
                this.allIncomingSickNotes[el._id] = el;
            }));
            // await this.getSickNotesSignatures({sickNotes: incomingSickNotes.concat(this.archivedLetters)});
            const incomingLetters = allLetters.filter(letter => letter.receivingAccounts.includes(accountId));
            this.incomingMessages = [...incomingSickNotes, ...incomingLetters];
            this.incomingMessages.sort(this.sortDateNewToOld);
        },
        async processSentMessagesForTeachersOrMaintainers(allLetters, accountId) {
            this.sentMessages = allLetters.filter(letter => letter.account === accountId);
            this.sentMessages.sort(this.sortDateNewToOld);
            this.sentMessages = await this.addStyleProps(this.sentMessages);
        },
        async processIncomingMessagesForParents(childId) {
            const parentalLetters = await this.getAllParentalLetters({ pupilAccount: childId });
            const thumbnailed = await Promise.all(parentalLetters.map(async (letter) => {
                await Promise.all(letter.files.map(async (file) => {
                    const res = await backend.getThumbnailForParentalFile(letter._id, file._id, file.accessToken);
                    const blob = await res.blob();
                    const hasThumbnail = blob.type !== 'text/plain; charset=utf-8';
                    file.thumbnail = window.URL.createObjectURL(blob);
                    file.hasThumbnail = hasThumbnail;
                    return file;
                }));
                return letter;
            }));
            this.incomingMessages = thumbnailed;
            this.incomingMessages.sort(this.sortDateNewToOld);
        },
        async processSentMessagesForParents(allSickNotes) {
            this.sentMessages = allSickNotes;
            // await this.getSickNotesSignatures({sickNotes: this.sentMessages});
            this.sentMessages.map((el) => {
                this.allSentSickNotes[el._id] = el;
            });
            this.sentMessages.sort(this.sortDateNewToOld);
        },

        async downloadAttachment(letterId, file) {
            try {
                var url = await this.getParentalLetterFile({ letterId, fileId: file._id });
                var a = document.createElement('a');
                a.href = url;
                a.download = file.uploadedName;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                setTimeout(function(){
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }, 1000);
            }
            catch (e) {
                console.error(e);
            }
        },
        //#endregion

        //#region Text to Speech

        async createTextToRead(msg) {
            const sender = this.getAccountName(msg.account);
            const dateAndTime = new Date(msg.updatedAt);
            let content = sanitizeHtml(this.urlify(msg.content));

            if (content) {
                let tmp = document.createElement("DIV");
                tmp.innerHTML = content;
                content = tmp.textContent || tmp.innerText || "";
            } else {
                content = 'Dieser Brief hat keinen Inhalt.';
            }

            let poll = '';
            if (msg.poll && msg.poll !== 'undefined') {
                const completePoll = typeof msg.poll === 'string' ? await this.getPoll(msg.poll) : msg.poll;
                
                if (completePoll) {
                    const pollOptions = completePoll.showVotes ? completePoll.options.map(option => `'${option.text}' mit ${option.votes} Stimmen`).join(', ') : completePoll.options.map(option => `'${option.text}'`).join(', ');
                    const userVote = completePoll.votes.length > 0 ? completePoll.options.find(option => option._id === completePoll.votes[0].option).text : '';

                    poll = `${completePoll.mandatory ? 'Verpflichtende ' : ''}Umfrage: ${completePoll.question}; Es gibt folgende Wahlmöglichkeiten: ${pollOptions}. 
                            ${completePoll.allowUserOptions ? ' Oder Sie geben eine eigene Antwort ein.' : ''}${userVote.length > 0 ? ` Sie haben sich für '${userVote}' entschieden.` : ''}`;
                }
            }

            const attachments = msg.files.length > 0 ? msg.files.map(file => file.uploadedName).join(', ') : '';

            let signature = '';
            if (msg.requiresSignature && this.accountRole !== 'maintainer' && this.accountRole !== 'teacher') {
                if (this.letterSignedByMe(msg)) {
                    signature = 'Sie haben diesen Brief bereits unterschrieben.'
                } else {
                    signature = 'Ihre Unterschrift fehlt noch, bitte unterschreiben Sie.'
                }
            }

            const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
            const textToRead = 
                `Brief von ${sender}, gesendet ${dateAndTime.toLocaleDateString('de-DE', options)}. Betreff: ${msg.title}.
                ${content ? ` Inhalt: ${content}.` : ''}${poll.length > 0 ? ` ${poll}` : ''}${attachments.length > 0 ? ` Anhang: ${attachments}` : ''}
                ${signature.length > 0 ? ` ${signature}` : ''}`;

            return textToRead;
        },

        async readText(msg, event) {
            let text = await this.createTextToRead(msg);
            
            let txt;
            if (text) {
                let tmp = document.createElement("DIV");
                tmp.innerHTML = text;
                txt = tmp.textContent || tmp.innerText || "";
            } else {
                txt = 'Dieser Eintrag hat keinen Inhalt.';
            }

            if (this.targetLang !== 'de') {
                txt = await this.translateToTargetLang({
                    targetLang: this.targetLang,
                    textToTranslate: txt
                });

                this.setTranslatedText(txt);
                this.showTranslation(true);

                if (this.showTextTimeout) {
                    clearTimeout(this.showTextTimeout);
                    this.showTextTimeout = null;
                }

                this.showTextTimeout = setTimeout(() => {
                    this.showTranslation(false);
                    this.showTextTimeout = null;
                }, 15000)
            }

            await textToSpeech(txt, this.targetLang, () => {
                event.target.src = this.lautsprecherAusIcon;
            }, () => {
                event.target.src = lautsprecherIcon;
            });
        },
        //#endregion

        //#region TEACHER methods
        displayReceivers(list) {
            let returnString;
            // let names = list.map(item => item.name.slice(0,1) + '. ' + item.lastName);
            let names = list.map(item => item.displayName ? item.displayName : 'Unbekannt');
            names.sort()

            names.forEach(function(name, i) {
                if (i === 0) {
                    returnString = name
                } else {
                    returnString += ', ' + name
                }
            })

            return returnString;
        },

        async setCurrentLetter(letter) {
            this.currentLetter = letter;
            this.signedCurrentLetter = [];
            this.notSignedCurrentLetter = [];
            this.seenCurrentLetter = [];

            for (let i = 0; i < letter.receivingAccounts.length; i++) {
                let account = this.accountsById[letter.receivingAccounts[i]];
                let currentTarget;

            if(account && account.accountName !== 'PersonAlle'){
                if (account.role === 'maintainer') {
                    currentTarget = {
                        ...this.maintainer,
                        name: 'Verwaltung',
                        lastName: '',
                        parent: false,
                    };
                } else if (account.role === 'pupil') {
                    currentTarget = await this.pupils.find((pupil) => pupil.account === letter.receivingAccounts[i]);
                } else if (account.role === 'teacher') {
                    currentTarget = await this.teachers.find((teacher) => teacher.account === letter.receivingAccounts[i]);
                }

                let fittingResponse = null;

                if (letter.requiresSignature) {
                    fittingResponse = letter.parentalLetterResponses.find((item) => item.account === currentTarget.account);
                } else {
                    fittingResponse = letter.seenBy.find(item => item === currentTarget.account);
                }

                if (fittingResponse) {
                    if (letter.requiresSignature) {
                        this.signedCurrentLetter.push({
                            ...fittingResponse,
                            name: currentTarget.name,
                            lastName: currentTarget.lastName,
                            role: account.role,
                        });
                    } else {
                        this.seenCurrentLetter.push({
                            ...fittingResponse,
                            name: currentTarget.name,
                            lastName: currentTarget.lastName,
                            role: account.role,
                        });
                    }
                } else {
                    this.notSignedCurrentLetter.push({
                        ...currentTarget,
                        name: currentTarget.name,
                        lastName: currentTarget.lastName,
                        role: account.role,
                    });
                }
                }else{
                console.log('error',account);
            }
            }
        },

        expandLetter(id){
            if(this.clicked.active){
                this.clicked= { id: '', active: false }
            } else {
                this.clicked= { id: id, active: true }
            }
        },

        // sets field 'displayName' to group.name for tree view
        async getPossibleReceivers() {
            try {
                let teacherObj = {};
                if (this.accountRole === 'maintainer') {
                    // copy because otherwise would mutate vuex store things / mess with original objects
                    let teachersCopy = this.teachers;
                    teachersCopy = teachersCopy.filter(item => item.accountName !== 'PersonAlle');
                    const teacherArray = [];
                    teachersCopy.map((teacher) => {
                        let obj = {
                            ...teacher,
                            displayName: teacher.name + ' ' + teacher.lastName,
                            _id: teacher.account,
                        }
                        teacherArray.push(obj);
                    });
                    teacherObj = {
                        _id: 'allTeachers',
                        displayName: 'Lehrer',
                        participants: teacherArray,
                    };
                }
                if (this.accountRole === 'parent') {
                    let maintainer = this.maintainer;
                }
                let addItemText = await this.getParentalLetterList();
                // Change id to a combination of participant id and group id so that it can be uniquely identified by
                // the v-treeview
                for (let i = 0; i < addItemText.length; i++) {
                    addItemText[i].displayName = `${addItemText[i].name !== 'Verwaltung' ? 'Eltern: ':''} ${addItemText[i].name}`;
                    addItemText[i].participants = addItemText[i].participants.filter((el) => {
                        return el.parent && el.parent !== null;
                    })
                    addItemText[i].participants.forEach((el) => {
                        el._id = el._id + "-" + addItemText[i]._id;
                    })
                }
                // removing the 'Alle' group that maintainer has and all groups which have no participants left
                addItemText = addItemText.filter(item => item.displayName !== 'Alle' && item.participants.length !== 0);

                // adding teachers to receivers for maintainer
                if (this.accountRole === 'maintainer') {
                    addItemText.unshift(teacherObj);
                }
                // adding maintainer to receivers for teacher
                if (this.accountRole === 'teacher') {
                    const maintainer = {
                        ...this.maintainer,
                        displayName: ' Verwaltung'
                    };
                    addItemText = [maintainer, ...addItemText];
                }
                this.possibleReceivers = addItemText;
            } catch (e) {
                console.error(e);
            }
        },



        // used by click outside and close button
        checkForChanges() {
            let changes = 0;
            if (this.body !== '') {
                changes++;
            }
            if (this.title !== '') {
                changes++;
            }
            if (this.receiverAccounts.length !== 0) {
                changes++;
            }
            if (this.attachedFiles.length !== 0) {
                changes++;
            }
            if (this.requiresSignature !== false) {
                changes++;
            }

            if (changes > 0) {
                this.confirmDialogType = 1;
                this.confirmDialog = true;
            } else {
                this.resetCreateLetter();
            }
        },

        async confirmSendLetter(calledWithPoll, pollIdEmitted) {
            this.confirmDialog = false;
            this.createLetterDialog = false;
            if (this.containsPoll) {
                // calling a method in child (Poll), this works by using the Event Bus
                EventBus.emit('createPollAndEmit');
                // now set containsPoll to false, MessagesWidget listens to emit of 'pollCreated' and calls this method again
                this.containsPoll = false;
            } else {
                let letter;
                const caption = (this.title === '') ? 'Elternbrief' : this.title;
                // this.receiverAccounts = this.receiverAccounts.map((el) => {
                //     return {...this.accountsById[el.account], _id: el.account};
                // });
                const receivingAccounts = this.teacherCreateSick ? this.receiverAccounts.account : this.receiverAccounts.map((el) => { return el.account});
                if (calledWithPoll) {
                    letter = {
                        receivingAccounts,
                        title: caption,
                        content: this.body,
                        requiresSignature: this.requiresSignature,
                        pollId: pollIdEmitted,
                        icon: this.letterIcon,
                        uploadedIcon: this.letterIcon.includes('Icon') ?  false : true,
                        color: this.letterColor,
                    }
                } else {
                    
                    letter = {
                        receivingAccounts : this.teacherCreateSick ? [receivingAccounts] : receivingAccounts,
                        title: caption,
                        content: this.body,
                        requiresSignature: this.requiresSignature,
                        icon: this.letterIcon,
                        uploadedIcon: this.letterIcon.includes('Icon') ?  false : true,
                        color: this.letterColor,
                    }
                }

                const files = this.filesToUpload;
                let resLetter = await this.createLetter(letter);

                await files.reduce(async (filesPromise, file) => {
                    const filesArray = await filesPromise;
                    const fileUploadRes = await this.postParentalLetterFile({ letterId: resLetter._id, file: file });
                    filesArray.push(fileUploadRes);
                    return filesArray;
                }, Promise.resolve([]));

                this.resetCreateLetter();
                await this.getMessages();
            }
        },

        // fileInputChange() {
        //     let input = this.$refs.fileInput;
        //     this.attachedFiles = [];
        //     for (let i = 0; i < input.files.length; i++) {
        //         this.attachedFiles.push(input.files[i]);
        //     }
        // },
        async fillRecieverAccounts(letter){
            const accounts = letter.receivingAccounts.map((accountId) => this.accountsById[accountId]);
            const findGroupIdByAccountId =  (groups, accountId) =>{
                // Iterate through each group in the groups array
                for (const group of groups) {
                    // Check if the participants list includes the given accountId
                    if (group.participants) {
                // Check if any participant has the given accountId
                const participant = group.participants.find(participant => participant.account === accountId);
                
                // If a matching participant is found, return the _id property of the group
                if (participant) {
                    return group._id;
                }
                }
                }

                // If no group is found, return null or handle the case accordingly
                return null;
            }
            const list = await this.getParentalLetterList();

            let returnAccounts = await Promise.all(accounts.map(async (account)=>{
                    let groupId = '';
                    if(account.role && account.role === 'pupil'){
                        groupId = findGroupIdByAccountId(list, account._id);

                        if(groupId){
                            const matching = this.possibleReceivers.filter(el => el._id === groupId);
                            const treeObject = matching[0].participants.filter(treeObject => treeObject.account === account._id);
                            return treeObject[0];
                        }
                    }else if(account.role && account.role === 'teacher'){
                         const teachers = this.possibleReceivers.filter(el => el._id === 'allTeachers');
                         const treeObject = teachers[0].participants.filter(treeObject => treeObject.account === account._id)
                        return treeObject[0];
                    }else if(account.role && account.role === 'maintainer'){
                          const maintainer = this.possibleReceivers.filter(el => el.account === account._id);
                        return maintainer[0];
                    }
                }));
                this.receiverAccounts = this.receiverAccounts.concat(returnAccounts);
            },
        async setEditLetter(letter) {
            this.letter = letter;
            this.editLetter = true;
            this.title = letter.title;
            this.letterIcon = letter.icon ? letter.icon : 'sonneIcon';
            this.letterUploadedIcon = letter.uploadedIcon ? letter.uploadedIcon : false;
            this.letterColor = letter.color ? letter.color : '#fcd20a',
                this.body = letter.content;
            this.filesFromOriginalLetter = await this.loadPreviews(letter.files);
            if(this.filesFromOriginalLetter.length > 0) {
                this.anhang=true;
                // add a field to mark files if they are supposed to be deleted
                for (let i = 0; i < this.filesFromOriginalLetter.length; i++) {
                    this.filesFromOriginalLetter[i].toBeDeleted = false;
                }
            }
            await this.getPossibleReceivers();
            this.receiversFetched = true;
            await this.fillRecieverAccounts(letter);
            this.requiresSignature = letter.requiresSignature;
            this.createLetterDialog = true;
        },

        markFileForDeletion(file) {
            let index = this.filesFromOriginalLetter.indexOf(file);
            this.filesFromOriginalLetter[index].toBeDeleted = !this.filesFromOriginalLetter[index].toBeDeleted;

            if(this.filesFromOriginalLetter[index].toBeDeleted){
                const element = this.$refs[`uploadPreview${file._id}`][0];
                element.classList.add('markedToDelete');
            }else {
                this.$refs[`uploadPreview${file._id}`][0].classList.remove('markedToDelete');
            }
            // forces a rerender so styling of btn is correct (red /w icon or regular)
            this.editTitle = true;
            this.editTitle = false;
        },

        async confirmEditLetter() {
            let successPatchLetter = false;
            let successDeleteFileFromOriginalLetter = false;
        //    this.receiverAccounts = this.receiverAccounts.map((el) => {
        //             return {...this.accountsById[el.account], _id: el.account};
        //         });
                const receivingAccounts = this.receiverAccounts.map((el) => { return el.account;});
            let letter = {
                id: this.letter._id,
                receivingAccounts,
                title: this.title,
                content: this.body,
                requiresSignature: this.requiresSignature,
                icon: this.letterIcon,
                uploadedIcon: this.letterIcon.includes('Icon') ?  false : true,
            }

            let resLetter = await this.updateParentalLetter(letter);

            if (resLetter.status === 200) {
                successPatchLetter = true;
            }

            const files = this.filesToUpload;

            await files.reduce(async (filesPromise, file) => {
                const filesArray = await filesPromise;
                const fileUploadRes = await this.postParentalLetterFile({ letterId: this.letter._id, file: file });
                filesArray.push(fileUploadRes);
                return filesArray;
            }, Promise.resolve([]));

            this.editLetter = false;
            this.confirmDialog = false;
            this.resetCreateLetter();
            // TODO: Performance: Update locally instead of calling whole method again
            await this.getMessages();
        },

        // opens the dialog and sets the two params
        openConfirmDelDialog(letter, list) {
            this.confirmDialogType = 2;
            this.confirmDialog = true;

            this.letterToDelete.letterToDel = letter;
            this.letterToDelete.list = list;
        },

        // deletes letter if dialog is confirmed, splicing is to remove letter from list rather than do backend request
        async deleteLetter() {
            this.confirmDialog = false;

            let res = await this.deleteParentalLetter(this.letterToDelete.letterToDel._id);
            if (res.status === 200) {
                this.currentLetter = null;
                this.showSnackbar({ message: 'Elternbrief "' + this.letterToDelete.letterToDel.title + '" erfolgreich gelöscht.'})

                if (this.letterToDelete.list) {
                    // TODO: implement when archive category is also implemented
                    // this.archivedLetters.splice(this.archivedLetters.indexOf(this.letterToDelete.letterToDel), 1);
                } else if (!this.letterToDelete.list) {
                    this.sentMessages.splice(this.sentMessages.indexOf(this.letterToDelete.letterToDel), 1);
                } else {
                    console.warn("Please specify list");
                }
            } else {
                this.showSnackbar({ message: 'Beim Löschen des Briefs ist ein Fehler aufgetreten.', color: 'error'})
            }
        },

        resetCreateLetter() {
            this.confirmDialog = false;
            this.createLetterDialog = false;
            this.title = '';
            this.body = '';
            this.requiresSignature = false;
            this.receiverAccounts = [];
            this.attachedFiles = [];
            this.filesToUpload = [];
            this.filesFromOriginalLetter = [];
            this.editTitle = null;
            this.editBody = null;
            this.selectReceiver = false;
            this.searchTreeView = '';
            this.containsPoll = false;
            this.editLetter = false;
            this.letterIcon = 'sonneIcon';
            this.letterUploadedIcon = false;
            this.letterColor = false;
            this.teacherCreateSick = false;
            this.replyLetterDialog = false;
            this.letterToReply = null;
            this.body = '';
            this.dateFrom = '';
            this.dateTill = '';
            this.sickFrom = '';
            this.sickTill = '';
        },

        async handleAcceptSickNote(sickNote, manuell) {
            let res = await this.acceptSickNote(sickNote._id);

            let ok = true;
            const resLate = await backend.getSickNoteLateTime(sickNote._id);
            const late = await resLate.json();

            if (!manuell && late.length > 0 && res.status === 200) {
                const latetimeRes = await Promise.all(late.map(async (latetime) => {
                    return await backend.toggleExcuseLateTime(latetime._id);
                }));
                if (latetimeRes.some((el) => el.status !== 200)) {
                    ok = false;
                }
            } else if (!manuell && late.length === 0 && res.status === 200 && sickNote.title === 'Befreiung Schulsport') {
                const lateTimes = await this.createLatetimes(sickNote);
                const latetimeRes = await Promise.all(lateTimes.map(async (latetime) => {
                    return await backend.toggleExcuseLateTime(latetime._id);
                }));
                if (latetimeRes.some((el) => el.status !== 200)) {
                    ok = false;
                }
            }

            if (res.status === 200 && ok) {
                this.showSnackbar({ message: 'Entschuldigung erfolgreich akzeptiert.'});
                // also set accepted in incomingMessages so it does no longer show the newIndicator
                let index = this.incomingMessages.map(e => e._id).indexOf(this.currentSickNote._id);
                let newItem = this.incomingMessages[index];
                newItem.accepted = true;
                // using splice so it's reactive and calcUnseenMsgs is recalculated
                this.incomingMessages.splice(index, 1, newItem);
            } else {
                this.showSnackbar({ message: 'Beim Annehmen der Entschuldigung ist ein Fehler aufgetreten.'})
            }
        },

        async handleDeclineSickNote(sickNoteId) {
            let res = await this.declineSickNote(sickNoteId);
            if (res.status === 200) {
                this.showSnackbar({ message: 'Entschuldigung erfolgreich abgelehnt.'});
                // also set accepted in incomingMessages so it does no longer show the newIndicator
                let index = this.incomingMessages.map(e => e._id).indexOf(this.currentSickNote._id);
                let newItem = this.incomingMessages[index];
                newItem.accepted = false;
                // using splice so it's reactive and calcUnseenMsgs is recalculated
                this.incomingMessages.splice(index, 1, newItem);
            } else {
                this.showSnackbar({ message: 'Beim Ablehnen der Entschuldigung ist ein Fehler aufgetreten.'})
            }
        },

        async handleArchiveSickNote() {
            try {
                const sickNote = await this.archiveSickNote({ sickNoteId: this.popupSickNote });
                const index = this.incomingMessages.findIndex(el => {
                    return  el._id === this.popupSickNote;
                });
                    await this.getMessages();
                    //  if(index > 1){
                    //     this.incomingMessages.splice(index,1);
                    //  }
                await this.loadArchivedMessages(false);
            }
            catch (e) {
                console.error(e);
            }
            this.showAreYouSure = false;
        },

        async handleRestoreSickNote() {
            try {
                const message = await this.restoreSickNote({ sickNoteId: this.popupSickNote });
                await this.getMessages();
                // this.incomingMessages = [...this.incomingMessages, ...[message]];
                await this.loadArchivedMessages(false);
            } catch (e) {
                console.error(e);
            }
        },

        async loadArchivedMessages(update){
            const allArchivedSickNotes = await this.getArchivedSickNotes(update);
            this.archivedLetters = allArchivedSickNotes;
            this.archivedLetters.map((el) => {
                this.allIncomingSickNotes[el._id] = el;
            });
        },

        // pdf-lib units: 1 mm = ~2.835 ; 10 mm/1cm = ~28.35
        async exportLetter(type) {
            this.exportLetterDialog = false;
            this.showSnackbar({ message: 'Brief wird exportiert...', color: 'success' });

            const pdfDoc = await PDFDocument.create();
            const page = pdfDoc.addPage(PageSizes.A4);
            pdfDoc.registerFontkit(fontkit);

            //#region Fonts
            const eKlaraFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
            const eKlaraBoldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

            const robotoBytes = await fetch(this.roboto).then((res) => res.arrayBuffer());
            const robotoBoldBytes = await fetch(this.robotoBold).then((res) => res.arrayBuffer());

            const robotoFont = await pdfDoc.embedFont(robotoBytes);
            const robotoBoldFont = await pdfDoc.embedFont(robotoBoldBytes);

            //arabic font (might still not support all characters, but google uses it so should be fine?)
            /* const arabicBytes = await fetch(this.noto).then((res) => res.arrayBuffer());
            const arabicBoldBytes = await fetch(this.notoBold).then((res) => res.arrayBuffer());

            const arabicFont = await pdfDoc.embedFont(arabicBytes);
            const arabicBoldFont = await pdfDoc.embedFont(arabicBoldBytes);*/

            // to check which characters a given font supports
            /*const supportedCharacters = arabicFont
                .getCharacterSet()
                .map((codePoint) => String.fromCodePoint(codePoint))
                .join('');
            console.log(`Characters supported by font: ${supportedCharacters}\n`);*/
            //#endregion

            const { height, width } = page.getSize();
            const fontSize = 12;
            const textStart = 56.7; // x value for where text is supposed to start
            const textHeight = eKlaraFont.heightAtSize(12);

            //#region sender address
            // get and embed logo
            const image = await this.getSchoolLogo({schoolId: this.school._id, schoolLogo: this.school.logo, type: true});
            const imageBytes = await fetch(image.url).then((res) => res.arrayBuffer());
            let embeddedImg;
            if (image.type === 'image/jpeg' || image.type === 'image/jpg') {
                embeddedImg = await pdfDoc.embedJpg(imageBytes);
            } else if (image.type === 'image/png') {
                embeddedImg = await pdfDoc.embedPng(imageBytes);
            } else {
                console.error('Image type not supported for export');
            }

            // scale image to not be higher than given values
            let scaleFactor, jpgScaled;
            if (embeddedImg.width > 240) {
                scaleFactor = 240 / embeddedImg.width;
                jpgScaled = embeddedImg.scale(scaleFactor);
            }
            if (embeddedImg.height !== 37.5) {
                scaleFactor = 37.5 / embeddedImg.height;
                jpgScaled = embeddedImg.scale(scaleFactor);
            }

            const logoX = textStart + 481.95 - jpgScaled.width;
            const logoY = height - jpgScaled.height - 20;

            page.drawImage(embeddedImg, {
                x: logoX,
                y: logoY,
                width: jpgScaled.width,
                height: jpgScaled.height,
            })

            // prepare data, if it is sicknote sender and receiver are swapped

            let senderData = [];
            if(type === 'parentalLetter'){
                senderData[0] = this.school.name;
                senderData[1] = this.school.street + ' • ' + this.school.postal + ' ' + this.school.city;
                senderData[2] = this.school.phone + ' • ' + this.school.email;
            }else if(type === 'sickNote'){
                senderData[0] = this.receiverName;
                senderData[1] = this.receiverStreet;
                senderData[2] = this.receiverPostal + ' ' + this.receiverCity;
            }

            let textWidth, textY;
            for (let i = 0; i < senderData.length; i++) {
                textWidth = eKlaraFont.widthOfTextAtSize(`${senderData[i]}`, 12);

                // change y coordinate for each line
                if (i > 0) {    // subtracting text height + some padding
                    textY = textY - textHeight - 2;
                } else {        // initial
                    textY = logoY - 5 - textHeight - 2;
                }

                page.drawText(`${senderData[i]}`, {
                    x: textStart + 481.95 - textWidth,
                    y: textY,
                    size: fontSize,
                    font: eKlaraFont
                });
            }
            //#endregion

            //#region receiver address
            if(type === 'parentalLetter')
            // if teacher puts in data print it on pdf, else just draw border of rectangle
            if (this.receiverName.trim() !== '' && this.receiverStreet.trim() !== ''
                && this.receiverPostal.trim() !== '' && this.receiverCity.trim() !== '') {
                // prepare receiver data
                let receiverData = [];
                receiverData[0] = this.receiverName;
                receiverData[1] = this.receiverStreet;
                receiverData[2] = this.receiverPostal + ' ' + this.receiverCity;

                // write receiver data (bottom to top)
                let textY;
                for (let i = receiverData.length - 1; i >= 0; i--) {
                    // change y coordinate for each line
                    if (i !== receiverData.length - 1) {    // add text height + some padding
                        textY = textY + textHeight + 2;
                    } else {        // initial
                        textY = height - 230;
                    }

                    page.drawText(`${receiverData[i]}`, {
                        x: textStart,
                        y: textY,
                        size: fontSize,
                        font: eKlaraFont
                    });
                }
            } else {
                page.drawRectangle({
                    x: textStart,
                    y: height - 250,
                    width: 240.975,
                    height: 127.575,
                    borderWidth: 1,
                    borderColor: rgb(0,0,0),
                    color: rgb(1, 1, 1),
                })
            }
            else if (type === 'sickNote') {
                let receiverData = [];
                receiverData[0] = this.school.name;
                receiverData[1] = this.school.street;
                receiverData[2] = this.school.postal + ' ' + this.school.city;
                // write receiver data (bottom to top)
                let textY;
                for (let i = receiverData.length - 1; i >= 0; i--) {
                    // change y coordinate for each line
                    if (i !== receiverData.length - 1) {    // add text height + some padding
                        textY = textY + textHeight + 2;
                    } else {        // initial
                        textY = height - 180;
                    }

                    page.drawText(`${receiverData[i]}`, {
                        x: textStart,
                        y: textY,
                        size: fontSize,
                        font: eKlaraFont
                    });
            }
            }
            //#endregion

            //#region letter title and content
            page.drawText(`${this.letterToExport.title}`, {
                x: textStart,
                y: height - 260,
                size: fontSize,
                font: eKlaraBoldFont,
                maxWidth: 481.95,
            })

            // remove any html tags from content https://stackoverflow.com/a/47140708
            // TODO: this also removes all the styling and line breaks, but pdf-lib doesn't support them, so...
            let doc = new DOMParser().parseFromString(this.letterToExport.content, 'text/html');
            const letterContent = doc.body.textContent || "";

            // TODO: check if text height needs a second page
            page.drawText(`${letterContent}`, {
                x: textStart,
                y: height - 260 - 28.35,
                size: fontSize,
                font: eKlaraFont,
                maxWidth: 481.95,
            });
            //#endregion

            //#region attachments
            // drawing from bottom up, so "Anhang" text is last
            let files = this.letterToExport.files;
            if (files.length > 0) {
                for (let i = files.length - 1; i >= 0; i--) {
                    page.drawText(
                        `${files[i].uploadedName}`,
                        { x: textStart, y: 25 + i * (textHeight + 2), size: fontSize, font: eKlaraFont, maxWidth: 481.95 }
                    );
                }

                page.drawText(
                    'Anhang',
                    { x: textStart, y: 27 + (files.length) * (textHeight + 2), size: fontSize, font: eKlaraBoldFont, maxWidth: 481.95 }
                );
            }
            // add signature at the bottom right, when printing sick note
            if (this.letterToExport.signature) {
                const signature = this.signaturesById.find((signature) => signature.sickNoteId === this.letterToExport._id);
                const signatureBytes = await fetch(signature.url).then((res) => res.arrayBuffer());
                embeddedImg = await pdfDoc.embedPng(signatureBytes);

                const posX = width - textStart - 116;
                const posY = 35;

                page.drawImage(embeddedImg, {
                    x: posX,
                    y: posY,
                    height: 50,
                    width: 116,
                })
                page.drawText('Unterschrift',{
                    x: posX + 5,
                    y: posY - 15,
                    size: fontSize,
                    font: eKlaraFont,
                })
            }
            //#endregion

            //#region poll
            if (this.letterToExport.poll) {
                const page2 = pdfDoc.addPage(PageSizes.A4);

                // write receiver address onto page, so it can easily be sent back
                senderData[0] = this.school.name;
                senderData[1] = this.school.street;
                senderData[2] = this.school.postal + ' ' + this.school.city

                let txtHeight, txtY;
                for (let i = senderData.length - 1; i >= 0; i--) {
                    txtHeight = eKlaraFont.heightAtSize(12);

                    // change y coordinate for each line
                    if (i !== senderData.length - 1) {    // add text height + some padding
                        txtY = txtY + txtHeight + 2;
                    } else {        // initial
                        txtY = height - 230;
                    }

                    page2.drawText(`${senderData[i]}`, {
                        x: textStart,
                        y: txtY,
                        size: fontSize,
                        font: eKlaraFont
                    });
                }

                const poll = await this.getPoll(this.letterToExport.poll);
                page2.drawText(
                    `${poll.question}`,
                    { x: 50, y: 500, font: eKlaraBoldFont, size: 14, maxWidth: 481.95 }
                );

                // note: using the form and form.createCheckBox creates hugh boxes & those will not be printed by a printer
                let yText, yRect;
                for (let i = 0; i < poll.options.length; i++) {
                    yText = 470 - i * 30;
                    yRect = 467.5 - i * 30;
                    page2.drawRectangle({
                        x: textStart,
                        y: yRect,
                        width: 15,
                        height: 15,
                        borderWidth: 1,
                        borderColor: rgb(0,0,0),
                        color: rgb(1, 1, 1),
                    })
                    page2.drawText(`${poll.options[i].text}`, { x: textStart + 20, y: yText, size: 14 });
                }
            }
            //#endregion

            //#region translation
            if (this.translationLanguage !== '' && this.translationLanguage !== undefined && this.translationLanguage !== 'none') {
                const pageTranslation = pdfDoc.addPage(PageSizes.A4);

                // TODO: need implementation for rtl languages https://lingohub.com/academy/best-practices/rtl-language-list
                // const arabic = this.translationLanguage === 'ar';

                // if it's a rtl language also add a notice that rtl isn't currently supported
                /* if (arabic) {
                    const disclaimer = 'Aktuell ist die Schreibrichtung von rechts nach links nicht unterstützt'
                    const disclaimerTranslated = await this.translateToTargetLang({
                        targetLang: this.translationLanguage,
                        textToTranslate: disclaimer
                    });
                    pageTranslation.drawText(`${disclaimer}`, {
                        color: rgb(1, 0, 0),
                        x: textStart,
                        y: height - 240,
                        size: fontSize,
                        font: robotoFont,
                        maxWidth: 481.95,
                    });

                    pageTranslation.drawText(`${disclaimerTranslated}`, {
                        color: rgb(1, 0, 0),
                        x: textStart,
                        y: height - 270,
                        size: fontSize,
                        font: arabicFont,
                        maxWidth: 481.95,
                    });
                } */

                const translatedTitle = await this.translateToTargetLang({
                    targetLang: this.translationLanguage,
                    textToTranslate: this.letterToExport.title
                });
                const translatedLetterContent = await this.translateToTargetLang({
                    targetLang: this.translationLanguage,
                    textToTranslate: letterContent
                });

                pageTranslation.drawText(`${translatedTitle}`, {
                    x: textStart,
                    y: height - 300,
                    size: fontSize,
                    font: /* arabic ? arabicBoldFont :*/ robotoBoldFont,
                    maxWidth: 481.95,
                });

                // TODO: check if text height needs a second page
                pageTranslation.drawText(`${translatedLetterContent}`, {
                    x: textStart,
                    y: height - 300 - 28.35,
                    size: fontSize,
                    font: /* arabic ? arabicFont :*/ robotoFont,
                    maxWidth: 481.95,
                });
            }
            //#endregion

            const resultingUintArray = await pdfDoc.save();
            await this.downloadFile(resultingUintArray);
            this.letterToExport = null;
        },

        async downloadFile(uintArray) {
            let blob = await new Blob([uintArray]);
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            const fileName = this.exportType === 'parentalLetter' ?  this.loggedInName  : this.receiverName;
            a.download = fileName + `_Elternbrief.pdf`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            setTimeout(function(){
                a.remove();
                window.URL.revokeObjectURL(url);
            }, 1000);
            this.resetExport();
        },
        //#endregion

        //#region PARENT methods
        //#region sick note methods
        async handleClickCreateSickNote() {
            this.childTeachers = await this.getChildTeacher(this.childId);
            this.childTeachers.map((teacher) => {
                teacher.displayName = teacher.name.slice(0, 1) + ' ' + teacher.lastName;
            });
            const maintainer = {
                ...this.maintainer,
                displayName: 'Verwaltung'
            };
            this.receiverAccountsParents = [...this.childTeachers, maintainer];
            this.meParent = await this.getMeParent();
            let pupils = this.meParent.pupils;
            await Promise.all(pupils.map(async (pupil) => { let item = this.pupils.find((item)=> item._id === pupil); this.children.push(item)} ));
            this.sickNoteReceivers = [this.receiverAccountsParents[0].account];
            this.sickNotePupil = this.children[0]._id;
            this.createSickNoteDialog = true;
        },

        sickNoteInputChange() {
            let input = this.sickNoteFiles;
            input.forEach((el) => {
                if(!(el['type'].split('/')[0] === 'image' || el['type'].split('/')[0] === 'video' || el['type'] === 'application/pdf')) {
                    this.snackbarText = 'Format nicht unterstützt'
                    this.snackbar = true;
                    input.value = '';
                }
            });
        },
        calculateAbsentDays(date1, date2) {
            const oneDay = 1000 * 60 * 60 * 24; // milliseconds in one day

            let diffDays = 0;
            let currentDate = new Date(date1);

            while (currentDate <= date2) {
                const dayOfWeek = currentDate.getDay();
                if (dayOfWeek !== 0 && dayOfWeek !== 6) { // skip weekends
                diffDays++;
                }
                currentDate = new Date(currentDate.getTime() + oneDay);
            }

            return diffDays;
        },
        async handleSendSickNote() {
            let start;
            let end;
            if(this.sickTill < this.sickFrom){
                this.showSnackbar({ message: 'Enddatum darf nicht vor Startdatum liegen!', color: 'error'},)
                return
            }
            if (this.addTime) {
                start = new Date();
                end = new Date();
                start.setHours(parseInt(this.sickFromTime.slice(0, 3)), parseInt(this.sickFromTime.slice(3, 5)));
                end.setHours(parseInt(this.sickTillTime.slice(0, 3)), parseInt(this.sickTillTime.slice(3, 5)));
            } else {
                start = '';
                end = '';
            }

            let body = {
                receiver: this.sickNoteReceivers,
                pupil: this.sickNotePupil,
                from: this.sickFrom,
                till: this.sickTill,
                fromTime: start,
                tillTime: end,
                title: this.sickReason,
                content: this.sickNote,
            };
            let sickNote = await this.createSickNote(body);

            if (sickNote.title === 'Befreiung Schulsport') {
                await this.createLatetimes(sickNote);
            } else {
                let missingTime = 0;
                let missingDays = 0;
                if(sickNote.tillTime === undefined || sickNote.fromTime === undefined){
                    missingDays = this.calculateAbsentDays(new Date(sickNote.from), new Date(sickNote.till))
                }else{
                    missingTime = (new Date(sickNote.tillTime).getTime() - new Date(sickNote.fromTime).getTime()) / 60000;
                }
                const sickFromDate = new Date(this.sickFrom);
                //put it into diary notification.
                let startOfDay = new Date(sickFromDate.getFullYear(), sickFromDate.getMonth(), sickFromDate.getDate(), 0, 0, 0, 0);
                const pupilAccount = this.pupilsById[this.sickNotePupil].account;
                const fullAccount = this.accountsById[pupilAccount];
                
                const groups =  fullAccount.groups.map((group) => this.groupsById[group]);
                
                //just take first group of pupil, normally should only have one, otherwise it gets too complicated --> blame the teacher
                const appRes = await backend.getGroupsAppointmentsOnDay(groups[0]._id, startOfDay)
                const appointmentsOnDay = await appRes.json();
                const sortedAppointments = appointmentsOnDay.sort((a, b) => (new Date(a.timeslot.start).getHours() * 60 + new Date(a.timeslot.start).getMinutes())
                            - (new Date(b.timeslot.start).getHours() * 60 + new Date(b.timeslot.start).getMinutes()));

                let lateTimeBody = {
                    dateStart: sickNote.from,
                    dateEnd: sickNote.till,
                    missingTime: missingTime,
                    missingDays: missingDays,
                    pupil: sickNote.pupil._id,
                    excused: false,
                    note: "Eltern: " + sickNote.title,
                    sicknote: sickNote._id,
                    group: groups[0]._id,
                }
                if(sickNote.fromTime){
                    lateTimeBody.missingTimeStart = start;
                }
                //put appointment into late time so it can be displayed properly in diary but only if appointment exists on that day
                if(sortedAppointments.length >0){
                    if(missingDays > 0 && missingTime === 0 && sortedAppointments){
                                        lateTimeBody.appointment = sortedAppointments[0]._id;
                                        lateTimeBody.isFullday = true;
                    }else if(missingDays === 0 && missingTime > 0 && sortedAppointments){
                        const appointment = sortedAppointments.filter(item => {
                        const filterDateTime = start.getUTCHours() * 60 + start.getUTCMinutes();
                        const startDateTime = new Date(item.timeslot.start).getUTCHours() * 60 +  new Date(item.timeslot.start).getUTCMinutes();
                        const endDateTime =  new Date(item.timeslot.end).getUTCHours() * 60 +  new Date(item.timeslot.end).getUTCMinutes();

                        return filterDateTime >= startDateTime && filterDateTime < endDateTime;
                        });
                        lateTimeBody.appointment = appointment.length > 0 ? appointment[0]._id : sortedAppointments[0]._id
                    }
                }
                
                const resLate = await this.postLateTime(lateTimeBody);
                //const lateTime = await resLate.json();
            }

            const files = this.sickNoteFiles;

            await files.reduce(async (filesPromise, file) => {
                const filesArray = await filesPromise;
                const fileUploadRes = await this.postSickNoteFile({ sickNoteId: sickNote._id, file: file });
                filesArray.push(fileUploadRes);
                return filesArray;
            }, Promise.resolve([]));

            await this.sendSickNoteSignature(sickNote);
            this.clearInputs();
            await this.getMessages();
        },

        increaseDay (isoString) {
            const date = new Date(isoString);
            date.setDate(date.getDate() + 1);
            return date.toISOString();
        },

        async createLatetimes(sickNote) {
            const subjects = this.subjects;
            const sports = subjects.filter((subject) => subject.isSport);
            const fullAccount = this.accountsById[sickNote.pupil.account];
                
            const groups =  fullAccount.groups.map((group) => this.groupsById[group]);
            if (sports.length > 0) {
                const appointments = this.appointments.filter((appointment) => sports.some((sport) => sport._id === appointment.schoolSubject._id ) && this.increaseDay(appointment.day) < sickNote.till && this.increaseDay(appointment.day) >= sickNote.from);
                const lateTimes = await Promise.all(appointments.map(async (appointment) => {
                    let timeslot;
                    if (appointment.timeslot._id) {
                        timeslot = this.timeslots.find((el) => el._id === appointment.timeslot._id);
                    } else {
                        timeslot = this.timeslots.find((el) => el._id === appointment.timeslot);
                    }
                    const missingTime =(new Date(timeslot.end).getTime() - new Date(timeslot.start).getTime()) / 60000;
                    let lateTimeBody = {
                        dateStart: this.increaseDay(appointment.day),
                        dateEnd: this.increaseDay(this.increaseDay(appointment.day)),
                        missingTime: missingTime,
                        pupil: sickNote.pupil._id,
                        excused: false,
                        note: "Eltern: " + sickNote.title,
                        sicknote: sickNote._id,
                        appointment: appointment._id,
                        group: groups[0]._id,
                    }

                    const lateTime = await this.postLateTime(lateTimeBody);
                    //const lateTime = await resLate.json();
                    return lateTime;
                }));
                return lateTimes;
            }
        },

        async sendSickNoteSignature(sickNote) {
            let fileBlob = this.signatureFile;
            if (!fileBlob && this.signaturePhoto) {
                const fileFetch = await fetch(this.signaturePhoto);
                fileBlob = await fileFetch.blob();
            }

            await this.createSickNoteSignature(fileBlob, 'signature.png', sickNote);
        },

        async createSickNoteSignature(file, fileName, sickNote) {
            this.signatureXmlHttpRequest = this.uploadSickNoteSignature({sickNoteId: sickNote._id, file: file, fileName: fileName});
        },

        clearInputs() {
            this.confirmDialog = false;
            this.createSickNoteDialog = false;
            this.sickNoteReceivers = [];
            this.sickNotePupil = '';
            this.sickFrom = '';
            this.sickTill = '';
            this.sickReasonIndex = '0';
            this.sickReason = '';
            this.sickNote = '';
            this.sickNoteFiles = [];
        },

        // used by click outside and close button
        checkForSickNoteChanges() {
            let changes = 0;
            if (this.sickNoteReceivers.length !== 0) {
                changes++;
            }
            if (this.sickNotePupil !== '') {
                changes++;
            }
            if (this.sickReason !== '') {
                changes++;
            }
            if (this.sickFrom !== '') {
                changes++;
            }
            if (this.sickTill !== '') {
                changes++;
            }
            if (this.sickNote !== '') {
                changes++;
            }
            if (this.sickTill !== '') {
                changes++;
            }
            if (this.sickNoteFiles.length !== 0) {
                changes++;
            }

            if (changes > 0) {
                this.confirmDialogType = 0; // 0 = discard sickNote changes
                this.confirmDialog = true;
            } else {
                this.clearInputs();
            }
        },

        /**
         * checks if all required fields are valid
         * @returns {boolean} true if there is an error, it's used for :disabled
         */
        validateCreateSickNote() {
            let error = false;

            if (!(this.sickNoteReceivers.length > 0)) {
                error = true;
            }
            if (!this.sickNotePupil) {
                error = true;
            }
            if (!this.sickFrom) {
                error = true;
            }
            if (!this.sickTill) {
                error = true;
            }
            if (this.sickReason.replace(/\s/g, '') === '') {
                error = true;
            }
            if (this.sickNote.replace(/\s/g, '') === '') {
                error = true;
            }
            if (!(this.signatureFile.size > 0)) {
                error = true;
            }

            return error;
        },

        async signatureSickNoteDone(imgData) {
            this.signaturePhoto = imgData;
            const signatureFile = await fetch(imgData);
            this.signatureFile = await signatureFile.blob();
            this.signatureDisplay = URL.createObjectURL(this.signatureFile);
            await this.$nextTick();
            this.$refs.scrollbarSicknote.$el.scrollTop = this.$refs.scrollbarSicknote.$el.scrollHeight;
            if(this.signatureDisplay) {
                await this.$nextTick();
                document.getElementById('eK-button-disabled').focus();
            }
        },
        //#endregion

        async signatureDone(imgData, sendLetter, letter) {
            this.signaturePhoto = imgData;
            const signatureFile = await fetch(imgData);
            this.signatureFile = await signatureFile.blob();
            if(signatureFile){
            await this.sendParentResponse();    
            }
            if (sendLetter) {
                this.letter = letter;
                await this.sendParentResponse();
            }

        },

        async openLetter(letter) {
            this.letter = letter;

            this.showLetterDialog = true;
            let result;

            let accId;
            if (this.accountRole === 'parent') {
                accId = this.childId;
            } else {
                accId =this.myAcc._id;
            }

            if (!this.letterSeenByMe(letter)) {
                let body = {
                    letterId: letter._id,
                    accId: accId,
                };
                result = await this.setSeenBy(body);
                // push childId into seenBy array so calcUnseenMsgs is newly computed and thus badge updated
                if (result === 200) {
                    // Todo infinite loop this next line toggles another infinite loop on parents receiving messages
                    this.incomingMessages[this.incomingMessages.findIndex(msg => msg._id === this.letter._id)].seenBy.push(accId);
                    this.$emit('clearBadge');
                }
            }
        },

        changeSignatureFileInput() {
            let input = this.$refs.signatureFileInput;
            this.signatureFile = input[0].files[0];

            if (this.signatureFile) {
                const name = this.signatureFile.name;
                this.originalFileName = name.substring(0, name.lastIndexOf("."));

                this.fileNameCallback = (newFileName) => {
                    this.showNameFile = false;
                    this.createFileResponse(this.signatureFile, newFileName, this.letter);
                }
                this.showNameFile = true;
            }
        },

        async sendParentResponse() {
            let fileBlob = this.signatureFile;
            if (!fileBlob && this.signaturePhoto) {
                const fileFetch = await fetch(this.signaturePhoto);
                fileBlob = await fileFetch.blob();
            }

            await this.createFileResponse(fileBlob, 'signature.png', this.letter);
            this.showLetterDialog = false;

        },

        async createFileResponse(file, fileName, letter) {
            this.fileXmlHttpRequest = await this.createResponse({letterId: letter._id, file: file, fileName: fileName, childId: this.childId});
            await this.getMessages();
            this.clicked= { id: '', active: false };
        },

        getProfileColor(accountId) {
            let parentAccount = this.accountsById[accountId];
            if (!parentAccount) {
                const parent = this.parentsById[accountId];
                if(parent){
                    parentAccount = this.accountsById[parent.account];
                }
            }
            const color = parentAccount.profileColor;
            return color ? color : '#ff6633';
        },

        getAccountNameById(accountId){
            const account = this.accountsById[accountId];
            if(account){
                if (account.role === 'pupil') {
                                return 'Eltern von ' + account.displayName;
                            } else {
                                return account.displayName;
                            }
            }else{
                return 'Unbekannt'
            }
          
        },

        openDeleteFileDialog(file){
            this.confirmDialogType = 5;
            this.confirmDialog = true;
            this.fileToBeDeleted = file;
        },

        async confirmDeleteFile(){
            const res = await this.deleteParentalLetterFile({letterId: this.letter._id, fileId: this.fileToBeDeleted._id});
            if (res.status === 204) {
                this.filesFromOriginalLetter.splice(this.filesFromOriginalLetter.indexOf(this.fileToBeDeleted), 1);
                this.fileToBeDeleted = null;
                this.showSnackbar({ message: 'Datei erfolgreich gelöscht.', color: 'success'},)
                this.confirmDialog = false;
            } else {
                this.showSnackbar({ message: 'Beim Löschen der Datei ist ein Fehler aufgetreten.', color: 'error'})
                this.confirmDialog = false;
            }
        },

        async checkForUpdates() {
            if (this.isUpdatePossible) {
                await this.getPossibleReceivers();
                await this.toggleUpdatePossible(false);
            }
        },

        getReceivers(receivers) {
            let receiverString = '';
            for (let i = 0; i < receivers.length; i++) {
                if (i === 0) {
                    receiverString = this.getAccountName(receivers[i]);
                } else {
                    receiverString = receiverString + ', ' + this.getAccountName(receivers[i]);
                }
            }
            return receiverString;
        },

        getFullName(accountId) {
            if(this.accountsById[accountId] && this.accountsById[accountId].displayName){
                return this.accountsById[accountId].displayName
            }else{
                return 'Unbekannt';
            }
        },

        letterSignedByMe(msg) {
            let responses = msg.parentalLetterResponses.map(response => response.account);
            let result = responses.some(id => id === this.childId);
            return result;
        },

        letterSeenByMe(letter) {
            let accId;
            if (this.accountRole === 'parent') {
                accId = this.childId;
            } else {
                accId = this.myAcc._id;
            }
            const result = letter.seenBy.some((account) => account === accId);
            return result;
        },
        resetExport(){
            this.receiverName = '';
            this.receiverStreet = '';
            this.receiverPostal = ' ';
            this.receiverCity = '';
            this.exportLetterDialog = false;
            this.letterToExport = null;
            this.exportType = null;
        },
        areYouSureArchive(messageId, hasOwnProperty) {
            if(hasOwnProperty){
                this.popupSickNote = messageId;
                this.changeSickText = false;
                this.popupArchive = true;
                this.areYouSureMsg = 'Wollen Sie diese Entschuldigung wirklich archivieren?'
                this.areYouSureBtn = 'Archivieren';
                this.areYouSureCancelBtn = 'Abbrechen';
                this.cancelButtonIcon = this.cancelIcon;
                this.cancelBtnColor = null;
                this.okBtnColor = 'rgb(var(--v-theme-parentLetterPurple))';
                this.showAreYouSure = true;
            }else{
                this.showSnackbar({message:'Bitte nehmen Sie den Elternbrief zunächst an oder lehnen diesen ab, bevor Sie diesen archivieren.', color: 'warning'})
            }
        },
        areYouSureRestore(messageId) {
            this.popupSickNote = messageId;
            this.changeSickText = false;
            this.popupArchive = false;
            this.areYouSureMsg = 'Wollen Sie diese Entschuldigung wirklich wiederherstellen?'
            this.areYouSureBtn = 'Wiederherstellen';
            this.areYouSureCancelBtn = 'Abbrechen';
            this.cancelButtonIcon = this.cancelIcon;
            this.cancelBtnColor = null;
            this.okBtnColor = 'rgb(var(--v-theme-parentLetterPurple))';
            this.showAreYouSure = true;
        },
        areYouSureChangeSickText() {
            this.popupArchive = false;
            this.changeSickText = true;
            this.areYouSureMsg = 'Soll der bisherige Textinhalt durch eine Vorlage überschrieben/ gelöscht werden?'
            this.areYouSureBtn = 'Ja';
            this.areYouSureCancelBtn = 'Nein';
            this.cancelButtonIcon = null;
            this.cancelBtnColor = 'error';
            this.okBtnColor = 'rgb(var(--v-theme-chatColor))';
            this.showAreYouSure = true;
        },
        areYouSureFinish() {
            this.changeSickText ? this.fillSickNoteFields() : this.popupArchive ? this.handleArchiveSickNote() : this.handleRestoreSickNote();
            this.showAreYouSure = false;
        },
        showLinkExplanation(){
            this.showSnackbar({message: 'Markieren Sie einen Text, klicken Sie auf das Link-Symbol und fügen Sie Ihren Link hinzu.'} );
        },
    }
}
</script>

<style lang="scss">
.cardStyle {
    .v-card__text {
        // unify border-radius
        .v-btn {
            border-radius: 8px;
        }
        .v-input__control>.v-input__slot {
            border-radius: 8px;
        }

        // remove border bottom line/underline from all the inputs in v-cards with this class
        .v-input__control>.v-input__slot:before, .v-input__control>.v-input__slot:after {
            border-style: none !important;
        }
    }
}
/* width */
::-webkit-scrollbar {
    width: 11px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #eee;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 6px;
    width: 7px;
}
::-webkit-scrollbar-thumb:hover {
    background: #999;
    border-radius: 6px;
    width: 11px;
}

.previewHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3%;
    background-color: rgb(var(--v-theme-dunkelgrau));
    font-size: 14px;
    border-radius: 8px 8px 0px 0px;
    height:40px;
}
.previewContainer {
    width: 194px;
    border-radius: 0px 0px 8px 8px;
    margin-left: 10px;
    margin-right: 10px;
    background-color: #b9b9b9;
    border-radius: 8px 8px 8px 8px;
}
@media only screen and (max-width: 501px) {
    .previewContainer{
        width:100%
    }
    .previewHeader{
        width: 100%
    }
}
</style>

<style lang="scss" scoped>
.v-menu__content{
    border-radius: 8px !important;
}

.iOS_ResponsiveHack{
    width: 1px;
    min-width: 100%;
    *width: 100%;
    padding-left: 10%;
    padding-right: 10%;
}

.thumbnailDisplayNormal {
    width: 100%;
    height: 154px;
    aspect-ratio: 1/1;
    cursor: pointer;
    display: flex;
    justify-content: center;
    min-width: 150px;
    max-width: 10vmax;
}


.uploadPreview {
    width: 194px;
    border-radius: 8px;
    margin-left: 10px;
    margin-right: 10px;
    background-color: rgba(80, 80, 80, 0.2);
    margin-bottom: 3%;
}

@media only screen and (max-width: 501px) {
    .uploadPreview{
        width: 90%
    }
    .uploadPreviewHeader{
        width: 100%
    }
}

.uploadPreviewHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3%;
    background-color: rgb(var(--v-theme-dunkelgrau));
    font-size: 14px;
    border-radius: 8px 8px 0 0;
    height:40px;
}

.fileSystemTile {
    display: flex;
    border-radius: 16px;
    color: rgb(var(--v-theme-schwarz));
    width: 45%;
    white-space: nowrap;
    aspect-ratio: 1/1;
}

.fileSystemTile[dropzone="true"] {
    filter: saturate(0);
}

.v-btn {
    text-transform: none !important;
}

#custom-disabled.v-btn--disabled {
    background-color: rgb(var(--v-theme-fgrau)) !important;
    color: white;
}

.hoverableElement:hover {
    background-color: #F1F1F1 !important;
}

.v-btn-toggle > .v-btn.v-btn--active {
    border-radius: 8px;
    color: white;
}

.messageFooter {
    display: flex;
    padding-top: 10px;
    font-size: small;
    color: grey;
}

.cardStyle {
    height: 90vh;
    overflow: hidden;
    border-radius: 8px;
    padding: 40px;

    .v-card__title {
        margin: 0;
        padding: 0;
    }
    .v-card__text {
        margin: 0;
        padding: 0;

        .textFieldPadRight {
            padding-right: 30px;
        }

        .padTop {
            padding-top: 15px;
        }
    }
    .v-card__action {
        margin: 40px 0 0;
        padding: 0 !important;
    }
}

.ek-listElement {
    cursor: pointer;
    width: calc(100% - 32px);
    height: 48px;
    border-radius: 8px;
    border: solid #BDBDBD 0;
    box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.2);
}

.messageCardHeader {
    cursor: pointer;
    background-color: #e6e6e6;
    font-size: medium;
    color: black;
    display: flex;
    flex-flow: row nowrap;
    max-width: 100%;
    align-content: center;
    border-radius: 8px 8px 0 0;
}
.titleContainer{
    max-width: calc(28vw - 108px);
}
@media only screen and (max-width: 900px) {
.titleContainer{
    max-width: calc(85vw - 108px);
}
}

.ek-badge {
    height: 24px;
    color: white !important;
    background-color: rgb(var(--v-theme-grau));
    border-radius: 8px;
    padding: 0 8px;
    margin-right: 4px;
}

.ek-newIndicator {
    position: absolute;
    left: 16px;
    width: 8px;
    height: 48px;
    border-radius: 8px 0 0 8px;
    background-color: rgb(var(--v-theme-parentLetterPurple));
}

.ek-green-text {
    color: rgb(var(--v-theme-gruen));
}

.ek-red-text {
    color: rgb(var(--v-theme-frot));
}

.message-title-small {
    width: 90%;
    color: rgb(var(--v-theme-dunkelgrau));
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

// custom disabled button, it uses an id and important as the regular vuetify button styling already uses disabled
// https://stackoverflow.com/a/63762909
#eK-button-disabled.v-btn--disabled {
    opacity: 0.5;
    background-color: rgb(var(--v-theme-grau)) !important;
    color: white !important;
}

#sendLetterButton.v-btn--disabled {
    opacity: 0.5;
    background-color: rgb(var(--v-theme-grau)) !important;
    color: white !important;
}

.whiteBg {
    background-color: #ffffff;
}

.greyColor {
    color: rgb(var(--v-theme-dunkelgrau));
}

.own-red-text {
    color: rgb(var(--v-theme-editorWrong));
}

.pointer {
    cursor: pointer;
}

.notSigned {
    background-color: rgba(212, 0, 0, 0.3);
}

.signed {
    background-color: rgba(43, 171, 109, 0.3);
}

.scroll-area {
    position: relative;
    margin: auto;
    max-height: calc(80vh - 75px);
    overflow-x:hidden;
}

.scroll-area-responses {
    position: relative;
    margin: auto;
    max-height: 50vh;
    overflow-x:hidden;

    /*border-radius: 0 0 15px 15px;*/
}

.scroll-area-treeview {
    position: relative;
    margin: auto;
    max-height: calc(80vh - 120px);
}

.scroll-area-sickNote {
    position: relative;
    margin: auto;
    max-height: calc(90vh - 160px);
    overflow-x:hidden;

}

.scroll-area-messages {
    position: relative;
    margin: auto;
    width: 100%;
    max-height: calc(75vh - 55px);
    overflow-x:hidden;
}

@media only screen and (max-height: 900px) {
    .scroll-area-messages {
        max-height: calc(75vh - 75px);
    }
}

.ellipsis {
    text-overflow: ellipsis;
    /* Required for text-overflow to do anything */
    white-space: nowrap;
    overflow: hidden;
}


.header {
    height: 56px;
    background-color: rgb(var(--v-theme-parentLetterPurple));
    border-radius: 16px 16px 0 0;
    padding: 10px 0;
    color: white;
}

.headerRow {
    max-width: 100%;
    height: 35px;
}

.sectionHeaderCol {
    padding-top: 0;
    padding-bottom: 0;
}

.letters {
    p {
        color: rgb(var(--v-theme-dunkelgrau));
    }
}

.activeLetter {
    background-color: rgb(var(--v-theme-hellgrau));
    border-radius: 12px;
}

.actionBtn {
    color: white;
    border-radius: 8px;
    min-width: 30px !important;
    width: 30px !important;
    height: 30px !important;
}

.optionBtn {
    border-radius: 8px;
    min-width: 35px !important;
    width: 35px !important;
    height: 35px !important;
}

.thinHr {
    height: 0;
    border: none;
    border-bottom: 1px solid;
    color: rgb(var(--v-theme-dunkelgrau));
    background-color: rgb(var(--v-theme-dunkelgrau));
}

.greyLine {
    color: rgb(var(--v-theme-hellgrau));
    background-color: rgb(var(--v-theme-hellgrau));
    border: 1px solid;
}

.headlineText {
    color: rgb(var(--v-theme-dunkelgrau));
    margin-bottom: 0;
    font-weight: bold;
}

.dialogRow {
    width: 99%;
    padding: 0 8px;
    // margin: 0.5em auto 0.5em auto;
}

.fileRow {
    background-color: rgb(var(--v-theme-hellgrau));
}

#signaturePicture {
    width: auto;
    min-width: 40px;
    height: 50px;
}

#letterContent {
    white-space: pre-line;
    max-width: 100%;
    word-break: normal;
}


@media only screen and (max-width: 600px) {
    .cardStyle {
        padding: 16px;
    }

    .scroll-area-sickNote {
        max-height: calc(90vh - 110px);
    }

    .cardStyle .v-card__text .textFieldPadRight {
        padding-right: 15px;
    }

    .wrapToCol {
        flex-direction: column;
        gap: 15px;

        .v-select, .v-text-field {
            width: 100% !important;
            padding-right: 0 !important;
        }
    }
}

.dropUiContainer {
    z-index: 4;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.dropUi {
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.4);
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    p {
        width: 75%;
        text-wrap: normal;
        text-align: center;
    }
}

.dragFeedback {
    background-color: #b9b9b9;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.dropIconNormal {
    width: 100%;
    min-width: 150px;
    max-width: 10vmax;
    height: 100%;
    min-height: 150px;
    max-height: 10vmax;
    cursor: pointer;
    display: flex;
    justify-content: center;
}

.markedToDelete {
    border: 4px solid red
}
.headerIcon {
    height: 35px;
    color: white !important;
    border-radius: 4px;
    padding: 5px;
}
.uploadedIcon {
    padding: 0 !important;
}

/* Customize the label (the container) */
.checkmarkContainer {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.checkmarkContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 1px solid #ccc;
    background-color: #eee;
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.checkmarkContainer:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkmarkContainer input:checked ~ .checkmark {
    background-color: #66bb6a;
    border-color: #66bb6a;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkmarkContainer input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkmarkContainer .checkmark:after {
    left: 6px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

hr.solid {
    border-top: 1px solid rgb(123, 123, 123);
    max-width: 95%;
    margin-left: 10px;
}

.showSpeaker{
    display: none;
}

@media only screen and (max-width: 500px),
(min-width: 900px) and (max-width: 1410px) {
    .sendingButton {
        display: none;
    }
}

.displayWholeThumbnail {
    width: -webkit-fill-available;
    height: 154px;
    aspect-ratio: 1/1;
    cursor: pointer;
    display: flex;
    justify-content: center;
    border-radius: 0px 0px 8px 8px;
}

.iconToBlue {
    filter: brightness(0) saturate(100%) invert(49%) sepia(95%) saturate(213%) hue-rotate(173deg) brightness(98%) contrast(95%);
}

.iconToGreen {
    filter: brightness(0) saturate(100%) invert(47%) sepia(84%) saturate(398%) hue-rotate(69deg) brightness(96%) contrast(96%);
}

.badgeEventVisualize {
    animation: badgeAppointmentRipple 1000ms ease-in-out;
}

@keyframes badgeAppointmentRipple {
    0% {
        background-color: rgba(255, 255, 255, 0.0);
    }
    10% {
        background-color: rgba(245, 160, 50, 0.6);
    }
    75% {
        background-color: rgba(245, 160, 50, 0.6);
    }
    100% {
        background-color: rgba(255, 255, 255, 0.0);
    }
}

.option {
    border-radius: 8px;
    min-width: 35px !important;
    height: 35px !important;
}
.teacherSickHeader {
    cursor: pointer;
    background-color: #e6e6e6;
    font-size: medium;
    color: black;
    align-content: center;
    border-radius: 8px 8px 0 0;
}
.datePickerInputRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 0.75rem ;
}

.equal-width {
  flex: 1; /* Ensures both fields take up the same width */
  min-width: 0;
}

.datePickerInputRow v-text-field:last-child {
  margin-right: 0;
}

.collapsedReply {
    max-width: 99%; 
    line-height: 1.8rem !important;
    max-height: 36px !important;
    text-overflow: ellipsis; 
    white-space: nowrap; 
    overflow: hidden;
}

</style>
<style> 
.datePickerInputRow .v-input .v-input__prepend{
        margin-right: 0;
    margin-left: 5px;
}
.custom-checkbox  .v-input__details{
    display: none;
}
</style>
