<template>
    <v-tooltip location="top">
        <template v-slot:activator="{ props }">
            <v-btn
                elevation="0"
                style="background-color: transparent; min-width: 25px; max-width: 30px"
                aria-hidden="true"
                v-bind="props"
                @click="changeListView"
            >
                <img
                    v-if="listView === 'largeTile'"
                    :src="largeTileIcon"
                    class="square20"
                >
                <v-icon
                    v-else
                    color="#706f6f"
                    class="square20"
                    alt=""
                >
                    {{ currentListViewIcon() }}
                </v-icon>
            </v-btn>
        </template>
        <span>Ansicht ändern</span>
    </v-tooltip>
</template>

<script>
import largeTileIcon from "@/assets/Icons/Listenanscht_groß.svg";

export default {
    name: "FileListEditButton",
    props: {
        listView: { type: String, required: true },
    },
    data: () => ({
        largeTileIcon,
    }),
    methods: {
        changeListView() {
            switch (this.listView) {
                default:
                case 'list':
                    this.$emit('changeListView', 'largeTile');
                    break;
                case 'largeTile':
                    this.$emit('changeListView', 'mediumTile');
                    break;
                case 'mediumTile':
                    this.$emit('changeListView', 'smallTile');
                    break;
                case 'smallTile':
                    this.$emit('changeListView', 'list');
                    break;
            }
        },
        currentListViewIcon() {
            switch (this.listView) {
                default:
                case 'list':
                    return 'mdi-view-list';
                case 'largeTile':
                    // use src in v-img
                    return '';
                case 'mediumTile':
                    return 'mdi-view-grid';
                case 'smallTile':
                    return 'mdi-view-comfy';
            }
        }
    }
}
</script>

<style scoped>
</style>
