<template>
    <div>
        <EditorBaseComponent
            v-if="mode === 'teacher'"
            ref="editorBaseComponent"
            v-model:loading-message="loadingMessage"
            :mode="mode"
            :save-function="saveCorrection"
            :loading-active="loadingActive"
            :save-in-private-folder-function="saveNewPrivateDocument"
            :save-draft-function="saveDraftOfDocument"
            :events="editorEvents"
            @close="close"
        />
        <EditorBaseComponent
            v-else-if="mode === 'pupil'"
            ref="editorBaseComponent"
            v-model:loading-message="loadingMessage"
            :mode="mode"
            :save-function="savePupilDocument"
            :save-draft-function="saveDraftOfDocument"
            :save-in-private-folder-function="saveNewPrivateDocumentAsPupil"
            :save-vfs-task-function="saveVFSTask"
            :loading-active="loadingActive"
            :events="editorEvents"
            @close="close"
            @close-pinboard-editor="closePinboardEditor"
        />
        <EditorBaseComponent
            v-else-if="mode === 'creator'"
            ref="editorBaseComponent"
            v-model:loading-message="loadingMessage"
            :mode="mode"
            :save-function="saveCreatorDocument"
            :save-in-private-folder-function="saveNewPrivateDocument"
            :save-in-shared-folder-function="saveNewSharedDocument"
            :save-draft-function="saveDraftOfDocument"
            :loading-active="loadingActive"
            :events="editorEvents"
            @close="close"
            @close-pinboard-editor="closePinboardEditor"
        />
        <EditorBaseComponent
            v-else-if="mode === 'viewer'"
            ref="editorBaseComponent"
            v-model:loading-message="loadingMessage"
            :mode="mode"
            :save-function="() => {}"
            :save-in-private-folder-function="() => {}"
            :save-in-shared-folder-function="() => {}"
            :loading-active="false"
            :events="editorEvents"
            @close="close"
        />
        <div v-else>
            Fehler: Editormodus {{ mode }} nicht gefunden
        </div>
        <!-- Name File for those empty pages that can be uploaded -->
        <NameFile
            :show-dialog="showNameFile"
            :original-name="uploadFileName"
            :callback="fileNameCallback"
            :show-is-assignment="showIsAssignmentCheckbox"
            :group="currGroup"
            :appointment-date="appointmentDate"
            :change-wording="true"
            @update:show-dialog="showNameFile = $event"
        />
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import NameFile from "./NameFile";
import EditorBaseComponent from "@/components/Editor/EditorBaseComponent";
import * as backend from "@/api/backend";

const NewFileType = {
    APPOINTMENT: 0,
    PRIVATE_FOLDER: 1,
    SHARED_FOLDER: 2,
    SPECIFIC_FOLDER: 3,
}

export default {
    name: 'EditLehrer',
    components: {
        EditorBaseComponent,
        NameFile,
    },
    data: () => ({
        mode: '',
        loadingActive: false,
        loadingMessage: 'Dokument wird geladen...',

        // Name File Upload Items
        showNameFile: false,
        uploadFileName: null,
        showIsAssignmentCheckbox: true,
        fileNameCallback: () => {},

        // remembers if previous save-dest was appointment or private
        savedToVFS : false,
        draftSaveCounts: 0,

        // Events to give to EditorBaseComponent instead of calling them per $ref
        editorEvents: [],
        currGroup:'',
        appointmentDate: new Date(),
    }),
    computed: {
        ...mapGetters('auth', [ 'accountRole' ]),
        ...mapGetters('util', [ 'currentlyOpenAppointment' ]),
        ...mapGetters('pinboard', [ 'currentlyOpenPinboard' ]),
        ...mapGetters("groups", ["groupsById"]),
        ...mapState('util', ['windowWidth']),
    },
    beforeMount() {
        this.currGroup = this.setGroupInOpenAppointment();
    },
    async mounted() {
        this.mode = this.$route.params.mode;
        this.appointmentDate = new Date(this.currentlyOpenAppointment?.day) || new Date()
        // Set RouteId for Videotutorial call since routename was changed to just editor
        // (we should improve this in the future)
    },
    methods: {
        ...mapActions('files', [
            'safeCurrentFile',
            'patchCurrentAppointmentUploadFile',
            'safeNewPdfFile',
            'safeNewPrivateFolderFile',
            'safeNewSharedFolderFile',
            'safeNewDocumentDraft',
            'patchCurrentDocumentDraft',
            'postNewDocumentDraftFile',
            'safeNewThumbnail',
            'safeNewFileInFolder',
            'savePinboardCardFile',
            'setCorrectionUploadAsSeen'
        ]),
        ...mapActions('virtualFileSystem', ['updateVFSFolderUpload_LegacyV2', "getVFSFolderUploadsV2"]),
        ...mapActions('appointments', ['getAppointment', 'getAppointments']),
        ...mapMutations("snackbar", ["showSnackbar"]),
        ...mapMutations("vfs", ["setOriginalFileIdForVFSDraft"]),
        ...mapActions('documentDrafts', [ 'deleteDocumentDraft' ]),
        ...mapActions('pinboard', ['toggleOpenPinboardLibrary', 'patchCard']),
        ...mapActions("pupils", ["getMePupil"]),

        /**
         * Method to toggle Loading Animation in Base Component
         */
        setLoading(loadingActive, loadingMessage) {
            this.loadingActive = loadingActive;
            this.loadingMessage = loadingMessage;
        },

        async close(appointmentId) {
            if(appointmentId) {
                await this.getAppointment(appointmentId);
            }
            this.editorEvents.push('dispose');
            // this.$refs.editorBaseComponent.dispose();

            // Handle go back correctly in here
            try {
                if (this.draftSaveCounts) {
                    await this.$router.go((this.draftSaveCounts + 1) * (-1));
                } else {
                    let temp = await this.getMePupil();
                    const babyView = temp.babyView;
                    // Stop the Appointment from reopening in PlanWidget for babys
                    if(babyView) {
                        let page = undefined;
                        if (this.windowWidth <= 900) {
                            page = 3;
                        }
                        await this.$router.push({name: 'schueler.plan', query: {
                                ...this.$route.query,
                                aId: undefined,
                                group: undefined,
                                ug: undefined,
                                color: undefined,
                                title: undefined,
                                fId: undefined,
                                uploadid: undefined,
                                page
                            }});
                    } else {
                        await this.$router.back();
                    }
                }
            } catch (e) {
                // Ignore error returned here atm, its a router navigation guard thing (probably)
                console.error(e);
            }

            this.setLoading(false);
        },

        async closePinboardEditor() {
            this.editorEvents.push('dispose');
            const openPinboard = this.currentlyOpenPinboard;
            try {
                await this.$router.push({
                    name: 'pinboardbase',
                    query: {
                        title: openPinboard.title,
                        id: openPinboard._id,
                        color: openPinboard.color,
                        icon: openPinboard.icon,
                        uploadedIcon: openPinboard.uploadedIcon,
                    }
                });
            } catch (e) {
                // Ignore error returned here atm, its a router navigation guard thing (probably)
            }
            this.setLoading(false);
        },

        /**
         * Method given to EditorBaseComponent to handle Saving Communication with outside
         * @param fileData base64 data from canvas pages
         * @param appointmentId read from URL
         * @param fileId if there is one, also read from URL
         * @param fileName to keep correct name or set a new one if file isn't set
         * @param loadNext flag that indicates whether the basecomponent
         *  should be closed after saving or if it loads the next document (default false)
         * @param draft if draft exists give id in this param
         * @param hideBadge indicates that file should be set as seen for the recipient
         */
        async saveCorrection({fileData, appointmentId, fileId, fileName, loadNext, draft, hideBadge}) {
            this.setLoading(true, "Korrektur wird gespeichert...");
            try {
                const response = await this.safeCurrentFile({
                    userGroup: 'teacher',
                    data: fileData,
                    fileType: 'pdf',
                    appointmentId: appointmentId,
                    fileId: fileId,
                    fileName: fileName,
                });

                if (response.status === 200
                    || response.status === 201
                    || response.status === 202
                    || response.status === 204) {
                    this.showSnackbar({ message: "Korrektur wurde gespeichert", color: "success" });
                    const responseJson = await response.json();
                    await this.$refs.editorBaseComponent.safeAllEditorMessages(responseJson._id);
                    if (hideBadge) {
                        const { uploadId, pupilId } = responseJson;
                        await this.setCorrectionUploadAsSeen({ uploadId, pupilId });
                    }
                    if (draft) {
                        await this.deleteDocumentDraft(draft);
                    }
                    if (!loadNext) {
                        await this.close(appointmentId);
                    }
                } else {
                    this.showSnackbar({ message: "Korrektur konnte nicht gespeichert werden. Bitte versuche es später nochmal.", color: "error" });
                }
                this.setLoading(false);
            } catch (e) {
                this.showSnackbar({ message: "Korrektur konnte nicht gespeichert werden. Bitte versuche es später nochmal.", color: "error" });
                this.setLoading(false);
            }
        },

        saveNewPrivateDocumentAsPupil(fileData, currentName, wasEmptyPage) {
            this.saveNewFile(fileData, currentName.split('.')[0], null, NewFileType.PRIVATE_FOLDER, wasEmptyPage);
            this.savedToVFS = true;
        },
        saveNewPrivateDocument(fileData, currentName, wasPageEmpty, screenshot) {
            this.handleSaveNewFile({
                fileData,
                currentFileName: currentName,
                showIsAssignment: false,
                appointmentId: null,
                newFileType: NewFileType.PRIVATE_FOLDER,
                screenshot});
            this.savedToVFS = true;
        },
        saveNewSharedDocument(fileData, currentName, screenshot) {
            this.handleSaveNewFile({
                fileData,
                currentFileName: currentName,
                showIsAssignment: false,
                appointmentId: null,
                newFileType: NewFileType.SHARED_FOLDER,
                screenshot});
            this.savedToVFS = true;
        },

        /**
         * Method given to EditorBaseComponent to handle Saving Communication with outside
         * @param fileData base64 data of pages
         * @param appointmentId id of open appointment
         * @param fileId id of open file, if no file is open this is null
         * @param fileName name of open file
         * @param wasEmptyPage indicates whether new File to be saved was newly created, and needs id from backend
         * @param screenshot data for thumbnails as screenshot of current page
         * @param draft if draft exists give id in this param
         * @param targetFolder specific folder to save in in filewidget, should be given from EditorBaseComponent to enable 'save under'
         * @param pinboardCardId pinboardEntry the file should belong to, should be given from EditorBaseComponent to enable 'save under'
         */
        async savePupilDocument({ fileData, appointmentId, fileId, fileName, wasEmptyPage, screenshot, draft, targetFolder, pinboardCardId }) {
            if (!fileId && !targetFolder) {
                await this.saveNewFile(fileData, fileName, appointmentId, NewFileType.APPOINTMENT, wasEmptyPage);
            } else if (!fileId && targetFolder) {
                this.handleSaveNewFile({
                    fileData,
                    currentFileName: fileName,
                    showIsAssignment: !!appointmentId,
                    appointmentId,
                    newFileType: NewFileType.SPECIFIC_FOLDER,
                    draft,
                    targetFolder,
                    pinboardCardId
                });
            } else {
                let response;
                const checkResponse = async (response) => {
                    if (response.status === 200
                        || response.status === 201
                        || response.status === 202
                        || response.status === 204) {
                        let responseJson;
                        if (response instanceof XMLHttpRequest) {
                            responseJson = response;
                        } else {
                            responseJson = await response.json();
                        }
                        await this.$refs.editorBaseComponent.safeAllEditorMessages(responseJson._id);
                        this.showSnackbar({ message: "Dein Aufschrieb ist abgegeben. Gut gemacht!", color: "success" });
                        if (draft) {
                            await this.deleteDocumentDraft(draft);
                        }
                        if (pinboardCardId) {
                            await this.closePinboardEditor();
                        } else {
                            await this.close(appointmentId);
                        }
                        return;
                    } else {
                        this.showSnackbar({ message: 'Ergebnisse konnten nicht gespeichert werden. Bitte versuche es später nochmal.', color: 'error' });
                        this.setLoading(false);
                        return;
                    }
                }

                if (pinboardCardId) {
                    response = await this.savePinboardCardFile({
                        data: fileData,
                        fileId: fileId,
                        fileName: fileName,
                        pinboardCardId,
                        targetFolder,
                    });

                    response.addEventListener('load', async (e) => {
                        await checkResponse(response);
                    });
                } else if (!appointmentId && !wasEmptyPage) { // If a pupil saves his own file
                    this.setLoading(true, 'Speichern...');
                    fileName = fileName.split('.')[0] + ".pdf";
                    response = await this.updateVFSFolderUpload_LegacyV2({
                        data: fileData,
                        fileId: fileId,
                        fileName: fileName,
                    });
                    this.setOriginalFileIdForVFSDraft(null);
                    response.addEventListener('load', async (e) => {
                        if (response.status !== 201 && response.status !== 200) {
                            console.error('File patch failed:', response.response);
                            this.showSnackbar({ message: "Ein unerwarteter Fehler ist beim Speichern der Datei aufgetreten.", color: "error" });
                            this.setLoading(false);
                        } else {
                            await this.$refs.editorBaseComponent.safeAllEditorMessages(fileId);
                            this.loadingActive = false;
                            this.loadingMessage = '';
                            await this.uploadThumbnail(fileId, screenshot);
                            if (draft) {
                                await this.deleteDocumentDraft(draft);
                            }
                            await this.close(appointmentId);
                        }
                    });
                } else {
                    response = await this.safeCurrentFile({
                        userGroup: 'pupil',
                        data: fileData,
                        fileType: 'pdf',
                        appointmentId,
                        fileId,
                        fileName
                    });

                    await checkResponse(response);
                }
            }
        },

        async saveVFSTask({fileId, fileData, fileType, fileName}) {
            try {
                let snackbarMessage = 'Deine Abgabe wurde erfolgreich eingereicht.';
                // Update AssignmentName
                let newFileName = 'Abgabe';
                if (fileName) {
                    newFileName = `Abgegeben-${fileName.replace(/todo-/i, '')}`;
                }
                let file = new File([fileData], newFileName, { type: 'application/pdf' });
                let response = await backend.postVFSSubmissionV2(fileId, file);
                const status = response.status;
                response = await response.json();
                if (status === 200
                    || status === 201
                    || status === 202
                    || status === 204) {
                    this.showSnackbar({ message: snackbarMessage, color: 'success' });
                    await this.$refs.editorBaseComponent.safeAllEditorMessages(response._id);
                    // Request vfs over store so that the homework in HausiWidget will be updated from to do to done
                    await this.getVFSFolderUploadsV2({ forceRequest: true });
                    await this.close();
                } else {
                    this.showSnackbar({ message: 'Die Abgabe konnte nicht gespeichert werden. Bitte versuche es später nochmal.', color: 'error' });
                    this.setLoading(false);
                }
            } catch (e) {
                // this.showSnackbar({ message: 'Die Abgabe konnte nicht gespeichert werden. Bitte versuche es später nochmal.', color: 'error' });
                this.setLoading(false);
            }
        },

        /**
         * Only used for pupil uploads
         */
        async saveNewFile(fileData, fileName, appointmentId, newFileType, wasEmptyPage = false) {
            try {
                let response = null;
                let snackbarMessage = 'Dein Aufschrieb ist abgegeben. Gut gemacht!';

                if (newFileType === NewFileType.APPOINTMENT) {
                    response = await this.safeNewPdfFile({
                        data: fileData,
                        appointmentId,
                        fileName,
                        userGroup: 'pupil'
                    });
                } else {
                    snackbarMessage = "Dein Aufschrieb ist in deinem Ordner gespeichert!"
                    response = await this.safeNewPrivateFolderFile({
                        data: fileData,
                        fileName
                    });
                }

                response.addEventListener('load', async (e) => {
                    if (response.status === 200
                        || response.status === 201
                        || response.status === 202
                        || response.status === 204) {
                        this.showSnackbar({ message: snackbarMessage, color: 'success' });
                        const responseJson = JSON.parse(response.response);
                        await this.$refs.editorBaseComponent.safeAllEditorMessages(responseJson._id);
                        if (wasEmptyPage) {
                            this.$refs.editorBaseComponent.setNewValuesForEmptyPage(fileName, responseJson._id);
                        }
                        if (newFileType !== NewFileType.PRIVATE_FOLDER) {
                            await this.close(appointmentId);
                        }
                    } else {
                        this.showSnackbar({ message: 'Ergebnisse konnten nicht gespeichert werden. Bitte versuche es später nochmal.', color: 'error' });
                        this.setLoading(false);
                    }
                });
            } catch (e) {
                this.showSnackbar({ message: 'Ergebnisse konnten nicht gespeichert werden. Bitte versuche es später nochmal.', color: 'error' });
                this.setLoading(false);
            }
        },
        /**
         * Method given to EditorBaseComponent to handle Saving Communication with outside
         * This includes the NameFile Stuff, calling appropriate Actions and triggering UI Feedback in Base Component
         * @param fileData base64 data from canvas pages
         * @param appointmentId read from URL
         * @param fileId if there is one, also read from URL
         * @param fileName to keep correct name or set a new one if file isn't set
         * @param redrawFile flag to indicate whether file needs to saved from scratch or not
         * @param screenshot data for thumbnails as screenshot of current page
         * @param draft if draft exists give id in this param
         * @param targetFolder specific folder to save in in filewidget, should be given from EditorBaseComponent to enable 'save under'
         * @param pinboardCardId pinboardEntry the file should belong to, should be given from EditorBaseComponent to enable 'save under'
         */
        async saveCreatorDocument({fileData, appointmentId, fileId, fileName, redrawFile, screenshot, draft, targetFolder, pinboardCardId}) {
            if (!fileId || (fileId && draft) || this.savedToVFS) {
                const nweFileTypeDeclaration = () => {
                    if (appointmentId) {
                        return NewFileType.APPOINTMENT;
                    }
                    if (targetFolder) {
                        return NewFileType.SPECIFIC_FOLDER;
                    }
                    return NewFileType.PRIVATE_FOLDER;
                }

                this.handleSaveNewFile({
                    fileData,
                    currentFileName: fileName,
                    showIsAssignment: !!appointmentId,
                    appointmentId,
                    newFileType: nweFileTypeDeclaration(),
                    screenshot,
                    draft,
                    targetFolder,
                    pinboardCardId});
                this.savedToVFS = false;
            } else if (redrawFile) {
                this.setLoading(true, 'Speichern...');
                fileName = fileName.split('.')[0] + ".pdf";

                let req;
                if (appointmentId) {
                    req = await this.patchCurrentAppointmentUploadFile({
                        data: fileData,
                        appointmentId: appointmentId,
                        fileId: fileId,
                        fileName: fileName
                    });
                } else if (pinboardCardId) {
                    req = await this.savePinboardCardFile({
                        data: fileData,
                        fileId: fileId,
                        fileName: fileName,
                        pinboardCardId,
                        targetFolder,
                    })
                } else {
                    if (fileName.includes('Abgegeben')) {
                        fileName = fileName.replace(/abgegeben/i, 'Korrigiert');
                    }
                    req = await this.updateVFSFolderUpload_LegacyV2({
                        data: fileData,
                        fileId: fileId,
                        fileName: fileName,
                    });
                    this.setOriginalFileIdForVFSDraft(null);
                }

                req.addEventListener('load', async (e) => {
                    if (req.status !== 201 && req.status !== 200) {
                        console.error('File patch failed:', req.response);
                        this.showSnackbar({ message: "Ein unerwarteter Fehler ist beim Speichern der Datei aufgetreten.", color: "error" });
                        this.setLoading(false);
                    } else {
                        await this.$refs.editorBaseComponent.safeAllEditorMessages(fileId);
                        this.loadingActive = false;
                        this.loadingMessage = '';
                        await this.uploadThumbnail(fileId, screenshot);
                        if (draft) {
                            await this.deleteDocumentDraft(draft);
                        }
                        if (pinboardCardId) {
                            await this.closePinboardEditor();
                        } else {
                            await this.close(appointmentId);
                        }
                    }
                });
            } else {
                this.loadingActive = true;
                this.loadingMessage = "Speichern";
                await this.$refs.editorBaseComponent.safeAllEditorMessages(fileId);
                this.loadingActive = false;
                this.loadingMessage = '';
                await this.uploadThumbnail(fileId, screenshot);
                await this.close();
            }
        },
        handleSaveNewFile({
              fileData,
              currentFileName,
              showIsAssignment,
              appointmentId,
              newFileType,
              screenshot = null,
              draft = null,
              targetFolder = null,
              pinboardCardId = null}) {
            this.setLoading(true, 'Warten auf Speichern...');

            this.showNameFile = true;
            this.uploadFileName = currentFileName;
            // Splice this shit out
            this.uploadFileName = this.uploadFileName.replace('In-Bearbeitung-', '');
            this.uploadFileName = this.uploadFileName.replace('.pdf', '');
            this.showIsAssignmentCheckbox = showIsAssignment;
            this.fileNameCallback = (newFileName, selectedUser, notes, startDate, isAssignment) => {
                this.showNameFile = false;
                this.saveNewPage({
                    appointmentId,
                    newFileName,
                    selectedUser,
                    startDate,
                    isAssignment,
                    base64: fileData,
                    newFileType,
                    screenshot,
                    draft,
                    targetFolder,
                    pinboardCardId
                });
                // this.assignmentName = newFileName;
            };
            this.setLoading(false);
        },

        /**
         * Method called from Namefile callback when Creation was an empty Page
         * @param appointmentId
         * @param newFileName came from NameFile
         * @param selectedUser array from NameFile
         * @param startDate from NameFile
         * @param isAssignment Checkbox from NameFile
         * @param base64 FileData for Single Page
         * @param newFileType
         * @param screenshot if data for a thumbnail is available, it will be put in here
         * @param draft
         * @param targetFolder
         * @param pinboardCardId required for assigning files to the right pinboard card
         * @returns {Promise<void>}
         */
        async saveNewPage({appointmentId, newFileName, selectedUser, startDate, isAssignment, base64, newFileType, screenshot, draft, targetFolder, pinboardCardId}) {
            try {
                let response;
                if(newFileType === NewFileType.APPOINTMENT) {
                    if(!appointmentId){
                        console.error("No appointment id given! Can not save this appointment file");
                        return
                    }
                    // Create new appointment upload
                    response = await this.safeNewPdfFile({
                        data: base64,
                        appointmentId,
                        fileName: newFileName,
                        params: { selectedUser, startDate, isAssignment },
                        userGroup: 'teacher'
                    });
                } else if (newFileType === NewFileType.SPECIFIC_FOLDER) {
                    response = await this.safeNewFileInFolder({
                        data: base64,
                        fileName: newFileName,
                        folderId: targetFolder
                    });
                } else if(newFileType === NewFileType.PRIVATE_FOLDER) {
                    // Create new private folder upload
                    // TODO update route to new VFS
                    response = await this.safeNewPrivateFolderFile({
                        data: base64,
                        fileName: newFileName
                    });
                } else if(newFileType === NewFileType.SHARED_FOLDER) {
                    // Create new shared folder upload
                    // TODO update route to new VFS
                    response = await this.safeNewSharedFolderFile({
                        data: base64,
                        fileName: newFileName
                    });
                }

                response.addEventListener('load', async (e) => {
                    if (response.status === 200
                        || response.status === 201
                        || response.status === 202
                        || response.status === 204) {
                        const responseJson = JSON.parse(response.response);
                        await this.$refs.editorBaseComponent.safeAllEditorMessages(responseJson._id, true);
                        this.$refs.editorBaseComponent.setNewValuesForEmptyPage(newFileName, responseJson._id);
                        if (screenshot) {
                            await this.uploadThumbnail(responseJson._id, screenshot);
                        }
                        if (draft) {
                            await this.deleteDocumentDraft(draft);
                        }
                        if (pinboardCardId) {
                            await this.patchCard({
                                cardId: pinboardCardId,
                                body: {files: [responseJson._id]}
                            });
                            await this.closePinboardEditor();
                        } else {
                            this.toggleOpenPinboardLibrary(false);
                            await this.close(appointmentId);
                        }
                    } else {
                        this.showSnackbar({
                            message: 'Dokument konnte nicht gespeichert werden',
                            color: 'error'
                        });
                        this.setLoading(false);
                    }
                });
            } catch (e) {
                console.error(e);
                this.showSnackbar({
                    message: 'Dokument konnte nicht gespeichert werden',
                    color: 'error'
                });
                this.setLoading(false);
            }
        },

        async saveDraftOfDocument({ appointmentId, existingDraftId, reference, baseFile, name, directoryPath, screenshot, canvasPages, closeEditor }) {
            try {
                // TODO safe existingDraft id in current editor
                // TODO handle existingDraft id
                // TODO handle existingBackgroundFile --> Not necessary
                const resultingRequests = async (draft) => {
                    const uploadMessage = (await this.$refs.editorBaseComponent?.safeAllEditorMessages(draft.file))?._id;
                    // Save thumbnail in uploadMessage
                    await this.uploadThumbnail(draft.file, screenshot);
                    const savedDraft = await this.patchCurrentDocumentDraft({
                        draftId: draft._id,
                        draftPayload: {
                            file: draft.file,
                            uploadMessage,
                        }
                    });
                    return await savedDraft.json();
                }

                if (existingDraftId) {
                    const response = await this.postNewDocumentDraftFile({
                        draftId: existingDraftId,
                        fileData: baseFile,
                        name
                    });
                    const backBone = await this.patchCurrentDocumentDraft({
                        draftId: existingDraftId,
                        draftPayload: {
                            name,
                            directoryPath,
                            canvasPages: JSON.stringify(canvasPages)
                        },
                    });
                    if (backBone.status === 200) {
                        const backBoneJson = await backBone.json();
                        const fileId = backBoneJson.uploadMessage.file;
                        await this.$refs.editorBaseComponent.safeAllEditorMessages(fileId);
                        await this.uploadThumbnail(fileId, screenshot);
                    }
                    if (closeEditor) {
                        await this.close(appointmentId);
                    }
                } else {
                    // Do first POST Req to backend to create draft backbone
                    const backBone = await this.safeNewDocumentDraft({
                        fileData: baseFile,
                        name,
                        directoryPath,
                        canvasPages,
                        reference
                    });
                    // Do follow-up post requests for uploadmessages and thumbnail
                    backBone.addEventListener('load', async (e) => {
                        if (backBone.status === 201) {
                            const backBoneJson = JSON.parse(backBone.response);
                            const neededStuff = {
                                _id: backBoneJson.createdDocumentDraft._id,
                                file: backBoneJson.fileInfo._id
                            };
                            const completedDraft = await resultingRequests(neededStuff);
                            await this.$router.push({
                                query: {
                                    ...this.$route.query,
                                    draftId: completedDraft._id
                                }
                            });
                            this.draftSaveCounts++;
                        }
                        if (closeEditor) {
                            await this.close(appointmentId);
                        }
                    });
                }
            } catch (e) {
                console.error(e);
                this.showSnackbar({
                    message: 'Dokument konnte nicht zwischengespeichert werden',
                    color: 'error'
                });
                this.setLoading(false);
            }
        },

        // #region General Post Upload Slot Methods
        async uploadThumbnail(fileId, thumbnailPromise) {
            const thumbnail = await thumbnailPromise;
            return await this.safeNewThumbnail({fileId, thumbnail});
        },
        // #endregion General Post Upload Slot Methods
        setGroupInOpenAppointment(){
            const appointment = this.currentlyOpenAppointment;
            if (!appointment) {
                return null;
            }
            if(typeof appointment.group === 'string'){
                const fullGroup = this.groupsById[appointment.group]
                return fullGroup
            }
            else if(this.currentlyOpenAppointment){
                return this.currentlyOpenAppointment.group;
            }
            else
            {
                return null;
            }
        }
    }
}
</script>

<style scoped lang="scss">
</style>
