<template>
    <div v-if="keyboard">
        <img
            id="pointer"
            :src="pointer"
            :style="'top:' + y + 'px; left:' + x + 'px'"
        >
        <div
            id="control0"
            :style="'top:' + (y + 60) + 'px; left:' + x + 'px; padding-top:1px'"
        >
            0
        </div>
        <div
            id="control1"
            :style="'top:' + (y + 60) + 'px; left:' + (x + 35) + 'px'"
        >
            <div style="display: inline-flex; margin: auto">
                <div style="margin-top: 1px">
                    1
                </div>
                <img
                    id="control1Icon"
                    :src="lautsprecher"
                >
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import pointer from "../assets/pointer.png";
import lautsprecher from "../assets/Icons/lautsprecher-weiss-13.svg";

export default {
  name: "KeyboardComponent",
  components: {},
  props: {},
  data() {
    return {
      x: window.innerWidth / 2,
      y: window.innerHeight / 2,
      keyCodes: {
        left: 37,
        up: 38,
        right: 39,
        down: 40,
        enter: 96,
        read: 97,
        enter2: 48,
        read2: 49,
      },
      keys: [],
      pointer,
      lautsprecher,
    };
  },
  computed: {
    ...mapState("util", ["keyboard"]),
    ...mapState("translation", ["targetLang"]),
    ...mapGetters("gamepad", {
      gamepadVerticalAxisPosition: "verticalAxisPosition",
      gamepadHorizontalAxisPosition: "horizontalAxisPosition",
    }),
  },
  async mounted() {
    window.addEventListener("keydown", this.handleKeyDown);
    window.addEventListener("keyup", this.handleKeyUp);

    setInterval(() => {
      let dx = 0;
      let dy = 0;

      // Gamepad movement
      dx = dx + this.gamepadHorizontalAxisPosition || 0;
      dy = dy + this.gamepadVerticalAxisPosition || 0;

      // update position
      // left/right
      if (this.keys[this.keyCodes.left]) {
        dx = dx - 3;
      }
      if (this.keys[this.keyCodes.right]) {
        dx = dx + 3;
      }
      // up/down
      if (this.keys[this.keyCodes.up]) {
        dy = dy - 3;
      }
      if (this.keys[this.keyCodes.down]) {
        dy = dy + 3;
      }
      this.moveCursor({ dx, dy });
    }, 1 / 30);
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.handleKeyDown);
    window.removeEventListener("keyup", this.handleKeyUp);
  },
  methods: {
    ...mapActions("translation", ["setTranslatedText", "showTranslation", "translateToTargetLang"]),
    handleKeyDown(evt) {
      if (this.keyboard) {
        this.keys[evt.keyCode] = true;

        if (
          evt.keyCode === this.keyCodes.enter ||
          evt.keyCode === this.keyCodes.enter2
        ) {
          document.elementFromPoint(this.x + 10, this.y + 10).focus();
          document.elementFromPoint(this.x + 10, this.y + 10).click();
        }
        if (
          evt.keyCode === this.keyCodes.read ||
          evt.keyCode === this.keyCodes.read2
        ) {
          this.speak(document.elementFromPoint(this.x + 10, this.y + 10));
        }
      }
    },
    handleKeyUp(evt) {
      this.keys[evt.keyCode] = false;
    },
    moveCursor({ dx, dy }) {
      const newX = this.x + dx;
      const newY = this.y + dy;
      // Set x
      if (newX < 0) {
        this.x = 0;
      } else if (newX > window.innerWidth - 20) {
        this.x = window.innerWidth - 20;
      } else {
        this.x = newX;
      }

      // Set y
      if (newY < 0) {
        this.y = 0;
      } else if (newY > window.innerHeight - 20) {
        this.y = window.innerHeight - 20;
      } else {
        this.y = newY;
      }
    },
    async speak(element) {
      if ("speechSynthesis" in window) {
        // Speech Synthesis supported 🎉
      } else {
        // Speech Synthesis Not Supported 😣
        alert("Sorry, your browser doesn't support text to speech!");
        return;
      }

      let text = element.textContent || element.innerText || "";

      if (window.speechSynthesis.speaking) {
        window.speechSynthesis.cancel();
      } else {
          if (this.targetLang !== "de") {
              text = await this.translateToTargetLang({
                  targetLang: this.targetLang,
                  textToTranslate: text
              });

              this.setTranslatedText(text);
              this.showTranslation(true);

              if (this.showTextTimeout) {
                  clearTimeout(this.showTextTimeout);
                  this.showTextTimeout = null;
              }

              this.showTextTimeout = setTimeout(() => {
                  this.showTranslation(false);
                  this.showTextTimeout = null;
              }, 15000);
          }

        let msg = new SpeechSynthesisUtterance();
        msg.text = text;
        if (this.isLangPackageAvailable()) {
          msg.lang = this.targetLang;
          window.speechSynthesis.speak(msg);
        }
      }
    },
    isLangPackageAvailable() {
      for (let i = 0; i < window.speechSynthesis.getVoices().length; i++) {
        if (
          window.speechSynthesis.getVoices()[i].lang.includes(this.targetLang)
        ) {
          return true;
        }
      }

      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
#pointer {
  position: fixed;
  height: 60px;
  width: 50px;
  z-index: 9999999999;
  pointer-events: none;
  transform: rotate(-45deg);
}

#control0 {
  background-color: #3baa69;
  color: white;
  position: fixed;
  height: 25px;
  width: 30px;
  text-align: center;
  z-index: 99999999999;
  pointer-events: none;
}
#control1 {
  background-color: rgb(var(--v-theme-error));
  color: white;
  position: fixed;
  height: 25px;
  width: 35px;
  text-align: center;
  z-index: 99999999999;
  pointer-events: none;
}

#control1Icon {
  height: 20px;
  margin-top: 2px;
}
</style>
