<template>
    <div>
        <!-- teacher View -->
        <div
            v-if="showTeacherUI"
            id="calculationOuter"
            ref="calculationOuter"
            class="calculationOuter"
            @click="() => { $emit('focusMe') }"
        >
            <!--            @mouseenter="()=> { showMenu = true; showMenuReentered = true;}"-->
            <!--            @mouseleave="closeMenu()"-->
            <v-container
                id="calculationInner"
                ref="calculationInner"
                fluid
                class="pa-0"
            >
                <!-- Zahlenraum Bereich -->
                <div
                    class="greyDiv editorOptionsFade"
                    style="display: flex; justify-content: space-between; margin-left: 56px; margin-bottom: 8px; font-size: xx-large"
                >
                    <span
                        style="cursor: pointer"
                        class="ml-2 editorColorPrimary"
                        :class="range === 10 ? 'controlsDisabled' : ''"
                        @click="reduceRange"
                    >&lt;</span>
                    <span>{{ '0 - ' + range }}</span>
                    <span
                        style="cursor: pointer"
                        class="mr-2 editorColorPrimary"
                        :class="range === 1000 ? 'controlsDisabled' : ''"
                        @click="increaseRange"
                    >&gt;</span>
                </div>
                <!-- Mittlerer Bereich -->
                <div style="display: flex">
                    <div
                        class="editorOptionsFade"
                        style="display: flex; flex-direction: column; min-height: 100%; margin-right: 8px"
                    >
                        <v-btn
                            class="editorColorPrimaryBackgroundOnHover greyDiv"
                            variant="flat"
                            color="#ededed"
                            style="flex-grow: 1; width: 48px; min-width: 48px; cursor: pointer;"
                            @click="addCol"
                        >
                            <span
                                class="editorColorPrimaryOnHoverChildColorToWhite editorColorPrimary"
                                style="font-size: xx-large"
                            >+</span>
                        </v-btn>
                        <v-btn
                            v-ripple="numbers[0].length > 2"
                            :style="numbers[0].length > 2 ? '' : 'cursor: default; background-color: rgb(var(--v-theme-editorGrey)) !important'"
                            class="editorColorWrongBackgroundOnHover greyDiv"
                            variant="flat"
                            color="#ededed"
                            style="width: 48px; min-width: 48px; cursor: pointer; height: 48px; margin-top: 4px"
                            @click="removeCol"
                        >
                            <span
                                :class="numbers[0].length > 2 ? 'editorColorWrongOnHoverChildColorToWhite editorColorWrong' : 'controlsDisabled'"
                                style="font-size: xx-large"
                            >-</span>
                        </v-btn>
                    </div>

                    <!-- Rechnungen -->
                    <div
                        style="display: flex"
                        class="greyDivBorderColor"
                        :style="sizeState === 0 ? 'font-size: medium' : sizeState === 1 ? 'font-size: x-large' : 'font-size: xx-large'"
                    >
                        <div
                            v-for="indexCol in numbers[0].length"
                            :key="indexCol"
                            style="display: flex; flex-direction: row; flex-wrap: nowrap; padding: 8px 8px"
                        >
                            <div
                                style="display: flex; flex-direction: column; flex-wrap: nowrap"
                                class="editorColorPrimaryBackgroundOnHover editorGreyDivFade greyDiv"
                            >
                                <v-fab
                                    v-if="!colHidden(indexCol - 1)"
                                    v-ripple="false"
                                    class="greyDiv mx-auto mt-2 backgroundToEditor showOnParentHover"
                                    style="width: 20px; height: 20px; color: rgb(var(--v-theme-editorGrey));"
                                    color="#ededed"
                                    variant="flat"
                                    @click="hideCol(indexCol - 1)"
                                >
                                    <img
                                        :src="eyeIconOpen"
                                        class="iconToWhiteHover"
                                    >
                                </v-fab>
                                <v-fab
                                    v-else
                                    v-ripple="false"
                                    class="mx-auto mt-2 backgroundToEditor elementHidden"
                                    style="width: 20px; height: 20px; color: rgb(var(--v-theme-editorGrey))"
                                    color="#ededed"
                                    variant="flat"
                                    @click="showCol(indexCol - 1)"
                                >
                                    <img
                                        :src="eyeIconClosed"
                                        class="iconToWhiteHover"
                                    >
                                </v-fab>
                                <div
                                    v-for="indexRow in numbers.length"
                                    :key="indexRow"
                                >
                                    <!-- <input v-model="numbers[indexRow - 1][indexCol - 1].number" size="3" class="inputField" :style="numbers[indexRow - 1][indexCol - 1].hidden ? 'color: #B2B2B2; border-color: #B2B2B2' : ''">-->
                                    <input
                                        :id="config._id +'-numberInput-' + (indexRow - 1) + '-' + (indexCol - 1)"
                                        ref="inputElement"
                                        v-model="numbers[indexRow - 1][indexCol - 1].number"
                                        class="inputField"
                                        style="background-color: white"
                                        :class="numbers[indexRow - 1][indexCol - 1].hidden ? 'elementHidden' : ''"
                                        :style="sizeState === 0 ? '' : sizeState === 1 ? 'width: 75px' : 'width: 85px'"
                                        @input="changeInputWidth(indexRow - 1, indexCol - 1); changeResultWidth(indexRow - 1)"
                                    >
                                </div>
                                <v-fab
                                    v-ripple="false"
                                    class="mx-auto mb-2 backgroundToEditor showOnParentHover"
                                    style="width: 20px; height: 20px; color: rgb(var(--v-theme-editorGrey));"
                                    color="#ededed"
                                    variant="flat"
                                    @click="randomizeCol(indexCol - 1)"
                                >
                                    <img
                                        class="iconToWhiteHover"
                                        :src="aktualisierenIcon"
                                    >
                                </v-fab>
                            </div>
                            <!-- Operatoren -->
                            <div
                                v-if="!(indexCol === numbers[0].length)"
                                style="display: flex; flex-direction: column; flex-wrap: nowrap; margin-left: 16px"
                                class="editorGreyDivFade greyDiv editorColorPrimaryBackgroundOnHover"
                            >
                                <div class="mx-auto">
                                    <v-fab
                                        v-if="!colOperatorHidden(indexCol - 1)"
                                        v-ripple="false"
                                        class="backgroundToEditor showOnParentHover"
                                        style="width: 20px; height: 20px; color: rgb(var(--v-theme-editorGrey)); margin-left: 10px"
                                        color="#ededed"
                                        variant="flat"
                                        :style="sizeState === 0 ? 'margin-top: 8px' : sizeState === 1 ? '' : 'margin-top: -8px'"
                                        @click="hideOperatorCol(indexCol - 1)"
                                    >
                                        <img
                                            :src="eyeIconOpen"
                                            class="iconToWhiteHover"
                                        >
                                    </v-fab>
                                    <v-fab
                                        v-else
                                        v-ripple="false"
                                        class="backgroundToEditor elementHidden"
                                        style="width: 20px; height: 20px; color: rgb(var(--v-theme-editorGrey)); margin-left: 10px"
                                        color="#ededed"
                                        variant="flat"
                                        :style="sizeState === 0 ? 'margin-top: 8px' : sizeState === 1 ? '' : 'margin-top: -8px'"
                                        @click="showOperatorCol(indexCol - 1)"
                                    >
                                        <img
                                            :src="eyeIconClosed"
                                            class="iconToWhiteHover"
                                        >
                                    </v-fab>
                                    <div
                                        v-for="indexRow in operators.length"
                                        :key="indexRow"
                                        style="width: 40px; display: flex; flex-direction: column; flex-wrap: nowrap;"
                                        :style="sizeState === 0 ? 'height: 52px' : sizeState === 1 && indexRow === operators.length ? 'height: 52px' : sizeState === 1 && indexRow !== operators.length ? 'height: 64px' : sizeState === 2 && indexRow === operators.length ? 'height: 44px' : 'height: 76px'"
                                    >
                                        <v-fab
                                            class="mx-auto backgroundToEditor"
                                            style="width: 30px; height: 30px; background-color: transparent"
                                            variant="flat"
                                            :class="colOperatorHidden(indexCol - 1) ? 'elementHidden' : ''"
                                            :style="sizeState === 0 ? 'font-size: 28px; margin-top: 11px' : sizeState === 1 ? 'font-size: 40px; margin-top: 8px' : 'font-size: 52px; margin-top: 4px'"
                                            @click="switchSingleOperator(indexRow - 1, indexCol - 1)"
                                        >
                                            <span
                                                class="editorColorPrimaryOnHoverChildColorToWhite"
                                                style="color: black"
                                            >{{ operators[indexRow - 1][indexCol - 1].operator }}</span>
                                        </v-fab>
                                    </div>
                                    <v-fab
                                        class="mb-2 backgroundToEditor showOnParentHover"
                                        style="width: 20px; height: 20px; color: rgb(var(--v-theme-editorGrey)); margin-left: 10px"
                                        color="#ededed"
                                        variant="flat"
                                        @click="randomizeOperatorCol(indexCol - 1)"
                                    >
                                        <img
                                            :src="aktualisierenIcon"
                                            alt="Zufällig generieren"
                                            class="iconToWhiteHover"
                                        >
                                    </v-fab>
                                </div>
                            </div>
                        </div>
                        <!-- Results -->
                        <div style="display: flex; flex-direction: column; flex-wrap: nowrap; margin: 40px 0px 12px 0px">
                            <div
                                v-for="indexRow in results.length"
                                :key="indexRow"
                                style="display: flex;"
                                :style="sizeState === 0 ? 'height: 52px' : sizeState === 1 ? 'height: 64px' : 'height: 76px'"
                            >
                                <span
                                    class="mx-auto"
                                    :style="sizeState === 0 ? 'font-size: 28px' : sizeState === 1 ? 'font-size: 40px' : 'font-size: 52px; margin-top: -4px'"
                                >
                                    =
                                </span>
                            </div>
                        </div>
                        <div
                            style="display: flex; flex-direction: column; flex-wrap: nowrap; margin: 8px 8px"
                            class="editorGreyDivFade greyDiv editorColorPrimaryBackgroundOnHover"
                        >
                            <v-fab
                                v-if="!colResultHidden()"
                                v-ripple="false"
                                class="mx-auto mt-2 backgroundToEditor showOnParentHover"
                                style="width: 20px; height: 20px; color: rgb(var(--v-theme-editorGrey));"
                                color="#ededed"
                                variant="flat"
                                @click="hideResultCol()"
                            >
                                <img
                                    :src="eyeIconOpen"
                                    class="iconToWhiteHover"
                                >
                            </v-fab>
                            <v-fab
                                v-else
                                v-ripple="false"
                                class="mx-auto mt-2 backgroundToEditor elementHidden"
                                style="width: 20px; height: 20px; color: rgb(var(--v-theme-editorGrey))"
                                color="#ededed"
                                variant="flat"
                                @click="showResultCol()"
                            >
                                <img
                                    :src="eyeIconClosed"
                                    class="iconToWhiteHover"
                                >
                            </v-fab>
                            <div
                                v-for="indexRow in results.length"
                                :key="indexRow"
                                style="display: flex"
                            >
                                <div>
                                    <input
                                        :id="config._id +'-resultInput-' + (indexRow - 1)"
                                        v-model="results[indexRow - 1].number"
                                        class="inputField"
                                        style="background-color: white"
                                        readonly
                                        :class="results[indexRow - 1].hidden ? 'elementHidden' : ''"
                                        :style="sizeState === 0 ? '' : sizeState === 1 ? 'width: 75px' : 'width: 85px'"
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Unterer Bereich -->
                <div
                    class="editorOptionsFade"
                    style="display: flex; flex-direction: row; margin-left: 56px; margin-top: 8px"
                >
                    <v-btn
                        class="editorColorPrimaryBackgroundOnHover greyDiv"
                        variant="flat"
                        color="#ededed"
                        style="flex-grow: 1; height: 48px; min-height: 48px; cursor: pointer"
                        @click="addRow"
                    >
                        <span
                            class="editorColorPrimaryOnHoverChildColorToWhite editorColorPrimary"
                            style="font-size: xx-large"
                        >+</span>
                    </v-btn>
                    <v-btn
                        v-ripple="numbers.length > 1"
                        :style="numbers.length > 1 ? '' : 'cursor: default; background-color: rgb(var(--v-theme-editorGrey)) !important'"
                        class="editorColorWrongBackgroundOnHover greyDiv"
                        variant="flat"
                        color="#ededed"
                        style="height: 48px; min-height: 48px; cursor: pointer; width: 48px; min-width: 48px; margin-left: 4px"
                        @click="removeRow"
                    >
                        <span
                            :class="numbers.length > 1 ? 'editorColorWrongOnHoverChildColorToWhite editorColorWrong' : 'controlsDisabled'"
                            style="font-size: xx-large"
                        >-</span>
                    </v-btn>
                </div>
                <!-- Controls rechts rausgeschoben -->
                <div class="editorOptionsFade">
                    <div style="display: flex; flex-direction: column; position: absolute; right: -56px; top: 60px; width: 48px; height: 48px; min-width: 48px">
                        <v-tooltip
                            location="right"
                            :disabled="!elementOnCurrentPage"
                        >
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    class="editorColorPrimaryBackgroundOnHover"
                                    style="width: 48px; height: 48px; min-width: 48px; margin-bottom: 6px"
                                   
                                    v-bind="props"
                                    @click="changeSize()"
                                >
                                    <!--                    <v-icon class="iconToWhiteHover" style="opacity: 0.75" large>mdi-format-size</v-icon>-->
                                    <img
                                        :src="currentSizeIcon"
                                        style="height: 32px"
                                        class="iconToWhiteHover"
                                        alt="Größe des Elements verändern"
                                    >
                                </v-btn>
                            </template>
                            <span>Größe ändern</span>
                        </v-tooltip>
                        <v-tooltip
                            location="right"
                            :disabled="!elementOnCurrentPage"
                        >
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    class="editorColorPrimaryBackgroundOnHover"
                                    style="width: 48px; height: 48px; min-width: 48px; font-size: xx-large; margin-bottom: 6px"
                                   
                                    v-bind="props"
                                    @click="switchOperator"
                                >
                                    <span
                                        class="editorColorPrimary editorColorPrimaryOnHoverChildColorToWhite"
                                        :style="selectedOperator === '*' ? 'margin-top: 15px' : ''"
                                    >{{ selectedOperator }}</span>
                                </v-btn>
                            </template>
                            <span>Rechenoperator ändern</span>
                        </v-tooltip>
                        <v-tooltip
                            location="right"
                            :disabled="!elementOnCurrentPage"
                        >
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    class="editorColorPrimaryBackgroundOnHover"
                                    style="width: 48px; height: 48px; min-width: 48px; font-size: xx-large; margin-bottom: 6px"
                                   
                                    v-bind="props"
                                    @click="randomizeInput"
                                >
                                    <img
                                        class="iconToWhiteHover"
                                        style="height: 17px"
                                        :src="aktualisierenIcon"
                                    >
                                </v-btn>
                            </template>
                            <span>Zahlen ändern</span>
                        </v-tooltip>
                    </div>
                </div>
            </v-container>
        </div>

        <!-- pupil view -->
        <div
            v-else-if="showPupilUI"
            id="calculationOuterPupil"
            ref="calculationOuterPupil"
            class="calculationOuter"
            @click="$emit('focusMe')"
        >
            <v-container
                id="calculationInnerPupil"
                ref="calculationInnerPupil"
                fluid
                class="pa-0"
            >
                <!-- Rechnungen -->
                <div style="display: flex; margin-top: 56px; margin-left: 56px">
                    <span class="visually-hidden">Rechenaufgabe</span>
                    <div
                        v-for="indexCol in numbers[0].length"
                        :key="indexCol"
                        style="display: flex; flex-direction: row; flex-wrap: nowrap; padding: 12px 4px"
                    >
                        <div
                            style="display: flex; flex-direction: column; flex-wrap: nowrap"
                            :style="sizeState === 0 ? 'font-size: medium' : sizeState === 1 ? 'font-size: x-large' : 'font-size: xx-large'"
                        >
                            <div
                                v-for="indexRow in numbers.length"
                                :key="indexRow"
                            >
                                <input
                                    v-if="!numbers[indexRow - 1][indexCol - 1].hidden"
                                    v-model="numbers[indexRow - 1][indexCol - 1].number"
                                    size="3"
                                    class="inputField"
                                    style="background-color: white"
                                    readonly
                                    aria-hidden="true"
                                    :style="sizeState === 0 ? '' : sizeState === 1 ? 'width: 75px' : 'width: 85px'"
                                >
                                <input
                                    v-else
                                    v-model="numbers[indexRow - 1][indexCol - 1].solution"
                                    size="3"
                                    class="inputField"
                                    style="background-color: white"
                                    :style="sizeState === 0 ? '' : sizeState === 1 ? 'width: 75px' : 'width: 85px'"
                                    :aria-label="getAriaLabelNumber(indexRow - 1)"
                                >
                            </div>
                        </div>
                        <!-- Operatoren -->
                        <div style="display: flex; flex-direction: column; flex-wrap: nowrap; margin-left: 10px">
                            <div
                                v-if="!(indexCol === numbers[0].length)"
                                class="mx-auto"
                                :style="sizeState === 0 ? '' : sizeState === 1 ? '' : 'margin-top: 10px'"
                            >
                                <div
                                    v-for="indexRow in operators.length"
                                    :key="indexRow"
                                    style="display: flex; flex-direction: column; flex-wrap: nowrap;"
                                    :style="sizeState === 0 ? 'height: 52px' : sizeState === 1 ? 'height: 64px' : 'height: 76px'"
                                >
                                    <v-fab
                                        v-if="!operators[indexRow - 1][indexCol - 1].hidden"
                                        class="mx-auto"
                                        style="background-color: transparent !important;"
                                        color="#ededed"
                                        variant="flat"
                                        aria-hidden="true"
                                        :style="sizeState === 0 ? 'font-size: 28px' : sizeState === 1 ? 'font-size: 40px' : 'font-size: 52px'"
                                    >
                                        <span style="color: black">{{ operators[indexRow - 1][indexCol - 1].operator }}</span>
                                    </v-fab>
                                    <v-select
                                        v-else
                                        v-model="operators[indexRow - 1][indexCol - 1].solution"
                                        :items="calculationOperators"
                                        class="pt-0"
                                        style="width: 56px"
                                        aria-label="Operatorauswahl"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Results -->
                    <div style="display: flex; flex-direction: column; flex-wrap: nowrap; margin: 12px 4px 12px 4px;">
                        <div
                            v-for="indexRow in results.length"
                            :key="indexRow"
                            style="display: flex"
                            :style="sizeState === 0 ? 'height: 52px' : sizeState === 1 ? 'height: 64px' : 'height: 76px'"
                        >
                            <span
                                style="padding: 0px 10px 24px 0px"
                                :style="sizeState === 0 ? 'font-size: 28px' : sizeState === 1 ? 'font-size: 40px' : 'font-size: 52px; margin-top: -4px'"
                                aria-hidden="true"
                            >=</span>
                        </div>
                    </div>
                    <div
                        style="display: flex; flex-direction: column; flex-wrap: nowrap; margin: 12px 4px"
                        :style="sizeState === 0 ? 'font-size: medium' : sizeState === 1 ? 'font-size: x-large' : 'font-size: xx-large'"
                    >
                        <div
                            v-for="indexRow in results.length"
                            :key="indexRow"
                        >
                            <input
                                v-if="!results[indexRow - 1].hidden"
                                v-model="results[indexRow - 1].number"
                                size="3"
                                class="inputField"
                                style="background-color: white"
                                readonly
                                aria-hidden="true"
                                :style="sizeState === 0 ? '' : sizeState === 1 ? 'width: 75px' : 'width: 85px'"
                            >
                            <input
                                v-else
                                v-model="results[indexRow - 1].solution"
                                size="3"
                                type="text"
                                class="inputField"
                                style="background-color: white"
                                :aria-label="getAriaLabelResult(indexRow - 1)"
                                :style="sizeState === 0 ? '' : sizeState === 1 ? 'width: 75px' : 'width: 85px'"
                                @loadeddata="changeResultWidthPupil(indexRow - 1)"
                            >
                        </div>
                    </div>
                </div>
            </v-container>
        </div>

        <!-- Correction or Viewer View -->
        <div
            v-else-if="showCorrectionUI || showViewerUI"
            class="calculationOuter"
            @click="$emit('focusMe')"
        >
            <v-container
                fluid
                class="pa-0"
            >
                <!-- Rechnungen -->
                <div
                    style="display: flex"
                    :style="sizeState === 0 ? 'font-size: medium' : sizeState === 1 ? 'font-size: x-large' : 'font-size: xx-large'"
                >
                    <div
                        v-for="indexCol in numbers[0].length"
                        :key="indexCol"
                        style="display: flex; flex-direction: row; flex-wrap: nowrap; padding: 12px 4px"
                    >
                        <div style="display: flex; flex-direction: column; flex-wrap: nowrap">
                            <div
                                v-for="indexRow in numbers.length"
                                :key="indexRow"
                            >
                                <input
                                    v-if="!numbers[indexRow - 1][indexCol - 1].hidden"
                                    v-model="numbers[indexRow - 1][indexCol - 1].number"
                                    size="3"
                                    class="inputField"
                                    style="background-color: white"
                                    readonly
                                >
                                <div
                                    v-else
                                    style="display: flex; flex-direction: column"
                                >
                                    <input
                                        v-if="numbers[indexRow - 1][indexCol - 1].number.toString() === numbers[indexRow - 1][indexCol - 1].solution"
                                        v-model="numbers[indexRow - 1][indexCol - 1].solution"
                                        size="3"
                                        class="inputField answerCorrect"
                                        readonly
                                        style="background-color: white"
                                        :style="sizeState === 0 ? '' : sizeState === 1 ? 'width: 75px' : 'width: 85px'"
                                    >
                                    <v-tooltip
                                        v-else
                                        location="top"
                                        :disabled="!elementOnCurrentPage"
                                    >
                                        <template v-slot:activator="{ props }">
                                            <input
                                                v-if="numbers[indexRow - 1][indexCol - 1].showSolution"
                                                v-model="numbers[indexRow - 1][indexCol - 1].number"
                                                size="3"
                                                style="cursor: pointer"
                                                class="inputField answerWrongCorrectShown"
                                                readonly
                                                :style="sizeState === 0 ? '' : sizeState === 1 ? 'width: 75px' : 'width: 85px'"
                                                v-on="on"
                                                @click="showSolutionNumber(indexRow - 1, indexCol - 1)"
                                            >
                                            <input
                                                v-else
                                                v-model="numbers[indexRow - 1][indexCol - 1].solution"
                                                size="3"
                                                style="cursor: pointer"
                                                class="inputField answerWrongCorrectHidden"
                                                readonly
                                                :style="sizeState === 0 ? '' : sizeState === 1 ? 'width: 75px' : 'width: 85px'"
                                                v-bind="props"
                                                @click="showSolutionNumber(indexRow - 1, indexCol - 1)"
                                            >
                                        </template>
                                        <span>{{ numbers[indexRow - 1][indexCol - 1].showSolution ? 'Lösung verstecken' : 'Lösung anzeigen' }}</span>
                                    </v-tooltip>
                                </div>
                            </div>
                        </div>
                        <div style="display: flex; flex-direction: column; flex-wrap: nowrap; margin-left: 10px">
                            <div
                                v-if="!(indexCol === numbers[0].length)"
                                class="mx-auto"
                                :style="sizeState === 0 ? '' : sizeState === 1 ? 'margin-bottom: 4px' : 'margin-bottom: 4px'"
                            >
                                <div
                                    v-for="indexRow in operators.length"
                                    :key="indexRow"
                                    style="display: flex; flex-direction: column; flex-wrap: nowrap;"
                                    :style="sizeState === 0 ? 'height: 52px' : sizeState === 1 ? 'height: 64px' : 'height: 76px'"
                                >
                                    <v-fab
                                        v-if="!operators[indexRow - 1][indexCol - 1].hidden"
                                        class="mx-auto"
                                        style="width: 30px; height: 30px; font-size: xx-large; color: rgb(var(--v-theme-editorGrey)); margin-top: 11px"
                                        color="#ededed"
                                        variant="flat"
                                        :style="sizeState === 0 ? 'font-size: 28px; margin-top: 11px' : sizeState === 1 ? 'font-size: 40px; margin-top: 11px' : 'font-size: 52px; margin-top: 11px'"
                                    >
                                        <span style="color: black">{{ operators[indexRow - 1][indexCol - 1].operator }}</span>
                                    </v-fab>
                                    <div
                                        v-else
                                        style="display: flex; flex-direction: column; font-size: x-large;"
                                        :style="sizeState === 0 ? 'height: 52px' : sizeState === 1 ? 'height: 64px' : 'height: 76px'"
                                    >
                                        <v-fab
                                            v-if="operators[indexRow - 1][indexCol - 1].operator === operators[indexRow - 1][indexCol - 1].solution"
                                            class="mx-auto"
                                            variant="flat"
                                            :style="sizeState === 0 ? 'font-size: 28px; height: 30px; width: 30px; margin-top: 11px' : sizeState === 1 ? 'font-size: 40px; height: 40px; width: 40px; margin-top: 11px' : 'font-size: 52px; height: 52px; width: 52px; margin-top: 11px'"
                                        >
                                            <span class="editorColorPrimary">{{ operators[indexRow - 1][indexCol - 1].operator }}</span>
                                        </v-fab>
                                        <v-tooltip
                                            v-else
                                            location="top"
                                            :disabled="!elementOnCurrentPage"
                                        >
                                            <template v-slot:activator="{ props }">
                                                <v-fab
                                                    v-if="operators[indexRow - 1][indexCol - 1].showSolution"
                                                    class="mx-auto answerWrongCorrectShown"
                                                    style="width: 30px; height: 30px; font-size: xx-large; margin-top: 11px"
                                                    variant="flat"
                                                    :style="sizeState === 0 ? 'font-size: 28px; height: 30px; width: 30px; margin-top: 11px' : sizeState === 1 ? 'font-size: 40px; height: 40px; width: 40px; margin-top: 11px' : 'font-size: 52px; height: 52px; width: 52px; margin-top: 11px'"
                                                    @click="showSolutionOperator(indexRow - 1, indexCol - 1)"
                                                    v-on="on"
                                                >
                                                    <span>{{ operators[indexRow - 1][indexCol - 1].operator }}</span>
                                                </v-fab>
                                                <v-fab
                                                    v-else
                                                    class="mx-auto answerWrongCorrectHidden"
                                                    style="width: 30px; height: 30px; font-size: xx-large; margin-top: 11px"
                                                    variant="flat"
                                                    :style="sizeState === 0 ? 'font-size: 28px; height: 30px; width: 30px; margin-top: 11px' : sizeState === 1 ? 'font-size: 40px; height: 40px; width: 40px; margin-top: 11px' : 'font-size: 52px; height: 52px; width: 52px; margin-top: 11px'"
                                                    v-bind="props"
                                                    @click="showSolutionOperator(indexRow - 1, indexCol - 1)"
                                                >
                                                    <span>{{ operators[indexRow - 1][indexCol - 1].solution }}</span>
                                                </v-fab>
                                            </template>
                                            <span>{{ operators[indexRow - 1][indexCol - 1].showSolution ? 'Lösung verstecken' : 'Lösung anzeigen' }}</span>
                                        </v-tooltip>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Results -->
                    <div style="display: flex; flex-direction: column; flex-wrap: nowrap; margin: 12px 4px 12px 4px;">
                        <div
                            v-for="indexRow in results.length"
                            :key="indexRow"
                            style="display: flex; height: 52px"
                        >
                            <span style="font-size: xx-large; padding: 0px 10px 24px 0px">=</span>
                        </div>
                    </div>
                    <div style="display: flex; flex-direction: column; flex-wrap: nowrap; margin: 12px 4px">
                        <div
                            v-for="indexRow in results.length"
                            :key="indexRow"
                            style="display: flex"
                        >
                            <input
                                v-if="!results[indexRow - 1].hidden"
                                v-model="results[indexRow - 1].number"
                                size="3"
                                class="inputField"
                                style="background-color: white"
                                readonly
                            >
                            <div
                                v-else
                                style="display: flex; flex-direction: column"
                            >
                                <input
                                    v-if="results[indexRow - 1].number.toString() === results[indexRow - 1].solution"
                                    v-model="results[indexRow - 1].solution"
                                    size="3"
                                    class="inputField answerCorrect"
                                    readonly
                                >
                                <v-tooltip
                                    v-else
                                    location="top"
                                    :disabled="!elementOnCurrentPage"
                                >
                                    <template v-slot:activator="{ props }">
                                        <input
                                            v-if="results[indexRow - 1].showSolution"
                                            v-model="results[indexRow - 1].number"
                                            size="3"
                                            style="cursor: pointer"
                                            class="inputField answerWrongCorrectShown"
                                            readonly
                                            v-on="on"
                                            @click="showSolutionResult(indexRow - 1)"
                                        >
                                        <input
                                            v-else
                                            v-model="results[indexRow - 1].solution"
                                            size="3"
                                            style="cursor: pointer"
                                            class="inputField answerWrongCorrectHidden"
                                            readonly
                                            v-bind="props"
                                            @click="showSolutionResult(indexRow - 1)"
                                        >
                                        <!--                            <span v-if="results[indexRow - 1].hidden && results[indexRow - 1].number.toString() !== results[indexRow - 1].solution"-->
                                        <!--                                  style="width: fit-content; height: 12px; max-height: 12px; margin-top: -4px; margin-bottom: 4px; color: green" class="mx-auto">-->
                                        <!--                                    {{ results[indexRow - 1].number }}-->
                                        <!--                                </span>-->
                                    </template>
                                    <span>{{ results[indexRow - 1].showSolution ? 'Lösung verstecken' : 'Lösung anzeigen' }}</span>
                                </v-tooltip>
                            </div>
                        </div>
                    </div>
                </div>
            </v-container>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import aktualisierenIcon from "../../../../assets/Icons/aktualisieren-green-107.svg";
import eyeIconClosed from "../../../../assets/Icons/eye-off.svg";
import eyeIconOpen from "../../../../assets/Icons/eye.svg";
import sizeSmallIcon from "@/assets/Icons/EditorRedesign/schrift_klein.svg";
import sizeMediumIcon from "@/assets/Icons/EditorRedesign/schrift_mittel.svg";
import sizeLargeIcon from "@/assets/Icons/EditorRedesign/schrift_groß.svg";
import {v4 as uuidv4} from "uuid";

export default {
    name: 'EditorCalculation',
    props: {
        config: { type: Object, required: true },
        state: { type: String, required: true },
        index: { type: Number, required: true },
        targetLang: { type: String, required: false, default: 'de' },
        translate: { type: Boolean, required: false, default: false },
        exportMode: { type: Boolean, required: false, default: false },
        tabIndex: { type: Number, required: false, default: 0 },
        canvasWidth: {type: Number, required: true},
        canvasHeight: {type: Number, required: true},
        elementOnCurrentPage: { type: Boolean, required: false },
        showMenu: { type: Boolean, required: false, default: false },
        previewPupilUI: { type: Boolean, required: false, default: false },
    },
    data() {
        return {
            numbers: [
                [{number: 3, hidden: false}, {number: 4, hidden: false}],
                [{number: 1, hidden: false}, {number: 1, hidden: false}],
                [{number: 2, hidden: false}, {number: 0, hidden: false}],
            ],

            calculationOperators: ['+','-','*'],
            selectedOperator: '+',
            operators: [
                [{operator: '+', hidden: false}],
                [{operator: '+', hidden: false}],
                [{operator: '+', hidden: false}],
            ],

            results: [
                {number: 7, hidden: false},
                {number: 2, hidden: false},
                {number: 2, hidden: false}
            ],

            range: 10,
            selectableRanges: [10, 100, 1000],

            // showMenu: false,
            // showMenuReentered: false,

            sizeState: 0,
            done: false,

            // screen info
            // can use this to reduce select elements conditionally, but might need some unification on margins first
            // i stopped resizing the component halfway through because most elements rely on specific intervals of sizeState increases and it grows out of sync quickly
            // also because it has duped divs for views.. sry
            screenX: null,
            screenY: null,
            smallUI: false,

            // Icons
            aktualisierenIcon,
            eyeIconClosed,
            eyeIconOpen,
            sizeSmallIcon,
            sizeMediumIcon,
            sizeLargeIcon,
        };
    },
    computed: {
        // ...mapGetters("auth",["accountRole"]),
        currentSizeIcon() {
            return this.sizeState ? (this.sizeState === 2 ? this.sizeLargeIcon : this.sizeMediumIcon) : this.sizeSmallIcon;
        },

        showPupilUI() {
            return this.exportMode || this.state === 'pupil' || this.previewPupilUI;
        },
        showTeacherUI() {
            return !this.exportMode && !this.previewPupilUI && this.state === 'creator';
        },
        showCorrectionUI() {
            return this.state === 'teacher' || this.state === 'viewerTeacher';
        },
        showViewerUI() {
            return this.state === 'viewer' || this.state === 'viewerPupil';
        },
    },
    watch: {
        numbers: {
            handler(val, oldVal) {
                this.recalculate();
                this.saveState();
            },
            deep: true
        },
        operators: {
            handler(val, oldVal) {
                this.recalculate();
                this.saveState();
            },
            deep: true
        },
        results: {
            handler(val, oldVal) {
                this.saveState();
            },
            deep: true
        },
        sizeState: {
            handler(val, oldVal) {
                this.saveState();
            }
        },
        previewPupilUI: {
            handler(val, oldVal) {
                for(let indexRow = 0; indexRow < this.numbers.length; indexRow++) {
                    for(let indexCol = 0; indexCol < this.numbers[indexRow].length; indexCol++) {
                        delete this.numbers[indexRow][indexCol].solution;
                    }
                }
                for(let indexRow = 0; indexRow < this.operators.length; indexRow++) {
                    for(let indexCol = 0; indexCol < this.operators[indexRow].length; indexCol++) {
                        delete this.operators[indexRow][indexCol].solution;
                    }
                }
                for(let indexRow = 0; indexRow < this.results.length; indexRow++) {
                    delete this.results[indexRow].solution;
                }
            }
        }
    },
    mounted() {
        this.evalScreenSize();

        if (!this.config.calcConfig) {
            this.config.calcConfig = {};
            this.config.identifier = uuidv4();
            this.config.calcConfig.numbers = this.numbers;
            this.config.calcConfig.operators = this.operators;
            this.config.calcConfig.results = this.results;
            this.config.calcConfig.sizeState = this.sizeState;
        } else {
            if(this.config._id) {
                this.config.identifier = this.config._id;
            }
            this.numbers = this.config.calcConfig.numbers;
            this.operators = this.config.calcConfig.operators;
            this.results = this.config.calcConfig.results;
            this.sizeState = this.config.calcConfig.sizeState;
        }

        // Allen numbers/operators/results die hidden sind ein 'solution' Feld hinzufügen
        for(let indexRow = 0; indexRow < this.numbers.length; indexRow++) {
            for(let indexCol = 0; indexCol < this.numbers[indexRow].length; indexCol++) {
                if(this.numbers[indexRow][indexCol].hidden && !this.numbers[indexRow][indexCol].solution) {
                    this.$set(this.numbers[indexRow], indexCol, {number: this.numbers[indexRow][indexCol].number, hidden: this.numbers[indexRow][indexCol].hidden, solution: ''})
                }
            }
        }
        for(let indexRow = 0; indexRow < this.operators.length; indexRow++) {
            for(let indexCol = 0; indexCol < this.operators[indexRow].length; indexCol++) {
                if(this.operators[indexRow][indexCol].hidden  && !this.operators[indexRow][indexCol].solution) {
                    this.$set(this.operators[indexRow], indexCol, {operator: this.operators[indexRow][indexCol].operator, hidden: this.operators[indexRow][indexCol].hidden, solution: ''})
                }
            }
        }
        for(let indexRow = 0; indexRow < this.results.length; indexRow++) {
            if(this.results[indexRow].hidden && !this.results[indexRow].solution) {
                this.$set(this.results, indexRow, {number: this.results[indexRow].number, hidden: this.results[indexRow].hidden, solution: ''})
            }
        }

        if(this.state === 'pupil') {
            this.done = true;
        }
        this.saveState();
    },
    methods: {
        getAriaLabelNumber(indexRow) {
            let calculationString = '';
            if(this.numbers[indexRow][0].hidden) {
                calculationString = 'versteckte Zahl'
            } else {
                calculationString = this.numbers[indexRow][0].number;
            }
            for(let indexCol = 1; indexCol < this.numbers[indexRow].length; indexCol++) {
                if(indexCol < this.numbers[indexRow].length) {
                    if(this.operators[indexRow][indexCol - 1].hidden) {
                        calculationString += ' versteckter Operator'
                    } else {
                        calculationString += ' ' + this.operators[indexRow][indexCol - 1].operator;
                    }
                    if(this.numbers[indexRow][indexCol].hidden) {
                        calculationString += ' versteckte Zahl'
                    } else {
                        calculationString += ' ' + this.numbers[indexRow][indexCol].number;
                    }
                } else {
                    if(this.numbers[indexRow][indexCol].hidden) {
                        calculationString += ' versteckte Zahl'
                    } else {
                        calculationString += ' ' + this.numbers[indexRow][indexCol].number;
                    }
                }
            }
            if(this.results[indexRow].hidden) {
                return calculationString + ' = verstecktes Ergebnis';
            } else {
                return calculationString + ' = ' + this.results[indexRow].number;
            }
        },

        getAriaLabelResult(indexRow) {
            let calculationString = this.numbers[indexRow][0].number;
            for(let indexCol = 1; indexCol < this.numbers[indexRow].length; indexCol++) {
                if(indexCol < this.numbers[indexRow].length) {
                    calculationString += ' ' + this.operators[indexRow][indexCol - 1].operator;
                    calculationString += ' ' + this.numbers[indexRow][indexCol].number;
                } else {
                    calculationString += ' ' + this.numbers[indexRow][indexCol].number;
                }
            }
            return calculationString + ' = ';
        },

        changeInputWidth(indexRow, indexCol) {
            const el = document.getElementById(this.config._id + '-numberInput-' + indexRow + '-' + indexCol);
            let width = (el.value.length + 3);

            if(!width < 6) {
                el.style.width = width + "ch"
            }
        },

        changeResultWidthPupil(indexRow) {

        },

        changeResultWidth(indexRow) {
            const el = document.getElementById(this.config._id + '-resultInput-' + indexRow);
            el.style.width = (el.value.length + 4) + "ch"
        },

        changeSize() {
            switch (this.sizeState) {
                case 0:
                    this.sizeState = 1;
                    break;
                case 1:
                    this.sizeState = 2;
                    break;
                case 2:
                    this.sizeState = 0;
                    break;
                default:
                    break;
            }
        },
        showSolutionNumber(indexRow, indexCol) {
            let showSolution = false;
            if(this.numbers[indexRow][indexCol].showSolution === undefined) {
                showSolution = true;
            } else {
                showSolution = !this.numbers[indexRow][indexCol].showSolution;
            }

            this.$set(this.numbers[indexRow], indexCol, {
                number: this.numbers[indexRow][indexCol].number,
                hidden: this.numbers[indexRow][indexCol].hidden,
                solution: this.numbers[indexRow][indexCol].solution,
                showSolution: showSolution,
            });
        },

        showSolutionOperator(indexRow, indexCol) {
            let showSolution = false;
            if(this.operators[indexRow][indexCol].showSolution === undefined) {
                showSolution = true;
            } else {
                showSolution = !this.operators[indexRow][indexCol].showSolution;
            }

            this.$set(this.operators[indexRow], indexCol, {
                operator: this.operators[indexRow][indexCol].operator,
                hidden: this.operators[indexRow][indexCol].hidden,
                solution: this.operators[indexRow][indexCol].solution,
                showSolution: showSolution,
            });
        },

        showSolutionResult(indexRow) {
            let showSolution = false;
            if(this.results[indexRow].showSolution === undefined) {
                showSolution = true;
            } else {
                showSolution = !this.results[indexRow].showSolution;
            }

            this.$set(this.results, indexRow, {
                number: this.results[indexRow].number,
                hidden: this.results[indexRow].hidden,
                solution: this.results[indexRow].solution,
                showSolution: showSolution,
            });
        },
        switchSingleOperator(indexRow, indexCol) {
            let index = this.calculationOperators.indexOf(this.operators[indexRow][indexCol].operator);
            let newOperator;
            if(index === this.calculationOperators.length - 1) {
                newOperator = this.calculationOperators[0];
            } else {
                newOperator = this.calculationOperators[index + 1];
            }
            this.$set(this.operators[indexRow], indexCol, {operator: newOperator, hidden: this.operators[indexRow][indexCol].hidden})
            this.recalculate();
        },

        colHidden(indexCol) {
            for(let indexRow = 0; indexRow < this.numbers.length; indexRow++) {
                if(!this.numbers[indexRow][indexCol].hidden) {
                    return false;
                }
            }
            return true;
        },

        hideCol(indexCol) {
            for(let indexRow = 0; indexRow < this.numbers.length; indexRow++) {
                this.$set(this.numbers[indexRow], indexCol, {number: this.numbers[indexRow][indexCol].number, hidden: true})
            }
        },

        showCol(indexCol) {
            for(let indexRow = 0; indexRow < this.numbers.length; indexRow++) {
                this.$set(this.numbers[indexRow], indexCol, {number: this.numbers[indexRow][indexCol].number, hidden: false})
            }
        },

        colOperatorHidden(indexCol) {
            for(let indexRow = 0; indexRow < this.operators.length; indexRow++) {
                if(!(this.operators[indexRow][indexCol].hidden)) {
                    return false;
                }
            }
            return true;
        },

        hideOperatorCol(indexCol) {
            for(let indexRow = 0; indexRow < this.operators.length; indexRow++) {
                this.$set(this.operators[indexRow], indexCol, {operator: this.operators[indexRow][indexCol].operator, hidden: true})
            }
        },

        showOperatorCol(indexCol) {
            for(let indexRow = 0; indexRow < this.operators.length; indexRow++) {
                this.$set(this.operators[indexRow], indexCol, {operator: this.operators[indexRow][indexCol].operator, hidden: false})
            }
        },

        colResultHidden() {
            for(let indexRow = 0; indexRow < this.results.length; indexRow++) {
                if(!(this.results[indexRow].hidden)) {
                    return false;
                }
            }
            return true;
        },

        hideResultCol() {
            for(let indexRow = 0; indexRow < this.results.length; indexRow++) {
                this.$set(this.results, indexRow, {number: this.results[indexRow].number, hidden: true})
            }
        },

        showResultCol() {
            for(let indexRow = 0; indexRow < this.results.length; indexRow++) {
                this.$set(this.results, indexRow, {number: this.results[indexRow].number, hidden: false})
            }
        },

        switchOperator() {
            let index = this.calculationOperators.indexOf(this.selectedOperator);
            if(index === this.calculationOperators.length - 1) {
                this.selectedOperator = this.calculationOperators[0];
            } else {
                this.selectedOperator = this.calculationOperators[index + 1];
            }

            for(let indexRow = 0; indexRow < this.numbers.length; indexRow++) {
                for(let indexCol = 0; indexCol < this.numbers[0].length - 1; indexCol++) {
                    this.$set(this.operators[indexRow], indexCol, {operator: this.selectedOperator, hidden: false})
                }
            }

            this.recalculate()
        },

        addCol() {
            this.pushToHistory();

            for(let i = 0; i < this.numbers.length; i++) {
                this.numbers[i].unshift({number: this.getRandomIntInclusive(0, this.range), hidden: false})
                this.operators[i].unshift({operator: this.selectedOperator, hidden: false})
            }
            this.recalculate();
        },

        removeCol() {
            if(this.numbers[0].length > 2) {
                for(let i = 0; i < this.numbers.length; i++) {
                    this.numbers[i].shift();
                    this.operators[i].shift();
                }
            }
        },

        addRow() {
            this.pushToHistory();

            let newRow = [];
            let operatorsNewRow = [];
            for(let i = 0; i < this.numbers[0].length; i++) {
                this.$set(newRow, i, {number: this.getRandomIntInclusive(0, this.range), hidden: this.numbers[this.numbers.length - 1][i].hidden});
                if(i !== this.numbers[0].length - 1) {
                    this.$set(operatorsNewRow, i, {
                        operator: this.selectedOperator,
                        hidden: this.operators[this.operators.length - 1][i].hidden
                    });
                }
            }
            this.numbers.push(newRow);
            this.operators.push(operatorsNewRow)
        },

        removeRow() {
            if(this.numbers.length > 1) {
                this.numbers.pop();
                this.operators.pop();
                this.results.pop();
            }
        },

        increaseRange() {
            if(this.range !== this.selectableRanges[this.selectableRanges.length - 1]) {
                this.range = this.selectableRanges[this.selectableRanges.indexOf(this.range) + 1];
            }
        },

        reduceRange() {
            if(this.range !== this.selectableRanges[0]) {
                this.range = this.selectableRanges[this.selectableRanges.indexOf(this.range) - 1];
            }
        },

        /**
         * Diese Methode ist ziemlicher Abfall und unter Zeitdruck hingeschmotzt
         * ToDo: Methode mit einem geeigneten Algorithmus überarbeiten und vorallem für * und / anpassen
         */
        randomizeInput() {
            for(let indexRow = 0; indexRow < this.numbers.length; indexRow++) {
                let pool = this.range;
                let newRow = [];

                for(let indexCol = 0; indexCol < this.numbers[indexRow].length; indexCol++) {
                    if(newRow.length === 0) {
                        newRow.push(this.getRandomIntInclusive(0, pool));
                    } else if(this.operators[indexRow][indexCol - 1].operator === '+') {
                        newRow.push(this.getRandomIntInclusive(0, pool - newRow[indexCol - 1]))
                    } else {
                        let zwischenErgebnis = this.range + 1;
                        newRow.push(this.getRandomIntInclusive(0, pool));

                        while(zwischenErgebnis > this.range || zwischenErgebnis < 0 ) {
                            this.$set(newRow, indexCol, this.getRandomIntInclusive(0, pool - newRow[indexCol - 1] ));
                            zwischenErgebnis = newRow[0];
                            for(let i = 1; i < newRow.length; i++) {
                                switch (this.operators[indexRow][i - 1].operator) {
                                    case '+':
                                        zwischenErgebnis += newRow[i];
                                        break;
                                    case '-':
                                        zwischenErgebnis -= newRow[i];
                                        break;
                                    case '*':
                                        zwischenErgebnis *= newRow[i];
                                        break;
                                    case '/':
                                        zwischenErgebnis /= newRow[i];
                                        break;
                                }
                            }
                        }
                    }
                    this.$set(this.numbers[indexRow], indexCol, {number: newRow[indexCol], hidden: this.numbers[indexRow][indexCol].hidden})
                }
            }
            this.recalculate();
        },

        randomizeCol(indexCol) {
            for(let indexRow = 0; indexRow < this.numbers.length; indexRow++) {
                this.$set(this.numbers[indexRow], indexCol, {number: this.getRandomIntInclusive(0, this.range), hidden: this.numbers[indexRow][indexCol].hidden})
            }
            this.recalculate();
        },

        randomizeOperatorCol(indexCol) {
            for(let indexRow = 0; indexRow < this.operators.length; indexRow++) {
                this.$set(this.operators[indexRow], indexCol, {operator: this.calculationOperators[this.getRandomIntInclusive(0, this.calculationOperators.length - 1)], hidden: this.operators[indexRow][indexCol].hidden})
            }
            this.recalculate();
        },

        getRandomIntInclusive(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min +1)) + min;
        },

        recalculate() {
            for(let indexRow = 0; indexRow < this.numbers.length; indexRow++) {
                let result = parseInt(this.numbers[indexRow][0].number);
                for(let indexCol = 1; indexCol < this.numbers[0].length; indexCol++) {
                    if(indexCol !== this.numbers[0].length) {
                        switch (this.operators[indexRow][indexCol - 1].operator) {
                            case '+':
                                result += parseInt(this.numbers[indexRow][indexCol].number);
                                break;
                            case '-':
                                result -= parseInt(this.numbers[indexRow][indexCol].number);
                                break;
                            case '*':
                                result *= parseInt(this.numbers[indexRow][indexCol].number);
                                break;
                            case '/':
                                result /= parseInt(this.numbers[indexRow][indexCol].number);
                                break;
                        }
                    }
                }
                if(isNaN(result)) {
                    result = '';
                }
                if(this.results[indexRow]) {
                    if(this.results[indexRow].hidden !== undefined && this.results[indexRow].solution) {
                        this.$set(this.results, indexRow, {number: result, hidden: this.results[indexRow].hidden, solution: this.results[indexRow].solution});
                    } else if(this.results[indexRow].hidden !== undefined && !this.results[indexRow].solution) {
                        this.$set(this.results, indexRow, {number: result, hidden: this.results[indexRow].hidden});
                    }
                } else {
                    this.$set(this.results, indexRow, { number: result, hidden: false});
                }
            }
        },

        saveState() {
            // save values by overwriting the calcConfig properties
            // which get saved in the db schoolSubjectAppointmentUploadMessage
            if(!this.previewPupilUI) {
                this.config.calcConfig.numbers = this.numbers;
                this.config.calcConfig.operators = this.operators;
                this.config.calcConfig.results = this.results;
                this.config.calcConfig.sizeState = this.sizeState;
            }
        },
        evalScreenSize() {
            let win = window;
            let doc = document;
            let docElem = doc.documentElement;
            let body = doc.getElementsByTagName('body')[0];
            this.screenX = win.innerWidth || docElem.clientWidth || body.clientWidth;
            this.screenY = win.innerHeight|| docElem.clientHeight|| body.clientHeight;
            if(this.screenX < 800 || this.screenY < 800) {
                this.smallUI = true;
            }
            // alert(this.screenX + ' × ' + this.screenY);
        },

        pushToHistory() {
            this.$emit('history', { calcConfig: this.config.calcConfig });
        }
    }
}
</script>

<style scoped lang="scss">
    .calculationOuter {
        z-index: 7 !important;
        //max-width: 300px; > Caused resize issue
    }

    .inputField {
      width: 55px;
      padding: 0px 12px;
      margin: 12px;
      border-style: solid;
      border-color: black;
      border-radius: 6px
    }

    .editorColorPrimaryBackgroundOnHover:hover .backgroundToWhite {
      background-color: white !important;
    }

    .editorColorPrimaryBackgroundOnHover:hover .backgroundToEditor {
      background-color: rgb(var(--v-theme-editorprimary)) !important;
    }

    .answerCorrect {
      color: rgb(var(--v-theme-editorprimary));
    }

    .answerWrongCorrectHidden {
      background-color: rgb(var(--v-theme-editorWrong)) !important;
      color: white;
    }

    .answerWrongCorrectShown {
      background-color: rgb(var(--v-theme-editorprimary)) !important;
      color: white;
    }

    .showOnParentHover {
      opacity: 0;
    }

    .editorColorPrimaryBackgroundOnHover:hover .showOnParentHover {
      opacity: 100;
    }

    .controlsFaded {
        margin: 56px 0 0 56px;
    }
</style>
