<template>
    <div style="display: inline-flex; width: 100%">
        <div
            v-if="!eventFromMe"
            style="position: absolute; top: 30px; right: -30px"
        >
            <div
                :ref="`menuBtns${chatRoomEvent._id}`"
                class="hideMenuBtns d-flex flex-column align-center"
            >
                <v-tooltip
                    :disabled="!showTooltips"
                    location="top"
                    style="z-index: 9;"
                >
                    <template v-slot:activator="{ props }">
                        <v-btn
                            :aria-hidden="true"
                            elevation="2"
                            class=" mb-2 pa-0 prchatRoomEventColorInvert chatMessageBtn"
                            v-bind="props"
                            @click="greet(chatRoomEvent, $event)"
                        >
                            <img
                                :src="isReading ? lautsprecherAusIcon : lautsprecherIcon"
                                :alt="isReading ? 'Vorlesen stoppen' : 'Nachricht vorlesen'"
                                style="height: 20px"
                            >
                        </v-btn>
                    </template>
                    <span>{{ isReading ? 'Vorlesen stoppen' : 'Nachricht vorlesen' }}</span>
                </v-tooltip>
                <v-tooltip
                    v-if="targetLang !== 'de'"
                    :disabled="!showTooltips"
                    location="top"
                    style="z-index: 9;"
                >
                    <template v-slot:activator="{ props }">
                        <v-btn
                            :aria-hidden="true"
                            elevation="2"
                            class="mb-2 pa-0 prchatRoomEventColorInvert chatMessageBtn"
                            v-bind="props"
                            @click="greet(chatRoomEvent)"
                        >
                            <img
                                :src="flagIcon"
                                :alt="isReading ? 'Vorlesen stoppen' : 'Nachricht vorlesen'"
                                style="height: 20px"
                            >
                        </v-btn>
                    </template>
                    <span>Nachricht vorlesen</span>
                </v-tooltip>
                <ChatRoomEventListItemDeleteButton
                    v-if="chatRoomMembershipRole === 'moderator' && accountRole !== 'pupil'"
                    :chat-room-event="chatRoomEvent"
                />
            </div>
        </div>

        <div
            v-else
            style="position: absolute; top: 30px; left: -30px"
        >
            <div
                :ref="`menuBtns${chatRoomEvent._id}`"
                class="d-flex flex-column align-center hideMenuBtns"
            >
                <v-tooltip
                    :disabled="!showTooltips"
                    location="top"
                    style="z-index: 9;"
                >
                    <template v-slot:activator="{ props }">
                        <v-btn
                            :aria-hidden="true"
                            elevation="2"
                            class="pa-0 mb-1 prchatRoomEventColorInvert chatMessageBtn"
                            v-bind="props"
                            @click="greet(chatRoomEvent, $event)"
                        >
                            <img
                                :src="isReading ? lautsprecherAusIcon : lautsprecherIcon"
                                :alt="isReading ? 'Vorlesen stoppen' : 'Nachricht vorlesen'"
                                style="height:20px;"
                            >
                        </v-btn>
                    </template>
                    <span>Nachricht vorlesen</span>
                </v-tooltip>
               
                <ChatRoomEventListItemDeleteButton
                    v-if="eventFromMe || chatRoomMembershipRole === 'moderator' && accountRole !== 'pupil'"
                    :chat-room-event="chatRoomEvent"
                />
            </div>
        </div>
        <!-- Nachricht -->
        <!-- Disable v-html xss checking entry.content is provided only by developers -->
        <!-- eslint-disable vue/no-v-html -->
        <p
            tabindex="0"
            style="
        min-width: calc(100% - 25px);
        max-width: 225px;
        word-wrap: break-word;
        white-space: pre-wrap;
        line-height: 1.25rem;
      "
            class="mb-0"
            :style="{'color': fontColor}"
            @click="handleMsgClick(chatRoomEvent)"
            v-html="highlightedText"
        />
    </div>
</template>
<script>
import { mapActions, mapState, mapMutations } from "vuex";
import sanitizeHtml from "../../../../util/sanitizeHtml";

import ChatRoomEventListItemDeleteButton from "./ChatRoomEventListItemDeleteButton";

import lautsprecherIcon from "../../../../assets/Icons/lautsprecher-13.svg";
import lautsprecherAusIcon from "../../../../assets/Icons/lautsprecher-aus-89.svg";
import deFlagIcon from "@/assets/Icons/deutsch-59.svg";
import enFlagIcon from "@/assets/Icons/englisch-61.svg";
import frFlagIcon from "@/assets/Icons/französisch-60.svg";
import itFlagIcon from "@/assets/Icons/flag-italy.svg";
import ruFlagIcon from "@/assets/Icons/russisch.svg";
import roFlagIcon from "@/assets/Icons/flag-romania.svg";
import trFlagIcon from "@/assets/Icons/türkisch-97.svg";
import arFlagIcon from "@/assets/Icons/syrisch-98.svg";
import kuFlagIcon from "@/assets/Icons/flag-kurdistan.svg";
import ukFlagIcon from "@/assets/Icons/flag-ukraine.svg";
import deleteIcon from '@/assets/Icons/FaceliftIcons/loeschen_muelleimer.svg';
import urlify from '../../../../util/urlify';


export default {
    name: "ChatRoomEventListTextItem",
    components: {
        ChatRoomEventListItemDeleteButton,
    },
    props: {
        chatRoomEvent: { type: Object, required: true },
        chatRoomMembershipRole: { required: true, type: String },
        fontColor: { required: true, type: String },
        chatSearch:{ required: false, type: String, default:''},
    },
    data: () => ({
        sanitizeHtml,
        isReading: false,

        showTextTimeout: null,

        lautsprecherIcon,
        deFlagIcon,
        enFlagIcon,
        frFlagIcon,
        itFlagIcon,
        ruFlagIcon,
        roFlagIcon,
        trFlagIcon,
        arFlagIcon,
        kuFlagIcon,
        ukFlagIcon,
        deleteIcon,

        urlify,
    }),
 
    computed: {
        ...mapState("auth", ["account",'accountRole']),
        ...mapState("tooltips", ["showTooltips"]),
        ...mapState('translation', ['targetLang']),
        eventFromMe() {
            return this.chatRoomEvent.author === this.account._id;
        },
        flagIcon() {
           switch(this.targetLang) {
                case 'de':
                    return this.iconColor === 'white' ? this.lautsprecherWhite : this.lautsprecher;
                case 'en':
                    return this.enFlagIcon;
                case 'fr':
                    return this.frFlagIcon;
                case 'it':
                    return this.itFlagIcon;
                case 'ru':
                    return this.ruFlagIcon;
                case 'ro':
                    return this.roFlagIcon;
                case 'tr':
                    return this.trFlagIcon;
                case 'ar':
                    return this.arFlagIcon;
                // case 'ku':
                //     return this.kuFlagIcon;
                case 'uk':
                    return this.ukFlagIcon;
                default:
                    return this.deFlagIcon;
            }
        },
        highlightedText() {
            return this.getHighlightedText(this.sanitizeHtml(this.urlify(this.chatRoomEvent.text)), this.chatSearch);
        },
       
    },
    methods: {
        ...mapActions("translation", [
            "showTranslation",
            "setTranslatedText",
            "translateToTargetLang",
        ]),
        ...mapMutations("snackbar", ["showSnackbar"]),
        
        getHighlightedText(text, highlight) {
            if (!highlight) return text;
            const regex = new RegExp(`(${highlight})`, 'gi');
            const returnVal = text.replace(regex, '<span style="background-color:yellow; border-radius: 4px; color: black">$1</span>');
            return returnVal;
        },
        async greet(message, event) {
            const content = message.text;
            const author = message.authorDisplayName;
            const sentTime = new Date(message.sentTime);
            const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'};
            
            if ("speechSynthesis" in window) {
                // Speech Synthesis supported 🎉
            } else {
                // Speech Synthesis Not Supported 😣
                alert("Sorry, your browser doesn't support text to speech!");
                return;
            }

            if (window.speechSynthesis.speaking) {
                window.speechSynthesis.cancel();
                const element = this.$refs[`menuBtns${message._id}`];
                
                element.classList.add('hideMenuBtns');      
                if (event) event.target.src = lautsprecherIcon;
                
            } else {
                //  console.log({
                //     targetLang: this.targetLang,
                //     textToTranslate: message,
                //   })

                let textToRead = `Nachricht von ${author}, am ${sentTime.toLocaleDateString('de-DE', options)}, ${content}`;

                if (this.targetLang !== "de") {

                    textToRead = await this.translateToTargetLang({
                        targetLang: this.targetLang,
                        textToTranslate: textToRead,
                    });
                    this.setTranslatedText(textToRead);
                    this.showTranslation(true);

                    if (this.showTextTimeout) {
                        clearTimeout(this.showTextTimeout);
                        this.showTextTimeout = null;
                    }

                    this.showTextTimeout = setTimeout(() => {
                        this.showTranslation(false);
                        this.showTextTimeout = null;
                    }, 15000);
                }

                if (event) event.target.src = lautsprecherAusIcon;
                let msg = new SpeechSynthesisUtterance();

                if (this.isLangPackageAvailable()) {
                    msg.text = textToRead;
                    msg.lang = this.targetLang;
                    window.speechSynthesis.speak(msg);
                } else {
                    if (event) event.target.src = lautsprecherIcon;
                }

                msg.onend = () => {
                    if (event) event.target.src = lautsprecherIcon;
                };
            }
        },
        isLangPackageAvailable() {
            for (let i = 0; i < window.speechSynthesis.getVoices().length; i++) {
                if (
                    window.speechSynthesis.getVoices()[i].lang.includes(this.targetLang)
                ) {
                    return true;
                }
            }

            return false;
        },

        handleMsgClick(event) {
    const element = this.$refs[`menuBtns${event._id}`];
    if (element) {
        element.classList.remove('hideMenuBtns');

        const hideMenu = () => {
            if (!window.speechSynthesis.speaking) {
                element.classList.add('hideMenuBtns');
                window.speechSynthesis.onend = null; // Clean up the event listener
            } else {
                window.speechSynthesis.onend = () => {
                    element.classList.add('hideMenuBtns');
                    window.speechSynthesis.onend = null; // Clean up the event listener
                };
            }
        };

        setTimeout(hideMenu, 4000);
    }
}
    }

};
</script>
<style scoped>
.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%)
    hue-rotate(36deg) brightness(104%) contrast(105%);
}

.icon {
    height: 20px;
}
.hideMenuBtns {
    display: none !important;
}
.chatMessageBtn{
    min-width: 30px !important;
    max-width: 30px !important;
    min-height: 30px !important;
    max-height: 30px !important;
    background-color:white !important;
}
</style>
