<template>
    <v-app style="height: 90vh;">
        <!-- Universal Loading Bar -->
        <v-progress-linear
            indeterminate
            buffer-value="100"
            :color="backendReachable ? 'gruen' : 'error'"
            height="5"
            style="position: fixed; top: 0 !important; z-index: 203"
            :style="showLoading ? {} : {opacity: 0}"
            aria-hidden="true"
        />

        <!-- Universal Upload -->
        <progress-bar
            title="Hochladen"
            :show-progress="showPupilUploadProgress"
            :progress="pupilUploadProgress"
            :abort-progress="
                pupilXmlHttpRequest
                    ? () => {
                        pupilXmlHttpRequest.abort();
                    }
                    : () => {}
            "
            aria-hidden="true"
            @update:show-progress="showPupilUploadProgress = $event"
            @update:progress="pupilUploadProgress = $event"
        />

        <label
            style="display: none"
            for="uploadInput"
        >Datei hochladen</label>
        <input
            id="uploadInput"
            ref="uploadInput"
            type="file"
            hidden
            multiple
            @change="() => uploadInputChange()"
        >

        <!-- Primary NameFile, try forwarding all FileUploads and Handling to this Component -->
        <NameFile
            v-if="showNameFile"
            :show-dialog="showNameFile"
            :original-name="uploadFileName"
            :callback="fileNameCallback"
            :is-subtitle-ready="isSubtitleReady"
            :show-teamwork-checkbox="showTeamworkCheckbox"
            :group="currentUploadGroup === 'pupil' ? null : (currentlyOpenAppointment ? currentlyOpenAppointment.group : null)"
            :header-color="accountRole === 'pupil' ? 'rgb(var(--v-theme-timetableColor))' : 'rgb(var(--v-theme-headerblue))'"
            :show-is-assignment="
                !(
                    currentUploadGroup === 'teacherFolder' ||
                    currentUploadGroup === 'privateFolder' ||
                    currentUploadGroup === 'groupFolder' ||
                    currentUploadGroup === 'baby'
                )
            "
            @update:show-dialog="showNameFile = $event"
        />

        <!-- Universal Upload End -->

        <!--#endregion -->
        <UserSwitch
            v-if="developmentMode"
            style="z-index: 5"
        />
        
        <!-- Cookie Law needs to be above the router view or otherwise it wont load any content -->
        <!-- <cookie-law /> -->
        <router-view />

        <!-- Snackbar Area -->
        <snackbar />
        <v-card
            v-if="showTranslatedText"
            id="translatedTextShow"
            v-click-outside="closeTranslation"
            style="background-color: #343e55"
        >
            <v-card-text
                class="py-2"
                style="text-align: center"
            >
                <simple-bar
                    :auto-hide="false"
                    class="scroll-area-translated" 
                >
                    <!-- Disable vue/no-v-html because translatedText from google translate, propably not insecure -->
                    <!-- eslint-disable vue/no-v-html -->
                    <p
                        id="translatedTextText"
                        style="color: white !important;"
                        class="my-1 mx-2"
                        v-html="urlify(translatedText, true)"
                    />
                    <!-- eslint-enable vue/no-v-html -->
                    <!-- <v-icon
                        role="button"
                        aria-label="Schließen"
                        large
                        dark
                        class="mr-1"
                        color="white"
                        @click="showTranslation(false)"
                    >
                        fas fa-times
                    </v-icon> -->
                </simple-bar>
            </v-card-text>
        </v-card>

        <keyboard />
        <magnifier v-if="magnifier" />
    </v-app>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import UserSwitch from "./components/Utils/UserSwitch";
import Snackbar from './components/Utils/Snackbar'
import NameFile from "./components/NameFile";
import ProgressBar from "./components/ProgressBar";
import * as backend from "./api/backend";
import Keyboard from "./components/Keyboard";
import Magnifier from "./components/Magnifier";
import CookieLaw from "./components/Policys/CookieLaw";
import urlify from '@/util/urlify';
import setGlobalButtonContrast from "./util/accessibilityButtonContrast";
import { allowedExtensionsForLibre } from "./constants/allowedMimeTypes"
import { generateThumbnailForPdf } from "@/util/tempThumbnailGen";
import backendWebSocket from "./api/backendWebSocket";
import simplebar from 'simplebar-vue';
import 'simplebar-vue/dist/simplebar.min.css';
import slugify from 'slugify';

export default {
    name: "App",
    components: { Magnifier, Keyboard, 
    UserSwitch,
     NameFile, ProgressBar, 
    //  CookieLaw, 
     Snackbar, },
    data: () => ({
        urlify,
        
        transText: '',
        developmentMode: process.env.NODE_ENV === "development",
        showNameFile: false,
        isSubtitleReady: false,
        uploadFileName: '',
        fileNameCallback: () => { },
        showPupilUploadProgress: false,
        showTeamworkCheckbox: false,
        showBackendUnreachable: false,
        pupilUploadProgress: 0.0,
        pupilXmlHttpRequest: null,
        hasAcceptedAGB: false,
        displayAGB: false,

        allowedExtensionsForLibre,
        setGlobalButtonContrast,
    }),
    computed: {
        ...mapState("magnifier", ["magnifier"]),
        ...mapGetters("auth", ["accountRole"]),
        ...mapState("auth", ["token"]),
        ...mapGetters('videoTutorial', [ 'videoTutorials' ]),
        ...mapState("connection", ["backendReachable"]),
        ...mapState('imageCapture', ['imageCaptureFile']),
        ...mapGetters('translation', ['getTranslatedText', 'isTranslationShown']),
        ...mapGetters('util', ['isLoadingShown', 'currentlyOpenAppointment', 'currentUploadGroup', 'currentUploadFolder', 'currentlyOpenTeacherUploadId', 'getFachInfoUploadCallback']),
        translatedText() {
            return this.getTranslatedText;
        },
        showTranslatedText() {
            return this.isTranslationShown;
        },
        showLoading() {
            return this.isLoadingShown;
        }
    },
    watch: {
        // Auth token (changes when user loggs in or out)
        token: {
            immediate: true,
            handler(newToken, oldToken) {
                //logout
                if(!newToken || newToken === "" ) {
                    backendWebSocket.closeWebSocket();
                    return;
                }

                // nothing changed
                if(newToken === oldToken) {
                    return;
                }

                // login
                if (newToken !== "") {
                    backendWebSocket.initWebSocket();

                    // Clear potential store data
                    this.clearStoreData();

                    // Load initial data
                    this.fetchAccountSets();
                    this.fetchAccounts();
                    this.getPupils();
                    this.getTeachers();
                    this.getParents();
                    this.getMaintainers();
                    this.getGroups();
                    this.fetchChats();
                    this.getImageRights();
                    this.getIsADVSigned();
                    this.fetchDeactivatedFeatures(true)
                }
            }
        },
        account(newAccount, oldAccount) {
            if (newAccount && newAccount.accountName) {

                // Display Terms of Service or directly login
                this.hasAcceptedAGB = newAccount.hasAcceptedToS;
                if (newAccount.hasAcceptedToS) {
                    // this.rerouteAccount(newAccount);
                } else {
                    this.displayAGB = true;
                }
                return;
            }
        },
        isTranslationShown(value) {
            // if (value) {
            //     setTimeout(() => {
            //         this.showTranslation(false);
            //         this.transText = '';
            //     }, 15000);
            // }
            if(value === false){
                window.speechSynthesis.cancel();
            }
            this.transText = this.translatedText;
        },
        backendReachable(backendReachability) {
            if (backendReachability === false) {
                this.showBackendUnreachable = true;
            }
        },
        getTranslatedText() {
            this.transText = this.getTranslatedText;
        },
        isLoadingShown(value) {
        },
        handleBeforeUnload(event) {
            // this.autoLogoutUser();
            this.resetServerTimeOffset();
        },
        imageCaptureFile: {
            immediate: true,
            handler() {
                if (this.imageCaptureFile) {
                    this.uploadInputChange(this.imageCaptureFile);
                }
            },
        },
    },
    async mounted() {
        window.addEventListener("orientationchange", this.handleOrientationChange);
        window.addEventListener('beforeunload', this.handleBeforeUnload);
        window.addEventListener('resize', this.handleViewHeightChange);
        this.handleViewHeightChange();
        this.initGamepadApi();
        this.setGlobalButtonContrast('none', 'none');
        this.checkBrowserCompatibilityForNotifications();
    },
    beforeUnmount() {
        window.removeEventListener("orientationchange", this.handleOrientationChange);
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
        window.removeEventListener('resize', this.handleViewHeightChange);
    },
    methods: {
        ...mapActions('imageRights',["getImageRights"]),
        ...mapActions('gamepad', ["initGamepadApi"]),
        ...mapActions(['setFabricLibLoaded']),
        ...mapActions("auth", ["autoLogoutUser", "logoutUser", "getAccount"]),
        ...mapActions('translation', ['showTranslation']),
        ...mapActions("groups", ["getGroup", "getGroups"]),
        ...mapActions("appointments", ['getAppointments','getTeacherAppointments']),
        ...mapActions("accounts", ["fetchAccounts"]),
        ...mapActions("pupils", ["getPupils"]),
        ...mapActions("teachers", ["getTeachers"]),
        ...mapActions("parents", ["getParents"]),
        ...mapActions("maintainers", ["getMaintainers"]),
        ...mapActions("accountSets", ["fetchAccountSets"]),
        ...mapActions('mimeTypes', ['validateMimeType']),
        ...mapActions('util', [
            'checkBrowserCompatibilityForNotifications',
            'resetServerTimeOffset',
            'toggleOpenAppointment',
            'toggleCurrentUploadGroup',
            'toggleCurrentUploadFolder',
            'toggleOpenTeacherUploadId',
            'setOpenAppointment',
            'reopenAppointment',
            'setWindowWidth',
            'setWindowHeight',
        ]),
        ...mapActions('chat', ['fetchChats','clearChatStore']),
        ...mapActions('maintainer', ['getIsADVSigned']),
        ...mapMutations('snackbar', ["showSnackbar"]),
        ...mapMutations('vfs', ['triggerReloadOfVfs']),
        ...mapMutations('pupils', ['clearCurrentPupil']),
        ...mapMutations("teachers", ["clearCurrentTeacher"]),
        ...mapMutations('parents', ['clearCurrentParent']),
        ...mapMutations('groups', ['clearAllGroups']),
        ...mapMutations('accounts', ['clearDevices']),
        ...mapMutations('uploadedIcons', ['clearUploadedIcons']),
        ...mapMutations('externalApp', ['clearExternalApps']),
        ...mapMutations('subjects', ['clearSubjects']),
        ...mapMutations('timeslots', ['clearTimeslots']),
        ...mapMutations('documentRegistry', ['clearDocumentRegistries']),
        ...mapMutations('virtualFileSystem', ['clearVFSStates']),
        ...mapMutations('pinboard', ['clearLibraryState']),
        ...mapActions('deactivatedFeatures',['fetchDeactivatedFeatures']),

    
        closeTranslation(){
            if(this.showTranslatedText){
                this.showTranslation(false);
            }
        },
        handleOrientationChange() {
            // const orientation = window.screen.orientation.type;
            // if (orientation === "portrait-primary") {
            //     // portrait mode
            // } else if (orientation === "landscape-primary") {
            //     // landscape mode
            // }
        },
        // height change to fit browser bar
        // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
        handleViewHeightChange() {
            // First we get the viewport height, and we multiply it by 1% to get a value for a vh unit
            let vh = window.innerHeight * 0.01;
            // Then we set the value in the --vh custom property to the root of the document
            document.documentElement.style.setProperty('--vh', `${vh}px`);

            // Timeout is needed for windowWidth because on Safari Mobile,
            // under certain circumstances the new innerWidth takes too long to calculate,
            // in which case we save the old one
            setTimeout(() => {
                this.setWindowWidth(window.innerWidth);
                this.setWindowHeight(window.innerHeight);
            }, 400);
        },

        getExtension(filename) {
            var parts = filename.split('.');
            return parts[parts.length - 1];
        },
        isVideo(filename) {
            var ext = this.getExtension(filename);
            switch (ext.toLowerCase()) {
                case 'm4v':
                case 'avi':
                case 'mpg':
                case 'mp4':
                case 'mkv':
                    return true;
            }
            return false;
        },

        // @params parsedFile   optional parameter, if we provide a file to be uploaded programmatically instead of
        //                      through a change listener in input.value
        async uploadInputChange(parsedFile) {
            if (this.currentUploadGroup === 'teacher') {
                let appointment = JSON.parse(JSON.stringify(this.currentlyOpenAppointment));
                let group = await this.getGroup(this.currentlyOpenAppointment.group);
                appointment.group = group ? group : null;
                this.toggleOpenAppointment(appointment);
            }
            let files;
            if (!parsedFile) {
                // a file was set as input.value
                files = Array.from(this.$refs.uploadInput.files);

            } else {
                // a file was programmatically parsed
                files = [parsedFile];
            }
            if (files.length === 1 && this.currentUploadGroup === 'teacher') {
                const fileExtenstion = files[0].name.split('.').pop();
                this.showTeamworkCheckbox = this.allowedExtensionsForLibre.includes(fileExtenstion);
            }
            if (await this.validateMimeType(files)) {
                // Create tmp variables because in uploadFile these variables get reset to null
                // and we want to call uploadFile with the initial variables
                const tmpCurrentlyOpenAppointment = this.currentlyOpenAppointment;
                const tmpCurrentGroup = this.currentUploadGroup;
                const tmpOpenTeacherUploadId = this.currentUploadFolder;
                // Upload files sequentially
                const fileUploadResults = await files.reduce(async (previousFileUploadResultsPromise, file) => {
                    const previousFileUploadResults = await previousFileUploadResultsPromise;

                    // Show name file widget and upload files when appropriate
                    const prepareFileUpload = (file) => new Promise((resolve, reject) => {
                        if (this.isVideo(file.name)) { // ToDo check Video duration
                            this.isSubtitleReady = true;
                        } else {
                            this.isSubtitleReady = false;
                        }

                        const name = file.name;
                        const lastDot = name.lastIndexOf('.');
                        const fileName = name.substring(0, lastDot);
                        const fileExtension = name.substring(lastDot + 1);
                        this.uploadFileName = fileName;
                        this.fileNameCallback = (newFileName, selectedUser, userNotes, startDate, isAssignment, createSubtitles, isTeamwork) => {
                            this.showNameFile = false;
                            this
                                .uploadFile(file, newFileName, fileExtension, selectedUser, userNotes, startDate, isAssignment, createSubtitles, tmpCurrentlyOpenAppointment, tmpCurrentGroup, tmpOpenTeacherUploadId, isTeamwork)
                                .then(() => {
                                    if (this.currentUploadGroup === 'pupil' || this.currentUploadGroup === 'teacher') {
                                        /*this.$route.query.appointment = localStorage.getItem('reopenAppointment');
                                        localStorage.removeItem('reopenAppointment');*/
                                    }
                                    resolve();
                                })
                        }
                        this.showNameFile = true;
                        if(this.accountRole === 'teacher'){
                            this.showSnackbar({ message: 'Speicherplatz auf 1 GB beschränkt um Leistung zu sparen. Laden Sie bitte keine zu großen Dateien hoch!', color: 'warning' });
                        }
                    });

                    const fileUploadResult = await prepareFileUpload(file);

                    return Promise.resolve([...previousFileUploadResults, fileUploadResult]);
                }, Promise.resolve([]))
            }
        },

        async uploadFile(file,
            fileName,
            fileExtension,
            selectedUser,
            userNotes,
            startDate,
            isAssignment,
            createSubtitles,
            currentlyOpenAppointment = this.currentlyOpenAppointment,
            currentUploadGroup = this.currentUploadGroup,
            currentUploadFolder = this.currentUploadFolder,
            isTeamwork,
        ) {
            const localAppointment = currentlyOpenAppointment;
            const localTeacherUploadId = this.currentlyOpenTeacherUploadId;
            if (file) {
                const formData = new FormData();
                formData.append('file', file, `${this.sanitizeFilename(fileName)}.${fileExtension}`);

                if (currentUploadGroup === 'teacher') {
                    formData.append('visibleFor', JSON.stringify(selectedUser));
                    formData.append('startDate', JSON.stringify(startDate));
                    formData.append('isAssignment', JSON.stringify(isAssignment));
                    formData.append('createSubtitles', JSON.stringify(createSubtitles));
                    formData.append("isTeamwork", JSON.stringify(isTeamwork));
                    if(userNotes.length > 0) {
                        formData.append('userNotes', JSON.stringify(userNotes));
                    }

                    this.pupilXmlHttpRequest = await backend.postTeacherUpload(localAppointment._id, file);
                } else if (currentUploadGroup === 'pupil') {
                    this.pupilXmlHttpRequest = await backend.postPupilUpload(localAppointment._id, file);
                } else if (currentUploadGroup === 'subject') {
                    this.pupilXmlHttpRequest = await backend.postSubjectFolderUpload(localAppointment._id, file);
                } else if (currentUploadGroup === 'baby' && localTeacherUploadId) {
                    this.pupilXmlHttpRequest = await backend.postTeacherUploadEdit(localAppointment._id, localTeacherUploadId, file).then((response) => {
                        if (response.status === 201) {
                            this.showSnackbar({ message: "Gespeichert!" });
                        } else {
                            this.showSnackbar({ message: "Etwas ist schiefgelaufen", color: "error" })
                        }
                        this.toggleOpenAppointment(null);
                        this.toggleCurrentUploadGroup(null);
                        this.toggleOpenTeacherUploadId(null);
                        this.pupilXmlHttpRequest = null;
                        this.$refs.uploadInput.value = '';
                    });
                } else if (currentUploadGroup === 'teacherFolder' || currentUploadGroup === 'privateFolder' || currentUploadGroup === 'groupFolder') {
                    this.pupilXmlHttpRequest = await backend.postVFSFolderUploadV2(file, currentUploadFolder._id);
                }

                this.pupilXmlHttpRequest.onerror = (e) => {
                    console.error('Pupil upload error:', e)
                    this.showPupilUploadProgress = false;
                    this.pupilXmlHttpRequest = null;
                    this.$refs.uploadInput.value = '';
                    this.toggleOpenAppointment(null);
                    this.toggleOpenTeacherUploadId(null);
                    this.toggleCurrentUploadGroup(null);
                };

                this.pupilXmlHttpRequest.onabort = (e) => {
                    console.warn('Pupil upload aborted');
                    this.showPupilUploadProgress = false;
                    this.pupilXmlHttpRequest = null;
                    this.$refs.uploadInput.value = '';
                    this.toggleOpenAppointment(null);
                    this.toggleOpenTeacherUploadId(null);
                    this.toggleCurrentUploadGroup(null);
                }

                this.pupilXmlHttpRequest.upload.addEventListener('progress', (e) => {
                    this.pupilUploadProgress = (e.loaded / e.total) * 100;
                });

                this.pupilXmlHttpRequest.addEventListener('load', async (e) => {
                    if (this.pupilXmlHttpRequest.status !== 201) {
                        console.error('Pupil upload failed:', this.pupilXmlHttpRequest.response);
                        if (this.pupilXmlHttpRequest.status === 409) {
                            this.showSnackbar({ message: "Speicher überschritten. Datei konnte nicht gespeichert werden", color: "error" });
                        }
                    }
                    this.showPupilUploadProgress = false;
                    this.showSnackbar({ message: "Gespeichert!" });
                    this.toggleOpenAppointment(null);
                    if (currentUploadGroup === 'teacherFolder' || currentUploadGroup === 'privateFolder' || currentUploadGroup === 'groupFolder') {
                        // generate thumbnail if file is pdf
                        if (fileExtension === 'pdf') {
                            const dataForThumbnail = await generateThumbnailForPdf(
                                JSON.parse(this.pupilXmlHttpRequest.response)._id,
                                file
                            );
                            // Push thumbnail
                            const thumbnailResponse = await backend.postThumbnail(
                                dataForThumbnail.originFileId,
                                dataForThumbnail.thumbnailFile
                            );
                        }
                    }
                    // Trigger reload of vfs in fileWidget
                    this.triggerReloadOfVfs();
                    this.toggleCurrentUploadGroup(null);
                    this.toggleOpenTeacherUploadId(null);
                    if(this.accountRole === 'teacher'){
                        this.getTeacherAppointments()
                    }else{
                        this.getAppointments(true)
                    }
                    // Optional Callback set in util/teacherUploadCallback to have a callback when upload is done
                    if(this.getFachInfoUploadCallback) {
                        this.getFachInfoUploadCallback();
                    }
                    this.pupilXmlHttpRequest = null;
                    this.$refs.uploadInput.value = '';
                });

                this.pupilUploadProgress = 0.0;
                this.showPupilUploadProgress = true;
                this.pupilXmlHttpRequest.send(formData);
            }
        },

        /**
         * This method ensures that no user can get another user's store data by accident,
         * e.g. by quickly switching accounts via our UserSwitch component.
         */
        clearStoreData() {
            this.clearCurrentPupil();
            this.clearCurrentTeacher();
            this.clearCurrentParent();
            this.clearAllGroups();
            this.clearDevices();
            this.clearUploadedIcons();
            this.clearExternalApps();
            this.clearSubjects();
            this.clearTimeslots();
            this.clearDocumentRegistries();
            this.clearVFSStates();
            this.clearLibraryState();
            this.clearChatStore();
        },
        sanitizeFilename(filename) {
            return slugify(filename, {
                replacement: '_',    // replace spaces with underscores
                remove: /[*+~.()'"!:@]/g,  // regex to remove characters
                lower: false,          // preserve case
                strict: true,         // strip special characters except replacement
                locale: 'de'          // use German locale to handle ä, ö, ü correctly
            });
        }
    }
};
</script>

<style lang="scss">
/*@import '~@/styles/globals.scss';*/
/*@import url('https://fonts.googleapis.com/css2?family=Andika+New+Basic&display=swap');*/

img {
  -webkit-user-select: none !important;
  -webkit-touch-callout: none !important; /* iOS Safari */
}
.scroll-area-translated {
    margin: auto;
    height: auto;
    max-height: 300px;
    min-width: 300px;
    border-radius: 8px 8px 8px 8px;
    overflow-x: hidden
}
.icon10 {
    height: 10px;
}

.icon20 {
    height: 20px;
}
.icon25 {
    height: 25px;
}
.icon30 {
    height: 30px;
}

.square20 {
    min-width: 20px;
    width: 20px;
    height: 20px;
}
.square25 {
    min-width: 25px;
    width: 25px;
    height: 25px;
}

// Filters generated with https://codepen.io/sosuke/pen/Pjoqqp
.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%)
    hue-rotate(36deg) brightness(104%) contrast(105%);
}

.iconToGrey {
    filter: brightness(0) saturate(100%) invert(89%) sepia(0%) saturate(0%) hue-rotate(326deg) brightness(87%) contrast(88%) !important;
}

.iconToDunkelgrau {
    filter: brightness(0) saturate(100%) invert(46%) sepia(0%) saturate(0%) hue-rotate(163deg) brightness(92%) contrast(82%);
}

.iconToRed {
    filter: brightness(0) saturate(100%) invert(20%) sepia(75%) saturate(7197%)
    hue-rotate(355deg) brightness(97%) contrast(87%) !important;
}

.iconToGreen {
    filter: brightness(0) saturate(100%) invert(47%) sepia(84%) saturate(398%) hue-rotate(69deg) brightness(96%) contrast(96%);
}
.iconToOrange {
    filter: invert(74%) sepia(29%) saturate(1213%) hue-rotate(335deg) brightness(97%) contrast(94%) !important;
}
#translatedTextShow {
  position: fixed;
  bottom: 10px;
  right: 0;
  left: 0;
  margin-right: 10%;
  margin-left: 10%;
  min-width: 300px !important;
  border-radius: 15px;
  box-shadow: 1px 5px 5px silver;
  z-index: 210; // increased so it also shows in front of v-dialogs
}

@media only screen and (max-width: 500px),
(max-width: 900px) and (orientation: portrait) {
    #translatedTextShow {
        bottom: 80px;
    }
}

#translatedTextText {
  text-align: center !important;
  font-size: 18px;
  color: #000000 !important;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   display: -webkit-box;
//   -webkit-line-clamp: 8; /* number of lines to show */
//   -webkit-box-orient: vertical;
}

.v-snack.v-snack--top {
  bottom: initial; /* or auto */
  width: 100vw !important;
}

.v-snack.v-snack--bottom {
  top: initial; /* or auto */
  width: 100vw !important;
}

.preventColorInvert {
  filter: unset !important;
  border-color: #22a9ff !important;
}
.preventColorInvertFilter {
  filter: unset !important;
}
.visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}
.iconToWhite {
  filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%)
    hue-rotate(36deg) brightness(104%) contrast(105%);
}

.iconToHeaderblue {
    filter: brightness(0) saturate(100%) invert(21%) sepia(43%) saturate(391%) hue-rotate(183deg) brightness(94%) contrast(92%);
}

h1 {
    display: inherit;
    font-size: inherit;
    margin: inherit;
    font-weight: inherit;
}

h2 {
    display: inherit;
    font-size: inherit;
    margin: inherit;
    font-weight: inherit;
}

.soft-box-shadow {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.moveableItemShadow {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

@font-face {
  font-family: "eKlara Custom";
  src: url("~@/assets/Fonts/ABeeZee-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

.v-application {
  font-family: "eKlara Custom", sans-serif !important;
}

.v-list-item{
  font-family: "eKlara Custom", sans-serif !important;

}
.v-tooltip {
  font-family: "eKlara Custom", sans-serif !important;

}
// Fix for vuetify version change from 2.4 to 2.6
.row {
    margin-top: 0;
    margin-bottom: 0;
}

.row + .row {
    margin-top: 0;
}

.widgetContainer {
    width: 30vw;
    max-width: 30vw;
    min-height: 82vh;
    max-height: 82vh;
    border-radius: 16px !important;
    box-shadow: 1px 5px 5px silver;
    display: flex;
    flex-direction: column;
}

.widgetContainerBaby {
    width: 47vw;
    max-width: 47vw;
}

@media only screen and (max-width: 900px) {
    .widgetContainer {
        width: 90vw;
        max-width: 90vw;
    }
}

.widgetAlign {
    display: flex;
    justify-content: center;
}

// So vue-slick-caroussel won't cut off box-shadows
.slick-list {
    overflow: visible !important;
}

// Use like bootstrap classes (mx-4), should be working but aren't
.rounded-1 {
    border-radius: 4px !important;
}
.rounded-2 {
    border-radius: 8px !important;
}
.rounded-3 {
    border-radius: 12px !important;
}
.rounded-4 {
    border-radius: 16px !important;
}

.outdated-image {
    opacity: 0.4;
    filter: grayscale(100%);
}
.outdated-image img {
    filter: grayscale(100%);
}
.insetBadge .v-badge__badge {
  border-radius: 3px;
  height: 18px;
  padding: 2px;
  cursor: pointer;
  top:-15px;
}
.scroll-area-translated .simplebar-scrollbar::before {
  background-color: white; /* Customize scrollbar color */
}

.mySwiper .swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
    margin-top: 1rem;
}

</style>
