<template>
    <div
        style="min-height: 100vh; min-width: 100vw"
        class="d-flex flex-column justify-center align-center"
    >
        <img
            :src="loadingIcon"
            style="height: 140px;"
        >
        <img
            class="mt-8"
            :src="loadingAnimation"
            style="max-width: 140px;"
            :style="windowWidth < 901 ? 'margin-bottom: 40%;' : ''"
        >
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";

import loadingIcon from '@/assets/Icons/eklara_logo_beta_2.0.svg'
import loadingAnimation from '@/assets/Icons/loading-animation.gif'


export default {
    name: "PreloaderComponent",
    data() {
        return {
            loadingIcon,
            loadingAnimation
        }
    },
    computed:{
        ...mapState('util', ['keyboard', 'bonusFunctions', 'browserSupported', 'windowWidth']),

    },
}
</script>

<style lang="scss" scoped>

</style>
