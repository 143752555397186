import * as backend from "@/api/backend";
import {checkResponseStatus} from "@/util/check";
import store from "@/store";

const defaultState = {
    signaturesById: [],
    archivedSickNotes: [],
};

const mutations = {
    setSignaturesById: (state, list) => {
        state.signaturesById = list;
    },
    addOrUpdateSignatureById: (state, signature)=>{
        const index = state.signaturesById.findIndex(el => {
            return  el.sickNoteId === signature._sickNoteId;
        });

        if (index >= 0){
            state.signaturesById[index] = signature;

        }else{
            state.signaturesById.push(signature);
        }

    },
    setArchivedSickNotes: (state, list) =>{
        state.archivedSickNotes = list;
    },
    addOrUpdateArchivedSickNote:  (state, sickNote) => {
        const index = state.archivedSickNotes.findIndex(el => {
            return  el._id === sickNote._id;
        });

        if (index >= 0){
            state.archivedSickNotes[index] = sickNote;

        }else{
            state.archivedSickNotes.push(sickNote);
        }
    },
    removeArchivedSickNote: (state, sickNoteId) => {
        const index = state.archivedSickNotes.findIndex(el => el._id === sickNoteId);
        if (index >= 0) {
            state.archivedSickNotes.splice(index, 1); // Directly modify the array
        }
    }
    
};

const getters = {
    signaturesById: state => state.signaturesById,
    signaturesBySickNoteId: state => state.signaturesById.reduce((acc, signatureById) => {
        acc[signatureById.sickNoteId] = signatureById;
        return acc;
    }, {}),
    archivedSickNotes: state => state.archivedSickNotes,
}

const actions = {
    async createSickNote({ commit, dispatch }, body) {
        try {
            const res = await backend.createSickNote(body);
            await checkResponseStatus(201, res);
            const sickNote = await res.json();
            sickNote.status = 201;
            return sickNote;
        }
        catch (err) {
            return err.response;
        }
    },

    async getSickNotes() {
        try {
            const res = await backend.getSickNotes();
            await checkResponseStatus(200, res);
            return await res.json();
        }
        catch (err) {
            return err.response;
        }
    },

    async getSickNotesSignatures({commit, dispatch, getters}, {sickNotes, update = false}) {
        try {
            // if (getters.signaturesById && getters.signaturesById.length > 0 && !update) {
            //     return getters.signaturesById;
            // }
            console.log('gettingsickNoteSignatures');
            const signaturesUrlsById = await sickNotes.reduce(async (previousPromise, sickNote) => {
                const previous = await previousPromise;
                let url;
                try {
                    const res = await backend.getSickNoteSignature(sickNote._id);
                    await checkResponseStatus(200, res);
                    const blob = await res.blob();
                    url = URL.createObjectURL(blob);
                } catch (e) {
                    console.warn(e);
                    console.warn(`Setting empty blob for id ${sickNote._id}`);
                    const blob = new Blob([], { type: 'image/png' });
                    url = URL.createObjectURL(blob);
                }
                previous.push({
                    sickNoteId: sickNote._id,
                    url
                })
                return previous;
            }, Promise.resolve([]));
            commit('setSignaturesById', signaturesUrlsById);

            return signaturesUrlsById;
        }
        catch (err) {
            return err.response;
        }
    },
    async getSickNotesSignature({commit, dispatch, getters}, sickNoteId) {
        try {
            if (getters.signaturesById && getters.signaturesById.length > 0 && getters.signaturesById.findIndex(el => el.sickNoteId === sickNoteId) >= 0) {
                return getters.signaturesBySickNoteId[sickNoteId];
            }

                try {
                    const res = await backend.getSickNoteSignature(sickNoteId);
                    await checkResponseStatus(200, res);
                    const blob = await res.blob();
                    const url = URL.createObjectURL(blob);
                    commit('addOrUpdateSignatureById', {
                        sickNoteId: sickNoteId,
                        url
                    });
                    return {
                        sickNoteId: sickNoteId,
                        url
                    };
                } catch (e) {
                    console.warn(e);
                    console.warn(`Setting empty blob for id ${sickNoteId}`);
                    const blob = new Blob([], { type: 'image/png' });
                    const url = URL.createObjectURL(blob);
                    return {
                        sickNoteId: sickNoteId,
                        url
                    };
                }

        }
        catch (err) {
            return err.response;
        }
    },
    async getArchivedSickNotes({commit, dispatch, getters},{update = false}) {
        try {
            if(update){
                const res = await backend.getArchivedSickNotes();
                await checkResponseStatus(200, res);
                const archived = await res.json();
                // const archivedWithSignatures =  await archived.reduce(async (previousPromise, sickNote) => {
                //     const previous = await previousPromise;
                //     let url;
                //     try {
                //         const res = await backend.getSickNoteSignature(sickNote._id);
                //         await checkResponseStatus(200, res);
                //         const blob = await res.blob();
                //         url = URL.createObjectURL(blob);
                //     } catch (e) {
                //         console.warn(e);
                //         console.warn(`Setting empty blob for id ${sickNote._id}`);
                //         const blob = new Blob([], { type: 'image/png' });
                //         url = URL.createObjectURL(blob);
                //     }
                //     previous.push({
                //         sickNoteId: sickNote._id,
                //         url
                //     })
                //     return previous;
                // }, Promise.resolve([]));
                // commit('setSignaturesById', [...getters.signaturesById,...archivedWithSignatures]);
                commit('setArchivedSickNotes', archived);
                return archived;
            }
            else{
                return getters.archivedSickNotes;
            }
        }
        catch (err) {
            return err.response;
        }
    },

    async getSickNote({commit, dispatch}, params) {
        try {
            const res = await backend.getSickNote(params);
            await checkResponseStatus(200, res);
            return await res.json();
        }
        catch (err) {
            return err.response;
        }
    },

    async acceptSickNote({commit, dispatch}, params) {
        try {
            const res = await backend.acceptSickNote(params);
            await checkResponseStatus(200, res);
            return res;
        }
        catch (err) {
            return err.response;
        }
    },

    async declineSickNote({commit, dispatch}, params) {
        try {
            const res = await backend.declineSickNote(params);
            await checkResponseStatus(200, res);
            return res;
        }
        catch (err) {
            return err.response;
        }
    },
    async toggleSickNote({commit, dispatch}, params) {
        try {
            const res = await backend.toggleSickNote(params);
            await checkResponseStatus(200, res);
            return res;
        }
        catch (err) {
            return err.response;
        }
    },

    async getSickNoteSignature({commit, dispatch}, {sickNoteId}) {
        try {
            const res = await backend.getSickNoteSignature(sickNoteId);
            await checkResponseStatus(200, res);
            let blob = await res.blob();
            return URL.createObjectURL(blob);
        }
        catch (err) {
            return err.response;
        }
    },

    async getSickNoteFile({commit, dispatch}, {sickNoteId, fileId}) {
        try {
            const res = await backend.getSickNoteFile(sickNoteId, fileId);
            await checkResponseStatus(200, res);
            let blob = await res.blob();
            return window.URL.createObjectURL(blob);
        }
        catch (err) {
            return err.response;
        }
    },

    async postSickNoteFile({ commit }, {sickNoteId, file}) {
        try {
            const formData = new FormData();
            formData.append("file", file, file.name);
            const XmlHttpRequest = await backend.postSickNoteFile(sickNoteId, file);

            XmlHttpRequest.addEventListener('load', (e) => {
                return XmlHttpRequest;
            });
            XmlHttpRequest.send(formData)
        } catch (e) {
            console.error(e);
        }
    },

    async uploadSickNoteSignature({ commit }, {sickNoteId, file, fileName}) {
        try {
            const formData = new FormData();
            formData.append("file", file, fileName);
            const XmlHttpRequest = await backend.uploadSickNoteSignature(sickNoteId, file);

            XmlHttpRequest.addEventListener('load', (e) => {
                return XmlHttpRequest;
            });
            XmlHttpRequest.send(formData)
        } catch (e) {
            console.error(e);
        }
    },

    async archiveSickNote({ commit }, { sickNoteId }) {
        try {
            const res = await backend.archiveSickNote(sickNoteId);
            if(res.status === 200){
                const sickNote = await res.json();
                commit('addOrUpdateArchivedSickNote',sickNote)

                store.commit('snackbar/showSnackbar', { message: 'Entschuldigung erfolgreich archiviert.'});
                return sickNote;
            }
            else {
                store.commit('snackbar/showSnackbar',{ message: 'Beim Archivieren der Entschuldigung ist ein Fehler aufgetreten.'});
            }
        } catch (e) {
            console.error(e);
        }
    },

    async restoreSickNote({ commit }, { sickNoteId }) {
        try {
            const res =  await backend.restoreSickNote(sickNoteId);
            if(res.status === 200){
                commit('removeArchivedSickNote',sickNoteId)
                store.commit('snackbar/showSnackbar', { message: 'Entschuldigung erfolgreich wiederhergestellt.'});
                const message = await res.json();
                return message;
            }
            else {
                store.commit('snackbar/showSnackbar',{ message: 'Beim Wiederherstellen der Entschuldigung ist ein Fehler aufgetreten.'});
            }

        } catch (e) {
            console.error(e);
        }
    }
}

export default {
    namespaced: true,
    state: defaultState,
    mutations,
    actions,
    getters,
};
