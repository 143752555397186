<template>
    <div>
        <div
            v-if="accountRole === 'teacher' || accountRole === 'maintainer'"
            class="d-flex justify-center align-center"
        >
            <v-btn
                elevation="0"
                class="text-none"
                style="margin: 6px; width: 228px"
                @click="openCropDialog"
            >
                <img
                    :src="bildIcon"
                    class="icon20 mr-2"
                >
                {{ usage === 'profilePicture' ? 'Profilbild hochladen' : 'Bild hochladen' }}
            </v-btn>
        </div>

        <div
            v-if="accountRole === 'teacher' || accountRole === 'maintainer'"
            class="d-flex justify-center align-center"
        >
            <v-btn
                v-if="activateDeleteBtn"
                id="deleteUploadedPictureBtn"
                elevation="0"
                class="text-none"
                style="margin: 6px; width: 228px; color: red"
                @click="clickDelete"
            >
                <img
                    :src="papierkorbIcon"
                    class="icon20 iconToRed mr-2"
                >
                {{ 'Bild löschen' }}
            </v-btn>
        </div>
        <v-dialog
            v-if="cropDialog"
            v-model="cropDialog"
            width="600"
        >
            <v-card>
                <v-card-title class="d-flex flex-row justify-space-between">
                    <h1>
                        {{ usage === 'profilePicture' ? 'Profilbild zuschneiden' : 'Eigenes Bild zuschneiden' }}
                    </h1>
                    <v-spacer />
                    <v-btn
                        size="x-small"
                        elevation="0"
                        style="width: 30px; height: 30px"
                        @click="closeCropDialog"
                    >
                        <img
                            :src="cancelIcon"
                            class="icon20"
                            alt="Dialog schließen"
                        >
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    <div class="d-flex justify-end align-center mb-2">
                        <v-spacer />
                        <v-btn
                            class="text-none"
                            elevation="0"
                            @click="closeCropDialog"
                        >
                            Abbrechen
                        </v-btn>
                        <v-btn
                            class="text-none ml-2"
                            elevation="0"
                            :disabled="!fileSelected"
                            @click="processData"
                        >
                            Hochladen
                        </v-btn>
                    </div>

                    <!-- File input to upload an image -->
                    <div v-if="!imageLoaded">
                        <input
                            ref="fileInput"
                            type="file"
                            accept="image/*"
                            @change="loadImage"
                        >
                        <p>Bild hier ablegen oder Knopf nutzen</p>
                    </div>

                    <!-- The cropper -->
                    <div
                        v-if="imageLoaded"
                        class="cropper-container"
                    >
                        <cropper
                            ref="cropper"
                            :src="imageSource"
                            :stencil-aspect-ratio="1"
                            class="cropper"
                            @stencil-change="onStencilChange"
                        />
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
        <are-you-sure-popup
            :show-dialog="showDeleteDialog"
            :content-text-prop="deleteText"
            :usage="'ImageUpload'"
            :header-color="'#343e55'"
            ok-btn-color="frot"
            @ok="confirmDelete"
            @close="showDeleteDialog = false"
        />
    </div>
</template>
<script>

import {mapActions, mapGetters, mapMutations} from "vuex";
import * as backend from "@/api/backend";
import cancelIcon from "@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg";
import bildIcon from "@/assets/Icons/FaceliftIcons/bild.svg";
import papierkorbIcon from "@/assets/Icons/FaceliftIcons/loeschen_muelleimer.svg";
import AreYouSurePopup from './AreYouSurePopup.vue';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
export default {
    name: 'ImageUpload',
    components: { Cropper, AreYouSurePopup },
    props:{
        usage: { type: String, required: false, default: '' },
        iconToDelete: { type: String, required: false },
        activateDeleteBtn: { type: Boolean, required: false, default: false },
    },
emits: ['uploaded'],

    data: () => ({
        cancelIcon,
        bildIcon,
        papierkorbIcon,
        profilePictureFile: null,
        profilePicXmlHttpRequest: null,
        cropDialog: false,
        profilePictureBlob: null,
        showProfilePicUploadProgress: false,
        profilePicUploadProgress: 0.0,
        fileSelected: false,
        account: null,
        showDeleteDialog: false,
        deleteText: 'Icon in Verwendung. Löschen führt zur Löschung an allen Orten und zum Ersatz durch Beispiel-Icon. Trotzdem löschen? ',
        croppedHeight: 400,
        croppedWidth: 400,
        imageType: null,
        originalFileName: 'Orginalname',
    }),
    computed: {
        ...mapGetters('auth', ['accountRole']),
        ...mapGetters('teachers', ['teachersByAccountId']),
    },
    watch: {
    },
    unmounted() {
		// Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
		if (this.imageSource) {
			URL.revokeObjectURL(this.imageSource)
		}
	},
    async mounted() {
        this.account = await this.getAccount();
        if(this.usage === 'pinboardUsage') {
            this.croppedHeight = 150;
            this.croppedWidth = 150;
        }
    },
    methods:{
        ...mapActions('auth', ['getAccount']),
        ...mapActions('avatar', ['setAvatarPickerOpen', 'setAvatarChanged']),
        ...mapActions('maintainers', ['getMaintainers', 'getMaintainerProfilePicture', 'deleteMaintainerProfilePicture']),
        ...mapActions('teachers', ['getTeachers', 'getProfilePicture', 'deleteProfilePicture']),
        ...mapMutations('snackbar', ['showSnackbar']),

        clickCropperSaveBtn() {
            const collection = document.getElementsByClassName("ankaCropper__saveButton");
            collection[0].click();
        },
        async processData(){
             if (!this.imageLoaded) {
                this.showSnackbar({message:'Kein Bild zum Zuschneiden vorhanden, bitte laden Sie die Seite neu und versuchen es nochmal.', color: 'error'});
                return;
            }
            const { canvas } = this.$refs.cropper.getResult();
                canvas.toBlob(async (blob) => {
                      const croppedFile = new File([blob], 'zugeschnitten.jpg', {
                         type: blob.type,
                     });
                      this.profilePictureFile =croppedFile;
                        this.profilePictureFile.originalname = this.originalFileName;

                    if(this.usage==='profilePicture'){
                        await this.uploadFile(this.profilePictureFile);
                    }else{
                        await this.uploadIconFile(this.profilePictureFile);

                    }
                }, this.imageType);
        },
        getMimeType(file, fallback = null) {
            const byteArray = (new Uint8Array(file)).subarray(0, 4);
            let header = '';
            for (let i = 0; i < byteArray.length; i++) {
            header += byteArray[i].toString(16);
            }
            switch (header) {
                case "89504e47":
                    return "image/png";
                case "47494638":
                    return "image/gif";
                case "ffd8ffe0":
                case "ffd8ffe1":
                case "ffd8ffe2":
                case "ffd8ffe3":
                case "ffd8ffe8":
                    return "image/jpeg";
                default:
                    return fallback;
            }
        },
        async loadImage(event) {
            const { files } = event.target;
            const file = files[0];
            this.originalFileName = file.name;
            const blob = URL.createObjectURL(files[0]);
            if (file && file.size <= 5000000) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.imageSource = blob;
                    this.imageType = this.getMimeType(e.target.result, files[0].type)
                    this.imageLoaded = true; // Mark the image as loaded
                    this.fileSelected = true;
                };
                reader.readAsDataURL(file); // Read the file as a Base64 string
            } else {
                this.showSnackbar({message:'Die Datei darf nicht größer als 5 MB sein.', color: 'error'});
            }
        },
        async uploadFile(data) {
            // this.profilePictureFile = cropData.croppedFile;
            // this.profilePictureFile.originalname = cropData.originalFile.name;

            const date = new Date().toISOString().substring(0, 10).replaceAll('-', '');

            if (data) {
                const formData = new FormData();
                // Cropper has a default mime type of jpg so file extension is .jpg here
                    formData.append("file", data, `${this.account._id}ProfilePicture${date}.jpg`);

                let userId;
                    if (this.accountRole === 'teacher') {
                        const thisTeacher = this.teachersByAccountId[this.account._id];
                        userId = thisTeacher._id;

                        this.profilePicXmlHttpRequest = backend.postTeacherPicture(
                            userId,
                            data
                        );
                    } else if (this.accountRole === 'maintainer') {
                        let allMaintainers = await this.getMaintainers();
                        let thisMaintainer = allMaintainers.find(el => el.account === this.account._id);
                        userId = thisMaintainer._id;
                        this.profilePicXmlHttpRequest = backend.postMaintainerProfilePicture(
                            userId,
                            data
                        );
                    }
                this.profilePicXmlHttpRequest.onerror = (e) => {
                    console.error("Upload error:", e);
                    this.showProfilePicUploadProgress = false;
                    this.profilePicXmlHttpRequest = null;
                };

                this.profilePicXmlHttpRequest.onabort = (e) => {
                    console.warn("Upload aborted");
                    this.showProfilePicUploadProgress = false;
                    this.profilePicXmlHttpRequest = null;
                };

                this.profilePicXmlHttpRequest.upload.addEventListener("progress", (e) => {
                    this.profilePicUploadProgress = (e.loaded / e.total) * 100;
                });

                this.profilePicXmlHttpRequest.addEventListener("load", (e) => {
                    if (this.profilePicXmlHttpRequest.status !== 201)
                        console.error(
                            "Profile picture upload failed:",
                            this.profilePicXmlHttpRequest.response
                        );
                    this.showProfilePicUploadProgress = false;
                    this.showSnackbar({ message: 'Gespeichert!'});
                    this.profilePicXmlHttpRequest = null;
                });

                this.profilePicUploadProgress = 0.0;
                this.showProfilePicUploadProgress = true;
                this.profilePicXmlHttpRequest.send(formData);
                this.$emit('uploaded');
            }
            this.closeCropDialog();
            this.setAvatarChanged(true);
        },
        async clickDelete(){
            this.usage==='profilePicture' ? await this.deleteProfilePic() :
            await this.deleteIcon()
        },
        async deleteProfilePic() {
            let res;
            if (this.accountRole === 'teacher') {
                const thisTeacher = this.teachersByAccountId[this.account._id];
                res = await this.deleteProfilePicture(thisTeacher._id);
            } else if (this.accountRole === 'maintainer') {
                let allMaintainers = await this.getMaintainers();
                let thisMaintainer = allMaintainers.find(el => el.account === this.account._id);
                res = await this.deleteMaintainerProfilePicture(thisMaintainer._id);
            }
            if (res === 204) {
                this.showSnackbar({ message: 'Hochgeladenes Profilbild erfolgreich gelöscht'});
                this.profilePictureBlob = null;
                this.$emit('deleted');
            } else {
                this.showSnackbar({ message: 'Beim Löschen des Profilbilds ist ein Fehler aufgetreten', color: "error" });
            }
        },
        async uploadIconFile(iconFile){
            const today = new Date();
            const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
            const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            const dateTime = date+' '+time;
            if (iconFile) {
                //warum geht hier name verloren?
                const res = await backend.postIconUploadFile(iconFile);
                const fileInfo = await res.json();

                const uploadResponse = await backend.postIconUpload({
                    title: iconFile.name,
                    uploadDate: dateTime,
                    file: fileInfo._id,
                    uploader: this.account._id,
                    });
                if(uploadResponse.status === 201){
                    this.showSnackbar({ message: 'Icon erfolgreich hochgeladen', color: "success" });
                    const res = await uploadResponse.json()
                    this.$emit('imageUpload', res);
                }else {
                    this.showSnackbar({ message: 'Beim hochladen des Icons ist etwas schiefgelaufen! Bitte neu laden', color: "error"  });
                }
                this.closeCropDialog();
            }
        },
        async deleteIcon() {
            if (this.iconToDelete !== "defaultIcon") {
                const iconId = this.iconToDelete;
                const res = await backend.checkForDelete(iconId);
                if(res.status === 200){
                    await this.confirmDelete();
                } else {
                    this.showDeleteDialog = true;
                }
            } else {
                this.showSnackbar({ message: 'Standard Icons können nicht gelöscht werden', color: "error"  });
            }

        },
        async confirmDelete() {
            const deleteRes = await backend.deleteIconUpload( this.iconToDelete);
             if (deleteRes.status === 204) {
                this.showSnackbar({ message: 'Icon erfolgreich gelöscht'});
                this.$emit('iconDeleted');
            } else {
                this.showSnackbar({ message: 'Beim Löschen des Icons ist ein Fehler aufgetreten', color: "error" });
            }
            this.showDeleteDialog = false;
        },
        openCropDialog() {
            this.cropDialog = true;
            this.$emit('upload');
        },
        closeCropDialog() {
            this.cropDialog = false;
            this.$emit('finished');
        },
    }
}
</script>
