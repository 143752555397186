<template>
    <div class="pickerContainer">
        <v-select
            :id="'select1'+ id"
            v-model="hours"
            :disabled="disabled"
            :items="availableHours"
            variant="solo"
            density="compact"
            hide-details
            :flat="disabled"
            :bg-color="disabled ? 'grey lighten-3' : 'white'"
            :menu-props="selectorStyling"
            :append-icon="''"
            style="max-width: 38px; height:20px;"
            @update:model-value="changedTime"
        />
        <span style="height: 20px; padding: 0 2px">:</span>
        <v-select
            :id="'select2' + id"
            v-model="minutes"
            :disabled="disabled"
            :items="availableMinutes"
            variant="solo"
            density="compact"
            hide-details
            :flat="disabled"
            :bg-color="disabled ? 'grey lighten-3' : 'white'"
            :menu-props="selectorStyling"
            :append-icon="''"
            style="max-width: 38px;height: 20px;"
            @update:model-value="changedTime"
        />
    </div>
</template>

<script>
export default {
    name:"TimepickerComponent",
    components: {
    },
    props: {
        time: { required: true },
        id: {type: String, required: false},
        disabled: { required: false, default: false, type: Boolean},
    },
    data: () => ({
        hours: '',
        availableHours: [
            '00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'
        ],
        minutes: '',
        availableMinutes: [
            '00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'
        ],

        selectorStyling: { nudgeBottom: '19' }
    }),
    computed: {
    },
    watch: {
        time: {
            handler() {
                if(this.time || this.time === '') {
                    if (this.time === '') {
                        this.hours = '';
                        this.minutes = '';
                    } else {
                        this.hours = this.time.slice(0, 2);
                        this.minutes = this.time.slice(3, 5);
                    }
                }
            }, immediate: true,
        }
    },
    async mounted() {
    },
    methods: {
        changedTime() {
            this.$emit('change', this.hours + ':' + this.minutes);
        },
    },
}
</script>

<style scoped>
    .pickerContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
</style>
<style lang="scss">
    .v-text-field--filled.v-input--dense.v-text-field--single-line > .v-input__control > .v-input__slot, .v-text-field--filled.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot, .v-text-field--filled.v-input--dense.v-text-field--outlined.v-text-field--filled > .v-input__control > .v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--single-line > .v-input__control > .v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--outlined.v-text-field--filled > .v-input__control > .v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--single-line > .v-input__control > .v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--outlined.v-text-field--filled > .v-input__control > .v-input__slot {
        min-height: auto !important;
        cursor: text;
    }
    .v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
        min-height: auto !important;
        cursor: text;
    }
    .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot, .v-text-field.v-text-field--enclosed .v-text-field__details {
        padding: 0 0 0 9px;
    }
</style>
