<template>
    <div>
        <div :class="{shakeMe: (accountRole === 'teacher' || !disableMovePupil)}">
            <v-row
                no-gutters
                align="center"
                justify="center"
                style="flex-wrap: nowrap"
            >
                <v-col>
                    <v-text-field
                        v-if="editMode"
                        v-model="tempText"
                        density="compact"
                        hide-details="auto"
                    />
                    <p
                        v-else
                        ref="movingIcon"
                        :tabindex="tabIndex"
                        style="cursor: grab; padding: 3px 8px 3px 8px; border: 2px solid blue; border-radius: 4px;"
                        class="mr-2"
                        :style="`
                            font-size: ${text.fontSize === 2
                        ? 'xx-large' : text.fontSize === 1 ? 'x-large' : 'large'};
                        `"
                        v-text="text.text"
                    />
                </v-col>
                <v-col
                    cols="auto"
                    style="padding: 1px 2px 1px 1px"
                >
                    <v-btn
                        v-if="accountRole !== 'teacher'"
                        :aria-hidden="person && person.accessibility && person.accessibility.screenreader"
                        size="x-small"
                        elevation="1"
                        style="height: 35px; width: 35px"
                        :tabindex="tabIndex"
                        @click="read"
                    >
                        <img
                            :src="speakerIcon"
                            alt="Text vorlesen"
                        >
                    </v-btn>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import {mapActions, mapState, mapGetters} from "vuex";
import {textToSpeech} from "@/util/textToSpeech";
import lautsprecherIcon from "@/assets/Icons/lautsprecher-13.svg";
import lautsprecherAusIcon from "@/assets/Icons/lautsprecher-aus-89.svg";

export default {
    name: "EditorMoveableText",
    props: {
        disableMovePupil: {type: Boolean, required: false, default: false },
        text: {type: Object, required: true},
        canvasWidth: {type: Number, required: true},
        canvasHeight: {type: Number, required: true},
        tabIndex: { type: Number, required: false, default: 0 },
    },
    data: () => ({
        lautsprecherIcon,
        lautsprecherAusIcon,

        speakerIcon: lautsprecherIcon,

        person: {
            accessibility: {
                screenreader: false,
            }
        },

        editMode: false,
    }),
    computed: {
        ...mapGetters("auth",["accountRole"]),
    },
    mounted() {
        if (this.accountRole !== 'teacher') {
            this.requestPerson();
        }
    },
    methods: {
        ...mapActions('pupils', [ 'getMePupil' ]),

        async requestPerson() {
            this.person = await this.getMePupil();
        },

        read() {
            textToSpeech(this.text.text, 'de', () => {
                this.speakerIcon = this.lautsprecherAusIcon;
            }, () => {
                this.speakerIcon = this.lautsprecherIcon;
            });
        }
    }
}
</script>

<style lang="scss">
// Language changed to SCSS while preserving functionality

// Iterate over the different text-items to have an individual animation-delay
@for $i from 1 through 30 {
  .shakeMe:nth-of-type(#{$i}) {
    animation: shake 5s linear infinite #{random(4000)}ms;
  }

  // Pause the animation when the cursor hovers an item
  .shakeMe:nth-of-type(#{$i}):hover {
    -webkit-animation-play-state: paused;
    -moz-animation-play-state: paused;
    -o-animation-play-state: paused;
    animation-play-state: paused;
  }
}

// Define keyframes for the shake animation
@keyframes shake {
  0% {
    transform: translate(1px, 0px) rotate(-1deg);
  }
  2% {
    transform: translate(-1px, 0px) rotate(-1deg);
  }
  5% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  8% {
    transform: translate(3px, 0px) rotate(0deg);
  }
  11% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}
</style>
