<template>
    <div class="fileListOuterContainer">
        <div :class="{ tileListContainer: view === 'mediumTile' || view === 'smallTile' }">
            <div
                v-for="(file, index) in filesToDisplay"
                :key="file + index"
                :class="`${view === 'list' ? 'listContainer' : view === 'largeTile' ? 'largeTileContainer' : view === 'mediumTile' ? 'mediumTileContainer' : 'smallTileContainer'}`"
            >
                <!-- list view, 1 file per row -->
                <div
                    v-if="view === 'list'"
                    class="listRow"
                    :class="file.isDeleted ? 'outdated-image' : ''"
                    style="border-top-style: outset; border-top-width: 2px"
                    :style="'background-color: ' + getBackgroundColorStyle(file)"
                    @click="()=>{clickDiv(file)}"
                >
                    <v-badge
                        v-if="(usage === 'abgabenPupil' && file.correction) || (usage === 'toCorrectTeacher' && !file.seenByMe)"
                        color="#ff0000"
                        content="1"
                        :model-value="usage === 'abgabenPupil' ? !file.correction.seenByMe : usage === 'toCorrectTeacher' ? !file.seenByMe : null"
                        location="left"
                        style="z-index: 9; width:100%"
                    />
                    <div
                        class="largeTileInfoFooterContainer"
                    >
                        <FileListTitle
                            :file="file"
                            :view="view"
                            :usage="usage"
                            :file-deleted="file.isDeleted"
                            @title-clicked="titleClicked(file, true)"
                        />
                        <div
                            :ref="`listSpeaker${file._id}`"
                            class="showSpeaker"
                        >
                            <v-btn
                                v-if="accountRole === 'pupil'"
                                elevation="6"
                                size="x-small"
                                style="height: 30px; width:30px; background-color:white; border-radius: 4px !important; bottom: 8px;"
                                @click="readText(file.title, $event)"
                            >
                                <img
                                    alt="Vorlesen"
                                    :src="lautsprecherIcon"
                                    style="height: 20px;"
                                >
                            </v-btn>
                        </div>
                        <div
                            class="pa-0 d-flex justify-end ml-2 align-center"
                        >
                            <FileListEditButton
                                v-if="buttonConditions.editButtonCondition(file, usage)"
                                :file="file"
                                :primary-color="primaryColor"
                                :person="person"
                                :account-role="accountRole"
                                @edit-in-editor="$emit('editInEditor', file)"
                                @edit-in-libre="$emit('editInLibre', file)"
                                @edit-draft="$emit('editDraft', file)"
                            />
                            <FileListPlayButton
                                v-if="buttonConditions.playButtonCondition(file)"
                                :file="file"
                                :primary-color="primaryColor"
                                :account-role="accountRole"
                                @play-video="$emit('playVideo', file)"
                                @play-audio="$emit('playAudio', file)"
                            />
                            <FileListDoneButton
                                v-if="buttonConditions.doneButtonCondition(file, accountRole, usage)"
                                :file="file"
                                :primary-color="primaryColor"
                                @done-file="$emit('doneFile', file)"
                            />
                            <!-- This info button displays user notes when given by the teacher -->
                            <FileListInfoButton
                                v-if="usage === 'correctedTeacher' && buttonConditions.infoButtonCondition('userNote', usage, file, accountRole, person)"
                                :usage="'userNote'"
                                :file="file"
                                :primary-color="primaryColor"
                                :person="person"
                            />
                            <!-- This info button displays that the file can't be edited within eklara -->
                            <FileListInfoButton
                                v-if="usage === 'correctedTeacher' && buttonConditions.infoButtonCondition('fileFormat', usage, file, accountRole, person)"
                                :usage="'fileFormat'"
                                :file="file"
                                :primary-color="primaryColor"
                                :person="person"
                            />
                            <!-- This info button displays that the file was given back to the teacher without an answer -->
                            <FileListInfoButton
                                v-if="usage === 'correctedTeacher' && buttonConditions.infoButtonCondition('noAnswer', usage, file, accountRole, person)"
                                :usage="'noAnswer'"
                                :file="file"
                                :primary-color="primaryColor"
                                :person="person"
                            />
                            <FileListEyeButton
                                v-if="buttonConditions.fileListEyeButtonCondition(file, usage)"
                                :file="file"
                                :primary-color="primaryColor"
                                @open-in-editor="$emit('openInEditor', file)"
                                @open-in-libre="$emit('openInLibre', file)"
                            />
                            <FileListVideoInVideoButton
                                v-if="buttonConditions.fileListVideoInVideoButtonCondition(file, accountRole, usage)"
                                :file="file"
                                @video-in-video-clicked="$emit('videoInVideoClicked', file)"
                            />
                            <FileListOptionsMenuButton
                                v-if="buttonConditions.fileListOptionsMenuCondition(file, usage)"
                                :default-options="buttonConditions.fileListOptionsMenuCondition(file, usage)"
                                :info-button-user-note="buttonConditions.infoButtonCondition('userNote', usage, file, accountRole, person)"
                                :info-button-file-format="buttonConditions.infoButtonCondition('fileFormat', usage, file, accountRole, person)"
                                :info-button-no-answer="buttonConditions.infoButtonCondition('noAnswer', usage, file, accountRole, person)"
                                :eye-button="usage !== 'correctedTeacher' && buttonConditions.fileListEyeButtonCondition(file, usage)"
                                :video-in-video-button="buttonConditions.fileListVideoInVideoButtonCondition(file, accountRole, usage)"
                                :file="file"
                                :account-role="accountRole"
                                :person="person"
                                @download-file="$emit('downloadFile', file)"
                                @delete-file="$emit('deleteFile', file)"
                                @delete-draft="$emit('deleteDraft', file)"
                                @upload-answer="$emit('uploadAnswer', file)"
                                @download-main-video="$emit('downloadMainVideo', file)"
                                @download-d-g-s-video="$emit('downloadDGSVideo', file)"
                                @show-information="(file)=>{currentfile = file; showDetails = true}"
                                @play-video="$emit('playVideo', file)"
                                @play-audio="$emit('playAudio', file)"
                                @edit-in-editor="$emit('editInEditor', file)"
                                @edit-in-libre="$emit('editInLibre', file)"
                                @open-in-editor="$emit('openInEditor', file)"
                                @video-in-video-clicked="$emit('videoInVideoClicked', file)"
                            />
                        </div>
                    </div>
                </div>

                <!-- large/medium/small tiles, 1/2/3 files per row on normal res -->
                <div
                    v-else
                    class="outerTileContainer"
                    :class="file.isDeleted ? 'outdated-image' : ''"
                    @click="()=>{clickDiv(file)}"
                >
                    <!-- Display image preview -->
                    <img
                        v-if="file.title && isFileThumbnailCompatibleExtension(file.title.split('.').pop().toLowerCase())"
                        :src="previewUrls[file._id]"
                        :class="view === 'largeTile' ? 'thumbnailLargeTile' : view === 'mediumTile' ? 'thumbnailMediumTile' : 'thumbnailSmallTile'"
                        alt=""
                        @click="$emit('thumbnailClicked', file)"
                    >
                    <img
                        v-else
                        :src="eklaraLogo"
                        :class="view === 'largeTile' ? 'thumbnailLargeTile' : view === 'mediumTile' ? 'thumbnailMediumTile' : 'thumbnailSmallTile'"
                        alt=""
                        @click="$emit('thumbnailClicked', file)"
                    >
                    <FileListTitle
                        :file="file"
                        :usage="usage"
                        :style="'background-color: ' + getBackgroundColorStyle(file)"
                        :file-deleted="file.isDeleted"
                        @title-clicked="titleClicked(file, false)"
                    />
                    <div
                        :class="view === 'largeTile' ? 'largeTileOverlay' : view === 'mediumTile' ? 'mediumTileOverlay' : 'smallTileOverlay'"
                        @click="$emit('thumbnailClicked', file)"
                    >
                        <v-badge
                            v-if="(usage === 'abgabenPupil' && file.correction) || (usage === 'toCorrectTeacher' && !file.seenByMe)"
                            color="#ff0000"
                            content="1"
                            :model-value="usage === 'abgabenPupil' ? !file.correction.seenByMe : usage === 'toCorrectTeacher' ? !file.seenByMe : null"
                            location="left"
                            style="position: absolute; z-index: 9; width:100%"
                        />
                        <div class="overlayRowContainer">
                            <div />
                            <FileListOptionsMenuButton
                                v-if="buttonConditions.fileListOptionsMenuCondition(file, usage)"
                                :file="file"
                                :account-role="accountRole"
                                :person="person"
                                :icon-white="true"
                                @download-file="$emit('downloadFile', file)"
                                @delete-file="$emit('deleteFile', file)"
                                @delete-draft="$emit('deleteDraft', file)"
                                @upload-answer="$emit('uploadAnswer', file)"
                                @download-main-video="$emit('downloadMainVideo', file)"
                                @download-d-g-s-video="$emit('downloadDGSVideo', file)"
                                @show-information="(file)=>{currentfile = file; showDetails = true}"
                            />
                        </div>
                        <div class="overlayRowContainer">
                            <div style="display: flex; flex-direction: row">
                                <FileListEditButton
                                    v-if="buttonConditions.editButtonCondition(file, usage)"
                                    :file="file"
                                    :primary-color="primaryColor"
                                    :person="person"
                                    :account-role="accountRole"
                                    @edit-in-editor="$emit('editInEditor', file)"
                                    @edit-in-libre="$emit('editInLibre', file)"
                                    @edit-draft="$emit('editDraft', file)"
                                />
                                <FileListEyeButton
                                    v-if="buttonConditions.fileListEyeButtonCondition(file, usage)"
                                    :file="file"
                                    :primary-color="primaryColor"
                                    @open-in-editor="$emit('openInEditor', file)"
                                />
                                <FileListPlayButton
                                    v-if="buttonConditions.playButtonCondition(file)"
                                    :file="file"
                                    :primary-color="primaryColor"
                                    :account-role="accountRole"
                                    @play-video="$emit('playVideo', file)"
                                    @play-audio="$emit('playAudio', file)"
                                />
                                <FileListDoneButton
                                    v-if="buttonConditions.doneButtonCondition(file, accountRole, usage)"
                                    :file="file"
                                    :primary-color="primaryColor"
                                    @done-file="$emit('doneFile', file)"
                                />
                                <!-- This info button displays user notes when given by the teacher -->
                                <FileListInfoButton
                                    v-if="buttonConditions.infoButtonCondition('userNote', usage, file, accountRole, person)"
                                    :usage="'userNote'"
                                    :file="file"
                                    :primary-color="primaryColor"
                                    :person="person"
                                />
                                <!-- This info button displays that the file can't be edited within eklara -->
                                <FileListInfoButton
                                    v-if="buttonConditions.infoButtonCondition('fileFormat', usage, file, accountRole, person)"
                                    :usage="'fileFormat'"
                                    :file="file"
                                    :primary-color="primaryColor"
                                    :person="person"
                                />
                                <!-- This info button displays that the file was given back to the teacher without an answer -->
                                <FileListInfoButton
                                    v-if="buttonConditions.infoButtonCondition('noAnswer', usage, file, accountRole, person)"
                                    :usage="'noAnswer'"
                                    :file="file"
                                    :primary-color="primaryColor"
                                    :person="person"
                                />
                            </div>
                            <FileListVideoInVideoButton
                                v-if="buttonConditions.fileListVideoInVideoButtonCondition(file, accountRole, usage)"
                                :file="file"
                                @video-in-video-clicked="$emit('videoInVideoClicked', file)"
                            />
                        </div>
                    </div>
                </div>
                <div
                    :ref="`speaker${file._id}`"
                    class="showSpeaker"
                >
                    <v-btn
                        v-if="accountRole === 'pupil'"
                        elevation="6"
                        absolute
                        size="x-small"
                        style="height: 30px; width:30px; background-color:white; border-radius: 4px !important; bottom: 25px; right: 5%;"
                        @click="readText(file.title, $event)"
                    >
                        <img
                            alt="Vorlesen"
                            :src="lautsprecherIcon"
                            style="height: 20px;"
                        >
                    </v-btn>
                </div>
            </div>
        </div>

        <div
            v-if="(view === 'smallTile' && files.length > 3) || (view !== 'smallTile' && files.length > 2)"
            class="d-flex flex-column align-center justify-center mt-2"
        >
            <v-btn
                :style="`background-color: ${primaryColor ? primaryColor : 'rgb(var(--v-theme-chatColor))'}`"
                size="small"
                elevation="0"
                @click="expandList = !expandList"
            >
                <img
                    :src="arrowDown"
                    :alt="expandList ? 'Weniger anzeigen' : 'Mehr anzeigen'"
                    style="height: 20px; filter: brightness(1000%)"
                    :style="expandList ? 'transform: rotate(180deg)' : ''"
                >
            </v-btn>
        </div>
        <FileInformationDialog
            v-if="currentfile"
            v-model:show-details="showDetails"
            :file="currentfile"
            @close="(file)=>{showDetails = false}"
        />
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import {textToSpeech} from "@/util/textToSpeech";
import eklaraLogo from '@/assets/Icons/eklara-logoV2.svg';
import arrowDown from "@/assets/Icons/FaceliftIcons/dropdown_mehr_unten_pfeil.svg";
import { isFileThumbnailCompatibleExtension } from "@/util/MimetypeHelper"
import FileListPlayButton from "@/components/SharedViews/Stundenplan/Schueler_und_Lehrer/FileList/FileListPlayButton";
import FileListEditButton from "@/components/SharedViews/Stundenplan/Schueler_und_Lehrer/FileList/FileListEditButton";
import FileListInfoButton from "@/components/SharedViews/Stundenplan/Schueler_und_Lehrer/FileList/FileListInfoButton";
import FileListOptionsMenuButton
    from "@/components/SharedViews/Stundenplan/Schueler_und_Lehrer/FileList/FileListOptionsMenuButton";
import FileInformationDialog from '@/components/Utils/FileInformationDialog.vue';
import FileListVideoInVideoButton
    from "@/components/SharedViews/Stundenplan/Schueler_und_Lehrer/FileList/FileListVideoInVideoButton";
import FileListTitle from "@/components/SharedViews/Stundenplan/Schueler_und_Lehrer/FileList/FileListTitle";
import FileListDoneButton from "@/components/SharedViews/Stundenplan/Schueler_und_Lehrer/FileList/FileListDoneButton";
import FileListEyeButton from "@/components/SharedViews/Stundenplan/Schueler_und_Lehrer/FileList/FileListEyeButton";
import * as buttonConditions from "@/components/SharedViews/Stundenplan/Schueler_und_Lehrer/FileList/FileListConditions"
import lautsprecherIcon from "@/assets/Icons/lautsprecher-13.svg";
import lautsprecherAusIcon from "@/assets/Icons/lautsprecher-aus-89.svg";

export default {
    name: "FileList",
    components: {
        FileListTitle,
        FileListVideoInVideoButton,
        FileListOptionsMenuButton,
        FileListInfoButton,
        FileListEditButton,
        FileListPlayButton,
        FileListDoneButton,
        FileListEyeButton,
        FileInformationDialog,
    },
    props: {
        files: { type: Array, required: true },
        previewUrls: { type: Array, required: false },
        // possible types: 'list', 'largeTile', 'smallTile'
        view: { type: String, required: true },
        primaryColor: { type: String, required: false},
        person: { type: Object, required: false },
        accountRole: { type: String, required: true },
        usage: { type: String, required: false },
        appointment: { type: Object, required: false },
        pupilAccountIdCorrection: { type: String, required: false },
    },
    data: () => ({
        expandList: false,
        speechActive: false,

        isFileThumbnailCompatibleExtension,
        buttonConditions,

        eklaraLogo,
        arrowDown,
        lautsprecherIcon,
        lautsprecherAusIcon,
        showDetails: false,
        currentfile: null,
    }),
    computed: {
        ...mapState('translation', ['targetLang',]),
        filesToDisplay() {
            if(this.expandList) {
                return this.files;
            }
            if(this.view === 'smallTile') {
                return this.files.slice(0, 3);
            }
            return this.files.slice(0, 2);
        }
    },
    watch:{
        currentfile(newval){
            console.log(
                'currfile',newval
            )
        }
    },
    methods: {
        ...mapActions('translation', [ 'setTranslatedText', 'showTranslation', 'translateToTargetLang' ]),
        ...mapMutations('snackbar', ['showSnackbar']),
        clickDiv(file){
            console.log('clickedIntoDiv', file)
        },
        getBackgroundColorStyle(file) {
            if(file.draft) {
                return '#f5a03240';
            } else if(this.usage === 'abgabenPupil' && file.correction) {
                return '#8cbe464d';
            }
        },
        titleClicked(file, list) {
            if (file.isDeleted) {
                this.showSnackbar({
                    message: "Aufgrund 30 Tagen Inaktivität ist die Datei nicht mehr verfügbar.",
                    color: "error"
                });
                return;
            }
            if (this.accountRole === 'pupil') {
                this.showSpeakerBtn(file._id, list);
            }
            this.$emit('titleClicked', file)
        },
        showSpeakerBtn(id, list){
            let element;
            if (list) {
                element = this.$refs[`listSpeaker${id}`][0];
            } else {
                element = this.$refs[`speaker${id}`][0];
            }
            if (element) {
                element.classList.remove('showSpeaker');


                setTimeout(() => {
                    element.classList.add('showSpeaker');
                }, 3000);
            }
        },
        async readText(text, event) {
            let txt;
            let tmp = document.createElement("DIV");
            tmp.innerHTML = text;
            txt = tmp.textContent || tmp.innerText || "";

            if (this.targetLang !== 'de') {
                txt = await this.translateToTargetLang({
                    targetLang: this.targetLang,
                    textToTranslate: txt
                });

                this.setTranslatedText(txt);
                this.showTranslation(true);

                if (this.showTextTimeout) {
                    clearTimeout(this.showTextTimeout);
                    this.showTextTimeout = null;
                }

                this.showTextTimeout = setTimeout(() => {
                    this.showTranslation(false);
                    this.showTextTimeout = null;
                }, 15000)
            }

            await textToSpeech(txt, this.targetLang, () => {
                event.target.src = lautsprecherAusIcon;
                event.target.alt = 'Stop';
            }, () => {
                event.target.src = lautsprecherIcon;
                event.target.alt = 'Vorlesen';
            });
        },
    }

}
</script>

<style scoped lang="scss">
.showSpeaker{
    display: none;
}

// Stuff for all or multiple views
.fileListOuterContainer {
    display: flex;
    flex-direction: column;
}

// list stuff
.listContainer {
    font-size: medium;
    font-weight: normal;
    line-height: 25px;
    width: 100%;
}

.listRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: column;
    width: 100%;
    padding: 4px 0 4px 8px;
    border-top-style: outset;
    border-top-width: 2px;
    border-radius: 4px;
}

.largeTileInfoFooterContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 4px 0;
    align-items: center;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

// overall tile stuff
.tileListContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.outerTileContainer {
    width: 100%;
    border-radius: 8px;
}

.overlayRowContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

// large tile stuff
.largeTileContainer {
    width: 98%;
    position: relative;
    margin: 1%;
}

.thumbnailLargeTile {
    width: 100%;
    height: 8vmax;
    min-height: 120px;
    object-fit: cover;
    border-radius: 8px;
}

.largeTileOverlay {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    left: 0;
    top: 0;
    width: 100%;
    height: 8vmax;
    min-height: 120px;
    box-shadow: inset 0 0 70px rgba(0,0,0,.4);
    border-radius: 8px;
    cursor: pointer;
}

// medium tile stuff
.mediumTileContainer {
    width: 48%;
    position: relative;
    float: left;
    margin: 1%;
}

.thumbnailMediumTile {
    width: 100%;
    height: 10vmax;
    min-height: 120px;
    object-fit: cover;
    border-radius: 8px;
}

.mediumTileOverlay {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    left: 0;
    top: 0;
    width: 100%;
    height: 10vmax;
    min-height: 120px;
    box-shadow: inset 0 0 70px rgba(0,0,0,.4);
    border-radius: 8px;
    cursor: pointer;
}

// small tile stuff
.smallTileContainer {
    width: 31.33%;
    position: relative;
    float: left;
    margin: 1%;
}

.thumbnailSmallTile {
    width: 100%;
    height: 7vmax;
    min-height: 120px;
    object-fit: cover;
    border-radius: 8px;
}

.smallTileOverlay {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    left: 0;
    top: 0;
    width: 100%;
    height: 7vmax;
    min-height: 120px;
    box-shadow: inset 0 0 70px rgba(0,0,0,.4);
    border-radius: 8px;
    cursor: pointer;
}
</style>

<style lang="css">
.fileListButton {
    min-height: 36px;
    min-width: 36px;
    margin: 4px;
}

@media only screen and (max-width: 600px) {
    .fileListButton {
        width: 28px !important;
        height: 28px !important;
        min-height: 28px !important;
        min-width: 28px !important;
    }

}
    .fileListButtonIcon {
        height: 16px;
    }

.fileListButtonSmallTile {

}

.fileListButtonIconSmallTile {

}
</style>
