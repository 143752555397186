<template>
    <v-dialog
        v-model="deleteDialog"
        max-width="350px"
        @close="close"
    >
        <v-card
            class="cardStyle"
            style="overflow: hidden; border-radius: 16px"
        >
            <v-card-title
                class="text-h5 mb-1 d-flex align-center justify-space-between"
                style="background-color: rgb(var(--v-theme-headerblue)); margin-bottom: 10px"
            >
                <p style="color: white">
                    Löschen bestätigen?
                </p>
                <v-btn
                    icon="$vuetify"
                    variant="plain"
                    color="transparent"
                    elevation="0"
                    style="margin-right: -8px"
                    @click="close"
                >
                    <img
                        :src="cancelIcon"
                        class="icon30 iconToWhite"
                        alt="schließen"
                    >
                </v-btn>
            </v-card-title>

            <v-card-text class="mt-2">
                <div v-if="killThemAll.length">
                    <p>
                        Die folgenden Elemente werden beim Drücken auf den
                        <span style="color: red">Löschen</span>
                        Knopf <span style="font-weight: bold">gelöscht</span>.
                        Bist du dir sicher, dass du fortfahren willst?
                    </p>
                    <div style="height: 10px" />
                    <p
                        v-for="element in killThemAll"
                        :key="`${element._id}ToDelete`"
                        style="margin-left: 20px; font-weight: bold"
                    >
                        {{ "Pinnwand: " + element.title }}
                    </p>
                </div>
            </v-card-text>

            <v-card-actions class="d-flex justify-center mb-2">
                <v-btn
                    class="optionBtn text-none ml-2"
                    color="#F5F5F5"
                    alt="Abbrechen"
                    style="width: 40%"
                    @click="close"
                >
                    <img
                        :src="cancelIcon"
                        style="height: 20px;"
                        class="mr-2"
                    >
                    Abbrechen
                </v-btn>

                <v-spacer />

                <v-btn
                    class="optionBtn text-none ml-2"
                    color="rot"
                    theme="dark"
                    style="width: 40%"
                    @click="confirmDelete"
                >
                    <img
                        :src="recycleBinIcon"
                        style="height: 20px; filter: brightness(1000%)"
                        class="mr-2"
                        alt="Löschen"
                    >
                    Löschen
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import * as backend from "../../api/backend";
import { mapActions, mapMutations } from "vuex";
import recycleBinIcon from "@/assets/Icons/FaceliftIcons/loeschen_muelleimer.svg";
import cancelIcon from "@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg";

export default {
    name: "PinboardElementDeleteDialog",
    props: {
        items: { required: true, type: Array },
    },
    data: () => ({
        deleteDialog: false,
        killThemAll:[],
        recycleBinIcon,
        cancelIcon,
        deleteSuccessful: true
    }),
    mounted() {
        this.extractDeleteData();
        this.deleteDialog = true;
    },
    methods: {
        ...mapMutations("snackbar", ["showSnackbar"]),
        ...mapActions("pinboard", ["deletePinboard"]),

        extractDeleteData() {
            this.items.forEach((item) => {
                this.killThemAll.push(item);
            });
        },
        async confirmDelete() {
            this.deleteSuccessful = true;
            await this.items.reduce(async (previousPromise, item) => {
                const previous = await previousPromise;
                let response;
                response = await this.deletePinboard(item._id);
                if (response.status === 204) {
                    this.showSnackbar({ message: 'Pinnwand wurde erfolgreich gelöscht!' });
                } else if (response.status === 403) {
                    this.showSnackbar({ message: 'Du hast nicht die Rechte zum Löschen der Pinnwand!', color: 'error' });
                } else {
                    this.deleteSuccessful = false;
                }
                previous.push(response);
                return previous;
            }, Promise.resolve([]));
            if (this.deleteSuccessful) {
                this.$emit('deleteComplete', this.items[0]._id);
            } else {
                this.showSnackbar({ message: 'Es ist ein unerwarteter Fehler aufgetreten!', color: 'error' });
            }
            this.close();
        },
        close() {
            this.deleteDialog = false;
            this.$emit('close');
        }
    }
}
</script>

<style scoped>
.optionBtn {
    border-radius: 8px;
    min-width: 35px !important;
    height: 35px !important;
}
</style>
