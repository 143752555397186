<template>
    <v-snackbar
        v-model="snackbarVisible"
        :timeout="snackbarTimeout"
        :color="snackbarColor"
        :location="showTop ? 'top' : undefined"
        :actions="snackbarClosable ? [{ text: 'Close', action: clickCloseSnackbar }] : []"
    >
        <template v-slot:actions>
            <v-btn
                variant="text"
                size="small"
                @click="clickCloseSnackbar"
            >
                <img
                    class="iconToWhite icon20"
                    alt="Schließen"
                    :src="closeIcon"
                >
            </v-btn>
        </template>
        {{ snackbarMessage }}
    </v-snackbar>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import closeIcon from "../../assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg";

export default {
  name: "CustomSnackbar",
  components: {},
  data: () => ({
    closeIcon,
    snackbarVisible: false,
  }),
  computed: {
    ...mapState("snackbar", [
      "snackbarMessage",
      "snackbarTimeout",
      "snackbarMessageNumber",
      "snackbarColor",
      "snackbarClosable",
      "showTop",
    ]),
  },

  watch: {
    snackbarMessageNumber() {
      // Set first to false then true to reset timeout
      this.snackbarVisible = true;
    },
  },
  methods: {
    clickCloseSnackbar() {
      this.snackbarVisible = false;
    },
  },
};
</script>
<style scoped>
.iconToWhite {
  filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%)
    hue-rotate(36deg) brightness(104%) contrast(105%);
}
</style>
