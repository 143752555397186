<template>
    <v-dialog
        :model-value="localShowProgress"
        :persistent="true"
        width="500"
        @update:model-value="updateShowProgress"
    >
        <v-card
            id="card"
            style="border-radius: 16px"
        >
            <v-card-title
                :style="`background-color: ${headerColor}`"
            >
                <h1
                    tabindex="0"
                    style="color: white"
                >
                    {{ title }}
                </h1>
            </v-card-title>
            <v-card-text>
                <v-container class="pa-0">
                    <v-row>
                        <v-col>
                            <v-progress-linear
                                id="progressbar"
                                :model-value="localProgress"
                                height="100%"
                                color="gruen"
                                :indeterminate="indeterminate"
                                @update:model-value="updateProgress"
                            >
                                <strong>{{ Math.ceil(localProgress) }}%</strong>
                            </v-progress-linear>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn
                                elevation="2"
                                size="x-small"
                                class="ml-2 pa-0"
                                style="width: 30px; height: 30px"
                                @click="abortProgress"
                            >
                                <img
                                    :src="schliesenIcon"
                                    style="height: 20px;"
                                >
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <p>Wenn der Upload lange dauert, kann es an einer langsamen Internet-Verbinung oder einer großen Datei liegen.</p>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import schliesenIcon from '../assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg'

export default {
  name: 'ProgressBar',
  props: {
    title: { type: String, required: true },
    showProgress: { type: Boolean, required: true },
    indeterminate: { type: Boolean, required: false, default: false },
    progress: { type: Number, required: true },
    abortProgress: { type: Function, required: true },
    headerColor: {type: String, required: false, default: 'rgb(var(--v-theme-headerblue))'}
  },
  data() {
    return {
      schliesenIcon,
      localProgress: this.progress, // Local copy of progress
      localShowProgress: this.showProgress, // Local copy of showProgress
    };
  },
  watch: {
    progress(newValue) {
      this.localProgress = newValue; // Sync localProgress with prop
    },
    showProgress(newValue) {
      this.localShowProgress = newValue; // Sync local value with prop
    },
  },
  methods: {
    updateProgress(newValue) {
      this.localProgress = newValue;
      this.$emit('update:progress', newValue); // Emit updates to the parent
    },
    updateShowProgress(newValue) {
      this.localShowProgress = newValue; // Update local value
      this.$emit('update:showProgress', newValue); // Emit changes to parent
    },
  },
};
</script>

<style>
.v-progress-linear .v-progress-linear__indeterminate--active .long {
    animation-duration: 1.1s !important;
}

.v-progress-linear .v-progress-linear__indeterminate--active .short {
    animation-duration: 1.1s !important;
}
</style>

<style lang="scss" scoped>
#card {
  border-radius: 5px;
}

#progressbar {
  border-radius: 5px;
}
</style>
<style scoped>
.v-overlay__scrim {
    background-color: rgba(0, 0, 0, 0.7) !important; /* Replace 0.7 with your desired opacity */
}
</style>
