<template>
    <div
        id="btnsContainer"
        class="d-flex justify-start"
        :style="accountRole === 'pupil' ? 'flex-direction: column' : ''"
    >
        <!--#region Pupil -->
        <v-tooltip
            v-if="accountRole === 'pupil'"
            :model-value="shortcutTooltips"
            :disabled="!showTooltips || bottomNav"
            location="bottom"
        >
            <template v-slot:activator="{ props }">
                <div
                    class="d-flex align-center"
                    @click="requestKlassenraums(true)"
                >
                    <v-btn
                        id="joinKlassenraumBtnPupil"
                        elevation="0"
                        size="x-small"
                        class="mr-3"
                        style="justify-content: left"
                        :color="atLeastOneKlassenraumOpen ? 'gruen' : 'fgrau'"
                        :aria-expanded="menu"
                        :aria-label="atLeastOneKlassenraumOpen ? 'Klassenraum geöffnet' : 'Klassenraum geschlossen'"
                        aria-haspopup="menu"
                        v-bind="props"
                    >
                        <img
                            :src="videoIcon"
                            alt="Klassenraum"
                            aria-hidden="true"
                        >

                        <p
                            v-if="windowWidth <= 900"
                            class="ml-0"
                        >
                            <small><u>K</u>lassenraum</small>
                        </p>
                        <p
                            v-else
                            class="ml-2"
                        >
                            <u>K</u>lassenraum
                        </p>
                    </v-btn>
                </div>
            </template>
            <span v-if="atLeastOneKlassenraumOpen">Ersten <span style="text-decoration: underline white">K</span>lassenraum beitreten (Alt + K)</span>
            <span v-else><span style="text-decoration: underline white">K</span>lassenraum ist geschlossen</span>
        </v-tooltip>
        <!--#endregion -->

        <!--#region Teacher -->
        <v-btn
            v-if="accountRole === 'teacher'"
            id="joinKlassenraumBtnTeacher"
            :color="teacherClassroomIsOpen ? 'gruen' : 'fgrau'"
            style="border-top-right-radius: 0; border-bottom-right-radius: 0; justify-content: left"
            class="px-2"
            elevation="0"
            @click="joinKlassenraum(teacherClassroomId)"
        >
            <img
                :src="videoIcon"
                :alt="teacherClassroomIsOpen ? 'Klassenraum geöffnet' : 'Klassenraum geschlossen'"
            >
            <p>Schüler</p>
        </v-btn>

        <v-btn
            v-if="accountRole === 'teacher'"
            id="schlossBtn"
            :color="teacherClassroomIsOpen ? 'gruen' : 'fgrau'"
            class="ml-0"
            elevation="0"
            style="border-top-left-radius: 0; border-bottom-left-radius: 0; border-left-style: solid; border-color: rgba(0, 0, 0, 0.33) !important"
            :size="getButtonSize(windowWidth)"
            @click="toggleKlassenraum"
        >
            <img
                :src="lockIcon"
                :alt="teacherClassroomIsOpen ? 'Raum geöffnet' : 'Raum geschlossen'"
            >
        </v-btn>
        <!--#endregion -->

        <!-- Pupil can chose from teachers -->
        <div
            v-if="menu && windowWidth >= 770"
            id="klassenraumMenu"
            v-click-outside="onClickOutsideMenu"
            :style="windowWidth > 900 ? 'position: fixed' : ''"
            role="menu"
        >
            <v-row
                v-for="(klassenraum, index) in klassenraums"
                :key="index"
                class="pa-0 ma-3"
            >
                <v-col
                    cols="10"
                    class="pa-0 pr-2"
                    @click="joinKlassenraum(klassenraum._id)"
                >
                    <v-btn
                        tabindex="-1"
                        class="d-flex justify-start"
                        style="padding: 0 6px !important"
                        width="100%"
                        color="hellgrau"
                        elevation="0"
                        aria-hidden="true"
                    >
                        <Avatar
                            :profile="klassenraum.teacher.account._id"
                            :show-burger="false"
                            :size="30"
                            :tabindex-avatar="-1"
                        />
                        <p
                            class="text-capitalize ma-0 ml-2"
                            style="color: rgb(var(--v-theme-dunkelgrau))"
                        >
                            {{ getAppropriateTeacherDisplayName(klassenraum.teacher) }}
                        </p>
                    </v-btn>
                </v-col>

                <v-col
                    cols="2"
                    class="pa-0 d-flex justify-end"
                    @click="joinKlassenraum(klassenraum._id)"
                >
                    <v-tooltip
                        :model-value="showSelectionTooltips"
                        :disabled="!showTooltips || !klassenraum.isOpen"
                        location="bottom"
                    >
                        <template v-slot:activator="{ props }">
                            <v-btn
                                class="videoBtn"
                                size="x-small"
                                elevation="0"
                                role="button"
                                :aria-label="klassenraum.isOpen ? 'Klassenraum von ' + getAppropriateTeacherDisplayName(klassenraum.teacher) + ' beitreten' : 'Klassenraum von ' + getAppropriateTeacherDisplayName(klassenraum.teacher) + ' ist geschlossen'"
                                :color="klassenraum.isOpen ? 'gruen' : 'hellgrau'"
                                v-bind="props"
                                @focusout="() => { if(index === klassenraums.length - 1) menu = false }"
                                @click="joinKlassenraum(klassenraum._id)"
                            >
                                <img
                                    :src="videoIcon"
                                    :class="klassenraum.isOpen ? '' : 'iconToDunkelgrau'"
                                    :alt="klassenraum.isOpen ? 'Klassenraum beitreten (Alt + K)' : 'Klassenraum geschlossen'"
                                >
                            </v-btn>
                        </template>
                        <div style="display: flex; flex-direction: column; text-align: center">
                            <span><span style="text-decoration: underline white">K</span>lassenraum beitreten (Alt + K)</span>
                            <img
                                v-if="hoeren"
                                :src="beitretenMetacom"
                                width="100"
                                style="margin: auto"
                                alt="Klassenraum beitreten"
                            >
                        </div>
                    </v-tooltip>
                </v-col>
            </v-row>
        </div>

        <!-- Pupil can chose from teachers in MOBILE -->
        <div
            v-if="menu && windowWidth < 770"
            id="klassenraumMenuBtm"
            v-click-outside="onClickOutsideMenu"
        >
            <v-row
                v-for="(klassenraum, index) in klassenraums"
                :key="index"
                class="pa-0 ma-3"
            >
                <v-btn
                    color="hellgrau"
                    class="d-flex justify-start"
                    style="padding: 0 6px !important"
                    @click="joinKlassenraum(klassenraum._id)"
                >
                    <Avatar
                        :profile="klassenraum.teacher.account._id"
                        :show-burger="false"
                        :size="30"
                        :tabindex-avatar="-1"
                    />
                    <p
                        class="text-truncate text-capitalize text-left ma-0 ml-2"
                        style="width: 80px; color: rgb(var(--v-theme-dunkelgrau))"
                    >
                        {{ getAppropriateTeacherDisplayName(klassenraum.teacher) }}
                    </p>
                </v-btn>

                <v-btn
                    :color="klassenraum.isOpen ? 'gruen' : 'hellgrau'"
                    class="videoBtn"
                    size="x-small"
                    @click="joinKlassenraum(klassenraum._id)"
                >
                    <img
                        :src="videoIcon"
                        :style="klassenraum.isOpen ? '' : 'iconToDunkelgrau'"
                        :alt="klassenraum.isOpen ? 'Videokonferenz offen' : 'Videokonferenz geschlossen'"
                    >
                </v-btn>
            </v-row>
        </div>

        <VideoTutorial
            v-if="videoTutorial && showVideoTutorial && !videoTutorialClosed"
            :video-tutorial="videoTutorial"
            @close="onVideoTutorialClose"
        />
    </div>
</template>

<script>
import {mapActions, mapState, mapGetters, mapMutations} from 'vuex';

import VideoTutorial from "../components/Tutorial/VideoTutorial.vue";

import Avatar from "@/components/SharedViews/Profile/Avatar";

import videoIcon from '@/assets/Icons/video.svg';
import lockIcon from '@/assets/Icons/lock.svg';
import beitretenMetacom from '../assets/METACOM/Stundenplan/unterricht.png';

export default {
    name: "JoinKlassenraumButton",
    components: {
        VideoTutorial,
        Avatar,
    },
    props: {
        bottomNav: {type: Boolean, required: false, default: false},
    },
    data: () => ({
        videoIcon,
        lockIcon,
        beitretenMetacom,

        menu: '',
        klassenraums: '',
        teacherClassroomId: '',
        teacherClassroomIsOpen: null,
        atLeastOneKlassenraumOpen: null,      //to make Klassenraum-btn for pupil green if >= 1 room isOpen

        // Show video Tutorial is set to true when button is clicked
        // videoTutorialClosed is set to false when VideoTutorial is closed
        videoTutorial: false,
        showVideoTutorial: false,
        videoTutorialClosed: false,
        videoTutorialOnCloseJoinClassroomId: '',

        sehen: false,
        hoeren: false,
        babyView: false,
    }),
    computed : {
        ...mapGetters("auth",["accountRole"]),
        ...mapState("tooltips", ["shortcutTooltips", "showTooltips"]),
        ...mapState('util', ['windowWidth']),
        ...mapGetters('videoTutorial', [ 'videoTutorialsByFilename' ]),
        showSelectionTooltips() {
            return (this.shortcutTooltips && this.menu);
        }
    },
    async mounted() {
        window.addEventListener("keyup", this.handleKeyUp);
        if (this.accountRole === 'pupil') {
            await this.requestBabyViewAndAccessibilityMode();
        }

        await this.getTeacherRoom();
        await this.requestKlassenraums();
        this.videoTutorial = (this.accountRole === 'teacher' || this.accountRole === 'maintainer')
            ? this.videoTutorialsByFilename['Tutorial_bbb_Lehrer_Verwaltung.mp4']
            : this.videoTutorialsByFilename['Tutorial_bbb_Eltern_Schueler.mp4'];
    },
    beforeUnmount() {
        window.removeEventListener("keyup", this.handleKeyUp);
    },
    methods: {
        ...mapActions('util', [ 'toggleLoading' ]),
        ...mapActions('rooms', [ 'teacherClassroom', 'changeRoomStatus', 'joinClassroom' ]),
        ...mapActions('teachers', [ 'getProfilePicture' ]),
        ...mapActions('pupils', [ 'getMePupil' ]),
        ...mapActions('tooltips', [ 'triggerShortcutTooltips' ]),
        ...mapMutations('snackbar', [ 'showSnackbar' ]),

        getButtonSize(width) {
            if (width <= 770) return 'x-small';
            if (width <= 900) return 'small';
            return 'default'; // or 'medium' if defined in your theme
        },
        async requestBabyViewAndAccessibilityMode() {
            let temp = await this.getMePupil();
            this.babyView = temp.babyView;
            if(temp.accessibility) {
                this.sehen = temp.accessibility.sehen;
                this.hoeren = temp.accessibility.hoeren;
            } else {
                this.sehen = false;
                this.hoeren = false;
            }
            this.loaded = true; //to check if they are babys
            if (this.babyView) {
                this.babyViewTut = true;
            }
        },
        handleKeyUp (event) {
            if (event.altKey && event.key === 'k') {
                if (this.atLeastOneKlassenraumOpen) {
                    this.joinKlassenraum(this.klassenraums[0]._id);
                    this.triggerShortcutTooltips();
                }
            }
        },
        onVideoTutorialClose() {
            this.videoTutorial = false;
            this.videoTutorialClosed = true;
            this.joinKlassenraum(this.videoTutorialOnCloseJoinClassroomId);
        },
        async joinKlassenraum(id) {
            console.log('JOINING KLASSENRAUM!!');
            this.toggleLoading(true);

            const response = await this.joinClassroom(id);
            if(this.accountRole === 'teacher') {
                if(!response.redirectUrl) {
                    this.showSnackbar({message: "Um den Klassenraum beitreten zu können, müssen Sie ihn erst öffnen. Dazu müssen Sie das Schloss anklicken.", color: "fgrau"});
                    this.toggleLoading(false);
                    return;
                }
            } else {
                if(!response.redirectUrl) {
                    this.showSnackbar({message: "Dieser Klassenraum ist geschlossen.", color: "fgrau"});
                    this.toggleLoading(false);
                    return;
                }
            }

            if(!response || response === 422 || response === 404) {
                this.showSnackbar({message: "Um den Klassenraum beitreten zu können, müssen Sie ihn erst öffnen. Dazu müssen Sie das Schloss anklicken.", color: "error"});
                this.toggleLoading(false);
                return;
            }

            if(this.videoTutorial) {
                // Open video tutorial and after try to exec joinClassroom again.
                this.showVideoTutorial = true;
                this.videoTutorialOnCloseJoinClassroomId = id;
                return;
            }
            window.location.href = response.redirectUrl;
            this.toggleLoading(false);
        },
        async toggleKlassenraum() {
            let newStatus = !this.teacherClassroomIsOpen;
            let data = {
                classroomId: this.teacherClassroomId,
                newStatus: newStatus,
            }
            let res = await this.changeRoomStatus(data);

            if(res) {   // if successful also change the local one
                this.teacherClassroomIsOpen = res.isOpen;

                // and show snackbar
                if (this.teacherClassroomIsOpen) {
                    this.showSnackbar({message: "Dieser Klassenraum ist nun geöffnet.", color: "success"});
                } else {
                    this.showSnackbar({message: "Der Klassenraum wurde geschlossen.", color: "success"});
                }
            }
        },

        async requestKlassenraums(menu) {
            // Make sure route is only called initial and if pupil opens menu, cause big route
            if (this.accountRole === 'pupil' && !this.menu) {
                this.klassenraums = await this.teacherClassroom();
                for (let i = 0; i < this.klassenraums.length; i++) {
                    if (this.klassenraums[i].teacher === null) {
                        this.klassenraums[i].teacher = {
                            _id: null,
                            name: "Bugfix",
                            lastName: "Bugfix",
                            gender: "d",
                            note: "",
                            schoolSubjects: [],
                            profilePicture: "",
                            email: "bugfix@bugfix.de",
                            account: {
                                groups: [],
                                qrPassword: [],
                                role: 'teacher',
                                accountName: 'Bugfix',
                                privateFolder: [],
                                note: '',
                            }
                        }
                    }
                    if (this.klassenraums[i].isOpen) {
                        this.atLeastOneKlassenraumOpen = true;
                        break;
                    }
                }
            }
            // If called on click of Klassenraum btn, also toggle the menu
            if (menu && this.atLeastOneKlassenraumOpen) {
                this.menu = !this.menu;
            }
        },

        getAppropriateTeacherDisplayName(teacher) {
          const teacherBugfix = teacher || {};
          let name = '';
          let lastName = '';
          if( teacherBugfix && teacherBugfix.name) {
            name = teacherBugfix.name.slice(0,1) + '. ';
          }
          if(teacherBugfix && teacherBugfix.lastName) {
            lastName = teacherBugfix.lastName;
          }
          const fullName = name + lastName;
          return fullName;
        },

        async getTeacherRoom() {
            if (this.accountRole === 'teacher') {
                let room = await this.teacherClassroom();
                this.teacherClassroomId = room._id;
                this.teacherClassroomIsOpen = room.isOpen;
            }
        },

        onClickOutsideMenu() {
            this.menu = false;
        },
    }
}
</script>

<style scoped lang="scss">
.v-btn {
  border-radius: 8px;
}

.ellipsis {
    text-overflow: ellipsis;

    /* Required for text-overflow to do anything */
    white-space: nowrap;
    overflow: hidden;
}

.roundIcon {
    border-radius: 50%;
}

#joinKlassenraumBtnTeacher {
    color: white;
    text-transform: inherit;
    height: 50px !important;

    img {
      height: 25px;
      filter: brightness(1000%);
      margin-right: 4px;
    }

    p {
        font-size: large;
        font-weight: 400;
        line-height: 0;
        margin-bottom: 0;
    }
}

#joinKlassenraumBtnPupil {
    color: white;
    text-transform: inherit;
    height: 50px !important;

    img {
      height: 25px;
      filter: brightness(1000%);
    }

    p {
      font-size: 20px !important;
      font-weight: 400;
      line-height: 0;
      margin-bottom: 0;
    }
}

#schlossBtn {
    height: 50px;
    min-width: 50px;
    width: 50px;

    img {
        height: 30px;
        filter: brightness(1000%);
    }
}

#klassenraumMenu {
    top: 74px;
    width: 250px;
    background-color: rgb(var(--v-theme-headerblue));
    border-radius: 0 0 8px 8px;
    z-index: 10; //to be one higher than fach
    color: rgb(var(--v-theme-grau));

    img {
      height: 25px;
    }
}

.videoBtn {
    height: 36px !important;
    width: 36px !important;
}

@media only screen and (max-width: 900px) {
    #joinKlassenraumBtnTeacher {
        height: 40px !important;
        padding: 8px;
        margin-left: 8px;

        p {
            font-size: small;
        }

        img {
            height: 21px !important;
        }
    }

    #joinKlassenraumBtnPupil {
        height: 40px !important;
        width: 160px;
        padding: 8px;
        margin-left: 0;
        margin-bottom: 8px;

        p {
          font-size: small;
        }

        img {
            height: 21px !important;
            margin-right: 8px !important;
        }
    }

    #schlossBtn {
        height: 40px;
        min-width: 40px;
        width: 40px;

        img {
            height: 21px !important;
            margin: 0 !important;
        }
    }
}

//when change to mobile nav on bottom
@media only screen and (max-width: 500px), (max-width: 900px) and (orientation: portrait) {
    #joinKlassenraumBtnTeacher {
        padding: 8px !important;
        margin-left: 0 !important;
        margin-bottom: 8px;
        width: 120px;
    }

    #joinKlassenraumBtnPupil {
        height: 40px !important;
    }

    #klassenraumMenuBtm {
        background-color: transparent;
        border-radius: 8px;
        z-index: 2;
        color: rgb(var(--v-theme-grau));
        margin-top: -10px;

        img {
            height: 25px;
        }
    }

    #schlossBtn {
        margin-left: 0 !important;
        padding: 0 !important;
        width: 40px !important;

      img {
          margin: 0 !important;
      }
    }
}
</style>
