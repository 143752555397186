<template>
    <div
        class="my-1 mx-auto fileSystemTile dropTile makeButtonStaySquare"
        :style="dropZone"
        @click="toggleNormalUpload"
    >
        <!--        @dragover="designateDropZone"-->
        <!--        @dragleave="() => { dropZone = '' }"-->
        <!--        @dragenter="limitEvent"-->
        <!--        @drop="dropFile"-->
        <div
            v-if="listView"
            class="dropContainer"
            style="margin-top: 5px !important"
        >
            <div
                class="dropIconList"
                style="overflow: hidden"
            >
                <img
                    :src="plusIcon"
                    class="mb-2 iconToDunkelgrau"
                    style="color: rgb(var(--v-theme-dunkelgrau)); height: 24px; margin-right: 6px"
                    alt="Plus"
                >

                <p style="text-align: center; max-height: 10px">
                    {{ learningPath ? `Station hinzufügen` : `Dateien hier ablegen` }}
                </p>
            </div>
        </div>
        <div
            v-else-if="smallView"
            class="dropContainer"
        >
            <div
                class="pa-1 dropIconNormal mx-auto d-flex flex-column align-center justify-center"
                style="margin-bottom: 5px; overflow: hidden; height: 16.3vmax; max-height: 20vh"
            >
                <img
                    :src="plusIcon"
                    class="mb-2 iconToDunkelgrau"
                    style="color: rgb(var(--v-theme-dunkelgrau)); height: 50px"
                    alt="Plus"
                >

                <p style="white-space: normal; max-width: 75%; text-align: center">
                    {{ learningPath ? `Station hinzufügen` : `Dateien hier ablegen` }}
                </p>
            </div>
        </div>
        <div
            v-else
            class="dropContainer"
        >
            <div
                class="pa-1 dropIconNormal mx-auto d-flex flex-column align-center justify-center"
                style="margin-bottom: 5px; overflow: hidden"
                :style="vfsGridWidthPct <= 33 ? 'max-height: 8.6vmax' : 'height: 16.3vmax; max-height: 20vh'"
            >
                <img
                    :src="plusIcon"
                    class="mb-2 iconToDunkelgrau"
                    style="color: rgb(var(--v-theme-dunkelgrau)); height: 50px"
                    alt="Plus"
                >

                <p style="white-space: normal; max-width: 75%; text-align: center">
                    {{ learningPath ? `Station hinzufügen` : `Dateien hier ablegen` }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import plusIcon from '@/assets/Icons/plus.svg';
import { mapGetters, mapMutations } from "vuex";


export default {
    name: 'FileTileDragDropTarget',
    // TODO put color on prop to make it customizable per widget
    // TODO add on dragOver Feedback
    props: {
        listView: { type: Boolean, default: false },
        smallView: { type: Boolean, default: false },
        learningPath: { type: Boolean, default: false },
    },
    data: () => ({
        plusIcon,

        allowedFileExtensions: [
            'application/pdf',
            'image/png',
            'image/jpg',
            'video/mp4',
            'audio/mp3',
            'text/csv',
            'application/zip',
            'application/x-zip-compressed',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // Excel xlsx
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // Word docx
            'image/bmp',
            'image/tiff'
        ],
        dropZone: '',
    }),
    computed: {
        ...mapGetters('vfs', ['vfsGridWidthPct']),
    },
    methods: {
        ...mapMutations('snackbar', ['showSnackbar']),
        limitEvent(event) {
            event.stopPropagation(); event.preventDefault();
        },
        designateDropZone(event) {
            this.limitEvent(event);
            this.dropZone = 'filter: saturate(0);'
        },
        dropFile(event) {
            if (this.learningPath) {
                return;
            }
            this.limitEvent(event);
            this.dropZone = '';
            const fileArray = Array.from(event.dataTransfer.files);
            // TODO safety checks for malicious files (.exe, .sh, etc)
            const allClear = this.safetyChecks(fileArray);
            if (fileArray && allClear) {
                this.$emit('filesDrop', fileArray);
            } else if (!allClear) {
                this.showSnackbar({
                    message: 'Bitte lade keine Dateien hoch die für das System schädlich sein könnten!',
                    color: 'warning'
                });
            }
        },
        safetyChecks(fileArray) {
            // Check file extension
            return fileArray.every((file) => this.allowedFileExtensions.includes(file.type));
        },
        toggleNormalUpload() {
            if (!this.learningPath) {
                this.$emit('normalUpload');
            } else {
                this.$emit('editSharing');
            }
        }
    }
}
</script>

<style scoped lang="scss">
.fileSystemTile {
    display: flex;
    border-radius: 8px;
    color: rgb(var(--v-theme-schwarz));
    width: 95%;
    white-space: nowrap;
}

.fileSystemTile[dropzone="true"] {
    filter: saturate(0);
}

.dropTile {
    position: relative;
    display: flex;
    justify-content: center;
    // align-items: center;
    background-color: rgba(80, 80, 80, 0.2);
}

.dropContainer {
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

// Needs to be square
.makeButtonStaySquare {
    width: 94%;
    // min-width: 110px;
    // max-width: 10vmax;
    height: 94%;
    min-height: 150px;
    max-height: 10vmax;
    aspect-ratio: 1/1;
    cursor: pointer;
    display: flex;
    justify-content: center;
    border-radius: 8px;
}

@media only screen and (min-width: 0px) and (max-width: 750px) {
    .makeButtonStaySquare {
        max-height: 20vmax;
    }
}

@media only screen and (min-width: 751px) and (max-width: 900px) {
    .makeButtonStaySquare {
        min-height: 23vmax;
    }
}

// Needs to be square
.dropIconNormal {
    width: 100%;
    min-width: 150px;
    max-width: 10vmax;
    height: 100%;
    aspect-ratio: 1/1;
    cursor: pointer;
    display: flex;
    color: rgb(var(--v-theme-dunkelgrau));
    justify-content: center;
}

@media only screen and (max-width: 900px) {
    .dropIconNormal {
        max-width: 20vmax;
        max-height: 20vmax;
    }
}

.dropIconList {
    min-width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
    display: flex;
    color: rgb(var(--v-theme-dunkelgrau));
    justify-content: center;
}
</style>
