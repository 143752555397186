<template>
    <v-menu
        :offset="[0,10]"
        location="left"
        eager
    >
        <template v-slot:activator="{ props }">
            <v-btn
               
                :style="`width: ${buttonSize}px; height: ${buttonSize}px`"
                class="fileListButton mx-1 my-1"
                variant="text"
                rounded
                size="x-small"
                v-bind="props"
            >
                <img
                    :src="optionsIcon"
                    :style="iconWhite ? 'filter: brightness(0) invert(1);' : ''"
                    alt="Weitere Optionen"
                    class="fileListButtonIcon"
                >
            </v-btn>
        </template>
        <v-list style="padding: 0 4px">
            <v-list-item
                v-if="playButton"
                class="px-1"
                @click.prevent="playFile"
            >
                <img
                    :src="playIcon"
                    style="height: 20px;"
                    alt=""
                    class="mr-2 filterIconFromWhiteToGrey"
                >
                <v-list-item-title>{{ video ? 'Video' : 'Audio' }} abspielen</v-list-item-title>
            </v-list-item>
            <v-list-item
                v-if="editButton"
                class="px-1"
                @click.prevent="openFile"
            >
                <img
                    :src="editIcon"
                    style="height: 20px;"
                    alt=""
                    class="mr-2 filterIconFromWhiteToGrey"
                >
                <v-list-item-title>{{ editorFile ? 'Bearbeiten' : 'Datei im Libre Editor bearbeiten' }}</v-list-item-title>
            </v-list-item>
            <v-list-item
                v-if="infoButtonUserNote && userNote && userNote.note"
                class="px-1"
                @click.prevent="clickInfoButton"
            >
                <img
                    :src="infoIcon"
                    style="height: 20px;"
                    alt=""
                    class="mr-2 filterIconFromWhiteToGrey"
                >
                <v-list-item-title>{{ "Notiz/Anweisung: " + userNote.note }}</v-list-item-title>
            </v-list-item>
            <v-list-item
                v-if="infoButtonFileFormat"
                class="px-1"
                @click.prevent="clickInfoButton"
            >
                <img
                    :src="infoIcon"
                    style="height: 20px;"
                    alt=""
                    class="mr-2 filterIconFromWhiteToGrey"
                >
                <v-list-item-title>{{ fileFormatText }}</v-list-item-title>
            </v-list-item>
            <v-list-item
                v-if="infoButtonNoAnswer"
                class="px-1"
                @click.prevent="clickInfoButton"
            >
                <img
                    :src="infoIcon"
                    style="height: 20px;"
                    alt=""
                    class="mr-2 filterIconFromWhiteToGrey"
                >
                <v-list-item-title>{{ noAnswerText }}</v-list-item-title>
            </v-list-item>
            <v-list-item
                v-if="eyeButton"
                class="px-1"
                @click.prevent="eyeClicked"
            >
                <img
                    :src="fasFaEye"
                    style="height: 20px;"
                    alt=""
                    class="mr-2 filterIconFromWhiteToGrey"
                >
                <v-list-item-title>Im Editor ansehen</v-list-item-title>
            </v-list-item>
            <v-list-item
                v-if="videoInVideoButton"
                class="px-1"
                @click.prevent="videoInVideoClicked"
            >
                <v-icon
                    color="#706f6f"
                    size="17"
                    class=" mr-2 fas fa-photo-video"
                />
                <v-list-item-title>Video in Video für Hörgeschädigte</v-list-item-title>
            </v-list-item>
            <div v-if="defaultOptions">
                <v-list-item
                    v-if="accountRole === 'pupil' && !file.pipReference && !file.draft"
                    class="px-1"
                    @click.prevent="uploadAnswer"
                >
                    <img
                        :src="kameraIcon"
                        style="height: 20px;"
                        alt=""
                        class="mr-2 filterIconFromWhiteToGrey"
                    >
                    <v-list-item-title>Antwort hochladen</v-list-item-title>
                </v-list-item>
                <v-list-item
                    v-if="file.pipReference"
                    class="px-1"
                    @click.prevent="downloadMainVideo"
                >
                    <img
                        :src="downloadIcon"
                        class="mr-2 filterIconFromWhiteToGrey"
                        style="height: 20px;"
                        alt=""
                    >
                    <v-list-item-title>Hauptvideo herunterladen</v-list-item-title>
                </v-list-item>
                <v-list-item
                    v-if="accountRole === 'pupil' && file.pipReference"
                    class="px-1"
                    @click.prevent="downloadDGSVideo"
                >
                    <img
                        :src="downloadIcon"
                        class="mr-2"
                        style="height: 20px;"
                        alt=""
                    >
                    <v-list-item-title>DGS-Video herunterladen</v-list-item-title>
                </v-list-item>
                <v-list-item
                    class="px-1"
                    @click.prevent="showFileInformation"
                >
                    <img
                        :src="infoIcon"
                        style="height: 20px;"
                        alt=""
                        class="mr-2 filterIconFromWhiteToGrey"
                    >
                    <v-list-item-title>Informationen</v-list-item-title>
                </v-list-item>
                <v-list-item
                    v-if="!file.draft && (accountRole === 'teacher' || (accountRole === 'pupil' && !file.pipReference))"
                    class="px-1"
                    @click.prevent="downloadFile"
                >
                    <img
                        :src="downloadIcon"
                        alt="Herunterladen"
                        style="height: 20px;"
                        class="mr-2"
                    >
                    <v-list-item-title>Herunterladen</v-list-item-title>
                </v-list-item>
                <v-list-item
                    v-if="accountRole === 'teacher' || file.draft"
                    class="px-1"
                    @click.prevent="deleteFile"
                >
                    <img
                        :src="trashIcon"
                        alt="Datei löschen"
                        style="height: 20px;"
                        class="mr-2"
                    >
                    <v-list-item-title>Löschen</v-list-item-title>
                </v-list-item>
            </div>
        </v-list>
    </v-menu>
</template>

<script>
import optionsIcon from "@/assets/Icons/FaceliftIcons/menu_mehr_weiteres_setup.svg";
import downloadIcon from "@/assets/Icons/FaceliftIcons/datei_herunterladen.svg";
import trashIcon from '@/assets/Icons/FaceliftIcons/loeschen_muelleimer.svg';
import kameraIcon from "@/assets/Icons/FaceliftIcons/kamera.svg";
import playIcon from "@/assets/Icons/rechts-filled-11.svg";
import editIcon from "@/assets/Icons/bearbeiten-komplimentär-weiß-88.svg";
import infoIcon from "@/assets/Icons/FaceliftIcons/info_hinweis.svg";
import fasFaEye from "@/assets/Icons/fas-fa-eye.svg";
import {
    allowedExtensionsForAudioPlayer,
    allowedExtensionsForEditor, allowedExtensionsForLibre,
    allowedExtensionsForVideoPlayer
} from "@/constants/allowedMimeTypes";

export default {
    name: "FileListOptionsMenuButton",
    props: {
        file: { type: Object, required: true },
        accountRole: { type: String, required: true },
        person: { type: Object, required: true },
        iconWhite: { type: Boolean, required: false, default: false },
        defaultOptions: { type: Boolean, required: false, default: true },
        playButton: { type: Boolean, required: false, default: false },
        editButton: { type: Boolean, required: false, default: false },
        infoButtonUserNote: { type: Boolean, required: false, default: false },
        infoButtonFileFormat: { type: Boolean, required: false, default: false },
        infoButtonNoAnswer: { type: Boolean, required: false, default: false },
        eyeButton: { type: Boolean, required: false, default: false },
        videoInVideoButton: { type: Boolean, required: false, default: false },
        buttonSize: { type: Number, required: false, default: 36 },
    },
    data: () => ({
        video: false,
        audio: false,

        editorFile: false,
        libreOfficeFile: false,

        userNote: '',
        fileFormatText: 'Dateiformat nicht unterstützt.',
        noAnswerText: 'Unbearbeitet abgegeben',

        optionsIcon,
        downloadIcon,
        trashIcon,
        kameraIcon,
        playIcon,
        editIcon,
        infoIcon,
        fasFaEye,
    }),
    mounted() {
        if(this.playButton) {
            if(allowedExtensionsForVideoPlayer.includes(this.file.title.split('.').pop().toLowerCase())) {
                this.video = true;
            } else if(allowedExtensionsForAudioPlayer.includes(this.file.title.split('.').pop().toLowerCase())) {
                this.audio = true;
            }
        }
        if(this.editButton) {
            if(allowedExtensionsForEditor.includes(this.file.title.split('.').pop().toLowerCase())) {
                this.editorFile = true;
            } else if(allowedExtensionsForLibre.includes(this.file.title.split('.').pop().toLowerCase())) {
                this.libreOfficeFile = true;
            }
        }
        if(this.infoButtonUserNote) {
            this.userNote = this.file.userNotes.find((el) => el.account === this.person.account);
        }
    },
    methods: {
        showFileInformation(){
            this.$emit('showInformation', this.file);
        },
        downloadFile() {
            this.$emit('downloadFile');
        },
        deleteFile() {
            this.$emit(this.file.draft ? 'deleteDraft' : 'deleteFile');
        },
        uploadAnswer() {
            this.$emit('uploadAnswer');
        },
        downloadMainVideo() {
            this.$emit('downloadMainVideo');
        },
        downloadDGSVideo() {
            this.$emit('downloadDGSVideo');
        },
        playFile(event) {
            event.stopPropagation();
            this.$emit(this.video ? 'playVideo' : 'playAudio');
        },
        openFile(event) {
            event.stopPropagation();
            this.$emit(this.editorFile ? 'editInEditor' : 'editInLibre');
        },
        async clickInfoButton(event) {
            event.stopPropagation();
            let message = '';
            switch (this.usage) {
                case 'fileFormat':
                    message = this.fileFormatText;
                    break;
                case 'userNote':
                    message = this.userNote.note;
                    break;
                case 'noAnswer':
                    message = this.noAnswerText;
                    break;
            }
            this.showSnackbar({
                message,
                color: "warning"
            });
        },
        eyeClicked(event) {
            event.stopPropagation();
            this.$emit('openInEditor');
        },
        videoInVideoClicked(event) {
            event.stopPropagation();
            this.$emit('videoInVideoClicked');
        }
    }
}
</script>

<style scoped>
 .filterIconFromWhiteToGrey {
     filter: invert(44%) sepia(12%) saturate(27%) hue-rotate(314deg) brightness(94%) contrast(84%);
 }

 .btnStyle {
     min-height: 36px;
     min-width: 36px;
     margin: 4px;
 }

 .actionIcon {
     height: 30px;
     filter: brightness(1000%);
 }

 @media only screen and (max-width: 900px) {
     .btnStyle {
         width: 18px;
         height: 18px;
         margin: 2px;
         min-height: 18px;
         min-width: 18px !important;
     }
     .actionIcon {
         height: 20px;
     }
 }
</style>
