<template>
    <!-- This just displays an avatar, for creator/changing avatar use AvatarCreator.vue -->
    <v-btn
        variant="text"
        size="x-small"
        :min-width="size"
        :min-height="size"
        class="avatarContainer pa-0 ma-0"
        :class="diaryFront ? 'avatarSize' : ''"
        :aria-label="alt"
        :style="isRunning ? `height: ${size}px; width: ${size}px;` + 'background-color: ' + currentProfileColor : `height: ${size}px; width: ${size}px;` + 'background-color: grey'"
        :tabindex="tabindexAvatar"
    >
        <img
            :src="profilePictureBlob"
            :class="diaryFront ? 'picSize' : !isRunning ? 'isFachRunningPicture' : ''"
            :style="`height: ${hasUploadedProfilePic ? size : size - 5}px; width: ${hasUploadedProfilePic ? size : size - 5}px; border-radius: 6px`"
            :alt="alt"
        >
        <img
            v-if="windowWidth > 900 && showBurger"
            class="colorIconBurger"
            style="-webkit-filter: drop-shadow(0px 0px 5px #222); filter: drop-shadow(0px 0px 5px #222);"
            :src="burgerMenuIcon"
        >
        <img
            v-if="windowWidth <= 900 && showBurger"
            class="cameraIcon"
            style="-webkit-filter: drop-shadow(0px 0px 5px #222); filter: drop-shadow(0px 0px 5px #222);"
            :style="windowWidth > 500 ? 'right: -10px' : 'right: 30px'"
            :src="videoIcon"
        >
    </v-btn>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import burgerMenuIcon from "@/assets/Icons/FaceliftIcons/menu_lang_weiss.svg";
import videoIcon from "@/assets/Icons/video.svg";
import avatar1 from "@/assets/Icons/Avatar1.svg";
import avatar2 from "@/assets/Icons/Avatar2.svg";
import avatar3 from "@/assets/Icons/Avatar3.svg";
import avatar4 from "@/assets/Icons/Avatar4.svg";
import avatar5 from "@/assets/Icons/Avatar5.svg";
import avatar6 from "@/assets/Icons/Avatar6.svg";
import avatar7 from "@/assets/Icons/Avatar7.svg";
import avatar8 from "@/assets/Icons/Avatar8.svg";
import avatar9 from "@/assets/Icons/Avatar9.svg";
import avatar10 from "@/assets/Icons/Avatar10.svg";
import avatar11 from "@/assets/Icons/Avatar11.svg";
import avatar12 from "@/assets/Icons/Avatar12.svg";
import avatar13 from "@/assets/Icons/Avatar13.svg";
import avatar14 from "@/assets/Icons/Avatar14.svg";

export default {
    name: "AvatarComponent",
    props: {
        alt: { required: false, type: String, default: "Profilbild" },
        showBurger: { required: false, type: Boolean, default: false },
        profile: { required: true, type: String },
        size: { required: false, type: Number, default: 50 },
        diaryFront: { required: false, type: Boolean, default: false },
        tabindexAvatar: { required: false, default: 0 },
        isRunning: { required: false, type: Boolean, default: true },
    },
    data: () => ({
        currentProfileColor: null,
        currentProfilePicture: {
            text: '',
            img: '',
        },
        availableProfileColors: [
            '#fcd20a', '#f0b423', '#ff6941', '#91c364', '#91c887', '#d7875f', '#ebaabe', '#9b91c8',
            '#6ec3dc', '#9bcdc3', '#ff6633', '#707070'
        ],
        availableProfilePictures: [
            'emoticon-happy-outline', 'baby-face-outline', 'emoticon-excited-outline', 'emoticon-cool-outline',
            'robot-excited-outline', 'emoticon-wink-outline', 'emoticon-tongue-outline', 'face-man-outline',
            'face-woman-outline', 'star-face', 'emoticon-lol-outline', 'halloween',
        ],
        defaultPictures: {
            Avatar1: avatar1,
            Avatar2: avatar2,
            Avatar3: avatar3,
            Avatar4: avatar4,
            Avatar5: avatar5,
            Avatar6: avatar6,
            Avatar7: avatar7,
            Avatar8: avatar8,
            Avatar9: avatar9,
            Avatar10: avatar10,
            Avatar11: avatar11,
            Avatar12: avatar12,
            Avatar13: avatar13,
            Avatar14: avatar14,
        },
        hasUploadedProfilePic: false,
        profilePictureBlob: null,
        burgerMenuIcon,
        videoIcon,
    }),
    computed: {
        ...mapGetters('auth', ['accountRole']),
        ...mapGetters('accounts', ['accountsById']),
        ...mapGetters('teachers', ['teachersByAccountId', 'profilePicturesById']),
        ...mapGetters('maintainers', ['maintainers']),
        ...mapState('avatar', ['avatarChanged']),
        ...mapState('util', ['windowWidth']),

        account() {
            return this.accountsById[this.profile];
        }
    },
    watch: {
        account(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.determineProfilePicture();
            }
        }
    },
    async mounted() {
        await this.determineProfilePicture();
    },
    methods: {
        ...mapActions('auth', ['getAccount']),
        ...mapActions('avatar', ['setAvatarChanged']),
        ...mapActions('maintainers', ['getMaintainers', 'getMaintainerProfilePicture']),
        ...mapActions('teachers', ['getTeachers', 'getProfilePicture']),

        async determineProfilePicture() {
            // console.log(`determineProfilePicture for account ${this.account}`);
            if (this.account) {
                let pictureValue = null;
                // teacher or maintainer - check for separate pciture
                if (this.account.role === 'teacher') {
                    const teacher = this.teachersByAccountId[this.account._id];
                    if (teacher && teacher.profilePicture) {
                        pictureValue = this.profilePicturesById[teacher._id];
                    }
                }
                else if (this.account.role === 'maintainer') {
                    const maintainer = this.maintainers[0]; // Todo update later
                    if (maintainer && maintainer.profilePicture) {
                        pictureValue = await this.getMaintainerProfilePicture(maintainer._id);
                    }
                }
                // handle avatar
                if (pictureValue) {
                    this.hasUploadedProfilePic = true;
                } else {
                    let avatarKey = 'Avatar3';
                    if (this.account.profilePicture
                        && this.account.profilePicture.img
                        && this.account.profilePicture.img.includes('Avatar')) {
                        avatarKey = this.account.profilePicture.img.replace('/', '').replace('.svg', '');
                    }
                    pictureValue = this.defaultPictures[`${avatarKey}`];
                }
                this.profilePictureBlob = pictureValue;

                // set profile color
                if (this.account.profileColor) {
                    this.currentProfileColor = this.account.profileColor;
                } else {
                    this.currentProfileColor = this.availableProfileColors[10]; // simple grey
                }
                return;
            }
            console.warn('Avatar.vue - no account given for profile picture')
        },
    },
}
</script>

<style lang="scss" scoped>
.avatarContainer {
    // width: 50px;
    // min-width: 50px;
    // min-height: 50px;
    // max-width: 50px;
    // max-height: 50px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cameraIcon{
    position: absolute;
    bottom: -10px;
    width: 20px !important;
    height: 20px !important;
    max-width: 20px !important;
    max-height: 20px !important;
}

.colorIconBurger{
    color: white !important;
    position: absolute;
    right: -8px;
    bottom: -6px;
    width: 17px !important;
    height: 17px !important;
    max-width: 20px !important;
    max-height: 17px !important;

}

@media only screen and (orientation: landscape) {
    .avatarSize {
        min-height: 6vh !important;
        min-width: 6vh !important;
        height: 6vh !important;
        width: 6vh !important;
    }
    .picSize {
        height: 5vh !important;
        width: 5vh !important;
    }
}

@media only screen and (orientation: portrait) {
    .avatarSize {
        min-height: 6vw !important;
        min-width: 6vw !important;
        height: 6vw !important;
        width: 6vw !important;
    }
    .picSize {
        height: 5vw !important;
        width: 5vw !important;
    }
    @media only screen and (max-width: 1085px) {
        .avatarSize {
            min-height: 8vw !important;
            min-width: 8vw !important;
            height: 8vw !important;
            width: 8vw !important;
        }
        .picSize {
            height: 8vw !important;
            width: 8vw !important;
        }
    }
}

.isFachRunningPicture {
    filter: grayscale(100%);
}

</style>
