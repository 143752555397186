<template>
    <div>
        <div v-if="mode==='create'">
            <div class="d-flex align-center">
                <v-text-field
                    v-model="title"
                    label="Titel"
                    placeholder="Titel eingeben"
                    @blur="notifyParentTitle"
                />
                <v-btn
                    icon="$vuetify"
                    variant="plain"

                    @click="close"
                >
                    <img
                        :src="schliessenIcon"
                        class="icon20"
                    >
                </v-btn>
            </div>
            <div
                v-for="(input, index) in inputs"
                :key="index"
                class="d-flex flex-row justify-space-between align-center px-4 py-0"
            >
                <v-checkbox-btn disabled />
                <v-text-field
                    v-model="inputs[index]" 
                    class="pt-2"
                    rounded
                    density="compact"
                    :placeholder="`Option ${index + 1}`"
                    @blur="notifyParentInput"
                    @keyup.enter="addInput(false)"
                />
                <v-btn
                    icon="$vuetify"
                    variant="plain"

                    @click="() => { removeInput(index,false) }"
                >
                    <img
                        class=" icon20"
                        :src="papierkorb"
                        alt="schließen"
                    >
                </v-btn>
            </div>
            <div>
                <v-tooltip location="right">
                    <template v-slot:activator="{ props }">
                        <v-btn
                            style="width: 100%;"
                            color="grey"
                           
                            v-bind="props"
                            @click="() => { addInput(false) }"
                        >
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                    <span>
                        Kontrollpunkt hinzufügen
                    </span>
                </v-tooltip>
            </div>
        </div>
        <div v-else-if="mode==='view'">
            <div class="d-flex justfy-flex-start align-center my-1">
                <!-- <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            v-bind="attrs"
                            class="mb-1"
                            v-on="on"
                        >
                            mdi-list-status
                        </v-icon>
                    </template>
                    <span>Kontroll-Liste</span>
                </v-tooltip> -->
                <h2
                    class="ml-2"
                    style="font-weight: bold;"
                >
                    {{ toDoListObject.title }}
                </h2>
            </div>
            <div
                v-for="(task, index) in toDoListObject.tasks"
                :key="index"
                v-ripple
                class="d-flex flex-row justify-flex-start align-center px-4  py-0"
                style="cursor: pointer"
                @click="toggleToDoTask(task)"
            >
                <v-checkbox-btn
                    :value="checkAccountInFinished(task.finished)"
                    style="border-radius: 50%"
                    @input="toggleToDoTask(task)"
                />
                <p :class="checkAccountInFinished(task.finished) ? 'finishedTask' : ''">
                    {{ task.text }}
                </p>
            </div>
        </div>
        <div v-else-if="mode==='edit'">
            <v-text-field
                v-model="editedTitle"
                class="pt-2, px-2"
                label="Titel"
                placeholder="Titel eingeben"
                @blur="titleEdited"
            />
            <div
                v-for="(input, index) in editedInputs"
                :key="index"
                class="d-flex flex-row justify-space-between align-center px-4 py-0"
            >
                <v-checkbox-btn disabled />
                <v-text-field
                    v-model="editedInputs[index].text" 
                    class="pt-2"
                    rounded
                    density="compact"
                    @blur="inputEdited"
                    @keyup.enter="addInput(true)"
                />
                <v-btn
                    icon="$vuetify"
                    variant="plain"

                    @click="() => { removeInput(index,true) }"
                >
                    <img
                        class=" icon20"
                        :src="papierkorb"
                        alt="schließen"
                    >
                </v-btn>
            </div>
            <div>
                <v-tooltip location="top">
                    <template v-slot:activator="{ props }">
                        <v-btn
                            style="width: 100%;"
                            color="grey"
                           
                            v-bind="props"
                            @click="() => { addInput(true) }"
                        >
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                    <span>
                        Kontrollpunkt hinzufügen
                    </span>
                </v-tooltip>
            </div>
        </div>
        <v-divider
            v-if="mode !== 'view'"
            class="mt-2"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as backend from "../api/backend";
import schliessenIcon from "../assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg";
import papierkorb from '../assets/Icons/FaceliftIcons/loeschen_muelleimer.svg';

export default {
    name: "ToDoList",
    props: {
        mode: { required: true, type: String, default: 'view' },
        toDoListObject: {
            required: false, type: Object, 
        },
        editObject: {
            required: false, type: Object
        }, 
        loggedIn: { 
            required: false 
            },
    },
    data() {
        return {
            inputs: [""],
 
            title:'',
            editedTitle: null,
            editedInputs: [""],
            //icons
            schliessenIcon,
            papierkorb,
        }
    },
    computed: {
        ...mapGetters("accounts", {'vuexMe': 'me'}),
    },

    watch: {
    },
    mounted(){
        if(this.editObject){
            const editInputs = this.editObject.tasks.map((task) => {
                    return task
                })
            
            this.editedInputs = editInputs;
            this.editedTitle = this.editObject.title;
        }
    },
    methods: {
        addInput(editMode){
            if(editMode){
                this.editedInputs.push({text:''})
            }else{
                this.inputs.push('');
            }
        },
        async removeInput(index,editMode){
            if(editMode){
                const spliced = this.editedInputs.splice(index,1);
                // check if removed task existed in original, delete if true
                const tasksToBeDeleted = [];
                this.editObject.tasks.forEach((task)=> {
                    if(task.text === spliced[0].text){
                        tasksToBeDeleted.push(task._id);
                    }
                    });
                this.$emit('notifyParentDeleteTasks',tasksToBeDeleted);
            }else{
                this.inputs.splice(index,1);
            }
        },
        notifyParentInput(){
            this.$emit('updateInputs', this.inputs);
        },
        notifyParentTitle(){
            this.$emit('updateTitle', this.title);
        },
        inputEdited(){
            this.$emit('inputEdited', this.editedInputs);
        },
        titleEdited(){
            this.$emit('titleEdited', this.editedTitle);
        },
        async toggleToDoTask(task){
            let toDoTask = task;
            let finished = task.finished;
            if(finished.includes(this.loggedIn)){
                const splice = finished.splice(finished.indexOf(this.loggedIn),1)
            }else {
                finished.push(this.loggedIn)
            }
            // needs to be done in store so blackboard updates correctly
            const updated = await backend.updateToDoTask(toDoTask._id,{
                finished: finished,
            });
        },
        checkAccountInFinished(finished){
            if(finished.includes(this.loggedIn)){
                return true;
            } else{
                return false;
            }
        },
        close() {
            this.$emit('cancelToDo');
        }
    }
}
</script>
<style lang="scss" scoped>
.finishedTask{
    text-decoration: line-through;
    opacity: 0.4;
}
</style>