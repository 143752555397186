<template>
    <div>
        <div
            class="expandableRowElementContainer mb-1"
            :style="selectedFolder === item ? 'background-color: rgb(var(--v-theme-chatColor))' : ''"
            @click="folderElementClicked"
        >
            <div
                class="d-flex align-center ml-2"
            >   
                <img
                    v-if="item.learningPath"
                    :src="lernpfadIcon"
                    class="icon20"
                >
                <v-icon
                    v-else
                    class="pr-4"
                    size="small"
                    style="font-size: x-large;"
                >
                    mdi-folder
                </v-icon>
                <img
                    v-if="item.folders.length > 0"
                    style="width: 25px"
                    :src="dropDown"
                    :style="`transform: rotate(${isExpanded ? '180deg' : '0deg'})`"
                    :alt="isExpanded ? 'einklappen' : 'ausklappen'"
                    @click="() => { isExpanded = !isExpanded }"
                >
            </div>

            <p
                class="pl-1"
                style="font-size: large; width: 60%;"
            >
                {{ item.name }}
            </p>
        </div>
        <!-- Div to expand with children -->
        <div
            v-if="isExpanded"
            style="width: 90%; margin-left: 10%"
        >
            <FileSystemExpandableRowElement
                v-for="item in children"
                :key="`saveToFolderEntry${item._id}`"
                :item="item"
                :selected-folder="selectedFolder"
                @folder-selected="(child) => { $emit('folderSelected', child); }"
            />
        </div>
    </div>
</template>

<script>
import {mapActions} from "vuex";
import dropDown from '@/assets/Icons/FaceliftIcons/dropdown_mehr_unten_pfeil.svg'
import lernpfadIcon from '@/assets/Icons/FaceliftIcons/Lernpfad.svg';

export default {
    name: 'FileSystemExpandableRowElement',
    props: {
        item: { required: true, type: Object },
        selectedFolder: { required: false, type: Object, default: () => { } },
    },
    data: () => ({
       dropDown,
       lernpfadIcon,
       children: [],
       isExpanded: false,
    }),
    async mounted() {
        if (this.item.folders) {
            for (let i = 0; i < this.item.folders.length; i++) {
                const childFolder = await this.getVFSFolderUploadsV2({ folderId: this.item.folders[i]._id || this.item.folders[i] });
                this.children.push(childFolder);
            }
        }
    },
    methods: {
        ...mapActions("virtualFileSystem", ["getVFSFolderUploadsV2"]),
        folderElementClicked() {
            this.$emit('folderSelected', this.item);
        }
    }
}
</script>

<style scoped lang="scss">
.expandableRowElementContainer {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    border-radius: 5px;
    height: 40px;
    padding: 4px;
    border: 1px solid rgb(var(--v-theme-dunkelgrau));
    cursor: pointer;
}
</style>
