<template>
    <div
        style="margin-left: 12px; margin-right: 12px"
    >
        <!--        @mouseenter="()=> { showMenu = true; showMenuReentered = true;}"-->
        <!--        @mouseleave="closeMenu()"-->
        <v-row>
            <!-- Grid/Matrix of Letters -->
            <div class="greyDivBorderColor">
                <div
                    ref="gridHTML"
                    class="greyDiv"
                    style="margin: 8px; padding-top: 1px; padding-bottom: 1px;"
                >
                    <div
                        class="greyDiv"
                        style="margin: 20px"
                    >
                        <div
                            v-for="(n, yVal) in gridSize"
                            :key="yVal"
                            style="display: flex; flex-direction: row"
                        >
                            <div
                                v-for="(m, xVal) in gridSize"
                                :key="xVal"
                                style="flex-direction: column"
                            >
                                <v-btn
                                    block
                                    tile
                                    :width="gridWidth"
                                    :height="gridHeight"
                                    :class="getGridSelected(xVal, yVal)"
                                    :min-width="gridWidth+'px !important'"
                                    :min-height="gridHeight+'px'"
                                    :max-width="gridWidth+'px'"
                                    :max-height="gridHeight+'px'"
                                    :color="getGridColor(xVal, yVal)"
                                    @click="gridClicked(xVal,yVal)"
                                >
                                    <span
                                        :style="`font-size: ${sizeState + (smallUI ? 1 : 2)}em`"
                                    >
                                        {{ getGridLetter(xVal, yVal) }}
                                    </span>
                                </v-btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- SideBar -->
            <div
                v-if="showTeacherUI"
                class="editorOptionsFade"
                :style="'position: absolute; left: '+(gridSize*gridWidth+52)+'px; top: 48px'"
            >
                <v-col>
                    <div>
                        <!-- Generate Grid -->
                        <v-tooltip
                            location="top"
                            :disabled="answers.length < 1"
                        >
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    :disabled="answers.length < 1"
                                    style="opacity: 0.9; width: 48px; height: 48px; min-width: 48px"
                                    class="ml-2 editorColorPrimaryBackgroundOnHover"
                                    v-bind="props"
                                    @click="generateGrid"
                                >
                                    <v-icon
                                        style="opacity: 0.75"
                                        class="editorColorPrimaryOnHoverChildColorToWhite"
                                        size="large"
                                    >
                                        mdi-dice-5-outline
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Buchstabensalat generieren</span>
                        </v-tooltip>
                        <!-- <span style="margin-top: 99px !important; margin-left: 10px">Würfeln</span> -->
                    </div>
                    <div>
                        <v-tooltip location="top">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    class="ml-2 editorColorPrimaryBackgroundOnHover"
                                    style="margin-top: 10px; opacity: 0.9; width: 48px; height: 48px; min-width: 48px"
                                    v-bind="props"
                                    @click="changeSize()"
                                >
                                    <!--                                    <v-icon style="opacity: 0.75" large class="editorColorPrimaryOnHoverChildColorToWhite">mdi-format-size</v-icon>-->
                                    <img
                                        style="height: 32px"
                                        class="iconToWhiteHover"
                                        :src="currentSizeIcon"
                                        alt="Größe des Elements verändern"
                                    >
                                </v-btn>
                            </template>
                            <span>Größe verändern</span>
                        </v-tooltip>
                    </div>
                    <div>
                        <v-tooltip location="top">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    class="ml-2 editorColorPrimaryBackgroundOnHover"
                                    style="margin-top: 10px; opacity: 0.9; width: 48px; height: 48px; min-width: 48px"
                                    v-bind="props"
                                    @click="mutateDirections()"
                                >
                                    <v-icon
                                        style="opacity: 0.75"
                                        size="large"
                                        class="editorColorPrimaryOnHoverChildColorToWhite"
                                    >
                                        {{ horizontal ? vertical ? 'mdi-format-text-rotation-down-vertical' : 'mdi-format-text-rotation-none' : vertical ? 'mdi-format-text-rotation-vertical' : '' }}
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>{{ horizontal ? vertical ? 'Wörter horizontal und vertikal einfügen' : 'Wörter horizontal einfügen' : vertical ? 'Wörter vertikal einfügen' : '' }}</span>
                        </v-tooltip>
                    </div>
                    <div>
                        <v-tooltip location="top">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    class="ml-2 editorColorPrimaryBackgroundOnHover"
                                    style="margin-top: 10px; opacity: 0.9; width: 48px; height: 48px; min-width: 48px"
                                    v-bind="props"
                                    @click="() => {cfgHighlightFirst = !cfgHighlightFirst}"
                                >
                                    <v-icon
                                        style="opacity: 0.75"
                                        size="large"
                                        class="editorColorPrimaryOnHoverChildColorToWhite"
                                    >
                                        {{ cfgHighlightFirst ? 'mdi-marker' : 'mdi-marker-cancel' }}
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>{{ cfgHighlightFirst ? 'Erster Buchstabe wird markiert':'Erster Buchstabe wird nicht markiert' }}</span>
                        </v-tooltip>
                    </div>
                    <div>
                        <v-tooltip location="top">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    class="ml-2 editorColorPrimaryBackgroundOnHover"
                                    style="margin-top: 10px; opacity: 0.9; width: 48px; height: 48px; min-width: 48px"
                                    v-bind="props"
                                    @click="() => {cfgAllowEmpty = !cfgAllowEmpty}"
                                >
                                    <v-icon
                                        style="opacity: 0.75"
                                        size="large"
                                        class="editorColorPrimaryOnHoverChildColorToWhite"
                                    >
                                        {{ cfgAllowEmpty ? 'mdi-border-none-variant' : 'mdi-border-all-variant' }}
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>{{ cfgAllowEmpty ? 'Erlaubt leere Felder':'Erlaubt keine leeren Felder' }}</span>
                        </v-tooltip>
                    </div>
                    <!-- <div v-if="accountRole === 'teacher' && !done && state !== 'viewer'">
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" class="ml-2" style="margin-top: 10px; opacity: 0.75" width="50" height="50" fab min-width="20" @click="()=>{cfgShowAnswers=!cfgShowAnswers}">
                                <v-icon large style="opacity: 0.75" height="50" width="50" >{{cfgShowAnswers ? 'mdi-eye' : 'mdi-eye-off'}}</v-icon>
                                </v-btn>
                            </template>
                            <span>{{cfgShowAnswers ? 'Die Antwort-Liste ist für SuS sichtbar' : 'Die Antwort-Liste ist für SuS nicht sichtbar'}}</span>
                        </v-tooltip>
                    </div> -->
                    <!-- deprecated:Diff-Settings -->
                    <!-- <div v-if="showMenu && accountRole === 'teacher' && !done && state !== 'viewer'" :hidden="true">
                        <v-card elevation="2" style="margin-top: 10px" width="370px" height="170px" shaped outlined>
                            <v-card-title style="margin-bottom: -20px; margin-top: -12px; display:flex; flex-direction: row">
                                <h2 style="font-size: 17px">Schwierigkeitsgrad</h2>
                                <v-spacer/>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{on}">
                                    <div>
                                        <img v-on="on" :src="infoIcon" style="height: 18px"/>
                                    </div>
                                    </template>
                                    <span>Hilfen für den Schüler:<br/>
                                    Leere Felder zulassen: Wenn die nicht belegten Felder mit zufälligen Buchstaben gefüllt werden, sind Lücken erlaubt.<br/>
                                    Ersten Buchstaben hervorheben: Der erste Buchstabe in jedem Lösungswort wird mit Grau für die Schüler markiert.<br/>
                                    Antworten auflisten: Die Schüler können sehen, welche Wörter gesucht sind, finden müssen sie diese aber nach wie vor im Buchstabensalat.<br/></span>
                                </v-tooltip>
                            </v-card-title>
                            <v-sheet style="display: flex; flex-direction: column">
                                <v-switch v-model="cfgAllowEmpty" dense inset :label="`Leere Felder zulassen: ${cfgAllowEmpty ? 'An' : 'Aus'}`" style="margin-left: 10px; margin-bottom: -20px"/>
                                <v-switch v-model="cfgHighlightFirst" dense inset :label="`Ersten Buchstaben hervorheben: ${cfgHighlightFirst ? 'An' : 'Aus'}`" style="margin-left: 10px; margin-bottom: -20px"/>
                                <v-switch v-model="cfgShowAnswers" dense inset :label="`Antworten auflisten: ${cfgShowAnswers ? 'An' : 'Aus'}`" style="margin-left: 10px; margin-bottom: -20px"/>
                            </v-sheet>
                        </v-card>
                    </div> -->
                </v-col>
            </div>
        </v-row>
        <!-- InputArea, Toggle Visibility, deprecated:Add/Remove Answers -->
        <div
            v-if="accountRole === 'teacher' && !previewPupilUI
                || (accountRole === 'pupil' && (done || cfgShowAnswers))
                || (cfgShowAnswers && exportMode && !previewPupilUI)"
            class="editorOptionsFade"
        >
            <v-row :style="'max-width: '+(gridSize*gridWidth+64)+'px; min-width: '+(gridSize*gridWidth+64)+'px'">
                <!-- <v-chip-group column>
                    <v-chip v-for="answerChip in answers" :key="answerChip" close>
                        {{answerChip}}
                    </v-chip>
                </v-chip-group> -->
                <v-combobox
                    v-model="answers"
                    v-model.search-input="answersArea"
                    multiple
                    :readonly="!showTeacherUI"
                    :label="(!showTeacherUI) ? 'Lösungen' : 'Worte durch Komma trennen'"
                    variant="outlined"
                    style="margin-top: 10px; margin-bottom: -28px"
                    chips
                    :closable-chips="showTeacherUI"
                    :clearable="showTeacherUI"
                />
                <div v-if="showTeacherUI">
                    <v-tooltip location="top">
                        <template v-slot:activator="{ props }">
                            <v-btn
                                class="ml-2 greyDiv editorColorPrimaryBackgroundOnHover"
                                style="margin-top: 15px; opacity: 0.9"
                                width="50"
                                height="50"
                                min-width="20"
                                :style="'position: absolute; left: '+(gridSize*gridWidth+64)+'px; top: '+(gridSize*gridWidth+66)+'px'"
                                v-bind="props"
                                @click="()=>{cfgShowAnswers=!cfgShowAnswers}"
                            >
                                <v-icon
                                    size="large"
                                    style="opacity: 0.75"
                                    height="50"
                                    width="50"
                                    class="editorColorPrimaryOnHoverChildColorToWhite"
                                >
                                    {{ cfgShowAnswers ? 'mdi-eye' : 'mdi-eye-off' }}
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>{{ cfgShowAnswers ? 'Die Antwort-Liste ist für SuS sichtbar' : 'Die Antwort-Liste ist für SuS nicht sichtbar' }}</span>
                    </v-tooltip>
                </div>
                <!-- <div>
                    <v-tooltip top :disabled="answers.length < 2">
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" :disabled="answers.length < 1" style="" class="mt-3 ml-2" width="30" height="30" fab min-width="20" @click="removeAnswerClicked">
                                {{'-'}}
                            </v-btn>
                        </template>
                        <span>Wort entfernen</span>
                    </v-tooltip>
                </div>
                <div>
                    <v-tooltip top :disabled="answers.length > 8">
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" :disabled="answers.length > 8" style="" class="mt-3 ml-2" width="30" height="30" fab min-width="20" @click="addAnswerClicked">
                                {{'+'}}
                            </v-btn>
                        </template>
                        <span>Wort hinzufügen</span>
                    </v-tooltip>
                </div> -->
            </v-row>
        </div>
        <!-- deprecated:Answer Fields -->
        <div
            v-if="((accountRole === 'teacher' && cfgShowAnswers) || (accountRole === 'pupil' && (cfgShowAnswers || state === 'viewer')))"
            :hidden="true"
            class="editorOptionsFade"
        >
            <div
                v-for="(answer, index) in answers"
                :key="index"
                style="display: flex; flex-direction: row"
            >
                <v-text-field
                    v-model="answers[index]"
                    variant="solo"
                    :readonly="accountRole === 'pupil' || state === 'viewer' || done"
                    :maxlength="maxGridSize"
                    density="compact"
                    label="Wort"
                    style="max-width: 400px;margin-top: 5px; margin-bottom: -25px; border-top-style: inset; border-width: 5px"
                    class="shrink"
                />
                <div v-if="accountRole === 'teacher' && !done">
                    <v-tooltip location="bottom">
                        <template v-slot:activator="{ props }">
                            <v-fab
                                size="small"
                                fab
                                tabindex="-1"
                                class="mx-2"
                                :color="colorArr[index]"
                                style="margin-left: 20px; margin-top: 5px; margin-bottom: -25px;"
                                v-bind="props"
                            >
                                <v-icon
                                    theme="dark"
                                    style="opacity: 0.5"
                                >
                                    mdi-eyedropper
                                </v-icon>
                            </v-fab>
                        </template>
                        <span>Die Antwort wird in dieser Farbe markiert</span>
                    </v-tooltip>
                </div>
                <div v-if="state === 'viewer' || (accountRole === 'teacher' && done)">
                    <v-tooltip location="bottom">
                        <template v-slot:activator="{ props }">
                            <v-fab
                                size="small"
                                tabindex="-1"
                                class="mx-2"
                                :color="colorArr[index]"
                                style="margin-left: 20px; margin-top: 5px; margin-bottom: -25px;"
                                v-bind="props"
                            >
                                <v-icon
                                    theme="dark"
                                    style="opacity: 0.5"
                                >
                                    {{ answersFound[index] ? 'mdi-check' : 'mdi-close' }}
                                </v-icon>
                            </v-fab>
                        </template>
                        <span> {{ answersFound[index] ? 'Das Wort wurde gefunden' : 'Das Wort wurde nicht gefunden' }} </span>
                    </v-tooltip>
                </div>
                <v-spacer />
            </div>
        </div>
    </div>
</template>

<script>
// uses a matrix of letter-elements to display a grid.
// answers are inserted and hidden between misleading letters.
// users can click their choice along a line to build answers.

import {mapActions, mapState, mapGetters} from "vuex";
import sizeSmallIcon from '@/assets/Icons/EditorRedesign/schrift_klein.svg';
import sizeMediumIcon from '@/assets/Icons/EditorRedesign/schrift_mittel.svg';
import sizeLargeIcon from '@/assets/Icons/EditorRedesign/schrift_groß.svg';

export default {
    name: 'EditorBuchstabensuppe',
    props: {
        config: { type: Object, required: true},
        state: { type: String, required: true},
        tabIndex: { type: Number, required: false, default: 0 },
        canvasWidth: {type: Number, required: true},
        canvasHeight: {type: Number, required: true},
        exportMode: { type: Boolean, required: false, default: false },
        previewPupilUI: { type: Boolean, required: false, default: false },
    },
    data() {
        return {
            // UI
            sizeSmallIcon,
            sizeMediumIcon,
            sizeLargeIcon,

            //Difficulty Settings
            cfgAllowEmpty: false,
            cfgHighlightFirst: true,
            cfgShowAnswers: true,

            gridWidth: 65,
            gridHeight: 65,
            sizeState: 1,

            // solutions possible directions. could extend by diagonal and reversal (r->l, d->u)
            horizontal: true,
            vertical: true,

            // toggle entire UI outside of minimum component
            // showMenu: true,
            // showMenuReentered : false,
            done: false,

            // Data
            answers: [],
            gridValues: [],
            gridSize: 8,

            answersFound: [],

            solutions: [[],[]],

            answersArea: '',

            screenX: null,
            screenY: null,
            smallUI: false,

            //limitations
            maxGridSize: 12,
            minGridSize: 8,
            maxWordCount: 12,

            //schemata
            colorArr: ['#518ecb','#a9c936','#f49924','#94519b',
                        '#e6272e','#fdd44a','#8ed3ef','#f4b4d0', //first 2 rows are from eklara logo
                        '#73C6B6','#E67E22','#40E0D0','#6495ED','#CCCCFF'], //these are random colors
            letters: ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'],
            grid: [{
                char: '',
                x: 0,
                y: 0,
                selectedByPupil: false,
                answ: [{
                    answIndex: null,
                    answCharIndex: null,
                }],
            }],
        };
    },
    computed: {
        ...mapGetters("auth", ["accountRole", "accountId"]),
        currentSizeIcon() {
            return this.sizeState ? (this.sizeState === 2 ? this.sizeLargeIcon : this.sizeMediumIcon) : this.sizeSmallIcon;
        },

        showTeacherUI() {
            return !this.exportMode && !this.previewPupilUI && this.state === 'creator';
        },
    },
    watch: {
        answers: {
            handler(val, oldVal) {
                this.saveState();
            },
            deep: true,
        },
        answersArea: {
            handler(val, oldVal) {
                if(this.answersArea) {
                    if(this.answersArea[this.answersArea.length-1]===',') {
                        this.spliceAnswers();
                    }
                }
            }
        },
        cfgHighlightFirst: {
            handler(val, oldVal) {
                this.saveState();
            }
        },
        cfgShowAnswers: {
            handler(val, oldVal) {
                this.saveState();
            }
        },
        sizeState: {
            handler(val, oldVal) {
                this.saveState();
            }
        },
    },
    mounted() {

        // init component
        this.evalScreenSize();
        // cover case of new creation
        if (!this.config.buchstabensuppe) {
            this.config.buchstabensuppe = {};
        }
        if (!this.config.buchstabensuppe.answers) {
            this.config.buchstabensuppe.answers = [];
        }
        this.answers = this.config.buchstabensuppe.answers;
        if (typeof this.config.buchstabensuppe.answersArea === "undefined" || !this.config.buchstabensuppe.answersArea) {
            this.config.buchstabensuppe.answersArea = '';
        }
        this.answersArea = this.config.buchstabensuppe.answersArea;
        if (this.config.buchstabensuppe.sizeState === undefined) {
            this.config.buchstabensuppe.sizeState = 1;
        }
        this.sizeState = this.config.buchstabensuppe.sizeState;
        switch (this.sizeState) {
            case 0:
                if(this.smallUI) {
                    this.gridWidth = this.gridHeight = 32;
                } else {
                    this.gridWidth = this.gridHeight = 50;
                }
                break;
            case 1:
                if(this.smallUI) {
                    this.gridWidth = this.gridHeight = 36;
                } else {
                    this.gridWidth = this.gridHeight = 65;
                }
                break;
            case 2:
                if(this.smallUI) {
                    this.gridWidth = this.gridHeight = 40;
                } else {
                    this.gridWidth = this.gridHeight = 80;
                }
                break;
            default:
                break;
        }
        if (!this.config.buchstabensuppe.gridValues) {
            this.config.buchstabensuppe.gridValues = [];
        }
        this.gridValues = this.config.buchstabensuppe.gridValues;

        const sqrt = Math.sqrt(this.gridValues.length);

        if(sqrt < this.minGridSize || sqrt > this.maxGridSize) {
            this.gridSize = this.minGridSize;
        } else {
            this.gridSize = sqrt;
        }

        if (!this.config.buchstabensuppe.difficulty) {
            this.config.buchstabensuppe.difficulty = {};
            this.config.identifier = this._uid;
            this.config.buchstabensuppe.difficulty.cfgAllowEmpty = this.cfgAllowEmpty;
            this.config.buchstabensuppe.difficulty.cfgHighlightFirst = this.cfgHighlightFirst;
            this.config.buchstabensuppe.difficulty.cfgShowAnswers = this.cfgShowAnswers;
        } else {
            if(this.config._id) {
                this.config.identifier = this.config._id;
            }
            this.cfgAllowEmpty = this.config.buchstabensuppe.difficulty.cfgAllowEmpty;
            this.cfgHighlightFirst = this.config.buchstabensuppe.difficulty.cfgHighlightFirst;
            this.cfgShowAnswers = this.config.buchstabensuppe.difficulty.cfgShowAnswers;
        }

        if(this.gridValues.length <= 0) {
            this.fillGrid();    // new creation, generate a placeholder grid
            // this.answers.push('');
            this.done = false;
        } else {
            this.initGrid();    // load grid from db
        }
    },
    methods: {
        // mutates size of grid and some fonts
        changeSize() {
            switch (this.sizeState) {
                case 0:
                    this.sizeState = 1;
                    if(this.smallUI) {
                        this.gridWidth = this.gridHeight = 36;
                    } else {
                        this.gridWidth = this.gridHeight = 65;
                    }
                    break;
                case 1:
                    this.sizeState = 2;
                    if(this.smallUI) {
                        this.gridWidth = this.gridHeight = 40;
                    } else {
                        this.gridWidth = this.gridHeight = 80;
                    }
                    break;
                case 2:
                    this.sizeState = 0;
                    if(this.smallUI) {
                        this.gridWidth = this.gridHeight = 32;
                    } else {
                        this.gridWidth = this.gridHeight = 50;
                    }
                    break;
                default:
                    break;
            }
        },

        spliceAnswers() {

            let newAnswers = this.answersArea.split(/\s*,+\s*/);
            this.answers.push(newAnswers[newAnswers.length-2].trim());
            if(this.answers.length > this.maxWordCount) {
                this.answers.splice(this.maxWordCount,this.answers.length-1);
            }
            // ensure no empty answer is left
            if(this.answers[0] == '' && this.answers.length == 1) {
                this.removeAnswerClicked();
            }
            this.answersArea = "";
            this.generateGrid();
        },

        // inserts answers, handles filling out with random letters and difficulty adjustments
        generateGrid() {
            // Nice-to-have: catch bad user inputs: regex filter whitespace, illegal characters etc
            this.answers = this.answers.filter((el) => {
                el = el.replace(/\s+/g, ' ').trim();
                if(el.length > 0) {
                    return el.toUpperCase();
                }
            })

            // shuffleColors
            this.shuffleColors();
            // re-initialize GridSize to highest answer length within bounds
            let i= this.minGridSize;
            for(let j=0; j<this.answers.length; j++) {
                if (this.answers[j].length > this.maxGridSize) {
                    this.answers.splice(j,1); // remove answer
                } else if (this.answers[j].length > i) {
                    i = this.answers[j].length;
                }
            }
            if (i<=this.maxGridSize && i>= this.minGridSize) {
                this.gridSize = i;
            } else if (i>this.maxGridSize) {
                this.gridSize = this.maxGridSize;
            } else {
                this.gridSize = this.minGridSize;
            }
            //  fill grid with randomLetters
            this.fillGrid();

            // insertion magic
            this.dlx(0);

            this.saveGrid();
        },

        // not actually dlx :=) but a recursive solution that remembers and backtracks
        // generates a set of instructions/solutions on how to insert answers into the grid
        dlx(count) {
            if(!this.solutions[count]) {
                this.solutions.push([]);
            }
            if(this.solutions[count].length <= 0) {
                for(let y=0; y<this.gridSize; y++) {
                    for(let x=0; x<this.gridSize; x++) {
                        //find all solutions for the current answer
                        if(this.answers[count].length <= this.gridSize - x && this.horizontal) {
                            //does it fit horizontally?
                            const chars = this.answers[count].split("");
                            let collision = false;
                            for(let i=0; i<chars.length; i++) {
                                const collider = this.findEl(x+i,y);
                                collider.answ.forEach((el) => {
                                    if(this.answers[el.answIndex].charAt(el.answCharIndex) != chars[i]) {
                                        collision = true;
                                    }
                                });
                            }
                            // when not colliding, add to viable solutions
                            if(!collision) {
                                this.solutions[count].push({x:x, y:y, direction: 'horizontal', count: count});
                            }
                        }
                        if(this.answers[count].length <= this.gridSize - y && this.vertical) {
                            //does it fit vertically?
                            const chars = this.answers[count].split("");
                            let collision = false;
                            for(let i=0; i<chars.length; i++) {
                                const collider = this.findEl(x,y+i);
                                collider.answ.forEach((el) => {
                                    if(this.answers[el.answIndex].charAt(el.answCharIndex) != chars[i]) {
                                        collision = true;
                                    }
                                });
                            }
                            // when not colliding, add to viable solutions
                            if(!collision) {
                                this.solutions[count].push({x:x, y:y, direction: 'vertical', count: count});
                            }
                        }
                    }
                }
            }
            // if no solutions could be found, backtrack to previous answer
            if(this.solutions[count].length <=0 && count > 0) {
                this.gridRemoveAnswer(count-1);
                this.dlx(count-1);
            } else if (this.solutions[count].length <=0 && count <= 0) {
                // failed to find any complete solution
                return;
            }
            // pick a random solution, remove it from viable solutions
            // insert answer to grid, proceed with next answer
            const solIndex = Math.floor(Math.random()*this.solutions[count].length);
            this.grindInsertAnswer(this.solutions[count][solIndex]);
            this.solutions[count].splice(solIndex,1);
            if(count+1 < this.answers.length) {
                this.dlx(count+1);
            }
        },

        // solution el { x, y, direction, count }
        // where x and y is origin, count is index of answers
        grindInsertAnswer(el) {
            const chars = this.answers[el.count].split("");
            let xCoef, yCoef;
            switch (el.direction) {
                case 'horizontal':
                    xCoef = 1; yCoef = 0;
                    break;
                case 'vertical':
                    xCoef = 0; yCoef = 1;
                    break
                default:
                    break;
            }
            for(let i=0; i<chars.length; i++) {
                const gridIndex = (el.y+i*yCoef)*this.gridSize+el.x+(i*xCoef);
                this.grid[gridIndex].char = chars[i];
                this.grid[gridIndex].answ.push({
                    answIndex: el.count,
                    answCharIndex: i,
                })
            }
        },

        // find and remove all characters of answer[count]
        // we dont save insertion direction, so it scans the entire grid
        gridRemoveAnswer(count) {
            for(let y=0; y<this.gridSize; y++) {
                for(let x=0; x<this.gridSize; x++) {
                    const gridIndex = y*this.gridSize+x;
                    if (typeof this.grid[gridIndex].answ != 'undefined') {
                        if ( this.grid[gridIndex].answ.length > 0) {
                            for(let l=0; l<this.grid[gridIndex].answ.length; l++) {
                                if(this.grid[gridIndex].answ[l].answIndex == count) {
                                    this.grid[gridIndex].answ[l] = { answIndex: null, answCharIndex: null };
                                    this.grid[gridIndex].char = this.getRandLetter();
                                }
                            }
                        }
                    }
                }
            }
        },

        // resets grid, fills it with random letters
        fillGrid() {
            this.grid = [];
            this.solutions = [[],[]];
            for(let y=0; y<this.gridSize; y++) {
                for(let x=0; x<this.gridSize; x++) {
                    this.grid.push({char: this.getRandLetter(), x: x, y: y, selectedByPupil: false, answ: [] });
                }
            }
        },

        // load: decode grid from arr to json
        // also evaluates the pupils choices against the hidden answers
        initGrid() {
            let answersTemp = [];
            this.grid = this.gridValues.map((gridVal) => {
                const chars = gridVal.split(',');
                let answArr = [];
                for( let i=4; i<chars.length; i=i+2) {
                    answArr.push({
                        answIndex: chars[i],
                        answCharIndex: chars[i+1],
                    });
                    if(chars[3] == 1) { // evaluate the pupils selection against answers on grid elements
                        if(!answersTemp[chars[i]]) {
                            answersTemp[chars[i]] = 0;
                        }
                        answersTemp[chars[i]] += 1;
                    }
                }
                // when a pupil has selected an answer, flag the component as done to prevent further editing on teacher-side
                if(chars[3] == 1) {
                    this.done = true;
                }
                return { char: chars[0], x: chars[1], y: chars[2], selectedByPupil: chars[3] == 0 ? false : true, answ: answArr, };
            });
            for(let i=0; i<this.answers.length; i++) { // reduce pupil selection to boolean
                if(answersTemp[i] == this.answers[i].length) {
                    this.answersFound[i] = true;
                } else {
                    this.answersFound[i] = false;
                }
            }
        },

        // save: encode grid from json to arr
        saveGrid() {
            for(let i=0; i<this.grid.length; i++) {
                this.gridValues[i] = `${this.grid[i].char},${this.grid[i].x},${this.grid[i].y},${this.grid[i].selectedByPupil ? 1 : 0}`;
                for(let j=0; j<this.grid[i].answ.length; j++) {
                    this.gridValues[i] = this.gridValues[i].concat(`,${this.grid[i].answ[j].answIndex},${this.grid[i].answ[j].answCharIndex}`);
                }
            }
        },

        saveState() {
            this.$emit('history', { buchstabensuppe: this.config.buchstabensuppe });
            this.saveGrid();
            this.config.buchstabensuppe.sizeState = this.sizeState;
            this.config.buchstabensuppe.answers = this.answers;
            this.config.buchstabensuppe.answersArea = this.answersArea;

            this.config.buchstabensuppe.gridValues = this.gridValues;
            this.config.buchstabensuppe.difficulty.cfgAllowEmpty = this.cfgAllowEmpty;
            this.config.buchstabensuppe.difficulty.cfgHighlightFirst = this.cfgHighlightFirst;
            this.config.buchstabensuppe.difficulty.cfgShowAnswers = this.cfgShowAnswers;
        },
        async removeAnswerClicked() {
            this.gridRemoveAnswer(this.answers.length-1);
            this.answers.pop();
            this.saveState(); // since field gets visually altered for teacher, save the board
        },

        // helper funct to find element with coordinates
        findEl(x, y) {
            return this.grid.find((el) => {
                return el.x == x && el.y == y;
            });
        },

        // used only for pupil, toggles coloring of the grid
        gridClicked(x, y) {
            if (this.accountRole === 'teacher' || this.state === 'viewer' && !this.previewPupilUI) {
                return;
            }
            const clicked = this.findEl(x,y);
            clicked.selectedByPupil = !clicked.selectedByPupil;
            this.saveState();
        },

        getGridLetter(x, y) {
            const el = this.findEl(x,y);
            return el.char;
        },

        getGridSelected(x, y) {
            if(this.findEl(x,y).selectedByPupil && (this.state === 'viewer' || this.accountRole === 'teacher' && this.done)) {
                return 'gridPupilSelected';
            }
            return '';
        },

        getGridColor(x, y) {
            const el = this.findEl(x,y);
            let colorMix = "#ffffff";
            let highlighted = false;
            if (el.answ) {
                if(el.answ.length > 0) {
                    if (el.answ.length == 1) {
                        colorMix = this.colorArr[el.answ[0].answIndex];
                        if(el.answ[0].answCharIndex == 0) {
                            highlighted = true;
                        }
                        if(this.answersFound.length > 0 && (this.state === 'viewer' || this.done && this.accountRole === 'teacher')) {
                            if(this.answersFound[el.answ[0].answIndex]) {
                                colorMix = "#AFE1AF";   // mark answer correct

                            }
                            else {
                            colorMix = "#EC5959"; // mark answer incorrect

                            }
                        }
                    } else
                    if (el.answ.length > 1) { // when multiple answers are present, blend their colors for visibility
                        for(let i=0; i<el.answ.length; i++) {
                            if(el.answ[i+1]) {
                                colorMix = this.blendColors(this.colorArr[el.answ[i].answIndex], this.colorArr[el.answ[i+1].answIndex], 0.5);
                            }
                            if(el.answ[i].answCharIndex == 0) {
                                highlighted = true;
                            }
                            if(this.answersFound.length > 0 && (this.state === 'viewer' || this.done && this.accountRole === 'teacher')) {
                                if(this.answersFound[el.answ[i].answIndex]) {
                                    colorMix = "#AFE1AF";   // mark answer correct
                                }
                                else {
                                colorMix = "#EC5959";   // mark answer incorrect
                                }
                            }
                        }
                    }
                    if (!el.selectedByPupil && (this.state === 'viewer' || this.done && this.accountRole === 'teacher')) {
                        colorMix = "#EC5959"; // state of game: show correction. pupil has not selected an answer
                    }
                } else
                if (el.selectedByPupil && (this.state === 'viewer' || this.done && this.accountRole === 'teacher')) {
                        colorMix = "#eeb3b3";   // state of game: show correction. pupil  selected the wrong field
                }
            }
            if((this.accountRole === 'pupil' && this.state !== 'viewer') || this.previewPupilUI) {
                if (el.selectedByPupil) {
                    colorMix = "#8ED3EF"; // state of game: pupil has to solve the grid, mark his selections in green
                } else {
                    colorMix = "#ffffff";
                }
            }
            if(this.accountRole === 'teacher' && !this.done) {
                colorMix = "#ffffff"; // teacher closes menu, wants a preview of how it looks for the pupil
            }
            if (this.cfgHighlightFirst && highlighted) {
                colorMix = this.blendColors(colorMix, '#000000', .25);
            }
            return colorMix;
        },

        shuffleColors() {
            for (var i = this.colorArr.length - 1; i > 0; i--) {
                var j = Math.floor(Math.random() * (i + 1));
                var temp = this.colorArr[i];
                this.colorArr[i] = this.colorArr[j];
                this.colorArr[j] = temp;
            }
        },

        getRandLetter() {
            let temp = this.letters[Math.floor(Math.random()*this.letters.length)];
            if(this.cfgAllowEmpty) {
                if (Math.floor(Math.random()*10) < 2) {
                    temp = '';  // chance that no-answer field stays empty
                }
            }
            return temp;
        },

        /*async closeMenu() {
            this.showMenuReentered = false;
            setTimeout(()=>{
                if(this.showMenuReentered === false) {
                    this.showMenu = false;
                }
            }, 145);
        },*/

        // called when multiple Answers share a single Field or the first letter gets highlighted
        blendColors(colorA, colorB, amount) {
        const [rA, gA, bA] = colorA.match(/\w\w/g).map((c) => parseInt(c, 16));
        const [rB, gB, bB] = colorB.match(/\w\w/g).map((c) => parseInt(c, 16));
        const r = Math.round(rA + (rB - rA) * amount).toString(16).padStart(2, '0');
        const g = Math.round(gA + (gB - gA) * amount).toString(16).padStart(2, '0');
        const b = Math.round(bA + (bB - bA) * amount).toString(16).padStart(2, '0');
        return '#' + r + g + b;
        },

        mutateDirections() {
            if(this.horizontal) {
                if(this.vertical) {
                    this.horizontal = false;
                    this.vertical = true;
                    return;
                } else {
                    this.vertical = true;
                    return;
                }
            } else {
                if(this.vertical) {
                    this.horizontal = true;
                    this.vertical = false;
                    return;
                } else {
                    // we shouldnt land here
                    this.horizontal = true;
                    return;
                }
            }
        },

        evalScreenSize() {
            let win = window;
            let doc = document;
            let docElem = doc.documentElement;
            let body = doc.getElementsByTagName('body')[0];
            this.screenX = win.innerWidth || docElem.clientWidth || body.clientWidth;
            this.screenY = win.innerHeight|| docElem.clientHeight|| body.clientHeight;
            if(this.screenX < 800 || this.screenY < 800) {
                this.smallUI = true;
            }
            // alert(this.screenX + ' × ' + this.screenY);
        },
    },
};
</script>

<style scoped lang="scss">
.userExerciseAnswerButton {
    float: right;
}

.icon {
    width: 25px;
    height: 25px;
}

.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

.v-label {
    font-size: 5px;
}

#answerFieldOuter {
    -webkit-transform-origin: top left !important; // vllt hilft das ja sogar, sonst hab ich das mal für morgen reingeschrieben :D thx. bb.
    background-color: #00000000;
    display: block;
    overflow: hidden !important;
    resize: both !important;

    min-width: 200px;
    min-height: 50px;
}

.answerField {
    font-size: large;
    display: inline-flex;
    border-radius: 8px;

    p {
        max-width: 400px;
        overflow-wrap: break-word;
    }
}

#pupilAnswerTextArea {
    width: 100% !important;
    height: 100% !important;
    resize: none;
    outline: none;

}

.gridPupilSelected {
    // border-style: solid;
    // border-width: 3px;
    // border-color: rgba(0, 0, 0, 0.438) !important;
    border-radius: 5px;
    // box-sizing: border-box !important;
    // table{border-collapse: collapse;}
    // color: black;
    box-shadow: inset 0 0 0 3px rgba(0, 0, 0, 0.438);
}

.acceptEntryButton {
    width: 36px !important;
    height: 36px !important;

    img {
        width: 20px !important;
        height: 20px !important;
    }
}

textarea.v-input {
    min-height: 10px !important;
}

.editorColorPrimaryBackgroundOnHover:hover .backgroundToEditor {
    background-color: rgb(var(--v-theme-editorprimary)) !important;
}

</style>
