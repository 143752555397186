<template>
    <v-dialog
        width="400"
        :model-value="showDialog"
        :persistent="!editMode"
        @update:model-value="onShowDialogChange"
        @click:outside="clickOutsideDialog"
    >
        <v-card
            class="cardStyle"
            style="overflow: hidden; border-radius: 16px; height: 80vh"
        >
            <v-card-title
                class="d-flex align-center justify-space-between"
                style="height: 56px; background-color: rgb(var(--v-theme-headerblue))"
            >
                <p style="color: white; font-size: 18px !important;">
                    {{ "Pinnwand " + (editMode ? "bearbeiten" : "erstellen") }}
                </p>
                <div class="d-flex justify-space-around align-center">
                    <v-tooltip
                        location="bottom"
                    >
                        <template v-slot:activator="{ props }">
                            <v-btn
                                size="small"
                                class="mr-2"
                               
                                width="25"
                                height="25"
                                color="success"
                                v-bind="props"
                                @click="editMode ? savePinboard() : createPinboard()"
                            >
                                <img
                                    :src="saveIcon"
                                    class="icon20 iconToWhite"
                                    alt="speichern"
                                >
                            </v-btn>
                        </template>
                        <span>{{ editMode ? "Speichern" : "Erstellen" }}</span>
                    </v-tooltip>
                    <v-btn
                        size="x-small"
                        variant="text"
                        elevation="0"
                        class="pa-0"
                        @click="closeButtonClicked"
                    >
                        <img
                            :src="cancelIcon"
                            class="icon20 iconToWhite"
                            alt="schließen"
                        >
                    </v-btn>
                </div>
            </v-card-title>
            <v-card-text
                class="group-list-container"
                style="height: calc(87vh - 55px - 60px)"
            >
                <div
                    class="d-flex align-end"
                    style="max-height: 73px"
                >
                    <icon-picker
                        class="iconSheet mr-5"
                        elevation="0"
                        :current-color="editMode ? item.color : color"
                        :callback="(result) => { editMode ? item.color = result : color = result }"
                        :current-icon="editMode ? item.icon : icon"
                        :callback-icon="(result) => { editMode ? item.icon = result : icon = result }"
                        :group-icons="icons"
                        :filter-icon-color-to-grey="true"
                        :color-picker-usage="'pinboard'"
                    />

                    <div>
                        <h1
                            class="formHeader"
                            style="font-size: 20px; margin-bottom: 3px"
                        >
                            Pinnwandname<span class="text-red bold">*</span>
                        </h1>
                        <v-text-field
                            v-model="newPinboardName"
                            variant="filled"
                            density="compact"
                            hide-details
                            placeholder="Name eintragen"
                            style="width: 100%; max-width: 500px; font-size: 20px"
                        />
                    </div>
                </div>

                <h1
                    class="formHeader"
                    style="font-size: 20px"
                >
                    Teilnehmer hinzufügen
                    <v-tooltip
                        location="bottom"
                        max-width="400px"
                    >
                        <template v-slot:activator="{ props }">
                            <img
                                :src="infoIcon"
                                class="icon20"
                                alt="Erklärung"
                               
                                v-bind="props"
                            >
                        </template>
                        <span>Wenn 'Alle' augewählt werden, haben alle Nutzer Zugriff auf die Pinnwand. Ansonsten stehen in der unteren Auflistung alle Nutzer sowie Gruppen/Klassen zur Auswahl und können mit dem Suchfeld gefunden werden.</span>
                    </v-tooltip>
                </h1>
                <div
                    class="d-flex"
                    style="flex-direction: row; align-items: center"
                >
                    <p>
                        Alle:
                    </p>
                    <v-select
                        v-model="selectAll"
                        class="pl-4 mt-1"
                        placeholder="Keine Aktion ausgewählt"
                        :items="['Sehen', 'Bearbeiten', 'Kein Mitglied']"
                        rounded
                    />
                </div>
                <div class="d-flex align-center">
                    <v-text-field
                        v-model="searchAccountSetName"
                        variant="filled"
                        density="compact"
                        hide-details
                        clearable
                        placeholder="Teilnehmer suchen"
                        style="max-width: 500px"
                    >
                        <template v-slot:prepend-inner>
                            <img
                                :src="pupilIcon"
                                class="icon20 iconToGrey mr-2 mb-2"
                                alt=""
                            >
                        </template>
                    </v-text-field>
                </div>
                <simple-bar
                    :auto-hide="false"
                    class="scroll-area"
                >
                    <v-data-table
                        :model-value="selectedAccountSetIds"
                        :headers="headers"
                        :items="filteredSelectableAccountSets"
                        item-key="id"
                        class="elevation-1"
                        hide-default-footer
                        disable-pagination
                    >
                        <template v-slot:item="{ item }">
                            <tr>
                                <td
                                    style="min-width: 109px"
                                >
                                    {{ item.displayName }}
                                </td>
                                <td>
                                    <v-select
                                        v-model="item.role"
                                        :items="['Sehen', 'Bearbeiten', 'Moderator', 'Kein Mitglied']"
                                    />
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </simple-bar>
                <div
                    v-if="editMode"
                    class="d-flex flex-column justify-center"
                >
                    <div
                        class="d-flex flex-column"
                        style="align-items: center"
                    >
                        <p>{{ 'oder ' + (this.account.role === 'pupil' ? 'verschicke' : 'verschicken Sie') + ' einen Einladungslink' }}</p>
                        <v-btn
                            class="mr-2 mb-4 toolSelector"
                            color="gruen"
                            min-width="90px"
                            elevation="0"
                            style="border-radius: 8px; max-width: 86%"
                            @click="showExternalDialog = true"
                        >
                            <p
                                class="text-capitalize"
                                style="font-size: large; color: white; margin-left: 4px"
                            >
                                Einladungslinks
                            </p>
                        </v-btn>
                    </div>
                </div>
            </v-card-text>
        </v-card>
        <PinboardExternalDialog
            v-if="showExternalDialog"
            :pinboard="item"
            :load-pinboard="updatePinboard"
            @close="showExternalDialog = false"
        />
    </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapGetters, mapState } from "vuex";
import IconPicker from"@/components/Utils/IconPicker";
import PinboardExternalDialog from "./PinboardExternalDialog";

import saveIcon from '@/assets/Icons/FaceliftIcons/speichern.svg';
import cancelIcon from '@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg';
import pupilIcon from "../../assets/Icons/pupil.svg";
import erdkundeIconWeiss from '../../assets/Icons/FaceliftIcons/f-erdkunde-weiss.svg';
import religionIconWeiss from '../../assets/Icons/FaceliftIcons/f-religion-weiss.svg';
import politikIconWeiss from '../../assets/Icons/FaceliftIcons/f-politik-weiss.svg';
import bioIconWeiss from '../../assets/Icons/FaceliftIcons/f-biologie-weiss.svg';
import englischIconWeiss from "../../assets/Icons/FaceliftIcons/f-englisch-weiss.svg";
import pinIconWeiss from "../../assets/Icons/FaceliftIcons/pin_alt_weiss.svg";
import chemieIconWeiss from '../../assets/Icons/FaceliftIcons/f-chemie-weiss.svg';
import deutschIconWeiss from '../../assets/Icons/FaceliftIcons/f-deutsch-weiss.svg';
import geschichteIconWeiss from '../../assets/Icons/FaceliftIcons/f-geschichte-weiss.svg';
import matheIconWeiss from '../../assets/Icons/FaceliftIcons/f-mathe-weiss.svg';
import musikIconWeiss from '../../assets/Icons/FaceliftIcons/f-musik-weiss.svg';
import physikIconWeiss from '../../assets/Icons/FaceliftIcons/f-physik-weiss.svg';
import sportIconWeiss from '../../assets/Icons/FaceliftIcons/f-sport-weiss.svg';
import wirtschaftIconWeiss from '../../assets/Icons/FaceliftIcons/f-wirtschaft-weiss.svg';
import kunstIconWeiss from '@/assets/Icons/FaceliftIcons/f-kunst-weiss.svg';
import infoIcon from '@/assets/Icons/FaceliftIcons/info_hinweis.svg';

export default {
    name: "PinboardCreateDialog",
    components: {
        IconPicker,
        PinboardExternalDialog,
    },
    props: {
        editMode: { required: false, type: Boolean, default: false },
        item: {required: false, type: Object, default: null},
        updatePinboard: {required: false, type: Function, default: () => {}}
    },
    data: () => ({
        saveIcon,
        cancelIcon,
        pupilIcon,

        color: '#fcd20a',
        icon: 'pinIconWeiss',
        icons:[
            {icon: pinIconWeiss, text: "pinIconWeiss"},
            {icon: englischIconWeiss, text: "englischIconWeiss"},
            {icon: politikIconWeiss, text: "politikIcon"},
            {icon: erdkundeIconWeiss, text: "erdkundeIcon"},
            {icon: religionIconWeiss, text: "religionIcon"},
            {icon: bioIconWeiss, text: "bioIcon"},
            {icon: chemieIconWeiss, text: "chemieIcon"},
            {icon: deutschIconWeiss, text: "deutschIcon"},
            {icon: geschichteIconWeiss, text: "geschichteIcon"},
            {icon: matheIconWeiss, text: "matheIcon"},
            {icon: musikIconWeiss, text: "musikIcon"},
            {icon: kunstIconWeiss, text: "kunstIcon"},
            {icon: physikIconWeiss, text: "physikIcon"},
            {icon: sportIconWeiss, text: "sportIcon"},
            {icon: wirtschaftIconWeiss, text: "wirtschaftIcon"},
        ],

        infoIcon,
        searchAccountSetName: "",
        showDialog: true,
        newPinboardName: "",
        selectedAccountSetIds: [],
        headers: [
            {
                text: "Mitglied",
                align: "left",
                value: "name"
            },
            { text: "Rechte" }
        ],
        selectAll: '',
        showExternalDialog: false,
    }),
    computed: {
        ...mapGetters("accountSets", ["accountSets"]),
        ...mapGetters("accounts", ["accountsById"]),
        ...mapGetters("groups", ["groupsById"]),
        ...mapState("auth", ["account"]),
        selectableAccountSets() {
            const getAccountSetDisplayName = (accountSet) => {
                if (accountSet.type === "account") {
                    const account = this.accountsById[accountSet.account];
                    return account ? account.displayName : account._id;
                } else if (accountSet.type === "groupLeaders") {
                    const group = this.groupsById[accountSet.group];
                    return `Gruppe "${group?.name}" Gruppenleiter`;
                } else if (accountSet.type === "groupAppointmentLeaders") {
                    const group = this.groupsById[accountSet.group];
                    return `Gruppe "${group?.name}" Fachlehrer`;
                } else if (accountSet.type === "groupParticipants") {
                    const group = this.groupsById[accountSet.group];
                    return `Gruppe "${group?.name}" Schüler`;
                } else if (accountSet.type === "groupParticipantsParents") {
                    const group = this.groupsById[accountSet.group];
                    return `Gruppe "${group?.name}" Eltern`;
                } else if (accountSet.type === "allTeachers") {
                    return "Alle Lehrer";
                } else if (accountSet.type === "allMaintainers") {
                    return "Alle Verwaltungen";
                } else if (accountSet.type === "allPupils") {
                    return "Alle Schüler";
                } else if (accountSet.type === "allParents") {
                    return "Alle Eltern";
                } else {
                    // For debugging if there is a new type
                    console.log('Problematic new type:', accountSet.type);
                    return 'Unbekannt'
                }
            };
            return this.accountSets
                .filter((accountSet) => accountSet._id !== this.myAccountSet?._id && accountSet.displayName !== 'Unbekannt'&& accountSet.type === 'account')
                 .map((accountSet) => ({
                     ...accountSet,
                     displayName: getAccountSetDisplayName(accountSet),
                     role: this.getMembershipRole(accountSet),
                 }))
                .filter((accountSet) => !accountSet.displayName.includes("PersonAlle"))
                .filter((accountSet) => !accountSet.displayName.includes("Verwaltungen"))
                .filter((accountSet) => !accountSet.displayName.includes("Alle"));
        },
        myAccountSet() {
            return this.accountSets.find(
                (accountSet) =>
                    accountSet.type === "account" &&
                    accountSet.account === this.account._id
            );
        },
       filteredSelectableAccountSets(){
            return this.selectableAccountSets.filter((set)=> set.displayName.toLowerCase().includes(this.searchAccountSetName.toLowerCase()));
       }
    },
    watch: {
        selectAll(newState) {
            this.selectableAccountSets.map((accountSet) => {
                accountSet.role = newState;
            })
        },
        searchAccountSetName(newVal){
            if(newVal === null){
                this.searchAccountSetName = '';
            }
        }
    },
    mounted() {
        if (this.editMode) {
            this.newPinboardName = this.item.title;
            this.selectedAccountSetIds = this.item.memberships.map((membership) => membership.accountSet._id);
        }
    },
    methods: {
        ...mapActions("pinboard", ["postPinboard", "postCard", "patchPinboard"]),
        ...mapMutations("snackbar", ["showSnackbar"]),
        closeDialog() {
            this.$emit("close");
        },

        onShowDialogChange(newValue) {
            if (!newValue) {
                this.closeDialog();
            }
        },

        async createPinboard() {
            if (this.newPinboardName.trim() === "") {
                this.showSnackbar({ message: 'Der Name der Pinnwand darf nicht leer sein!', color: 'error'});
            } else {
                const accountSetsToPatch = this.selectableAccountSets.filter((accountSet) => accountSet.role !== 'Kein Mitglied');
                const otherMembership = await Promise.all(accountSetsToPatch.map(async (accountSet) => ({
                    accountSet: accountSet._id,
                    role: this.getMembershipRoleForBackend(accountSet),
                })));
                const myMembership = {
                    accountSet: this.myAccountSet._id,
                    role: "moderator",
                };
                let data = {
                    title: this.newPinboardName,
                    memberships: [myMembership, ...otherMembership],
                    color: this.color,
                    icon: this.icon.toString(),
                    uploadedIcon: !this.icon.includes('Icon'),
                }
                let response = await this.postPinboard(data);
                if (Number.isInteger(response)) {
                    if (response.status === 409) {
                        this.showSnackbar({ message: 'Name bereits vergeben. Bitte geben Sie einen anderen Pinnwandnamen ein!', color: 'error'});
                    } else {
                        this.showSnackbar({ message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!', color: 'error'});
                    }
                } else {
                    const pinboardId = await response.json();
                    await this.createDefaultTitleCard(pinboardId._id);
                    await this.createDefaultContentCard(pinboardId._id);
                    this.showSnackbar({ message: 'Pinnwand wurde erfolgreich erstellt!'});
                }
                // TODO: After new pinboard was created, try to update pinboard list locally (if possible) instead of calling refreshPinboards()
                this.closeDialog();
            }
        },

        async createDefaultTitleCard(id) {
            let data = {
                content: 'Titel',
                position_x: 0,
                position_y: 0,
                pinboard: id,
                isTitleCard: true,
                color: '#3cb371',
            }
            await this.postCard(data);
        },

        async createDefaultContentCard(id) {
            let data = {
                content: '',
                position_x: 0,
                position_y: 1,
                pinboard: id,
                isTitleCard: false,
                color: '#3cb371',
            }
            await this.postCard(data);
        },

        async savePinboard() {
            if (this.newPinboardName.trim() === "") {
                this.showSnackbar({ message: 'Der Name der Pinnwand darf nicht leer sein!', color: 'error'});
            } else {
                const accountSetsToPatch = this.selectableAccountSets.filter((accountSet) => accountSet.role !== 'Kein Mitglied');
                const otherMembership = await Promise.all(accountSetsToPatch.map(async (accountSet) => ({
                    accountSet: accountSet._id,
                    role: this.getMembershipRoleForBackend(accountSet),
                })));
                console.log('otherMemberShip',otherMembership);
                const myMembership = {
                    accountSet: this.myAccountSet._id,
                    role: "moderator",
                };
                const pinboardResponse = await this.patchPinboard({ cardId: this.item._id, body: {
                        title: this.newPinboardName,
                        memberships: [myMembership, ...otherMembership],
                        color: this.item.color,
                        icon: this.item.icon,
                        uploadedIcon: !this.item.icon.includes('Icon'),
                    } });
                const newPinboard = await pinboardResponse.json();
                this.updatePinboard(newPinboard, this.item._id);
                if (pinboardResponse.status === 200) {
                    this.showSnackbar({ message: 'Änderungen wurden erfolgreich gespeichert!'});
                } else if (pinboardResponse.status === 403) {
                    this.showSnackbar({ message: 'Du hast nicht die Rechte zum Bearbeiten der Pinnwand!', color: 'error'});
                } else {
                    this.showSnackbar({ message: 'Etwas ist schiefgelaufen!', color: 'error'});
                }
                this.closeDialog();
            }

        },

        closeButtonClicked() {
            this.$emit('closeButtonClicked');
        },

        clickOutsideDialog() {
            if (this.editMode) {
                this.savePinboard();
            }
        },

        getMembershipRole(accountSet) {
            let foundMembership = false;
            if (this.editMode) {
                foundMembership = this.item.memberships.find((membership) => membership.accountSet._id === accountSet._id);
            }
            if (foundMembership) {
                const membershipRole = foundMembership.role;
                if (membershipRole === 'moderator') {
                    return 'Moderator';
                } else if (membershipRole === 'member') {
                    return 'Bearbeiten';
                } else if (membershipRole === 'visitor') {
                    return 'Sehen';
                }
            } else {
                return 'Kein Mitglied';
            }
        },

        getMembershipRoleForBackend(accountSet) {
            if (accountSet.role === 'Bearbeiten') {
                return 'member';
            } else if (accountSet.role === 'Sehen') {
                return 'visitor';
            } else if (accountSet.role === 'Moderator') {
                return 'moderator';
            }
        }
    },
}
</script>

<style scoped lang="scss">
.cardStyle {
    .v-card__text {
        // unify border-radius
        .v-btn {
            border-radius: 8px;
        }
        .v-input__control > .v-input__slot {
            border-radius: 8px;
        }

        // remove border bottom line/underline from all the inputs in v-cards with this class
        .v-input__control > .v-input__slot:before,
        .v-input__control > .v-input__slot:after {
            border-style: none !important;
        }
    }
}

.group-list-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.scroll-area {
    flex: 1;
}

.formHeader {
    font-size: 16px;
    margin-top: 20px !important;
}

#eK-button-disabled.v-btn--disabled {
    opacity: 0.5;
    background-color: rgb(var(--v-theme-grau)) !important;
    color: white !important;
}

.iconSheet {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px !important;
    width: 60px;
    border-radius: 12px;
}

.icon {
    font-size: xxx-large;
}
</style>
