import { h, createApp } from 'vue';
import QrCodeVue from 'qrcode.vue';

const encodeAsQrCodeImage = ({ size = 200, value }) => {
    // Create a temporary container
    const container = document.createElement('div');
    document.body.appendChild(container); // Append to DOM for rendering

    // Use Vue's `h` function to create the QrCodeVue component
    const app = createApp({
        render() {
            return h(QrCodeVue, { value, size });
        },
    });

    // Mount the app to the container
    const instance = app.mount(container);

    // Find the <canvas> element inside the rendered component
    const canvas = container.querySelector('canvas');
    if (!canvas) {
        throw new Error('Failed to render QR code canvas.');
    }

    // Generate the data URL from the canvas
    const dataURL = canvas.toDataURL('image/png');

    // Clean up
    app.unmount();
    container.remove();

    // Return the data URL
    return dataURL;
};

export default {
    encodeAsQrCodeImage,
};
