<template>
    <!--nudge-left="130px" with Formen-->
    <v-menu
        v-model="vMenu"
        :offset="[80,-20]"
        :close-on-content-click="false"
        :attach="'#editorStaticElementBarColumn'"
    >
        <template v-slot:activator="{ props:menu }">
            <v-tooltip location="bottom">
                <template v-slot:activator="{ props:tooltip }">
                    <v-btn
                        v-if="mode === 'creator' || mode === 'teacher' || mode === 'pupil'"
                        class="squareBtn"
                        icon="$vuetify"
                        variant="plain"
                        aria-hidden="true"
                        v-bind="$mergeProps(menu, tooltip)"
                        @click="() => { menu = 'basic'; $emit('opened') }"
                    >
                        <img
                            :src="plusIcon"
                            alt="Menü öffnen"
                        >
                    </v-btn>
                </template>
                <span>Medien und Formen</span>
            </v-tooltip>
        </template>

        <!-- component selection -->
        <div
            v-if="menu === 'basic'"
            class="menuBg"
        >
            <div class="d-flex pa-4">
                <div>
                    <div v-if="!smartphoneUi">
                        <p class="text-white">
                            Medien
                        </p>
                    </div>
                    <div
                        class="d-flex mr-2"
                        style="flex-wrap: wrap"
                    >
                        <v-tooltip location="bottom">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="x-small"
                                    class="squareBtn mr-2 mb-2"
                                    v-bind="props"
                                    @click="addImage"
                                >
                                    <img
                                        :src="bildIcon"
                                        alt="Bild hinzufügen"
                                    >
                                </v-btn>
                            </template>

                            <div style="display: flex; flex-direction: column; text-align: center">
                                <span>Bild hinzufügen</span>
                            </div>
                        </v-tooltip>

                        <v-tooltip location="bottom">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="x-small"
                                    class="squareBtn mr-2"
                                    v-bind="props"
                                    @click="addVideo"
                                >
                                    <img
                                        :src="videoIcon"
                                        alt="Video hinzufügen"
                                    >
                                </v-btn>
                            </template>

                            <div style="display: flex; flex-direction: column; text-align: center">
                                <span>Video hinzufügen</span>
                            </div>
                        </v-tooltip>

                        <v-tooltip location="bottom">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="x-small"
                                    class="squareBtn mr-2"
                                    v-bind="props"
                                    @click="addAudio"
                                >
                                    <img
                                        :src="audioIcon"
                                        alt="Audio hinzufügen"
                                    >
                                </v-btn>
                            </template>

                            <div style="display: flex; flex-direction: column; text-align: center">
                                <span>Audio hinzufügen</span>
                            </div>
                        </v-tooltip>

                        <v-tooltip
                            v-if="showPdfButton"
                            location="bottom"
                        >
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    v-if="showPdfButton"
                                    id="textLayerButton"
                                    aria-label="Texte des Dokuments aktivieren"
                                    size="x-small"
                                    elevation="0"
                                    class="mr-2 squareBtn"
                                    :class="{ 'textLayerActive': isTextLayerVisible }"
                                    v-bind="props"
                                    @click="$emit('changePDFTextLayerVisibility')"
                                >
                                    <img
                                        :src="isTextLayerVisible ? lautsprecherAusIcon : lautsprecherIcon"
                                        width="28px"
                                        height="35px"
                                        style="filter: brightness(100%)"
                                    >
                                </v-btn>
                            </template>
                            <div style="display: flex; flex-direction: column; text-align: center">
                                <span>Texte des Dokuments aktivieren</span>
                            </div>
                        </v-tooltip>

                        <EditorPreviewModeSelector
                            v-if="(mode === 'creator' || mode === 'teacher') && windowWidth <= 460"
                            :current-preview="currentPreview"
                            :window-width="windowWidth"
                            :element-bar="true"
                            @change="$emit('changeCurrentPreviewMode')"
                            @preview-pupil-u-i-changed="$emit('updatePreviewUIProp')"
                            @toggle-helping-rulers="(value) => { $emit('toggleHelpingRulers', value) }"
                        />

                        <!-- Sprachnotiz for smartphone -->
                        <v-btn
                            v-if="smartphoneUi"
                            aria-label="Sprachnachricht"
                            elevation="3"
                            size="x-small"
                            class="mr-2 squareBtn"
                            tabindex="1"
                            @click="() => $emit('addAudioMessage')"
                        >
                            <img
                                height="35px"
                                :src="audioMessageIcon"
                            >
                        </v-btn>
                        <!-- Textnotiz for smartphone -->
                        <v-btn
                            v-if="smartphoneUi"
                            aria-label="Textnachricht"
                            elevation="3"
                            size="x-small"
                            class="mr-2 squareBtn"
                            tabindex="1"
                            @click="() => $emit('addTextMessage')"
                        >
                            <img
                                height="35px"
                                :src="textIcon"
                            >
                        </v-btn>
                        <!-- neue Seite toggle for smartphone -->
                        <v-btn
                            v-if="smartphoneUi"
                            aria-label="Neue Seite Menü"
                            elevation="3"
                            size="x-small"
                            class="mr-2 squareBtn"
                            tabindex="1"
                            @click="() => $emit('toggleNewPageMenu')"
                        >
                            <img
                                height="35px"
                                :src="pageMenuIcon"
                            >
                        </v-btn>
                    </div>

                    <!-- surprise inputs that will help us later -->
                    <div>
                        <input
                            ref="imgUpload"
                            type="file"
                            style="display: none"
                            hidden
                            name="file"
                            accept="image/*"
                            @change="imgSelected"
                        >
                        <input
                            ref="videoUpload"
                            type="file"
                            style="display: none"
                            hidden
                            name="file"
                            accept="video/mp4,video/mpeg,video/mov,video/*"
                            @change="videoSelected"
                        >
                        <input
                            ref="audioUpload"
                            type="file"
                            style="display: none"
                            hidden
                            name="file"
                            accept="audio/m4a,.m4a,audio/mpeg,audio/ogg,audio/mp3,audio/*"
                            @change="audioSelected"
                        >
                    </div>
                </div>

                <div v-if="false">
                    <div>
                        <p class="text-white">
                            Formen
                        </p>
                    </div>
                    <div>
                        <v-tooltip location="bottom">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="x-small"
                                    class="squareBtn mr-2"
                                    v-bind="props"
                                    @click="clickedAddRect"
                                >
                                    <img
                                        :src="rechteckIcon"
                                        alt="Rechteck hinzufügen"
                                    >
                                </v-btn>
                            </template>

                            <div style="display: flex; flex-direction: column; text-align: center">
                                <span>Rechteck hinzufügen</span>
                            </div>
                        </v-tooltip>

                        <v-tooltip location="bottom">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="x-small"
                                    class="squareBtn"
                                    v-bind="props"
                                    @click="clickedAddLine"
                                >
                                    <img
                                        :src="linieIcon"
                                        alt="Linie hinzufügen"
                                    >
                                </v-btn>
                            </template>

                            <div style="display: flex; flex-direction: column; text-align: center">
                                <span>Linie hinzufügen</span>
                            </div>
                        </v-tooltip>
                    </div>
                </div>

                <!-- probably only in right sidebar -->
                <!--                <div>
                    <div>
                        <p class="white&#45;&#45;text">Notizen</p>
                    </div>
                    <div>
                        <v-btn x-small class="squareBtn mr-2">
                            <img :src="plusIcon" alt="Bild hinzufügen">
                        </v-btn>
                        <v-btn x-small class="squareBtn">
                            <img :src="plusIcon" alt="Bild hinzufügen">
                        </v-btn>
                    </div>
                </div>-->
            </div>
        </div>

        <!-- rectangle options -->
        <div
            v-if="menu === 'rect' || menu === 'line'"
            class="menuBg"
        >
            <div class="d-flex pa-4">
                <div>
                    <div>
                        <p class="text-white">
                            Farbauswahl
                        </p>
                    </div>

                    <div>
                        <v-color-picker
                            id="cPicker"
                            v-model="selectedColor"
                            class="ma-2 menuBg"
                            :swatches="swatches"
                            hide-canvas
                            hide-inputs
                            show-swatches
                        />
                    </div>

                    <div
                        v-if="menu === 'rect'"
                        class="d-flex flex-column"
                    >
                        <span class="text-white">Mit Rahmen</span>
                        <v-checkbox
                            v-model="rectangleBorderState"
                            hide-details
                            dark
                            class="ma-0"
                        />
                    </div>

                    <div class="d-flex justify-space-between">
                        <v-btn
                            color="error"
                            class="text-capitalize ml-1"
                            @click="back"
                        >
                            Zurück
                        </v-btn>
                        <v-btn
                            color="success"
                            class="text-capitalize"
                            @click="spawnForm"
                        >
                            Hinzufügen
                        </v-btn>
                    </div>
                </div>
            </div>
        </div>
    </v-menu>
</template>

<script>
import plusIcon from "@/assets/Icons/plus.svg";
import audioIcon from '@/assets/Icons/EditorRedesign/audio.svg';
import audioMessageIcon from '@/assets/Icons/FaceliftIcons/mikrofon_an.svg';
import textIcon from '@/assets/Icons/text-note.svg';
import bildIcon from '@/assets/Icons/EditorRedesign/bild.svg';
import videoIcon from '@/assets/Icons/EditorRedesign/video.svg';
import linieIcon from '@/assets/Icons/EditorRedesign/linie.svg';
import rechteckIcon from '@/assets/Icons/EditorRedesign/rechteck.svg';
import lautsprecherIcon from "@/assets/Icons/lautsprecher-13.svg";
import lautsprecherAusIcon from "@/assets/Icons/lautsprecher-aus-89.svg";
import pageMenuIcon from '@/assets/Icons/FileCreator/EKLARA_Icons_editor_neue_seite.svg'
import EditorPreviewModeSelector from "@/components/Editor/EditorPreviewModeSelector";

export default {
    name: "EditorStaticElementBar",
    components: {
        EditorPreviewModeSelector
    },
    props: {
        mode: { required: true, type: String },
        showPdfButton: { required: true, type: Boolean },
        isTextLayerVisible: { required: true, type: Boolean },
        currentPreview: { required: true, type: String },
        windowWidth: { required: true, type: Number },
        smartphoneUi: { required: true, type: Boolean },
    },
    data: () => ({
        plusIcon,
        audioIcon,
        bildIcon,
        videoIcon,
        linieIcon,
        rechteckIcon,
        lautsprecherIcon,
        lautsprecherAusIcon,
        textIcon,
        pageMenuIcon,
        audioMessageIcon,

        vMenu: false,
        menu: '', // basic = shows components, rest shows the respective options for that component e.g. rect(angle)

        selectedItem: '',
        selectedColor: '#FFFFFF',
        swatches: [
            ['#FFFFFF', '#b2b2b2', '#707070'],
            ['#000000', '#1b5f99', '#9bcdc3'],
            ['#fcd20a', '#f0b423', '#ff6941'],
            ['#91c364', '#91c887', '#d7875f'],
            ['#9b91c8', '#ebaabe', '#6ec3dc'],
        ],
        rectangleBorderState: true,
    }),
    methods: {
        // general methods
        back() {
            this.menu = 'basic';
            this.selectedColor = '#FFFFFF';
            this.rectangleBorderState = true;
        },

        // FormMenu
        clickedAddRect() {
            this.menu = 'rect';
            this.selectedItem = 'rect';
        },
        clickedAddLine() {
            this.menu = 'line';
            this.selectedItem = 'line';
        },
        spawnForm() {
            if (this.selectedItem === 'rect') {
                this.spawnRectangle();
            } else if (this.selectedItem === 'line') {
                this.spawnLine();
            }
        },
        spawnRectangle() {
            this.$emit('addRectangle', this.selectedColor, (this.rectangleBorderState ? '1px solid black': ''));
            this.selectedColor = '#FFFFFF';
            this.vMenu = false;
        },
        spawnLine() {
            this.$emit('addLine', this.selectedColor);
            this.selectedColor = '#FFFFFF';
            this.vMenu = false;
        },
        addImage() {
            this.$refs.imgUpload.click();
            this.vMenu = false;
        },
        addVideo() {
            this.$refs.videoUpload.click();
            this.vMenu = false;
        },
        addAudio() {
            this.$refs.audioUpload.click();
            this.vMenu = false;
        },
        imgSelected() {
            if(this.$refs.imgUpload.files.length > 0) {
                let blob = this.$refs.imgUpload.files[0];
                let url = window.URL.createObjectURL(blob);
                this.$emit('addImageField', url, blob);
                this.$refs.imgUpload.value = "";
            }
        },
        videoSelected() {
            if(this.$refs.videoUpload.files.length > 0){
                let blob = this.$refs.videoUpload.files[0];
                let url = window.URL.createObjectURL(blob);
                this.$emit('addVideoField', url, blob);
                this.$refs.videoUpload.value = "";
            }
        },
        audioSelected() {
            if(this.$refs.audioUpload.files.length > 0){
                let blob = this.$refs.audioUpload.files[0];
                let url = window.URL.createObjectURL(blob);
                this.$emit('addAudioField', url, blob);
                this.$refs.audioUpload.value = "";
            }
        },
    },
}
</script>

<style scoped lang="scss">
.menuBg {
    background-color: rgba(100, 100, 100, 0.8)
}

.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

.squareBtn {
    width: 49px;
    height: 49px;
    min-width: 49px;
    min-height: 49px;
    border-radius: 8px;

    img {
        width: 35px;
        height: 35px;
    }
}

.vl {
    border-right: 2px solid white;
    border-radius: 1px;
    height: 49px;
}

#cPicker {
    .v-color-picker__controls {
        .v-color-picker__preview--hide-alpha {
            .v-color-picker__dot {
                border-radius: 0% !important;
            }

            .v-color-picker__dot {
                border: 1px solid gray;
                -webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.4);
                box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.4);
            }

            .v-color-picker__sliders {
                display: none !important;
            }
        }
    }
}

#textLayerButton {
    img {
        filter: brightness(0);
    }
}

.textLayerActive {
    filter: invert(0.8);
}
</style>
