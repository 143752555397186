<template>
    <v-dialog
        persistent
        :model-value="showFirstLoginDialog"
        scrollable
        :width="windowWidth > 900 ? '600px' : '80vw'"
        @update:model-value="updateShowFirstLoginDialog"
    >
        <v-card style="border-radius: 8px;">
            <v-card-title
                class="d-flex flex-row justify-space-between pa-3"
                style="background-color:#343e55; color: white"
            >
                Ersteinrichtung                <v-btn
                    color="success"
                    @click="confirmInitialization()"
                >
                    <img
                        :src="saveIcon"
                        class="icon20 iconToWhite mr-2"
                        alt="speichern"
                    >
                    Speichern
                </v-btn>
            </v-card-title>
            <v-card-text>
                <p class="pt-2">
                    Wilkommen bei eklara, wir benötigen Folgende Daten um Dokumnete wie Nutzungsbedingungen, Datenschutzverodnung oder Briefkopf automatisiert vervollständigen zu können
                </p>
                <br>
                <div class="d-flex flex-row justify-space-around">
                    <div class="d-flex flex-column">
                        <v-text-field
                            v-model="schoolName"
                            variant="outlined"
                            density="compact"
                            hide-details
                            label="Name der Schule"
                            class="inputField mb-2"
                        />

                        <v-text-field
                            v-model="schoolStreet"
                            variant="outlined"
                            density="compact"
                            hide-details
                            label="Straße und Nummer"
                            class="inputField mb-2"
                        />
                            
                        <v-text-field
                            v-model="schoolPostal"
                            variant="outlined"
                            density="compact"
                            label="PLZ"
                            hide-details
                            class="inputField mb-2"
                        />

                        <v-text-field
                            v-model="schoolCity"
                            variant="outlined"
                            density="compact"
                            hide-details
                            label="Ort"
                            class="inputField mb-2"
                        />
                        <v-text-field
                            v-model="schoolEmail"
                            variant="outlined"
                            density="compact"
                            hide-details
                            label="E-Mail"
                            class="inputField mb-2"
                        />
                        <v-text-field
                            v-model="schoolPhone"
                            variant="outlined"
                            density="compact"
                            label="Telefon"
                            hide-details
                            class="inputField mb-2"
                        />
                        <v-text-field
                            v-model="principal"
                            variant="outlined"
                            density="compact"
                            hide-details
                            label="Schulleiter"
                            class="inputField mb-2"
                        />
                        <v-text-field
                            v-model="schoolDataSecurityOfficer"
                            variant="outlined"
                            density="compact"
                            label="Kontakt Datenschutz"
                            hide-details
                            class="inputField mb-2"
                        />
                        <!-- <v-text-field
                            v-model=" generalDataSecurityOfficer"
                            outlined
                            dense
                            hide-details
                            label="Zuständiger Datenschützer"
                            class="inputField mb-2"
                        /> -->
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import saveIcon from '@/assets/Icons/FaceliftIcons/speichern.svg';

export default {
    name: "SchoolInit",
    components: {},
    props: {
        maintainer: { required: true, },
        showFirstLoginDialog:{required: true,},
    },
    data: () => ({
        //schoolModel
        schoolId: '',
        schoolPostal: '',
        schoolName:'',
        schoolStreet: '',
        schoolCity: '',
        schoolPhone: '',
        schoolEmail: '',
        // generalDataSecurityOfficer:'',
        principal:'',
        schoolDataSecurityOfficer:'',
        saveIcon,
    }),
    computed:{
        ...mapGetters('auth', ["accountRole",]),
        ...mapState('accounts', ["me"]),
        ...mapState('util', ['windowWidth', 'windowHeight']),
    },
    async mounted() {
        await this.initializeSchool();
    },
    methods: {
        ...mapActions('auth', ['logoutUser']),
        ...mapActions('auth', ['acceptTermsOfService']),
        ...mapActions("accounts", ["toggleUsageAgreement", "pullMe"]),
        ...mapActions('maintainers', ['getMaintainers', 'confirmMaintainerInitialization']),
        ...mapActions('school', [ 'getSchool', 'postSchool' ]),
         updateShowFirstLoginDialog(value) {
            this.showFirstLoginDialog = value;
            },
        async initializeSchool(){
            const school = await this.getSchool();
            this.schoolId = school._id;
            this.schoolPostal = school.postal;
            this.schoolName = school.name;
            this.schoolStreet = school.street;
            this.schoolCity = school.city;
            this.schoolPhone = school.phone;
            this.schoolEmail = school.email;
            // this.generalDataSecurityOfficer = school.generalDataSecurityOfficer;
            this.principal = school.principal;
            this.schoolDataSecurityOfficer = school.schoolDataSecurityOfficer;
        
        },
        async confirmInitialization(){
            const schoolBody = {
                id: this.schoolId,
                name: this.schoolName,
                street: this.schoolStreet,
                postal: this.schoolPostal,
                city: this.schoolCity,
                email: this.schoolEmail,
                phone: this.schoolPhone,
                // generalDataSecurityOfficer: this.generalDataSecurityOfficer,
                principal: this.principal,
                schoolDataSecurityOfficer: this.schoolDataSecurityOfficer,
            }
            await this.postSchool(schoolBody);
            await this.confirmMaintainerInitialization(this.maintainer._id);
            this.$emit('closeFirstLogin')
        },
}
}
</script>

<style scoped lang="scss">

</style>
