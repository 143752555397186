<template>
    <v-dialog
        v-if="showDetails"
        :model-value="showDetails"
        absolute
        :scrim="false"
        style="z-index: 205"
        max-height="90vh"
        max-width="95vw"
        @update:model-value="updateDialog"
    >
        <v-card
            v-click-outside="()=>{closeDialog();}" 
            style="background-color: white;max-height: 90vh; max-width: 95vw;"
        >
            <v-card-title
                class="px-1 pt-1 pb-0"
                style="background-color:rgb(var(--v-theme-hellgrau))"
            >
                <p style="font-size: 14px; font-weight: bold">
                    Datei - Informationen
                </p>
            </v-card-title>
            <v-card-text class="px-1 py-0">
                <p style="font-weight:bold">
                    Dateiname:
                </p>
                <p>{{ fileToDisplay.uploadedName ? fileToDisplay.uploadedName :'Kein Dateiname gefunden' }}</p>
                <p>
                    <b>Datei-Typ: </b> {{ fileToDisplay.mimetype ? getMimeType(fileToDisplay.mimetype) : 'Nicht gefunden' }} 
                </p>
                <p>
                    <b>Größe: </b> {{ fileToDisplay.size ? getFileSize(fileToDisplay.size) :'Nicht gefunden' }} 
                </p>
                <p v-if="file.createdAt">
                    <b>Erstellungsdatum: </b> {{ fileToDisplay.createdAt ? formatDateTime(fileToDisplay.createdAt) : 'Nicht gefunden' }} 
                </p>
                <p v-if="file.updatedAt">
                    <b>Zuletzt Bearbeitet: </b> {{ fileToDisplay.updatedAt ? formatDateTime(fileToDisplay.updatedAt):'Nicht gefunden' }} 
                </p>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import {mapActions, mapState} from "vuex";
import * as backend from "@/api/backend";
export default {
     name: "FileInformationDialog",
     props: {
        showDetails: { required: true, type: Boolean, default: false },      
        file: { required: true, type: Object },
    },
    emits: ['close', 'update:showDetails'],
    data: () => ({
        fileFromBackend: null,
    }),
    computed:{
        fileToDisplay(){
            return this.fileFromBackend ? this.fileFromBackend : this.file;
        }
    },
    watch:{
        async file(newVal){
            if(newVal.file && newVal.fileAccessToken){
                await this.initFile(newVal);
            }
        }
    },
    async mounted(){
        if(this.file.file){
            await this.initFile(this.file);
        }
    },
    methods: {
        updateDialog(value){
            this.$emit('update:showDetails', value);
        },
         async initFile(file) {

            const fileId = file.file;
            const token = file.fileAccessToken;

            const res = await backend.getFileInformation(fileId, token);
            this.fileFromBackend = await res.json();
            
        },
        formatDateTime(date) {
            let readableDate = '';
            
            if (date !== undefined) {
                readableDate = new Date(date).toLocaleDateString('de-DE', { day: 'numeric', month: 'numeric', year: '2-digit' }) + ' '
                + new Date(date).toLocaleTimeString('de-DE', {
                                                                                hour: '2-digit',
                                                                                minute: '2-digit'
                                                                            })
            }
            return readableDate;
        },
         getFileSize(bytes){
            const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
            let i = 0;

            // Keep dividing the size by 1024 until it's smaller than 1024, and track the unit index
            while (bytes >= 1024 && i < units.length - 1) {
                bytes /= 1024;
                i++;
            }

            // Return the size rounded to 2 decimal places along with the corresponding unit
            return `${bytes.toFixed(2)} ${units[i]}`;
        },
             getMimeType(mimetype) {
        const mimeTypes = {
            'image/jpeg': 'JPEG - Bild',
            'image/jpg': 'JPG - Bild',
            'image/png': 'PNG - Bild',
            'image/gif': 'GIF - Bild',
            'image/bmp': 'BMP - Bild',
            'image/tiff': 'TIFF - Bild',
            'image/heic': 'HEIC - Bild',
            'image/jp2': 'JPEG 2000 - Bild',
            'image/svg+xml': 'SVG - Bild',
            
            'application/pdf': 'PDF - Datei',
            'application/rtf': 'RTF - Dokument',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'Excel - XLSX Datei',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'Word - DOCX Datei',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'PowerPoint - Präsentation',
            'application/vnd.sealed.xls': 'Versiegelte Excel Datei',
            'application/vnd.sealed.ppt': 'Versiegelte PowerPoint Datei',
            'application/vnd.sealed.tiff': 'Versiegelte TIFF Datei',
            'application/vnd.ms-xpsdocument': 'XPS - Dokument',
            'application/xls': 'Excel - XLS Datei',
            'application/x-zip-compressed': 'ZIP - Komprimierte Datei',
            'application/zip': 'ZIP - Komprimierte Datei',
            'application/msword': 'Word - DOC Datei',
            'application/vnd.oasis.opendocument.text': 'OpenDocument - Text Datei',
            'application/vnd.oasis.opendocument.spreadsheet': 'OpenDocument - Tabelle',
            'application/vnd.oasis.opendocument.presentation': 'OpenDocument - Präsentation',
            'application/vnd.oasis.opendocument.graphics': 'OpenDocument - Grafik',
            'application/octet-stream': 'Binäre Datei',

            'audio/mp3': 'MP3 - Audio',
            'audio/mpeg': 'MPEG - Audio',
            'audio/mp4': 'MP4 - Audio',
            'audio/MP4A-LATM': 'MP4A - Audio',
            'audio/asc': 'ASC - Audio',
            'audio/aac': 'AAC - Audio',
            'audio/ogg': 'OGG - Audio',
            'audio/wav': 'WAV - Audio',
            'audio/x-wav': 'WAV - Audio',

            'video/mp4': 'MP4 - Video',
            'video/MP4V-ES': 'MP4V - Video',
            'video/vnd.dece.mp4': 'DECE - MP4 Video',
            'video/vnd.nokia.mp4vr': 'Nokia - MP4 Video',
            'video/vnd.uvvu.mp4': 'UVVU - MP4 Video',
            'video/vnd.sealedmedia.softseal.mov': 'Sealed - MOV Video',
            'video/BMPEG': 'MPEG - Video',
            'video/mpeg4-generic': 'MPEG4 - Video',
            'video/quicktime': 'QuickTime - Video',
            'video/webm': 'WebM - Video',

            'text/csv': 'CSV - Datei',
            'text/rtf': 'RTF - Text',
            'text/plain': 'Einfacher Text',
            'text/vnd.ascii-art': 'ASCII-Art - Text',
            'text/csv-schema': 'CSV - Schema',
        };

        // Return the mapped value, or if not found, the original mimetype
            return mimeTypes[mimetype] || mimetype;
        },
        closeDialog(){
            this.$emit('close')
        }
    }
}
</script>
            