<template>
    <v-dialog
        :model-value="showForceLogoutDialog"
        max-width="400"
        persistent
        @update:model-value="updateValue"
    >
        <v-card>
            <v-card-title
                class="px-4 py-2 d-flex justify-space-between"
                style="background-color: rgb(var(--v-theme-headerblue)); color: white"
            >
                Geräte abmelden
                <img
                    :src="cancelIcon"
                    class="iconToWhite icon30"
                    style="cursor: pointer;"
                    alt="Pin schließen"
                    @click="close(false)"
                >
            </v-card-title>
            <div
                class="px-2 py-1"
                style="font-size: 15px; min-height:80px;"
            >
                Aktuell sind {{ otherConnectedDevices }} {{ otherConnectedDevices > 1 ? 'Geräte' : 'weiteres Gerät' }} mit diesem Nutzer angemeldet. Mit Klick auf 'Rauswerfen' können sie diese abmelden.
            </div>
            <v-card-actions class="d-flex justify-center mb-2">
                <v-btn
                    class="optionBtn text-none ml-2"
                    color="#F5F5F5"
                    @click="close(false)"
                >
                    Abbrechen
                </v-btn>
                <v-spacer />
                <v-btn
                    class="optionBtn text-none ml-2"
                    color="rgb(var(--v-theme-headerblue))"
                    theme="dark"
                    @click="forceLogout()"
                >
                    Rauswerfen
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>  
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import * as backend from '@/api/backend';
import cancelIcon from '@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg';

export default {
 name: "ForceLogout",
    props: {
        currentAccount: {required: true },
        showForceLogoutDialog: {required: true, default: false,},
        otherConnectedDevices:{required: true,},
    },
    emits: ['update:showForceLogoutDialog', 'confirmForceLogout', 'closeForceLogout'], 
    data: () => ({
       cancelIcon,
    }),
    methods:{
        ...mapMutations('auth', ['setPreventLogout']),
    updateValue(value) {
        this.$emit('update:showForceLogoutDialog', value);
        },
    async forceLogout(){
        await this.setPreventLogout(true);
        const res = await backend.forceLogout(this.currentAccount);
        const logout = await res.json();
        if(logout.successful){
            this.close(true);
            await this.setPreventLogout(false);
        }
    },
    close(showSnackbar){
        if(showSnackbar){
            this.$emit('confirmForceLogout');
        }else{
            this.$emit('closeForceLogout');
        }
    }
    }
}
</script>

<style>

</style>