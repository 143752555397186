<template>
    <div style="position: relative">
        <div
            v-if="newFolders.length"
            style="position: fixed; z-index: 9"
        >
            <FileSystemListElement
                :type="'folder'"
                :item="newFolders[0]"
                :folder-id="currentFolderId"
                :folder-type="folderType"
                :vfs="vfs"
                :screen-x="screenX"
                :screen-y="screenY"
                @canceled-editing="cancelFolderCreation"
                @folder-edited="(newFolderCreated) => createFolder(newFolders[0], newFolderCreated)"
            />
        </div>
        <div v-if="patchingFile">
            <FileSystemListElement
                :type="'file'"
                :item="patchingFile"
                :folder-id="currentFolderId"
                :folder-type="folderType"
                :vfs="vfs"
                :screen-x="screenX"
                :screen-y="screenY"
                @canceled-editing="cancelPatchUpload(patchingFile)"
                @folder-edited="patchFolderUpload(patchingFile)"
            />
        </div>
        <!-- Header Row with Folder Path and Space Indicator-->
        <div
            class="px-7 pt-2"
            style="width: 100%"
        >
            <!-- Path -->
            <div style="display: flex; flex-direction: row; flex-wrap: wrap">
                <div
                    class="directoryPath"
                    style="min-height: 25px"
                    tabindex="0"
                    role="button"
                    aria-label="Zurück zum Hauptverzeichnis"
                    @click="initRootDir(true)"
                    @keypress.enter="initRootDir(true)"
                >
                    <img
                        style="height: 24px; padding-bottom: 2px"
                        :src="ordnerHomeStartIcon"
                        alt="Home-Ordner"
                    >
                </div>
                <span
                    :aria-hidden="folderPath.length === 0"
                >/</span>
                <div
                    v-for="(el, index) in folderPath"
                    :key="el._id + index + 'teacher'"
                    style="display: flex; flex-direction: row"
                >
                    <div
                        class="directoryPath"
                        role="button"
                        :aria-label="index === folderPath.length - 1 ? 'Aktueller Hierarchie: ' + el.name : 'Zurück zu ' + el.name"
                        @click="diveInFolderFromHeader(el._id)"
                        @keypress.enter="diveInFolderFromHeader(el._id)"
                    >
                        <img
                            v-if="el.ruleSet === 'teacherFolder'"
                            class="iconToDunkelgrau"
                            style="height: 24px; padding-bottom: 2px"
                            :src="ordnerLehrerIcon"
                            alt="Lernpfad"
                        >
                        <img
                            v-else-if="el.learningPath && el.learningPath.pathVFSOrigin === el._id"
                            class="iconToDunkelgrau"
                            style="height: 24px; padding-bottom: 2px"
                            :src="lernpfadIcon"
                            alt="Lernpfad"
                        >
                        <img
                            v-else-if="el.learningPath && el.learningPath.pathVFSOrigin !== el._id"
                            class="iconToDunkelgrau"
                            style="height: 24px; padding-bottom: 2px"
                            :src="lernpfadStationIcon"
                            alt="LernpfadStation"
                        >
                        <v-icon
                            v-else
                            style="margin-right: -2px; margin-left: 1px"
                        >
                            {{ el.learningPath ? el.learningPath.pathVFSOrigin == el._id ? '' : '' : 'mdi-folder' }}
                        </v-icon>
                        <span
                            style="margin-left: 2px; margin-right: 2px;"
                            :style="`${index === folderPath.length - 1 ? 'text-decoration: underline;': ''}`"
                        >{{ el.name }}</span>
                    </div>
                    <span
                        :aria-hidden="index === folderPath.length - 1"
                    >
                        /
                    </span>
                </div>
            </div>
        </div>
        <div
            v-if="vfs.learningPath && accountRole === 'teacher'"
            style="display: flex; justify-content: flex-end"
        >
            <v-btn
                color="red"
                theme="dark"
                size="small"
                style="z-index: 9; margin-right: 3.5%; text-transform: capitalize"
                @click="clickedEditLearningpathSettings"
            >
                <img
                    :src="stiftIcon"
                    alt="Lernpfad-Einstellungen"
                    class="iconToWhite icon20 mr-1"
                >
                Lernpfad
            </v-btn>
        </div>
        <!-- Default VFS Display -->
        <div
            v-if="!vfs.appointment"
        >
            <div
                v-for="(el, index) in filesAndFolders"
                :key="el._id + index + 'teacher'"
                :ref="`filetile${el._id}`"
                :style="'width: ' + vfsGridWidthPct + '%; float: left'"
                style="position: relative"
            >
                <FileSystemTileElementV2
                    :type="el.type"
                    :index="index"
                    :item="el"
                    :folder-type="folderType"
                    :folder-id="currentFolderId"
                    :screen-x="screenX"
                    :screen-y="screenY"
                    :scroll-top="scrollTop"
                    :debug-mode="debugMode"
                    :vfs="vfs"
                    :view-file-function="() => { $emit('viewFile', el) }"
                    :multiselect-active="multiselectActive"
                    :selected-in-multi-select="!!multiselectedItems.find(item => item === el._id)"
                    :appointment="vfs.appointment || el.appointmentId"
                    @open-folder="() => { diveInFolder(el._id); markLearningPath(el); }"
                    @open-appointment="diveInAppointment"
                    @load-folder="diveInFolder"
                    @open-delete-dialog="() => openDeleteDialog(el)"
                    @started-editing="() => startedEditing(el)"
                    @canceled-editing="canceledEditing(el)"
                    @folder-edited="(newFolderCreated) => folderEdited(el, newFolderCreated)"
                    @download="() => clickDownloadFolderUpload(el)"
                    @notify-copy-file="() => notifyCopyOrMoveFile(el, 'copy')"
                    @notify-move-file="() => notifyCopyOrMoveFile(el, 'move')"
                    @edit-sharing="(item) => { $emit('editSharing', item) }"
                    @edit-learning-path-settings="clickedEditLearningpathSettings"
                    @load-fullscreen-preview="loadFullscreenPreview"
                    @object-u-r-l="(url) => FSLE_URL_Collection.push(url)"
                    @publish-dialog="openPublishDialog(el)"
                    @open-submissions="() => openSubmissions(el)"
                    @update-v-f-s="refreshCurrentFolder"
                    @disable-parent-scroll="(value) => { $emit('disableParentScroll', value) }"
                    @start-multiselect="startMultiselect"
                    @update-multiselect="updateMultiselect"
                    @play-audio="playAudio"
                    @play-video="playVideo"
                />
            </div>
        </div>
        <!-- Appointments in VFS Integration / Hausiwidget -->
        <!-- This is very bloated and should be condensed when the prototype is accepted -->
        <div v-else>
            <div
                v-if="filesAndFolders.length !== 0"
                class="d-flex align-center pa-5"
                style="width: 100%"
            >
                <img
                    :src="materialIcon"
                    style="height: 20px; margin-right: 5px"
                    alt="Material"
                >
                <p style="color: rgb(var(--v-theme-dunkelgrau))">
                    Material:
                </p>
            </div>
            <div
                v-for="(el, index) in filesAndFolders"
                :key="el._id + index + 'teacherFile'"
                :ref="`filetile${el._id}`"
                :style="'width: ' + vfsGridWidthPct + '%; float: left'"
                style="position: relative"
            >
                <FileSystemTileElementV2
                    v-if="el.displayTeacherFile"
                    :type="el.type"
                    :index="index"
                    :item="el"
                    :folder-type="folderType"
                    :folder-id="currentFolderId"
                    :screen-x="screenX"
                    :screen-y="screenY"
                    :scroll-top="scrollTop"
                    :debug-mode="debugMode"
                    :vfs="vfs"
                    :view-file-function="() => { $emit('viewFile', el) }"
                    :appointment="true"
                    @open-folder="() => { diveInFolder(el._id); markLearningPath(el); }"
                    @open-appointment="diveInAppointment"
                    @load-folder="diveInFolder"
                    @open-delete-dialog="() => openDeleteDialog(el)"
                    @started-editing="() => startedEditing(el)"
                    @canceled-editing="canceledEditing(el)"
                    @folder-edited="(newFolderCreated) => folderEdited(el, newFolderCreated)"
                    @download="() => clickDownloadFolderUpload(el)"
                    @notify-copy-file="() => notifyCopyOrMoveFile(el, 'copy')"
                    @notify-move-file="() => notifyCopyOrMoveFile(el, 'move')"
                    @edit-sharing="(item) => { $emit('editSharing', item) }"
                    @load-fullscreen-preview="loadFullscreenPreview"
                    @object-u-r-l="(url) => FSLE_URL_Collection.push(url)"
                    @publish-dialog="openPublishDialog(el)"
                    @open-submissions="() => openSubmissions(el)"
                    @update-v-f-s="refreshCurrentFolder"
                    @disable-parent-scroll="(value) => { $emit('disableParentScroll', value) }"
                    @play-audio="playAudio"
                    @play-video="playVideo"
                />
            </div>
            <div
                v-if="filesAndFolders.length !== 0"
                class="d-flex align-center pa-5"
                style="width: 100%"
            >
                <img
                    :src="abgabenIcon"
                    style="height: 20px; margin-right: 5px"
                    alt="Abgabensymbol"
                >
                <p style="color: rgb(var(--v-theme-dunkelgrau))">
                    Abgaben:
                </p>
            </div>
            <div
                v-for="(el, index) in filesAndFolders"
                :key="el._id + index + 'pupilFile'"
                :ref="`filetile${el._id}`"
                :style="'width: ' + vfsGridWidthPct + '%; float: left'"
                style="position: relative"
            >
                <FileSystemTileElementV2
                    v-if="el.displayPupilFile"
                    :type="el.type"
                    :index="index"
                    :item="el"
                    :folder-type="folderType"
                    :folder-id="currentFolderId"
                    :screen-x="screenX"
                    :screen-y="screenY"
                    :scroll-top="scrollTop"
                    :debug-mode="debugMode"
                    :vfs="vfs"
                    :view-file-function="() => { $emit('viewFile', el) }"
                    :appointment="true"
                    @open-folder="() => { diveInFolder(el._id); markLearningPath(el); }"
                    @open-appointment="diveInAppointment"
                    @load-folder="diveInFolder"
                    @open-delete-dialog="() => openDeleteDialog(el)"
                    @started-editing="() => startedEditing(el)"
                    @canceled-editing="canceledEditing(el)"
                    @folder-edited="(newFolderCreated) => folderEdited(el, newFolderCreated)"
                    @download="() => clickDownloadFolderUpload(el)"
                    @notify-copy-file="() => notifyCopyOrMoveFile(el, 'copy')"
                    @notify-move-file="() => notifyCopyOrMoveFile(el, 'move')"
                    @edit-sharing="(item) => { $emit('editSharing', item) }"
                    @load-fullscreen-preview="loadFullscreenPreview"
                    @object-u-r-l="(url) => FSLE_URL_Collection.push(url)"
                    @publish-dialog="openPublishDialog(el)"
                    @open-submissions="() => openSubmissions(el)"
                    @update-v-f-s="refreshCurrentFolder"
                    @disable-parent-scroll="(value) => { $emit('disableParentScroll', value) }"
                    @play-audio="playAudio"
                    @play-video="playVideo"
                />
            </div>
            <div
                v-if="filesAndFolders.length !== 0"
                class="d-flex align-center pa-5"
                style="width: 100%"
            >
                <p style="color: rgb(var(--v-theme-dunkelgrau))">
                    Korrekturen:
                </p>
            </div>
            <div
                v-for="(el, index) in filesAndFolders"
                :key="el._id + index + 'correctedFile'"
                :ref="`filetile${el._id}`"
                :style="'width: ' + vfsGridWidthPct + '%; float: left'"
                style="position: relative"
            >
                <FileSystemTileElementV2
                    v-if="el.displayCorrectedFile"
                    :type="el.type"
                    :index="index"
                    :item="el"
                    :folder-type="folderType"
                    :folder-id="currentFolderId"
                    :screen-x="screenX"
                    :screen-y="screenY"
                    :scroll-top="scrollTop"
                    :debug-mode="debugMode"
                    :vfs="vfs"
                    :view-file-function="() => { $emit('viewFile', el) }"
                    :appointment="true"
                    @open-folder="() => { diveInFolder(el._id); markLearningPath(el); }"
                    @open-appointment="diveInAppointment"
                    @load-folder="diveInFolder"
                    @open-delete-dialog="() => openDeleteDialog(el)"
                    @started-editing="() => startedEditing(el)"
                    @canceled-editing="canceledEditing(el)"
                    @folder-edited="(newFolderCreated) => folderEdited(el, newFolderCreated)"
                    @download="() => clickDownloadFolderUpload(el)"
                    @notify-copy-file="() => notifyCopyOrMoveFile(el, 'copy')"
                    @notify-move-file="() => notifyCopyOrMoveFile(el, 'move')"
                    @edit-sharing="(item) => { $emit('editSharing', item) }"
                    @load-fullscreen-preview="loadFullscreenPreview"
                    @object-u-r-l="(url) => FSLE_URL_Collection.push(url)"
                    @publish-dialog="openPublishDialog(el)"
                    @open-submissions="() => openSubmissions(el)"
                    @update-v-f-s="refreshCurrentFolder"
                    @disable-parent-scroll="(value) => { $emit('disableParentScroll', value) }"
                    @play-audio="playAudio"
                    @play-video="playVideo"
                />
            </div>
        </div>
        <div
            v-if="filesAndFolders.length === 0"
            class="text-center"
            style="width: 100%; padding-top: 11vh"
        >
            <img
                :src="keineOrdnerIcon"
                alt="Keine Ordner"
                style="height: 100px"
            >
            <p
                class="text-center"
                style="font-size: 20px"
            >
                Dieser Ordner ist leer
            </p>
        </div>
        <div
            :class="filesAndFolders.length === 0 ? 'd-flex' : ''"
            :style="filesAndFolders.length === 0 ? 'justify-content: center' : ''"
        >
            <div
                class="pa-1"
                :style="'width: ' + vfsGridWidthPct + '%; float: left'"
            >
                <FileTileDragDropTarget
                    v-if="isDragDropTargetEnabled"
                    :list-view="vfsGridWidthPct<100 ? false : true"
                    :small-view="vfsGridWidthPct>50 ? false : true"
                    :learning-path="isDragDropLearningPath"
                    @files-drop="dropItLikeItsHot"
                    @normal-upload="() => { emitNormalUpload() }"
                    @edit-sharing="() => { $emit('editSharing') }"
                />
            </div>
        </div>

        <!-- popups, overlays and snackbars -->
        <FileWidgetElementDeleteDialog
            v-if="deleteDialog"
            :vfs="vfs"
            :items="itemsToDelete"
            @delete-complete="deleteComplete"
            @close="() => { deleteDialog = false; }"
        />

        <!-- Publishing dialog -->
        <PublishDocumentToDocRegDialog
            v-if="showPublishDialog"
            :document-details="documentToPublish"
            @close="() => { showPublishDialog = false; documentToPublish = null; }"
        />
        <v-dialog
            v-if="audioUrl"
            scrim="”rgba(0,0,0,0.9)”"
            :model-value="audioUrl"
            z-index="95"
            max-width="90%"
            width="unset"
            @click:outside="audioUrl = null;"
        >
            <v-row
                justify="end"
                align="end"
            >
                <v-col
                    cols="1"
                    align-self="end"
                >
                    <v-icon
                        size="large"
                        theme="dark"
                        @click="audioUrl = null;"
                    >
                        fas fa-times
                    </v-icon>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="auto">
                    <audio
                        id="audioPlayer"
                        :src="audioUrl"
                        controls
                        :width="windowWidth > 900 ? '80%' : '40%'"
                    >
                        Your browser does not support the video tag.
                    </audio>
                </v-col>
            </v-row>
            <!--            <v-icon alt="Audio schließen" tabindex="2" large style="position: fixed; top: 50px; right: 50px; z-index: 99" @click="audioUrl = false" dark>-->
            <!--                fas fa-times-->
            <!--            </v-icon>-->
            <!--            <audio id="audioPlayer" alt="" tabindex="1" :src="audioUrl" controls :width="windowWidth > 900 ? '80%' : '40%%'"-->
            <!--                   :style="{ marginLeft: windowWidth > 900 ? '10%' : '10px' }">-->
            <!--                Your browser does not support the video tag.-->
            <!--            </audio>-->
        </v-dialog>
        <v-dialog
            v-if="videoUrl"
            :model-value="videoUrl"
            z-index="95"
            scrim="”rgba(0,0,0,0.9)”"
            max-width="90%"
            width="unset"
            @click:outside="videoUrl = null"
        >
            <v-row
                justify="end"
                align="end"
            >
                <v-col
                    cols="1"
                    align-self="end"
                >
                    <v-icon
                        size="large"
                        theme="dark"
                        @click="videoUrl = null"
                    >
                        fas fa-times
                    </v-icon>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="auto">
                    <video
                        id="videoPlayer"
                        :src="videoUrl"
                        controls
                    >
                        Your browser does not support the video tag.
                    </video>
                </v-col>
            </v-row>
        </v-dialog>
        <FilePreviewDialog
            v-if="fullscreenPreviewEnabled"
            ref="previewDialog"
            :files="this.vfs.files"
            :preview-selection="previewSelection"
            :preview-information="previewSelection"
            :enable-fullscreen-preview="fullscreenPreviewEnabled"
            :enable-editing="editorAccessible"
            :show-submissions="folderType === 'groupFolder' && validMimetype && isTask(previewSelection) && accountRole === 'teacher'"
            :audio-compatible="!previewSelection.ghostElement && isCurrentPreviewAudioCompatible"
            :vfs-usage="!previewSelection.ghostElement"
            :usage="'file'"
            @close="resetFilePreview"
            @download="clickDownloadFolderUpload(previewSelection)"
            @cycle-prev="cyclePreview(-1)"
            @cycle-next="cyclePreview(1)"
            @open-editor="openEditor"
            @open-libre="openLibreEditor(previewSelection)"
            @open-submissions="openSubmissions(previewSelection)"
            @play-audio="playAudio(previewSelection)"
            @notify-copy-or-move-file="notifyCopyOrMoveFile(previewSelection, 'copy')"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

import { allowedMimeTypesForLibre, allowedMimeTypesForAudioPlayer, allowedMimeTypesForEditor} from "../../constants/allowedMimeTypes";
import herunterladenIcon from "@/assets/Icons/FaceliftIcons/herunterladen.svg";
import ordnerHomeStartIcon from '@/assets/Icons/FaceliftIcons/ordner_home_start.svg';
import lernpfadIcon from '@/assets/Icons/FaceliftIcons/Lernpfad.svg';
import lernpfadStationIcon from '@/assets/Icons/FaceliftIcons/Lernpfad_Station.svg';
import stiftIcon from '@/assets/Icons/FaceliftIcons/stift_bearbeiten_.svg';
import materialIcon from "@/assets/Icons/FaceliftIcons/material_dateien.svg";
import abgabenIcon from "@/assets/Icons/FaceliftIcons/hausis.svg";
import ordnerLehrerIcon from '@/assets/Icons/FaceliftIcons/ordner_lehrer.svg';
import musikIcon from "@/assets/Icons/FaceliftIcons/f-musik.svg";
import keineOrdnerIcon from '@/assets/Icons/FaceliftIcons/keine_ordner.svg';
import img_eklaraLogo from '@/assets/EditorLibraryMock/eklara_logo.png';
import FileSystemListElement from "@/components/FileArchive/FileSystemListElement";
import PublishDocumentToDocRegDialog from "@/components/FileArchive/PublishDocumentToDocRegDialog";
import FileSystemTileElementV2 from "@/components/FileArchive/FileSystemTileElementV2";
import FileTileDragDropTarget from "@/components/FileArchive/FileTileDragDropTarget";
import FileWidgetElementDeleteDialog from "@/components/FileArchive/FileWidgetElementDeleteDialog";
import {generateThumbnailForPdf} from "@/util/tempThumbnailGen";
import * as backend from "@/api/backend";
import EventBus from '../../util/eventBus.js'
import {getVFSUploadThumbnail} from "../../api/backend";
import FilePreviewDialog from "../Utils/FilePreviewDialog";

export default {
    name: "VirtualFileSystem",
    components: {
        FilePreviewDialog,
        FileTileDragDropTarget,
        FileSystemTileElementV2,
        PublishDocumentToDocRegDialog,
        FileSystemListElement,
        FileWidgetElementDeleteDialog,
    },
    props: {
        folderType: { required: true, type: String },
        folderDepth: { required: true, type: Number },
        isStorageFull: { required: false, type: Boolean, default: false },
        refreshToggle: { required: false, type: String, default: '' },
        uploadLocalFileFunction: { required: false, type: Function },
        uploadFileFromSharedFunction: { required: false, type: Function },
        uploadFileFromPrivateFunction: { required: false, type: Function },
        scrollTop: { required: true, type: Number, default: 0 },
        searchResult: { required: false, type: Object },
        activeFilters: { required: false, type: Array },
        initalFolderIdTree: { required: false, type: Array },
        submissionToOpen: { required: false, type: Object },
        triggerRefresh: { required: false, type: Boolean, default: false },
        sortByDate: { required: false, type: Boolean, default: false },
    },
    data: () => ({
        folders: [],
        newFolders: [], // For displaying newly created Folders in the same list
        files: [],
        folderIdTree: [],
        faecherFolders: [],
        rootVfs: null,
        currentFolderId: "",
        folderName: "",
        vfs: {},
        babyView: false,
        teachers: [],
        me: null,

        replaceFolderFound : false,

        //Libre
        libreDocId: '',
        libreAccessToken: '',
        libreFile: null,

        // schoolSubjectAppointment integration
        subjects: null,
        appointments: null,
        slots: null,
        monthNames: ["Januar", "Februar", "März", "April", "Mai", "Juni",
            "Juli", "August", "September", "Oktober", "November", "Dezember"],
        // dayNames: ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"],
        deleteDialog: false,
        itemsToDelete: [],
        elementToDelete: null,
        deleteMethod: '',
        deleteInfoText: '',
        forceUpdate: 0,

        patchingFile: null,
        sortAlphabeticalDescending: false,
        displayingSearchResult: false,
        validMimetype: true,
        previewPDF: false,
        currentAppointmentId: '',

        traveledFromPath: false,

        fullscreenPreviewEnabled: false,
        previewSelection: { name: 'Vorschau' },
        previewSelectionIndex: 0,
        previewURL: null,
        isPreviewFileAppointmentUpload: false,
        isCurrentPreviewLibreCompatible: false,
        isCurrentPreviewAudioCompatible: false,
        isCurrentPreviewEditorCompatible: false,

        FSLE_URL_Collection: [],

        // Publishing Stuff
        documentToPublish: null,
        showPublishDialog: false,
        debugMode: false,

        // multiselected
        multiselectedItems: [],
        multiselectActive: false,

        audioUrl: '',
        videoUrl: '',

        allowedMimeTypesForLibre,
        allowedMimeTypesForAudioPlayer,
        allowedMimeTypesForEditor,

        //  Icons
        herunterladenIcon,
        ordnerHomeStartIcon,
        img_eklaraLogo,
        lernpfadIcon,
        lernpfadStationIcon,
        stiftIcon,
        ordnerLehrerIcon,
        materialIcon,
        abgabenIcon,
        musikIcon,
        keineOrdnerIcon,

        screenX: 1920,
        screenY: 1080,

        // performance measurement
        // t0: 0,
        // t1: 0,
        // t2: 0,
        sortByDateAscending: false,
        sortByDateDescending: false,

    }),
    computed: {
        ...mapState("auth", ["token"]),
        ...mapState("vfs", ["reloadVfs"]),
        ...mapGetters('auth', ['accountRole', 'accountId']),
        ...mapGetters('vfs', ['privateFolderSortDescending', 'teacherFolderSortDescending', 'groupFolderSortDescending','groupFolderSortDate', 'vfsGridWidthPct']),
        ...mapState('util', ['windowWidth']),
        ...mapGetters("groups", ['groupsByLeader']),

        shownItems() {
            return this.folders;
        },
        folderPath() {
            let path = [];
            for (let i = 1; i < this.folderIdTree.length; i++) {
                path.push(this.folderIdTree[i]);
            }
            return path;
        },
        filesAndFolders() {
            let all = [];
            let folders = this.folders ? [...this.folders] : [];
            let files = this.files ? [...this.files] : [];
            let drafts = this.vfs && this.vfs.drafts ? [...this.vfs.drafts] : null;
            this.forceUpdate;

            function compareStations(learningPath, a, b) {
                const stationA = learningPath.stations.find((station) => station.vfs == a._id);
                const stationB = learningPath.stations.find((station) => station.vfs == b._id);
                if (!stationA || !stationB) {
                    return 0;
                }
                return stationA.order == stationB.order ? 0 : stationA.order > stationB.order ? 1 : -1;
            }

            if (!this.displayingSearchResult) {
                if (this.vfs.learningPath ? this.vfs.learningPath.pathVFSOrigin == this.vfs._id : false) {
                    // custom sorting: use learningPath.stations to set order
                    folders.sort((a, b) => {
                        return compareStations(this.vfs.learningPath, a, b);
                    });
                    const filteredFolders = [];
                    let addFolder = true;
                    let addGhostFolder = false;
                    let blockAddGhostFolder = false;
                    let invisibleUntil;
                    // hide stations depending on settings
                    if (this.accountRole === 'pupil') {
                        for (let i = 0; i < folders.length; i++) {
                            const station = this.vfs.learningPath.stations.find((station) => station.vfs == folders[i]._id);
                            if (i >= 1) {
                                // check the previous folder and see if all tasks contain a submission by this account
                                if (folders[i-1].sharing_Tasks) {
                                    const submittedTasks = folders[i-1].sharing_Tasks.map((task) => {
                                        if (task.activeTask) {
                                            const foundSubmission = task.submissions.find((el) => {
                                                return el.account == this.accountId;
                                            });
                                            return !!foundSubmission;
                                        } else {
                                            // task is not active anymore, consider it 'solved' so pupil cannot be stuck
                                            return true;
                                        }
                                    });
                                    if (submittedTasks.filter((el) => el == true).length === folders[i-1].sharing_Tasks.length) {
                                        // solved all tasks, set to visible
                                        filteredFolders[i-1].stationCompleted = true;
                                        if (station.revealedByPrevious) {
                                            addFolder = true;
                                        }
                                    } else {
                                        if (station.revealedByPrevious) {
                                            // did not solve all tasks yet, set to hidden
                                            addFolder = false;
                                            addGhostFolder = true;
                                        }
                                    }
                                } else {
                                    filteredFolders[i-1].stationCompleted = true;
                                    if (station.revealedByPrevious) {
                                        // no tasks in this station, set to visible
                                        addFolder = true;
                                    }
                                }
                            } else {
                                if (station.revealedByPrevious) {
                                    // first station is not revealed by previous, set to visible
                                    addFolder = true;
                                }
                            }
                            if (station.revealedByDate) {
                                const current = new Date();
                                invisibleUntil = new Date(station.revealedByDate);
                                if (current < invisibleUntil) {
                                    addFolder = false;
                                    addGhostFolder = true;
                                }
                            } else if (!station.revealedByDate && !station.revealedByPrevious) {
                                // no revealedBy states are set -> implicitly always visible
                                addFolder = true;
                            }
                            if (addFolder) {
                                filteredFolders.push(folders[i]);
                            } else if (addGhostFolder && !blockAddGhostFolder) {
                                blockAddGhostFolder = true;
                                addGhostFolder = false;
                                let ghostFolder = {
                                    _id: `${folders[i]}_ghostFolder`,
                                    name: station.revealedByPrevious ? 'Bearbeite zuerst alle Aufgaben' : `Ab dem ${invisibleUntil.getDay()}.${invisibleUntil.getMonth()}.${invisibleUntil.getFullYear()}`,
                                    version: 2,
                                    root: false,
                                    files: [],
                                    folders: [],
                                    owner: folders[i].owner,
                                    parentFolder: folders[i].parentFolder,
                                    rootFolder: folders[i].rootFolder,
                                    ghostElement: true,
                                    learningPath: folders[i].learningPath,
                                }
                                filteredFolders.push(ghostFolder);
                            }
                        }
                        folders = filteredFolders;
                    }
                } else {
                    if(this.sortByDateAscending){
                        folders.sort(function (a, b) {
                            const dateA = new Date(a.updatedAt) || new Date();
                            const dateB = new Date(b.updatedAt) || new Date();

                            return dateA - dateB;
                        });
                        files.sort(function (a, b) {
                            const dateA = new Date(a.updatedAt) || new Date();
                            const dateB = new Date(b.updatedAt) || new Date();

                            return dateA - dateB;
                        }); 
                    }else if(this.sortByDateDescending){
                       folders.sort(function (a, b) {
                            const dateA = new Date(a.updatedAt) || new Date();
                            const dateB = new Date(b.updatedAt) || new Date();

                            return dateB - dateA;
                        });
                        files.sort(function (a, b) {
                            const dateA = new Date(a.updatedAt) || new Date();
                            const dateB = new Date(b.updatedAt) || new Date();

                            return dateB - dateA;
                        }); 
                    }else{

                    // default sorting: alphabetically
                    folders.sort((a, b) => {
                        a = a.name.toLowerCase();
                        b = b.name.toLowerCase();
                        if (this.sortAlphabeticalDescending) {
                            return (a < b) ? 1 : (a > b) ? -1 : 0;
                        } else {
                            return (a < b) ? -1 : (a > b) ? 1 : 0;
                        }
                    })
                    files.sort((a, b) => {
                        a = (a.originalname || a.name || a.title).toLowerCase();
                        b = (b.originalname || b.name || b.title).toLowerCase();
                        if (this.sortAlphabeticalDescending) {
                            return (a < b) ? 1 : (a > b) ? -1 : 0;
                        } else {
                            return (a < b) ? -1 : (a > b) ? 1 : 0;
                        }
                    })
                    }
                }
            }

            for (let i = 0; i < folders.length; i++) {
                folders[i].type = 'folder';
                all.push(folders[i]);
            }

            for (let i = 0; i < files.length; i++) {
                files[i].type = 'file';
                if (files[i].uploadedName) {
                    // add corrected to accomodate filter function
                    if (files[i].uploadedName.includes('Korrigiert')) {
                        files[i].corrected = true;
                    }
                }
                // add todo to accomodate filter function
                if (this.vfs.sharing_Tasks) {
                    if (this.vfs.sharing_Tasks.find((el) => {
                        return files[i]._id == (el.file ? el.file._id ? el.file._id : el.file : false) && el.activeTask && !el.submissions.find((sub) => sub ?
                            sub.account : false == this.accountId);
                    })) {
                        files[i].todo = true;
                    }
                }
                all.push(files[i]);
            }

            if (drafts && drafts.length) {
                all = all.concat(drafts.map(draftEntry => {
                    draftEntry.draft.type = 'draft'
                    return draftEntry.draft;
                }));
            }

            if (this.folderType === 'privateFolder') {
                this.setPrivateFolderSelection(all.length);
            } else if (this.folderType === 'teacherFolder') {
                this.setTeacherFolderSelection(all.length);
            } else if (this.folderType === 'groupFolder') {
                this.setGroupFolderSelection(all.length);
            }

            // use active filters
            if (this.activeFilters.length) {
                for (let i = 0; i < this.activeFilters.length; i++) {
                    all = all.filter((el) => this.activeFilters[i].filterFunction(el, this.accountId));
                }
            }

            return all;
        },
        isDragDropLearningPath() {
            return this.vfs.learningPath ? this.vfs.learningPath.pathVFSOrigin == this.vfs._id : false;
        },
        isDragDropTargetEnabled() {
            if (this.vfs.rulesetTask) {
                return false;
            }
            if (this.accountRole === 'teacher' && !this.vfs.rulesetAppointment) {
                return true;
            } else if (this.accountRole === 'pupil') {
                // check if pupil is owner
                if (!this.vfs) {
                    return false;
                }
                return this.vfs.owner === this.accountId;
            }
            return false;
        },
        rootFolderBadges() {
            let eventList = [];

            const calculateEventList = (folder) => {
                if (folder.files ? folder.files.length > 0 : false) {
                    folder.files.forEach(file => {
                        if (!file.seenBy && file.seenByMe) {
                            return;
                        }
                        if (file.seenBy && !file.seenBy.includes(this.accountId)) {
                            const obj = {
                                folder: folder,
                                target: file,
                            };
                            eventList.push(obj);
                        } else if (!file.seenBy && !file.seenByMe && file.uploader !== this.accountId) {
                            const obj = {
                                folder: folder,
                                target: file,
                            };
                            eventList.push(obj);
                        }
                    });
                }
                if (folder.folders ? folder.folders.length > 0 : false) {
                    folder.folders.forEach(folderIt => {
                        if (folderIt.learningPath) {
                            if(!folderIt.learningPath.seenBy) return;
                            if (!folderIt.learningPath.seenBy.includes(this.accountId)) {
                                const obj = {
                                    folder: folder,
                                    target: folderIt,
                                };
                                eventList.push(obj);
                            }
                        } else {
                            calculateEventList(folderIt);
                        }
                    });
                }
            };

            calculateEventList(this.vfs);

            return eventList;
        },
        editorAccessible() { // For FilePreviewDialog.vue
            return (this.isCurrentPreviewEditorCompatible && !this.previewSelection.ghostElement && (this.accountRole === 'teacher' || this.previewSelection.owner === this.accountId))
                || (this.folderType === 'groupFolder' && this.validMimetype && this.isTaskActive(this.previewSelection) && this.accountRole === 'pupil')
                || (!this.previewSelection.ghostElement && this.isCurrentPreviewLibreCompatible);
        }
    },
    watch: {
      async fullscreenPreviewEnabled(newVal, oldVal)  {
          if (newVal) {
             let compatible = this.allowedMimeTypesForLibre.includes(this.previewSelection.mimetype);
             this.isCurrentPreviewLibreCompatible = compatible;
             compatible = this.allowedMimeTypesForAudioPlayer.includes(this.previewSelection.mimetype);
             this.isCurrentPreviewAudioCompatible = compatible;
             compatible = this.allowedMimeTypesForEditor.includes(this.previewSelection.mimetype);
             this.isCurrentPreviewEditorCompatible = compatible;
          }
      },
        groupFolderSortDescending: {
            handler(val, oldVal) {
                if (this.folderType === 'groupFolder') {
                    this.sortAlphabeticalDescending = this.groupFolderSortDescending;
                    this.sortByDateDescending = false;
                    this.sortByDateAscending = false;
                }
            }
        },
        // this is not done well since reloadVFS already covers the necessary functionality
        // it just didnt work in my case and I need a quick solution to desynching while manipulating vfs
        async triggerRefresh(newVal, oldVal) {
            if (newVal != oldVal) {
                await this.refreshCurrentFolder();
            }
        },

        async reloadVfs(newVal, oldVal) {
            if (newVal && !oldVal) {
                await this.refreshCurrentFolder();
            }
        },

        searchResult() {
            if (this.displayingSearchResult) {
                this.leaveCurrentFolder();
            } else {
                this.$emit('mutateFolderDepth', this.folderDepth + 1);
            }
            this.displayingSearchResult = true;
            this.vfs = this.searchResult;
            this.vfs.name = 'Suche';
            this.folderIdTree.push(this.vfs);
            this.folders = this.vfs.folders;
            this.files = this.vfs.files;
            // dont parse a new folder because we navigate towards a temporary folder
            // all actions should pretend to be in previous folder
        },
        folderDepth(newVal, oldVal) {
            if (newVal - oldVal === -1) {
                if (this.traveledFromPath) {
                    this.traveledFromPath = false;
                    return;
                }
                this.leaveCurrentFolder();
                this.softCancelFolderCreation();
            }
        },
        submissionToOpen: {
            deep: true,
            handler() {
                const matchingFile = this.vfs.files.find((file) => this.submissionToOpen.fileId == file._id);
                if (matchingFile) {
                    this.openSubmissions(matchingFile, this.submissionToOpen.accountId);
                }
            }
        },
        sortByDate(newVal, oldVal){
            if(newVal !== oldVal){
                if(newVal === true && oldVal === false){

                    this.sortByDateAscending = true;
                    this.sortByDateDescending = false;
                }else{
                     this.sortByDateAscending = false;
                    this.sortByDateDescending = true;
                }
            }
        }
    },
    async beforeMount() {
        if (this.accountRole === 'teacher') {
            this.teachers = await this.getTeachers();
            this.me = this.teachers.find((teacher) => teacher.account === this.accountId);
        }
    },
    async mounted() {
        const t0 = performance.now();

        if(this.accountRole === 'teacher'){
            this.appointments = await this.getTeacherAppointments();
        }else{
            this.appointments = await this.getAppointments();
        }
        this.slots = await this.getSlots();
        await this.queryCheck();
        this.processAppointments();
        this.evalScreenSize();
        this.debugMode = this.$route.hash === '#debug';
        if (this.accountRole === 'pupil') {
            let temp = await this.getMePupil();
            this.babyView = temp.babyView;
        }
        if (this.accountRole === 'teacher') {
            this.subjects = await this.getSubjects();
        }
        await this.initRootDir(true);
        this.rootVfs = this.vfs;
        this.$emit('setRootVFS', this.rootVfs);

        EventBus.off('fetchFolders');
        EventBus.on('fetchFolders', async () => {
            await this.refreshCurrentFolder();
        });
        EventBus.off('vfs-new');
        EventBus.on('vfs-new', (body) => {
            const { parentFolder, isRoot, newFolder } = body;
            if (this.vfs._id === parentFolder) {
                this.folders.push(newFolder);
            }
        });
        EventBus.off('vfs-delete');
        EventBus.on('vfs-delete', (body) => {
            const { parentFolderId, folderId, isRoot } = body;
            if (this.vfs._id === parentFolderId) {
                this.folders = this.folders.filter((folder) => folder._id !== folderId);
            }
            // console.log(this.folders.filter((folder) => folder._id == folderId).length > 0 ? 'found folder with matching id' : 'did not match folderId');
        });
        EventBus.off('vfs-new-upload');
        EventBus.on('vfs-new-upload', (body) => {
            const { newFile, folderId } = body;
            const folder = this.findFolderInVfs(folderId, this.vfs);
            if (!folder) return;
            folder.files.push(newFile);
            this.$emit('badgeContentChanged', this.rootFolderBadges);
        });
        EventBus.off('vfs-delete-upload');
        EventBus.on('vfs-delete-upload', (body) => {
            const { fileId, folderId } = body;
            const folder = this.findFolderInVfs(folderId, this.vfs);
            if (!folder) return;
            folder.files = folder.files.filter((file) => file._id !== fileId);
            if (folder._id === this.vfs._id) {
                this.files = this.files.filter((file) => file._id !== fileId);
            }
            this.$emit('badgeContentChanged', this.rootFolderBadges);
        });
        EventBus.off('vfs-update-folder');
        EventBus.on('vfs-update-folder', (body) => {
            this.replaceFolder(body);
            this.$emit('badgeContentChanged', this.rootFolderBadges);
        });
        const t1 = performance.now();

        console.debug(`VFS Mounted in: ${t1 - t0} ms.`);
    },
    methods: {
        ...mapActions("virtualFileSystem", [
            "getVFSFolderUploadsV2",
            "getVFSFolderUploadV2",
            "createVFSFolderV2",
            "deleteVFSFolderUploadV2",
            "updateVFSFolderV2",
            "updateVFSFolderUploadV2",
            "deleteVFSFolderV2",
            'postVFSFolderUploadV2',
            'getVFSSharingV2',
            'markFileSeen',
            'markLearningPathSeen',
            'getFileBlob'
        ]),
        ...mapMutations('vfs', ['setGroupFolderSelection', 'setVfs', 'setOriginalFileIdForVFSDraft', 'setLibreIsTask', 'setLibreFileId']),
        ...mapActions('subjects', ['getThumbnail', 'getSubjects']),
        ...mapMutations('snackbar', [ 'showSnackbar' ]),
        ...mapActions("appointments", ["getAppointments","getTeacherAppointments"]),
        ...mapActions('mimeTypes', ['getFileExtension']),
        ...mapActions("timeslots", ["getSlots"]),
        ...mapActions('auth', ['getCurrentAccount']),
        ...mapActions("pupils", ["getMePupil"]),
        ...mapActions("files", ["safeNewThumbnail"]),
        ...mapActions("teachers", ["getTeachers"]),

        findFolderInVfs(folderId, vfs) {
            const findFolderRecursive = (currentVFS) => {
                if (currentVFS._id === folderId) {
                    return currentVFS;
                }
                if (!currentVFS.folders || !currentVFS.folders.length) {
                    return;
                }
                const found = currentVFS.folders.find((currentFolder) => {
                    if (currentFolder._id === folderId) {
                        return true;
                    } else {
                        return findFolderRecursive(currentFolder);
                    }
                });
                return found;
            }

            return findFolderRecursive(vfs);
        },
        async markLearningPath(folder) {
          if (folder.learningPath ? folder.learningPath.seenBy ? false : true : true) {
              return;
          }
          folder.learningPath.seenBy.push(this.accountId);
          await this.markLearningPathSeen(folder.learningPath._id);
          this.$emit('badgeContentChanged', this.rootFolderBadges);
        },
        async playAudio(preview) {
            this.audioUrl = await this.getFileBlob(preview);
        },
        async playVideo(preview) {
            this.videoUrl = await this.getFileBlob(preview);
        },
        async openLibreEditor(filePreview) {
            if (this.isTaskActive(filePreview) && this.accountRole !== 'teacher') {
                let res = await backend.getCopyOfFile(this.previewSelection._id, this.previewSelection.accessToken);
                const copyOfFile = await res.json();
                filePreview = copyOfFile;
                this.setLibreFileId(filePreview._id);
                this.setLibreIsTask(true);
            }
            let fileAccessToken = filePreview.accessToken;
            const libreDoc = await backend.getLibreDocFromFile(filePreview._id);
            const libreDocJson = await libreDoc.json();
            this.libreDocId = libreDocJson.libreDoc._id;
            this.libreAccessToken = libreDocJson.accessToken;
            const fileType = await this.getFileExtension(filePreview.mimetype);
            const query = {
                ...this.$route.query,
                fileId: filePreview._id,
                libreDocId: this.libreDocId,
                accessToken: this.libreAccessToken,
                fileAccessToken,
                isTemplate: false,
                fileType: fileType,
                mode: 'default',
            };

            await this.$router.push({
                name: 'libreoffice',
                query,
            });
        },
        //#Region Query Check for Widget State
        async queryCheck() {
            // Check open Folder
            if (this.$route.query.fwfolder) {
                // Check if folder is valid id
                const folderId = this.$route.query.fwfolder;
                if (folderId.match(/^[0-9a-fA-F]{24}$/)) {
                    await this.loadFolderFromQuery(folderId);
                }
            }
            // Check open Task
            // When is a task declared open
            if (this.$route.query.fwtask) {
                // Check if task is valid id
                const task = this.$route.query.fwtask;
                if (task.match(/^[0-9a-fA-F]{24}$/)) {
                    // check if the current folder has the task
                    let neededFolder = {};
                    if (this.vfs && this.vfs.files) {
                        neededFolder = this.vfs;
                    } else {
                        // Find correct folder
                        // neededFolder = this.findFolderToTask(task)
                    }
                    const completeTask = neededFolder.files.find(file => file._id === task)
                    if (completeTask) {
                        // Pull current folder again for complete tasks
                        // TODO Fix in Backend to get submissions correctly for task on request without folderId
                        const currentFolder = await this.getVFSFolderUploadsV2(
                            {
                                folderType: this.folderType,
                                folderId: this.currentFolderId
                            });
                        this.vfs.sharing_Tasks = currentFolder.sharing_Tasks;
                        // teacher: open task entries
                        this.openSubmissions(completeTask);
                    }
                }
            }
        },
        async loadFolderFromQuery(folderId) {
            // load folder path
            let rootFolder = this.vfs;
            const newFolderPath = [];
            if (!this.vfs.root) {
                // Pull root folder
                rootFolder = await this.getVFSFolderUploadsV2({ folderType: this.folderType });
                this.vfs = rootFolder;
            }
            const findFolder = (parentFolder) => {
                for (let i = 0; i < parentFolder.folders.length; i++) {
                    const curr = parentFolder.folders[i];
                    if (curr._id === folderId) {
                        newFolderPath.push(curr);
                        return curr;
                    }
                    const checkFolder = findFolder(curr);
                    if (checkFolder) {
                        newFolderPath.push(curr);
                        return checkFolder;
                    }
                }
                // Only if folder doesnt exist
                return null;
            };
            const wantedFolder = findFolder(this.vfs);
            if (!wantedFolder) {
                await this.initRootDir(true);
                return;
            }
            newFolderPath.reverse();
            // load folder
            this.vfs = wantedFolder;
            this.folderIdTree = this.folderIdTree.concat(newFolderPath);
            this.folders = wantedFolder.folders;
            this.files = wantedFolder.files;
            this.currentFolderId = wantedFolder._id;
            await this.notifyFolderSelection(newFolderPath.length + 1);
        },
        //#endregion Query Check for Widget State

        //#region Multiselect VFS
        /**startMultiselect Method, to initialize the multiselect prozess
         * returns nothing
         */
        startMultiselect(firstItem) {
            // init list
            this.multiselectedItems = []
            // change style
            this.multiselectActive = true; // Think about changing this to computed
            this.updateMultiselect(firstItem)
            // check for permissions (what should permissions even be?)

            // tell filewidget to get user some options
            this.$emit('mutliselectStarted');
        },
        updateMultiselect(itemId) {
            const itemIndex = this.multiselectedItems.indexOf(itemId);
            if (itemIndex > -1) {
                this.multiselectedItems.splice(itemIndex, 1)
            } else {
                this.multiselectedItems.push(itemId)
            }

            if (this.multiselectedItems.length === 0) {
                this.stopMultiselect()
            }
        },
        async getFilesFromMultiselectIds() {
            const ids = this.multiselectedItems
            return this.filesAndFolders.filter(item => !!ids.find(id => item._id === id))
        },
        // eslint-disable-next-line
        async prepareMultiselectDelete() {
            this.itemsToDelete = await this.getFilesFromMultiselectIds();
            this.deleteDialog = true;
        },
        stopMultiselect() {

            this.multiselectActive = false; // Think about changing this to computed
            this.multiselectedItems = []
            // tell filewidget to get outta here
            this.$emit('mutliselectStop')
        },
        //#endregion Multiselect VFS

        folderEdited(el, newFolderCreated) {
            if (el.type === 'file') {
                this.patchFolderUpload(el);
            } else if (el.type === 'folder') {
                this.createFolder(el, newFolderCreated);
            }
        },

        canceledEditing(el) {
            if (el.type === 'file') {
                this.cancelPatchUpload(el);
            } else if (el.type === 'folder') {
                this.cancelFolderCreation(el)
            }
        },

        startedEditing(el) {
            if (el.type === 'file') {
                this.startPatchUpload(el);
            } else if (el.type === 'folder') {
                this.switchUIToEditMode(el)
            }
        },

        async replaceFolder(body) {
            body = body.updatedFolder;
            this.replaceFolderFound = false;
            if (body._id == this.vfs._id) {
                this.vfs = body;
                this.folders = this.vfs.folders;
                this.files = this.vfs.files;
                this.replaceFolderFound = true;
            } else {
                this.vfs = await this.replaceFolderRecursion(body, this.vfs);
                this.folders = this.vfs.folders;
                this.files = this.vfs.files;
            }
            // if we did not find a folder to replace but received the websocket event, refresh currentFolder
            if (!this.replaceFolderFound) {
                await this.refreshCurrentFolder();
            }
            this.forceUpdate++;
        },

        async replaceFolderRecursion(newFolder, vfs) {
            if (vfs.folders && vfs.folders.length) {
                vfs.folders = await Promise.all(vfs.folders.map(async (folder) => {
                    if (folder._id == newFolder._id) {
                        this.replaceFolderFound = true;
                        return newFolder;
                    } else {
                        folder.folders = await this.replaceFolderRecursion(newFolder, folder);
                        return folder;
                    }
                }));
            }
            return vfs;
        },

        async openSubmissions(el, accountId) {
            this.$emit('mutateFolderDepth', this.folderDepth + 1);
            let taskFolder = this.vfs.sharing_Tasks.find(task => (task.file === el._id) || (task.file._id == el._id));
            taskFolder = taskFolder.submissions.filter((element) => {
                return (this.accountRole === 'teacher' || (this.accountRole === 'pupil' && element.account === this.accountId));
            });
            let matchingFile;
            if (accountId) {
                matchingFile = taskFolder.find((submission) => {
                    return submission.account == accountId;
                });
            }
            // append ghostElements to show that some pupils have not yet submitted
            const sharing = await this.getVFSSharingV2(this.vfs._id);
            let ghostFolders = this.vfs.sharing_Accounts.filter((account) => {
                return !taskFolder.find((el) => {
                    return el.account == account;
                });
            });
            ghostFolders = ghostFolders.map((el) => {
                for (let i = 0; i < sharing.length; i++) {
                    let invalidTeacherGroup = false;
                    if (sharing[i].displayName === 'Lehrer') {
                        invalidTeacherGroup = true;
                    }
                    sharing[i].participants.map((participant) => {
                        if (participant.account == el) {
                            // use the full name information provided by the sharing
                            el = {
                                account: participant.account,
                                displayName: participant.displayName,
                                type: 'file',
                                originalname: `${participant.displayName}_keine_Abgabe`,
                                uploadedName: `${participant.displayName}_keine_Abgabe`,
                                ghostElement: true,
                                // this is hacky, but the accountId will be unique
                                _id: participant.account,
                            }
                            if (invalidTeacherGroup) {
                                el = null;
                            }
                            return el;
                        }
                    });
                }
                return el;
            })
            // also give badges and filter functionality to the elements
            // reduce to file
            taskFolder = taskFolder.map((el) => {
                if (el.seenByTeacher) {
                    // korrigiert
                    el.file.corrected = true;
                } else {
                    // offen
                    el.file.seenByBadge = true;
                }
                return el.file;
            });
            ghostFolders = ghostFolders.filter((el) => {
                return el != null;
            });
            taskFolder = taskFolder.concat(ghostFolders);
            this.vfs = {
                files: taskFolder,
                folders: [],
                root: false,
                name: 'Abgaben',
                // identifies the vfs for a special ruleset
                // don't allow menus that perform any modification to the submitted work
                // except teacher can open the file in editor and overwrite it to submit their correction
                rulesetTask: true,
            }
            this.folderIdTree.push(this.vfs);
            this.folders = this.vfs.folders;
            this.files = this.vfs.files;
            this.fullscreenPreviewEnabled = false;
            this.notifyFolderSelection(1);

            // add task id to query
            this.$router.replace({ query: { ...this.$route.query, fwtask: el._id } });

            // directly opens preview of a requested file if parsed
            if (matchingFile) {
                this.loadFullscreenPreview(matchingFile.file);
            }
        },

        isTask(el) {
            // loading the initial vfs might be staggered but only in the root directory
            // where the tasks dont matter yet
            if (!this.vfs) {
                return false;
            }
            if (!this.vfs.sharing_Tasks) {
                return false;
            }
            return !!this.vfs.sharing_Tasks.find((task) => {
                return task.file ? task.file._id ? task.file._id == el._id : task.file == el._id : false;
            })
        },

        // for pupil, file has to be marked activeTask to be opened in Editor
        isTaskActive(el) {
            if (!this.vfs) {
                return false;
            }
            if (!this.vfs.sharing_Tasks) {
                return false;
            }
            let found = this.vfs.sharing_Tasks.find((task) => {
                return (task.file == el._id) || (task.file._id == el._id);
            });
            if (found) {
                return found.activeTask;
            } else {
                return false;
            }
        },

        //JUMP
        async loadFullscreenPreview(elTemp, preview, openEditor) {
            let el;
            
            if(preview){
                elTemp.thumbnail = preview;
            }
            if (elTemp.appointmentId) {
                this.currentAppointmentId = elTemp.appointmentId;
                const res = await backend.getFile(elTemp.file, elTemp.fileAccessToken);
                const resJson = await res.json();
                this.isPreviewFileAppointmentUpload = true;
                el = resJson.file;
            } else {
                this.isPreviewFileAppointmentUpload = false;
                el = elTemp;
            }
             this.previewSelection = el;
             for (let i = 0; i < this.filesAndFolders.length; i++) {
               if (el == this.filesAndFolders[i]) {
                 this.previewSelectionIndex = i;
               }
             }
             // early exit: open the editor/libre now that we have sufficient previewselection data set
             if (openEditor) {
                // split between editor and libre office depending on mimetype
                if (allowedMimeTypesForLibre.includes(elTemp.mimetype)) {
                    this.openLibreEditor(elTemp);
                } else {
                    this.openEditor();
                }
                return;
             }
             this.fullscreenPreviewEnabled = true;
             if (!el.ghostElement) {
                 if (!preview) {
                     await this.initThumbnail();
                 } else {
                     this.previewURL = preview;
                 }
             } else {
               this.previewURL = null;
             }
             const fileId = el.type === 'draft' ? el.file._id : el._id;
             let update = true;
             if (el.type === 'draft' && el.file.seenBy.includes(this.accountId)) {
                 update = false; // Don't push accountId again if you've already seen your draft
             }
             if (update) {
                 await this.markFileSeen(fileId);
                 if (el.file && el.file.seenBy) {
                     const seenBy = el.type === 'draft' ? el.file.seenBy : el.seenBy;
                     seenBy.push(this.accountId);
                     this.$emit('badgeContentChanged', this.rootFolderBadges);
                 }
             }
             if(this.$refs.previewDialog && this.$refs.previewDialog.$el){
                setTimeout(() => {
                     this.$refs.previewDialog.$el.focus();
                }, 150);
             }
        },
        resetFilePreview() {
            this.previewSelection = { name: 'Vorschau' };
            this.previewSelectionIndex = 0;
            this.fullscreenPreviewEnabled = false;
        },

        clickedEditLearningpathSettings(item) {
            // find out the origin of the current learningPath
            if (this.vfs.learningPath) {
                for (let i = this.folderIdTree.length - 1; i >= 0; i--) {
                    if (this.vfs.learningPath.pathVFSOrigin == this.folderIdTree[i]._id) {
                        // emit edit Sharing with the learningPath origin ->
                        // this resolves by opening the LearningPathDialog and allowing full settings manipulation
                        this.$emit('editSharing', this.folderIdTree[i]);
                    }
                }
            } else {
                // clicked on the root LP
                this.$emit('editSharing', item);
            }
        },

        /**
         * Toggles the dialog for publishing to document registry,
         * and sets publishable document
         * @param el publishable document
         */
        openPublishDialog(el) {
            this.documentToPublish = el;
            this.showPublishDialog = true;
        },

        // to prevent memory leaks, enforces a limit on maximum available URLs
        // generated through FileSystemListElements
        // this should be called whenever diving or leaving a folder
        clean_URLCollection() {
            for (let i = this.FSLE_URL_Collection.length; i > 15; i--) {
                URL.revokeObjectURL(this.FSLE_URL_Collection.shift());
            }
        },

        // integrates appointments into VFS through a fetched appointment selection
        // which is sorted into a mocked folder structure and a special flag 'rulesetAppointment'
        async populateAppointments() {
            console.count(`populating ${this.appointments.length} Appointments `);
            if (this.folderDepth !== 0) {
                return;
            }
            // case top level: add 'Fächer' appointments entry point
            const subjectFolder = {
                files: [],
                folders: [],
                name: 'Stundenplan',
                groupName: 'Fächer',
                parentFolder: this.currentFolderId,
                root: false,
                type: 'folder',
                version: 2,
                rulesetAppointment: true,
                _id: 'faecherId',
            };

            // case select from subjects
            let mySubjects = [];

            if (this.accountRole === 'teacher') {
                const myGroups = await this.groupsByLeader(this.me._id);
                if (this.subjects && myGroups.length === 0) {
                    mySubjects = this.subjects.filter((subject) => subject.teachers.find((teacher) => teacher.account === this.accountId));
                }
            }
            let uniqueAppointments = new Set();
            for (const el of this.appointments) {
                if (this.accountRole === 'teacher' && mySubjects.length > 0 && mySubjects.find((mySubject) => mySubject.name === el.schoolSubject.name)) {
                    uniqueAppointments.add(el.schoolSubject.name + '#' + el.schoolSubject.icon);
                } else if (mySubjects.length === 0){
                    uniqueAppointments.add(el.schoolSubject.name + '#' + el.schoolSubject.icon);
                }
            }

            for (const subject of [...uniqueAppointments]) {
                const name = subject.split('#')[0];
                const fachIcon = subject.split('#')[1];
                const newFolder = {
                    files: [],
                    folders: [],
                    name,
                    groupname: name,
                    fachIcon,
                    parentFolder: this.currentFolderId,
                    root: false,
                    type: 'folder',
                    version: 2,
                    rulesetAppointment: true,
                    _id: null,
                };
                subjectFolder.folders.push(newFolder);
            }

            let sub1Folders = subjectFolder.folders;
            // case select from months
            subjectFolder.folders = await Promise.all(sub1Folders.map(async (subFolder) => {
                const uniqueMonths = new Set();
                for (const el of this.appointments) {
                    // filter by subject from path
                    if (this.containsFiles(el) && el.month && subFolder.name == el.schoolSubject.name) {
                        uniqueMonths.add(el.month);
                    }
                }
                for (const month of [...uniqueMonths]) {
                    const newFolder = {
                        files: [],
                        folders: [],
                        name: month,
                        parentFolder: this.currentFolderId,
                        root: false,
                        type: 'folder',
                        version: 2,
                        rulesetAppointment: true,
                        _id: null,
                    };
                    subFolder.folders.push(newFolder);
                }
                return subFolder;
            }));

            // case select from days
            subjectFolder.folders = await Promise.all(subjectFolder.folders.map(async (sub1Folder) => {
                sub1Folder.folders = await Promise.all(sub1Folder.folders.map(async (sub2Folder) => {
                    const uniqueDays = new Set();
                    for (const el of this.appointments) {
                        // filter by subject and month from path
                        if (this.containsFiles(el) &&
                            sub1Folder.name == el.schoolSubject.name &&
                            sub2Folder.name == el.month) {
                            uniqueDays.add(el.day);
                        }
                    }
                    for (const day of [...uniqueDays]) {
                        const date = new Date(day);
                        const newFolder = {
                            files: [],
                            folders: [],
                            name: date.getUTCDate().toString(),
                            parentFolder: this.currentFolderId,
                            root: false,
                            type: 'folder',
                            version: 2,
                            rulesetAppointment: true,
                            _id: null,
                        };
                        sub2Folder.folders.push(newFolder);
                    }
                    return sub2Folder;
                }));
                return sub1Folder;
            }));

            // case selected all criteria, show appointments
            // if (this.vfs.rulesetAppointment && this.folderDepth === 4 && this.vfs.folders.length === 0) {
            let count = 0;
            subjectFolder.folders = await Promise.all(subjectFolder.folders.map(async (sub1Folder) => {
                sub1Folder.folders = await Promise.all(sub1Folder.folders.map(async (sub2Folder) => {
                    sub2Folder.folders = await Promise.all(sub2Folder.folders.map(async (sub3Folder) => {
                        const matchingAppointments = [];
                        for (const el of this.appointments) {
                            const date = new Date(el.day);
                            // filter by subject, month, day from path
                            if (this.containsFiles(el) &&
                                sub1Folder.name == el.schoolSubject.name &&
                                sub2Folder.name == el.month &&
                                sub3Folder.name == date.getUTCDate().toString()) {
                                // element has matched filter, now standardize its :item prop
                                for (let j = 0; j < el.teacherUploads.length; j++) {
                                    el.teacherUploads[j].fachIcon = el.schoolSubject.icon;
                                    el.teacherUploads[j].fachColor = el.schoolSubject.color;
                                    el.teacherUploads[j].groupName = el.schoolSubject.name;
                                    el.teacherUploads[j].appointmentId = el._id;
                                    el.teacherUploads[j].previewData = {
                                        fileId: el.teacherUploads[j].file,
                                        loading: false,
                                        fetched: false,
                                        preview: null
                                    }
                                       el.teacherUploads[j].displayTeacherFile = true; // this flag causes custom formatting
                                    if (el.teacherUploads[j].editParent) {
                                        let correctedFile = el.pupilUpload.filter(
                                            upload => upload._id == el.teacherUploads[j].editParent);
                                        if (correctedFile && correctedFile.length > 0) {
                                            // console.log('found a matching pupilFile that was corrected', correctedFile);
                                            el.teacherUploads[j].displayTeacherFile = false;
                                            el.teacherUploads[j].displayCorrectedFile = true; // this flag causes custom formatting
                                        } else {
                                            // console.log('didnt find a matching pupilFile that was corrected', el.teacherUploads[j].editParent);
                                        }
                                    }
                                }
                                for (let j = 0; j < el.pupilUpload.length; j++) {
                                    // todo: if a correction exists, only show that and not the base hand-in of pupil
                                    el.pupilUpload[j].fachIcon = el.schoolSubject.icon;
                                    el.pupilUpload[j].fachColor = el.schoolSubject.color;
                                    el.pupilUpload[j].groupName = el.schoolSubject.name;
                                    el.pupilUpload[j].appointmentId = el._id;
                                    el.pupilUpload[j].previewData = {
                                        fileId: el.pupilUpload[j].file,
                                        loading: false,
                                        fetched: false,
                                        preview: null
                                    }
                                    el.pupilUpload[j].displayPupilFile = true; // this flag causes custom formatting
                                }

                                if (this.accountRole === "teacher" && el.teachers.find((teacher) => teacher.account === this.accountId)) {
                                    matchingAppointments.push(el);
                                } else if (this.accountRole !== "teacher") {
                                    matchingAppointments.push(el);
                                }

                            }
                        }
                        for (const appointment of matchingAppointments) {
                            const newFolder = {
                                files: appointment.pupilUpload.concat(appointment.teacherUploads),
                                folders: [],
                                name: this.getSlotTime(appointment.timeslot),
                                parentFolder: this.currentFolderId,
                                root: false,
                                type: 'folder',
                                version: 2,
                                rulesetAppointment: true,
                                appointment: true, // this flag sets the FSTE.vue to appointment mode
                                _id: `appointmentFolder${count}`,
                            };
                            count++;
                            sub3Folder.folders.push(newFolder);
                            this.faecherFolders.push(newFolder);
                        }
                        return sub3Folder;
                    }));
                    return sub2Folder;
                }));
                return sub1Folder;
            }));
            // finished constructing subject Folder, push into vfs
            this.vfs.folders.push(subjectFolder);
        },

        // appends month for sorting
        async processAppointments() {
            for (let i = 0; i < this.appointments.length; i++) {
                const date = new Date(this.appointments[i].day);
                this.appointments[i].month = this.monthNames[date.getUTCMonth()] + JSON.stringify(date.getUTCFullYear()).slice(-2);
            }
        },

        getSlotTime(slotId) {
            const temp = this.slots.find((el) => el._id == slotId._id);
            if (temp) {
                return ("0" + new Date(temp.start).getHours()).slice(-2) + ":" + ("0" + new Date(temp.start).getMinutes()).slice(-2) + '-' + ("0" + new Date(temp.end).getHours()).slice(-2) + ":" + ("0" + new Date(temp.end).getMinutes()).slice(-2);
            }
            return ("Stunde");
        },

        containsFiles(el) {
            return el.teacherUploads.length > 0 || el.pupilUpload.length > 0;
        },

        async initThumbnail() {
            this.validateMimetype();
            if (this.previewURL && this.previewURL !== img_eklaraLogo) {
                URL.revokeObjectURL(this.previewURL);
            }
            // request thumbnail identified by fileId
            const fileId = this.previewSelection.type === 'draft' ? this.previewSelection.file._id : this.previewSelection._id;
            let thumb = await getVFSUploadThumbnail(fileId, this.previewSelection.accessToken);
            this.previewPDF = false;
            if (thumb) {
                const blob = await thumb.blob();
                const url = URL.createObjectURL(blob);

                this.previewURL = url;
                this.previewSelection.thumbnail = url;
            } else {
                this.previewURL = img_eklaraLogo;
            }
        },

        async cyclePreview(direction) {
            this.previewSelectionIndex += direction;
            if (this.previewSelectionIndex < 0) {
                this.previewSelectionIndex = this.filesAndFolders.length - 1;
            } else
                if (this.previewSelectionIndex >= this.filesAndFolders.length) {
                    this.previewSelectionIndex = 0;
                }
            this.previewSelection = this.filesAndFolders[this.previewSelectionIndex];
            if (this.previewSelection.type !== 'file' || (!this.allowedMimeTypesForEditor.includes(this.previewSelection.mimetype)
                && !this.allowedMimeTypesForLibre.includes(this.previewSelection.mimetype))) {
                this.cyclePreview(direction);
                return;
            }
            await this.initThumbnail();
        },

        validateMimetype() {
            const uploadedName = this.previewSelection.type === 'draft' ? this.previewSelection.file.uploadedName : this.previewSelection.uploadedName ? this.previewSelection.uploadedName : this.previewSelection.title;

            if (['pdf', 'png', 'jpg', 'jpeg', 'tiff', 'bmp'].includes(uploadedName.split('.').pop().toLowerCase())) {
                this.validMimetype = true;
            } else {
                this.validMimetype = false;
            }
        },

        openEditor() {
            let params = {};
            if (this.folderType === 'groupFolder' && this.accountRole === 'pupil' && this.isTask(this.previewSelection)) {
                // params get parsed to the open-Editor call, this sets the route-query up to inform the editor
                params.sharing = true;
                // parse the current vfs to the store and let editor retrieve relevant information
                this.setVfs(this.vfs);
            }
            // // allow pupil to edit his own private files (the editor does not like this currently, disabled)
            // if (this.accountRole === 'pupil' && !this.vfs.sharing_enabled && !this.vfs.sharing_discoverable) {
            //     params = { edit: true };
            // }
            if (this.previewSelection.type === 'draft') {
                const foundCompleteDraftVFSEntry = this.vfs.drafts
                    .find(entry => entry.draft._id === this.previewSelection._id);
                this.setOriginalFileIdForVFSDraft(foundCompleteDraftVFSEntry.originalFile);
            }
            this.$emit('viewFile', this.previewSelection, params, this.isPreviewFileAppointmentUpload, this.currentAppointmentId);
        },

        evalScreenSize() {
            let win = window;
            let doc = document;
            let docElem = doc.documentElement;
            let body = doc.getElementsByTagName('body')[0];
            this.screenX = win.innerWidth || docElem.clientWidth || body.clientWidth;
            this.screenY = win.innerHeight || docElem.clientHeight || body.clientHeight;
        },

        handleKeydown(event) {
                        switch (event.code) {
                case 'ArrowLeft':
                    this.cyclePreview(-1);
                    break;
                case 'KeyA':
                    this.cyclePreview(-1);
                    break;
                case 'ArrowRight':
                    this.cyclePreview(1);
                    break;
                case 'KeyD':
                    this.cyclePreview(1);
                    break;
                case 'Enter':
                    if (this.validMimetype) {
                        this.openEditor();
                    }
                    break;
                case 'Space':
                    if (this.validMimetype) {
                        this.openEditor();
                    }
                    break;
                default:
                    break;
            }
        },

        async notifyFolderSelection(direction) {
            if (!direction && direction != 0) {
                direction = 1;
            }
            this.$emit("currentFolder", this.vfs, direction);
        },

        async notifyCopyOrMoveFile(el, mode) {
            this.$emit("notifyCopyFile", el, mode);
        },

        async clickDownloadFolderUpload(file) {
            const res = await this.getVFSFolderUploadV2(
                file._id
            );
            fetch(res.url, {
                method: "GET",
                headers: new Headers({
                    Authorization: "Bearer " + this.token,
                }),
            })
                .then((response) => response.blob())
                .then((blob) => {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement("a");
                    a.href = url;
                    a.download = file.uploadedName;
                    document.body.appendChild(a);
                    a.click();
                    setTimeout(function () {
                        a.remove();
                        window.URL.revokeObjectURL(url);
                    }, 1000);
                });
        },

        async initRootDir(reset = false) {
            if (!this.initalFolderIdTree) {
                const t0 = performance.now();
                // No path to inital folder has been given so load root
                // use default root folder
                this.folderIdTree = [];
                this.$emit('mutateFolderDepth', 0);
                this.vfs = await this.getVFSFolderUploadsV2({
                    folderType: this.folderType,
                    mounting: true
                });
                this.files = this.vfs.files;
                this.folders = this.vfs.folders;
                this.currentFolderId = this.vfs._id;
                this.folderIdTree.push(this.vfs);
                this.notifyFolderSelection(0);
                await this.populateAppointments();
                this.$emit('badgeContentChanged', this.rootFolderBadges)
                const t1 = performance.now()
                console.debug(`initRootDir performance: ${t1 - t0} ms.`);
                if (reset) {
                    if (this.$route.query.fwfolder) {
                        // remove folder id from query
                        await this.$router.replace({ query: { ...this.$route.query, fwfolder: undefined } })
                    }
                    if (this.$route.query.fwtask) {
                        // remove task id from query
                        await this.$router.replace({ query: { ...this.$route.query, fwtask: undefined } })
                    }
                }
            } else {
                this.folderIdTree = this.initalFolderIdTree;
                this.vfs = this.folderIdTree[this.folderIdTree.length - 1];
                this.files = this.vfs.files;
                this.folders = this.vfs.folders;
                this.currentFolderId = this.vfs._id;
                this.notifyFolderSelection(0);
                this.$emit('mutateFolderDepth', this.folderIdTree.length);
                this.$emit('resetInitalFolderIdTree');
            }
            // reset filters
            this.$emit('resetFilters');
            // this.t1 = performance.now() - this.t0;
        },

        // Folder Creation Bits
        switchUIToEditMode(el) {
            el.isMoving = true;
            this.newFolders = [el];
            const elIndex = this.shownItems.findIndex(item => item === el);
            this.shownItems.splice(elIndex, 1);
        },

        // vue/no-unused-properties cannot detect methods by $ref, so make a exception
        // eslint-disable-next-line vue/no-unused-properties
        startFolderCreation() {
            this.newFolders = [{ isEditing: true }];
            this.newFolders[0].isCreating = true;
            // todo: move initialization of possibleReceiver Accounts from ListElement to here, then pass as prop
        },

        // creates AND patches Folders
        async createFolder(folder, newFolderCreated = true) {
            if (this.folderType === 'groupFolder') {
                folder.receiverAccounts = await Promise.all(folder.receiverAccounts.map((el)=> {
                    return el.account;
                }));
            }
            if (newFolderCreated) {
                let params = {
                    name: folder.name ? folder.name : 'Neuer Ordner',
                    currentFolderId: this.currentFolderId,
                    folderType: this.folderType, // deprecated property, default in v2 is 'groupFolder' but should be dropped as it has no impact
                    sharing_Accounts: folder.sharing_enabled ? folder.receiverAccounts : [],
                    sharing_enabled: folder.sharing_enabled,
                    sharing_discoverable: folder.sharing_discoverable,
                };
                let res = await this.createVFSFolderV2(params);
                if (res === 403) {
                    this.showSnackbar({ message: 'Keine Berechtigung vorhanden, nur der Besitzer kann Ordner erstellen.', color: "error" })
                } else {
                    this.showSnackbar({ message: 'Aktion gespeichert!' })
                }
                this.folderName = "";
                this.newFolders = [];
            } else {
                folder.parentFolderId = this.currentFolderId;
                let params = {
                    _id: folder._id,
                    parentFolderId: folder.parentFolderId,
                    name: folder.name,
                    sharing_Accounts: folder.receiverAccounts,
                    sharing_enabled: true,
                    sharing_discoverable: true,
                }
                if (!params.name) {
                    delete params.name;
                }
                let res = await this.updateVFSFolderV2(params);
                if (res.status === 200) {
                    this.showSnackbar({ message: 'Aktion gespeichert!' });
                }
                this.newFolders = [];
            }
            await this.refreshCurrentFolder();
        },
        softCancelFolderCreation() {
            this.newFolders = [];
        },

        cancelFolderCreation() {
            this.newFolders = [];
            this.refreshCurrentFolder();
        },

        //Patch uploads Bits
        startPatchUpload(file) {
            this.patchingFile = { ...file, isMoving: true };
            const elIndex = this.files.findIndex(item => item === file);
            this.files.splice(elIndex, 1);
        },

        cancelPatchUpload(file) {
            this.patchingFile = null;
            this.refreshCurrentFolder();
        },

        async patchFolderUpload(file) {
            let body = {
                fileId: file._id,
                name: file.name,
                parentFolderId: this.currentFolderId
            }

            let res = await this.updateVFSFolderUploadV2(body);
            if (res.status === 200) {
                this.showSnackbar({ message: 'Aktion gespeichert!' });
            }
            await this.refreshCurrentFolder();
            this.patchingFile = null;
        },

        openDeleteDialog(el) {
            this.itemsToDelete = [el]
            this.deleteDialog = true;
            // this.elementToDelete = el._id;
            switch (el.type) {
                case 'file':
                    this.deleteMethod = 'shared file';
                    this.deleteInfoText = 'Wollen Sie die Datei wirklich unwiderruflich löschen?';
                    break;
                case 'folder':
                    this.deleteMethod = 'shared folder';
                    this.deleteInfoText = 'Wollen Sie den Ordner wirklich unwiderruflich löschen? Es werden sämtliche Unterordner und Dateien gelöscht.';
                    break;
                case 'draft':
                    this.deleteMethod = 'shared draft';
                    this.deleteInfoText = 'Wollen Sie den Entwurf wirklich unwiderruflich löschen?';
                    break;
                default:
                    console.warn('undefined deleteMethod selection in openDeleteDialog VirtualFileSystem');
            }
        },

        deleteComplete() {
            this.deleteDialog = false;
            this.stopMultiselect();
            this.itemsToDelete = [];
            this.refreshCurrentFolder();

        },

        async diveInFolderFromHeader(folderId) {
            this.clean_URLCollection();
            let newFolder;
            let directionCounter = 0;
            for (let i = this.folderIdTree.length - 1; i >= 0; i--) {
                if (this.folderIdTree[i]._id == folderId) {
                    newFolder = this.folderIdTree[i];
                    this.vfs = newFolder;
                    this.folders = newFolder.folders;
                    this.files = newFolder.files;
                    this.currentFolderId = newFolder._id;
                    this.traveledFromPath = true;
                    this.notifyFolderSelection(directionCounter);
                    for (let j = Math.abs(directionCounter); j > 0; j--) {
                        this.folderIdTree.pop();
                    }
                    break;
                }
                directionCounter -= 1;
            }
            this.softCancelFolderCreation();
            if (this.$route.query.fwfolder !== this.currentFolderId) {
                await this.$router.replace({ query: { ...this.$route.query, fwfolder: this.currentFolderId } });
            }
            if (this.$route.query.fwtask) {
                // remove task id from query
                await this.$router.replace({ query: { ...this.$route.query, fwtask: undefined } })
            }
        },

        // Method for dive In folder, but specifically for badges to load from root. called through $refs
        // eslint-disable-next-line vue/no-unused-properties
        async openFolderFromBadge(folderId, history = null) {
            await this.diveInFolder(folderId, history, true);
        },

        async diveInFolder(folderId, history = null, badeJump) {
            this.clean_URLCollection();
            // TODO Very bad performance, can be improved in the future
            if (history) {
                for (let i = 0; i < history.length; i++) {
                    const historyFolder = await this.getVFSFolderUploadsV2({
                        folderType: this.folderType,
                        folderId: history[i]
                    });
                    this.folderIdTree.push(historyFolder);
                }
            }
            this.softCancelFolderCreation();
            if (!folderId.includes('appointmentFolder')) {
                this.vfs = await this.getVFSFolderUploadsV2({folderType: this.folderType, folderId});
            } else {
                this.vfs = this.faecherFolders.filter(folder => folder._id === folderId)[0];
            }
            this.folders = this.vfs.folders;
            this.files = this.vfs.files;
            this.currentFolderId = this.vfs._id;
            this.notifyFolderSelection(history ? history.length+1 : 1);
            // update query
            try {
                await this.$router.replace({query: {...this.$route.query, fwfolder: this.currentFolderId}});
                if (this.$route.query.fwtask) {
                    // remove task id from query
                    await this.$router.replace({query: {...this.$route.query, fwtask: undefined}})
                }
                if (badeJump) {
                    this.folderIdTree = this.calculateFolderPath(folderId);
                } else {
                    this.folderIdTree.push(this.vfs);
                }
            } catch (e) {
                console.log('Avoided double navigation');
            }
        },
        calculateFolderPath(folderId) {
          let tempPath = [];
          const path = [];

          //Find folder, starting at the root folder
          const lookForFolder = (currentFolder, folderId) => {
              if (!currentFolder.folders || !currentFolder.folders.length) {
                  return;
              }
              const temp = currentFolder.folders.some(subFolder => {
                  if (folderId === subFolder._id) {
                      tempPath.push(currentFolder);
                      tempPath.push(subFolder);
                      return true;
                  }
                  if (lookForFolder(subFolder, folderId)) {
                      tempPath.push(currentFolder);
                      return true;
                  }
                  return false;
              });
              return temp;
          };
          lookForFolder(this.rootVfs, folderId);

          //remove root folder
          tempPath = tempPath.filter(folder => !folder.isRoot);

          //Reverse the path
          for (let i = tempPath.length-1; i >= 0; i--) {
              path[tempPath.length - 1 -i] = tempPath[i];
          }
          return path;
        },
        async diveInAppointment(item) {
            this.$emit('mutateFolderDepth', this.folderDepth + 1);
            this.vfs = item;
            this.folderIdTree.push(this.vfs);
            this.folders = this.vfs.folders;
            this.files = this.vfs.files;
            this.notifyFolderSelection(0);
        },

        leaveCurrentFolder() {
            if (!this.vfs.root) {
                // prevent throwing errors due to redundant navigation when navigating 'Faecher' Folder
                const replaceRoute = !this.vfs.rulesetAppointment;
                this.softCancelFolderCreation();
                this.clean_URLCollection();
                this.folderIdTree.pop();
                const parent = this.folderIdTree[this.folderIdTree.length - 1];
                if (parent) {
                    this.folders = parent.folders;
                    this.files = parent.files;
                    this.currentFolderId = parent._id;
                    this.vfs = parent;
                }
                this.notifyFolderSelection(0);
                this.displayingSearchResult = false;
                // eslint-disable-next-line no-extra-boolean-cast
                if (!!this.vfs.learningPath) {
                    // we are in a learningPath => modification upwards along the tree is possible
                    // therefore reload parentFolder instead of using the possibly outdated one
                    this.refreshCurrentFolder();
                }
                if(replaceRoute) {
                    this.$router.replace({ query: {
                        ...this.$route.query,
                        fwfolder: this.vfs.root ? undefined : this.currentFolderId
                    }});
                }
                if (this.$route.query.fwtask) {
                    // remove task id from query
                    this.$router.replace({ query: { ...this.$route.query, fwtask: undefined } })
                }
            }
        },

        async refreshCurrentFolder() {
            if (this.vfs.root) {
                this.vfs = await this.getVFSFolderUploadsV2();
            } else {
                this.vfs = await this.getVFSFolderUploadsV2({ folderId: this.currentFolderId });
            }
            this.folderIdTree.pop();
            this.folderIdTree.push(this.vfs);
            this.folders = this.vfs.folders;
            this.files = this.vfs.files;
            this.currentFolderId = this.vfs._id;
            await this.populateAppointments();
        },

        async dropItLikeItsHot(theHotStuff) {
            let errorMessage = null;
            if (this.isStorageFull) {
                this.showSnackbar({
                    color: 'error',
                    message: 'Ihr Speichervolumen ist aufgebraucht. Löschen sie einige Dateien oder wenden Sie sich bitte an die Verwaltung.',
                });
                return;
            }
            const cooledDownStuff = await theHotStuff.reduce(async (previousPromise, hot) => {
                const previous = await previousPromise;
                const cool = await this.postVFSFolderUploadV2({
                    file: hot,
                    destinationId: this.currentFolderId
                });
                if (!cool._id) {
                    errorMessage = cool.name === "AuthorizationError"
                        ? 'Du hast nicht die Berechtigungen um hier hochzuladen! Bitte frage den Besitzer des Ordners.'
                        : 'Etwas ist schiefgelaufen.'
                } else {
                    if (hot.type === 'application/pdf') {
                        const dataForThumbnail = await generateThumbnailForPdf(
                            cool._id,
                            hot
                        );
                        // Push thumbnail
                        await this.safeNewThumbnail({
                            fileId: dataForThumbnail.originFileId,
                            thumbnail: dataForThumbnail.thumbnailFile
                        });
                    }
                }
                previous.push(cool);
                return previous;
            }, Promise.resolve([]));
            const defaultMessage = `${cooledDownStuff.length} Datei${cooledDownStuff.length >= 2 ? 'en':''} erfolgreich hochgeladen!`;
            this.showSnackbar({
                message: errorMessage || defaultMessage,
                color: errorMessage ? 'warning' : 'success',
            })
            await this.refreshCurrentFolder();
        },
        emitNormalUpload(){
           if (this.isStorageFull) {
                this.showSnackbar({
                    color: 'error',
                    message: 'Ihr Speichervolumen ist aufgebraucht. Löschen sie einige Dateien oder wenden Sie sich bitte an die Verwaltung.',
                });
                return;
            }else{
                this.$emit('normalUpload');
            }
        }
    }
}
</script>

<style scoped lang="scss">
.optionBtn {
    border-radius: 8px;
    min-width: 35px !important;
    width: 35px !important;
    height: 35px !important;
}

.directoryPath:hover {
    background-color: #e4e3e3;
}

.textTransformNone {
    text-transform: none;
}

.directoryPath {
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    align-items: flex-end;
}

.fileSystemElement {
    display: flex;
    position: relative;
    border-radius: 4px;
    color: rgb(var(--v-theme-dunkelgrau));
    box-shadow: 1px 2px 3px silver;
    width: 90%;
    white-space: nowrap;
    background-color: #fff;
}

.fileSystemElementName {
    cursor: pointer;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-flex;
    align-items: center;
}

.badgeEventVisualize {
    animation: badgeAppointmentRipple 1000ms ease-in-out;
}

@keyframes badgeAppointmentRipple {
    0% {
        background-color: rgba(255, 255, 255, 0.0);
    }
    10% {
        background-color: rgba(245, 160, 50, 0.6);
    }
    75% {
        background-color: rgba(245, 160, 50, 0.6);
    }
    100% {
        background-color: rgba(255, 255, 255, 0.0);
    }
}

.fileWidgetButton {
    height: 30px !important;
    width: 30px !important;
    min-width: 30px !important;
}

#hintBox {
    display: inline-flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    color: grey;
}
</style>
