<template>
    <v-dialog
        width="500"
        height="100%"
        :model-value="showDialog"
    >
        <v-card>
            <!-- Popup header -->
            <div
                class="px-1 align-start"
                style="display: inline-flex; width: 100%; background-color: rgb(var(--v-theme-headerblue)); font-size: larger; padding-top: 10px; padding-bottom: 14px; color: white;"
            >
                <div
                    class="mt-1"
                    style="margin-left: 10px; display:flex; align-items: center; justify-content: center; width: 100%"
                >
                    <p
                        class="ma-0"
                        style="display: inline-block; font-size: 20px !important"
                    >
                        Einladungslinks
                    </p>
                    <v-spacer />
                    <v-btn
                        size="x-small"
                        elevation="0"
                        class="d-flex align-center mr-2 ml-2"
                        style="background-color: transparent; width: 35px; height: 35px;"
                        @click="() => { $emit('close') }"
                    >
                        <img
                            :src="cancelIcon"
                            class="boardIconDialog"
                            alt="abbrechen-Icon"
                        >
                    </v-btn>
                </div>
            </div>

            <!-- Popup body -->
            <v-card-text>
                <div
                    class="d-flex flex-row align-center"
                    style="justify-content: unset"
                >
                    <h3 class="mb-1 mt-4 mb-2">
                        Neuen Einladungslink erstellen
                    </h3>
                    <v-tooltip
                        location="top"
                        max-width="400px"
                    >
                        <template v-slot:activator="{ props }">
                            <img
                                :src="infoIcon"
                                class="ml-2"
                                style="height: 30px"
                                alt="Erklärung"
                               
                                v-bind="props"
                            >
                        </template>
                        <span>{{ accountRole === 'pupil' ? 'Beachte' : 'Beachten Sie' }}, dass die erstellen Links nur für Nutzer geeignet sind, die über einen
                            eklara-Account verfügen.</span>
                    </v-tooltip>
                </div>
                <p>Gültigkeitsdauer:</p>
                <div
                    class="d-flex flex-row align-center justify-space-between"
                >
                    <v-select
                        v-model="expiryDate"
                        class="ml-4"
                        style="max-width: 54%"
                        placeholder="Keine Dauer ausgewählt"
                        :items="['unbegrenzt', '15 Minuten', '1 Stunde', '1 Tag', '7 Tage']"
                    />
                    <v-btn
                        class="mr-5 toolSelector"
                        color="gruen"
                        min-width="90px"
                        elevation="0"
                        style="border-radius: 8px;"
                        @click="createInvitationLink"
                    >
                        <p
                            class="text-capitalize"
                            style="font-size: large; color: white; margin-left: 4px"
                        >
                            Erstellen
                        </p>
                    </v-btn>
                </div>

                <div
                    class="d-flex flex-row align-center"
                    style="justify-content: space-between"
                >
                    <h3 class="mt-6 mb-1">
                        Bestehende Einladungslinks
                    </h3>
                    <h4
                        v-if="pinboard.invitations.length > 0"
                        class="mt-6"
                        style="margin-right: 29px"
                    >
                        gültig bis:
                    </h4>
                </div>

                <simple-bar
                    class="scroll-area"
                    :auto-hide="false"
                    :settings="settings"
                    style="max-height: 378px"
                >
                    <v-row
                        v-for="(invitation) in pinboard.invitations"
                        :key="invitation._id"
                    >
                        <v-col
                            class="d-flex align-center"
                            style="overflow-x: auto; max-width: 100px"
                            cols="4"
                        >
                            {{ invitation.code }}
                        </v-col>
                        <v-col
                            class="d-flex"
                            style="max-width: 252px"
                        >
                            <v-text-field
                                v-if="windowWidth > 456"
                                :model-value="getLink(invitation.code)"
                                readonly
                                hide-details
                                style="padding: 0; margin: 0;"
                                class="mr-2"
                            />
                            <v-tooltip location="bottom">
                                <template v-slot:activator="{ props }">
                                    <v-btn
                                        class="button mr-2"
                                        v-bind="props"
                                        @click="clickCopyMeetingExternalJoinUrl(invitation.code)"
                                    >
                                        <img
                                            :src="copyIcon"
                                            class="iconToDunkelgrau"
                                            alt="Kopieren"
                                        >
                                    </v-btn>
                                </template>
                                <span>Link kopieren</span>
                            </v-tooltip>
                            <v-tooltip location="bottom">
                                <template v-slot:activator="{ props }">
                                    <v-btn
                                        class="button mr-2"
                                        v-bind="props"
                                        @click="removeInvitation(invitation.code)"
                                    >
                                        <img
                                            :src="deleteIcon"
                                            class="iconToRed"
                                            alt="Löschen"
                                        >
                                    </v-btn>
                                </template>
                                <span>Link löschen</span>
                            </v-tooltip>
                        </v-col>
                        <v-col
                            class="d-flex align-center"
                            style="overflow-x: auto; max-width: 101px"
                            cols="4"
                        >
                            {{ getLocalDate(invitation.expiryDate) }}
                        </v-col>
                    </v-row>
                </simple-bar>
                <span v-if="pinboard.invitations.length === 0">Keine Einladungslinks angelegt.</span>
            </v-card-text>
            <div
                ref="copyUrlContainer"
                type="text"
            />
        </v-card>
    </v-dialog>
</template>

<script>

import {mapActions, mapGetters, mapMutations, mapState} from "vuex";

import cancelIcon from '@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg';
import copyIcon from '@/assets/Icons/FaceliftIcons/kopieren_datei_.svg';
import deleteIcon from '@/assets/Icons/FaceliftIcons/loeschen_muelleimer.svg'
import infoIcon from '@/assets/Icons/FaceliftIcons/info_hinweis.svg';

export default {
    name: "PinboardExternalDialog",

    props: {
        pinboard: {required: true, type: Object},
        loadPinboard: { required: false, type: Function, default: () => {} },
    },
    data: () => ({
        showDialog: true,
        expiryDate: 'unbegrenzt',
        settings: {
            suppressScrollY: false,
            suppressScrollX: true,
            wheelPropagation: false,
        },

        cancelIcon,
        copyIcon,
        deleteIcon,
        infoIcon,
    }),
    computed: {
        ...mapGetters('auth', ['accountRole']),
        ...mapState('util', ['windowWidth']),
    },
    methods: {
        ...mapActions('pinboard', ['postInvitation', 'deleteInvitation']),
        ...mapMutations("snackbar", ["showSnackbar"]),

        async createInvitationLink() {
            let code = '';
            const characters = 'ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz123456789';
            const charactersLength = characters.length;
            let counter = 0;
            while (counter < 8) {
                code += characters.charAt(Math.floor(Math.random() * charactersLength));
                counter += 1;
            }

            let currentDate = new Date();
            const currentDay = currentDate.getDate();
            switch (this.expiryDate) {
                case '15 Minuten':
                    currentDate.setMinutes(currentDate.getMinutes() + 15);
                    break;
                case '1 Stunde':
                    currentDate.setHours(currentDate.getHours() + 1);
                    break;
                case '1 Tag':
                    currentDate.setDate(currentDay + 1);
                    break;
                case '7 Tage':
                    currentDate.setDate(currentDay + 7);
                    break;
                case 'unbegrenzt': // Just take + 1000 years (quite dirty I know)
                    currentDate.setFullYear(currentDate.getFullYear() + 1000);
                    break;
                default:
                    currentDate.setFullYear(currentDate.getFullYear() + 1000);
            }

            const invitation = {
                code: code,
                expiryDate: currentDate,
            }
            await this.postInvitation({
                pinboardId: this.pinboard._id,
                invitation
            });
            if (process.env.VUE_APP_BACKEND_ADDRESS === 'PRODUCTION_BACKEND_ADDRESS') {
                this.newInvitationLink = `${location.protocol}//${location.host}/pinboard/${code}`;
            } else {
                this.newInvitationLink = `http://localhost:8080/pinboard/${code}`;
            }
            await this.loadPinboard();
        },

        async clickCopyMeetingExternalJoinUrl(invitationCode) {
            let domain = '';
            if (process.env.VUE_APP_BACKEND_ADDRESS === 'PRODUCTION_BACKEND_ADDRESS') {
                domain = `${location.protocol}//${location.host}/pinboard/${invitationCode}`;
            } else {
                domain = `http://localhost:8080/pinboard/${invitationCode}`;
            }
            const tempInput = document.createElement("input");
            tempInput.style = "position: absolute; left: -1000px; top: -1000px";
            tempInput.value = domain;
            this.$refs.copyUrlContainer.appendChild(tempInput);
            tempInput.select();
            document.execCommand("copy");
            this.$refs.copyUrlContainer.removeChild(tempInput);
            this.showSnackbar({message: "Link kopiert!"});
        },

        getLink(invitationCode) {
            if (process.env.VUE_APP_BACKEND_ADDRESS === 'PRODUCTION_BACKEND_ADDRESS') { // Production server
                return `${location.protocol}//${location.host}/pinboard/${invitationCode}`;
            } else { // Local host
                return `http://localhost:8080/pinboard/${invitationCode}`;
            }
        },

        async removeInvitation(invitationCode) {
            const response = await this.deleteInvitation(invitationCode);
            if (response.status === 204) {
                this.showSnackbar({ message: 'Einladungslink erfolgreich gelöscht!'});
            } else {
                this.showSnackbar({ message: 'Ein unerwarteter Fehler ist aufgetreten.', color: 'error'});
            }
            await this.loadPinboard();
        },

        getLocalDate(expiryDate) {
            const date = new Date(expiryDate);
            const year = date.getUTCFullYear();
            if (year > 3000) { // If 'unbegrenzt' was selected when creating the link
                return 'unbegrenzt';
            }
            const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
            return date.toLocaleDateString('de-DE', options);
        },
    },
};
</script>

<style scoped lang="scss">
.boardIconDialog {
    height: 25px;
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

.button {
    height: 36px;
    min-width: 36px !important;
    width: 38px;
    box-shadow: none;

    img {
        height: 20px !important;
        width: 20px !important;
        margin: 0 !important;
        filter: brightness(1000%);
    }
}

.iconToDunkelgrau {
    filter: brightness(0) saturate(100%) invert(46%) sepia(0%) saturate(0%) hue-rotate(163deg) brightness(92%) contrast(82%) !important;
}

.scroll-area {
    flex: 1;
}
</style>
