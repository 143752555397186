<template>
    <v-menu
        :model-value="colorMenu"
        style="border-radius: 5px !important;"
        max-width="500px"
        :offset="[162,240]"
        :attach="'#' + attach"
        aria-haspopup="menu"
    >
        <template v-slot:activator="{ props:menu }">
            <v-tooltip location="right">
                <template v-slot:activator="{ props:tooltip }">
                    <v-btn
                        size="x-small"
                        class="mr-3 toolSelector preventColorInvert"
                        :style="windowWidth <= 600 ? 'height: 40px !important; width: 40px !important' : ''"
                        aria-label="Farbe auswählen"
                        aria-hidden="true"
                        tabindex="-1"
                        v-bind="$mergeProps(menu, tooltip)"
                        @click="openMenu()"
                    >
                        <v-icon
                            style="font-size: 22px !important;"
                            :color="color"
                        >
                            fas fa-circle
                        </v-icon>
                    </v-btn>
                </template>
                <div style="display: flex; flex-direction: column; text-align: center">
                    <span>Farbe auswählen</span>
                    <div>
                        <img
                            v-if="hoeren"
                            :src="farbenMetacom"
                            width="100"
                            style="margin: auto"
                            class="mr-1"
                        >
                        <img
                            v-if="hoeren"
                            :src="aussuchenMetacom"
                            width="100"
                            style="margin: auto"
                        >
                    </div>
                </div>
            </v-tooltip>
        </template>

        <div
            role="menu"
            style="height: 25px; display: inline-table; border-radius: 0 !important; background-color: rgb(var(--v-theme-hellgrau)); z-index: 2"
        >
            <v-btn
                id="firstColor"
                variant="text"
                size="x-small"
                role="menuitem"
                style="background-color: rgb(var(--v-theme-hellgrau)); border-radius: 0 !important; width: 54px; height: 49px"
                aria-label="Farbe weiß"
                class="preventColorInvert"
                @click="changeColor('#ffffff')"
            >
                <v-icon style="font-size: 22px !important; color: #ffffff">
                    fas fa-circle
                </v-icon>
            </v-btn>
            <v-btn
                variant="text"
                size="x-small"
                role="menuitem"
                style="background-color: rgb(var(--v-theme-hellgrau)); border-radius: 0 !important; width: 54px; height: 49px"
                aria-label="Farbe gelb"
                class="preventColorInvert"
                @click="changeColor('#fac837')"
            >
                <v-icon style="font-size: 22px !important; color: rgb(var(--v-theme-gelb))">
                    fas fa-circle
                </v-icon>
            </v-btn>
            <v-btn
                variant="text"
                size="x-small"
                role="menuitem"
                style="background-color: rgb(var(--v-theme-hellgrau)); border-radius: 0 !important; width: 54px; height: 49px"
                class="pa-0 preventColorInvert"
                aria-label="Farbe orange"
                @click="changeColor('#f07d32')"
            >
                <v-icon style="font-size: 22px !important; color: rgb(var(--v-theme-orange))">
                    fas fa-circle
                </v-icon>
            </v-btn>
            <v-btn
                variant="text"
                size="x-small"
                role="menuitem"
                style="background-color: rgb(var(--v-theme-hellgrau)); border-radius: 0 !important; width: 54px; height: 49px"
                class="pa-0 preventColorInvert"
                aria-label="Farbe rot"
                @click="changeColor('#e6231e')"
            >
                <v-icon style="font-size: 22px !important; color: rgb(var(--v-theme-error))">
                    fas fa-circle
                </v-icon>
            </v-btn>
            <v-btn
                variant="text"
                size="x-small"
                role="menuitem"
                style="background-color: rgb(var(--v-theme-hellgrau)); border-radius: 0 !important; width: 54px; height: 49px"
                class="pa-0 preventColorInvert"
                aria-label="Farbe hellblau"
                @click="changeColor('#6996cd')"
            >
                <v-icon style="font-size: 22px !important; color: rgb(var(--v-theme-primary))">
                    fas fa-circle
                </v-icon>
            </v-btn>
            <v-btn
                variant="text"
                size="x-small"
                role="menuitem"
                style="background-color: rgb(var(--v-theme-hellgrau)); border-radius: 0 !important; width: 54px; height: 49px"
                class="pa-0 preventColorInvert"
                aria-label="Farbe dunkelblau"
                @click="changeColor('#505faa')"
            >
                <v-icon style="font-size: 22px !important; color: rgb(var(--v-theme-blau))">
                    fas fa-circle
                </v-icon>
            </v-btn>
            <v-btn
                variant="text"
                size="x-small"
                role="menuitem"
                style="background-color: rgb(var(--v-theme-hellgrau)); border-radius: 0 !important; width: 54px; height: 49px"
                class="pa-0 preventColorInvert"
                aria-label="Farbe grün"
                @click="changeColor('#3ba934')"
            >
                <v-icon style="font-size: 22px !important; color: rgb(var(--v-theme-chatColor))">
                    fas fa-circle
                </v-icon>
            </v-btn>
            <v-btn
                variant="text"
                size="x-small"
                role="menuitem"
                style="background-color: rgb(var(--v-theme-hellgrau)); border-radius: 0 !important; width: 54px; height: 49px"
                class="pa-0 preventColorInvert"
                aria-label="Farbe grau"
                @click="changeColor('#706f6f')"
            >
                <v-icon style="font-size: 22px !important; color: #706f6f">
                    fas fa-circle
                </v-icon>
            </v-btn>
            <v-btn
                variant="text"
                size="x-small"
                role="menuitem"
                style="background-color: rgb(var(--v-theme-hellgrau)); border-radius: 0 !important; width: 54px; height: 49px"
                class="pa-0 preventColorInvert"
                aria-label="Farbe türkis"
                @click="changeColor('#40E0D0')"
            >
                <v-icon style="font-size: 22px !important; color: #40E0D0">
                    fas fa-circle
                </v-icon>
            </v-btn>
            <v-btn
                variant="text"
                size="x-small"
                role="menuitem"
                style="background-color: rgb(var(--v-theme-hellgrau)); border-radius: 0 !important; width: 54px; height: 49px"
                class="pa-0 preventColorInvert"
                aria-label="Farbe beige"
                @click="changeColor('#D1BC8A')"
            >
                <v-icon style="font-size: 22px !important; color: #D1BC8A">
                    fas fa-circle
                </v-icon>
            </v-btn>
            <v-btn
                variant="text"
                size="x-small"
                role="menuitem"
                style="background-color: rgb(var(--v-theme-hellgrau)); border-radius: 0 !important; width: 54px; height: 49px"
                class="pa-0 preventColorInvert"
                aria-label="Farbe bordeaux"
                @click="changeColor('#5e2028')"
            >
                <v-icon style="font-size: 22px !important; color: #5e2028">
                    fas fa-circle
                </v-icon>
            </v-btn>
            <v-btn
                variant="text"
                size="x-small"
                role="menuitem"
                style="background-color: rgb(var(--v-theme-hellgrau)); border-radius: 0 !important; width: 54px; height: 49px"
                class="pa-0 preventColorInvert"
                aria-label="Farbe lila"
                @click="changeColor('#8800FF')"
            >
                <v-icon style="font-size: 22px !important; color: #8800FF">
                    fas fa-circle
                </v-icon>
            </v-btn>
            <v-btn
                variant="text"
                size="x-small"
                role="menuitem"
                style="background-color: rgb(var(--v-theme-hellgrau)); border-radius: 0 !important; width: 54px; height: 49px"
                class="pa-0 preventColorInvert"
                aria-label="Farbe rosa"
                @click="changeColor('#D47479')"
            >
                <v-icon style="font-size: 22px !important; color: #D47479">
                    fas fa-circle
                </v-icon>
            </v-btn>
            <v-btn
                variant="text"
                size="x-small"
                role="menuitem"
                style="background-color: rgb(var(--v-theme-hellgrau)); border-radius: 0 !important; width: 54px; height: 49px"
                class="pa-0 preventColorInvert"
                aria-label="Farbe eKlara-violett"
                @click="changeColor('#8d4c93')"
            >
                <v-icon style="font-size: 22px !important; color: #8d4c93">
                    fas fa-circle
                </v-icon>
            </v-btn>
            <v-btn
                variant="text"
                size="x-small"
                role="menuitem"
                style="background-color: rgb(var(--v-theme-hellgrau)); border-radius: 0 !important; width: 54px; height: 49px"
                class="pa-0 preventColorInvert"
                aria-label="Farbe schwarz"
                @focusout="colorMenu = false"
                @click="changeColor('#000000')"
            >
                <v-icon style="font-size: 22px !important; color: #000000">
                    fas fa-circle
                </v-icon>
            </v-btn>
        </div>
    </v-menu>
</template>

<script>
import farbenMetacom from "@/assets/METACOM/Editor/farbe.png";
import aussuchenMetacom from "@/assets/METACOM/Editor/aussuchen.png";

export default {
    name: "EditorColorPicker",
    props: {
        attach: { required: false, type: String },
        changeColor: { required: true, type: Function },
        hoeren: { required: true, type: Boolean },
        sehen: { required: true, type: Boolean },
        color: { required: true, type: String },
        windowWidth: { required: true, type: Number },
        toggleIcon: { required: true, type: Function }
    },
    data: () => ({
        colorMenu: false,
        farbenMetacom,
        aussuchenMetacom,
    }),
    methods: {
        // https://stackoverflow.com/questions/58075400/using-v-tooltip-inside-v-menu-activator-in-vuetify-2-0
        getHandlers() {
            return [...arguments].reduce((seed, item) => {
                const mergedHandlers = { ...item, ...seed };

                return mergedHandlers;
            }, {});
        },

        openMenu() {
            this.colorMenu = true;
            this.toggleIcon('color');
        }
    }
}
</script>

<style scoped lang="scss">
.toolSelector {
    background-color: #f8f8f8 !important;
    width: 49px !important;
    height: 49px !important;

    img {
        filter: brightness(0%);
    }

    @media (max-width: 430px) {
        width: 40px !important;
        height: 40px !important;
    }
}

.colorPickerMenu {
    width: 216px !important;
    height: 25px;
    display: inline;
    border-radius: 0 !important;
    background-color: rgb(var(--v-theme-hellgrau));
}
</style>
