<template>
    <v-dialog
        v-model="deleteDialog"
        max-width="337px"
        @close="close"
    >
        <v-card
            class="cardStyle"
            style="overflow: hidden; border-radius: 16px"
        >
            <v-card-title
                class="text-h5 mb-1 d-flex align-center justify-space-between"
                style="background-color: rgb(var(--v-theme-fileArchiveOrange)); margin-bottom: 10px"
            >
                <p style="color: white">
                    Löschen bestätigen?
                </p>
                <v-btn
                    icon="$vuetify"
                    variant="plain"
                    color="transparent"
                    elevation="0"
                    style="margin-right: -8px"
                    @click="close"
                >
                    <img
                        :src="cancelIcon"
                        class="iconToWhite icon30"
                        alt="abbrechen"
                    >
                </v-btn>
            </v-card-title>

            <v-card-text class="mt-2">
                <div v-if="killThemAll.length">
                    <p>
                        Die folgenden Elemente werden beim Drücken auf den
                        <span style="color: red">Löschen</span>
                        Knopf <span style="font-weight: bold">gelöscht</span>.
                        Bist du dir sicher das du fortfahren willst?
                    </p>
                    <p v-if="folderToBeDeleted">
                        Mindestens eines dieser Elemente scheint ein Ordner zu sein.
                        Bist du sicher das du diesen löschen möchtest?
                        <span style="font-weight: bold">
                            Damit werden auch alle Unterordner und Dateien darin gelöscht!
                        </span>
                    </p>
                    <div style="height: 10px" />
                    <p
                        v-for="element in killThemAll"
                        :key="`${element.name || element.originalname}ToDelete`"
                        style="margin-left: 20px; font-weight: bold"
                    >
                        {{ element.name || element.originalname }}
                    </p>
                </div>
                <div v-if="stayingAlive.length">
                    <p>
                        Die folgenden Elemente können nicht gelöscht werden.
                        Bitte kontaktiere den Besitzer der Datei oder einen Lehrer.
                    </p>
                    <p
                        v-for="element in stayingAlive"
                        :key="`${element.name || element.originalname}ToDelete`"
                    >
                        {{ element.name || element.originalname }}
                    </p>
                </div>
            </v-card-text>

            <v-card-actions class="d-flex justify-center mb-2">
                <v-btn
                    class="optionBtn text-none ml-2"
                    color="#F5F5F5"
                    alt="Abbrechen"
                    style="width: 40%"
                    @click="close"
                >
                    <img
                        :src="cancelIcon"
                        style="height: 20px;"
                        class="mr-2"
                    >
                    Abbrechen
                </v-btn>

                <v-spacer />
                
                <v-btn
                    class="optionBtn text-none ml-2"
                    color="rot"
                    theme="dark"
                    style="width: 40%"
                    @click="confirmDelete"
                >
                    <img
                        :src="recycleBinIcon"
                        style="height: 20px; filter: brightness(1000%)"
                        class="mr-2"
                        alt="Löschen"
                    >
                    Löschen
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import recycleBinIcon from "@/assets/Icons/FaceliftIcons/loeschen_muelleimer.svg";
import cancelIcon from "@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg";

export default {
    name: "FileWidgetElementDeleteDialog",
    props: {
        vfs: { required: true, type: Object },
        items: { required: true, type: Array },
    },
    data: () => ({
        deleteDialog: false,
        // I just want to have some fun ok
        killThemAll:[],
        stayingAlive: [],
        folderToBeDeleted: false,

        recycleBinIcon,
        cancelIcon,
    }),
    computed: {
        ...mapGetters('auth', ['accountRole', 'accountId']),
    },
    mounted() {
        this.extractDeleteData();
        this.deleteDialog = true;
    },
    methods: {
        ...mapActions("virtualFileSystem", ["deleteVFSFolderUploadV2", "deleteVFSFolderV2"]),
        ...mapActions('documentDrafts', [ 'deleteDocumentDraft' ]),
        extractDeleteData() {
            const canBeDeleted = (item) => {
                return !this.vfs.rulesetTask
                    && (this.accountRole === 'teacher' || (this.accountRole === 'pupil'
                            && (!this.vfs.sharing_enabled && !this.vfs.sharing_discoverable)
                            || this.accountId === item.owner)
                    );
            }

            // go through all files and check for each if they can be deleted or not
            this.items.forEach((item) => {
                const canIt = canBeDeleted(item);
                if (canBeDeleted(item)) {
                    // check if item is folder to display extra text
                    if (item.type === 'folder') {
                        this.folderToBeDeleted = true;
                    }
                    this.killThemAll.push(item);
                } else {
                    this.stayingAlive.push(item);
                }
            });


        },
        async confirmDelete() {
            // Needed to not refresh folder prematurely
            await this.items.reduce(async (previousPromise, item) => {
                const previous = await previousPromise;
                let response;
                if (item.type === 'file') {
                    response = await this.deleteVFSFolderUploadV2(item._id)
                }
                if (item.type === 'folder') {
                    response = await this.deleteVFSFolderV2(item._id)
                }
                if (item.type === 'draft') {
                    response = await this.deleteDocumentDraft(item._id)
                }
                previous.push(response);
                return previous;
            }, Promise.resolve([]));

            this.folderToBeDeleted = false;
            this.$emit('deleteComplete');
            this.close();
        },
        close() {
            this.deleteDialog = false;
            this.$emit('close');
        }
    }
}
</script>

<style scoped>
.optionBtn {
    border-radius: 8px;
    min-width: 35px !important;
    height: 35px !important;
}
</style>
