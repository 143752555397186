<template>
    <v-dialog
        v-model="dialogModel"
        :close-on-content-click="false"
        max-width="75vw"
        @click:outside="close"
    >
        <v-card style="width: 100%">
            <v-card-title class="d-flex justify-space-between">
                <p>Geräte importieren per Excel</p>
                <v-btn
                    size="x-small"
                    style="height: 35px; width: 35px"
                    @click="close"
                >
                    <img
                        :src="abbrechenIcon"
                    >
                </v-btn>
            </v-card-title>
            <v-card-text>
                <div class="d-flex justify-space-between">
                    <div
                        style="width: 47.5%"
                    >
                        Um Verleihgeräte per Excel in eKlara einzutragen laden Sie bitte die Vorlage herunter und füllen diese aus.
                        Bitte verändern Sie die Struktur der Excel-Datei nicht!
                        <br>
                        <br>
                        <v-btn
                            style="text-transform: inherit"
                            @click="downloadExchangeDeviceExcelTemplate"
                        >
                            Vorlage runterladen
                        </v-btn>
                        <a
                            id="downloadExchangeExcelTemplate"
                            :href="exchangeExcelSheet"
                            download
                            hidden
                        />
                        <br>
                    </div>
                    <div
                        style="width: 47.5%"
                    >
                        Wenn Sie die Vorlage ausgefüllt haben können Sie diese hier wieder hochladen.
                        Danach wird der Inhalt der Datei überprüft und, falls keine Fehler erkannt werden,
                        werden die Geräte angelegt.
                        <br>
                        <br>
                        <v-btn
                            style="text-transform: inherit"
                            @click="clickUploadExcel"
                        >
                            Hochladen
                        </v-btn>
                        <br>
                    </div>
                </div>
                <br>
                <div
                    v-if="wrongFile"
                >
                    Die hochgeladene Datei hat nicht den richtigen Dateityp oder ist beschädigt!
                    Bitte basieren Sie ihre Liste auf der herunterladbaren Vorlage um solche Fehler zu vermeiden.
                </div>
                <div
                    v-if="faultyEntries.length"
                >
                    <span style="font-weight: bold">Achtung! </span>Es wurden einige fehlerhafte Einträge festgestellt:
                    <p
                        v-for="entry in faultyEntries"
                        :key="`faultyEntry${entry.index}${entry.name}`"
                    >
                        {{ `Excel Reihe ${entry.index + 1}: Fehler in ${entry.error === 'name' ? 'Name' : 'Beschreibung'} des Eintrags` }}
                    </p>
                    Bitte korrigieren oder entfernen Sie die fehlerhaften Einträge in Excel. Danach können Sie ihr Dokument erneut hier hochladen.
                </div>
                <div
                    v-if="excelImportProgessDisplay"
                >
                    <p>{{ `${deviceUploadProgess.length} von ${maximumDevices} Geräten erstellt.` }}</p>
                    <!-- display errors -->
                </div>

                <input
                    id="exchangeExcelUploadInput"
                    ref="exchangeExcelUploadInput"
                    type="file"
                    hidden
                    @change="computeUploadedExchangeDevices"
                >
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import abbrechenIcon from "@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg";
import {mapMutations} from "vuex";
import exchangeExcelSheet from '@/assets/Ausleihgeräte Vorlage.xlsx'
import * as xlsx from "xlsx";
import * as backend from "@/api/backend";

export default {
    name: "ExchangeDeviceExcelImport",
    data: () => ({
        dialogModel: true,
        excelImportProgessDisplay: false,
        deviceUploadProgess: [],
        maximumDevices: -1,
        deviceArray: [],
        faultyEntries: [],
        wrongFile: false,

        abbrechenIcon,
        exchangeExcelSheet,
    }),
    methods: {
        ...mapMutations('snackbar', ['showSnackbar']),
        downloadExchangeDeviceExcelTemplate() {
            document.getElementById('downloadExchangeExcelTemplate').click();
        },
        clickUploadExcel() {
            document.getElementById('exchangeExcelUploadInput').click();
        },
        async computeUploadedExchangeDevices() {
            this.wrongFile = false;
            this.deviceUploadProgess = [];
            const input = this.$refs.exchangeExcelUploadInput;
            const file = input.files[0];
            input.value = '';
            this.blockBecauseError = false;

            if (!file) {
                this.wrongFile = true;
                return;
            }

            if (file.type !==
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                this.wrongFile = true;
                this.showSnackbar({
                    message: 'Dateiformat nicht zugelassen. Bitte Excel-Datei auswählen! (".xlsx")',
                    color: 'error'
                });
                return;
            }

            const deviceArray = [];
            const faultyEntries = [];

            // open excel file
            const fileData = await file.arrayBuffer();
            const mainWorkbook = xlsx.read(fileData);
            const workBookAsJson = xlsx.utils.sheet_to_json(mainWorkbook.Sheets["Ausleihgeräte"]);
            this.maximumDevices = Object.keys(workBookAsJson).length;
            workBookAsJson.map((row) => {
                    const deviceEntry = {
                        name: `${row['Gerätename'] || ''}`,
                        description: `${row['Beschreibung'] || ''}`,
                        icon: 'mdi-tablet-android',
                        color: '#91c364',
                    };
                    // check if valid
                    let error = null;
                    if (!(typeof deviceEntry.name === 'string' && deviceEntry.name.length > 0)) {
                        error = 'name';
                    }
                    if (deviceEntry.description.length && !(typeof deviceEntry.description === 'string')) {
                        error = 'description';
                    }
                    if (error) {
                        // TODO handle error
                        console.error(`Error in device row ${row["__rowNum__"]}: ${error}`);
                        faultyEntries.push({
                            ...deviceEntry,
                            index: row["__rowNum__"],
                            error
                        })
                    } else {
                        // push into array
                        deviceArray.push(deviceEntry);
                    }
                });

            this.deviceArray = deviceArray;
            this.faultyEntries = faultyEntries;

            if (!faultyEntries.length) {
                await this.pushToBackend();
            }
        },
        async pushToBackend() {
            this.excelImportProgessDisplay = true;
            // push devices to backend
            await this.deviceArray.reduce(async (previousPromise, device) => {
                const previous = await previousPromise;
                this.deviceUploadProgess = previous;
                const res = await backend.postExchange(device);
                const resJson = await res.json();
                previous.push(resJson);
                return previous;
            }, Promise.resolve([]));

            this.showSnackbar({
                message: `${this.deviceUploadProgess.length} Geräte erfolgreich angelegt!`,
            });
            // show end message
            this.$emit('refreshItems');

            // close dialog
            this.close();
        },
        close() {
            this.$emit('close');
        }
    }
}
</script>

<style scoped>

</style>
