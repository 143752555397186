import { createApp } from 'vue';
import App from './App.vue';
import '@fortawesome/fontawesome-free/css/all.css'; // FontAwesome
import 'quill/dist/quill.bubble.css'; // Quill styles
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import vuetify from './plugins/vuetify'; // Import the updated vuetify.js
import 'vuetify/styles';
import { setServiceWorkerUpdatedHandler, setServiceWorkerUpdatingHandler } from './registerServiceWorker';
import store from './store';
import { createRouter, createWebHistory } from 'vue-router';
import LibreOfficeDialog from '@/components/Apps/LibreOffice/LibreOfficeDialog.vue';
import PinboardBase from './components/Pinboard/PinboardBase';
import Version from './components/Version';
import JoinExternal from './views/JoinExternal';
import Login from './components/Login';
import ChatV2 from './components/ChatV2/ChatWidgetV2';
import ProfileManagement from './components/ProfileManagement.vue';
import Faq from './components/Faq';
import PlanSchueler from './components/Schueler/PlanSchueler';
import PlanEltern from './components/Eltern/PlanEltern';
import PlanLehrer from './components/Lehrer/PlanLehrer';
import Plan from './components/Verwaltung/Plan';
import Editor from './components/Editor';
import { RecycleScroller } from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import SimpleBar from 'simplebar-vue';
import 'simplebar-vue/dist/simplebar.min.css';
import { quillEditor } from 'vue3-quill'
import globalUtils from './plugins/globalUtils';
import { setupCalendar, Calendar, DatePicker } from 'v-calendar';
import 'v-calendar/style.css';
// import router from './router'; 
import Ripple from 'vuetify/lib/directives/ripple'; // Ripple directive

const auth = (roles) => async (to, from, next) => {
    const { account, stayLoggedIn } = store.state.auth;

    if (!account) {
        next({ name: 'login' });
        return;
    }

    const revalidatedAccount = await store.dispatch('auth/revalidateUserLogin');
    if (!(revalidatedAccount && roles.includes(revalidatedAccount.role))) {
        next({ name: 'login' });
        return;
    }

    if (!stayLoggedIn && !document.referrer.startsWith(location.origin)) {
        store.dispatch('auth/logoutUser');
        next({ name: 'login' });
    } else {
        next();
    }
};

const rerouteLoggedInUser = async (to, from, next) => {
    const { account } = store.state.auth;

    if (!account) {
        next({ name: 'login' });
        return;
    }

    const revalidatedAccount = await store.dispatch('auth/revalidateUserLogin');
    if (!revalidatedAccount) {
        next({ name: 'login' });
        return;
    }

    switch (revalidatedAccount.role) {
        case 'maintainer':
            next({ name: 'verwaltung.plan', query: to.query });
            break;
        case 'pupil':
            next({ name: 'schueler.plan', query: to.query });
            break;
        case 'teacher':
            next({ name: 'lehrer.plan', query: to.query });
            break;
        case 'parent':
            next({ name: 'eltern.plan', query: to.query });
            break;
        default:
            next({ name: 'login', query: to.query });
    }
};

const routes = [
    {
        path: '/',
        beforeEnter: rerouteLoggedInUser,
    },
    {
        path: '/libreoffice',
        name: 'libreoffice',
        component: LibreOfficeDialog,
    },
    {
        path: '/pinboardbase',
        name: 'pinboardbase',
        component: PinboardBase,
        beforeEnter: auth(['pupil', 'teacher', 'parent', 'maintainer']),
    },
    {
        path: '/pinboard/:invitationCode',
        name: 'pinboard',
        component: PinboardBase,
        beforeEnter: auth(['pupil', 'teacher', 'parent', 'maintainer']),
    },
    {
        path: '/version',
        name: 'version',
        component: Version,
    },
    {
        path: '/join-external/:externalBbbRoomId',
        name: 'joinExternal',
        component: JoinExternal,
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
    },
    {
        path: '/chat-v2',
        name: 'chat-v2',
        component: ChatV2,
        beforeEnter: auth(['pupil', 'teacher', 'parent', 'maintainer']),
    },
    {
        path: '/profile-managment',
        name: 'profile-managment',
        component: ProfileManagement,
        beforeEnter: auth(['pupil', 'teacher', 'parent']),
    },
    {
        path: '/faq',
        name: 'faq',
        component: Faq,
        beforeEnter: auth(['pupil', 'teacher', 'parent']),
    },
    {
        path: '/schueler/plan',
        name: 'schueler.plan',
        component: PlanSchueler,
        beforeEnter: auth(['pupil']),
    },
    {
        path: '/eltern/plan',
        name: 'eltern.plan',
        component: PlanEltern,
        beforeEnter: auth(['parent']),
    },
    {
        path: '/lehrer/plan',
        name: 'lehrer.plan',
        component: PlanLehrer,
        beforeEnter: auth(['teacher']),
    },
    {
        path: '/verwaltung/plan',
        name: 'verwaltung.plan',
        component: Plan,
        beforeEnter: auth(['maintainer']),
    },
    {
        path: '/editor/:mode',
        name: 'editor',
        component: Editor,
        beforeEnter: auth(['pupil', 'teacher']),
    },
    // Catch-all route for non-existing paths
    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        beforeEnter: rerouteLoggedInUser,
    },
];

// Create Vue Router instance
const router = createRouter({
    history: createWebHistory(),
    routes,
});
router.beforeEach((to, from, next) => {
    if (!Object.keys(to.query).length && Object.keys(from.query).length) {
        next({ name: to.name, query: from.query });
    } else {
        next();
    }
});

const app = createApp(App);

app.config.errorHandler = (err, instance, info) => {
    console.error('Error:', err);
    console.error('Component Instance:', instance);
    console.error('Info:', info);
  };

app.use(quillEditor);
app.use(store);
app.use(router);
app.use(vuetify);
app.use(globalUtils);
app.use(setupCalendar, {})
app.component('SimpleBar', SimpleBar);
app.component('RecycleScroller',RecycleScroller);
app.component('VCalendar', Calendar)
app.component('VDatePicker', DatePicker)
// Service worker updated handler
setServiceWorkerUpdatedHandler((event, registration) => {
    store.commit('snackbar/showSnackbar', {
        message: "Update heruntergeladen. Änderungen werden bei Seitenwechsel aktiviert. Bei Problemen leeren Sie den Browser-Cache.",
        color: "info",
    });

    router.afterEach(() => {
        window.location.reload();
    });

    registration.pushManager.getSubscription().then((sub) => {
        if (!sub) {
            console.log("Not subscribed to push service.");
        } else {
            store.dispatch("account/updateSubscription", sub);
        }
    });
});

// Service worker updating handler
setServiceWorkerUpdatingHandler(() => {
    store.commit('snackbar/showSnackbar', {
        message: "Update wird heruntergeladen..." ,
    });
});
// Optional: Register global directives
app.directive('ripple', Ripple);

app.mount('#app');
