<template>
    <v-tooltip location="top">
        <template v-slot:activator="{ props }">
            <v-btn
                size="x-small"
                class="fileListButton"
                :style="`width: ${buttonSize}px; height: ${buttonSize}px`"
                elevation="0"
                v-bind="props"
                @click="videoInVideoClicked"
            >
                <v-icon
                    color="#646464"
                    size="17"
                    class="fas fa-photo-video fileListButtonIcon"
                />
            </v-btn>
        </template>
        <span>Video in Video für Hörgeschädigte</span>
    </v-tooltip>
</template>

<script>

export default {
    name: "FileListVideoInVideoButton",
    props: {
        file: { type: Object, required: true },
        buttonSize: { type: Number, required: false, default: 36 },
    },
    data: () => ({
    }),
    mounted() {
    },
    methods: {
        videoInVideoClicked(event) {
            event.stopPropagation();
            this.$emit('videoInVideoClicked');
        }
    }
}
</script>

<style scoped>
.btnStyle {
    min-height: 36px;
    width: 37.75px
}
</style>
