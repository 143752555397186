<template>
    <div :class="currentFontSize">
        <div
            v-if="showTeacherUI"
            class="greyDivBorderColor"
        >
            <div
                style="padding: 12px; margin: 12px"
                class="greyDiv"
            >
                <input
                    v-model="text"
                    placeholder="Text eingeben"
                >
            </div>
        </div>
        <!-- Lehrer Controls rechts rausgeschoben -->
        <div
            v-if="showTeacherUI"
            class="editorOptionsFade"
            style="display: flex; flex-direction: column; position: absolute; right: -56px; top: 60px; width: 48px; height: 48px; min-width: 48px"
        >
            <div>
                <v-btn
                    class="editorColorPrimaryBackgroundOnHover editorComponentOptionsBtn"
                    @click="changeSize()"
                >
                    <img
                        :src="currentSizeIcon"
                        style="height: 32px"
                        class="iconToWhiteHover"
                        alt="Größe des Elements verändern"
                    >
                </v-btn>
            </div>
        </div>
        <!-- Pupil fields that are fillable with drag n drop -->
        <div v-if="showPupilUI">
            <div
                style="display: flex; width: 195px; height: 48px; border: 2px solid; border-radius: 6px; margin: 12px"
                :style="sizeState ? (sizeState === 2 ? 'width: 362px; height: 72px' : 'width: 277px; height: 60px') : 'width: 214px; height: 51px'"
                :class="currentSolutionHint|| solution ? 'editorColorNeutralBackground' : ''"
                class="greyDiv colorWhite"
                @drop="drop($event, index)"
                @dragenter="setCurrentSolutionHint"
                @dragleave="currentSolutionHint = undefined"
                @click="setClickSolution"
                @mouseenter="currentSolutionHint = lastClickedFree.text"
                @mouseleave="currentSolutionHint = undefined"
            >
                <div
                    class="mx-auto my-auto"
                    :style="!solution ? 'pointer-events: none' : ''"
                >
                    <span style="pointer-events: none">{{ currentSolutionHint ? currentSolutionHint : solution }}</span>
                    <v-icon
                        v-if="solution"
                        style="color: white; cursor: pointer; margin-left: 4px"
                        :size="sizeState === 0 ? '25' : sizeState === 1 ? '35' : '45'"
                        @click="resetSolution($event)"
                    >
                        mdi-close-circle-outline
                    </v-icon>
                </div>
            </div>
        </div>
        <div v-if="!showTeacherUI && !showPupilUI">
            <v-tooltip
                location="top"
                :disabled="text === solution || !elementOnCurrentPage"
            >
                <template v-slot:activator="{ props }">
                    <div
                        style="display: flex; width: 195px; height: 48px; border: 2px solid; border-radius: 6px; margin: 12px"
                        :class="solution === text ? 'editorColorPrimary backgroundColorWhite' : showSolution ? 'editorColorPrimaryBackground colorWhite' : 'editorColorWrongBackground colorWhite'"
                        v-bind="props"
                        @click="showSolution = !showSolution"
                    >
                        <div class="mx-auto my-auto">
                            <span style="pointer-events: none">{{ showSolution ? text : solution === undefined ? '-' : solution }}</span>
                        </div>
                    </div>
                </template>
                <span>{{ showSolution ? 'Lösung verstecken' : 'Lösung anzeigen' }}</span>
            </v-tooltip>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapMutations, mapState} from "vuex";
import sizeSmallIcon from "@/assets/Icons/EditorRedesign/schrift_klein.svg";
import sizeMediumIcon from "@/assets/Icons/EditorRedesign/schrift_mittel.svg";
import sizeLargeIcon from "@/assets/Icons/EditorRedesign/schrift_groß.svg";

export default {
    name: "EditorDragnDrop",
    props: {
        disableMovePupil: {type: Boolean, required: false, default: false },
        canvasWidth: {type: Number, required: true},
        canvasHeight: {type: Number, required: true},
        tabIndex: { type: Number, required: false, default: 0 },
        config: { type: Object, required: true },
        state: { type: String, required: true },
        exportMode: {type: Boolean, required: false, default: false },
        elementOnCurrentPage: { type: Boolean, required: false },
        previewPupilUI: { type: Boolean, required: false, default: false },
    },
    data: () => ({
        text: '',
        index: null,
        solution: '',
        currentSolutionHint: '',
        sizeState: 0,
        showSolution: false,

        sizeSmallIcon,
        sizeMediumIcon,
        sizeLargeIcon,
    }),
    computed: {
        ...mapState("editorStore",["lastDragged", "dragndropSizeState", "lastClickedFree"]),
        ...mapGetters("auth",["accountRole"]),
        ...mapGetters("editorStore",["dragndropfields"]),

        currentFontSize() {
            return this.sizeState ? (this.sizeState === 2 ? 'editorFontSize3' : 'editorFontSize2') : 'editorFontSize1';
        },

        currentSizeIcon() {
            return this.sizeState ? (this.sizeState === 2 ? this.sizeLargeIcon : this.sizeMediumIcon) : this.sizeSmallIcon;
        },
        showPupilUI() {
            return this.exportMode || this.state === 'pupil' || this.previewPupilUI;
        },
        showTeacherUI() {
            return !this.exportMode && !this.previewPupilUI && this.state === 'creator';
        },
    },
    watch: {
        text() {
            this.mutateSingleField({text: this.text, index: this.index});
            this.saveState();
        },
        solution() {
            this.saveState();
        },
        dragndropSizeState() {
            this.sizeState = this.dragndropSizeState;
        },
        sizeState() {
            this.saveState();
        },
        previewPupilUI: {
            handler(val, oldVal) {
                this.solution = '';
            }
        }
    },
    mounted() {
        if (!this.config.dragndropFreeTargetConfig) {
            this.config.dragndropFreeTargetConfig = {};
            this.config.identifier = this._uid;
            this.config.dragndropFreeTargetConfig.text = this.text;
            this.config.dragndropFreeTargetConfig.solution = this.solution;
            this.config.dragndropFreeTargetConfig.sizeState = this.sizeState;
            this.index = this.dragndropfields.length;
            this.config.dragndropFreeTargetConfig.index = this.index;
            this.addDragnDropField({ text: this.text, index: this.index });
        } else {
            if(this.config._id) {
                this.config.identifier = this.config._id;
            }
            this.text = this.config.dragndropFreeTargetConfig.text;
            this.index = this.config.dragndropFreeTargetConfig.index;
            this.solution = this.config.dragndropFreeTargetConfig.solution;
            this.sizeState = this.config.dragndropFreeTargetConfig.sizeState;
        }
    },
    methods: {
        ...mapMutations('editorStore', ["addDragnDropField", "mutateSingleField", "mutateLastClickedFree"]),

        changeSize() {
            switch (this.sizeState) {
                case 0:
                    this.sizeState = 1;
                    break;
                case 1:
                    this.sizeState = 2;
                    break;
                case 2:
                    this.sizeState = 0;
                    break;
                default:
                    break;
            }
        },

        saveState() {
            // save values by overwriting the calcConfig properties
            // which get saved in the db schoolSubjectAppointmentUploadMessage
            if(!this.previewPupilUI) {
                this.config.dragndropFreeTargetConfig.text = this.text;
                this.config.dragndropFreeTargetConfig.index = this.index;
                this.config.dragndropFreeTargetConfig.solution = this.solution;
                this.config.dragndropFreeTargetConfig.sizeState = this.sizeState;
            }
        },

        drop(event, index) {
            event.preventDefault();
            this.currentSolutionHint = '';
            this.solution = event.dataTransfer.getData("fieldText");
        },

        setCurrentSolutionHint() {
            this.currentSolutionHint = this.lastDragged.text;
        },

        setClickSolution() {
            this.solution = this.lastClickedFree.text;
            this.mutateLastClickedFree(undefined);
        },

        resetSolution(event) {
            this.solution = undefined;
            event.stopPropagation();
        }
    },
}
</script>

<style scoped lang="scss">
    input:focus {
        outline: none;
    }
    textarea:focus {
        outline: none;
    }
    .colorWhite {
      color: white;
    }

    .backgroundColorWhite {
      background-color: white;
    }
</style>
