<template>
    <v-dialog
        :model-value="showPinDialog"
        max-width="300"
        persistent
    >
        <v-card style="min-height:200px;">
            <v-card-title
                class="px-4 py-2 d-flex justify-space-between"
                :style="`background-color: ${headerColor}; color: white;`"
            >
                {{ (accountHasPin || hasPin) ? 'PIN eingeben' : 'PIN vergeben' }}
                <img
                    :src="cancelIcon"
                    class="iconToWhite icon30"
                    style="cursor: pointer;"
                    alt="Pin schließen"
                    @click="close"
                >
            </v-card-title>
            <div
                class="px-2 py-1"
                style="font-size: 15px; min-height:80px;"
            >
                <div
                    v-if="accountHasPin || hasPin"
                >
                    <div
                        v-if="wrongPins > 0"

                        class="d-flex flex-row justify-space-around"
                    >
                        <p
                            style="font-size:14px"
                        >
                            Verbleibende Eingaben: {{ remainingTries }}
                        </p>
                    </div>
                    <div
                        class="d-flex justify-space-around align-center"
                    >
                        <v-otp-input
                            v-model="accountPin"
                            type="password"
                            length="4"
                            style="max-width: 250px; max-height: 60px;"
                            class="custom-otp-input"
                            @finish="validateAccountPin"
                        />
                    </div>
                    <div
                       
                        style="position: relative; margin-top: -20px;"
                    >
                        <p
                            v-if="usage !=='login'"
                            style="font-size:14px"
                            class="mb-2"
                        >
                            Weitere Infos zur PIN finden sie unter Profileinstellungen > Sicherheit > PIN. Bei 5 Fehleingaben wird Ihr Zugang gesperrt.
                        </p>
                        <p
                            v-else
                            style="font-size:14px"
                            class="mb-2"
                        >
                            Geben Sie ihre einst vergebene persönliche PIN ein. Die Schulverwaltung kann die PIN deaktivieren oder zurücksetzen.
                        </p>
                    </div>
                </div>
                <div v-else>
                    <div class="d-flex flex-column">
                        <p
                            style="font-size:14px"
                            class="mb-2"
                        >
                            {{ usage === 'login' ? 'Bitte PIN vergeben. Die PIN-Abfrage ist aktiviert und soll ihren Zugang zusätzlich schützen. Wenden Sie sich bei Fragen an die Schulverwaltung':'4-stellige PIN ist als zusätzliche Sicherheit für diese Funktion aktiviert' }}  {{ accountRole !=='maintainer'? '. Wenden Sie sich bei weiteren Fragen an die Schulverwaltung':'' }}.
                        </p>
                        <p>PIN vergeben (Zahlen, Buchstaben) </p>
                        <v-otp-input
                            v-model="newPin"
                            type="password"
                            length="4"
                            style="max-width: 250px; max-height: 60px;"
                            class="custom-otp-input"
                        />
                    </div>
                    <div class="d-flex flex-column">
                        <p>PIN wiederholen</p>
                        <v-otp-input
                            v-model="repeatNewPin"
                            type="password"
                            length="4"
                            style="max-width: 250px;"
                            class="custom-otp-input"
                        />
                    </div>
                    <div class="d-flex flex-row mb-2 justify-end">
                        <v-btn
                            color="success"
                            :disabled="!(newPin === repeatNewPin && newPin !== null)"
                            @click="saveNewPin"
                        >
                            Bestätigen
                        </v-btn>
                    </div>
                </div>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import * as backend from '@/api/backend';
import cancelIcon from '@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg';

export default {
 name: "PinDialog",
//     directives: {
//     'numeric-only': {
//       // Directive definition
//       bind(el, binding, vnode) {
//         el.addEventListener('keydown', (e) => {
//           // Allow: backspace, delete, tab, escape, enter, and numbers
//           if ([46, 8, 9, 27, 13].includes(e.keyCode) ||
//               // Allow: Ctrl/cmd+A
//               (e.keyCode === 65 && (e.ctrlKey === true || e.metaKey === true)) ||
//               // Allow: Ctrl/cmd+C
//               (e.keyCode === 67 && (e.ctrlKey === true || e.metaKey === true)) ||
//               // Allow: Ctrl/cmd+X
//               (e.keyCode === 88 && (e.ctrlKey === true || e.metaKey === true)) ||
//               // Allow: home, end, left, right
//               (e.keyCode >= 35 && e.keyCode <= 39)) {
//                return;
//           }
//           // Ensure that it is a number and stop the keypress if not
//           if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
//             e.preventDefault();
//           }
//         });
//       }
//     }
//   },
    props: {
       account: {required: true},
       showPinDialog:{required: true,},
       usage: {required: true},
       hasPin: {required: false, default: false},

    }, 
    emits: ['update:showPinDialog', 'close'],
    data: () => ({
       cancelIcon,
        newPin: null,
        repeatNewPin: null,
        accountPin: null,
        wrongPins: 0,
    }),
    computed: {
        ...mapGetters('auth',['accountHasPin','accountRole']),
        headerColor(){
            return this.usage === 'messages' ? 'rgb(var(--v-theme-parentLetterPurple))':
                         this.usage === 'diary' ? 'rgb(var(--v-theme-dunkelgruen))' :
                                this.usage === 'chat' ? 'rgb(var(--v-theme-chatColor))':
                                    this.usage === 'users' ? 'rgb(var(--v-theme-rot))' :
                                    'rgb(var(--v-theme-headerblue))';
        },
        snackbarText(){
            return this.usage === 'messages' ? 'Sie können die Elternbriefe nun einsehen.' : this.usage === 'diary' ? 'Sie können das Klassenbuch nun öffnen.' :  this.usage === 'login' ?'Sie werden nun eingeloggt.':'';
        },
        remainingTries(){
            return 5-this.wrongPins;
        }
    },
    methods:{
        ...mapMutations('snackbar', ['showSnackbar']),
        ...mapMutations('auth', ['setAccountHasPin']),
        ...mapActions('auth', ['setPinValidTrue']),
        close(){
            this.$emit('close')
        },
        accountPinValid(){
            this.$emit('accountPinValid');
        },
        async saveNewPin(){
            const res = await backend.updateAccountPin(this.account._id, {pin: this.newPin});
            const newAcc = await res.json();
            if(res.status === 200){
                this.showSnackbar({message:'PIN erfolgreich geändert. Sie können den PIN nun verwenden.', color:'success'});
                this.accountPinValid();
                this.setAccountHasPin(true);
                await this.setPinValidTrue();
            }else{
                this.showSnackbar({message:'Beim Erstellen der PIN ist etwas schief gelaufen. Bitte laden Sie die Seite neu. Falls das Problem bestehen bleibt, wenden Sie sich an den Support.', color:'error'});
            }
            this.newPin = null;
            this.repeatNewPin = null;
        },
         async validateAccountPin(){
            const res = await backend.isPinValid(this.account._id,{ pin: this.accountPin });
            if(res.status === 200){
                this.showSnackbar({message:`Gültige Pin. ${this.snackbarText}`, color:'success'});
                this.accountPin = null;
                this.wrongPins = 0;
                this.accountPinValid();
                await this.setPinValidTrue();
                this.close();
            }else if(res.status === 401){
                this.showSnackbar({ message:'Pin wurde aufgrund zu vielen Fehlversuchen gesperrt. Melden Sie sich bei der Verwaltung um diesen wieder freizuschalten.', 
                        color: 'error', timeout: 10000});
                this.wrongPins = 5;
                this.accountPin = null;
            }else{
                const account = await res.json();
                this.wrongPins = account.wrongPins;
                if(account.wrongPins === 4){
                    this.showSnackbar(
                        {message:'Pin ungültig. Pin wurde bereits 4 mal falsch eingegeben. Beim nächsten Fehlversuch wird die Pin aufgrund zu häufiger Falscheingaben gesperrt.', 
                        color: 'error', timeout: 10000});
                          this.accountPin = null;
                }else{
                    this.wrongPins = account.wrongPins;
                    this.showSnackbar({message:'Pin ungültig', color: 'error'});
                    this.accountPin = null;
                }
            }
       },
    }
}
</script>

<style>

</style>