<template>
    <v-tooltip
        :model-value="typeof showTip === 'boolean' ? showTip : undefined"
        v-bind="$attrs"
        content-class="tooltip"
        @input="typeof showTip === 'boolean' ? $emit('showChange', $event.target.value) : undefined"
    >
        <template v-slot:activator="{ props }">
            <div
                :class="showTip && 'content'"
               
                :style="`
          --overlay-left: ${overlayLeft};
          --overlay-top: ${overlayTop};
          --overlay-width: ${overlayWidth};
          --overlay-height: ${overlayHeight};
        `"
                v-bind="props"
                @click="typeof showTip === 'boolean' ? hideTip() : null"
            >
                <slot />
            </div>
        </template>
        <div
            class="tooltip"
            @click="typeof showTip === 'boolean' ? hideTip() : null"
        >
            <v-container class="pa-0">
                <v-row
                    no-gutters
                    align="center"
                    justify="center"
                >
                    <v-col
                        cols="auto"
                    >
                        <v-img
                            :src="image"
                            cover
                            width="11rem"
                        />
                    </v-col>
                    <v-col
                        class="pl-2"
                        align-self="start"
                    >
                        <v-row
                            v-if="title"
                            dense
                        >
                            <v-col class="pt-0 pr-2">
                                <h3>
                                    {{ title }}
                                </h3>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col
                                id="text-col"
                                class="pb-2 pr-2"
                            >
                                <span>
                                    {{ text }}
                                </span>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn
                            variant="text"
                            class="pa-0"
                            @click="textToSpeak()"
                        >
                            <v-img
                                :src="speakerBtn"
                                cover
                                height="3rem"
                                width="3rem"
                            />
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </v-tooltip>
</template>

<script>
import speakerBtn from '@/assets/Tooltip/lautsprecher_icon.svg';
import { textToSpeech } from '@/util/textToSpeech';

export default {
  name: 'ETooltip',
  model: {
    prop: 'showTip',
    event: 'showChange'
  },
  props: {
    image: { type: String, required: false, default: null },
    title: { type: String, required: false, default: '' },
    text: { type: String, required: false, default: '' },
    showTip: { type: Boolean, required: false, default: undefined },
    overlayLeft: { type: String, required: false, default: '0' },
    overlayTop: { type: String, required: false, default: '0' },
    overlayWidth: { type: String, required: false, default: '100%' },
    overlayHeight: { type: String, required: false, default: '100%' },
    closeCallback: { type: Function, required: false, default: () => {} },
  },
  data() {
    return {
      speakerBtn,
    };
  },
  methods: {
    hideTip(event) {
      this.$emit('showChange', false);
      this.closeCallback();
    },
    textToSpeak() {
      if (this.text)
        textToSpeech(this.text);
    },
  }
};
</script>

<style lang="scss" scoped>

.tooltip {
  padding: 5px !important;
  pointer-events: initial;
  border-radius: 5px;
  background: rgb(105,150,205);
  background: linear-gradient(90deg, rgba(105,150,205,1) 0%, rgba(80,95,170,1) 100%);
}

#text-col {
  max-width: 15rem;
  font-size: medium;
  line-height: 1.33;
}

.content {
  position:relative;
  z-index: 1;
}

.content:after {
  content:'';
  margin-top: -5px;
  margin-left: -5px;
  position:absolute;
  top: var(--overlay-top);
  left: var(--overlay-left);
  width: calc(var(--overlay-width) + 10px);
  height: calc(var(--overlay-height) + 10px);
  box-shadow: 0 0 0 9999px rgba(0,0,0,.7);
  border-radius: 5px;
}
</style>
