<template>
    <div>
        <v-overlay
            v-if="isOpen"
            opacity="0.80"
            z-index="10"
            @click="toggleAppsOpen"
        />
        <div
            id="container"
            :style="isOpen ? 'z-index: 11' : 'z-index: 9'"
        >
            <v-badge
                color="#ff0000"
                :content="newEventsCount"
                :model-value="newEventsAvailable > 10 ? '10+': newEventsAvailable"
                location="top left"
                class="ml-4"
                :style="isOpen ? 'z-index: 11' : 'z-index: 9'"
                style="width: 100%"
            >
                <v-tooltip
                    :model-value="shortcutTooltips"
                    :disabled="!showTooltips"
                    location="bottom"
                >
                    <template v-slot:activator="{ props }">
                        <v-btn
                            id="AppsWidgetButton"
                            style="background-color: #FFFFFF; width: 50px; height: 50px; border-radius: 8px;"
                            size="x-small"
                            elevation="0"
                            :class="{chatClicked: isOpen}"
                            v-bind="props"
                            @click="toggleAppsOpen"
                        >
                            <v-icon
                                color="rgb(var(--v-theme-headerblue))"
                                :class="{iconToWhite: isOpen}"
                                size="xx-large"
                                :alt="isOpen? 'Apps schließen' : 'Apps öffnen'"
                            >
                                mdi-apps
                            </v-icon>
                        </v-btn>
                    </template>
                    <div style="display: flex; flex-direction: column; text-align: center">
                        <span><span style="text-decoration: underline white" />Apps</span>
                    </div>
                </v-tooltip>
                <AppWidget
                    v-show="isOpen"
                    id="AppWidget"
                    @update-event-count="updateEventCount"
                />
            </v-badge>
        </div>
    </div>
</template>

<script>
import AppWidget from "@/components/Apps/AppWidget";
import {mapState} from "vuex";

export default {
    name: "BabyAppDropDown",
    components: {
        AppWidget
    },
    props: {
        popupApps: {required: false, default: false},
    },
    data() {
        return {
            isOpen: false,
            newEventsAvailable: false,
            newEventsCount: 0,
        }
    },
    computed : {
        ...mapState("tooltips", ["shortcutTooltips", "showTooltips"]),
    },

    watch: {
      popupApps(newVal) {
          if (newVal) {
              this.isOpen = newVal;
          }
        }  ,
    },

    methods: {
        toggleAppsOpen() {
            if(!this.isOpen) {
                document.getElementById("AppsWidgetButton").focus();
            }
            this.isOpen = !this.isOpen;
            if (this.isOpen) {
                this.$emit('openPopup');
            }
        },
        updateEventCount(count) {
            this.newEventsCount = count;
            this.newEventsAvailable = count > 0;
        },
    },
}
</script>

<style lang="scss" scoped>
#container {
    position: relative;
}

#AppWidget {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 1;
    width: 400px;
    max-width: 95vw;
}

.animateIcon {
    overflow: hidden;
}

.animateIcon:after {
    animation: shine 60s ease-in-out infinite;
    animation-fill-mode: forwards;
    content: "";
    position: absolute;
    top: -110%;
    left: -210%;
    width: 200%;
    height: 200%;
    opacity: 0;
    transform: rotate(30deg);

    background: rgba(255, 255, 255, 0.0);
    background: linear-gradient(
            to right,
            rgba(112, 112, 112, 0.2) 0%,
            rgba(112, 112, 112, 0.2) 77%,
            rgba(112, 112, 112, 0.5) 92%,
            rgba(112, 112, 112, 0.0) 100%
    );
}

/* Hover state - trigger effect */

/* Active state */
.animateIcon:active:after {
    opacity: 0;
}

@keyframes shine{
    1% {
        opacity: 1;
        top: -30%;
        left: -30%;
        transition-property: left, top, opacity;
        transition-duration: 0.7s, 0.7s, 0.15s;
        transition-timing-function: ease;
    }
    5% {
        opacity: 0;
        top: -30%;
        left: -30%;
        transition-property: left, top, opacity;
    }
}

//filter generated with https://codepen.io/sosuke/pen/Pjoqqp
.iconToWhite {
    filter: brightness(0) saturate(100%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

.iconToHeaderblue {
    filter: brightness(0) saturate(100%) invert(21%) sepia(43%) saturate(391%) hue-rotate(183deg) brightness(94%) contrast(92%);
}

.appClicked {
    background-color: rgb(var(--v-theme-chatColor)) !important;
}
</style>
