<template>
    <div>
        <v-dialog
            v-model="openDialog"
            persistent
            fullscreen
            scrollable
            :scrim="false"
            transition="dialog-bottom-transition"
            @keydown.esc="notifyCloseList"
        >
            <v-card
                style="background-color: white"
            >
                <v-toolbar
                    color="rgb(var(--v-theme-headerblue))"
                    style="max-height: 65px; justify-content: center;"
                >
                    <v-toolbar-title style="width: 250px">
                        <v-row class="ml-2">
                            <img
                                :src="libraryIcon"
                                alt="Bibliothek"
                                style="height: 50px; margin-right: 5px"
                            >
                            <div v-if="windowWidth > 450">
                                <p style="color: white; margin-top: 10px">
                                    Online Bibliothek
                                </p>
                            </div>
                        </v-row>
                    </v-toolbar-title>
                    <v-spacer />
                    <v-toolbar-items class="align-center">
                        <v-btn
                            size="x-small"
                            variant="text"
                            class="mx-4"
                            elevation="0"
                            @click="mutateVfsGridWidthPctLibrary"
                        >
                            <v-icon
                                size="large"
                                color="white"
                            >
                                mdi-view-grid
                            </v-icon>
                        </v-btn>
                        <v-btn
                            size="x-small"
                            variant="text"
                            class="mx-4"
                            elevation="0"
                            @click="notifyCloseList"
                        >
                            <img
                                :src="abbrechenIcon"
                                alt="Pinnwandliste schließen"
                                style="height: 40px; filter: brightness(1000%)"
                            >
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <!-- Using the Legacy VFS because I only have 1 Day until Showcase
                Recommended would be writing a custom Interface -->
                <div
                    v-if="docRegistry"
                    class="my-3"
                    style="overflow-y: scroll; display: flex; justify-content: center"
                >
                    <virtual-file-system-doc-registry
                        ref="docRegistryVFS"
                        :style="windowWidth > 900 ? 'width: 75%' : 'width: 90%'"
                        :folder-depth="1"
                        :folder-type="'documentRegistry'"
                        :scroll-top="0"
                        :scrolled-to-end="false"
                        :inital-folder-id-tree="folderIdTreeToDocumentRegistry"
                        @mutate-folder-depth="(val) => mutateFolderDepth(val)"
                        @copy-document-to-backend="prepareUploadFromDocumentRegistry"
                    />
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import * as backend from "../../api/backend";
import {mapActions, mapMutations, mapState} from "vuex";
import abbrechenIcon from "../../assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg";
import libraryIcon from '@/assets/Icons/FaceliftIcons/bibliothek_app.svg';
import VirtualFileSystemDocRegistry from './VirtualFileSystemDocRegistry.vue';

export default {
    name: "LibraryApp",
    components: {
        VirtualFileSystemDocRegistry,
    },
    data() {
        return {
            // Icons
            abbrechenIcon,
            libraryIcon,

            // State Managment
            openDialog: true,
            docRegistry: false,
            folderDepth: 0,
            folderIdTreeToDocumentRegistry: null,
        }
    },
    computed: {
        ...mapState('util', ['windowWidth']),
        ...mapState('documentRegistry', ['documentRegistryDocuments', 'documentRegistryRootFolder', 'documentRegistryFolders']),

    },
    async mounted() {
        // prepare docReg state in store
        await this.loadDocumentRegistryDocuments();
        await this.loadDocumentRegistryRootFolder();
        await this.loadDocumentRegistryFolders();

        // generate entry point from store
        this.folderIdTreeToDocumentRegistry = [{
            files: [...this.documentRegistryDocuments],
            folders: [...this.documentRegistryFolders],
            root: this.documentRegistryRootFolder,
            name: 'documentRegistry',
        }];
        // load docReg
        this.docRegistry = true;
    },
    methods: {
        ...mapActions('documentRegistry', ['loadDocumentRegistryDocuments', 'loadDocumentRegistryRootFolder', 'loadDocumentRegistryFolders']),
        ...mapMutations('vfs', ['mutateVfsGridWidthPctLibrary']),

        notifyCloseList() {
            this.$emit('closeList');
        },
        mutateFolderDepth(val) {
            if (val >= 0) {
                this.folderDepth = val;
            }
        },
        async prepareUploadFromDocumentRegistry(el, url, destination) {
            let blob = await fetch(url).then(res => res.blob());
            let file = new File([blob], el.name, {type: "application/pdf" });
            await this.safeNewPrivateFolderFile({
                data: file,
                fileName: el.name,
            });
            this.showSnackbar({ message: "Die Datei wurde im privaten Ordner hinterlegt." });
        },

    }
}
</script>

<style scoped>

</style>
