<template>
    <v-dialog
        v-if="image"
        :model-value="image"
        scrim="rgba(0, 0, 0, 0.9)"
        class="dialog"
        width="unset"
        @click:outside="closeFullscreenDialog"
    >
        <div class="dialogChild">
            <div>
                <!-- The fullscreen image itself -->
                <img
                    :src="image"
                    class="fullscreenImage"
                    :alt="imageAltText"
                >
            </div>
            <!-- Options, like download or close -->
            <div class="fullScreenOptionsContainer">
                <img
                    :src="cancelIcon"
                    alt="Vollbild schließen"
                    class="iconToWhite pointer optionsIcon"
                    @click="closeFullscreenDialog"
                >
                <img
                    :src="herunterladenIcon"
                    alt="Bild herunterladen"
                    class="iconToWhite pointer optionsIcon"
                    @click="downloadImage(image)"
                >
            </div>
        </div>
    </v-dialog>
</template>

<script>
import downloadIcon from '../../assets/Icons/download-116.svg';
import cancelIcon from '@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg';
import herunterladenIcon from "@/assets/Icons/FaceliftIcons/herunterladen.svg";

export default {
    name: "FullscreenImageDialog",
    props: {
        image: { required: true },
        fileName: { required: false, type: String, default: 'Bild' },
        imageAltText: { required: true, type: String },
    },
    data: () => ({
        downloadIcon,
        cancelIcon,
        herunterladenIcon
    }),
    methods: {
        downloadImage(fileUrl) {
            let el = document.createElement("a");
            el.setAttribute("href", fileUrl);
            el.setAttribute("download", this.fileName);
            document.body.appendChild(el);
            el.click();
            el.remove();
        },

        closeFullscreenDialog() {
            this.$emit('close');
        }
    }
}
</script>

<style scoped>
    .dialog {
        height: 90%;
        width: unset;
    }

    .dialogChild {
        width: 100%;
        display: flex;
        flex-direction: row;
        position: relative;
        max-height: 90%;
    }

    .fullScreenOptionsContainer {
        width: 9%;
        display: flex;
        flex-direction: column;
        padding-left: 1%;
    }

    .fullscreenImage {
        max-height: 85vh;
        max-width: 100%;
    }

    .iconToWhite {
        filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
    }

    .optionsIcon {
        height: 50px;
        cursor: pointer;
    }
</style>
