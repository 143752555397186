import * as backend from '../../api/backend';
import {checkResponseStatus} from "../../util/check";

const defaultstate = {
    externalApps: []
}

const mutations = {
    setExternalApps: (state, externalApps) => {
        state.externalApps = externalApps;
    },
    clearExternalApps: (state, externalApps) => {
        state.externalApps = [];
    },
    addNewApp: (state, app) => {
        // Todo move update of event bus into here
        const index = state.externalApps.findIndex(el => el._id === app._id);
        if (index >= 0) {
            state.externalApps[index] = app;
        }
        else{
            state.externalApps.push(app);
        }
    },
};

const getters = {
    externalApps: state => state.externalApps
};

const actions = {
    async pushNewExternalApp({commit, getters}, app){
        commit('addNewApp',app);
    },
    async getAllExternalApps({ commit, getters }, update) {
        if (getters.externalApps && getters.externalApps.length > 0 && !update) {
            return getters.externalApps;
        }
        const response = await backend.getAllExternalApps();
        const externalApps = await response.json();
        return externalApps;
    },
    async postExternalApp({commit}, body) {
        const response = await backend.postExternalApp(body);

        return await response.json();
    },
    async deleteExternalApp({commit}, params) {
        const response = await backend.deleteExternalApp(params);

        return await response.json();
    },
    async patchExternalApp({commit}, params) {
        const id = params._id;
        const response = await backend.patchExternalApp(id, params);

        return await response.json();
    },
    async getExternalApps({commit, getters}, params) {
        const update = params.update;
        if (getters.externalApps && getters.externalApps.length > 0 && !update) {
            return getters.externalApps;
        }
        const userId = params.userId;
        const response = await backend.getExternalApps(userId);
        const externalApps = await response.json();
        commit('setExternalApps', externalApps);
        return externalApps;
    },
    async markExternalAppSeen ({ commit, dispatch }, appId) {
        try {
            const res = await backend.markExternalAppSeen(appId);
            await checkResponseStatus(200, res);
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    }
};

export default {
    namespaced: true,
    actions,
    mutations,
    getters
}

