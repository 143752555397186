import * as backend from '../../api/backend'
import { checkResponseStatus } from "@/util/check";

const getDefaultLateTime = (pupilId) => Object.freeze({
    pupil: pupilId,
    note: '',
    missingTime: 0,
    excused: false,
    unexcused: true,
    isFullday: false,
});
const enhanceDiaryEntry = (entry, lateTimesByAppointmentId) => {
    const enhancedEntry = entry;    
    const enhancedPupils = enhancedEntry.pupils.map((pupil)=>{
        const participant = pupil;    
        if(!participant.diaryNote){
                participant.diaryNote = '';
            }
            if(entry.diaryEntryNotes){
                const index = enhancedEntry.diaryEntryNotes.findIndex(note => note.pupil.toString() === participant._id.toString());
                if(index > -1){
                    participant.diaryEntryNoteOnAppointment = enhancedEntry.diaryEntryNotes[index];
                    participant.diaryNote = enhancedEntry.diaryEntryNotes[index].text;
                }
            }
            if (lateTimesByAppointmentId[entry.appointment._id]) {
                const lateTimeObject = lateTimesByAppointmentId[entry.appointment._id]
                    .filter(lateTime => lateTime.pupil === participant._id);
    
                if (lateTimeObject.length > 0) {
                    participant.lateTime = {
                        ...lateTimeObject[0], // Copy the existing lateTime
                        unexcused: !lateTimeObject[0].excused, // Adjust excused status
                    };
                } else {
                    // Lazy initialization of default lateTime
                    participant.lateTime = getDefaultLateTime(participant._id);
                }
            } else {
                // Lazy initialization of default lateTime
                participant.lateTime = getDefaultLateTime(participant._id);
            }
            return participant;
    })
        
    enhancedEntry.pupils = enhancedPupils;
    let dateOldStart = new Date(entry.appointment.timeslot.start);
    dateOldStart.setFullYear(2020, 11, 18);
    let dateOldEnd = new Date(entry.appointment.timeslot.end);
    dateOldEnd.setFullYear(2020, 11, 18);
    enhancedEntry.appointment.timeslot.start = dateOldStart.toISOString();
    enhancedEntry.appointment.timeslot.end = dateOldEnd.toISOString();

    return enhancedEntry;
};
const state = {
    diaries: [],
    diaryEntries: [],
};

const actions = {
    // get all needed data for the provided day
    async getDiary({ commit, dispatch }, params) {
        try {
            const res = await backend.getDay(params);
            await checkResponseStatus(200, res);
            return await res.json();
        }
        catch (err) {
            //console.error(err);
            return err?.response?.status;
        }
    },
    async getDiaryV2({ commit, getters,dispatch, rootGetters}, params) {
        try {
            if(getters.diaries.length > 0 
                && getters.diaries.findIndex(diary => (diary.group === params.groupId) && (diary.day === params.startOfDay)) >=0
                 && !params.update){
                    const foundDiaryIndex = getters.diaries.findIndex(diary => (diary.group === params.groupId) && (diary.day === params.startOfDay))
                    
                    commit('setDiaryEntries', getters.diaries[foundDiaryIndex].diaryEntries);

                return getters.diaries[foundDiaryIndex];
            }
            const res = await backend.getDiary(params.groupId, params.startOfDay);
            await checkResponseStatus(200, res);
            const diary = await res.json();
            dispatch("lateTimes/getLateTimesForGroupOnDay",{groupId: params.groupId, day: params.startOfDay},{root: true})

            // if(diary.diaryEntries && diary.diaryEntries.length >0){
            //     const enhancedEntries = diary.diaryEntries.map((diaryEntry)=>{
            //         const enhanced =  enhanceDiaryEntry(diaryEntry, rootGetters['lateTimes/getLateTimesByAppointmentId']);
            //         return enhanced
            //     });
            //     diary.diaryEntries = enhancedEntries;
            //     commit('addOrUpdateDiary', diary);
            //     commit('setDiaryEntries', enhancedEntries);
            //     return diary;
            // }else{
            //     commit('addOrUpdateDiary', diary);
            //     return diary;
            // }
            commit('addOrUpdateDiary', diary);
            if(getters.diaryEntries.length === 0){
                commit('setDiaryEntries', diary.diaryEntries);
            }
            else{
                await Promise.all(diary.diaryEntries.map(entry => {
                    commit('addOrUpdateDiaryEntry', entry)
                }))
            }
            return diary;
        }
        catch (err) {
            //console.error(err);
            return err?.response?.status;
        }
    },
    async getDiaryExport({ rootGetters, dispatch}, params){
        const res = await backend.getDiary(params.groupId, params.startOfDay);
            await checkResponseStatus(200, res);
            const diary = await res.json();
            if(diary.diaryEntries && diary.diaryEntries.length >0){
                const enhancedEntries = diary.diaryEntries.map((diaryEntry)=>{
                    const enhanced =  enhanceDiaryEntry(diaryEntry, rootGetters['lateTimes/getLateTimesByAppointmentId']);
                    return enhanced
                });
                diary.diaryEntries = enhancedEntries;
                return diary;
            }
            return diary;
    },
    // create a new diary entry for a specific day and group
    async postDiary({ commit, dispatch }, params) {
        try {
            const res = await backend.postDay(params);
            await checkResponseStatus(201, res);
            return res;
        }
        catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },
    async getDiaryEntryUpdate({commit, dispatch, rootGetters}, params){
        try{
            const res = await backend.getDiaryEntry(params.diaryEntryId);
            const updatedDirayEntry = await res.json();
            // const enhanced = enhanceDiaryEntry(updatedDirayEntry, rootGetters['lateTimes/getLateTimesByAppointmentId']);
            commit('addOrUpdateDiaryEntry', updatedDirayEntry);
        }
        catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    }
};

const mutations = {
   addOrUpdateDiary: (state, diary) => {
    const index = state.diaries.findIndex(el => {
        return  el._id === diary._id;
    });

    if (index >= 0){
        state.diaries[index] = diary;

    }else{
        state.diaries.push(diary);
    }
   },
   addOrUpdateDiaryEntry: (state, diaryEntry) => {
    const index = state.diaryEntries.findIndex(el => {
        return  el._id === diaryEntry._id;
    });

    if (index >= 0){
        state.diaryEntries[index] = diaryEntry;

    }else{
        state.diaryEntries.push(diaryEntry);
    }
   },

   updateDiaryEntry: (state, diaryEntry)=>{
    const diaryIndex = state.diaries.findIndex(diary => diary.diaryEntries.some(entry => entry._id.toString() === diaryEntry._id.toString()));
    if(diaryIndex >=0){
        const entryIndex = state.diaries[diaryIndex].diaryEntries.findIndex(
            entry => entry._id.toString() === diaryEntry._id.toString()
        );
        state.diaries[diaryIndex].diaryEntries[entryIndex] = diaryEntry;
    }

    const index = state.diaryEntries.findIndex(el => {
        return  el._id.toString() === diaryEntry._id.toString();
    });

    if (index >= 0){
        state.diaryEntries[index] = diaryEntry;
    }
   },
    setDiaries: (state, diaries)=>{
      state.diaries = diaries;
    },
   setDiaryEntries: (state, diaryEntries)=>{
    console.log('setallAtOnce',diaryEntries.length);
     state.diaryEntries = diaryEntries;
   },

};

const getters = {
    diaries: (state) => state.diaries,
    diaryEntries: (state) => {
        return [...state.diaryEntries].sort(function (a, b) {
            return new Date(a.appointment.timeslot.start) - new Date(b.appointment.timeslot.start);})
    },
    diaryByDayAndGroup: (state) => (day, groupId) => {
        return state.diaries.find(diary => {
            const diaryDate = new Date(diary.day);
            const comparisonDate = new Date(day);
      
            // Compare the year, month, and day only (ignoring time)
            return (
              diaryDate.getUTCFullYear() === comparisonDate.getUTCFullYear() &&
              diaryDate.getUTCMonth() === comparisonDate.getUTCMonth() &&
              diaryDate.getUTCDate() === comparisonDate.getUTCDate() &&
              diary.group .toString() === groupId.toString()
            );
          });
      },
    
    diaryEntriesByDiary: (state) => (diaryId) => {
        return state.diaryEntries.filter(entry => entry.diary.toString() === diaryId.toString());
      }
};


export default {
    namespaced: true,
    actions,
    mutations,
    getters,
    state
}
