import * as backend from '../../api/backend';
import {checkResponseStatus} from '../../util/check';

const defaultState = {
    subjectListInvalid: false,
    subjects: []
};

const mutations = {
    setSubjectListInvalid: (state, status) => {
        state.subjectListInvalid = status;
    },
    setSubjects: (state, subjects) => {
        state.subjects = subjects;
    },
    clearSubjects: (state) => {
        state.subjects = [];
    },
    pushSubject: (state, subject) => {
        state.subjects.push(subject);
    },
    patchSubject: (state, patchedSubject) => {
        state.subjects.map((subject) => subject._id === patchedSubject ? patchedSubject : subject);
    },
    deleteSubject: (state, subjectId) => {
        state.subjects = state.subjects.filter((subject) => subject._id !== subjectId);
    },
};

const getters = {
    getSubjectInvalidStatus: state => state.subjectListInvalid,
    subjects: state => state.subjects,
    subjectsById: state => state.subjects.reduce((acc, subject) => {
        acc[subject._id] = subject;
        return acc;
    }, {}),

};

const actions = {
    setSubjectListStatus ({ commit }, status) {
        commit('setSubjectListInvalid', status);
    },

    async createSubject ({ commit, dispatch }, params) {
        try {
            const res = await backend.postSchoolSubject(params);
            await checkResponseStatus(201, res);
            const subject = await res.json();
            // add new subject to list
            commit('pushSubject', subject);
            return subject;
        }
        catch(err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async editSubject ({ commit, dispatch }, params) {
        try {
            const id = params._id
            delete params._id;
            const res = await backend.patchSchoolSubject(id, params);
            if(res.status === 409) {
                return 409;
            }
            const subject = await res.json();
            return subject;
        }
        catch(err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async deleteSubject ({ commit, dispatch }, id) {
        try {
            const res = await backend.deleteSchoolSubject(id);
            if(res.status === 404) {
                return 404;
            }
            await checkResponseStatus(204, res);
            return true;
        }
        catch(err) {
            console.error(err)
            return false;
        }
    },

    async getSubjects ({ commit, dispatch, getters, rootGetters }, update = false) {
        try {
            if(getters.subjects && getters.subjects.length > 0 && !update) {
                return getters.subjects;
            }
            const res = await backend.getSchoolSubjects();
            await checkResponseStatus(200, res);
            const subjects = await res.json();

            // Sort subjects and teachers alphabetically
            subjects.sort((a, b) => a.name.localeCompare(b.name));
            const accountRole = rootGetters['auth/accountRole'];
            if (accountRole === 'teacher' || accountRole === 'maintainer') {
                subjects.map((subject) => {
                    subject.teachers.sort((a, b) => a.name.localeCompare(b.name));
                });
            }

            commit('setSubjects', subjects);
            return subjects;
        }
        catch(err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async getSchoolSubject ({ commit, dispatch }, subjectId) {
        try {
            const res = await backend.getSchoolSubject(subjectId);
            await checkResponseStatus(200, res);
            const subject = await res.json();
            return subject;
        } catch(err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async getThumbnail({ commit, dispatch }, fileId) {
        if (typeof fileId === 'undefined' || !fileId || fileId === null) {
            return null;
        }
        try {
            const res = await backend.getThumbnail(fileId);
            if (res.status === 404) {
                return null;
            }
            return await res.blob();
        } catch (err) {
            console.error(err);
            return null;
        }
    },
};

export default {
    namespaced: true,
    state: defaultState,
    mutations,
    getters,
    actions,
};
