<template>
    <div style="display: flex; width: 100%">
        <div
            ref="box"
            :class="{ 'soft-box-shadow': accountRole === 'teacher' }"
            :style="`
                border: ${rect.startTextTask ? rect.startTextTask : 'none'};
                background-color: ${rect.text};
                width: 100%; height: 100%;
                max-width: ${canvasWidth}px; max-height: ${canvasHeight}px;
                pointer-events: none;
                z-index: 2;
            `"
        />
    </div>
</template>

<script>

import {mapState, mapGetters} from "vuex";

export default {
    name: "EditorRectangle",
    props: {
        rect: {type: Object, required: true},
        canvasWidth: {type: Number, required: true},
        canvasHeight: {type: Number, required: true},
    },
    data: () => ({
    }),
    computed: {
        ...mapGetters("auth",["accountRole"]),
    },
}
</script>

<style lang="scss">
// Language needs to be scss to maintain functionality and resolve issues

// Iterate over the different text-items to have an individual animation-delay
@for $i from 1 through 30 {
  .shakeMe:nth-of-type(#{$i}) {
    animation: shake 5s linear infinite #{random(4000)}ms;
  }

  // Pause the animation when the cursor hovers over an item
  .shakeMe:nth-of-type(#{$i}):hover {
    -webkit-animation-play-state: paused;
    -moz-animation-play-state: paused;
    -o-animation-play-state: paused;
    animation-play-state: paused;
  }
}

// Define the keyframes for the shake animation
@keyframes shake {
  0% {
    transform: translate(1px, 0px) rotate(-1deg);
  }
  2% {
    transform: translate(-1px, 0px) rotate(-1deg);
  }
  5% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  8% {
    transform: translate(3px, 0px) rotate(0deg);
  }
  11% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}
</style>
