<template>
    <v-dialog
        v-model="showDialog"
        width="400"
    >
        <v-card
            class="cardStyle"
            style="height: 80vh; overflow: hidden; border-radius: 16px"
        >
            <v-card-title class="chat-header d-flex align-center justify-space-between">
                <p>Chatraum erstellen</p>
                <v-btn
                    size="x-small"
                    variant="text"
                    elevation="0"
                    class="pa-0"
                    @click="closeDialog"
                >
                    <img
                        :src="cancelIcon"
                        class="icon20 iconToWhite"
                        alt="schließen"
                    >
                </v-btn>
            </v-card-title>
            <v-card-text
                class="group-list-container"
                style="height: calc(80vh - 55px - 60px)"
            >
                <simple-bar
                    :auto-hide="false"
                    class="scroll-area"
                    settings="settings"
                >
                    <h1 class="formHeader">
                        Gruppenname<span class="text-red bold">*</span>
                    </h1>
                    <v-text-field
                        v-model="newGroupChatName"
                        variant="filled"
                        density="compact"
                        hide-details
                        class="mb-2"
                        style="max-width: 500px"
                    />

                    <h1 class="formHeader">
                        Teilnehmer hinzufügen (mind. 2)<span class="text-red bold">*</span>
                    </h1>
                    <div class="d-flex align-center">
                        <v-text-field
                            v-model="searchTreeView"
                            variant="filled"
                            density="compact"
                            hide-details
                            clearable
                            style="max-width: 500px"
                        >
                            <template v-slot:prepend-inner>
                                <img
                                    :src="searchIcon"
                                    alt="Suche"
                                    class="icon mr-2"
                                >
                            </template>
                        </v-text-field>
                    </div>
                    <v-treeview
                        v-model="selectedAccountSets"
                        :items="selectableAccountSets"
                        item-text="displayName"
                        item-key="_id"
                        item-value="_id"
                        :search="searchTreeView"
                        selected-color="rgb(var(--v-theme-chatColor))"
                        selectable
                        dense
                        class="mt-2"
                        style="font-size: 16px"
                    />
                </simple-bar>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn
                    id="eK-button-disabled"
                    color="gruen"
                    elevation="0"
                    class="text-none"
                    theme="dark"
                    :disabled="isGroupChatCreatable"
                    @click="createGroupChat"
                >
                    Erstellen
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import * as backend from "../../../../api/backend";
import { mapActions, mapGetters, mapState } from "vuex";

import searchIcon from "@/assets/Icons/lupe-18.svg";
import cancelIcon from '@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg';

export default {
    name: "ActivateBrowserNotificationsDialog",

    data: () => ({
        showDialog: true,
        newGroupChatName: "",
        searchTreeView: "",
        searchIcon,
        cancelIcon,
        selectedAccountSets: [],
    }),
    computed: {
        ...mapGetters("accountSets", ["accountSets"]),
        ...mapGetters("accounts", ["accountsById"]),
        ...mapGetters("groups", ["groupsById"]),
        ...mapState("auth", ["account"]),
        isGroupChatCreatable() {
            return (
                this.newGroupChatName.trim() === "" ||
                this.selectedAccountSets.length < 1
            );
        },
        selectableAccountSets() {
            const getAccountSetDisplayName = (accountSet) => {
                if (accountSet.type === "account") {
                    const account = this.accountsById[accountSet.account];
                    return account ? account.displayName : account._id;
                } else if (accountSet.type === "groupLeaders") {
                    const group = this.groupsById[accountSet.group];
                    return `Gruppe "${group?.name}" Leiter`;
                } else if (accountSet.type === "groupAppointmentLeaders") {
                    const group = this.groupsById[accountSet.group];
                    return `Gruppe "${group?.name}" Fachlehrer`;
                } else if (accountSet.type === "groupParticipants") {
                    const group = this.groupsById[accountSet.group];
                    return `Gruppe "${group?.name}" Mitglieder`;
                } else if (accountSet.type === "groupParticipantsParents") {
                    const group = this.groupsById[accountSet.group];
                    return `Gruppe "${group?.name}" Eltern`;
                } else if (accountSet.type === "allTeachers") {
                    return "Alle Lehrer";
                } else if (accountSet.type === "allMaintainers") {
                    return "Verwaltung";
                } else if (accountSet.type === "allPupils") {
                    return "Alle Schüler";
                } else if (accountSet.type === "allParents") {
                    return "Alle Eltern";
                } else {
                    // For debugging if there is a new type
                    console.log('Problematic new type:', accountSet.type);
                    return 'Unbekannt'
                }
            };

            return this.accountSets
                .filter((accountSet) => accountSet._id !== this.myAccountSet?._id)
                .map((accountSet) => ({
                    ...accountSet,
                    displayName: getAccountSetDisplayName(accountSet),
                }))
                .filter((accountSet) => !accountSet.displayName.includes("PersonAlle"));
        },
        myAccountSet() {
            return this.accountSets.find(
                (accountSet) =>
                    accountSet.type === "account" &&
                    accountSet.account === this.account._id
            );
        },
    },
    methods: {
        ...mapActions("matrix", ["createCustomRoom"]),
        ...mapActions("chat", ["postChatRoom"]),

        async createGroupChat() {
            const memberships = this.selectedAccountSets.map((accountSet) => ({
                accountSet,
                role: "moderator",
            }));
            const myMembership = {
                accountSet: this.myAccountSet._id,
                role: "moderator",
            };

            await this.postChatRoom({
                memberships: [...memberships, myMembership],
                name: this.newGroupChatName,
                description: "",
                type: "groupChat",
                isOpen: true,
            });

            //   await this.createCustomRoom({
            //     groupName: this.newGroupChatName,
            //     members: this.newGroupChatMembers,
            //   });
            this.closeDialog();
        },

        closeDialog() {
            this.$emit("closeDialog");
        },
    },
};
</script>

<style lang="scss">
.cardStyle {
    .v-card__text {
        // unify border-radius
        .v-btn {
            border-radius: 8px;
        }
        .v-input__control > .v-input__slot {
            border-radius: 8px;
        }

        // remove border bottom line/underline from all the inputs in v-cards with this class
        .v-input__control > .v-input__slot:before,
        .v-input__control > .v-input__slot:after {
            border-style: none !important;
        }
    }
}
</style>
<style scoped lang="scss">
.chat-header {
    background-color: rgb(var(--v-theme-chatColor));
    border-radius: 15px 15px 0 0;
    font-size: larger;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    color: white;
}

.group-list-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.scroll-area {
    flex: 1;
}

.formHeader {
    font-size: 16px;
    margin-top: 40px !important;
    margin-bottom: 20px !important;
}

.icon {
    height: 20px;
}

.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%)
    hue-rotate(36deg) brightness(104%) contrast(105%);
}

#eK-button-disabled.v-btn--disabled {
    opacity: 0.5;
    background-color: rgb(var(--v-theme-grau)) !important;
    color: white !important;
}
</style>
