<template>
    <div
        class="ma-4"
    >
        <!-- <div class="d-flex justify-space-between align-center">
            <p>{{ currentSickNote.title }}</p>
            <p>{{ createDate(currentSickNote.createdAt) }}</p>
        </div>


        <div class="d-flex justify-space-between">
            <p>Für: {{ currentSickNote.pupil.name + ' ' + currentSickNote.pupil.lastName }}</p>
            <p>{{ createDate(currentSickNote.from)+' - ' +createDate(currentSickNote.till) }}</p>
        </div>

        <hr class="my-1"> -->

        <div style="white-space: pre-line;">
            {{ currentSickNote.content }}
        </div>

        <div
            class="d-flex justify-space-between"
            style="flex-flow: row wrap;align-items:flex-end;"
        >
            <div
                class="d-flex justify-flex-start"
                style="align-items:flex-end"
            >
                <v-progress-circular
                    v-if="signatureLoading"
                    indeterminate
                    color="grey"
                />
                <img
                    v-else
                    :src="signatureImg"
                    alt="Unterschrift"
                    class="signatureImage"
                >

                <v-btn
                    class="actionBtn mr-1"
                    size="x-small"
                    @click="downloadSickNoteSignature()"
                >
                    <img
                        :src="downloadIcon"
                        class="icon"
                        alt="Unterschrift herunterladen"
                    >
                </v-btn>
            </div>
        </div>

        <hr
            v-if="currentSickNote.files.length > 0"
            class="mt-3"
        >

        <div v-if="currentSickNote.files.length">
            <p class="font-weight-bold">
                {{ currentSickNote.files.length > 1 ? 'Anhänge' : 'Anhang' }}
            </p>
        </div>

        <!-- <div
            v-for="(file, index) in currentSickNote.files"
            :key="file._id"
            class="d-flex justify-space-between align-center"
            :class="index === currentSickNote.files.length-1 ? '' : 'mb-2'"
        >
            <p class="ellipsis">
                {{ file.uploadedName }}
            </p>
            <v-btn
                class="actionBtn mr-1"
                x-small
                @click="downloadSickNoteAttachment(currentSickNote._id, currentSickNote.files[index])"
            >
                <img
                    :src="downloadIcon"
                    class="icon"
                    alt="Datei herunterladen"
                >
            </v-btn>
        </div> -->
        <v-row
            class="d-flex justify-flex-start mx-4 mt-2"
            style="align-items:center;"
        >
            <div
                v-for="file in filePreviews"
                :key="file.thumbnail"
                class="previewContainer"
            >
                <div
                    class="previewHeader text-truncate"
                >
                    <p
                        class="text-truncate"
                        style="width:90%; color:white"
                    >
                        {{ file.uploadedName }}
                    </p>

                    <v-btn
                        class="actionBtn"
                        size="x-small"
                        @click="downloadSickNoteAttachment(currentSickNote._id, file)"
                    >
                        <img
                            :src="downloadIcon"
                            class="icon"
                            alt="Datei herunterladen"
                        >
                    </v-btn>
                </div>
                <div
                    v-if="file.mimetype==='application/pdf'"
                    class="displayWholeThumbnail"
                    @click="handlePreview(file.thumbnail, 'pdf')"
                >
                    <object
                        :data="file.thumbnail + '#toolbar=0&navpanes=0&scrollbar=0&view=fitH'"
                        type="application/pdf"
                        class="iOS_ResponsiveHack"
                        style="cursor: pointer; pointer-events: none"
                    />
                </div>
                <div
                    v-else-if="file['mimetype'].split('/')[0] === 'image'"
                    class="displayWholeThumbnail"
                    style="overflow:hidden"
                    @click="handlePreview(file.thumbnail, 'image')"
                >
                    <v-img
                        :src="file.thumbnail"
                        align="center"
                    >
                        <div class="fill-height bottom-gradient" />
                        <template v-slot:placeholder>
                            <v-row
                                class="fill-height fill-width ma-0"
                                align="center"
                                justify="center"
                            >
                                <!-- <v-progress-circular
                                    indeterminate
                                    color="grey"
                                /> -->
                            </v-row>
                        </template>
                    </v-img>
                </div>
                <div
                    v-else-if="(file['mimetype'].split('/')[0] === 'video')"
                    class="displayWholeThumbnail"
                    style="margin-bottom: 5px; overflow: hidden"
                    @click="handlePreview(file.thumbnail, 'video')"
                >
                    <v-img
                        :src="videoIcon"
                        class="mb-2 iconToDunkelgrau"
                        alt="Video"
                        cover
                    />
                </div>
                <div
                    v-else-if="(file['mimetype'].split('/')[0] === 'audio')"
                    class="displayWholeThumbnail"
                    style="overflow: hidden"
                    @click="handlePreview(file.thumbnail, 'audio')"
                >
                    <v-img
                        :src="audioIcon"
                        class="mb-2 iconToDunkelgrau"
                        alt="Audio"
                        cover
                    />
                </div>
                <div
                    v-else
                    class="displayWholeThumbnail"
                    style="overflow: hidden"
                >
                    <v-icon
                        style="font-size: 70px"
                    >
                        mdi-file
                    </v-icon>
                </div>
            </div>
        </v-row>
        <div
            v-if="role === 'teacher' && !this.currentSickNote.hasOwnProperty('accepted')
                || role === 'maintainer' && !this.currentSickNote.hasOwnProperty('accepted')"
            class="d-flex justify-space-between mt-4"
        >
            <v-btn
                class="text-none"
                color="frot"
                elevation="0"
                theme="dark"
                @click="handleSickNoteClick(0)"
            >
                Ablehnen
            </v-btn>

            <v-btn
                class="text-none"
                color="gruen"
                elevation="0"
                theme="dark"
                @click="checkForSports()"
            >
                Annehmen
            </v-btn>
        </div>
        <v-dialog
            v-if="sportsDialog"
            v-model="sportsDialog"
            max-width="400"
        >
            <v-card>
                <v-card-title
                    class="d-flex justify-space-between"
                    style="background-color: rgb(var(--v-theme-parentLetterPurple))"
                >
                    <p
                        style="font-family: 'eKlara Custom'; color: white; font-size: 30px"
                    >
                        Hinweis
                    </p>
                    <v-tooltip
                        location="bottom"
                    >
                        <template v-slot:activator="{ props }">
                            <v-btn
                                icon="$vuetify"
                                variant="plain"
                                elevation="0"
                                color="transparent"
                                v-bind="props"
                                @click="sportsDialog = false"
                            >
                                <img
                                    :src="cancelIcon"
                                    class="optionsIcon iconToWhite"
                                >
                            </v-btn>
                        </template>
                        <span>Schließen</span>
                    </v-tooltip>
                </v-card-title>
                <v-card-text class="mt-5">
                    <p style="color: red">
                        Kein Fach mit der Markierung "Schulsport" konnte gefunden werden.
                    </p>
                    <p class="mt-3">
                        Um die Fehlzeiten des Schülers/ der Schülerin automatisch in das 
                        Klassenbuch eintragen zu lassen, müssen in der Fach-Verwaltung Fächer, 
                        die sportliche Tätigkeiten beinhalten, als "Schulsport" markiert werden.
                    </p>
                </v-card-text>
                <v-card-actions class="d-flex justify-space-between pa-4">
                    <v-btn
                        class="option"
                        color="#F5F5F5"
                        @click="sportsDialog = false"
                    >
                        <img
                            :src="cancelIcon"
                            class="icon20 mr-2"
                        >
                        Abbrechen
                    </v-btn>

                    <v-btn
                        class="option"
                        theme="dark"
                        color="gruen"
                        @click="handleSickNoteClick(1, true);"
                    >
                        Akzeptieren
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import downloadIcon from "@/assets/Icons/runterladen-14.svg";
import videoIcon from '@/assets/Icons/video.svg';
import audioIcon from '@/assets/Icons/EditorRedesign/audio.svg';
import cancelIcon from "@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg";
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'MessagesSickNote',
    props: {
        currentSickNote: {},
        handleAcceptSickNote: {},
        handleDeclineSickNote: {},
        role: {},
        settings: {},
        signature: {},
    },
    data() {
        return {
            downloadIcon,
            videoIcon,
            audioIcon,
            cancelIcon,
            signatureImg: null,

            currentLetterFiles:[],
            filePreviews:[],
            sportsDialog: false,
            signatureLoading: true,
        }
    },
    computed: {
        ...mapGetters('subjects', ['subjects']),
        ...mapGetters("sickNote", ["signaturesById",'signaturesBySickNoteId']),
        ...mapGetters('auth', [ 'accountRole']),
    },
    
    async mounted(){
        await this.displaySignature();
        this.currentLetterFiles = this.currentSickNote.files;
        this.filePreviews = await this.loadFilePreviews(this.currentSickNote._id);
    },
    methods: {
        ...mapActions('sickNote', ['getSickNoteFile','getSickNotesSignature']),

        async loadFilePreviews(sickNoteId){
            const tempfiles = [];
            await this.currentLetterFiles.forEach(async(file) => {
                file.thumbnail = await this.getSickNoteFile({sickNoteId: sickNoteId, fileId: file._id});
                tempfiles.push(file);
            });
            return tempfiles;
        },
        // creates dates of format DD.MM.YY with leading zeros
        createDate(date) {
            return new Date(date).toLocaleDateString([], {year: '2-digit', month: '2-digit', day: '2-digit'});
        },

        handleSickNoteClick(number, manuell) {
            // number = 0 is decline, 1 is accept
            if (number === 0) {
                this.$emit('handleDeclineSickNote', this.currentSickNote._id);
                this.currentSickNote.accepted = false;
            } else if (number === 1) {
                this.$emit('handleAcceptSickNote', this.currentSickNote, manuell);
                this.currentSickNote.accepted = true;
            }

            if (manuell) {
                this.sportsDialog = false;
            }
            // force update so info instead of accept/decline btns are shown
            this.$forceUpdate();
        },

        async downloadSickNoteAttachment(sickNoteId, file) {
            try {
                var url = await this.getSickNoteFile({sickNoteId: sickNoteId, fileId: file._id});
                var a = document.createElement('a');
                a.href = url;
                a.download = file.uploadedName;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                setTimeout(function(){
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }, 1000);

            }
            catch (e) {
                console.error(e);
            }
        },
        async displaySignature(){
                this.signatureLoading = true;
                const signature = await this.getSickNotesSignature(this.currentSickNote._id);
                this.signatureImg =  signature.url;
                this.signatureLoading = false;
        },

        async downloadSickNoteSignature() {
            try {
                var url = this.signature;
                var a = document.createElement('a');
                a.href = url;
                a.download = 'Unterschrift.png';
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                setTimeout(function(){
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }, 1000);

            }
            catch (e) {
                console.error(e);
            }
        },
        handlePreview(thumbnail, type) {
            switch (type) {
                case 'audio':
                    this.$emit("playAudio", thumbnail);
                    break;
                case 'image':
                    this.$emit('openImage', thumbnail);
                    break;
                case 'pdf':
                    this.$emit("openPdf", thumbnail);
                    break;
                case 'video':
                    this.$emit("playVideo", thumbnail);
                    break;
                default:
                    console.warn('Type not supported for preview')
                    break;
            }
        },

        checkForSports() {
            const subjects = this.subjects;
            const sports = subjects.filter((subject) => subject.isSport);
            if (this.currentSickNote.title === 'Befreiung Schulsport' && sports.length === 0) {
                this.sportsDialog = true;
            } else {
                this.handleSickNoteClick(1, false);
            }
        }
    }
}
</script>
<style lang="scss" scoped>

// custom disabled button, it uses an id and important as the regular vuetify button styling already uses disabled
// https://stackoverflow.com/a/63762909

.icon {
    height: 20px;
}

.scroll-area-specificSickNote {
    position: relative;
    margin: auto;
    padding-bottom: 8px;
    width: 100%;
    max-height: 62vh;
}

.ellipsis {
    text-overflow: ellipsis;
    /* Required for text-overflow to do anything */
    white-space: nowrap;
    overflow: hidden;
}

.actionBtn {
    color: white;
    border-radius: 8px;
    min-width: 30px !important;
    width: 30px !important;
    height: 30px !important;
}
.signatureImage{
    height: calc(20px + 6vh);
    border-bottom: 2px solid black;
}
.thumbnailDisplayNormal {
    width: 100%;
    min-width: 150px;
    max-width: 10vmax;
    height: 100%;
    min-height: 150px;
    max-height: 10vmax;
    aspect-ratio: 1/1;
    cursor: pointer;
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 900px) {
    .thumbnailDisplayNormal {
        max-width: 20vmax;
        max-height: 20vmax;
    }
}
.previewHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3%;
    background-color: rgb(var(--v-theme-dunkelgrau));
    font-size: 14px;
    border-radius: 8px 8px 0px 0px;
    height:40px;
}
.previewContainer {
    width: 194px;
    border-radius: 0px 0px 8px 8px;
    margin-left: 10px;
    margin-right: 10px;
    background-color: #b9b9b9;
    border-radius: 8px 8px 8px 8px;
}
.iOS_ResponsiveHack{
    width: 1px;
    min-width: 100%;
    *width: 100%;
    padding-left: 10%;
    padding-right: 10%;
}
@media only screen and (max-width: 501px) {
    .previewContainer{
        width:100%
    }
    .previewHeader{
        width: 100%
    }
}
.displayWholeThumbnail {
    width: -webkit-fill-available;
    height: 154px;
    aspect-ratio: 1/1;
    cursor: pointer;
    display: flex;
    justify-content: center;
    border-radius: 0px 0px 8px 8px;
}
</style>
