<template>
    <div>
        <div>
            <v-card
                :style="headerColor ? {'background-color': headerColor} : 'background-color: #dadada'"
                style="max-height:100vh"
            >
                <div
                    class="d-flex align-center justify-space-between"
                    style="max-height: 50px"
                >
                    <div
                        class="d-flex align-center pa-2 ml-1"
                    >
                        <img
                            v-if="headerIcon"
                            :src="headerIcon"
                            :height="40"
                            class="mr-2"
                            alt="Header"
                        >
                        <v-tooltip
                            location="bottom"
                        >
                            <template v-slot:activator="{ props }">
                                <p
                                    style="color: white; font-size: 1rem"
                                   
                                    v-bind="props"
                                >
                                    {{ headerTitle }}
                                </p>
                            </template>
                            <span>{{ headerTooltip }}</span>
                        </v-tooltip>
                    </div>
                    <div
                        class="d-flex align-center pa-2 ml-1"
                    >
                        <!--
                        <v-tooltip
                            v-if="currentAccount.role === 'parent'"
                            bottom
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <div
                                    style="display: flex; align-items: center"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-btn
                                    icon
                                    style="color: white;">
                                        <v-img
                                            :src="fragezeichenIcon"
                                            aspect-ratio="1.5"
                                            alt="test"
                                            style="color: white;"
                                        />
                                    </v-btn>
                                </div>
                            </template>
                            <span>{{ 'Da Sie als Elternteil nicht über das File-Widget verfügen, können wir die Datei \nnicht in eKlara speichern. Laden Sie die Datei deshalb auf \nIhr Gerät herunter.' }}</span>
                        </v-tooltip>
                        -->
                  
                        <div
                            class="d-flex justify-space-between align-center"
                        >
                            <div
                                class="d-flex justify-space-around align-center"
                            >
                                <span style="color: white">Benachrichtigen</span>
                                <v-tooltip
                                    v-if="isKorrektur"
                                    location="bottom"
                                >
                                    <template v-slot:activator="{ props }">
                                        <img
                                            :src="infoIcon"
                                            class="icon20 iconToWhite"
                                            alt="Info"
                                           
                                            v-bind="props"
                                        >           
                                    </template>
                                    <span>Soll der Schüler eine Mitteilung für die Korrektur erhalten? Ja/Nein</span>
                                </v-tooltip>
                            </div>
                            <v-checkbox
                                v-model="showBadgeForKorrektur"
                                hide-details
                                color="#ffffff"
                                style="width: 50px; height: 50px"
                            />
                        </div>

                        <v-tooltip
                            location="bottom"
                        >
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    style="background-color: transparent;"
                                    elevation="0"
                                   
                                    v-bind="props"
                                    @click="triggerSave"
                                >
                                    <v-img
                                        :src="saveIcon"
                                        style="max-width: 40px;"
                                    />
                                </v-btn>
                            </template>
                            <span>Datei zwischenspeichern</span>
                        </v-tooltip>
                        <v-tooltip
                            v-if="mode === 'material'"
                            location="bottom"
                        >
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    class="mr-2"
                                    color="gruen"
                                    :min-width="windowWidth > 544 ? '90px' : '40px'"
                                    elevation="0"
                                   
                                    v-bind="props"
                                    @click="uploadMaterial"
                                >
                                    <p
                                        class="text-capitalize"
                                        style="font-size: large; color: white"
                                        :style="windowWidth > 544 ? 'font-size: large; margin-left: 4px' : 'font-size: small'"
                                    >
                                        Fertig
                                    </p>
                                </v-btn>
                            </template>
                            <span> {{ isPupil ? 'Datei abgeben': 'Material hochladen' }} </span>
                        </v-tooltip>
                        <v-tooltip location="bottom">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    style="background-color: transparent;"
                                    elevation="0"
                                   
                                    v-bind="props"
                                    @click="closeDialog"
                                >
                                    <v-img
                                        :src="closeIcon"
                                    />
                                </v-btn>
                            </template>
                            <span>Schließen</span>
                        </v-tooltip>
                    </div>
                </div>
                <iframe
                    :id="libreFrameId"
                    :src="libreUrl"
                    class="libreOffice"
                    style="width: 100vw;"
                />
            </v-card>
        </div>
        <SaveToFolder
            v-if="showSaveToFolder"
            :mode="'save'"
            :file="file"
            @close="showSaveToFolder = false"
            @save-file-to-destination="saveFileToSelectedFolder"
        />
        <NameFile
            v-if="showNameFile && mode === 'material'"
            :show-dialog="showNameFile"
            :original-name="uploadFileName"
            :callback="fileNameCallback"
            :group="group"
            :show-teamwork-checkbox="true"
            :show-is-assignment="mode === 'material'"
            @update:show-dialog="showNameFile = $event"
        />
    </div>
</template>
<script>
import  * as backend from '@/api/backend';
import closeIcon from '@/assets/Icons/Redesign/close_white_24dp.svg'
import saveIcon from '@/assets/Icons/redesignV3/save.svg'
import infoIcon from '@/assets/Icons/FaceliftIcons/info_hinweis.svg';
import SaveToFolder from '@/components/FileArchive/SaveToFolderDialog.vue'
import {mapActions, mapMutations, mapGetters, mapState} from "vuex";
import NameFile from "@/components/NameFile";
// import fragezeichenIcon from '@/assets/Tooltip/fragezeichen.svg'
import powerpointIcon from '@/assets/Icons/FaceliftIcons/libreoffice_ppt.svg';
import wordIcon from '@/assets/Icons/FaceliftIcons/libreoffice_doc.svg';
import excelIcon from '@/assets/Icons/FaceliftIcons/libreoffice_xls.svg';
import {getBackendAddress} from '../../../util/getBackendAddress';
import {postPupilUploadEditFromExistingFile} from "../../../api/backend";

export default {

    name: "LibreOfficeTest",
    components: {
        SaveToFolder,
        NameFile,
    },
    data: () => ({
        // fragezeichenIcon,

        currentAccount: null,
        fileId: '',
        libreDocId: '',
        fileExtension: '',
        fileType: '',
        accessToken: '',
        mode: '',
        file: null,
        libreUrl: '',
        isTemplate: false,
        showSaveToFolder: false,
        showBadgeForKorrektur: false,
        appointment: null,
        subjectName: '',
        group: null,
        uploadFileName: '',
        isKorrektur: false,
        fileNameCallback: () => {
        },
        closeIcon,
        saveIcon,
        isPupil: false,
        headerColor: '',
        headerIcon: '',
        headerTitle: '',
        headerTooltip: '',
        waitForSave: false,

        libreFrameId : 'libreFrame',
        fileAccessToken: '',
        showNameFile: false,
        parentComponent: '',
        wordIcon,
        powerpointIcon,
        excelIcon,
        infoIcon,
    }),
    computed: {
        ...mapGetters('badges', ['showCorrectionBadge']),
        ...mapGetters('vfs', ['libreFileId', 'libreIsTask']),
        ...mapState('util', ['windowWidth']),
        ...mapGetters("auth", ["accountRole"]),
        isRouteValid() {
            if (!this.libreDocId.trim()) {
                return false;
            }
            if (!this.fileId.trim()) {
                return false;
            }
            if (!this.accessToken.trim()) {
                return false;
            }
            if (!this.fileType.trim()) {
                return false;
            }
            if (!this.mode.trim()) {
                return false;
            }

            if (this.mode === 'material') {
                if (!this.appointment) {
                    return false;
                }
                if (!this.group) {
                    return false;
                }
                if (!this.currentAccount) {
                    return false;
                }
            }
            return true;
        },
    },
    async mounted() {
        const query = this.$route.query;

        this.fileId = query.fileId;
        this.libreDocId = query.libreDocId;
        this.accessToken = query.accessToken;
        this.fileType = query.fileType;
        this.isTemplate = query.isTemplate;
        this.mode = query.mode;
        this.fileAccessToken = query.fileAccessToken;
        this.parentComponent = query.parentComponent;

        this.currentAccount = await this.getCurrentAccount();
        this.isPupil = this.currentAccount.role === 'pupil';


        if (this.mode === 'material') {
            this.subjectName = query.subjectName;
            let res = await backend.getSchoolSubjectAppointmentWithId(query.aId);
            this.appointment = await res.json();
            res = await this.getGroup(this.appointment.group);
            this.group = res;
            this.isKorrektur = query.isCorrectur;
        }

        let res = await backend.getFile(this.fileId, this.fileAccessToken);
        this.file = await res.json();
        this.file = this.file.file;

        this.fileExtension = await this.getFileExtension(this.file.mimetype);
        // Check if we are on a production server else we are on a local machine
        if (process.env.VUE_APP_BACKEND_ADDRESS === 'PRODUCTION_BACKEND_ADDRESS') {
            this.libreUrl = `${location.protocol}//collaboraonline.${location.host}/browser/b639546/cool.html?WOPISrc=${getBackendAddress()}/wopi/files/${this.libreDocId}?access_token=${this.accessToken}`;
        } else {
            this.libreUrl = `http://localhost:9980/browser/b639546/cool.html?WOPISrc=http://host.docker.internal:8074/wopi/files/${this.libreDocId}?access_token=${this.accessToken}`;
        }
        if (!this.isRouteValid) {
            if (process.env.VUE_APP_BACKEND_ADDRESS === 'PRODUCTION_BACKEND_ADDRESS') {
                this.showSnackbar({
                    message: 'Der LibreOffice Komponente wurden nicht alle essenziellen Daten übergeben.',
                    color: 'error'
                })
            } else {
                this.showSnackbar({
                    message: 'Konnte LibreOffice nicht starten',
                    color: 'error',
                })
            }

            this.closeDialog();
        }
        this.setHeader();
        window.removeEventListener('message', this.handlePostMessage, false);
        window.addEventListener('message', this.handlePostMessage, false);
        this.showBadgeForKorrektur = this.showCorrectionBadge;
    },
    methods: {
        ...mapMutations('snackbar', ['showSnackbar']),
        ...mapActions('auth', ['getCurrentAccount']),
        ...mapActions('mimeTypes', ['getFileExtension']),
        ...mapMutations('badges', ['setShowCorrectionBadge']),
        ...mapActions('groups', ['getGroup']),
        ...mapActions('appointments', ['getAppointment',"patchAppointmentUploadSeenBy"]),
        async triggerSave() {
            await this.postMessageToLibre({
                MessageId: 'Action_Save',
                SendTime: Date.now.toString(),
                Values: {
                    DontTerminateEdit: true,
                    DontSaveIfUnmodified: false,
                    Notify: true,
                    ExtendedData: '',
                },
            });
            // when this file has been opened from vfs and is a task (to which a pupil submits)
            if (this.libreIsTask && this.libreFileId ? this.fileId == this.libreFileId : false) {
                await backend.postVFSSubmissionLibre(this.fileId);
            }
        },
        postMessageToLibre(body) {
         document.getElementById(this.libreFrameId).contentWindow.postMessage(JSON.stringify(body), '*');
        },
        handlePostMessage(e) {
            try {
                const msg = JSON.parse(e.data);
                if (msg.MessageId === 'UI_SaveAs') {
                    this.clickSaveAs();
                } else if (msg.MessageId === 'Action_Save_Resp') {
                    if (this.waitForSave) {
                        this.waitForSave = false;
                        this.closeDialog();
                    }
                    if (this.currentAccount.role === 'parent') {
                        this.showSnackbar({
                            message: 'Die Datei wurde gespeichert. Denken Sie bitte daran, dass eklara ihre Datei nicht in eKlara speichern können. Sie können die Datei unter "Datei" und "Herunterladen" lokal auf Ihrem Gerät speichern.',
                            color: 'error'
                        });
                    }
                } else if (msg.MessageId === 'App_LoadingStatus') {
                    if(msg.Values.Status === 'Document_Loaded') {
                        this.postMessageToLibre({ MessageId: 'Host_PostmessageReady'});
                    }
                }
            } catch (e) {
                return;
            }
        },
        async uploadMaterial() {
            if (this.isTemplate && this.currentAccount.role === 'teacher') {
                await this.uploadInputChange(this.file);
            } else if (this.currentAccount.role === 'pupil') {
                const res = await backend.postPupilUploadFromExistingFile(this.fileId, this.appointment._id);
                if (res.status === 200) {
                    this.showSnackbar({
                        message: 'Aufgabe ist abgegeben, gut gemacht!',
                    });
                    this.waitForSave = true;
                    this.triggerSave();
                } else {
                    this.showSnackbar({
                        message: 'Beim Hochladen der Aufgabe ist ein Fehler aufgetreten!',
                        color: 'error'
                    })
                }
            } else if (this.isKorrektur && this.currentAccount.role === 'teacher') {
                const res = await backend.postPupilUploadEditFromExistingFile(this.fileId, this.appointment._id, {rating: 10});
                const information = await res.json();
                if (res.status === 200) {
                    this.setShowCorrectionBadge(this.showBadgeForKorrektur);
                    this.showSnackbar({
                        message: 'Korrektur erfolgreich hochgeladen und abgegeben!',
                    });

                    if (!this.showBadgeForKorrektur) {
                        const res = await this.patchAppointmentUploadSeenBy({uploadId: information.uploadId, accountId: information.pupilUpload.uploader, role: this.accountRole});
                    }
                    this.waitForSave = true;
                    this.triggerSave();
                } else {
                    this.showSnackbar({
                        message: 'Fehler beim hochladen der Korrektur!',
                        color: 'error',
                    })
                }
            } else {
                this.closeDialog();
            }
        },
        async clickSaveAs() {
            if (this.showSaveToFolder)  {
                return;
            }
            this.showSaveToFolder = true;
        },
        async saveFileToSelectedFolder(selectedName, selectedFolderId) {
            const res = await backend.postExistingFileToVFS(this.file._id, selectedFolderId, {
                fileName: selectedName,
                libreDocId: this.fileId,
            });
            const resJson = await res.json();
            const path = await resJson.folderPath;
            if (res.status === 200) {
                this.triggerSave();
                this.showSnackbar({
                    message: `Datei wurde erfolgreich in Dateien unter ${path} gespeichert!`
                });
                this.showSaveToFolder = false;
            } else {
                this.showSnackbar({
                    message: 'Fehler!',
                    color: 'error',
                })
            }
        },
        async closeDialog() {
            if (this.appointment) {
                await this.getAppointment(this.appointment._id);
            }
            window.removeEventListener('message', this.handlePostMessage, false);
            let accountQuery = this.currentAccount.role === 'teacher' ? 'lehrer' : this.currentAccount.role === 'pupil' ? 'schueler' : 'eltern';
            let query;
            let component;
            switch (this.parentComponent) {
                case 'plan':
                    query = {
                        aId: this.appointment ? this.appointment._id : '',
                        page: 0,
                    };
                    component = "plan";
                    break;
                case 'apps':
                    query = {
                        page: 1,
                    };
                    component = "plan";
                    break;
                case 'pinboard':
                    query = {
                        id: this.$route.query.id,
                        color: this.$route.query.color,
                        title: this.$route.query.title,
                        uploadedIcon: this.$route.query.uploadedIcon,
                        icon: this.$route.query.icon,
                    };
                    component = "pinboardbase";
                    break;
            }
            if (component === 'pinboardBase') {
                await this.$router.push({
                    name: `${component}`,
                    query,
                });
            } else {
                await this.$router.push({
                    name: `${accountQuery}.plan`,
                    query,
                });
            }
        },
        setHeader() {
            switch (this.fileType) {
                case 'word':
                    this.headerColor = '#6995cd';
                    this.headerIcon = this.wordIcon;
                    this.headerTitle = 'Texte';
                    this.headerTooltip = 'Text erstellen'
                    break;
                case 'powerpoint':
                    this.headerColor = '#ff6633';
                    this.headerIcon = this.powerpointIcon;
                    this.headerTitle = 'Präsentation';
                    this.headerTooltip = 'Präsentation erstellen'
                    break;
                case 'excel':
                    this.headerColor = '#3ba934';
                    this.headerIcon = this.excelIcon;
                    this.headerTitle = 'Tabelle';
                    this.headerTooltip = 'Tabelle erstellen'
                    break;
                case 'docx':
                    this.headerColor = '#6995cd';
                    this.headerIcon = this.wordIcon;
                    this.headerTitle = 'Texte';
                    this.headerTooltip = 'Text erstellen'
                    break;
                case 'pptx':
                    this.headerColor = '#ff6633';
                    this.headerIcon = this.powerpointIcon;
                    this.headerTitle = 'Präsentation';
                    this.headerTooltip = 'Präsentation erstellen'
                    break;
                case 'xlsx':
                    this.headerColor = '#3ba934';
                    this.headerIcon = this.excelIcon;
                    this.headerTitle = 'Tabelle';
                    this.headerTooltip = 'Tabelle erstellen'
                    break;
            }
        },
        async uploadInputChange(parsedFile) {
            const date = new Date();
            const dayString = String(date.getDate()).padStart(2, '0');
            const monthString = String(date.getMonth() + 1).padStart(2, '0');
            const yearString = date.getFullYear()

            const dateString = `${dayString}.${monthString}.${yearString}`;

            this.uploadFileName = `${this.subjectName} ${dateString}.${this.fileExtension}`;
            this.fileNameCallback = async (newFileName, selectedUser, userNotes, startDate, isAssignment, createSubtitles, isTeamwork) => {
                const body = {
                    newFileName,
                    selectedUser,
                    userNotes,
                    startDate,
                    isAssignment,
                    createSubtitles,
                    isTeamwork
                }
                const arr = newFileName.split('.');
                if (arr[arr.length - 1] !== this.fileExtension) {
                    body.newFileName = `${newFileName}.${this.fileExtension}`
                }

                const res = await backend.postTeacherUploadFromExistingFile(this.appointment._id, parsedFile._id, body);
                if (res.status === 201) {
                    this.showSnackbar({
                        message: 'Datei wurde erfolgreich hochgeladen!',
                    });
                    this.showNameFile = false;
                    this.waitForSave = true;
                    this.triggerSave();
                } else {
                    this.showSnackbar({
                        message: 'Fehler beim hochladen des Materials!',
                        color: 'error',
                    });
                }
            }
            this.showNameFile = true;
        },
    },
}
</script>

<style scoped lang="scss">
.libreOffice {
    height: calc(100vh - 72px);
}

</style>
