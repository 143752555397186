<template>
    <v-dialog
        :model-value="true"
        fullscreen
        @click:outside="{}"
    >
        <div class="outerContainer">
            <div id="topControl">
                <div class="d-flex">
                    <v-btn
                        style="margin-right: 12px; width: 40px; height: 40px"
                        size="x-small"
                        elevation="0"
                        @click="ttsFinishedTalking ? ttsCurrentSlideSubtitle() : callTtsForPause()"
                    >
                        <img
                            :src="ttsFinishedTalking ? speaker : speakerOff"
                            :alt="ttsFinishedTalking ? 'Vorlesen' : 'Vorlesen stoppen'"
                            class="lightBlueColorFilterFromBlack"
                        >
                    </v-btn>
                    <!-- Language selection -->
                    <v-select
                        v-model="language"
                        :items="languages"
                        item-title="text"
                        variant="outlined"
                        density="compact"
                        hide-details
                        :menu-props="{ offsetY: true }"
                        style="max-width: calc(100vw - 150px)"
                        @update:model-value="translateSubtitles()"
                    >
                        <template v-slot:selection="{ item }">
                            <img
                                :src="item.icon"
                                class="icon20 mr-2"
                                :alt="item.alt"
                            >
                            {{ item.text }}
                        </template>

                        <template v-slot:item="{ item, on }">
                            <v-list-item v-on="on">
                                <img
                                    :src="item.icon"
                                    class="icon20 mr-2"
                                    :alt="item.alt"
                                >
                                {{ item.text }}
                            </v-list-item>
                        </template>
                    </v-select>
                </div>

                <v-btn
                    v-if="!forceTutorial"
                    style="min-width: 40px; width: 40px; height: 40px"
                    size="x-small"
                    elevation="0"
                    @click="endSlideTutorial"
                >
                    <img
                        :src="cancelIcon"
                        class="arrowImg lightBlueColorFilterFromGrey"
                        alt="Tutorial schließen"
                    >
                </v-btn>
            </div>

            <!-- Slide (img, title, text) -->
            <div class="contentContainer d-flex justify-space-between">
                <v-btn
                    elevation="0"
                    class="colorLightBlue"
                    style="height: calc(100vh - 120px); width: 40px"
                    size="x-small"
                    :style="currentSlideIndex >= 1 ? '' : 'opacity: 0; cursor: unset'"
                    @click="previousSlide()"
                >
                    <img
                        :src="leftArrow"
                        class="arrowImg lightBlueColorFilterFromGrey"
                        alt=""
                    >
                </v-btn>

                <div>
                    <img
                        :src="content[currentSlideIndex]"
                        class="contentImg"
                        :style="getCorrectContentImgHeight()"
                        style="max-height: 50vh"
                        alt=""
                    >
                    <!-- Disable v-html xss checking currentSubtitleHeader is provided only by developers -->
                    <!-- eslint-disable vue/no-v-html -->
                    <p
                        v-if="subtitleHeadersGerman && subtitleHeadersGerman.length > 0"
                        class="contentSubtitleHeader"
                        v-html="currentSubtitleHeader"
                    />
                    <!-- Disable v-html xss checking currentSubtitle is provided only by developers -->
                    <!-- eslint-disable vue/no-v-html -->
                    <p
                        v-if="subtitlesGerman && subtitlesGerman.length > 0"
                        class="contentSubtitle"
                        :style="subtitleHeadersGerman && subtitleHeadersGerman.length > 0 ? '' : 'padding-top: 5vh'"
                        v-html="currentSubtitle"
                    />
                </div>

                <v-btn
                    elevation="0"
                    class="colorLightBlue"
                    style="height: calc(100vh - 120px); width: 40px"
                    size="x-small"
                    @click="nextSlide()"
                >
                    <img
                        :src="rightArrow"
                        class="arrowImg lightBlueColorFilterFromGrey"
                        alt=""
                    >
                </v-btn>
            </div>

            <div
                class="bottomControl"
                style="display: flex; flex-direction: row; width: 100%; justify-content: center"
            >
                <div style="padding-bottom: 1vh">
                    <div style="position: relative; display: flex; flex-direction: row">
                        <div
                            v-for="index in content.length"
                            :key="index"
                            :style="`width: ${fittingDotSize}; height: ${fittingDotSize}; ${index - 1 === currentSlideIndex || forceTutorial ? '' : 'cursor: pointer'}`"
                            :class="index - 1 === currentSlideIndex ? 'backgroundColorLightBlue' : ''"
                            class="dot"
                            @click="index - 1 === currentSlideIndex || forceTutorial ? {} : stepWithDots(index - 1)"
                        />
                    </div>
                </div>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import leftArrow from '../../assets/Icons/FaceliftIcons/zurueck_pfeil.svg'
import rightArrow from '../../assets/Icons/FaceliftIcons/vor_pfeil.svg'
import speaker from '../../assets/Icons/lautsprecher-14.svg'
import speakerOff from '../../assets/Icons/lautsprecher-aus-90.svg'
import cancelIcon from '../../assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg'

import {textToSpeech} from "@/util/textToSpeech";
import translation from '../../store/modules/translation'
import sanitizeHtml from '../../util/sanitizeHtml'
import {mapActions} from "vuex";
import deFlagIcon from "@/assets/Icons/deutsch-59.svg";
import enFlagIcon from "@/assets/Icons/englisch-61.svg";
import frFlagIcon from "@/assets/Icons/französisch-60.svg";
import itFlagIcon from "@/assets/Icons/flag-italy.svg";
import ruFlagIcon from "@/assets/Icons/russisch.svg";
import roFlagIcon from "@/assets/Icons/flag-romania.svg";
import trFlagIcon from "@/assets/Icons/türkisch-97.svg";
import arFlagIcon from "@/assets/Icons/syrisch-98.svg";
import kuFlagIcon from "@/assets/Icons/flag-kurdistan.svg";
import ukFlagIcon from "@/assets/Icons/flag-ukraine.svg";

export default {
    name: "SlideTutorial",
    props: {
        content: { required: true, type: Array },
        subtitlesGerman: { required: false, type: Array },
        subtitleHeadersGerman: { required: false, type: Array },
        // If the tutorial can be skipped in any way or not
        forceTutorial: { required: false, type: Boolean, default: true },
    },
    data: () => ({
        rightArrow,
        leftArrow,
        speaker,
        speakerOff,
        cancelIcon,

        sanitizeHtml,

        currentSlideIndex: 0,
        currentSubtitle: null,
        currentSubtitleHeader: null,
        language: 'Deutsch (German)',
        languages: [
            { text: 'Deutsch (German)', countryCode: 'de', icon: deFlagIcon, alt: 'Deutsche Flagge' },
            { text: 'English (English)', countryCode: 'en', icon: enFlagIcon, alt: 'Englische Flagge' },
            { text: 'Україна (Ukrainian)', countryCode: 'uk', icon: ukFlagIcon, alt: 'Ukrainische Flagge' },
            { text: 'France (French)', countryCode: 'fr', icon: frFlagIcon, alt: 'Französische Flagge' },
            { text: 'Italia (Italian)', countryCode: 'it', icon: itFlagIcon, alt: 'Italienische Flagge' },
            { text: 'Россия (Russian)', countryCode: 'ru', icon: ruFlagIcon, alt: 'Russische Flagge' },
            { text: 'România (Romanian)', countryCode: 'ro', icon: roFlagIcon, alt: 'Rumänische Flagge' },
            { text: 'Türkiye (Turkish)', countryCode: 'tr', icon: trFlagIcon, alt: 'Türkische Flagge' },
            { text: '(Arab) الإمارات العربية المتحدة', countryCode: 'ar', icon: arFlagIcon, alt: 'Arabische Flagge' },
            // { text: 'Kurdî / کوردی (Kurdish)', countryCode: 'ku', icon: kuFlagIcon, alt: 'Kurdische Flagge' },
        ],
        translatedSubtitles: [],
        ttsFinishedTalking: true,
        ttsUsedOnce: false,
        fittingDotSize: '2vw',      // Could be used for more responsive dot size
    }),
    beforeCreate() {
        // currently the slideTutorial is disabled to prevent tutorial overload
        // we close this component before it even creates any DOM nodes
        // remove this section to reenable the Tutorial
        this.$emit("close");
    },
    mounted() {
        if (this.subtitlesGerman) {
            this.currentSubtitle = this.subtitlesGerman[0];
        }
        if (this.subtitleHeadersGerman) {
            this.currentSubtitleHeader = this.subtitleHeadersGerman[0];
        }
    },
    methods: {
        ...mapActions('translation', ['translateToTargetLang']),

        nextSlide() {
            if (this.currentSlideIndex < this.content.length - 1) {
                this.currentSlideIndex++;
                this.setSlide();
            } else {
                this.endSlideTutorial();
            }
        },
        previousSlide() {
            if (!this.currentSlideIndex <= 0) {
                this.currentSlideIndex--;
                this.setSlide();
            }
        },
        stepWithDots(index) {
            this.currentSlideIndex = index;
            this.setSlide();
        },
        setSlide() {
            if (this.language === 'Deutsch (German)') {
                this.currentSubtitle = this.subtitlesGerman[this.currentSlideIndex];
                this.currentSubtitleHeader = this.subtitleHeadersGerman[this.currentSlideIndex];
            } else {
                const languageShort = this.resolveLangToShort(this.language);
                this.currentSubtitle = this.translatedSubtitles.find((el) => {
                    if (el.language === languageShort) {
                        return el;
                    }
                }).subtitles[this.currentSlideIndex];

                this.currentSubtitleHeader = this.translatedSubtitles.find((el) => {
                    if (el.language === languageShort) {
                        return el;
                    }
                }).subtitleHeaders[this.currentSlideIndex];
            }
            if (this.ttsUsedOnce) {
                this.ttsCurrentSlideSubtitle();
            }
        },
        endSlideTutorial() {
            // this.callTtsForPause();
            window.speechSynthesis.cancel();
            this.$emit("close");
        },
        async translateSubtitles() {
            const targetLanguage = this.resolveLangToShort(this.language);

            let indexOfTranslatedSubtitles = this.translatedSubtitles.map((el) => {
                return el.language;
            }).indexOf(targetLanguage);

            if (indexOfTranslatedSubtitles === -1) {
                indexOfTranslatedSubtitles = this.translatedSubtitles.length;
                this.translatedSubtitles[indexOfTranslatedSubtitles] = {
                    language: targetLanguage,
                    subtitles: [],
                    subtitleHeaders: [],
                }
                for (const index of this.subtitlesGerman.keys()) {
                    if (this.subtitlesGerman) {
                        const translatedSubtitle = await translation.actions.translateToTargetLang({
                            commit: null, dispatch: null
                        }, {
                            targetLang: targetLanguage,
                            textToTranslate: this.subtitlesGerman[index],
                            textFormat: 'html',
                            useFilter: false,
                        });
                        this.translatedSubtitles[indexOfTranslatedSubtitles].subtitles.push(translatedSubtitle);
                    }
                    if (this.subtitleHeadersGerman) {
                        const translatedSubtitleHeader = await translation.actions.translateToTargetLang({
                            commit: null, dispatch: null
                        }, {
                            targetLang: targetLanguage,
                            textToTranslate: this.subtitleHeadersGerman[index],
                            textFormat: 'html',
                            useFilter: false,
                        });
                        this.translatedSubtitles[indexOfTranslatedSubtitles].subtitleHeaders.push(translatedSubtitleHeader);
                    }
                }
            }
            this.setSlide();    // Set the translated texts for current slide
        },

        async ttsCurrentSlideSubtitle() {
            this.ttsUsedOnce = true;
            const cleanedSubtitle = this.stripHtml(this.currentSubtitle);
            if(!this.ttsFinishedTalking) {
                await textToSpeech(cleanedSubtitle, this.resolveLangToShort(this.language), () => {}, () => {});
            }
            await textToSpeech(cleanedSubtitle, this.resolveLangToShort(this.language), () => {
                this.ttsFinishedTalking = false;
            }, () => {
                this.ttsFinishedTalking = true;
            });
        },
        async callTtsForPause() {
            await textToSpeech(this.currentSubtitle, this.resolveLangToShort(this.language), () => {}, () => {});
        },
        resolveLangToShort(language) {
            switch (language) {
                case 'Deutsch (German)':
                    return 'de';
                case 'English (English)':
                    return 'en';
                case 'Україна (Ukrainian)':
                    return 'uk';
                case 'France (French)':
                    return 'fr';
                case 'Italia (Italian)':
                    return 'it';
                case 'Россия (Russian)':
                    return 'ru';
                case 'România (Romanian)':
                    return 'ro';
                case 'Türkiye (Turkish)':
                    return 'tr';
                /* case '(Arab) الإمارات العربية المتحدة ':
                    return 'ar';
                case 'Kurdî / کوردی (Kurdish)':
                    return 'ku'; */
            }
        },
        stripHtml(html)
        {
            let tmp = document.createElement("DIV");
            tmp.innerHTML = html;
            return tmp.textContent || tmp.innerText || "";
        },
        getCorrectContentImgHeight() {
            const hasSubtitles = this.subtitlesGerman && this.subtitlesGerman.length > 0;
            const hasSubtitleHeaders = this.subtitleHeadersGerman && this.subtitleHeadersGerman.length > 0;
            if (hasSubtitles && hasSubtitleHeaders) {
                return 'height: 70%';
            } else if (hasSubtitles && !hasSubtitleHeaders) {
                return 'height: 80%';
            } else if (!hasSubtitles && hasSubtitleHeaders) {
                return 'height: 90%';
            } else {
                return 'height: 100%';
            }
        },
    }
}
</script>

<style scoped>
    .colorLightBlue {
        color: #5f8ebf;
    }

    /* Used for the bottom dots/slide index indicator */
    .backgroundColorLightBlue {
        background-color: #5f8ebf !important;
    }

    .lightBlueColorFilterFromGrey {
        /* Set Color to #5f8ebf for img tags */
        filter: invert(0) sepia(1) saturate(2) hue-rotate(170deg);
    }

    .lightBlueColorFilterFromBlack {
        /* Set Color to #5f8ebf for img tags */
        filter: invert(55%) sepia(72%) saturate(296%) hue-rotate(169deg) brightness(84%) contrast(86%);
    }

    .outerContainer {
        position: relative;
        width: 100%;
        height: 100%;
        background-color: white;
        overflow: hidden;
    }

    .contentContainer {
        width: 100%;
        height: 100%;
        padding: 5vh 0.5vw;
        margin: 12px 0;
    }

    .contentImg {
        display: block;
        width: 100%;
        object-fit: contain;
        margin: auto;
    }

    .contentSubtitleHeader {
        width: 100%;
        height: 10%;
        margin: auto;
        font-size: 3rem;
        text-align: center;
    }

    .contentSubtitle {
        width: 100%;
        height: 20%;
        margin: auto;
        padding: 0 48px;
        font-size: 1.5rem;
        text-align: center;
    }

    .arrowImg {
        height: 24px;
    }

    #topControl {
        position: absolute;
        display: flex;
        justify-content: space-between;
        top: 0;
        left: 0;
        right: 0;
        padding: 0.5vw;
    }

    .bottomControl {
        position: absolute;
        bottom: 0;
    }

    .dot {
        width: 2vw;
        height: 2vw;
        background-color: #73737350;
        border-radius: 50%;
        margin-left: 5px;
        margin-right: 5px;
        margin-top: 0.55rem;
    }

    @media screen and (max-width: 900px) {
        .contentSubtitleHeader {
            font-size: 2rem;
        }

        .contentSubtitle {
            font-size: 1rem;
        }
    }
</style>
