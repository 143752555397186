<template>
    <div>
        <div
            class="d-flex"
            style="flex-direction: column; align-content: center; max-width: 0; max-height: 0"
        >
            <div class="custom-badge-container">
                <v-tooltip
                    v-if="eventAmount > 0 || (eventList ? eventList.length : false)"
                    location="top"
                >
                    <template v-slot:activator="{ props }">
                        <button
                            v-if="eventAmount > 0 || (eventList ? eventList.length : false)"
                           
                            id="testButton"
                            class="kindOfHeartbeat"
                            :class="usage === 'calendar' ? 'custom-badge-calendar' : 'custom-badge'"
                            :style="badgeStyling"
                            aria-hidden="true"
                            v-bind="props"
                            @click="eventAmount ? '' : badgeClicked()"
                        >
                            {{ eventAmount ? eventAmount : eventList ? eventList.length : '' }}
                        </button>
                    </template>
                    <span>Benachrichtigungen</span>
                </v-tooltip>
            </div>
            <v-tooltip
                v-if="deleteButton && showClearBtn"
                location="bottom"
                style="z-index: 9"
            >
                <template v-slot:activator="{ props }">
                    <!-- TODO: Currently using deleteButtonPositioningChat class for ChatWidget as a hotfix -> delete button is slightly off -->
                    <v-btn
                        icon="$vuetify"
                        variant="plain"
                       
                        :class="usage === 'calendar' ? 'deleteButtonCalendar' : deleteButtonClasses + 'deleteButton'"
                        elevation="2"
                        v-bind="props"
                        @click="clearBadges()"
                    >
                        <img
                            :src="papierkorbIcon"
                            class="iconToRed pa-0"
                            :style="smallBadge ? 'max-width: 14px' : 'max-width: 21px'"
                            alt="Alles als gesehen markieren"
                        >
                    </v-btn>
                </template>
                <span>Alle Benachrichtigungen als gesehen markieren</span>
            </v-tooltip>
        </div>
        <AreYouSurePopup
            v-if="showAreYouSure"
            v-model:show-dialog="showAreYouSure"
            :header-color="usage === 'splan' || usage === 'vertretungsplan' ? 'rgb(var(--v-theme-timetableColor))' : usage === 'calendar' ? '#282f44' : usage === 'blackboard' ? 'rgb(var(--v-theme-blackboardGrey))' : '#3ba934'"
            ok-btn-color="frot"
            :content-text-prop="areYouSureMsg"
            @close="showAreYouSure = false"
            @ok="confirmClearBadges"
        />
    </div>
</template>

<script>
import papierkorbIcon from "@/assets/Icons/FaceliftIcons/loeschen_muelleimer.svg";
import AreYouSurePopup from "./AreYouSurePopup";
import {mapGetters} from "vuex";

export default {
    name: 'ClickableBadge',
    components: {AreYouSurePopup},
    props: {
        // Chronological List of events in widget (newest is on index 0)
        eventList: { required: false, type: Array},
        smallEventList: { required: false, type: Array},
        eventAmount: { required: false, type: Number }, // In case you only have the number of events to display in the badge
        deleteButton: { required: false, type: Boolean, default: false },
        usage: { required: false, type: String },
        smallBadge: { required: false, type: Boolean, default: false },
        teacherWeekly: { required: false, type: Boolean, default: false }, // Need this for some styling
    },
    data: () => ({
        currentEventIndex: 0,
        showClearBtn: false,
        showAreYouSure: false,
        areYouSureMsg: 'Wollen Sie wirklich alle Benachrichtigungen als gesehen markieren?',

        papierkorbIcon
    }),
    computed: {
        ...mapGetters("auth", ["accountRole"]),

        deleteButtonClasses() {
            let classes = '';
            if (this.usage === 'chat') {
                classes += 'deleteButtonPositioningChat ';
            } else {
                classes += 'deleteButtonPositioning ';
            }
            if (this.teacherWeekly) {
                classes += 'teacherWeeklyDeleteButton ';
            }
            if (this.smallBadge) {
                classes += 'smallBadge ';
            }
            return classes;
        },

        badgeStyling() {
            let style = '';
            if (this.smallBadge) {
                style += 'height: 20px !important; width: 20px !important; font-size: 15px; ';
            }
            if (this.teacherWeekly) {
                style += 'top: -26px !important; ';
            }
            return style;
        },
    },
    watch: {
        eventList(newVal, oldVal) {
            if(this.usage==='blackboard'){
                if(newVal && newVal.length !== oldVal.length){
                    this.currentEventIndex = 0;
                }
            }else{
                this.currentEventIndex = 0;
            }
            
        },
        smallEventList(newVal, oldVal) {
            if(this.usage==='blackboard'){
                if(newVal && newVal.length !== oldVal.length){
                    this.currentEventIndex = 0;
                }
            }else{
                this.currentEventIndex = 0;
            }
        },
    },
    methods: {
        badgeClicked() {
            this.showClearBtn = true;
            let list = this.eventList;
            if (this.smallEventList) {
                list = this.smallEventList;
            }
            this.$emit('badgeClicked', list[this.currentEventIndex]);
            this.currentEventIndex++;
            if (this.currentEventIndex === list.length) {
                this.currentEventIndex = 0;
            }
            setTimeout(() => {
                this.showClearBtn = false;
            }, 3000);
        },
        clearBadges() {
            if (this.accountRole === 'pupil') {
                this.areYouSureMsg = 'Willst du wirklich alle Benachrichtigungen als gesehen markieren?'
            }
            this.showAreYouSure = true;
        },
        confirmClearBadges() {
            this.showAreYouSure = false;
            this.$emit('clearBadges');
        }
    }
}
</script>

<style lang="scss" scoped>
.custom-badge-container {
    position: relative;
    width: 100%;
    pointer-events: none;
    z-index: 4;
}

.custom-badge {
    position: absolute;
    pointer-events: all;
    top: -10px;
    left: -10px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background-color: #ff0000;
    color: white;
}

.custom-badge-calendar {
    position: absolute;
    pointer-events: all;
    top: -24px;
    left: -23px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background-color: #ff0000;
    color: white;
}

@keyframes heartbeat {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
    }
    1.4% {
        -webkit-transform: scale(1.10);
        transform: scale(1.10);
    }
    2.0% {
        -webkit-transform: scale(0.98);
        transform: scale(0.98);
    }
    4.6% {
        -webkit-transform: scale(1.18);
        transform: scale(1.18);
    }
    6.8% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.kindOfHeartbeat {
    -webkit-animation: heartbeat 30s ease-in-out 30s infinite both;
    animation: heartbeat 15s ease-in-out 2s infinite both;
}

.deleteButton{
    min-width: 30px !important;
    max-width: 30px !important;
    min-height: 30px !important;
    max-height: 30px !important;
    background-color:white !important;
    position: absolute;
    z-index: 10;
}
.deleteButtonCalendar{
    min-width: 30px !important;
    max-width: 30px !important;
    min-height: 30px !important;
    max-height: 30px !important;
    background-color:white !important;
    z-index: 10;
    margin-left: -54px;
    margin-top: -7px;
}

.deleteButtonPositioning {
    top: 36px;
    left: -10px;
}

.deleteButtonPositioningChat {
    margin-top: 35px;
}

.smallBadge {
    height: 20px !important;
    width: 20px !important;
    min-height: 20px !important;
    min-width: 20px !important;
    position: relative;
    top: 23px;
}

.teacherWeeklyDeleteButton {
    top: 6px !important;
}
</style>
