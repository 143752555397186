<template>
    <div class="image-capture">
        <v-dialog
            v-if="dialog"
            v-model="dialog"
            content-class="dialog"
            :fullscreen="fullscreen"
        >
            <v-card
                class="dialog"
                :width="fullscreen ? '' : imageWidth"
            >
                <v-toolbar class="toolbarBlue">
                    <v-toolbar-items style="display: flex; position: absolute; left: 5px;">
                        <v-btn
                            icon="$vuetify"
                            variant="plain"
                            @click="toggleCameraFacing"
                        >
                            <v-icon color="white">
                                mdi-camera-flip
                            </v-icon>
                        </v-btn>
                    </v-toolbar-items>
                    <v-spacer />
                    <v-spacer />
                    <v-toolbar-items style="display: flex; position: absolute; right: 5px">
                        <!-- <v-btn
                            icon
                            @click="toggleFullscreen"
                        >
                            <v-icon>mdi-arrow-expand</v-icon>
                        </v-btn> -->
                        <v-btn
                            icon="$vuetify"
                            variant="plain"
                            @click="closeDialog"
                        >
                            <v-icon color="white">
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <div :class="fullscreen ? 'fullscreen content' : 'content'">
                    <video
                        v-show="!isPhotoTaken"
                        ref="camera"
                        autoplay
                        height="auto"
                        width="100%"
                    />
                    <canvas
                        v-show="isPhotoTaken"
                        ref="canvas"
                        :height="imageHeight"
                        :width="imageWidth"
                    />
                    <div style="display: flex; position: absolute; bottom: 15%">
                        <v-btn
                            v-if="isPhotoTaken"
                            class="discard toolbar"
                            elevation="6"
                            icon="$vuetify"
                            variant="plain"
                            size="x-large"
                            @click="discardPicture"
                        >
                            <v-icon> mdi-delete </v-icon>
                        </v-btn>
                        <v-btn
                            :disabled="isPhotoTaken"
                            :class="!isPhotoTaken ? 'toolbar' : ''"
                            elevation="6"
                            icon="$vuetify"
                            variant="plain"
                            size="x-large"
                            @click="takePicture"
                        >
                            <v-icon> mdi-camera </v-icon>
                        </v-btn>

                        <v-btn
                            v-if="isPhotoTaken"
                            class="accept toolbar"
                            elevation="6"
                            icon="$vuetify"
                            variant="plain"
                            size="x-large"
                            @click="acceptPicture"
                        >
                            <v-icon> mdi-check-bold </v-icon>
                        </v-btn>
                    </div>
                    <div style="display: flex; position: absolute; bottom: 5%">
                        <v-btn
                            v-if="isPhotoTaken"
                            class="toolbar"
                            elevation="6"
                            icon="$vuetify"
                            variant="plain"
                            size="x-large"
                            @click="rotatePicture"
                        >
                            <v-icon> mdi-rotate-right </v-icon>
                        </v-btn>
                    </div>
                </div>
            </v-card>

            <!-- Loading overlay -->
            <v-overlay
                absolute
                :model-value="showLoadingOverlay"
                style="width: 100% !important; z-index: 500 !important;"
            >
                <v-progress-circular
                    v-if="showLoadingOverlay"
                    indeterminate
                    size="100"
                />
            </v-overlay>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default {
    name: "ImageCapture",
    props: {
        toggleDialog: { required: true, type: Boolean, default: false },
        imageCaptureUsage: { required: false, type: String, default: '' }
    },
    data: () => ({
        dialog: false,

        stream: null,
        loading: false,
        cameraActive: false,
        defaultWidth: 720,
        defaultHeight: 1020,
        settings: {
            video: {
                facingMode: 'environment',
            },
            audio: false,
        },
        imageWidth: null,
        imageHeight: null,
        isPhotoTaken: false,

        fullscreen: false,
        showLoadingOverlay: false,

        cameraImage: null,
        currentRotation: 0,
    }),
    computed: {
        ...mapState('util', ['windowWidth', 'windowHeight']),
    },
    watch: {
        toggleDialog() {
            this.dialog = !this.dialog;
        },
        async dialog() {
            if (this.dialog) {
                this.startCamera();
            } else {
                await this.stopCamera();
            }
        },
    },
    mounted() {
        // console.log('image capture mounted');
        // calculate width and height
        this.defaultHeight = Number.parseInt(this.windowHeight) - 200;
        this.defaultWidth = Math.trunc(this.defaultHeight / Math.sqrt(2));
    },
    methods: {
        ...mapMutations('snackbar', [ 'showSnackbar' ]),
        ...mapMutations('imageCapture', [ 'setFile' ]),

        async startCamera(){
                this.imageWidth = this.defaultWidth;
                this.imageHeight = this.defaultHeight;
                this.loading = true;
                this.settings.video.width = { ideal: this.defaultWidth };
                this.settings.video.height = { ideal: this.defaultHeight };

                navigator.mediaDevices
                .getUserMedia(this.settings)
                .then(stream => {
                    this.stream = stream;
                    this.$refs.camera.srcObject = stream;
                    let _this = this;
                    setTimeout(() => {
                        // leave some time to decode stream, then read the camera feeds height
                        this.imageHeight = _this.$refs.camera.clientHeight;
                        this.imageWidth = _this.$refs.camera.clientWidth;
                        // canvas proper intial size
                        this.giveCanvasInitialSize();
                        this.loading = false;
                        this.cameraActive = true;
                    }, 300);
                })
                .catch(error => {
                    this.loading = false;
                    this.cameraActive = false;
                    this.showSnackbar({ color: 'error', message:
                        'Es konnte keine Kamera geöffnet werden. Bitte überprüfen Sie die Berechtigungen in ihrem Browser und stellen Sie sicher, dass keine andere Anwendung Ihre Kamera benutzt.'});
                    this.closeDialog();
                });
        },

        async stopCamera() {
            this.stream.getTracks().forEach(track => {
                track.stop();
            });
            this.cameraActive = false;
        },

        toggleFullscreen() {
            this.fullscreen = !this.fullscreen;
        },

        giveCanvasInitialSize() {
            const canvas = this.$refs.canvas;
            const context = this.$refs.canvas.getContext('2d');
            this.imageWidth = this.$refs.camera.clientWidth;
            this.imageHeight = this.$refs.camera.clientHeight;
            canvas.width = this.imageWidth;
            canvas.height = this.imageHeight;
        },

        takePicture() {
            this.giveCanvasInitialSize();
            const context = this.$refs.canvas.getContext('2d');
            // set timeout to give the canvas enough time to write the image
            // takes longer on mac
            setTimeout(() => {
                context.drawImage(this.$refs.camera, 0, 0, this.imageWidth, this.imageHeight);
                this.isPhotoTaken = true;
                // save image for rotation
                this.$nextTick(() => {
                    const imgUrl = this.$refs.canvas.toDataURL('image/png');
                    this.cameraImage = new Image;
                    this.cameraImage.src = imgUrl;
                });
            }, 250);
        },

         async rotatePicture() {
            this.showLoadingOverlay = true;
            this.currentRotation++;
            const canvas = this.$refs.canvas;
            const context = canvas.getContext('2d');
            const radAngle = 90 * Math.PI / 180; // Rotate needs radians

            // Clear the canvas and adjust dimensions
            context.clearRect(0, 0, canvas.width, canvas.height);
            const width = this.imageWidth;
            const height = this.imageHeight;
            canvas.width = height;
            canvas.height = width;
            this.imageWidth = canvas.width;
            this.imageHeight = canvas.height;

            // Wait for the DOM to update
            await this.$nextTick();

            // Perform canvas transformations
            context.translate(canvas.width / 2, canvas.height / 2);
            context.rotate(this.currentRotation * radAngle);

            // Determine correct dimensions based on rotation
            const isOddRotation = this.currentRotation % 2 === 1;
            const heightDivider = isOddRotation ? canvas.height : canvas.width;
            const widthDivider = isOddRotation ? canvas.width : canvas.height;

            // Draw the image onto the canvas
            context.drawImage(this.cameraImage, -heightDivider / 2, -widthDivider / 2);

            this.showLoadingOverlay = false;
        },

        acceptPicture() {
            this.emitPicture();
            this.isPhotoTaken = false;
            this.closeDialog();
        },

        discardPicture() {
            this.isPhotoTaken = false;
        },

        closeDialog() {
            if (this.imageCaptureUsage === 'vfs') { // Need to emit here because VFS is having trouble reopening afterwards the other way
                this.$emit("toggleClose");
            } else {
                this.dialog = false;
            }
        },

        async toggleCameraFacing() {
            if (this.settings.video.facingMode === 'environment') {
                this.settings.video.facingMode = 'user';
            } else {
                this.settings.video.facingMode = 'environment';
            }
            await this.stopCamera();
            await this.startCamera();
        },

        emitPicture() {
            this.$refs.canvas.toBlob((blob) => {
                let file = new File([blob], 'Foto.jpg', { type: 'image/jpeg' });
                this.$emit('handleImageCaptured');
                // leave a small delay to commit currentAppointment to store via parentComponent
                setTimeout(() => {
                    this.setFile(file);
                }, 250);
            });
            this.$refs.canvas.getContext('2d')
                .clearRect(0,0,this.imageWidth, this.imageHeight);
        },

        // for testing, leaving this incase we want a download button
        downloadPicture() {
            let url = this.$refs.canvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
            var a = document.createElement('a');
            a.href = url;
            a.download = 'camera.jpg';
            document.body.appendChild(a);
            a.click();
            setTimeout(function(){
                a.remove();
                window.URL.revokeObjectURL(url);
            }, 1000);
        },
    }
}
</script>

<style scoped>

.image-capture {
    display: flex;
}

.toolbar {
    background-color: rgb(var(--v-theme-grau)) !important;
}

.toolbarBlue {
    background-color: rgb(var(--v-theme-headerblue)) !important;
}

.dialog {
    min-width: 400px;
    margin: auto;
}

.accept {
    color: rgb(var(--v-theme-gruen)) !important;
}

.discard {
    color: rgb(var(--v-theme-frot)) !important;
}

.content {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.fullscreen {
    overflow: hidden;
    width: 100%;
    min-width: 480px;
    max-width: 100vw;
    max-height: 95vh;
}
</style>
