<template>
    <v-tooltip
        location="bottom"
        style="z-index: 12"
    >
        <template v-slot:activator="{ props:tooltip }">
            <v-menu
                :attach="'#aktionsMenuAttachPoint'"
                max-width="300"
                :offset="[0,10]"
                location="left"
                style="z-index: 12"
            >
                <template v-slot:activator="{ props:menu }">
                    <v-btn
                        id="aktionsMenuButton"
                        size="x-small"
                        variant="text"
                        color="white"
                        class="ml-2 pa-0 optionBtn"
                        style="width: 20px; height: 20px; float: right"
                        :style="isMenuEnabled ? '' : 'display: none'"
                        v-bind="$mergeProps(menu, tooltip)"
                    >
                        <img
                            :src="plusIcon"
                            class="icon20 iconToWhite"
                            alt="Aktionsmenü"
                        >
                    </v-btn>
                    <div id="aktionsMenuAttachPoint" />
                </template>
                <div>
                    <!-- Menu Content -->
                    <v-btn
                        v-if="accountRole === 'teacher' && item.learningPath && item.learningPath.pathVFSOrigin === item._id
                            && !item.rulesetAppointment && !item.rulesetTask"
                        elevation="0"
                        class="fileSystemHeaderMenuButton subMenu"
                        @click="createLearningPath"
                    >
                        <v-icon
                            class="iconToDunkelgrau pr-2"
                        >
                            mdi-flag-variant
                        </v-icon>
                        Neue Lernstation
                    </v-btn>
                    <div v-else>
                        <v-btn
                            v-if="uploadLocalFileFunction && !isStorageFull"
                            elevation="0"
                            class="fileSystemHeaderMenuButton subMenu"
                            @click="uploadLocalFileFunction"
                        >
                            <img
                                class="icon20 pr-3"
                                :src="hochladenIcon"
                                alt="Datei hochladen"
                            >
                            Datei Hochladen
                        </v-btn>
                        <v-btn
                            elevation="0"
                            class="fileSystemHeaderMenuButton subMenu"
                            @click="startFolderCreation"
                        >
                            <v-icon
                                class="iconToDunkelgrau pr-2"
                            >
                                mdi-folder
                            </v-icon>
                            Neuer Ordner
                        </v-btn>
                    </div>
                    <v-btn
                        v-if="accountRole === 'teacher' && !item.learningPath && !item.rulesetAppointment && !item.rulesetTask"
                        elevation="0"
                        class="fileSystemHeaderMenuButton subMenu"
                        @click="createLearningPath"
                    >
                        <img
                            class="iconToDunkelgrau icon20 pr-3"
                            :src="lernpfadIcon"
                            alt="Neuer Lernpfad"
                        >
                        Neuer Lernpfad
                    </v-btn>
                </div>
            </v-menu>
        </template>
        <span>Aktionsmenü</span>
    </v-tooltip>
</template>

<script>
import { mapGetters } from 'vuex';
import plusIcon from "@/assets/Icons/plus.svg";
import lernpfadIcon from '@/assets/Icons/FaceliftIcons/Lernpfad.svg';
import hochladenIcon from '@/assets/Icons/FaceliftIcons/hochladen.svg';

export default {
    name: 'FileSystemPlusMenu',
    props: {
        folderType: {required: true, type: String},
        isStorageFull: { required: false, type: Boolean, default: false },
        item: { required: false, default: {} },
        uploadLocalFileFunction: { required: true, type: Function },
        startFolderCreation: { required: true, type: Function },
        uploadFileFromSharedFunction: { required: false, type: Function },
        uploadFileFromPrivateFunction: { required: false, type: Function },
    },
    data: () => ({
       plusIcon,
        lernpfadIcon,
        hochladenIcon
    }),
    computed: {
        ...mapGetters('auth', [ 'accountRole', 'accountId' ]),
        isMenuEnabled() {
            if (this.accountRole === 'teacher') {
                return true;
            } else if (this.accountRole === 'pupil') {
                // check if pupil is owner
                return this.item.owner === this.accountId;
            }
            return false;
        }
    },
    methods: {
        createLearningPath() {
            this.$emit('createLearningPath');
        },
    },
}
</script>

<style scoped>
.optionBtn {
    border-radius: 8px;
    min-width: 35px !important;
    width: 35px !important;
    height: 35px !important;
}

.fileSystemHeaderMenuButton {
    width: 100%;
    border-radius: 0;
}

.subMenu {
    justify-content: flex-start;
    text-transform: none;
}
</style>
