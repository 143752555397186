<template>
    <v-tooltip location="top">
        <template v-slot:activator="{ props }">
            <v-badge
                v-if="video || audio"
                color="#ff0000"
                content="1"
                :model-value="accountRole === 'pupil' && !file.seenByMe"
                location="left"
                style="z-index: 9; display: flex"
            >
                <v-btn
                    theme="dark"
                    size="x-small"
                    class="fileListButton"
                    :style="`width: ${buttonSize}px; height: ${buttonSize}px`"
                    elevation="0"
                    style="background-color: rgb(var(--v-theme-chatColor))"

                    v-bind="props"
                    @click="playFile"
                >
                    <img
                        :src="playIcon"
                        :alt="`${video ? 'Video' : 'Audio'} abspielen`"
                        style="filter: brightness(1000%);"
                        class="fileListButtonIcon"
                    >
                </v-btn>
            </v-badge>
        </template>
        <span>{{ video ? 'Video' : 'Audio' }} abspielen</span>
    </v-tooltip>
</template>

<script>
import playIcon from "@/assets/Icons/rechts-filled-11.svg";
import {allowedExtensionsForVideoPlayer, allowedExtensionsForAudioPlayer} from "@/constants/allowedMimeTypes";

export default {
    name: "FileListPlayButton",
    props: {
        file: { type: Object, required: true },
        primaryColor: { type: String, required: false },
        accountRole: { type: String, required: true },
        buttonSize: { type: Number, required: false, default: 36 },
    },
    data: () => ({
        video: false,
        audio: false,

        playIcon,
    }),
    mounted() {
        if(allowedExtensionsForVideoPlayer.includes(this.file.title.split('.').pop().toLowerCase())) {
            this.video = true;
        } else if(allowedExtensionsForAudioPlayer.includes(this.file.title.split('.').pop().toLowerCase())) {
            this.audio = true;
        }
    },
    methods: {
        playFile(event) {
            event.stopPropagation();
            this.$emit(this.video ? 'playVideo' : 'playAudio');
        }
    }
}
</script>

<style scoped>
.btnStyle {
    min-height: 36px;
    min-width: 36px;
    margin: 4px;
}

.actionIcon {
    height: 20px;
    filter: brightness(1000%);
}

@media only screen and (max-width: 900px) {
    .btnStyle {
        width: 18px;
        height: 18px;
        min-height: 18px;
        min-width: 18px !important;
        margin: 2px;
    }
    .actionIcon {
        height: 14px;
    }
}
</style>
