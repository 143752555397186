<template>
    <v-dialog
        v-model="show"
        width="500"
        @close="() => { $emit('close') }"
        @click:outside="() => { $emit('close') }"
    >
        <v-card style="border-radius: 16px">
            <v-card-title
                class="d-flex justify-space-between"
                style="background-color: rgb(var(--v-theme-fileArchiveOrange)); color: white"
            >
                <p>{{ dialogHeaderText }}</p>
                <v-btn
                    icon="$vuetify"
                    variant="plain"
                    size="small"
                    @click="() => { $emit('close') }"
                >
                    <img
                        :src="closeIcon"
                        class="iconToWhite icon30"
                        alt="Dialog schließen"
                    >
                </v-btn>
            </v-card-title>
            <v-card-text
                class="mt-2"
                style="height: 90%"
            >
                <!-- Content of Dialog -->
                <p>{{ `Dateiname: ${newFileName ? newFileName : file ? file.originalname : 'templateFile.txt'}` }}</p>
                <v-text-field
                    v-model="newFileName"
                    placeholder="Dateiname eingeben"
                    variant="outlined"
                    density="compact"
                />
                <p>{{ `Ausgewählter Ordner: ${selectedFolder ? selectedFolder.name : '/'}` }}</p>
                <div id="folderContainer">
                    <FileSystemExpandableRowElement
                        v-for="item in folders"
                        :key="`saveToFolderEntry${item._id}`"
                        :item="item"
                        :selected-folder="selectedFolder"
                        @folder-selected="setFolderSelected"
                    />
                </div>
            </v-card-text>
            <v-card-actions class="d-flex justify-end">
                <v-btn
                    theme="dark"
                    color="grey"
                    elevation="0"
                    style="text-transform: inherit"
                    @click="() => { $emit('close') }"
                >
                    Abbrechen
                </v-btn>
                <v-btn
                    color="success"
                    elevation="0"
                    style="text-transform: inherit"
                    @click="startTransaction"
                >
                    Bestätigen
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import FileSystemExpandableRowElement from "@/components/FileArchive/FileSystemExpandableRowElement";
import {mapActions} from "vuex";
import closeIcon from '@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg';

export default {
    name: 'SaveToFolderDialog',
    components: {FileSystemExpandableRowElement},
    props: {
        file: { required: true, type: Object },
        mode: { required: true, type: String },
    },
    data: () => ({
        folders: [],
        show: true,
        selectedFolder: null,
        newFileName: '',
        fileExtension: '',
        originalFileName: '',

        //Icons
        closeIcon,
    }),
    computed: {
        dialogHeaderText() {
            let returnString = 'Datei';

            switch (this.mode) {
                case 'copy': returnString += ' kopieren'; break;
                case 'move': returnString += ' verschieben'; break;
                case 'save': returnString += ' speichern unter'; break;
                default: returnString = 'Ordner auswählen'; break;
            }
            return returnString;
        }
    },
    async mounted() {
        // get folders
        const vfs = await this.getVFSFolderUploadsV2({});

        // Create and modify rootFolder object
        const rootFolder = JSON.parse(JSON.stringify(vfs));
        rootFolder.name = 'In Dateien';
        rootFolder.folders = [];
        this.folders.push(rootFolder);

        vfs.folders.forEach((folder) => {
            this.folders.push(folder);
        })

        if (this.file && this.file.uploadedName) {
            const splitFileName = this.file.uploadedName.split('.');
            for (let i = 0; i < splitFileName.length-1; i++) {
                this.newFileName += splitFileName[i];
                this.originalFileName += splitFileName[i];
            }
            this.fileExtension = splitFileName[splitFileName.length-1];
            // console.log(this.file, splitFileName);
        } else if (this.file && this.file.name) {
            this.newFileName = this.file.name;
        }
    },
    methods: {
        ...mapActions("virtualFileSystem", ["getVFSFolderUploadsV2"]),
        setFolderSelected(folder) {
            this.selectedFolder = folder;
        },
        startTransaction() {
            const completeNewFileName = `${this.newFileName ? this.newFileName : this.originalFileName}.${this.fileExtension}`;
            if (this.mode === 'copy') {
                this.$emit('copyFileToDestination', completeNewFileName, this.selectedFolder._id);
            } else if (this.mode === 'move') {
                this.$emit('moveFileToDestination', completeNewFileName, this.selectedFolder._id);
            } else if (this.mode === 'save') {
                this.$emit('saveFileToDestination', completeNewFileName, this.selectedFolder._id);
            }
        }
    }
}
</script>

<style scoped>

</style>
