<template>
    <div>
        <div class="editHeader d-flex justify-space-between align-center pa-2">
            <div class="d-flex justify-space-around align-center">
                <v-btn
                    icon="$vuetify"
                    variant="plain"
                    color="#ffffff"
                    @click="
                        () => {
                            $emit('stopEvent');
                        }
                    "
                >
                    <v-icon>mdi-undo</v-icon>
                </v-btn>
                <p
                    style="color: white; font-size: larger"
                >
                    Neuen Schüler anlegen
                </p>
            </div>
            <v-btn
                class="text-none"
                color="success"
                elevation="0"
                :disabled="!valid"
                :loading="loadingSave"
                @click="save"
            >
                Speichern
            </v-btn>
        </div>
        <div class="editContainer pa-1">
            <div class="innerContainer pa-3">
                <!--#region Pupil -->
                <p><b>Schülerdaten</b></p>

                <v-text-field
                    v-model="firstName"
                    variant="outlined"
                    density="compact"
                    hide-details
                    label="Vorname"
                    placeholder="Vorname eingeben"
                    class="inputField mb-2"
                    :class="{ errorInput: fields.firstName }"
                    @update:model-value="
                        generateUsername();
                        validateInput();
                    "
                />

                <v-text-field
                    v-model="lastName"
                    variant="outlined"
                    density="compact"
                    hide-details
                    label="Nachname"
                    placeholder="Nachname eingeben"
                    class="inputField mb-2"
                    :class="{ errorInput: fields.lastName }"
                    @update:model-value="
                        () => {
                            overrideFamilyName();
                            generateUsername();
                            generateParentLoginId();
                            validateInput();
                        }
                    "
                />

                <v-textarea
                    v-model="note"
                    variant="outlined"
                    density="compact"
                    hide-details
                    auto-grow
                    label="Notiz"
                    placeholder="Notiz eingeben"
                    class="inputField mb-4"
                />
                <v-tooltip
                    location="top"
                >
                    <template v-slot:activator="{ props }">
                        <div
                           
                            style="display: flex; flex-direction: row; justify-content:space-between; width: fit-content mb-1"
                            v-bind="props"
                        >
                            <span>QR-Anmeldung</span>
                            <v-switch
                                v-model="invertedQrLoginDeactivated"
                                inset
                                hide-details
                                color="success"
                                class="mt-0"
                            />
                        </div>
                    </template>
                    <span>Aktivieren Sie dieses Feld, um die Qr-Anmeldung für diesen Nutzer zu aktivieren.</span>
                </v-tooltip>
                <!--#region Help -->
                <p><b>Hilfen</b></p>

                <div style="width: 100%">
                    <div
                        v-for="(accessibility, _id) in accessibilities"
                        :key="_id"
                        class="d-flex justify-space-between align-center mb-1"
                    >
                        <div class="d-flex align-center mb-1">
                            <span class="text-capitalize">{{ accessibility.text }}</span>

                            <v-tooltip location="top">
                                <template v-slot:activator="{ props }">
                                    <img
                                        v-if="accessibility.explanation"
                                        :src="questionMarkIcon"
                                        class="icon pl-1"
                                        alt="Erklärung"
                                       
                                        v-bind="props"
                                    >
                                </template>
                                <p
                                    style="max-width: 250px"
                                    v-html="accessibility.explanation"
                                />
                            </v-tooltip>
                        </div>

                        <v-switch
                            v-model="accessibility.value"
                            inset
                            hide-details
                            :disabled="
                                accessibility.text === 'Vorlesen' && !accessibilities[0].value
                            "
                            color="success"
                            class="mt-0"
                            @update:model-value="toggleA11y(accessibility)"
                        />
                    </div>
                </div>
                <!--#endregion -->

                <div>
                    <div class="d-flex justify-space-between align-center">
                        <p><b>Login</b></p>
                    </div>

                    <v-text-field
                        v-model="username"
                        variant="outlined"
                        density="compact"
                        hide-details
                        label="Nutzername"
                        placeholder="Nutzername eingeben"
                        class="inputField mb-2"
                        :class="{ errorInput: fields.username }"
                        @update:model-value="
                            () => {
                                this.fields.usernameGenerated = false;
                                validateInput();
                            }
                        "
                    />
                    <span
                        v-if="fields.usernameStrangeLetters"
                        style="color: rgb(var(--v-theme-frot))"
                    >
                        Es werden nur die Buchstaben A-Z (ohne Umlaute) und Zahlen
                        unterstützt.
                    </span>
                    <span
                        v-if="fields.usernameDuplicate"
                        style="color: rgb(var(--v-theme-frot))"
                    >
                        Der Nutzer existiert bereits, passen Sie den Nutzernamen an.
                    </span>
                    <span
                        v-if="fields.usernameGenerated"
                        style="color: rgb(var(--v-theme-forange))"
                    >
                        Der Nutzer existiert bereits, der Nutzername wurde automatisch
                        angepasst.
                    </span>

                    <v-text-field
                        v-model="password"
                        variant="outlined"
                        density="compact"
                        hide-details
                        label="Passwort"
                        placeholder="Passwort eingeben"
                        type="password"
                        class="inputField"
                        :class="{ errorInput: fields.password }"
                        @update:model-value="validateInput"
                    />
                    <span
                        v-if="fields.password"
                        style="color: rgb(var(--v-theme-frot))"
                    >
                        Mind. 8 Buchstaben und 1 Zahl
                    </span>
                    <v-tooltip
                        location="top"
                    >
                        <template v-slot:activator="{ props }">
                            <div
                               
                                style="display: flex; flex-direction: row; width: fit-content; align-items: center"
                                class="pa-0"
                                v-bind="props"
                            >
                                <v-checkbox
                                    v-model="canChangePassword"
                                />
                                <span>Darf Passwort ändern</span>
                            </div>
                        </template>
                        <span>Wenn ausgewählt, darf dieser Nutzer sein Passwort selbst ändern.</span>
                    </v-tooltip>
                </div>
                <!--#endregion -->

                <!--#region Parent -->
                <div
                    id="parentContent"
                    class="d-flex justify-space-between align-center"
                >
                    <div
                        class="d-flex justify-space-between align-center"
                        style="width: 100%"
                    >
                        <div class="d-flex justify-space-between align-center mb-1">
                            <p>
                                <b>Elterndaten von {{ firstName }}</b>
                            </p>
                        </div>

                        <v-btn
                            v-if="!selectedParent"
                            variant="text"
                            color="gruen"
                            class="text-none"
                            @click="
                                createNewParent = !createNewParent;
                                scrollToParent();
                            "
                        >
                            {{ createNewParent ? "deaktivieren" : "aktivieren" }}
                        </v-btn>
                    </div>
                </div>

                <v-select
                    v-model="selectedParent"
                    :items="parents"
                    item-title="account.accountName"
                    placeholder="Andere Eltern zuweisen"
                    no-data-text="Keine Eltern vorhanden"
                    hide-details
                    variant="outlined"
                    density="compact"
                    class="inputField"
                    :class="selectedParent ? '' : 'mb-4'"
                    :menu-props="{ closeOnContentClick: true, offsetY: true }"
                >
                    <template v-slot:prepend-item>
                        <v-list-item @click="selectedParent = ''">
                            <v-list-item-title />
                        </v-list-item>
                    </template>
                </v-select>

                <p
                    v-if="selectedParent"
                    class="text-center mt-1 mb-4"
                >
                    Um einen neuen Eltern-Account zu erstellen wählen Sie die leere
                    Option.
                </p>

                <v-text-field
                    v-if="!selectedParent"
                    v-model="familyName"
                    :disabled="deactivateParent || !createNewParent"
                    variant="outlined"
                    density="compact"
                    hide-details
                    label="Nutzername"
                    placeholder="Nutzername eingeben"
                    class="inputField mb-4"
                    @update:model-value="
                        generateParentLoginId();
                        validateInput();
                    "
                />

                <v-textarea
                    v-if="!selectedParent"
                    v-model="noteParent"
                    :disabled="deactivateParent || !createNewParent"
                    variant="outlined"
                    density="compact"
                    hide-details
                    auto-grow
                    label="Kontaktdaten"
                    placeholder="Kontaktdaten eingeben"
                    class="inputField"
                />

                <div v-if="!selectedParent">
                    <div class="d-flex justify-space-between align-center">
                        <p><b>Login</b></p>
                    </div>

                    <v-text-field
                        v-model="usernameParent"
                        :disabled="deactivateParent || !createNewParent || pupilHasParent"
                        variant="outlined"
                        density="compact"
                        hide-details
                        label="Nutzername"
                        placeholder="Nutzername eingeben"
                        class="inputField mb-2"
                        :class="{ errorInput: fields.parentLoginId }"
                        @update:model-value="
                            () => {
                                this.fields.parentLoginIdGenerated = false;
                                validateInput();
                            }
                        "
                    />
                    <span
                        v-if="fields.parentLoginIdStrangeLetters"
                        style="color: rgb(var(--v-theme-frot))"
                    >
                        Es werden nur die Buchstaben A-Z (ohne Umlaute) und Zahlen
                        unterstützt.
                    </span>
                    <span
                        v-if="fields.parentLoginIdDuplicate"
                        style="color: rgb(var(--v-theme-frot))"
                    >
                        Der Nutzer existiert bereits, passen Sie den Nutzernamen an.
                    </span>
                    <span
                        v-if="fields.parentLoginIdGenerated"
                        style="color: rgb(var(--v-theme-forange))"
                    >
                        Der Nutzer existiert bereits, der Nutzername wurde automatisch
                        angepasst.
                    </span>

                    <v-text-field
                        v-model="passwordParent"
                        :disabled="deactivateParent || !createNewParent"
                        variant="outlined"
                        density="compact"
                        hide-details
                        label="Passwort"
                        placeholder="Passwort eingeben"
                        type="password"
                        class="inputField mb-4"
                        :class="{ errorInput: fields.parentPassword }"
                        @update:model-value="validateInput"
                    />
                    <span
                        v-if="fields.parentPassword"
                        style="color: rgb(var(--v-theme-frot))"
                    >Mind. 8 Buchstaben und 1 Zahl</span>
                </div>
                <v-tooltip
                    location="top"
                >
                    <template v-slot:activator="{ props }">
                        <div
                           
                            style="display: flex; flex-direction: row; justify-content:space-between; width: fit-content mb-1"
                            v-bind="props"
                        >
                            <span>QR-Anmeldung</span>
                            <v-switch
                                v-model="invertedParentQrLoginDeactivated"
                                inset
                                hide-details
                                color="success"
                                class="mt-0"
                            />
                        </div>
                    </template>
                    <span>Aktivieren Sie dieses Feld, um die Qr-Anmeldung für diesen Nutzer zu aktivieren.</span>
                </v-tooltip>
                <div v-if="!parentQrLoginDeactivated">
                    <span
                        style="color: rgb(var(--v-theme-frot)); font-size: 14px;"
                    >
                        Bei der QR-Code Anmeldung ist die Zwei-Faktor-Authentifizierung zu verwenden.
                    </span>
                </div>
                <div class="d-flex justify-space-between align-center mb-1">
                    <div class="d-flex align-center mb-1">
                        <b>Persönliche PIN </b>

                        <v-tooltip location="top">
                            <template v-slot:activator="{ props }">
                                <img
                                    :src="questionMarkIcon"
                                    class="icon20 pl-1"
                                    alt="Erklärung"
                                   
                                    v-bind="props"
                                >
                            </template>
                            <!-- Disable v-html xss checking accessibility.explanation is provided only by developers -->
                            <!-- eslint-disable vue/no-v-html -->
                            <p style="max-width: 250px">
                                4-stellige  Persönliche PIN wird vom Nutzer selbst unter Profileinstellungen vergeben.
                            </p>
                        </v-tooltip>
                    </div>
                </div>
                <div class="d-flex justify-space-between align-center my-1 ml-2">
                    <div class="d-flex align-center mb-1 ">
                        <span>Anmeldung</span>
                        <v-tooltip location="top">
                            <template v-slot:activator="{ props }">
                                <img
                                    :src="questionMarkIcon"
                                    class="icon20 pl-1"
                                    alt="Erklärung"
                                   
                                    v-bind="props"
                                >
                            </template>
                            <!-- Disable v-html xss checking accessibility.explanation is provided only by developers -->
                            <!-- eslint-disable vue/no-v-html -->
                            <p style="max-width: 250px">
                                Aktiviert die Verwendung einer zusätzlichen PIN für die Anmeldung.
                            </p>
                        </v-tooltip>
                    </div>

                    <v-switch
                        v-model="loginPinActivated"
                        inset
                        hide-details
                        color="success"
                        class="mt-0"
                    />
                </div>
                <div class="d-flex justify-space-between align-center my-1 ml-2">
                    <div class="d-flex align-center mb-1 ">
                        <span>Elternbrief</span>
                        <v-tooltip location="top">
                            <template v-slot:activator="{ props }">
                                <img
                                    :src="questionMarkIcon"
                                    class="icon20 pl-1"
                                    alt="Erklärung"
                                   
                                    v-bind="props"
                                >
                            </template>
                            <!-- Disable v-html xss checking accessibility.explanation is provided only by developers -->
                            <!-- eslint-disable vue/no-v-html -->
                            <p style="max-width: 250px">
                                Aktiviert die Verwendung einer zusätzlichen PIN für den Elternbrief.
                            </p>
                        </v-tooltip>
                    </div>

                    <v-switch
                        v-model="parentPinActivated"
                        inset
                        hide-details
                        color="success"
                        class="mt-0"
                    />
                </div>
                <!--#endregion -->
            </div>
        </div>


        <v-dialog
            v-model="saveDialog"
            persistent
            max-width="400"
            style="border-radius: 15px"
        >
            <v-card>
                <v-card-title
                    class="pa-2 px-2 pl-4 d-flex justify-space-between align-center"
                    style="background-color: #467abb;"
                >
                    <div style="color: white">
                        Accounterstellung
                    </div>
                    <img
                        :src="closeIcon"
                        class="icon20 iconToWhite mr-2"
                        style="cursor: pointer"
                        @click="finish()"
                    >
                </v-card-title>
                <v-card-text>
                    <b>WICHTIG:</b> Laden Sie direkt die Zugangsdaten für die Nutzer
                    herunter. Dies ist nachträglich aus Datenschutzgründen nicht mehr
                    möglich. <br>
                    <br>
                    Insofern der Nutzer die Zugangsdaten vergessen oder verloren hat,
                    klicken Sie auf "Zugangsdaten neu generieren", um die neuen Zugangsdaten zu
                    erhalten.

                    <br>
                    <br>
                    <div>
                        <v-row
                            class="mt-5"
                            align="center"
                            justify="center"
                        >
                            <div style="width: 200px">
                                <div
                                    style="width: 200px; gap: 4px"
                                    class="d-flex flex-column align-center ma-0"
                                >
                                    <v-btn
                                        v-if="createNewParent"
                                        class="text-none"
                                        color="gruen"
                                        elevation="0"
                                        theme="dark"
                                        @click="downloadPupilAndParent"
                                    >
                                        Download Zugangsdaten Schüler & Eltern
                                    </v-btn>

                                    <v-btn
                                        v-else
                                        class="text-none"
                                        color="gruen"
                                        elevation="0"
                                        theme="dark"
                                        @click="pdf()"
                                    >
                                        Download Zugangsdaten Schüler
                                    </v-btn>
                                </div>
                            </div>
                        </v-row>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { PDFDocument } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
// import pdfFile from "@/assets/PDF/Schueler-QR-Vorlage.pdf";
import pdfFile from "@/assets/PDF/QR-Vorlage-Schueler-Lehrer.pdf";
// import pdfParentFile from "@/assets/PDF/Eltern-QR-Vorlage.pdf";
import pdfParentFile from "@/assets/PDF/QR-Vorlage-Eltern.pdf";
import roboto from "@/assets/Fonts/Roboto-Regular.ttf";
import robotoBold from "@/assets/Fonts/Roboto-Bold.ttf";
import QrcodeVue from "qrcode.vue";
import { compress } from "shrink-string";
import questionMarkIcon from "@/assets/Icons/redesignV3/questionmark.svg";
import closeIcon from '@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg';
import LoginPDF from '@/util/loginPdf'
import LoginPdf from "@/util/loginPdf";
import defaultProfilePicture from "/src/assets/Icons/Avatar3.svg";
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

export default {
  name: "CreatePupil",
  props: {
    addNewPupil: { type: Function },
    groupName:{type: String, default:''},
  },
  data: () => ({
    defaultProfilePicture,
    closeIcon,
    questionMarkIcon,
    loadingSave: false,
    saveDialog: false,
    savedPupil: null,

    // Pupil data
    firstName: "",
    lastName: "",
    note: "",
    username: "",
    password: "",
    qrPassword: "",
    pupilHasParent: false,
    canChangePassword: false,

    babyView: false,
    translationAllowed: false,
    sehen: false,
    hoeren: false,
    motorik: false,
    screenreader: false,
    // Leave sehen and vorlesen next to each other.
    // Only when sehen is true you can interact with vorlesen/screenreader in UI
    // name is what the value is called in the DB and used for patch request
    accessibilities: [
      { text: "Sehen", name: "sehen", value: false },
      {
        text: "Vorlesen",
        name: "screenreader",
        value: false,
        explanation:
          "Es werden Funktionen deaktiviert, welche für Screenreader-Nutzernutzlos sind (Vorlesefunktion, Stundenplan im Vollbild)",
      },
      { text: "Hören", name: "hoeren", value: false },
      { text: "Motorik", name: "motorik", value: false },
      {
        text: "Übersetzung",
        name: "translationAllowed",
        value: false,
        explanation:
          "Sie entscheiden ob diese/r Schüler/in die Übersetzungsfunktion nutzen kann. Aus datenschutzrechtlichen Gründen muss diese Entscheidung von einem Erwachsenen getroffen werden.",
      },
      {
        text: "Einfach",
        name: "babyView",
        value: true,
        explanation: `<div class="d-flex-column align-center justify-center">
                    <p>Einfache Ansicht:</p>
                    <img style="height: 100px" src="/einfache-Ansicht.svg">
                    <p>Normale Ansicht:</p>
                    <img style="height: 100px" src="/normale-Ansicht.svg">
                    </div>`,
      },
    ],

    // Parent data
    parents: [],
    selectedParent: "",
    deactivateParent: false,
    createNewParent: true,
    familyName: "",
    noteParent: "",
    usernameParent: "",
    passwordParent: "",
    qrPasswordParent: "",

    fields: {
      firstName: false,
      lastName: false,
      username: false,
      usernameStrangeLetters: false,
      usernameDuplicate: false,
      usernameGenerated: false,
      password: false,

      parent: false,
      parentLoginId: false,
      parentLoginIdStrangeLetters: false,
      parentLoginIdDuplicate: false,
      parentLoginIdGenerated: false,
      parentPassword: false,
    },
    valid: false,

    qrLoginDeactivated: false,
    loginPinActivated: false,
    parentQrLoginDeactivated: true,
    parentPinActivated: false,
  }),
  computed: {
        ...mapGetters('parents', {'vuexParents': 'parents'}),
        ...mapGetters('pupils', ['pupilsById']),
        ...mapGetters('accounts', ['accountsById']),
        invertedQrLoginDeactivated: {
            get() {
                return !this.qrLoginDeactivated;
            },
            // The setter updates the actual data model based on the inverse of the input
            set(value) {
                this.qrLoginDeactivated = !value;
            }
        },
        invertedParentQrLoginDeactivated: {
            get() {
                return !this.parentQrLoginDeactivated;
            },
            // The setter updates the actual data model based on the inverse of the input
            set(value) {
                this.parentQrLoginDeactivated = !value;
            }
        },
    },
    watch:{
        invertedParentQrLoginDeactivated(newVal){
            if(newVal === true){
                this.loginPinActivated = true;
            }
        },
    },
  async mounted() {
    this.generatePassword();
    this.generatePassword(true);
    this.parents = await this.getParents();
    this.parents.sort((a, b) =>
      this.alphabetically(a.account.accountName, b.account.accountName)
    );
    this.valid = false;
  },
  methods: {
    ...mapActions("auth", ['isAccountNameAvailable']),
    ...mapActions("parents", ["createParent", "editParent", "getParents"]),
    ...mapActions("pupils", ["createPupil"]),
    ...mapMutations("snackbar", ["showSnackbar"]),

    getParents() {
      return this.vuexParents.map((parent) => ({
        ...parent,
        account:  this.accountsById[parent.account],
        pupils: parent.pupils.map((child) => this.pupilsById[child]),
      }));
    },

    alphabetically(a, b) {
      let x = a.toLowerCase();
      let y = b.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    },

    // a11y is abbreviation for accessibility
    async toggleA11y(a11y) {
      // Deep copy, so that the Einfach toggle doesn't vanish in the UI
      let a11ys = JSON.parse(JSON.stringify(this.accessibilities));

      if (a11y.text === "babyView") {
        let i = a11ys.findIndex((el) => el.text === "babyView");
        let babyView = a11ys.splice(i, 1);

        // babyView could become null sometimes, so we fix it with this
        if (babyView[0].value !== true) {
          babyView[0].value = false;
        }
      } else {
        // Making sure value is either true or false, became null sometimes
        if (a11y.value !== true) {
          a11y.value = false;
        }

        // Toggling off sehen while screen reader is true should turn both to off/false
        if (a11y.name === "sehen" && a11y.value === false) {
          // Toggling off in UI
          let i = this.accessibilities.findIndex(
            (el) => el.name === "screenreader"
          );
          this.accessibilities[i].value = false;
        }
      }
    },

    scrollToParent() {
      const element = document.getElementById("parentContent");
      element.scrollIntoView();
    },

    // result = false indicates a problem, if a field is true it indicates a problem with this specific field
    async validateInput() {
      let result = true;

      this.fields.firstName = false;
      this.fields.lastName = false;
      this.fields.username = false;
      this.fields.usernameStrangeLetters = false;
      this.fields.usernameDuplicate = false;
      this.fields.password = false;

      if (this.firstName.replace(/\s/g, "") === "") {
        this.fields.firstName = true;
        result = false;
      }
      if (this.lastName.replace(/\s/g, "") === "") {
        this.fields.lastName = true;
        result = false;
      }

      // With strange letters we highlight the field and also show a warning about naming convention
      if (
        this.username.replace(/\s/g, "") === "" ||
        this.username.trim().indexOf(" ") > -1 ||
        this.containsUsernameStrangeLetters(this.username)
      ) {
        this.fields.username = true;
        this.fields.usernameStrangeLetters = true;
        result = false;
      }
      let available = await this.checkIdAvailability(this.username);
      if (!available) {
        this.fields.usernameDuplicate = true;
        result = false;
      }

      this.valid = result;
      return result;
    },

    async checkIdAvailability(loginId) {
      return this.isAccountNameAvailable(loginId);
    },

    containsUsernameStrangeLetters(username) {
      // eslint-disable-next-line no-useless-escape
      const format = /[!@#$äüö%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
      return format.test(username);
    },

    overrideFamilyName() {
      this.familyName = this.lastName;
    },

    async generateUsername() {
      this.fields.usernameGenerated = false;
      let id =
        this.firstName.slice(0, 1).toLowerCase() + this.lastName.toLowerCase();

      let available = await this.isAccountNameAvailable(id);

      if (available) {
        this.username = id;
      } else {
        this.fields.usernameGenerated = true;
        let i = 1;
        this.username = id + i;
        for (let i = 1; !available; i++) {
          id =
            this.firstName.slice(0, 1).toLowerCase() +
            this.lastName.toLowerCase() +
            i;


          available = await this.isAccountNameAvailable(id);
          this.username = id;
        }
      }
      this.username = this.username.replaceAll('ü', 'ue');
      this.username = this.username.replaceAll('Ü', 'UE');
      this.username = this.username.replaceAll('ö', 'oe');
      this.username = this.username.replaceAll('Ö', 'OE');
      this.username = this.username.replaceAll('ä', 'ae');
      this.username = this.username.replaceAll('Ä', 'AE');
    },

    async generateParentLoginId() {
      // When editing you can't change loginId
      if (this.pupilHasParent) {
        return;
      }

      this.fields.parentLoginIdGenerated = false;
      let id;
      this.familyName
        ? (id = this.familyName.toLowerCase())
        : (id = this.lastName.toLowerCase());

      let available = await this.isAccountNameAvailable(id);

      if (available) {
        this.usernameParent = id;
      } else {
        this.fields.parentLoginIdGenerated = true;
        let i = 1;
        this.usernameParent = id + i;
        for (let i = 1; !available; i++) {
          this.familyName
            ? (id = this.familyName.toLowerCase() + i)
            : (id = this.lastName.toLowerCase() + i);
          available = await this.isAccountNameAvailable(id);
          this.usernameParent = id;
        }
      }

      if (!this.familyName) {
        this.familyName = this.lastName;
      }

      await this.validateInput();
    },

          generatePassword(parent) {
            let result = "";
            let qrResult = "";
            let allCharacters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789.,?!()€&@";
            let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
            let numbers = "0123456789";
            let additional = ".,?!()€&@";
            result += characters.charAt(Math.floor(Math.random() * characters.length)); // Add an uppercase letter
            result += characters.charAt(Math.floor(Math.random() * characters.length)).toLowerCase(); // Ensure lowercase
            result += numbers.charAt(Math.floor(Math.random() * numbers.length)); // Add a number
            result += additional.charAt(Math.floor(Math.random() * additional.length)); // Add a special character
                

            // Make the rest of the password, up to 10 characters or more if needed
            for (let i =  result.length; i < 10; i++) {
                    result += allCharacters.charAt(Math.floor(Math.random() * allCharacters.length));
            }
            result = this.shuffle(result);
            for (let i = 0; i < 16; i++) {
                qrResult += allCharacters.charAt(
                    Math.floor(Math.random() * allCharacters.length)
                );
            }
            if (parent) {
                this.passwordParent = result;
                this.qrPasswordParent = qrResult;
            } else {
                this.password = result;
                this.qrPassword = qrResult;
            }
        },
       shuffle(string) {
            let array = string.split('');
            for (let i = array.length - 1; i > 0; i--) {
                let j = Math.floor(Math.random() * (i + 1));
                [array[i], array[j]] = [array[j], array[i]]; // Swap elements
            }
            return array.join('');
        },
    async save() {
      let valid = await this.validateInput();
      // If an input is not valid (change only fires when input is no longer focused)
      // so we do this to prevent: changing an input field to an invalid input, then clicking save
      if (!valid) {
        return;
      }
      this.loadingSave = true;

      this.showLoadingOverlay = true;

      let response, data;
      let parentIdPupil;

      // Create parent, create first to save reference to parent in pupil account
      // Check for selectedParent first, as createNewParent isn't false when selecting an existing parent account
      if (this.selectedParent) {
        let parentAcc = this.parents.find(
          (parent) => parent.account.accountName === this.selectedParent
        );
        parentIdPupil = parentAcc._id;
      } else if (this.createNewParent) {

          let givenDefaulPic = defaultProfilePicture;
          try {
              /**  set avatar.svg as profile picture string */
              const split1 = defaultProfilePicture.split('/');
              const split2 = split1[split1.length - 1].split('.')[0];
              givenDefaulPic = {
                  img: `/${split2}.svg`,
              }
          } catch (e) {
              console.warn(e);
          }

        let parentData = {
          gender: "",
          accountName: this.usernameParent.trim(),
          lastName: this.familyName,
          password: this.passwordParent,
          qrPassword: this.qrPasswordParent,
          note: this.noteParent,
          pupils: [],
          profileColor: '#95509C',
          profilePicture: givenDefaulPic,
          qrLoginDeactivated: this.parentQrLoginDeactivated,
          loginPinActivated: this.loginPinActivated,
          messagesPinActivated: this.parentPinActivated,
        };

        let responseParent = await this.createParent(parentData);
        parentIdPupil = responseParent["_id"];

        if (Number.isInteger(responseParent)) {
          this.showSnackbar({
            message:
              "Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!",
            color: "error",
          });
        }
      }

      // Get a11ys from accessibility array
      let index = this.accessibilities.findIndex((el) => el.name === "sehen");
      this.sehen = this.accessibilities[index].value;
      index = this.accessibilities.findIndex(
        (el) => el.name === "screenreader"
      );
      this.screenreader = this.accessibilities[index].value;
      index = this.accessibilities.findIndex((el) => el.name === "hoeren");
      this.hoeren = this.accessibilities[index].value;
      index = this.accessibilities.findIndex((el) => el.name === "motorik");
      this.motorik = this.accessibilities[index].value;
      index = this.accessibilities.findIndex(
        (el) => el.name === "translationAllowed"
      );
      this.translationAllowed = this.accessibilities[index].value;
      index = this.accessibilities.findIndex((el) => el.name === "babyView");
      this.babyView = this.accessibilities[index].value;

      let givenDefaulPic = defaultProfilePicture;
      try {
        /**  set avatar.svg as profile picture string */
        const split1 = defaultProfilePicture.split('/');
        const split2 = split1[split1.length - 1].split('.')[0];
        givenDefaulPic = {
            img: `/${split2}.svg`,
        }
      } catch (e) {
        console.warn(e);
      }

      // Create pupil
      data = {
        name: this.firstName,
        lastName: this.lastName,
        gender: "",
        note: this.note,
        babyView: this.babyView,
        translationAllowed: this.translationAllowed,
        accessibility: {
          sehen: this.sehen,
          screenreader: this.screenreader,
          hoeren: this.hoeren,
          motorik: this.motorik,
        },
        accountName: this.username.trim(),
        password: this.password,
        qrPassword: this.qrPassword,
        parent: parentIdPupil,
        profilePicture: givenDefaulPic,
        canChangePassword: this.canChangePassword,
        qrLoginDeactivated: this.qrLoginDeactivated,
      };

      response = await this.createPupil(data);
      // update pupils
      this.savedPupil = response;

      if (Number.isInteger(response)) {
        this.showSnackbar({
          message:
            "Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!",
          color: "error",
        });
        return;
      }

      // Add children to newly created or existing parent account
      let pupils = [];
      let parentId;

      // Use existing parent account
      if (this.selectedParent) {
        let parent = this.parents.find(
          (parent) => parent.account.accountName === this.selectedParent
        );
        pupils = parent.pupils;
        pupils.push(response["_id"]);
        parentId = parent._id;
      }
      // Use newly created parent account
      else if (this.createNewParent) {
        pupils.push(response["_id"]);
        parentId = parentIdPupil;
      }

      if (this.createNewParent || this.selectedParent) {
        let parentUpdate = {
          _id: parentId,
          pupils: pupils,
        };
        let responseUpdate = await this.editParent(parentUpdate);

        if (Number.isInteger(responseUpdate)) {
          this.showSnackbar({
            message:
              "Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!",
            color: "error",
          });
          return;
        }
      }
      this.addNewPupil(response);
      this.showSnackbar({ message: "Gespeichert!" });
      this.loadingSave = false;
      this.saveDialog = true;

      this.showLoadingOverlay = false;
    },

      async pdf(returnPdfBytes) {
          const pdfBytes = await LoginPdf.getNormalLoginPdf({
              firstName: this.firstName,
              lastName: this.lastName,
              accountName: this.username,
              password: this.password,
              qrPassword: this.qrPassword,
              role: 'pupil'
          });
          if (returnPdfBytes) {
              return pdfBytes;
          } else {
              this.saveByteArray(`${this.groupName}_SuS_${this.username}.pdf`, pdfBytes);
          }
      },

    async parentPdf(returnPdfBytes) {
      const fullChildName = this.firstName + ' ' + this.lastName;
      const pdfBytes = await LoginPdf.getParentLoginPdfv2({
            fullChildName: fullChildName,
            accountName: this.usernameParent,
            password: this.passwordParent,
            qrPassword:  this.qrPasswordParent,
        });
      if (returnPdfBytes) {
          return pdfBytes;
      } else {
          this.saveByteArray(`${this.groupName}_Eltern_${fullChildName}.pdf`, pdfBytes);
      }
    },

    saveByteArray(reportName, byte) {
      let blob = new Blob([byte], { type: "application/pdf" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = reportName;
      link.click();
    },

    finish() {
      this.saveDialog = false;
      this.$emit("stopEvent", this.savedPupil);
      this.clearInputs();
    },
    clearInputs(){
      this.firstName = "";
      this.lastName = "";
      this.note = "";
      this.username = "";
      this.password = "";
      this.qrPassword = "";
      this.pupilHasParent = false;

      this.babyView = false;
      this.translationAllowed = false;
      this.sehen = false;
      this.hoeren = false;
      this.motorik = false;
      this.screenreader = false;
      this.canChangePassword = false;
    },
      async downloadPupilAndParent() {
          const pupilPdfBytes = await this.pdf(true);
          const parentPdfBytes = await this.parentPdf(true);
          const jsZip = new JSZip();
          jsZip.file('SuS' + this.username + '.pdf', pupilPdfBytes);
          const pdfNameParents = 'Eltern_' + this.firstName + ' ' + this.lastName;
          jsZip.file(pdfNameParents + '.pdf', parentPdfBytes);
          const content = await jsZip.generateAsync({ type: 'blob' });
          saveAs(content, 'SuS_und_Eltern_' + this.lastName + '.zip');
      }
  }
};
</script>

<style lang="scss" scoped>
table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}

tr {
  height: 40px;
}

table tr th:first-child,
table tr td:first-child {
  width: 10px !important;
}

table tr th,
table tr td {
  width: 9em;
  border: none;
}
table,
tr,
td {
  border: none;
}

input[type="date"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  min-height: 1.6rem;
}

.input {
  display: block;
  width: 100%;
  max-width: 100%;
  border-radius: 5px;
  border-width: 0;
  color: black;
  padding: 1px 5px 1px 5px;
  border: none !important;
  background-color: rgb(var(--v-theme-hellgrau));
  overflow: hidden;
  appearance: none;
}

.input[disabled] {
  color: grey;
  background-color: #cacaca;
}

.errorInput {
  border: 2px solid red !important;
}

.icon {
  height: 20px;
}

.editContainer {
  border-radius: 0px 0px 15px 15px;
  background-color: #dfdfdf;
  //necessary to set a maximum height to enable scrolling (can also just be height)
	height: auto;
	max-height: calc(80vh - 120px);
  //necessary if we want to disable horizontal scroll
  overflow-x: hidden;
}

.editHeader {
  height: 55px;
  min-height: 55px;
  max-height: 55px;
  background-color: #467abb;
  border-radius: 15px 15px 0 0;
  margin-left: 0;
  margin-right: 0;
}

.innerContainer {
  background-color: #ffffff;
  border-radius: 15px;
}

</style>
