<template>
    <div>
        <!-- v-menu here is only necessary to open maintainer fachinfo in popup -->
        <v-menu
            v-if="appointment"
            v-model="editLessonMenu"
            :disabled="(!currentRoomModel && roomMode) || filterMode"
            :close-on-content-click="false"
            :width="'400px'"
            :max-width="'400px'"
            :location="windowWidth < 600 ? 'top' : 'bottom'"
            :z-index="10"
            :offset="[70, 0]"
        >
            <template v-slot:activator="{ menuProps }">
                <!-- Subject with text and image -->
                <div
                    class="text-white"
                    v-bind="menuProps"
                    :style="accountRole !== 'parent' ? 'cursor: pointer' : ''"
                    style="width: 100%; display: block; padding-left: 0 !important"
                    :class="editMode && copyMode && currentCopiedAppointment && appointment._id === currentCopiedAppointment._id ? getBorderIfCopied() : 'py-2'"
                >
                    <div class="font-weight-medium fachRow shadowFix">
                        <v-badge
                            color="#ff0000"
                            :content="badgeValue"
                            :model-value="(badgeValue || (accountRole === 'maintainer' && !seenByMaintainer)) && !parent"
                            location="left"
                            floating
                            :aria-label="badgeValue + ' Neuigkeiten'"
                            :offset-x="fullscreenWeeklyView ? 16 : badgeValue >= 10 || badgeValue === '+1' ? 27 : 22"
                            style="width: 100%;display: inline-block !important;"
                            :style="isRunning && (accountRole === 'teacher' || accountRole === 'pupil') && !(editMode || sickMode) ? 'width: calc(100% - 64px)' : ''"
                            class="d-flex"
                            :class="showSubstitutionIcon ? 'columnMaxWidth':''"
                        >
                            <v-tooltip
                                :model-value="shortcutTooltips"
                                :disabled="!isRunning || parent"
                                location="bottom"
                            >
                                <template v-slot:activator="{ props }">
                                    <div
                                        class="appointmentItem"
                                        :class="showSubstitutionIcon ? 'max-width: 180px;':''"
                                        aria-haspopup="dialog "
                                        :aria-label="getAccessibilityDescription()"
                                        role="button"
                                        :style="accountRole !== 'parent' ? 'cursor: pointer' : 'cursor: default !important'"
                                        v-bind="attrs"
                                        v-on="on"
                                        @keypress.enter="handleFachClicked"
                                        @keypress.space="handleFachClicked"
                                        @click.prevent="handleFachClicked"
                                    >
                                        <div
                                            :class="currentCopiedAppointment ? 'ml-2':''"
                                            style="height: 56px; width: 56px; position: relative;"
                                            :style="accountRole !== 'parent' ? 'cursor: pointer' : 'cursor: default !important'"
                                            aria-hidden="true"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <div
                                                style="background-color:white;"
                                            >
                                                <div
                                                    id="carouselItem1"
                                                    :class="!deactivatedAnimation ? ' carouselItem fachView' : 'noAnimationItem'"
                                                >
                                                    <img
                                                        v-if="!hasUploadedIcon"
                                                        :src="isRunning ? getSrcWhite() : getSrc()"
                                                        class="pa-2"
                                                        :class="{ inactiveAppointment: !isRunning }"
                                                        :style="isRunning ? getBackgroundColor() : 'background-color: transparent'"
                                                        alt="icon"
                                                    >
                                                    <img
                                                        v-else
                                                        :src="getSrc()"
                                                        class=""
                                                        :class="{ inactiveAppointment: !isRunning }"
                                                        alt="icon"
                                                    >
                                                </div>

                                                <div
                                                    v-if="!deactivatedAnimation"
                                                    id="carouselItem2"
                                                    class="carouselItem fachView"
                                                    style="background-color:white"
                                                >
                                                    <Avatar
                                                        :profile="getResponsibleTeacher().account"
                                                        :size="48"
                                                        :alt="'Profilbild'"
                                                        :is-running="isRunning"
                                                    />
                                                </div>
                                                <!-- disable numbers for now -->
                                                <!-- <div
                                                    v-if="hourNumber"
                                                    id="carouselItem3"
                                                    class="carouselItem"
                                                    :class="hourNumber ? 'planView' : 'fachView'"
                                                    style="background-color:#6995cd"
                                                >
                                                    <div
                                                        class="numberStyle"
                                                    >
                                                        {{ hourNumber }}
                                                    </div>
                                                </div> -->
                                            </div>

                                            <!-- <div
                                                v-else
                                                class="iconSelect"
                                            /> -->
                                        </div>
                                        <div class="parentContainer">
                                            <div
                                                class="fachTextContainer"
                                                aria-hidden="true"
                                                :style="hasAppointmentRooms(appointment) && widgetView !== 'lesson' && widgetView !== 'day' ? 'padding-bottom: 0 !important;' : ''"
                                            >
                                                <div
                                                    v-if="noSubstitutionSelected()"
                                                    class="noSubOverlay"
                                                >
                                                    <svg
                                                        class="diagonal-line"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <line
                                                            x1="0"
                                                            y1="5"
                                                            x2="100%"
                                                            y2="100%"
                                                        />
                                                    </svg>
                                                </div>
                                                <p style="font-size: x-small">
                                                    {{ getTimeslotTimes() }}
                                                </p>
                                                <p style="font-size: large; padding-top: 2px; padding-bottom: 2px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                                                    {{ appointment.schoolSubject.name }}
                                                </p>
                                                <v-tooltip
                                                    location="top"
                                                >
                                                    <template v-slot:activator="{ props }">
                                                        <div
                                                            :class="hasAppointmentRooms(appointment) && widgetView !== 'lesson' && widgetView !== 'day' ? 'selectedRoom' : ''"
                                                            v-bind="props"
                                                        >
                                                            <p
                                                                style="font-size: x-small"
                                                            >
                                                                {{ subjectRooms }}
                                                            </p>
                                                        </div>
                                                    </template>
                                                    <span> {{ subjectRoomsFullNames }} </span>
                                                </v-tooltip>
                                                <div style="position: absolute; top: 5px; right: 10px; display: flex; flex-direction: row">
                                                    <!-- Paperclip -->
                                                    <img
                                                        v-if="appointment.teacherUploads.length > 0"
                                                        alt=""
                                                        :src="paperclipIcon"
                                                        :style="isRunning ? 'right: 30px' : 'right: 27px'"
                                                        style="opacity: 60%; height: 30px; margin-top: -12px;"
                                                    >
                                                    <v-tooltip
                                                        location="top"
                                                    >
                                                        <template v-slot:activator="{ props }">
                                                            <div
                                                                style="display: flex; flex-direction: row; align-items: center"
                                                                v-bind="props"
                                                            >
                                                                <img
                                                                    v-if="getSubstitutionTeacher()"
                                                                    :src="wechselKreisIcon"
                                                                    class="iconToSuccessGreen mr-1"
                                                                    style="height: 12px; cursor: pointer; margin-bottom: 8px"
                                                                    alt="Abwesend melden"
                                                                >
                                                                <img
                                                                    v-else-if="showSickIcon()"
                                                                    :src="abwesendIcon"
                                                                    class="iconToSick mr-1"
                                                                    style="height: 10px; cursor: pointer; margin-bottom: 6px"
                                                                    alt="Abwesend melden"
                                                                >
                                                                <span
                                                                    v-for="teacherInfo in appointment.displayTeacherInfo"
                                                                    :key="teacherInfo.teacher._id"
                                                                    :class="showCameraIcon ? 'responsiveTeacherInfo' : ''"
                                                                    style="font-size: x-small; margin-bottom: 6px"
                                                                    :style="teacherInfo.role === 'substitution' ? 'color: rgb(var(--v-theme-success))' : teacherInfo.role === 'sickTeacher' ? 'color:rgb(var(--v-theme-sickColor)); text-decoration: line-through' : ''"
                                                                >
                                                                    {{
                                                                        getTeachersNames(teacherInfo.teacher, 'short')
                                                                    }}
                                                                </span>
                                                                <img
                                                                    v-if="windowWidth > 900 && windowWidth < 1101 && showCameraIcon"
                                                                    :src="infoIcon"
                                                                    style="height: 10px; max-height:10px; margin-bottom: 6px;"
                                                                    alt="LehrerInfo"
                                                                >
                                                            </div>
                                                        </template>
                                                        <span
                                                            v-for="teacherInfo in appointment.displayTeacherInfo"
                                                            :key="teacherInfo.teacher._id"
                                                            :style="teacherInfo.role === 'substitution' ? 'color: rgb(var(--v-theme-success))' : teacherInfo.role === 'sickTeacher' ? 'color:rgb(var(--v-theme-sickColor)); text-decoration: line-through' : ''"
                                                        >
                                                            {{ getTeachersNames(teacherInfo.teacher, 'long') }}
                                                        </span>
                                                        <!-- <span
                                                        v-else-if="getSubstitutionTeacher()"
                                                        style="color: #31e825"
                                                    >
                                                        {{ getTeachersNames(getSubstitutionTeacher(), 'long') }}
                                                    </span>
                                                    <span
                                                        v-else
                                                    >
                                                        {{ getTeachersNames(appointment.teachers, 'long') }}
                                                    </span> -->
                                                    </v-tooltip>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- when there are multiple teachers involved we need to open extra edit menu -->
                                        <v-menu
                                            v-if="sickMode && accountRole === 'maintainer' && appointment.teachers.length > 1"
                                            location="top"
                                            :persistent="!(true)"
                                        >
                                            <template v-slot:activator="{ props }">
                                                <div
                                                    class="pa-0 mr-0 pt-1"
                                                    style="position: absolute; left: 50%;"
                                                   
                                                    v-bind="props"
                                                >
                                                    <v-tooltip location="bottom">
                                                        <template v-slot:activator="{ props }">
                                                            <v-fab
                                                                class="ml-2 pa-0"
                                                                size="small"
                                                                elevation="5"
                                                                absolute
                                                                style="width: 36px; height: 36px; border-radius: 12px"
                                                                :style="`background-color: ${sickAppointments.some(el => el === appointment._id) ? 'rgb(var(--v-theme-error))' : 'white'}`"
                                                                v-bind="props"
                                                            >
                                                                <img
                                                                    v-if="!sickAppointments.some(el => el === appointment._id)"
                                                                    :src="anwesendIcon"
                                                                    alt="Für diese Stunde Abwesend melden"
                                                                    style="height: 18px"
                                                                >
                                                                <img
                                                                    v-else
                                                                    :src="abwesendIcon"
                                                                    alt="Abwesenheit für diese Stunde bearbeiten"
                                                                    class="iconToWhite"
                                                                    style="height: 18px"
                                                                >
                                                            </v-fab>
                                                        </template>
                                                        <span>
                                                            {{
                                                                sickAppointments.some(el => el === appointment._id) ?
                                                                    'Abwesenheit für diese Stunde bearbeiten' : 'Für diese Stunde Abwesend melden'
                                                            }}
                                                        </span>
                                                    </v-tooltip>
                                                </div>
                                            </template>
                                            <v-list>
                                                <v-list-item
                                                    v-for="(teacher, index) in appointment.teachers"
                                                    :key="index"
                                                    class="d-flex justify-space-between"
                                                    @click="()=>{handleMultipleTeacherAppointment(appointment,teacher);}"
                                                >
                                                    <v-btn
                                                        class=" pa-0 mr-4"
                                                        size="small"
                                                        :style="`background-color: ${isTeacherSick(appointment,teacher) ? 'rgb(var(--v-theme-error))' : 'white'}`"
                                                        v-bind="props"
                                                    >
                                                        <img
                                                            v-if="!isTeacherSick(appointment,teacher)"
                                                            :src="anwesendIcon"
                                                            alt="Für diese Stunde Abwesend melden"
                                                            style="height: 18px"
                                                        >
                                                        <img
                                                            v-else
                                                            :src="abwesendIcon"
                                                            alt="Abwesenheit für diese Stunde bearbeiten"
                                                            class="iconToWhite"
                                                            style="height: 18px"
                                                        >
                                                    </v-btn>
                                                    <v-list-item-title>
                                                        {{ getTeachersNames(teacher, 'long') }}
                                                    </v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                        <div
                                            v-else-if="sickMode && fullscreenWeeklyView && !editMode && meNotSickAtThisAppointment
                                                || sickMode && accountRole === 'maintainer' && !appointmentHasSickTeacher"
                                            class="pa-0 mr-0 pt-1"
                                            style="position: absolute; left: 50%;"
                                            @click="$emit('sickClick', appointment)"
                                        >
                                            <v-tooltip location="bottom">
                                                <template v-slot:activator="{ props }">
                                                    <v-fab
                                                        class="ml-2 pa-0"
                                                        size="small"
                                                        elevation="5"
                                                        
                                                        absolute
                                                        style="width: 36px; height: 36px; border-radius: 12px"
                                                        :style="`background-color: ${sickAppointments.some(el => el === appointment._id) ? 'rgb(var(--v-theme-error))' : 'white'}`"
                                                        v-bind="props"
                                                    >
                                                        <img
                                                            v-if="!sickAppointments.some(el => el === appointment._id)"
                                                            :src="anwesendIcon"
                                                            alt="Für diese Stunde Abwesend melden"
                                                            style="height: 18px"
                                                        >
                                                        <img
                                                            v-else
                                                            :src="abwesendIcon"
                                                            alt="Abwesenheit für diese Stunde zurücknehmen"
                                                            class="iconToWhite"
                                                            style="height: 18px"
                                                        >
                                                    </v-fab>
                                                </template>
                                                <span>
                                                    {{
                                                        sickAppointments.some(el => el === appointment._id) ?
                                                            'Abwesenheit für diese Stunde zurücknehmen' : 'Für diese Stunde Abwesend melden'
                                                    }}
                                                </span>
                                            </v-tooltip>
                                        </div>
                                        <div
                                            v-else-if="sickMode && fullscreenWeeklyView && !editMode
                                                || sickMode && accountRole === 'maintainer' && appointmentHasSickTeacher"
                                            class="pa-0 mr-0 pt-1"
                                            style="position: absolute; left: 50%;"
                                            @click="$emit('undoSick', appointment)"
                                        >
                                            <v-tooltip location="bottom">
                                                <template v-slot:activator="{ props }">
                                                    <v-fab
                                                        class="ml-2 pa-0"
                                                        size="small"
                                                        elevation="5"
                                                        absolute
                                                        style="width: 36px; height: 36px; border-radius: 12px; "
                                                        :style="removeSickAppointments.some(el => el === appointment._id) ? '' : 'background-color: rgb(var(--v-theme-error))'"
                                                        v-bind="props"
                                                    >
                                                        <img
                                                            v-if="removeSickAppointments.some(el => el === appointment._id)"
                                                            :src="anwesendIcon"
                                                            alt="Für diese Stunde Abwesend melden"
                                                            style="height: 18px"
                                                        >
                                                        <img
                                                            v-else
                                                            :src="abwesendIcon"
                                                            alt="Abwesenheit für diese Stunde zurücknehmen"
                                                            class="iconToWhite"
                                                            style="height: 18px"
                                                        >
                                                    </v-fab>
                                                </template>
                                                <span>
                                                    {{
                                                        removeSickAppointments.some(el => el === appointment._id) ?
                                                            'Abwesenheit beibehalten' : 'Abwesenheit für diese Stunde zurücknehmen'
                                                    }}
                                                </span>
                                            </v-tooltip>
                                        </div>
                                        <div
                                            v-else-if="editMode && fullscreenWeeklyView && !substitutePlan && (!copyMode || copyMode && currentCopiedAppointment && currentCopiedAppointment._id === appointment._id) && !filterMode"
                                            class="pa-0 mr-0 pt-1 copyButton"
                                            style="position: absolute; left: 50%;"
                                            @click="(event) => { copyMode ? $emit('stopCopyMode') : $emit('setCopyLesson', appointment); event.stopPropagation() }"
                                        >
                                            <v-tooltip
                                                :model-value="shortcutTooltips"
                                                location="bottom"
                                            >
                                                <template v-slot:activator="{ props }">
                                                    <v-fab
                                                        class="ml-2 pa-0"
                                                        size="small"
                                                        elevation="5"
                                                        
                                                        absolute
                                                        style="width: 36px; height: 36px; border-radius: 12px"
                                                        :style="currentCopiedAppointment && appointment._id === currentCopiedAppointment._id ? 'background-color: red' : 'background-color: white'"
                                                        v-bind="props"
                                                    >
                                                        <img
                                                            v-if="currentCopiedAppointment && appointment._id === currentCopiedAppointment._id"
                                                            :src="cancelIcon"
                                                            class="iconToWhite"
                                                            style="height: 25px; width: 25px"
                                                            alt="Stunde kopieren"
                                                        >

                                                        <img
                                                            v-else
                                                            :src="copyIcon"
                                                            class="pa-0"
                                                            style="height: 25px; width: 25px"
                                                            alt="Kopieren beenden"
                                                        >
                                                    </v-fab>
                                                </template>
                                                <span>
                                                    {{
                                                        currentCopiedAppointment && appointment._id === currentCopiedAppointment._id ?
                                                            'Kopieren beenden' : 'Stunde kopieren'
                                                    }}
                                                </span>
                                            </v-tooltip>
                                        </div>
                                        <!--bookmark-->
                                        <img
                                            :src="getColorIcon()"
                                            alt=""
                                            style="position: absolute; height: 25px; top: 0; right: 10px;"
                                        >
                                    </div>
                                    <!-- Class name -->
                                    <v-menu
                                        location="top left"
                                        
                                        style="z-index: 99"
                                    >
                                        <template v-slot:activator="{ props }">
                                            <span
                                                class="className"
                                                :class="showCameraIcon ? 'responsiveSubjectInfo' : ''"
                                                style="font-size: x-small; margin-bottom: 6px"
                                                :style="isRunning ? '' : 'opacity: 0.4'"
                                                aria-hidden="true"
                                                v-bind="props"
                                            >
                                                {{ getGroupName(appointment.group) }}
                                            </span>
                                        </template>
                                        <div>
                                            <v-list
                                                density="compact"
                                                color="rgba(97, 97, 97, 0.9)"
                                                style="min-width: 150px !important;"
                                            >
                                                <p
                                                    class="mx-2"
                                                    style="font-weight: bold; color: white"
                                                >
                                                    Klassenlehrer:
                                                </p>
                                              
                                                <template
                                                    v-for="element in appointment.group.leaders"
                                                    :key="element._id"
                                                >
                                                    <div
                                                        style="background-color: transparent; min-width: 150px !important;"
                                                    >
                                                        <div
                                                            class="d-flex flex-row justify-space-between px-2"
                                                            style="max-width: 28vw;"
                                                        >
                                                            <div
                                                                class="justify-flex-start"
                                                                style="font-size: 14px; color: white"
                                                            >
                                                                {{ `${teachersById[element].name} 
                                                                    ${teachersById[element].lastName}` }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>
                                                <p 
                                                    class="mx-2"
                                                    style="font-weight: bold; color: white"
                                                >
                                                    Schüler:
                                                </p>
                                                <template
                                                    v-for="element in appointment.group.participants"
                                                    :key="element._id"
                                                >
                                                    <div
                                                        style="background-color: transparent; min-width: 150px !important;"
                                                    >
                                                        <div
                                                            class="d-flex flex-row justify-space-between px-2"
                                                            style="max-width: 28vw;"
                                                        >
                                                            <div
                                                                class="justify-flex-start"
                                                                style="font-size: 14px; color: white"
                                                            >
                                                                {{ element.name + ' ' + element.lastName }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>
                                            </v-list>
                                        </div>
                                    </v-menu>
                                </template>
                                <span>Aktuelle <span
                                    style="text-decoration: underline white"
                                >S</span>tunde (Alt + S)</span>
                            </v-tooltip>
                        </v-badge>

                        <!-- Camera Icon next to Fach -->
                        <div
                            v-if="showCameraIcon"
                            class="pa-0 mr-0"
                            style="min-height: 100%"
                            @click="clickBeitreten"
                        >
                            <v-tooltip
                                :model-value="shortcutTooltips"
                                location="bottom"
                                style="z-index: 7"
                            >
                                <template v-slot:activator="{ props }">
                                    <v-btn
                                        id="cameraBtn"
                                        class="pa-0 ml-2"
                                        size="small"
                                        :class="{ isFachRunning: isRunning && !editMode && !sickMode && !substitutePlan }"
                                        v-bind="props"
                                    >
                                        <img
                                            :src="videoIcon"
                                            class="pa-0"
                                            :class="{ isFachRunningIcon: isRunning }"
                                            style="height: 40px !important; width: 40px !important;"
                                            alt="Stunde beitreten"
                                        >
                                    </v-btn>
                                </template>
                                <div style="display: flex; flex-direction: column; text-align: center">
                                    <span>Stunde <span style="text-decoration: underline white">b</span>eitreten (Alt + B)</span>
                                    <img
                                        v-if="hoeren"
                                        :src="beitretenMetacom"
                                        width="100"
                                        style="margin: auto"
                                        alt=""
                                    >
                                </div>
                            </v-tooltip>
                        </div>
                        <!-- Copy Icon next to Fach -->

                        <!-- Sick Icon next to Fach -->

                        <!-- Vertretungs Icon next to Fach -->
                        <div
                            v-else-if="showSubstitutionIcon"
                            class="pa-0 mr-0"
                            style="min-height: 100%"
                        >
                            <v-dialog
                                width="400"
                                style="border-radius: 16px !important"
                                :model-value="substitutionDialog"
                                :disabled="accountRole === 'teacher'"
                                :close-on-content-click="false"
                            >
                                <template v-slot:activator="{ props:dialog }">
                                    <v-tooltip location="bottom">
                                        <template v-slot:activator="{ props:tooltip }">
                                            <v-btn
                                                class="ml-2 pa-0"
                                                size="small"
                                                style="height: 55px; width: 55px; border-radius: 12px"
                                                :style="`background-color: ${sickAtThisAppointment ? 'rgb(var(--v-theme-success))' : 'rgb(var(--v-theme-sickColor))'}`"
                                                v-bind="$mergeProps(dialog, tooltip)"
                                                @click="handleSubstituteClick()"
                                            >
                                                <v-icon
                                                    class="pa-0"
                                                    size="35px"
                                                    color="white"
                                                    :alt="accountRole === 'maintainer' ? 'Vertretung bearbeiten' : 'Stunde übernehmen'"
                                                >
                                                    {{ accountRole === 'maintainer' ? 'mdi-swap-horizontal' : 'mdi-account-check-outline' }}
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>
                                            {{ accountRole === 'maintainer' ? 'Vertretung bearbeiten' : 'Stunde übernehmen' }}
                                        </span>
                                    </v-tooltip>
                                </template>
                                <div style="background-color: white; height: 80vh; overflow: hidden; border-radius: 16px">
                                    <VertretungsplanTeacherList
                                        class="pb-4"
                                        style="background-color: white"
                                        :teachers="teachers"
                                        :appointment="appointment"
                                        :appointments="allAppointments"
                                        :subjects="subjects"
                                        :date="date"
                                        :icons="icons"
                                        mode="list"
                                        @no-substitution="(notify)=>{noSubstitutionClick(notify)}"
                                        @handle-substitute-click="handleSubstituteClick"
                                        @close="substitutionDialog = false"
                                    />
                                </div>
                            </v-dialog>
                        </div>
                    </div>
                </div>
            </template>

            <FachInfoMaintainer
                v-if="(editMode || roomMode) && fullscreenWeeklyView"
                :appointment="appointment"
                :hide-add-school-subject-option-in-selector="accountRole !== 'maintainer'"
                :subjects="subjects"
                :teachers="teachers"
                :current-group="currentGroup"
                :timeslot="timeslot"
                :day="day"
                :add-new-teacher="addNewTeacher"
                :request-appointments="requestAppointments"
                :selected-room="currentRoomModel"
                @create-teacher="$emit('createTeacher')"
                @close="editLessonMenu = false"
            />
        </v-menu>
        <div
            v-else-if="timeslot.category === 'break'"
            class="py-2 text-white"
            style="width: 100%; display: block; padding-left: 0 !important; "
            :style="!fullscreenWeeklyView ? 'padding-right: 12px;' : ''"
        >
            <div
                class="font-weight-medium shadowFix "
                :style="editMode ? 'height:56px; display: flex; flex-flow: wrap; align-content: center; width: 100% !important;' : 'height: 28px;' "
            >
                <div
                    class="pa-0 ml-0"
                    style="width: 100%"
                >
                    <div
                        class="ma-0 appointmentItem "
                        style="width: 100% !important; background-color: rgb(var(--v-theme-timetableColor))"
                    >
                        <div
                            class="pauseContainer"
                            style="margin-left: 64px; height: 28px;"
                        >
                            <p
                                class="mr-2"
                                style="font-size: small; color: white"
                            >
                                {{ getTimeslotTimes(true) }}
                            </p>
                            <p style="font-size: small; color: white">
                                Pause
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Hohlstunde or Hinzufügen or Verfügbar-->
        <v-menu
            v-else
            v-model="editLessonMenu"
            :close-on-content-click="false"
            :width="400"
            :max-width="400"
            :z-index="10"
            :disabled="copyMode || substitutePlan || (!currentRoomModel && roomMode) || filterMode"
            :location="windowWidth < 600 ? true : false ? 'top' : undefined"
            :offset="[70, 0]"
        >
            <template v-slot:activator="{ props }">
                <div
                    class="py-2 text-white"
                    style="width: 100%; display: block; padding-left: 0 !important; "
                    :style="!fullscreenWeeklyView ? 'padding-right: 12px;' : ''"
                >
                    <div class="font-weight-medium shadowFix">
                        <div class="pa-0 ml-0">
                            <div
                                class="ma-0 appointmentItem inactiveAppointment"
                                style="width: 100% !important"
                               
                                v-bind="props"
                                @click="fachClicked"
                            >
                                <img
                                    :src="hohlstundenIcon"
                                    class="iconToLightGray icon30 mt-3 mx-3"
                                    style="opacity: 0.6"
                                >
                                <div
                                    class="fachTextContainer"
                                    style="margin-left: 8px; height: 56px; line-height: 1;"
                                    :style="(editMode && !copyMode) || (accountRole === 'maintainer' && !substitutePlan) ? '' : (copyMode ? '' : 'opacity: 0.6')"
                                >
                                    <p style="font-size: small; color: lightgrey">
                                        {{ getTimeslotTimes(true) }}
                                    </p>
                                    <p style="font-size: large; color: lightgrey">
                                        {{ roomMode ? 'Verfügbar' : (editMode && !copyMode) || (accountRole === 'maintainer' && !substitutePlan) ? 'Hinzufügen' : copyMode ? 'Einfügen' : 'Hohlstunde' }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <FachInfoMaintainer
                v-if="(editMode || roomMode) && fullscreenWeeklyView"
                :hide-add-school-subject-option-in-selector="accountRole !== 'maintainer'"
                :subjects="subjects"
                :teachers="teachers"
                :current-group="currentGroup"
                :timeslot="timeslot"
                :day="day"
                :request-appointments="requestAppointments"
                :selected-room="currentRoomModel"
                @create-teacher="$emit('createTeacher')"
                @close="editLessonMenu = false"
            />
        </v-menu>

        <!-- if only one fach is displayed show info -->
        <div
            v-if="widgetView && widgetView === 'lesson'"
            class="lessonViewContainer"
        >
            <FachInfoSchueler
                v-if="accountRole === 'pupil'"
                :is-popup="false"
                :appointment="appointment"
                :person="person"
                :urls="urls"
                :is-joinable="isJoinable"
                :hoeren="hoeren"
                :sehen="sehen"
                :motorik="motorik"
                :plan-vollbild="fullscreenWeeklyView"
                :get-src="getSrc"
                :get-background-color="getBackgroundColor"
                :get-color-icon="getColorIcon"
                :get-src-white="getSrcWhite"
                :request-appointments="requestAppointments"
                :upload-file-callback="callFileUpload"
                :upload-file-object="fileToUpload"
                :has-uploaded-icon="hasUploadedIcon"
                @click-beitreten="clickBeitreten"
                @file-uploaded="notifyParent"
                @audio-overlay="handleAudioUrl"
                @load-image-previews="loadImagePreviews"
            />
            <FachInfoLehrer
                v-else-if="accountRole === 'teacher' && appointment"
                :is-popup="false"
                :appointment="appointment"
                :person="person"
                :urls="urls"
                :is-joinable="isJoinable"
                :hoeren="hoeren"
                :sehen="sehen"
                :motorik="motorik"
                :plan-vollbild="fullscreenWeeklyView"
                :groups="groups"
                :get-src="getSrc"
                :get-background-color="getBackgroundColor"
                :get-color-icon="getColorIcon"
                :get-src-white="getSrcWhite"
                :request-appointments="requestAppointments"
                :upload-file-callback="callFileUpload"
                :upload-file-object="fileToUpload"
                :has-uploaded-icon="hasUploadedIcon"
                @click-beitreten="clickBeitreten"
                @file-uploaded="notifyParent"
                @audio-overlay="handleAudioUrl"
                @load-image-previews="loadImagePreviews"
            />
            <FachInfoMaintainer
                v-else-if="accountRole === 'maintainer'"
                :embedded-style="true"
                :appointment="appointment"
                :subjects="subjects"
                :teachers="teachers"
                :current-group="currentGroup"
                :timeslot="timeslot"
                :day="day"
                :request-appointments="requestAppointments"
                @close="clickedCancelInCreateLesson()"
            />
            <FachInfoEltern
                v-else-if="accountRole === 'parent'"
                :is-popup="false"
                :appointment="appointment"
                :person="person"
                :is-joinable="isJoinable"
                :plan-vollbild="fullscreenWeeklyView"
                :get-src="getSrc"
                :get-background-color="getBackgroundColor"
                :get-color-icon="getColorIcon"
                :get-src-white="getSrcWhite"
                :current-child="currentChild"
                :request-appointments="requestAppointments"
                :upload-file-callback="callFileUpload"
                :upload-file-object="fileToUpload"
                :has-uploaded-icon="hasUploadedIcon"
                @click-beitreten="clickBeitreten"
            />
        </div>

        <!--#region dialogs and overlay -->
        <v-dialog
            v-if="weeklyCopyDialog"
            v-model="weeklyCopyDialog"
            persistent
            content-class="rounded-4"
            max-width="400"
        >
            <v-card
                class="cardStyle"
                style=" border-radius: 16px"
            >
                <div
                    class="text-h5 d-flex flex row align-center justify-space-between pa-2 ma-0"
                    style="background-color: rgb(var(--v-theme-hellblau))"
                >
                    <div
                        style="color: white;"
                    >
                        Termin wöchentlich eintragen?
                    </div>
                    <v-btn
                        min-width="36px"
                        width="36px"
                        icon="$vuetify"
                        variant="plain"
                        color="transparent"
                        elevation="0"
                        @click="weeklyCopyDialog = false"
                    >
                        <img
                            :src="cancelIcon"
                            class="icon20 iconToWhite"
                            alt="schließen"
                        >
                    </v-btn>
                </div>
                <v-card-text>
                    Sind Sie sicher, dass Sie diesen Termin bis
                    {{
                        currentCopiedAppointment.rule ? new Date(currentCopiedAppointment.rule.endDate).getDate() + "." +
                            (new Date(currentCopiedAppointment.rule.endDate).getMonth() + 1) + "." +
                            new Date(currentCopiedAppointment.rule.endDate).getFullYear() : ''
                    }}
                    einfügen möchten? <br>
                    <v-checkbox
                        v-model="confirmWeeklyCopy"
                        label="Ja, ich möchte diesen Termin wöchentlich einfügen!"
                    />
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        color="grey"
                        @click="weeklyCopyDialog = false"
                    >
                        <div style="color: white">
                            Abbrechen
                        </div>
                    </v-btn>
                    <v-btn
                        color="success"
                        @click="pasteAppointmentWeekly"
                    >
                        Akzeptieren
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <AreYouSurePopup
            v-model:show-dialog="areYouSureCopyDialog"
            :content-text-prop="'Sind sie sicher, dass sie die Stunde hierher kopieren wollen?'"
            :header-color="'rgb(var(--v-theme-hellblau))'"
            :ok-button-text="'Kopieren'"
            :ok-button-icon="bestaetigenIcon"
            @ok="pasteAppointmentSingle"
            @close="areYouSureCopyDialog = false"
        />


        <VideoTutorial
            v-if="videoTutorial && showVideoTutorial"
            :video-tutorial="videoTutorial"
            @close="onVideoTutorialClose"
        />
        <!--#endregion -->
    </div>
</template>

<script>
import * as backend from "@/api/backend";
import setGlobalButtonContrast from "@/util/accessibilityButtonContrast";
import videoIcon from '@/assets/Icons/video.svg'
import paperclipIcon from "@/assets/Icons/FaceliftIcons/anhang_bueroklammer.svg";
import kameraIcon from '@/assets/Icons/kamera-21.svg';
import cancelIcon from '@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg';
import copyIcon from '@/assets/Icons/FaceliftIcons/kopieren_datei_.svg';
import abwesendIcon from "@/assets/Icons/FaceliftIcons/lehrer_abwesend.svg";
import anwesendIcon from "@/assets/Icons/FaceliftIcons/lehrer.svg";
import wechselKreisIcon from '@/assets/Icons/FaceliftIcons/wechsel_kreis.svg';
import hohlstundenIcon from '@/assets/Icons/FaceliftIcons/fach_kontur.svg';

import bioIcon from '@/assets/Icons/FaceliftIcons/f-biologie.svg'
import chemieIcon from '@/assets/Icons/FaceliftIcons/f-chemie.svg'
import deutschIcon from '@/assets/Icons/FaceliftIcons/f-deutsch.svg'
import englischIcon from '@/assets/Icons/FaceliftIcons/f-englisch.svg'
import erdkundeIcon from '@/assets/Icons/FaceliftIcons/f-erdkunde.svg'
import geschichteIcon from '@/assets/Icons/FaceliftIcons/f-geschichte.svg'
import matheIcon from '@/assets/Icons/FaceliftIcons/f-mathe.svg'
import musikIcon from '@/assets/Icons/FaceliftIcons/f-musik.svg'
import kunstIcon from '@/assets/Icons/FaceliftIcons/f-kunst.svg';
import physikIcon from '@/assets/Icons/FaceliftIcons/f-physik.svg'
import politikIcon from '@/assets/Icons/FaceliftIcons/f-politik.svg'
import religionIcon from '@/assets/Icons/FaceliftIcons/f-religion.svg'
import sportIcon from '@/assets/Icons/FaceliftIcons/f-sport.svg'
import wirtschaftIcon from '@/assets/Icons/FaceliftIcons/f-wirtschaft.svg'
import loadingIcon from '@/assets/Icons/FaceliftIcons/aktualisieren.svg'
import infoIcon from '@/assets/Icons/info-2-121.svg'

import bioIconWeiss from "@/assets/Icons/FaceliftIcons/f-biologie-weiss.svg";
import chemieIconWeiss from "@/assets/Icons/FaceliftIcons/f-chemie-weiss.svg";
import deutschIconWeiss from "@/assets/Icons/FaceliftIcons/f-deutsch-weiss.svg";
import englischIconWeiss from "@/assets/Icons/FaceliftIcons/f-englisch-weiss.svg";
import erdkundeIconWeiss from "@/assets/Icons/FaceliftIcons/f-erdkunde-weiss.svg";
import geschichteIconWeiss from "@/assets/Icons/FaceliftIcons/f-geschichte-weiss.svg";
import matheIconWeiss from "@/assets/Icons/FaceliftIcons/f-mathe-weiss.svg";
import musikIconWeiss from "@/assets/Icons/FaceliftIcons/f-musik-weiss.svg";
import kunstIconWeiss from '@/assets/Icons/FaceliftIcons/f-kunst-weiss.svg';
import physikIconWeiss from "@/assets/Icons/FaceliftIcons/f-physik-weiss.svg";
import politikIconWeiss from "@/assets/Icons/FaceliftIcons/f-politik-weiss.svg";
import religionIconWeiss from "@/assets/Icons/FaceliftIcons/f-religion-weiss.svg";
import sportIconWeiss from "@/assets/Icons/FaceliftIcons/f-sport-weiss.svg";
import wirtschaftIconWeiss from "@/assets/Icons/FaceliftIcons/f-wirtschaft-weiss.svg";
import infoIconWeiss from '@/assets/Icons/info-2-weiß-89.svg'

import beitretenMetacom from '../../../../assets/METACOM/Stundenplan/unterricht.png';
import bestaetigenIcon from '@/assets/Icons/FaceliftIcons/speichern_bestaetigen.svg';

import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import VideoTutorial from "../../../Tutorial/VideoTutorial.vue";
import FachInfoSchueler from "@/components/Schueler/FachInfoSchueler";
import FachInfoLehrer from "@/components/Lehrer/FachInfoLehrer";
import FachInfoMaintainer from "@/components/Verwaltung/FachInfoMaintainer";
import FachInfoEltern from "@/components/FachInfoEltern";
import VertretungsplanTeacherList from "@/components/SharedViews/Vertretungsplan/VertretungsplanTeacherList";
import { allowedExtensionsForAudioPlayer } from "../../../../constants/allowedMimeTypes";
import EventBus from "../../../../util/eventBus";

import Avatar from "@/components/SharedViews/Profile/Avatar";
import AreYouSurePopup from "@/components/Utils/AreYouSurePopup";

export default {
    name: "FachComponent",
    components: {
        AreYouSurePopup,
        VertretungsplanTeacherList,
        FachInfoMaintainer,
        FachInfoLehrer,
        FachInfoSchueler,
        FachInfoEltern,
        VideoTutorial,
        Avatar,
    },
    props: {
        // Info about the current Fach
        appointment: {required: false, default: null},
        timeslot: {type: Object, required: false},
        day: {type: Date, required: false},
        isRunning: {type: Boolean, required: false, default: false},
        isOpen: {type: Boolean, required: false, default: false},
        isJoinable: {type: Boolean, required: false, default: false},
        icons: {type: Array, required: false},

        // General Info
        slots: {required: false},
        subjects: {required: true},
        teachers: {required: true},
        hourNumber: {required: false},
        groups: {required: false},
        // Widget Info
        widgetView: {default: false},
        currentGroup: {type: Object, required: false},
        currentChild: { required: false},
        currentRoomModel: {type: Object, required: false, default: () => {}},
        fullscreenWeeklyView: {required: false, default: false},
        sickMode: {default: false, type: Boolean, required: false},
        editMode: {default: false, type: Boolean, required: false},
        roomMode: {default: false, type: Boolean, required: false},
        filterMode: {default: false, type: Boolean, required: false},
        substitutePlan: {default: false, type: Boolean, required: false},

        // Info about the user
        person: { default: null },
        me: {default: null},
        parent: {default: false, type: Boolean},

        // Info about copy feature
        currentCopiedAppointment: {type: Object, required: false},
        copyMode: {type: Boolean, required: false},

        // Info about sickmode
        sickAppointments: { type: Array, required: false},
        removeSickAppointments: { type: Array, required: false},

        // Vertretungsplan feature
        allAppointments: {type: Array},
        date: {},

        // Meta functions
        requestAppointments: {type: Function, required: true},
        addNewTeacher: {type: Function},

        //dragAndDropFunction
        uploadFile: {default: null, required: false},

        uploadFileCallback: {default: false, type: Boolean, required: false},

        currentFilteredTeacher: { default: null },


    },
    data: () => ({
        // menu bool for v-menu popup in editMode (maintainer and teacher)
        editLessonMenu: false,
        fileToUpload:'',
        hasUploadedIcon: false,

        callFileUpload:false,

        uploadLoadings: [],
        uploadLoadingsPupil: [],
        urls:[],

        // BBB Videotutorial
        bbbTutState: null,
        bbbTut: false,
        // Show video Tutorial is set to true when button is clicked
        videoTutorial: false,
        showVideoTutorial: false,

        // Accessibility
        sehen: false,
        hoeren: false,
        motorik: false,
        setGlobalButtonContrast,

        // Copy mode
        weeklyCopyDialog: false,
        areYouSureCopyDialog: false,
        confirmWeeklyCopy: true,
        isSafari: false,

        // Substitution
        substitutionDialog: false,
        newSickTeacher: null,

        // Icons
        videoIcon,
        paperclipIcon,
        kameraIcon,
        cancelIcon,
        copyIcon,
        abwesendIcon,
        anwesendIcon,
        wechselKreisIcon,
        hohlstundenIcon,
        bestaetigenIcon,

        bioIcon,
        chemieIcon,
        deutschIcon,
        englischIcon,
        erdkundeIcon,
        geschichteIcon,
        matheIcon,
        musikIcon,
        kunstIcon,
        physikIcon,
        politikIcon,
        religionIcon,
        sportIcon,
        wirtschaftIcon,
        loadingIcon,
        infoIcon,
        infoIconWeiss,

        // These icons are actually used by getWhiteIconSrc
        // eslint-disable-next-line vue/no-unused-properties
        bioIconWeiss, chemieIconWeiss, deutschIconWeiss, englischIconWeiss, erdkundeIconWeiss, geschichteIconWeiss, matheIconWeiss, musikIconWeiss, kunstIconWeiss, physikIconWeiss, politikIconWeiss, religionIconWeiss, sportIconWeiss, wirtschaftIconWeiss,

        // Metacom
        beitretenMetacom,
    }),
    computed: {
        ...mapState('util', ['keyboard', 'bonusFunctions', 'browserSupported', 'windowWidth']),
        ...mapState("speechControl", ["word"]),
        ...mapState("tooltips", ["shortcutTooltips"]),
        ...mapGetters("auth",["accountRole", 'accountId', 'deactivatedAnimation']),
        ...mapGetters("teachers",["teachersById"]),
        ...mapGetters("groups",["groupsById"]),
        ...mapGetters("accounts",["accountsById"]),
        ...mapGetters('videoTutorial', [ 'videoTutorialsByFilename' ]),
        ...mapGetters('appointments', ['appointments']),

        meNotSickAtThisAppointment() {
            if(this.appointment && this.appointment.substitutions && this.appointment.substitutions.some(el => el.sickTeacher._id === this.person._id)) {
                return false;
            }
            return true;
        },
        appointmentHasSickTeacher(){
            if(this.appointment && this.appointment.substitutions && this.appointment.substitutions.length > 0){
                return true;
            }
            return false;
        },
        seenByMaintainer() {
            if(this.accountRole === 'maintainer'
                && this.appointment.substitutions?.length
                // Depends on seenBy-array and whether maintainer has taken any action
                && this.appointment.substitutions?.some(el => el.seenBy.includes(this.accountId) ? false : el.seenByMaintainer === false)) {
                return false;
            }
            return true;
        },
        badgeValue() {
            let count = 0;
            if (this.accountRole === 'maintainer') {
                if (this.substitutePlan) {
                    if (this.hasSickTeacherButNoSubstitution()) {
                        count = this.appointment.substitutions.length;
                    }
                }
            }
            if (this.accountRole === 'pupil' && !
                (this.appointment.noteSeenByMe ||
                    (this.appointment.noteSeenBy && this.appointment.noteSeenBy.find((accountId) => accountId === this.accountId)))) {
                count++;
            }
            if (this.accountRole === 'teacher') {
                if (this.sickMode) {
                    this.appointment.substitutions.forEach((sub) => {
                        if (sub.seenBy.includes(this.accountId) ? false : sub.seenByMaintainer === false) {
                            count++;
                        }
                    })
                }
            }
            const filterRule = (upload) => {
                return !upload.seenByMe && upload.uploader !== this.accountId;
            }
            if (this.accountRole === 'pupil' && typeof this.appointment.teacherUploads === "object") {
                const length = this.appointment.teacherUploads
                    .filter((element) => filterRule(element)).length;
                count += length;
            }
            if (this.accountRole === 'teacher' && typeof this.appointment.pupilUpload === "object") {
                if (!this.sickMode) {
                    const length = this.appointment.pupilUpload.filter((element) => filterRule(element)).length;
                    if (length > 1) {
                        return '+1';
                    } else {
                        count += length;
                    }
                }
            }
            return count;
        },
        sickAtThisAppointment() {
            return this.appointment.substitutions && this.appointment.substitutions?.some(el => el.sickTeacher._id === this.person._id);
        },
        showSubstitutionIcon() {
            return this.substitutePlan && this.fullscreenWeeklyView &&
                (this.accountRole === 'maintainer' || !this.editMode) &&
                this.appointment.substitutions &&
                !this.appointment.substitutions.some((substitution) => substitution.sickTeacher._id === this.person._id) &&
                this.appointment.substitutions.some((substitution) => substitution.sickTeacher && !substitution.substitutionTeacher);
        },
        subjectRooms() {
            let allRoomsString = '';
            if(!this.appointment.rooms) {
                return allRoomsString = ''
            }
            for(let i = 0; i < this.appointment.rooms.length; i++) {
                let roomName = this.appointment.rooms[i].roomName;
                if (roomName.length > 10) {
                    roomName = roomName.substring(0, 10) + '...';
                }
                if (i === 0) {
                    allRoomsString += roomName;
                } else {
                    allRoomsString += ', ' + roomName;
                }

            }
            return allRoomsString;
        },
        subjectRoomsFullNames() {
            let allRoomsString = '';
            if(!this.appointment.rooms) {
                return allRoomsString = ''
            }
            for(let i = 0; i < this.appointment.rooms.length; i++) {
                if (i === 0) {
                    allRoomsString += this.appointment.rooms[i].roomName;
                } else {
                    allRoomsString += ', ' + this.appointment.rooms[i].roomName;
                }
            }
            return allRoomsString;
        },
        showCameraIcon() {
            return this.isRunning && this.accountRole !== 'parent' && this.accountRole !== 'maintainer'
                && !this.editMode && !this.sickMode && !this.substitutePlan;
        }
    },
    watch: {
        word(newVal) {
            if (newVal === 'starte unterricht' && this.isRunning) {
                this.joinBBB();
            }
        },
        /**
         * This watcher is necessary to instantly load thumbnails after an upload happend and the appointment got
         * replaced. It won't trigger this.loadImagePreviews() in the beginning. This is done by the emits from
         * FachInfoPupil and FachInfoTeacher
         */
        appointment() {
            if(this.accountRole === "teacher" || this.accountRole === "pupil") {
                this.loadImagePreviews();
            }
        },
        uploadFileCallback(newVal) {
            if(newVal){
                this.callFileUpload=true;
            }else {
                this.callFileUpload=false;
            }
        },
        uploadFile(newVal) {
            if(newVal && this.callFileUpload===true){
                this.fileToUpload=newVal;
            }
        },
    },
    created() {
        this.checkTutorialState();
    },
    async mounted() {
        this.requestMe();
        if(!this.parent) {
            this.checkTutorialState();
            this.videoTutorial = (this.accountRole === 'teacher' || this.accountRole === 'maintainer')
                ? this.videoTutorialsByFilename['Tutorial_bbb_Lehrer_Verwaltung.mp4']
                : this.videoTutorialsByFilename['Tutorial_bbb_Eltern_Schueler.mp4'];
            // Register Listener for Shortcuts (Alt + S & Alt + B)
            window.addEventListener("keyup", this.handleKeyUp);
            // Safari 3.0+ "[object HTMLElementConstructor]"
            this.isSafari = (/constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification)))
                || window.navigator.userAgent === "Mozilla/5.0 (iPad; CPU OS 15_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.4 Mobile/15E148 Safari/604.1"
                || window.navigator.userAgent === "Mozilla/5.0 (Macintosh; Intel Mac OS X 12_4) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.4 Safari/605.1.15";
        }
        EventBus.off('new-thumbnail');
        EventBus.on('new-thumbnail', async (fileId) => {
            if (!this.appointment) return;
            let foundUpload = this.appointment.teacherUploads.find((upload) => upload.file === fileId);
            if (!foundUpload) {
                foundUpload = this.appointment.pupilUpload .find((upload) => upload.file === fileId);
                if (!foundUpload) {
                    return;
                }
            }
            await this.loadImagePreview(foundUpload);
            foundUpload.hasThumbnail = true;
        });
    },
    async unmounted() {
        EventBus.off('new-thumbnail');
    },
    beforeUnmount() {
        // Remove Listener for Shortcuts (Alt + S & Alt + B)
        window.removeEventListener("keyup", this.handleKeyUp);
    },
    methods: {
        ...mapActions('weeklyView', ['toggleWeeklyView', 'closeWeeklyView']),
        ...mapActions("util", ['toggleLoading']),
        ...mapActions("tooltips", ["triggerShortcutTooltips"]),
        ...mapActions('appointments', ['createAppointment', 'editAppointment', 'setAppointmentNoteSeen', 'getSchoolSubjectAppointmentUploadThumbnail',
                                        'undoSubstitutionsInAppointments', 'postSubstitutionsInAppointments', 'getAppointment']),
        ...mapActions('teachers', ['getTeachers','getProfilePicture', 'createTeacher', 'deleteTeacher', 'editTeacher']),
        ...mapMutations("snackbar", ["showSnackbar"]),
        ...mapActions('mimeTypes', ['getFileExtension']),
        ...mapActions('substitution', ['patchSubstitution','selectNoSubstitution']),


        getGroupName(group){
            // necessary since sometimes group isnt populated
            if(!group._id){
                return this.groupsById[group].name;
            }
            return group.name;
        },
        getGroupParticipants(group){
            const names = group.participants.map((pupil)=> {return pupil.name +' ' + pupil.lastName + '\n'})
            return names;
        },
        hasAppointmentRooms(appointment){
            if(this.currentRoomModel){
                return appointment.rooms.some(app => app._id === this.currentRoomModel._id)
            }
            else{
                return false;
            }
        },
        getResponsibleTeacher() {
            const substitutionTeacher = this.getSubstitutionTeacher();
            if (substitutionTeacher) {
                return substitutionTeacher;
            }
            return this.appointment.teachers[0];
        },
        showSickIcon(){
            const substitutions = this.appointment.substitutions;
            if(substitutions && substitutions.length) {
                return substitutions.some((sub) => sub.substitutionTeacher ? false : true  )
            }
            return false;
        },
        hasSickTeacherButNoSubstitution() {
            const substitutions = this.appointment.substitutions;
            if(substitutions && substitutions.length) {
                // Depends on seenBy-array and whether maintainer has taken any action
                return substitutions.some((sub) => sub.seenBy.includes(this.accountId) ? false : sub.seenByMaintainer === false)
            }
            return false;
        },
        //checks if teacher is in appointments substitutions, to display it on fachinfo
        // if performance suffers we need to (in vuex-store) map a list of every teacher inside substitutions to this appointment object
        // so it only needs to be calculated once and can be accessed from appointment object
        isTeacherSick(appointment, teacher) {
            const substitutions = appointment.substitutions;
            if (substitutions.length > 0) {
                return substitutions.some(sub => sub.sickTeacher?._id === teacher._id);
            } else {
                return false;
            }
        },

        async handleMultipleTeacherAppointment(appointment, teacher){
            if(this.isTeacherSick(appointment, teacher)){
                await this.undoSubstitutionsInAppointments({appointmentIds: [appointment._id], teacherId: teacher._id})
            }else{
                const appointmentArray = [appointment._id];
                const teacherForSub = teacher;
                const dontNotify = false;
                try{
                    await this.$store.dispatch('appointments/postSubstitutionsInAppointments', {
                                    appointmentIds: appointmentArray,
                                    notify: dontNotify,
                                    currentTeacher: teacherForSub
                                    });
                    this.showSnackbar({
                        message: 'Die Abwesenheiten wurde erfolgreich eingetragen!',
                        color: 'success'
                    });
                } catch (err) {
                    this.showSnackbar({
                        message: 'Bei dem Eintragen der Abwesenheit ist ein unerwarteter Fehler aufgetreten!',
                        color: 'error'
                    });
                    console.error(err);
                }
            }
        },

        noSubstitutionSelected() {
            const substitutions = this.appointment.substitutions;
            if(substitutions && substitutions.length) {
                return ((substitutions[0].sickTeacher) && (!substitutions[0].substitutionTeacher) && (substitutions[0].seenByMaintainer));
            }
            return false;
        },

        getSubstitutionTeacher() {
            if (this.appointment.substitutions) {
                if (this.appointment.substitutions.length) {
                    if (this.appointment.substitutions[0].sickTeacher) {
                        if (this.appointment.substitutions && this.appointment.substitutions.some((el) => el.sickTeacher && el.substitutionTeacher)) {
                            return this.appointment.substitutions.find((el) => el.sickTeacher && el.substitutionTeacher).substitutionTeacher;
                        }
                    }
                }
            }
            return null;
        },

        async handleSubstituteClick(teacher) {
            if(this.accountRole === 'maintainer' && !teacher) {
                this.substitutionDialog = true;
            } else {
                await this.$nextTick();
                const substitutionTeacher = teacher ? teacher._id : this.person._id;
                const patchedSubstitutions = await Promise.all(this.appointment.substitutions.map(async (substitution) => {
                    if(substitution.sickTeacher && !substitution.substitutionTeacher) {
                        const res = await this.patchSubstitution({substitutionId: substitution._id, params:{
                            substitutionTeacher,
                            seenByMaintainer: true,
                        }});
                    }
                }));
                await this.requestAppointments([this.appointment._id]);
                this.substitutionDialog = false;
            }
        },

        async noSubstitutionClick(notify) {
            if (this.accountRole === 'maintainer') {
                await Promise.all(this.appointment.substitutions.map(async (substitution) => {
                    const res = await this.selectNoSubstitution({substitutionId: substitution._id, params:{
                        notifyParents: notify,
                    }});
                }));
                await this.requestAppointments();
                this.substitutionDialog = false;
            }
        },

        clickedCancelInCreateLesson() {
            this.editLessonMenu = false
            this.$emit('cancelCreationEmptyLesson');
        },
        notifyParent(){
            this.$emit('fileUploaded');
        },
        getTeacherName(teacherObj){
            let teacher = teacherObj;
                if(typeof teacherObj === 'string'){
                    teacher = this.teachersById[teacherObj];
                }
            return teacher.name[0] + '.' + teacher.lastName;
        },
        getTeachersNames(teachers, length){
            let teacherNames = '';
            let teacher;
            if (Array.isArray(teachers)) {
                teachers.forEach((teacher, index) => {
                   if (typeof teacher === 'string') {
                       // assume only teacher id is given
                       teacher = this.teachersById[teacher];
                   }
                   if (teacher) {
                       let name = `${teacher.name[0]}.${length === 'short' ? teacher.lastName[0]+'.' : teacher.lastName}`;
                       if (index+1 !== teachers.length) {
                          name = name+',';
                       }
                       teacherNames = teacherNames.concat(name);
                   }
                });
            } else if (typeof teachers === 'string' || typeof teachers === 'object'){
                if (typeof teachers === 'string') {
                    teacher = this.teachersById[teachers];
                } else {
                    teacher = teachers;
                }
                const name = `${teacher.name[0]}.${length === 'short' ? teacher.lastName[0]+'.' : teacher.lastName}`;
                teacherNames = teacherNames.concat(name);
            }
            return teacherNames;
        },
        handleFachClicked(){
            if (!this.currentRoomModel && this.roomMode) {
                this.showSnackbar({message: 'Bitte wählen Sie zuerst einen Raum aus!', color: 'error'});
                return;
            }
            if (this.roomMode) {
                this.openLessonPopup();
                return;
            }
            if(!this.sickMode){
                if(this.editMode && !this.substitutePlan ){
                    if(this.copyMode) {
                        this.showSnackbar({
                            message: 'Löschen sie zuerst die Stunde ' + this.appointment.schoolSubject.name + ' um hierher zu kopieren.',
                            color: 'warning'
                        });
                    } else {
                        this.openLessonPopup();
                    }
                } else if(this.substitutePlan){
                    // this.openVertretungsplanDialog();
                } else {
                    this.openLessonInWidget();
                    this.closeWeeklyView();
                }
            } else if (this.sickMode || this.substitutePlan) {
                this.markSickFachAsSeen();
            }
        },
        async markSickFachAsSeen() {
            this.appointment.substitutions.map(async (sub) => {
                if (!sub.seenBy.includes(this.accountId)) {
                    let newSeenBy = [];
                    sub.seenBy.forEach((seen) => {
                        newSeenBy.push(seen);
                    })
                    newSeenBy.push(this.accountId);
                    this.patchSubstitution({
                        substitutionId: sub._id, params: {
                            seenBy: newSeenBy
                        }
                    });
                    await this.getAppointment(this.appointment._id);
                }
            })
        },
        // openVertretungsplanDialog(){
        //     this.$emit('openVertretungsplanDialog');
        // },
        /**
         * Attaches a border to the current Fach so it's clear which one is currently copied
         */
        getBorderIfCopied() {
            if(this.currentCopiedAppointment && this.appointment._id === this.currentCopiedAppointment._id) {
                if(this.isSafari) {
                    return 'safariCopiedLesson';
                } else {
                    const color = this.appointment.schoolSubject.color;
                    const styleElement = document.createElement("style");
                    styleElement.innerHTML = ".currentlyCopiedLesson::before { background-image: linear-gradient("+color+","+color+"), linear-gradient(#6995cd, #6995cd), linear-gradient("+color+","+color+"), linear-gradient(#6995cd, #6995cd) !important;}";
                    document.head.appendChild(styleElement);
                    return 'currentlyCopiedLesson';
                }
            } else {
                return 'py-2';
            }
        },

        /**
         * Pastes an appointment on a single day after confirming that it should be copied
         */
        async pasteAppointmentSingle() {
            let data = {
                schoolSubject: this.currentCopiedAppointment.schoolSubject._id,
                teachers: this.currentCopiedAppointment.teachers,
                rooms: this.currentCopiedAppointment.rooms,
                // teacher has propagated group, maintainer not
                groupId: this.currentCopiedAppointment.group._id ? this.currentCopiedAppointment.group._id : this.currentCopiedAppointment.group,
                ...(typeof this.currentCopiedAppointment.group === 'string' ? { groupId: this.currentCopiedAppointment.group } :  { groupId: this.currentCopiedAppointment.group._id }),
                day: this.day,
                timeslot: this.timeslot._id,
            };
            await this.saveCopiedAppointment(data);
            this.areYouSureCopyDialog = false;
        },

        /**
         * Pastes an appointment weekly after confirming that it should be copied with the original rule
         */
        async pasteAppointmentWeekly() {
            let data = {
                schoolSubject: this.currentCopiedAppointment.schoolSubject._id,
                teachers: this.currentCopiedAppointment.teachers,
                rooms: this.currentCopiedAppointment.rooms,
                // teacher has propagated group, maintainer not
                groupId: this.currentCopiedAppointment.group._id ? this.currentCopiedAppointment.group._id : this.currentCopiedAppointment.group,
                day: this.day,
                timeslot: this.timeslot._id,
                ...(this.confirmWeeklyCopy
                    ? {
                        repeat: {
                            interval: "weekly",
                            from: this.day,
                            until: this.currentCopiedAppointment.rule.endDate,
                        },
                    }
                    : {}),
            };
            await this.saveCopiedAppointment(data);
            this.confirmWeeklyCopy = true;
            this.weeklyCopyDialog = false;
        },

        /**
         * Pastes an appointment by clicking on this Fach
         */
        async openPasteCopiedAppointmentDialog() {
            if(this.currentCopiedAppointment.rule) {
                this.weeklyCopyDialog = true;
            } else {
                this.areYouSureCopyDialog = true;
            }
        },

        /**
         * Creates the pasted appointment with the backend and the data given
         * @param data is the data of the new appointment
         */
        async saveCopiedAppointment(data) {
            let response = await this.createAppointment(data);
            if (Number.isInteger(response)) {
                if (response === 409) {
                    this.showSnackbar({message: 'Der Termin konnte nicht erstellt werden.', color: 'error'});
                } else {
                    this.showSnackbar({message: 'Ein unerwarteter Fehler ist aufgetretten!', color: 'error'});
                }
                this.snackbar = true;
            } else {
                this.showSnackbar({message: 'Gespeichert!'});
                this.requestAppointments();
            }
        },

        onVideoTutorialClose() {
            this.videoTutorial = false;
            this.joinBBB();
        },

        async requestMe() {
            const role =  this.accountRole;
            if(role === 'pupil' && this.me) {
                this.sehen = this.me.accessibility ? this.me.accessibility.sehen : false;
                this.hoeren = this.me.accessibility ? this.me.accessibility.hoeren : false;
                this.motorik = this.me.accessibility ? this.me.accessibility.motorik : false;
            } else {
                this.sehen = false;
                this.hoeren = false;
                this.motorik = false;
                this.setGlobalButtonContrast('none', 'none');
            }
        },

        handleKeyUp() {
            if (event.altKey && event.key === 's' && this.isRunning) {
                this.openLessonInWidget();
                this.triggerShortcutTooltips();
            }
            if(event.altKey && event.key === 'b' && this.isRunning) {
                console.log("pressed b")
                this.clickBeitreten();
                this.triggerShortcutTooltips();
            }
        },
        /**
         * @param file File is actually the teacher- /pupilUpload
         * @returns {Promise<*>}
         */
        async loadImagePreview(file) {
            if (allowedExtensionsForAudioPlayer.includes(file.title.split('.').pop())) {
                return file;
            }
            try {
                const blob = await this.getSchoolSubjectAppointmentUploadThumbnail({
                    file: file,
                    appointmentId: this.appointment._id,
                    uploadId: file._id
                });

                // Ensure this.urls is reactive; assign the property directly
                this.urls[file._id] = window.URL.createObjectURL(blob);
                
                // Set `hasThumbnail` property directly
                file.hasThumbnail = blob.type !== 'text/plain; charset=utf-8';

                return file;
            } catch (e) {
                console.error(e);
            }
        },
        /**
         * @param file File is actually the teacher- /pupilUpload
         * @returns {Promise<*>}
         */
        async loadImagePreviews() {
            if (this.appointment && this.appointment.pupilUpload) {
                await Promise.all(this.appointment.pupilUpload.map(async (file) => {
                    if (allowedExtensionsForAudioPlayer.includes(file.title.split('.').pop())) {
                        return file;
                    }
                    try {
                        let blob;
                        blob = await this.getSchoolSubjectAppointmentUploadThumbnail({file: file, appointmentId: this.appointment._id, uploadId: file._id});
                        this.urls[file._id] = window.URL.createObjectURL(blob);
                        file.hasThumbnail = blob.type !== 'text/plain; charset=utf-8';
                        return file;
                    } catch (e) {
                        console.error(e);
                    }
                }));
            }
            if (this.appointment && this.appointment.teacherUploads) {
                await Promise.all(this.appointment.teacherUploads.map(async (file) => {
                    if (allowedExtensionsForAudioPlayer.includes(file.title.split('.').pop())) {
                        return file;
                    }
                    try {
                        let blob;
                        blob = await this.getSchoolSubjectAppointmentUploadThumbnail({file: file, appointmentId: this.appointment._id, uploadId: file._id});
                        this.urls[file._id] = window.URL.createObjectURL(blob);
                        file.hasThumbnail = blob.type !== 'text/plain; charset=utf-8';
                        return file;
                    } catch (e) {
                        console.error(e);
                    }
                }));
            }

        },

        checkTutorialState() {
            this.bbbTutState = JSON.parse(localStorage.getItem('bbbTut'));
            if (this.bbbTutState) {
                this.bbbTut = false;
            }
        },

        getSrc() {
            if(this.appointment){
                if(this.appointment.schoolSubject.uploadedIcon){
                    const icon =  this.icons.find((icon)=> icon.icon === this.appointment.schoolSubject.icon);
                    if(icon){
                        this.hasUploadedIcon = true;
                        return icon.iconUrl;
                    }else{
                        return loadingIcon;
                    }
                }else {
                    return this[this.appointment.schoolSubject.icon]
                }
            }
            else if(this.thisSubject){
                if(this.thisSubject.uploadedIcon){
                    const icon =  this.icons.find((icon)=> icon.icon === this.thisSubject.icon);
                         if(icon){
                            this.hasUploadedIcon = true;
                        return icon.iconUrl;
                    }else{
                        return loadingIcon;
                    }

                }else {
                    return this[this.thisSubject.icon]
                }
            }
            else {
                return null;
            }
           // return this.thisSubject ? this[this.thisSubject.icon] : (this.appointment ? this[this.appointment.schoolSubject.icon] : null);

        },

        /**
         * Cant be done like getSrc() one because the second part of string doesnt match with the normal icon, so a search is probably needed
         */
        getSrcWhite() {
            let temp = this.thisSubject
                ? this[this.thisSubject.icon]
                : this.appointment
                    ? this[this.appointment.schoolSubject.icon]
                    : null;
            let searchString = temp ? temp.slice(0, temp.length - 13) + "-weiss" : '';
            let returnString = '';

            for (let i = 0; i < Object.keys(this.$data).length; i++) {
                let key = Object.keys(this.$data)[i];
                if (this.$data[key] && this.$data[key].includes && this.$data[key].includes(searchString)) {
                    returnString = this.$data[key];
                    break;
                }
            }

            return returnString.length > 0 ? returnString : temp;
        },

        getColorIcon() {
            return this.thisSubject ? this['color' + this.thisSubject.color.substring(1)] : (this.appointment && this.appointment.schoolSubject.color ? this['color' + this.appointment.schoolSubject.color.substring(1)] : this.colorb2b2b2);
        },

        /*Hintergrund-Farbe für Icon wenn es aktuell ist*/
        getBackgroundColor() {
            let returnString = "background-color: ";
            return returnString + this.appointment.schoolSubject.color;
        },

        getTimeslotTimes() {
            if (this.timeslot) {
                return (
                    ("0" + new Date(this.timeslot.start).getHours()).slice(-2) + ":" + ("0" + new Date(this.timeslot.start).getMinutes()).slice(-2)
                    + ' - ' +
                    ("0" + new Date(this.timeslot.end).getHours()).slice(-2) + ":" + ("0" + new Date(this.timeslot.end).getMinutes()).slice(-2));
            }
        },

        startBBB() {
            /*emit to PlanWidget*/
            this.$emit('pass-on-bbb-tut', this.joinBBB);
        },

        async clickBeitreten() {
            if (!this.isJoinable) {
                this.showSnackbar({ message: "Dieser Raum ist abgeschlossen.", color: "error"})
                return;
            }
            this.menu = false;

            if (!this.bbbTutState) {
                this.startBBB();
            } else if (this.bbbTutState) {
                await this.joinBBB();
            }
        },

        async joinBBB() {
            if (this.accountRole === 'pupil') {
                try {
                    // Check if the appointment meeting is open
                    const isOpen = await backend.isAppointmentMeetingOpen(this.appointment._id);
                    // If status is 202, show snackbar and exit the method
                    if (isOpen.status === 204) {
                        this.showSnackbar({ message: "Aktuell ist der Raum geschlossen! Du kannst erst beitreten sobald der Lehrer den Raum betreten hat", color: "error", timeout: 10000 });
                        this.toggleLoading(false);
                        return;
                    }

                    // Proceed to join the meeting if it's open
                    this.toggleLoading(true);
                    const res = await backend.joinSchoolSubjectAppointmentBbbMeetingUrl(this.appointment._id);
                    const { redirectUrl } = await res.json();
                    window.location.href = redirectUrl;
                } catch (error) {
                    console.error('Error handling appointment:', error);
                    this.showSnackbar({ message: "Ein Fehler ist aufgetreten!", color: "error" });
                } finally {
                    this.toggleLoading(false);
                }
            } else {
                try {
                    // Logic for non-pupil roles
                    this.toggleLoading(true);
                    const res = await backend.joinSchoolSubjectAppointmentBbbMeetingUrl(this.appointment._id);
                    const { redirectUrl } = await res.json();
                    window.location.href = redirectUrl;
                } catch (error) {
                    console.error('Error handling appointment:', error);
                    this.showSnackbar({ message: "Ein Fehler ist aufgetreten!", color: "error" });
                } finally {
                    this.toggleLoading(false);
                }
            }
        },

        async openLessonInWidget() {
            const appointmentDate = new Date(this.appointment.day);
            appointmentDate.setHours(this.timeslot.start.getHours(), this.timeslot.start.getMinutes(), this.timeslot.start.getSeconds());
            this.$emit("clickedFach", appointmentDate, this.appointment)
            // set fachinfo seen if it isnt
            if (this.accountRole === 'pupil' && !this.appointment.noteSeenByMe) {
                await this.setAppointmentNoteSeen(this.appointment._id);
            }
            
            //this resets the query  in url so it can be set again right afterwards 
            //to then trigger watcher in HomeworkCategoryContainer that highlights homework
            if(this.$route.query.aId === this.appointment._id){
                await this.$router.replace({ query: { appointment: undefined } });
            }
            // set appointment id in query
            await this.$router.replace({ query: { ...this.$route.query, aId: this.appointment._id } });
        },

        fachClicked() {
            if(this.filterMode){
                this.showSnackbar({message: 'Bitte verlassen Sie zunächst den Filter-Modus', color: 'error'});
                return;
            }
            if (!this.currentRoomModel && this.roomMode) {
                this.showSnackbar({message: 'Bitte wählen Sie zuerst einen Raum aus!', color: 'error'});
                return;
            }
            if (this.substitutePlan) {
                return;
            }
            if(this.accountRole === 'parent'){
                    this.openLessonInWidget();
            }
            if (this.editMode) {
                if (this.fullscreenWeeklyView){
                    this.copyMode ? this.openPasteCopiedAppointmentDialog() : this.openLessonPopup()
                } else {
                    this.openLessonInWidget();
                }
            } else if (this.accountRole === 'maintainer') {
                this.openEmptyLesson();
            }
        },

        openLessonPopup() {
            this.editLessonMenu = true;
        },

        openEmptyLesson() {
            this.$emit("clickedEmptyFach", this.timeslot);
        },

        getAccessibilityDescription() {
            const timeslotTimes = this.getTimeslotTimes();
            if(timeslotTimes && this.appointment) {
                const time = timeslotTimes.replace('-', 'bis');
                const name = this.appointment.schoolSubject.name;
                let bei = '';
                if(this.accountRole === 'teacher') {
                    bei = this.appointment.group.name;
                } else {
                    bei = this.appointment.teachers[0].name.slice(0, 1) + '. ' + this.appointment.teachers[0].lastName;
                }
                const rooms = this.subjectRooms;

                return `Von ${time} ${name} bei ${bei}${rooms ? ' in Raum ' + rooms : ''}`;
            } else {
                return '';
            }
        },

        getAccessibilityTime(time) {
            let charArray = time.toString().split('');
            if (charArray[0] === '0' && charArray[1] === '0') {
                return '';
            } else if (charArray[0] === '0' && charArray[1] !== '0') {
                return charArray[1];
            } else {
                return charArray[0] + charArray[1];
            }
        },
        handleAudioUrl(audioUrl) {
            this.$emit('audioOverlay', audioUrl);
        },
    }
};
</script>

<style lang="scss" scoped>
.appointmentItem:hover {
    .copyButton {
        display: flex;
    }
}

.copyButton {
    display: none;
}
scroll-area {
    margin: auto;
    height: auto;
    max-height: 60vh;
    border-radius: 8px 8px 8px 8px;
    overflow-x: hidden;
    width: 200px;
}
#cameraBtn {
    height: 56px;
    width: 56px;
    box-shadow: 1px 2px 3px silver;
    border-radius: 12px;
}

.safariCopiedLesson {
    border: 5px dotted #FF0000;
    border-radius: 20px;
}

// Copy animation
.currentlyCopiedLesson {
    position: relative;
    z-index: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    padding: 8px;

    &::before {
        content: '';
        position: absolute;
        z-index: -2;
        left: -25px;
        top: -150px;
        width: 350px;
        height: 350px;
        background-color: #399953;
        background-repeat: no-repeat;
        background-size: 50% 50%, 50% 50%;
        background-position: 0 0, 100% 0, 100% 100%, 0 100%;
        background-image: linear-gradient(#fac837, #fac837), linear-gradient(#6995cd, #6995cd), linear-gradient(#fac837, #fac837), linear-gradient(#6995cd, #6995cd);
        animation: rotate 4s linear infinite;
    }

    &::after {
        content: '';
        position: absolute;
        z-index: -1;
        left: 6px;
        top: 6px;
        width: calc(100% - 12px);
        height: calc(100% - 12px);
        background: white;
        border-radius: 5px;
    }
}

@keyframes rotate {
    100% {
        transform: rotate(1turn);
    }
}

.fach {
    cursor: pointer;
}

.appointmentItem {
    display: flex;
    flex-direction: row;
    color: rgb(var(--v-theme-dunkelgrau));
    border-radius: 12px;
    box-shadow: 1px 2px 3px silver;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #FFF;
    width: 100%;
}

.fachTextContainer {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    padding: 6px 0;
    width: 50%;
}
.noSubOverlay {
    position: absolute;
    top: 0;
    left: -50px;
    width: 100%;
    height: 100%;
    pointer-events: none; /* Non-interactive overlay */
}

.diagonal-line {
    width: calc(100% + 50px);
    height: 100%;
}

.diagonal-line line {
    stroke: grey; /* Color of the line */
    stroke-width: 4; /* Thickness of the line */

}
.parentContainer {
    width: 100%; /* Full width to match screen width responsiveness */
    height: auto; /* Adjust based on `.fachTextContainer` height */
}
.pauseContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.fachRow {
    display: flex;
    flex-direction: row;
}

.shadowFix {
    margin-right: 2px;
}

.lessonViewContainer {
    display: flex;
    flex-direction: row;
    max-height: calc(80vh - 175px);
    overflow: hidden;
    width: 100%;
}

// .inactiveAppointment {
//     opacity: 0.4 !important;
// }

//border-radius of v-menu
.v-menu__content {
    border-radius: 12px;
}

.iconSelect {
    width: 48px;
    height: 48px;
    border-radius: 12px;
    background-color: rgba(234, 234, 234, 0.7);
    box-shadow: 1px 2px 3px silver;
    margin: 5%;
}

.isFachRunning {
    color: white;
    background-color: rgb(var(--v-theme-chatColor)) !important;
}

.isFachRunningIcon {
    filter: brightness(1000%);
}

.className {
    position: absolute;
    right: 2.5%;
    bottom: 5%;
    color: rgb(var(--v-theme-dunkelgrau));
}

@media only screen and (max-width: 1280px) {
    .className {
        font-size: small;
    }
}

.carouselItem {
    width: 100%;
    overflow: hidden;
    position: absolute;
    top: 100%;
    display: inline-flex;
    width: 48px;
    height: 48px;
    border-radius: 12px;
    background-color: rgba(234, 234, 234, 0.7);
    box-shadow: 1px 2px 3px silver;
    margin: 5%;
}
.noAnimationItem{
    width: 100%;
    overflow: hidden;
    display: inline-flex;
    width: 48px;
    height: 48px;
    border-radius: 12px;
    background-color: rgba(234, 234, 234, 0.7);
    box-shadow: 1px 2px 3px silver;
    margin: 5%;
}
.numberStyle {
    color: white;
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-content: center;
    align-self: center;
}

@keyframes autoplay {
  0% {top: 100%}
  5% {top: 0%}
  30% {top: 0%}
  40% {top: -100%}
  100% {top: -100%}
}
@keyframes autoplay2 {
  0% {top: 100%}
  5% {top: 0%}
  45% {top: 0%}
  60% {top: -100%}
  100% {top: -100%}
}
.planView{
    animation: 30s autoplay infinite ease-in-out;

}
.fachView{
    animation: 20s autoplay2 infinite ease-in-out;

}

#carouselItem1 {   animation-delay: 0s }
#carouselItem2 {  animation-delay: 10s }
#carouselItem3 {  animation-delay: 20s }

.iconToSick {
    filter: brightness(0) saturate(100%) invert(32%) sepia(94%) saturate(6875%) hue-rotate(352deg) brightness(110%) contrast(87%);
}

.iconToSuccessGreen {
    filter: brightness(0) saturate(100%) invert(51%) sepia(64%) saturate(530%) hue-rotate(68deg) brightness(93%) contrast(89%);
}

.v-btn--fab.v-btn--absolute, .v-btn--fab.v-btn--fixed {
    z-index: 0 !important;
}

.responsiveSubjectInfo {
    display: none !important;
}
@media only screen and (min-width: 900px) and (max-width: 1100px) {
    .responsiveTeacherInfo {
        display: none;
    }
}
.iconToLightGray {
    filter: brightness(0) saturate(100%) invert(96%) sepia(23%) saturate(0%) hue-rotate(122deg) brightness(88%) contrast(88%);
}

.selectedRoom {
    background-color:rgb(var(--v-theme-timetableColor)); /* Blue background color */
    width: fit-content;
    border-radius: 10px;
    padding-inline: 10px;
    padding-block: 2px;
    display: inline-block;
    color: #FFFFFF; /* White text color */
}

@media only screen and (min-width: 1340px) and (max-width: 1920px) {
    .columnMaxWidth {
        max-width: 12vw;
    }

}

@media only screen  and (max-width: 1340px) {
    .columnMaxWidth {
        width: 100%;
        max-width: 160px; // 225 - 55 for substitute button - 10 padding
    }
}
</style>
