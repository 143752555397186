import * as backend from "@/api/backend";
import {checkResponseStatus} from "@/util/check";

const state = {
    imageRights: [],
};

const mutations = {
    setImageRights: (state, imageRights) => {
        state.imageRights = imageRights;
    },
    addOrUpdateImageRights: (state, imageRight) => {
        const index = state.imageRights.findIndex(el => {
            return  el._id === imageRight._id;
        });
        if (index >= 0){
            state.imageRights[index] = imageRight;
        }    
        else {
            state.appointments.push(imageRight);
        }
    },
};

const getters = {
    imageRights: state => state.imageRights,
    imageRightsByPupilId: state => state.imageRights.reduce((acc, imageRight) =>{
        acc[imageRight.pupil._id] = imageRight;
        return acc;
    },{}),
    imageRightsByGroup: state => group => {
        return state.imageRights.filter(imageRight => imageRight.group === group);
      }
}

const actions = {
    async addPupilGroup({rootGetters}, pupilId){
        const allGroups = rootGetters['groups/groups'];
        const groups = allGroups.filter((group) =>{
            if(group.participants.some((participant)=> participant._id === pupilId)){
                return group._id
            }
        })
        return groups.length > 0 ? groups[0]._id : null;
        
    },
    async postImageRights({ commit, dispatch, rootGetters }, body) {
        try {
            const {pupilId} = body;
            const res = await backend.postImageRight(body);
            await checkResponseStatus(201, res);
            let imageRight = await res.json();
            imageRight.group =  await dispatch('addPupilGroup',pupilId);
            imageRight.pupil = rootGetters['pupils/pupilsById'][pupilId];
            commit('addOrUpdateImageRights', imageRight);
            return imageRight;
        }
        catch (err) {
            return err.response;
        }
    },

    async getImageRights({commit, dispatch}) {
        try {
            const res = await backend.getImageRights();
            await checkResponseStatus(200, res);
            const imageRights = await res.json();
            const populatedImageRights = await Promise.all(imageRights.map(async (imageRight) => {
                imageRight.group = await dispatch('addPupilGroup',imageRight.pupil._id);
                return imageRight;
            }
            ))
            commit('setImageRights', populatedImageRights);
            return populatedImageRights;
        }
        catch (err) {
            return err.response;
        }
    },
    async getImageRight({commit, dispatch}, params){
        try {
            const imageRightId = params.imageRightId;
            const res = await backend.getImageRight(imageRightId);
            await checkResponseStatus(200, res);
            return await res.json();
        }
        catch (err) {
            return err.response;
        }
    },

    async toggleImageRight({commit, dispatch, rootGetters}, imageRightId) {
        try {
            const res = await backend.toggleImageRight(imageRightId);
            await checkResponseStatus(201, res);
            let imageRight = await res.json();
            imageRight.group =  await dispatch('addPupilGroup',imageRight.pupil);
            imageRight.pupil = rootGetters['pupils/pupilsById'][imageRight.pupil];
            commit('addOrUpdateImageRights', imageRight);
            return imageRight;
        }
        catch (err) {
            return err.response;
        }
    },
    async getImageRightNamesForGroups({getters}, groups) {
        const imageRightsByGroups = groups.map((group) => getters.imageRightsByGroup(group._id)).filter(arr => arr.length > 0);
        const mergedImageRightsByGroups = imageRightsByGroups.reduce((acc, curr) => acc.concat(curr), []);
        const uniqueImageRightsByGroups = Array.from(new Set(mergedImageRightsByGroups));
        const namesOfRestrictedPupils = uniqueImageRightsByGroups.map((imageRight)=>{
            if(imageRight.restrictImage){
                return `${imageRight.pupil.name} ${imageRight.pupil.lastName}`
            }
        })
       return namesOfRestrictedPupils.filter((el)=> el !== undefined)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};