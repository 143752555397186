<template>
    <div
        id="btnsContainer"
        class="d-flex justify-start"
    >
        <v-btn
            class="joinRoomBtn d-flex px-2"
            style="justify-content: left"
            elevation="0"
            color="gruen"
            @click="clickExternalButton"
        >
            <img
                :src="videoIcon"
                alt="Videokamera"
            >
            <p
                style="padding-right: 52px"
            >
                Extern
            </p>
        </v-btn>

        <!-- Pack dialog into a external component in order to conditionally load data-->
        <JoinExternalDialog
            v-if="dialogActive"
            @close-dialog="closeDialog"
        />
    </div>
</template>

<script>
import videoIcon from '@/assets/Icons/video.svg';
import JoinExternalDialog from './JoinExternalDialog.vue';
import {mapActions} from "vuex";

export default {
    name: "JoinExternalButton",
    components: {
        JoinExternalDialog,
    },
    data: () => ({
        videoIcon,
        dialogActive: false,
    }),
    methods: {
        ...mapActions('avatar', ['setAvatarPickerOpen']),

        async clickExternalButton() {
            this.dialogActive = true;
            // For sidebar/profileManagement in mobile view, so that dialog stays open while interacting with it
            this.setAvatarPickerOpen(true);
        },
        closeDialog() {
            this.dialogActive = false;
            this.setAvatarPickerOpen(false);    // When dialog close, set this back to false
        },
    }
}
</script>

<style scoped lang="scss">
.joinRoomBtn {
    color: white;
    text-transform: inherit;
    height: 50px !important;
    border-radius: 8px;
    width: 160px;

    img {
        height: 25px;
        filter: brightness(1000%);
        margin-right: 4px;
    }

    p {
        font-size: large;
        font-weight: 400;
        line-height: 0;
        margin-bottom: 0;
    }
}

@media only screen and (max-width: 900px) {
    .joinRoomBtn {
        height: 40px !important;
        padding: 8px;
        margin-left: 8px;

        img {
            height: 20px;
        }

        p {
            font-size: small;
        }
    }
}

// When change to mobile nav on bottom
@media only screen and (max-width: 500px), (max-width: 900px) and (orientation: portrait) {
    .joinRoomBtn {
        padding: 8px !important;
        margin-left: 0 !important;
        margin-bottom: 8px;
    }
}
</style>
