import backendWebSocket from "../api/backendWebSocket";
import {mapMutations} from "vuex";
import {showSnackbar} from "../store/modules/snackbar"
import EventBus from "../util/eventBus.js"
// Based on https://vuex.vuejs.org/guide/plugins.html#committing-mutations-inside-plugins
// When user loggs/authenticates in, then create a websocket connection to our ackend
// On logout close the connection
const createBackendWebSocketPlugin = () => {
  return (store) => {
    // store.subscribe(mutation => {
    //   // login
    //   if (mutation.type === 'auth/login') {
    //     backendWebSocket.initWebSocket();
    //   }

    //   // logout
    //   if (mutation.type === 'auth/logout') {
    //     backendWebSocket.closeWebSocket();
    //   }
    // });

      //SCHOOLSUBJECTS
      backendWebSocket.post('/schoolsubject/new', (request) => {
         EventBus.emit('schoolsubject-new', request.body.createdSchoolSubject);
      });
      backendWebSocket.patch('/schoolsubject/patch', (request) => {
          EventBus.emit('schoolsubject-patch', request.body.updatedSubject);
      });
      backendWebSocket.delete('/schoolsubject/delete/:subjectId', (request) => {
         EventBus.emit('schoolsubject-delete', request.params.subjectId);
      });
      //CALENDAR
      backendWebSocket.post('/calendar/new/event', (request) => {
         EventBus.emit('calendar-new-event', (request.body.createdEvent));
      });
      backendWebSocket.delete('/calendar/delete/:eventId', (request) => {
         EventBus.emit('calendar-delete-event', (request.params.eventId));
      });
      backendWebSocket.post('/calendar/new/events', (request) => {
          EventBus.emit('calendar-new-events', (request.body.createdEvents));
      });
      backendWebSocket.delete('/calendar/delete/:eventIds', (request) => {
         EventBus.emit('calendar-delete-events', (request.params.eventIds));
      });
      backendWebSocket.patch('/calendar/change/event', (request) => {
          EventBus.emit('calendar-change-event', (request.body.updatedEvent));
      })
      backendWebSocket.patch('/calendar/new/event/file', (request) => {
         EventBus.emit('calendar-change-event', (request.body.updatedEvent));
      });
      backendWebSocket.patch('/calendar/change/events/data', (request) => {
          EventBus.emit('calendar-change-events-data', (request.body.updatedEvents));
      });
      backendWebSocket.delete('/calendar/delete/:eventId/:fileId', (request) => {
         EventBus.emit('calendar-delete-file', request.params);
      });

      //THUMBNAILS
      backendWebSocket.get('/new/thumbnail/:fileId/:accessToken', (request) => {
          EventBus.emit('new-thumbnail', request.params.fileId);
          EventBus.emit('new-thumbnail-pinboard', request.params.fileId);
          EventBus.emit('new-thumbnail-blackboard', request.params.fileId, request.params.accessToken);
          EventBus.emit('new-thumbnail-parental', request.params.fileId);
          EventBus.emit('new-thumbnail-chat', request.params.fileId);
      });
      //TIMESLOTS
      backendWebSocket.post('/timeslot/new', (request) => {
          EventBus.emit('timeslot-new', request.body.result)
      });
      backendWebSocket.delete('/timeslot/delete/:timeslotId', (request) => {
          EventBus.emit('timeslot-delete', request.params.timeslotId);
      });
      backendWebSocket.patch('/timeslot/patch', (request) => {
          EventBus.emit('timeslot-patch', request.body.timeslot);
      });
    
      //PlANWIDGET
      backendWebSocket.post('/appointment/new', (request) => {
          EventBus.emit('appointment-new', request.body.populatedAppointments);
          EventBus.emit('appointment-new-hw', request.body.populatedAppointments);
      });
      backendWebSocket.delete('/appointment/delete/:appointmentId', (request) => {
         EventBus.emit('appointment-delete', request.params.appointmentId);
      });
      backendWebSocket.patch('/appointment/patch', (request) => {
         EventBus.emit('appointment-patch', request.body.updatedAppointment);
      });
      backendWebSocket.post('/teacherupload/new', (request) => {
          EventBus.emit('teacherupload-new', request.body);
          EventBus.emit('teacherupload-new-hw', request.body);
      });
      backendWebSocket.delete('/teacherupload/delete', (request) => {
          EventBus.emit('teacherupload-delete', request.body);
          EventBus.emit('teacherupload-delete-hw', request.body);
      });
      backendWebSocket.post('/pupilupload/new', (request) => {
          EventBus.emit('pupilupload-new', request.body);
      });
      backendWebSocket.delete('/pupilupload/delete', (request) => {
          EventBus.emit('pupilupload-delete', request.body);
          EventBus.emit('pupilupload-delete-hw', request.body);
      });
      backendWebSocket.post('/teacherupload/edit', (request) => {
          EventBus.emit('teacherupload-edit', request.body);
      });
      backendWebSocket.post('/pupilupload/edit', (request) => {
          EventBus.emit('pupilupload-edit', request.body);
          EventBus.emit('pupilupload-edit-hw', request.body);
      });

      //VFSv2
      backendWebSocket.post('/new/diskspace/used', (request) => {
          EventBus.emit('add-new-space', request.body.spaceToAdd);
      });
      backendWebSocket.post('/vfs/new/', (request) => {
         EventBus.emit('vfs-new', request.body);
      });
      backendWebSocket.delete('/vfs/delete/', (request) => {
          EventBus.emit('vfs-delete', request.body);
      });
      backendWebSocket.post('/vfs/new/upload/', (request) => {
          EventBus.emit('vfs-new-upload', request.body);
      });
      backendWebSocket.delete('/vfs/delete/upload/', (request) => {
          EventBus.emit('vfs-delete-upload', request.body);
      });
      backendWebSocket.patch('/vfs/update/folder/', (request) => {
          EventBus.emit('vfs-update-folder', request.body);
      });

      //HOMEWORK
      backendWebSocket.post('/vfs/new/task/', (request) => {
        EventBus.emit('vfs-new-task', request.body);
      });
      backendWebSocket.delete('/vfs/delete/task/', (request) => {
        EventBus.emit('vfs-delete-task', request.body);
      });
      //APPS
      backendWebSocket.post('/externalApp/new', (request) => {
        EventBus.emit('new-external-app', request.body);
      });
      backendWebSocket.delete('/externalApp/delete/:appId', (request) => {
        EventBus.emit('delete-app', request.params.appId);
      });
      //PINBOARD
      backendWebSocket.post('/pinboard/new/', (request) => {
         EventBus.emit('pinboard-new', request.body);
         EventBus.emit('pinboard-badge');
      });
      backendWebSocket.delete('/pinboard/delete/:pinboardId/', (request) => {
         EventBus.emit('pinboard-delete', request.params.pinboardId);

      });
      backendWebSocket.patch('/pinboard/patch/', (request) => {
         EventBus.emit('pinboard-patch', request.body.patchedPinboard);
          EventBus.emit('pinboard-badge');
      });
      backendWebSocket.post('/pinboard/card/new/', (request) => {
         EventBus.emit('pinboard-card-new', request.body);
          EventBus.emit('pinboard-badge');
      });
      backendWebSocket.delete('/pinboard/card/delete/', (request) => {
          EventBus.emit('pinboard-card-delete', request.body);
      });
      backendWebSocket.patch('/pinboard/card/patch/', (request) => {
         EventBus.emit('pinboard-card-patch', request.body);
          EventBus.emit('pinboard-badge');
      });
      backendWebSocket.post('/pinboard/card/upload/', (request) => {
          EventBus.emit('pinboard-card-upload', request.body);
          EventBus.emit('pinboard-badge');
      });

      //SICKNOTE
      backendWebSocket.post('/sicknote/new/', (request) => {
          EventBus.emit('sicknote-new', request.body.sickNote);
          EventBus.emit('parental-badge');
      });
      backendWebSocket.post('/sicknote/new/file/', (request) => {
          EventBus.emit('sicknote-new-file', request.body);
      });
      backendWebSocket.patch('/sicknote/response/', (request) => {
          EventBus.emit('sicknote-response', request.body);
      });
      backendWebSocket.post('/sicknote/signature/new/', (request) => {
          EventBus.emit('sicknote-signature-new', request.body);
      });


      //PARENTALLETTERS
      backendWebSocket.post('/parental/new/', (request) => {
          EventBus.emit('parental-new', request.body.newParentalLetter);
          EventBus.emit('parental-badge');
      });
      backendWebSocket.get('/parental/delete/:letterId/', (request) => {
         EventBus.emit('parental-delete', request.params.letterId);
      });
      backendWebSocket.patch('/parental/patch/', (request) => {
          EventBus.emit('parental-patch', request.body.updatedParentalLetter);
          EventBus.emit('parental-badge');
      });
      backendWebSocket.post('/parental/new/file/', (request) => {
          EventBus.emit('parental-new-file', request.body);
          EventBus.emit('parental-badge');
      });
      backendWebSocket.post('/parental/seen/', (request) => {
          EventBus.emit('parental-seen', request.body);
      });
      backendWebSocket.post('/parental/signed/', (request) => {
          EventBus.emit('parental-signed', request.body);
      });
      backendWebSocket.post('/parental/reply/seen/', (request) => {
        EventBus.emit('parentalReply-seen', request.body);
    });
      //BRETTWIDGET
      backendWebSocket.post('/blackboard/seen/', (request) => {
          EventBus.emit('blackboard-seen', request.body);
      });
      backendWebSocket.post('/blackboard/new/upload/', (request) => {
          EventBus.emit('blackboard-badge');
          EventBus.emit('blackboard-new-upload', request.body);
      });
      backendWebSocket.patch('/blackboard/patch/', (request) => {
          EventBus.emit('blackboard-badge');
          EventBus.emit('blackboard-patch', request.body.updatedMessage);
      });
      backendWebSocket.get('/blackboard/delete/:blackboardId', (request) => {
          EventBus.emit('blackboard-delete', request.params.messageId);
      });
      backendWebSocket.post('/blackboard/new/', (request) => {
          EventBus.emit('blackboard-badge');
          EventBus.emit('blackboard-new', request.body);
      });


      //LIBREOFFICE
      backendWebSocket.get('/libre-office/save/exit', () => {
          store.commit('snackbar/showSnackbar', {
              message: 'Dokument geschlossen. Änderungen wurden verworfen.'
          });
      });
    backendWebSocket.post('/libre-office/save', () => {
       store.commit('snackbar/showSnackbar', {
           message: 'Datei wurde erfolgreich gespeichert!'
       });
    });
    backendWebSocket.post('/libre-office/rename', (request) => {
        const body = request.body;
        let messageSnack = `Datei wurde erfolgreich von "${body.oldName}" zu "${body.newName}" umbenannt.\n`;
        messageSnack += body.state ? `Die Datei ist nun unter Dokumente/${body.type === 'pptx' ? 'Präsentationen'
        : body.type === 'slxs' ? 'Tabellen' : 'Text Dokumente'} zu finden!` : '';
        store.commit('snackbar/showSnackbar', {
            message: messageSnack,
            timeout: 8000,
        });
    });
    backendWebSocket.post('/libre-office/save/template', (request) => {
        const body = request.body;
        store.commit('snackbar/showSnackbar', {
            message: body.state ? `Datei wurde erfolgreich in Dateien unter LibreOffice/${body.type === 'pptx' ?
                'Präsentationen' : body.type === 'xlsx' ? 'Tabellen' : 'Texte'}
                gespeichert!` : 'Datei wurde erfolgreich gespeichert!',
            timeout: 8000,
        });
    });
    backendWebSocket.post('/libre-office/save/cancel', () => store.commit('snackbar/showSnackbar', {
        message: 'Es wurden keine Änderungen erkannt, Datei wurde nicht gespeichert!',
    }));

    backendWebSocket.get('/libre-office/save/parent', () => store.commit('snackbar/showSnackbar', {
        message: 'Die Datei wurde gespeichert. Denken Sie bitte daran, dass eklara ihre Datei nicht in eKlara speichern können. Sie können die Datei unter "Datei" und "Herunterladen" lokal auf Ihrem Gerät speichern.',
        color: 'error',
    }))
    backendWebSocket.post('/chat-room/:chatRoomId', () => store.dispatch('chat/fetchChats', { sinceUpdatedAt: store.state.chat.lastFetchTime }));
    backendWebSocket.patch('/chat-room/:chatRoomId', () => store.dispatch('chat/fetchChats', { sinceUpdatedAt: store.state.chat.lastFetchTime }));
    backendWebSocket.delete('/chat-room/:chatRoomId', () => store.dispatch('chat/fetchChats')); // don't fetch with sinceUpdatedAt becaue it doesnt contain the deleted ones

    backendWebSocket.post('/chat-room-event/:chatRoomEventId', () => store.dispatch('chat/fetchChats', { sinceUpdatedAt: store.state.chat.lastFetchTime }));
    backendWebSocket.patch('/chat-room-event/:chatRoomEventId', (request) => {
        const body = request.body;
        store.dispatch('chat/updateChatRoomEvent', body.eventId);
    });
    backendWebSocket.delete('/chat-room-event/:chatRoomEventId', () => store.dispatch('chat/fetchChats'));  // don't fetch with sinceUpdatedAt becaue it doesnt contain the deleted ones


    backendWebSocket.post('/account/:accountId', () => store.dispatch('accounts/fetchAccounts'));
    backendWebSocket.post('/account/forcelogout', () => {
        EventBus.emit('account-forcelogout');
    });
    backendWebSocket.patch('/account/:accountId', () => store.dispatch('accounts/fetchAccounts'));
    backendWebSocket.delete('/account/:accountId', () => store.dispatch('accounts/fetchAccounts'));

    backendWebSocket.post('/account-set/:accountSetId', () => store.dispatch('accountSets/fetchAccountSets'));
    backendWebSocket.patch('/account-set/:accountSetId', () => store.dispatch('accountSets/fetchAccountSets'));
    backendWebSocket.delete('/account-set/:accountSetId', () => store.dispatch('accountSets/fetchAccountSets'));


    backendWebSocket.post('/teacher/:teacherId', () => store.dispatch('teachers/fetchTeachers'));
    backendWebSocket.patch('/teacher/:teacherId', () => store.dispatch('teachers/fetchTeachers'));
    backendWebSocket.delete('/teacher/:teacherId', () => store.dispatch('teachers/fetchTeachers'));

    backendWebSocket.post('/pupil/:pupilId', () => store.dispatch('pupils/fetchPupils'));
    backendWebSocket.patch('/pupil/:pupilId', () => store.dispatch('pupils/fetchPupils'));
    backendWebSocket.delete('/pupil/:pupilId', () => store.dispatch('pupils/fetchPupils'));


    backendWebSocket.post('/parent/:accountSetId', () => store.dispatch('parents/fetchParents'));
    backendWebSocket.patch('/parent/:accountSetId', () => store.dispatch('parents/fetchParents'));
    backendWebSocket.delete('/parent/:accountSetId', () => store.dispatch('parents/fetchParents'));

    backendWebSocket.post('/maintainer/:accountSetId', () => store.dispatch('maintainers/fetchMaintainers'));
    backendWebSocket.patch('/maintainer/:accountSetId', () => store.dispatch('maintainers/fetchMaintainers'));
    backendWebSocket.delete('/maintainer/:accountSetId', () => store.dispatch('maintainers/fetchMaintainers'));

    //DiaryEntries
    backendWebSocket.patch('/diaryEntry/update', (request) => {
        EventBus.emit('diaryEntry-update', request.body)
    });
    //LateTimes
    backendWebSocket.patch('/lateTime/update', 
        (request) => {
            const {groupId, day}=request.body;
            console.log('PATCH LATETIME',groupId, day);
            store.dispatch('lateTimes/getLateTimesForGroupOnDay',{day, groupId})
            EventBus.emit('diaryEntriesOnDay-update',request.body);
        }
    );
    backendWebSocket.patch('/diaryEntry/update/fullDay', 
        (request) => {
            const {groupId, dayToUpdate}=request.body;
            store.dispatch('lateTimes/getLateTimesForGroupOnDay',{day: dayToUpdate, groupId})
            EventBus.emit('diaryEntriesFullDay-update',request.body);
        }
    );
    backendWebSocket.patch('/device/', () => { /* Currently unused, but avoid error message due to unhandled route */});
  }
}

export default {
  createBackendWebSocketPlugin,
}



