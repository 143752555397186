<template>
    <div
        class="fileName"
        :title="file.title"
        @click="titleClicked"
    >
        <v-img
            v-if="view === 'list' && uploadIcon(file)"
            :src="uploadIcon(file)"
            class="mr-2"
            alt=""
            cover
            style="height: 24px; max-width: 24px"
        />
        {{ getDisplayName() }}
    </div>
</template>

<script>
import imageIcon from "@/assets/Icons/FaceliftIcons/bild.svg";
import wordIcon from "@/assets/Icons/FaceliftIcons/doc_libre_fachinfo.svg";
import powerpointIcon from "@/assets/Icons/FaceliftIcons/ppt_libre_fachinfo.svg";
import excelIcon from "@/assets/Icons/FaceliftIcons/xls_libre_fachinfo.svg";
import musicIcon from "@/assets/Icons/f-musik-84.svg";
import videoIcon from "@/assets/Icons/video-grey.svg";
import pdfIcon from "@/assets/Icons/FaceliftIcons/pdf_libre_fachinfo.svg";
import documentIcon from "@/assets/Icons/FaceliftIcons/datei_blatt.svg";
import uhrIcon from "@/assets/Icons/FaceliftIcons/stundenplan_uhr.svg";

export default {
    name: "FileListTitle",
    props: {
        file: { type: Object, required: true },
        view: { type: String, required: false },
        usage: { type: String, required: false },
        fileDeleted: { type: Boolean, required: false},
    },
    methods: {
        getDisplayName() {
            const fileDeletedInfo = this.fileDeleted ? 'Gelöscht: ' : '';
            if(this.file.draft) {
                return fileDeletedInfo + this.file.draft.name
            } else if(this.usage === 'abgabenPupil' && this.file.correction) {
                return fileDeletedInfo + this.file.correction.title;
            } else {
                return fileDeletedInfo + this.file.title;
            }
        },
        titleClicked() {
            this.$emit('titleClicked');
        },
        uploadIcon(file) {
            let icon;
            if(file.draft) {
                return uhrIcon;
            }
            const name = file.title;
            if (['png', 'jpg', 'jpeg', 'bmp', 'gif'].includes(name.split('.').pop().toLowerCase())) {
                icon = imageIcon;
            }
            if (['doc', 'docx'].includes(name.split('.').pop().toLowerCase())) {
                icon = wordIcon;
            }
            if (['ppt', 'pptx'].includes(name.split('.').pop().toLowerCase())) {
                icon = powerpointIcon;
            }
            if (['xls', 'xlsx'].includes(name.split('.').pop().toLowerCase())) {
                icon = excelIcon;
            }
            if (['wav', 'mp3', 'wma', 'm4a', 'acc', 'ogg'].includes(name.split('.').pop().toLowerCase())) {
                icon = musicIcon;
            }
            if (['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(name.split('.').pop().toLowerCase())) {
                icon = videoIcon;
            }
            if (['pdf'].includes(name.split('.').pop().toLowerCase())) {
                icon = pdfIcon;
            }
            if (['txt'].includes(name.split('.').pop().toLowerCase())) {
                icon = documentIcon;
            }
            return icon;
        },
    },
}
</script>

<style scoped>
.fileName {
    height: 30px;
    display: flex;
    align-items: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
}
</style>
